import { Avatar, Card, Col, Divider, List, Row, Space } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import { conditionsListData } from "../../../../data/data";
import { userDetailsAction } from "../../../../redux/userDetail/action";
import { getUser } from "../../../../Utils/isAuth";
import { MailOutlined } from "@ant-design/icons";

import DetailsFundRaisersTable from "./DetailsFundRaisersTable";
import { getFirstLater, getImageURL } from "../../../../Utils";

const DetailsTab = ({ data }) => {
  const dispatch = useDispatch();
  const user = getUser();

  const handleUserClick = () => {
    if (user.is_superuser) {
      dispatch(
        userDetailsAction({ id: data?.organizer?.id, deal_id: data?.id || "" })
      );
    }
  };
  return (
    <>
      <Row gutter={[24, 0]}>
        <Col xs={24} lg={12} style={{ marginBottom: "20px" }}>
          <Col xs={24} style={{ marginBottom: "20px", padding: "0 24px" }}>
            <h3 className="dd-label">Project Details</h3>
            <small className="dd-value">{data?.projectName}</small>
          </Col>
          {!data?.is_m_and_a && (
            <List
              grid={{ gutter: 24, xs: 1, sm: 2, md: 2, lg: 2, xl: 2, xxl: 2 }}
              dataSource={conditionsListData(data)}
              bordered={false}
              className="deal-intro-list deal-intro-list-ext"
              renderItem={(item) => (
                <List.Item>
                  <Card className="list-item" bordered={false}>
                    <div>{item.title}</div>
                    <Space>
                      <span className="list-item__value">{item.value}</span>
                    </Space>
                  </Card>
                </List.Item>
              )}
            />
          )}
        </Col>
        {data?.organizer ? (
          <Col
            xs={24}
            lg={12}
            style={{ marginBottom: "20px", cursor: "pointer" }}
          >
            <Card className="condition_card" onClick={handleUserClick}>
              <Row gutter={[16, 8]}>
                <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
                  <Avatar
                    src={getImageURL(data?.organizer.avatar || "")}
                    className="shape-avatar-letter"
                  >
                    {getFirstLater(data?.organizer.name || "")}
                  </Avatar>
                </Col>

                <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
                  <h3>{data?.organizer.name}</h3>
                </Col>
              </Row>
              <Row gutter={[16, 0]}>
                <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
                  <Space>
                    <small>{data?.organizer.designation}</small>
                  </Space>
                </Col>
              </Row>
              <Row gutter={[16, 0]}>
                <Divider />
              </Row>
              <Row gutter={[8, 8]}>
                <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
                  <Space
                    onClick={() => {
                      window.open(
                        `mailto:${data?.organizer?.email}?subject=SendMail&body=Description`
                      );
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <MailOutlined />
                    <label>{data?.organizer?.email}</label>
                  </Space>
                </Col>
              </Row>
            </Card>
          </Col>
        ) : null}
        <Col span={24} style={{ marginBottom: "20px" }}>
          <h2 className="financial_title">Fund Terms</h2>
        </Col>

        <Col span={24} style={{ marginBottom: "20px" }}>
          <DetailsFundRaisersTable data={data} />
        </Col>

        {/* <Col span={24} style={{ marginBottom: "20px" }}>
          <h2 className="financial_title">Accounting Details</h2>
        </Col>

        <Col span={24} style={{ marginBottom: "20px" }}>
          <DetailsFundRaisersTable data={data} />
        </Col> */}
      </Row>
    </>
  );
};

export default DetailsTab;
