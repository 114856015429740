import {
  ADD_COMMENT_REQUEST,
  CHENGE_ALLOW_DOC_REQUEST,
  CLEAR_DEAL_ERROR,
  CLEAR_USER_DOC,
  DEAL_DETAILS,
  DELETE_ANY_DOCUMENT_REQUEST,
  GET_USER_DOCUMENTS_REQUEST,
  INVESTOR_DEAL_DETAILS_REQUEST,
  INVESTOR_DETAILS_REQUEST,
  ORGANIZER_DEAL_DETAILS_REQUEST,
  PUBLISH_DEAL_REQUEST,
  SELECT_DEALTYPE_REQUEST,
  SIGN_ANY_DOCUMENT_REQUEST,
  UPLOAD_ANY_DOCUMENT_REQUEST,
  UPLOAD_DOCUMENT_REQUEST,
  PUBLISH_DEAL_FALSE,
  UPLOAD_DATA_ROOM_REQUEST,
  GET_DATA_ROOM_REQUEST,
  UPDATE_ANY_DOCUMENT_REQUEST,
  ASK_PERMISSION_TO_DOCUMENT_REQUEST,
  ASKED_PERMISSION_TO_DOCUMENTS_REQUEST,
  GIVE_PERMISSION_TO_DOCUMENTS_REQUEST,
  UNPUBLISH_DEAL_REQUEST,
} from "./constants";

export const dealDetailsAction = (data) => {
  return {
    type: DEAL_DETAILS,
    data,
  };
};

export const organizerDealDetailsAction = (data) => {
  return {
    type: ORGANIZER_DEAL_DETAILS_REQUEST,
    data,
  };
};

export const investorDealDetailsAction = (data) => {
  return {
    type: INVESTOR_DEAL_DETAILS_REQUEST,
    data,
  };
};

export const publishDeal = (data) => {
  return {
    type: PUBLISH_DEAL_REQUEST,
    data,
  };
};

export const unPublishDeal = (data) => {
  return {
    type: UNPUBLISH_DEAL_REQUEST,
    data,
  };
};

export const selectDealType = (data) => {
  return {
    type: SELECT_DEALTYPE_REQUEST,
    data,
  };
};

export const addComment = (data) => {
  return {
    type: ADD_COMMENT_REQUEST,
    data,
  };
};

export const investorDetailAction = (data) => {
  return {
    type: INVESTOR_DETAILS_REQUEST,
    data,
  };
};

export const uploadDocument = (data) => {
  return {
    type: UPLOAD_DOCUMENT_REQUEST,
    data,
  };
};

export const uploadDataRoomDocument = (data) => {
  return {
    type: UPLOAD_DATA_ROOM_REQUEST,
    data,
  };
};

export const getDataRoomDocument = (data) => {
  return {
    type: GET_DATA_ROOM_REQUEST,
    data,
  };
};

export const uploadAnyDocument = (data) => {
  return {
    type: UPLOAD_ANY_DOCUMENT_REQUEST,
    data,
  };
};

export const updateAnyDocument = (data) => {
  return {
    type: UPDATE_ANY_DOCUMENT_REQUEST,
    data,
  };
};

export const deleteAnyDocument = (data) => {
  return {
    type: DELETE_ANY_DOCUMENT_REQUEST,
    data,
  };
};

export const askPermissionToDocument = (data) => {
  return {
    type: ASK_PERMISSION_TO_DOCUMENT_REQUEST,
    data,
  };
};

export const askedPermissionToDocuments = (data) => {
  return {
    type: ASKED_PERMISSION_TO_DOCUMENTS_REQUEST,
    data,
  };
};

export const givePermissionToDocuments = (data) => {
  return {
    type: GIVE_PERMISSION_TO_DOCUMENTS_REQUEST,
    data,
  };
};

export const getUserDocuments = (data) => {
  return {
    type: GET_USER_DOCUMENTS_REQUEST,
    data,
  };
};

export const signAnyDocuments = (data) => {
  return {
    type: SIGN_ANY_DOCUMENT_REQUEST,
    data,
  };
};

export const chengeAllowDocAction = (data) => {
  return {
    type: CHENGE_ALLOW_DOC_REQUEST,
    data,
  };
};

export const clearDealError = () => {
  return {
    type: CLEAR_DEAL_ERROR,
  };
};

export const clearUserDoc = () => {
  return {
    type: CLEAR_USER_DOC,
  };
};

export const closePublishDealModal = () => {
  return {
    type: PUBLISH_DEAL_FALSE,
  };
};
