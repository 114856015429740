export const SET_TYPE_USER_REQUEST = "SET_TYPE_USER_REQUEST";
export const SET_TYPE_USER_SUCCESS = "SET_TYPE_USER_SUCCESS";
export const SET_TYPE_USER_ERROR = "SET_TYPE_USER_ERROR";

export const SET_PROFILE_IND_REQUEST = "SET_PROFILE_IND_REQUEST";
export const SET_PROFILE_IND_SUCCESS = "SET_PROFILE_IND_SUCCESS";
export const SET_PROFILE_IND_ERROR = "SET_PROFILE_IND_ERROR";

export const SET_PROFILE_COMP_REQUEST = "SET_PROFILE_COMP_REQUEST";
export const SET_PROFILE_COMP_SUCCESS = "SET_PROFILE_COMP_SUCCESS";
export const SET_PROFILE_COMP_ERROR = "SET_PROFILE_COMP_ERROR";

export const PUT_PROFILE_COMP_REQUEST = "PUT_PROFILE_COMP_REQUEST";
export const PUT_PROFILE_COMP_SUCCESS = "PUT_PROFILE_COMP_SUCCESS";
export const PUT_PROFILE_COMP_ERROR = "PUT_PROFILE_COMP_ERROR";

export const SET_KYC_REQUEST = "SET_KYC_REQUEST";
export const SET_KYC_SUCCESS = "SET_KYC_SUCCESS";
export const SET_KYC_ERROR = "SET_KYC_ERROR";

export const SET_KYB_REQUEST = "SET_KYB_REQUEST";
export const SET_KYB_SUCCESS = "SET_KYB_SUCCESS";
export const SET_KYB_ERROR = "SET_KYB_ERROR";
export const SET_KYB_PASS = "SET_KYB_PASS";

export const SET_QUESTIONARE_REQUEST = "SET_QUESTIONARE_REQUEST";
export const SET_QUESTIONARE_SUCCESS = "SET_QUESTIONARE_SUCCESS";
export const SET_QUESTIONARE_ERROR = "SET_QUESTIONARE_ERROR";

export const SET_SOURCE_OF_FUNDS_REQUEST = "SET_SOURCE_OF_FUNDS_REQUEST";
export const SET_SOURCE_OF_FUNDS_SUCCESS = "SET_SOURCE_OF_FUNDS_SUCCESS";
export const SET_SOURCE_OF_FUNDS_ERROR = "SET_SOURCE_OF_FUNDS_ERROR";

export const FACILITATORS_SERVICES_REQUEST = "FACILITATORS_SERVICES_REQUEST";
export const FACILITATORS_SERVICES_SUCCESS = "FACILITATORS_SERVICES_SUCCESS";
export const FACILITATORS_SERVICES_ERROR = "FACILITATORS_SERVICES_ERROR";

export const FACILITATORS_SERVICES_SET_REQUEST =
  "FACILITATORS_SERVICES_SET_REQUEST";
export const FACILITATORS_SERVICES_SET_SUCCESS =
  "FACILITATORS_SERVICES_SET_SUCCESS";
export const FACILITATORS_SERVICES_SET_ERROR =
  "FACILITATORS_SERVICES_SET_ERROR";

export const PREVIOUS_PAGE = "PREVIOUS_PAGE";

export const CLEAR_SUCCESS = "CLEAR_SUCCESS";
export const CLEAR_ERROR = "CLEAR_ERROR";

export const DELETE_IDENTITY_DOC_REQUEST = "DELETE_IDENTITY_DOC_REQUEST";
export const DELETE_IDENTITY_DOC_SUCCESS = "DELETE_IDENTITY_DOC_SUCCESS";
export const DELETE_IDENTITY_DOC_ERROR = "DELETE_IDENTITY_DOC_ERROR";
