import { Empty, message, Modal, Tabs } from "antd";
import React, { useState } from "react";
import {
  getComparativeAnalysis,
  getMatchingTools,
  getSwotMetrics,
} from "../../../../api/external";
import { useEffect } from "react";
import { sendEmailsForIRM } from "../../../../api";
import DetailsTab from "./DetailsTab";
import MatchingToolTab from "./MatchingToolTab";
import GetInTouchTab from "./GetInTouchTab";
import SwotAnalysisTab from "./SwotAnalysisTab";
import ComparativeAnalysisTab from "./ComparativeAnalysisTab";

const { TabPane } = Tabs;

function Index({
  openDrawer,
  toggleDrawer,
  investorDetail: { data, industryGroups = [] },
  deals,
}) {
  const [tabValue, setTabValue] = useState("details");
  const [matchingTools, setMatchingTools] = useState([]);
  const [investors, setInvestors] = useState([]);
  const [competitors, setCompetitors] = useState([]);
  const [competitorsInvestors, setCompetitorsInvestors] = useState([]);
  const [swotMetrics, setSwotMetrics] = useState({});

  useEffect(() => {
    getComparativeAnalysis("investors").then((res) => {
      setInvestors(res.data.companies);
    });
    getComparativeAnalysis("competitors").then((res) => {
      setCompetitors(res.data.companies);
    });
    getComparativeAnalysis("competitors_investors").then((res) => {
      setCompetitorsInvestors(res.data.companies);
    });
  }, []);

  useEffect(() => {
    if (data.id) {
      getSwotMetrics(data.id).then((res) => {
        setSwotMetrics(res.data);
      });
    }
  }, [data.id]);

  const fetchMatchingTools = async () => {
    if (industryGroups.length > 0) {
      try {
        industryGroups.map(async (item) => {
          let res = await getMatchingTools(item);
          setMatchingTools([...matchingTools, ...res.data.data]);
        });
      } catch (error) {
        message.error("Something went wrong, While fetching matching tools");
      }
    }
  };

  useEffect(() => {
    if (industryGroups) {
      fetchMatchingTools();
    }
  }, [industryGroups]);

  const handleSubmit = async (values) => {
    let formData = new FormData();
    formData.append("to_email", values.to);
    // formData.append("company_name", data.title);
    formData.append("message", values?.message);
    formData.append("subject", values.subject);
    values.deal && formData.append("deal_id", values.deal);
    try {
      const res = await sendEmailsForIRM(formData);
      message.success(res.data.msg || "");
    } catch (error) {
      message.error("Something went wrong while sending mail");
    }
  };

  return (
    <Modal
      centered
      open={openDrawer}
      closable
      footer={null}
      bodyStyle={{
        height: "80vh",
      }}
      onCancel={toggleDrawer}
      width="80vw"
      className="inv-modal"
      title="Investor Details"
    >
      <Tabs defaultActiveKey={tabValue} onChange={(tab) => setTabValue(tab)}>
        <TabPane tab="Details" key="details" className="tab-of-modal">
          <DetailsTab data={data} />
        </TabPane>
        <TabPane tab="Matching Tool" key="tools" className="tab-of-modal">
          {matchingTools && matchingTools.length > 0 ? (
            <MatchingToolTab matchingTools={matchingTools} />
          ) : (
            <div className="empty-image">
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            </div>
          )}
        </TabPane>
        {data.c_em && (
          <TabPane
            tab="Get In Touch"
            key="get-in-touch"
            className="tab-of-modal"
          >
            <GetInTouchTab
              data={data}
              handleSubmit={handleSubmit}
              deals={deals}
            />
          </TabPane>
        )}
        <TabPane
          tab="SWOT Analysis"
          key="swot-analysis"
          className="tab-of-modal"
        >
          {swotMetrics?.metrics?.length ? (
            <SwotAnalysisTab swotMetrics={swotMetrics} />
          ) : (
            <div className="empty-image">
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            </div>
          )}
        </TabPane>
        <TabPane
          tab="Comparative Analysis"
          key="comparative-analysis"
          className="tab-of-modal"
        >
          <ComparativeAnalysisTab
            investors={investors}
            competitors={competitors}
            competitorsInvestors={competitorsInvestors}
          />
        </TabPane>
      </Tabs>
    </Modal>
  );
}

export default Index;
