import { Card, Col, Row, Statistic, Tag } from "antd";
import { ArrowUpOutlined, ArrowDownOutlined } from "@ant-design/icons";
import PastFundraises from "../tables/PastFundraises";
import SharedLinks from "../tables/SharedLinks";
import React from "react";
import {
  financials,
  revenue,
  shorttermdebt,
  incash,
  netprofit,
  pastfundraises,
  sharedlink,
} from "../../../data/data";

const Financials = () => {
  return (
    <div className="deal_financials">
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
          {financials.map((c, index) => (
            <h2 className="financial_title">{c.title}</h2>
          ))}
        </Col>

        <Col xs={24} sm={24} md={6} xl={6} xxl={6}>
          {revenue.map((c, index) => (
            <Card>
              <Row>
                <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
                  {" "}
                  {<h3 className="financial_statics_title">{c.title}</h3>}
                </Col>
              </Row>
              <Row gutter={16}>
                <Col xs={24} sm={24} md={12} xl={12} xxl={12}>
                  <Statistic className="stats_percentage" value={c.amt} />{" "}
                </Col>
                <Col xs={24} sm={24} md={12} xl={12} xxl={12}>
                  <div className="stats">
                    <Tag icon={<ArrowUpOutlined />}>
                      {c.percent}
                      <small>%</small>
                    </Tag>
                  </div>
                </Col>
              </Row>
            </Card>
          ))}
        </Col>

        <Col xs={6} sm={6} md={6} xl={6} xxl={6}>
          {shorttermdebt.map((c, index) => (
            <Card>
              <Row>
                <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
                  {" "}
                  {<h3 className="financial_statics_title">{c.title}</h3>}
                </Col>
              </Row>
              <Row gutter={16}>
                <Col xs={24} sm={24} md={12} xl={12} xxl={12}>
                  <Statistic className="stats_percentage" value={c.amt} />{" "}
                </Col>
                <Col xs={24} sm={24} md={12} xl={12} xxl={12}>
                  <div className="stats">
                    <Tag icon={<ArrowUpOutlined />}>
                      {c.percent}
                      <small>%</small>
                    </Tag>
                  </div>
                </Col>
              </Row>
            </Card>
          ))}
        </Col>
        <Col xs={6} sm={6} md={6} xl={6} xxl={6}>
          {incash.map((c, index) => (
            <Card>
              <Row>
                <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
                  {" "}
                  {<h3 className="financial_statics_title">{c.title}</h3>}
                </Col>
              </Row>
              <Row gutter={16}>
                <Col xs={24} sm={24} md={12} xl={12} xxl={12}>
                  <Statistic className="stats_percentage" value={c.amt} />{" "}
                </Col>
                <Col xs={24} sm={24} md={12} xl={12} xxl={12}>
                  <div className="stats1">
                    <Tag icon={<ArrowDownOutlined />}>
                      {c.percent}
                      <small>%</small>
                    </Tag>
                  </div>
                </Col>
              </Row>
            </Card>
          ))}
        </Col>
        <Col xs={6} sm={6} md={6} xl={6} xxl={6}>
          {netprofit.map((c, index) => (
            <Card>
              <Row>
                <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
                  {" "}
                  {<h3 className="financial_statics_title">{c.title}</h3>}
                </Col>
              </Row>
              <Row gutter={16}>
                <Col xs={24} sm={24} md={12} xl={12} xxl={12}>
                  <Statistic className="stats_percentage" value={c.amt} />{" "}
                </Col>
                <Col xs={24} sm={24} md={12} xl={12} xxl={12}>
                  <div className="stats1">
                    <Tag icon={<ArrowDownOutlined />}>
                      {c.percent}
                      <small>%</small>
                    </Tag>
                  </div>
                </Col>
              </Row>
            </Card>
          ))}
        </Col>

        <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
          {pastfundraises.map((c, index) => (
            <h2 className="financial_statics_title">{c.title}</h2>
          ))}
        </Col>

        <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
          <PastFundraises />
        </Col>

        <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
          {sharedlink.map((c, index) => (
            <h2 className="financial_statics_title">{c.title}</h2>
          ))}
        </Col>
        <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
          <SharedLinks />
        </Col>
      </Row>
    </div>
  );
};

export default Financials;
