import { call, put, takeLatest } from "redux-saga/effects";

import {
  serviceOrganizerDealsApi,
  updateServiceOrganizerDealStatusAPi,
} from "../../../api";
import {
  SERVICE_ORGANIZER_DEAL_LIST_REQUEST,
  SERVICE_ORGANIZER_DEAL_LIST_SUCCESS,
  SERVICE_ORGANIZER_DEAL_LIST_ERROR,
  UPDATE_SERVICE_ORGANIZER_DEAL_STATUS_REQUEST,
  UPDATE_SERVICE_ORGANIZER_DEAL_STATUS_ERROR,
  UPDATE_SERVICE_ORGANIZER_DEAL_STATUS_SUCCESS,
} from "./constants";

function* getServiceOrganizersDeals(action) {
  try {
    let { data } = yield call(serviceOrganizerDealsApi, action?.data);

    if (data) {
      yield put({ type: SERVICE_ORGANIZER_DEAL_LIST_SUCCESS, data });
    }
  } catch (err) {
    yield put({ type: SERVICE_ORGANIZER_DEAL_LIST_ERROR });
  }
}

function* updateServiceOrganizerDealStatus(action) {
  try {
    let { data } = yield call(
      updateServiceOrganizerDealStatusAPi,
      action?.data
    );
    if (data?.Msg) {
      yield put({ type: UPDATE_SERVICE_ORGANIZER_DEAL_STATUS_SUCCESS, data });
      yield put({ type: SERVICE_ORGANIZER_DEAL_LIST_REQUEST });
    }
  } catch (err) {
    yield put({ type: UPDATE_SERVICE_ORGANIZER_DEAL_STATUS_ERROR });
  }
}

function* ServiceOrganizersDealsWatcher() {
  yield takeLatest(
    SERVICE_ORGANIZER_DEAL_LIST_REQUEST,
    getServiceOrganizersDeals
  );
  yield takeLatest(
    UPDATE_SERVICE_ORGANIZER_DEAL_STATUS_REQUEST,
    updateServiceOrganizerDealStatus
  );
}
export default ServiceOrganizersDealsWatcher;
