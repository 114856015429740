import React from "react";
import { Row, Col, Card, Avatar, Space } from "antd";

import { team } from "../../../data/data";
import { getFirstLater, getImageURL } from "../../../Utils";

const Team = () => {
  return (
    <div>
      <Row gutter={[8, 16]}>
        {team.map((c) => (
          <Col>
            <Card className="team_card">
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
                  <Avatar
                   size={60}
                   src={getImageURL(c.image || "")}
                  //  icon={<UserOutlined />}
                  className={`${!c.image ? "shape-avatar-letter" : ""}`}
                  >
                    {getFirstLater(c?.title || "")}
                  </Avatar>
                </Col>

                <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
                  {<h3>{c.title}</h3>}
                </Col>
              </Row>
              <Row>
                <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
                  {
                    <Space>
                      <small>{c.designation}</small>
                      <small> {c.designation2}</small>
                    </Space>
                  }
                </Col>
                <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
                  <Space>
                    {c.linkedinicon}
                    {c.fbicon}
                  </Space>
                </Col>
              </Row>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default Team;
