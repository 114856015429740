import jwt from "jwt-decode";

export const isToken = () => {
  const token = localStorage.getItem("token");
  if (token) {
    return true;
  } else {
    return false;
  }
};

export const getToken = () => {
  const token = localStorage.getItem("token");
  if (token) {
    return token;
  } else {
    return null;
  }
};

export const getUser = () => {
  const userStr = localStorage.getItem("user");
  if (userStr) return { ...JSON.parse(userStr) };
  else return null;
};

// remove the token and user from the session storage
export const removeUserSession = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("user");
};

export const setRefreshToken = (refreshToken) => {
  localStorage.setItem("refreshToken", refreshToken);
};

export const setRememberMe = (username) => {
  localStorage.setItem("rememberUser", username);
};

export const setVerifyEmail = (link) => {
  localStorage.setItem("verifyEmailLink", link);
};

export const getVerifyEmail = () => {
  return localStorage.getItem("verifyEmailLink");
};

export const removeVerifyEmail = () => {
  localStorage.removeItem("verifyEmailLink");
};

export const removeRememberMe = () => localStorage.removeItem("rememberUser");

export const removeLongevityMail = () => localStorage.removeItem("lc_email");

export const getRefreshToken = () => {
  const refreshToken = localStorage.getItem("refreshToken");
  if (refreshToken) {
    return refreshToken;
  } else {
    return null;
  }
};

// set the token and user from the session storage
export const setUserSession = (token, userLogo, userDetail, userRole) => {
  localStorage.setItem("token", token);
  let user = getUser();
  const obj = jwt(token);

  if (!userDetail && user?.onboardingStepsDone) {
    user = {
      ...user,
      ...obj,
      userLogo,
      is_onboarded: user?.onboardingStepsDone?.onboarding_status,
      onboardingStepsDone: user?.onboardingStepsDone,
    };

    localStorage.setItem("type_of_account", obj?.type_of_account);
    localStorage.setItem("user", JSON.stringify(user));
    return;
  }

  if (userDetail) {
    user = {
      ...user,
      ...obj,
      userLogo,
      type_of_account: userDetail?.type_of_account || obj?.type_of_account,
      is_onboarded: userDetail?.onboarding_status,
      onboardingStepsDone: userDetail,
      usertab_visible: userDetail?.usertab_visible,
    };
  } else {
    user = {
      ...user,
      ...obj,
      userLogo,
      is_onboarded: userDetail?.onboarding_status,
      onboardingStepsDone: userDetail,
      usertab_visible: userDetail?.usertab_visible,
    };
  }

  if (userRole) {
    user = {
      ...user,
      ...jwt(token),
      userLogo,
      type_of_account: userRole?.type_of_account,
      sub_type_of_account: userRole?.sub_type_of_account,
    };
  }

  localStorage.setItem("user", JSON.stringify(user));
  window.dispatchEvent(new Event("storage"));
};
