import {
  CLEAR_ERROR,
  CLEAR_SUCCESS,
  CREATE_DEAL_ERROR,
  CREATE_DEAL_REQUEST,
  CREATE_DEAL_SUCCESS,
  DOWLOAD_SPV_DOCS_ERROR,
  DOWLOAD_SPV_DOCS_REQUEST,
  DOWLOAD_SPV_DOCS_SUCCESS,
  EDIT_DEAL_DETAILS_ERROR,
  EDIT_DEAL_DETAILS_REQUEST,
  EDIT_DEAL_DETAILS_SUCCESS,
  GET_SECTORS_ERROR,
  GET_SECTORS_SUCCESS,
  SIGN_DOCS_OPEN_ERROR,
  SIGN_DOCS_OPEN_REQUEST,
  SIGN_DOCS_OPEN_SUCCESS,
  SIGN_DOCS_SUCCESS,
  UPDATE_DEAL_ERROR,
  UPDATE_DEAL_REQUEST,
  UPDATE_DEAL_SUCCESS,
  UPLOAD_SPV_DOCS_ERROR,
  UPLOAD_SPV_DOCS_REQUEST,
  UPLOAD_SPV_DOCS_SUCCESS,
  CLEAR_CREATE_DEAL_DETAILS,
  SHOW_POPUP,
  CLEAR_SIGN_DETAILS,
  DELETE_SPV_DOCS_REQUEST,
  DELETE_SPV_DOCS_SUCCESS,
  DELETE_SPV_DOCS_ERROR,
  UPDATE_DEAL_ON_REQUEST,
  UPDATE_DEAL_ON_SUCCESS,
  UPDATE_DEAL_ON_ERROR,
  DEAL_TITLES_REQUEST,
  DEAL_TITLES_SUCCESS,
  DEAL_TITLES_ERROR,
  CLEAR_DEAL_TITLES,
} from "./constants";

const initialState = {
  loading: false,
  stepLoading: false,
  downloadLinks: null,
  fileUploaded: false,
  envelopeUrl: null,
  signed: [],
  pending: null,
  successMessage: null,
  errorMessage: null,
  memorandum_of_association: null,
  spv_services_agreement: null,
  sectors: [],
  dealID: null,

  signingDetails: {
    inProgress: false,
    currentDocumentKey: null,
    signed: false,
    envalopURL: null,
  },
  dealTitles: [],
};

export default function createDeal(state = initialState, action) {
  switch (action.type) {
    case CREATE_DEAL_REQUEST:
    case UPDATE_DEAL_REQUEST:
    case DEAL_TITLES_REQUEST:
      return { ...state, loading: true };
    case CLEAR_DEAL_TITLES:
      return { ...state, dealTitles: [] };

    case UPDATE_DEAL_ON_REQUEST:
      return { ...state, stepLoading: true };
    case CREATE_DEAL_SUCCESS:
    case UPDATE_DEAL_SUCCESS:
      return {
        ...initialState,
        successMessage: "Success",
      };
    case UPDATE_DEAL_ON_SUCCESS:
      return {
        ...state,
        stepLoading: false,
      };
    case DEAL_TITLES_SUCCESS:
      return {
        ...initialState,
        loading: false,
        dealTitles: action.data,
      };
    case DEAL_TITLES_ERROR:
      return {
        ...initialState,
        loading: false,
      };
    case CREATE_DEAL_ERROR:
    case UPDATE_DEAL_ERROR:
      return {
        ...state,
        loading: false,
        errorMessage: action.data,
      };
    case UPDATE_DEAL_ON_ERROR:
      return {
        ...state,
        stepLoading: false,
        errorMessage: action.data,
      };
    case DOWLOAD_SPV_DOCS_REQUEST:
      return { ...state, loading: true };
    case DOWLOAD_SPV_DOCS_SUCCESS:
      return {
        ...state,
        downloadLinks: action.data,
        loading: false,
      };
    case DOWLOAD_SPV_DOCS_ERROR:
      return { ...state, loading: false };
    case UPLOAD_SPV_DOCS_REQUEST:
    case DELETE_SPV_DOCS_REQUEST:
      return { ...state, loading: true, fileUploaded: false };
    case UPLOAD_SPV_DOCS_SUCCESS:
    case DELETE_SPV_DOCS_SUCCESS:
      return {
        ...state,
        loading: false,
        fileUploaded: true,
        [action.data.doc_type]: null,
      };
    case UPLOAD_SPV_DOCS_ERROR:
    case DELETE_SPV_DOCS_ERROR:
      return { ...state, loading: false };

    case SIGN_DOCS_OPEN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case SIGN_DOCS_OPEN_SUCCESS:
      return {
        ...state,
        [action.data.key]: action.data.envelope_id,
        envelopeUrl: action.data.envelope_url,
        pending: action.data.key,
        loading: false,
        signingDetails: {
          inProgress: true,
          envelopeUrl: action.data.envelope_url,
          currentDocumentKey: action.data.key,
          signed: false,
        },
      };
    case SIGN_DOCS_OPEN_ERROR:
      return { ...state, loading: false, envelopeUrl: null };

    case SIGN_DOCS_SUCCESS:
      return {
        ...state,
        envelopeUrl: null,
        signed: [...state.signed, state.pending],
        pending: null,
        loading: false,
        signingDetails: {
          ...state.signingDetails,
          signed: true,
          inProgress: false,
        },
      };

    case CLEAR_SIGN_DETAILS:
      return {
        ...state,
        signingDetails: {
          inProgress: false,
          currentDocumentKey: null,
          signed: false,
          envalopURL: null,
        },
      };
    case CLEAR_SUCCESS:
      return { ...state, successMessage: null };

    case CLEAR_ERROR:
      return { ...state, errorMessage: "" };

    case GET_SECTORS_SUCCESS:
      return { ...state, sectors: action.data };
    case GET_SECTORS_ERROR:
      return { ...state, loading: false };

    case EDIT_DEAL_DETAILS_REQUEST:
      return { ...state, loading: true };
    case EDIT_DEAL_DETAILS_SUCCESS:
      return {
        ...state,
        editedDeal: action.data,
        dealID: action?.data?.deal_info?.id,
        loading: false,
      };
    case EDIT_DEAL_DETAILS_ERROR:
      return { ...state, loading: false };
    case CLEAR_CREATE_DEAL_DETAILS:
      return {
        ...initialState,
        downloadLinks: state.downloadLinks,
        sectors: state.sectors,
      };
    case SHOW_POPUP:
      return {
        ...state,
        errorMessage: action.data,
      };
    default:
      return state;
  }
}
