import { message } from "antd";
import { call, put, select, takeLatest } from "redux-saga/effects";

import {
  addCommentApi,
  askPermissionApi,
  chengeAllowDocApi,
  deleteAnyDocumentApi,
  getDataRoomApi,
  getInvestorDealDetailsApi,
  getOrganizerDealDetails,
  getPermissionRequestApi,
  getUserDocumentsApi,
  givePermissionRequestApi,
  investorDetailsApi,
  publishDealApi,
  unPublishDealApi,
  signAnyDocumentApi,
  updateAnyDocumentApi,
  uploadAnyDocumentApi,
  uploadDocumentApi,
  uploadSignedAnyDocumentsApi,
} from "../../../api";
import {
  ADD_COMMENT_ERROR,
  ADD_COMMENT_REQUEST,
  ADD_COMMENT_SUCCESS,
  ASKED_PERMISSION_TO_DOCUMENTS_ERROR,
  ASKED_PERMISSION_TO_DOCUMENTS_REQUEST,
  ASKED_PERMISSION_TO_DOCUMENTS_SUCCESS,
  ASK_PERMISSION_TO_DOCUMENT_ERROR,
  ASK_PERMISSION_TO_DOCUMENT_REQUEST,
  CHENGE_ALLOW_DOC_ERROR,
  CHENGE_ALLOW_DOC_REQUEST,
  DELETE_ANY_DOCUMENT_ERROR,
  DELETE_ANY_DOCUMENT_REQUEST,
  GET_DATA_ROOM_ERROR,
  GET_DATA_ROOM_REQUEST,
  GET_DATA_ROOM_SUCCESS,
  GET_USER_DOCUMENTS_ERROR,
  GET_USER_DOCUMENTS_REQUEST,
  GET_USER_DOCUMENTS_SUCCESS,
  GIVE_PERMISSION_TO_DOCUMENTS_ERROR,
  GIVE_PERMISSION_TO_DOCUMENTS_REQUEST,
  GIVE_PERMISSION_TO_DOCUMENTS_SUCCESS,
  INVESTOR_DEAL_DETAILS_ERROR,
  INVESTOR_DEAL_DETAILS_REQUEST,
  INVESTOR_DEAL_DETAILS_SUCCESS,
  INVESTOR_DETAILS_ERROR,
  INVESTOR_DETAILS_REQUEST,
  INVESTOR_DETAILS_SUCCESS,
  ORGANIZER_DEAL_DETAILS_ERROR,
  ORGANIZER_DEAL_DETAILS_REQUEST,
  ORGANIZER_DEAL_DETAILS_SUCCESS,
  PUBLISH_DEAL_ERROR,
  PUBLISH_DEAL_REQUEST,
  PUBLISH_DEAL_SUCCESS,
  SIGN_ANY_DOCUMENT_ERROR,
  SIGN_ANY_DOCUMENT_REQUEST,
  SIGN_ANY_DOCUMENT_SUCCESS,
  UNPUBLISH_DEAL_ERROR,
  UNPUBLISH_DEAL_REQUEST,
  UNPUBLISH_DEAL_SUCCESS,
  UPDATE_ANY_DOCUMENT_ERROR,
  UPDATE_ANY_DOCUMENT_REQUEST,
  UPLOAD_ANY_DOCUMENT_ERROR,
  UPLOAD_ANY_DOCUMENT_REQUEST,
  UPLOAD_ANY_DOCUMENT_SUCCESS,
  UPLOAD_DATA_ROOM_ERROR,
  UPLOAD_DATA_ROOM_REQUEST,
  UPLOAD_DOCUMENT_ERROR,
  UPLOAD_DOCUMENT_REQUEST,
  UPLOAD_DOCUMENT_SUCCESS,
  UPLOAD_SIGN_ANY_DOCS_ERROR,
  UPLOAD_SIGN_ANY_DOCS_REQUEST,
  UPLOAD_SIGN_ANY_DOCS_SUCCESS,
} from "./constants";

function* requestOrganizerDealDetails(action) {
  try {
    let { data } = yield call(getOrganizerDealDetails, action?.data);
    if (data) {
      yield put({ type: ORGANIZER_DEAL_DETAILS_SUCCESS, data });
    }
  } catch (err) {
    yield put({ type: ORGANIZER_DEAL_DETAILS_ERROR });
  }
}

function* requestInvestorDealDetails(action) {
  try {
    let { data } = yield call(getInvestorDealDetailsApi, action?.data);

    if (data) {
      yield put({ type: INVESTOR_DEAL_DETAILS_SUCCESS, data });
    }
  } catch (err) {
    yield put({ type: INVESTOR_DEAL_DETAILS_ERROR });
  }
}

function* requestPublishDealApi(action) {
  try {
    let { data } = yield call(publishDealApi, action?.data);
    if (data.Msg) {
      yield put({ type: PUBLISH_DEAL_SUCCESS, data: data.Msg });
      message.success("Published successfully");
    }
  } catch (err) {
    yield put({ type: PUBLISH_DEAL_ERROR });
  }
}

function* requestUnPublishDealApi(action) {
  try {
    let { data } = yield call(unPublishDealApi, action?.data);
    if (data.Msg) {
      yield put({ type: UNPUBLISH_DEAL_SUCCESS, data: data.Msg });
      message.success("Unpublished successfully");
    }
  } catch (err) {
    yield put({ type: UNPUBLISH_DEAL_ERROR });
  }
}

function* requestAddComment(action) {
  try {
    let { data } = yield call(addCommentApi, action?.data);

    if (data) {
      yield put({ type: ADD_COMMENT_SUCCESS });
    }
  } catch (err) {
    yield put({ type: ADD_COMMENT_ERROR });
  }
}

function* requestInvestorDetails(action) {
  try {
    let { data } = yield call(investorDetailsApi, action?.data);

    if (data) {
      yield put({
        type: INVESTOR_DETAILS_SUCCESS,
        data: {
          [action?.data?.user_id]: data,
        },
      });
    }
  } catch ({ response }) {
    if (response) {
      yield put({ type: INVESTOR_DETAILS_ERROR, data: response?.data?.Error });
    } else {
      yield put({ type: INVESTOR_DETAILS_ERROR, data: "Error" });
    }
  }
}

function* requestPploadDocument(action) {
  try {
    let { data } = yield call(uploadDocumentApi, action?.data);
    if (data) {
      yield put({ type: UPLOAD_DOCUMENT_SUCCESS, data });
    }
  } catch ({ response }) {
    if (response) {
      yield put({ type: UPLOAD_DOCUMENT_ERROR, data: response?.data?.Error });
    } else {
      yield put({ type: UPLOAD_DOCUMENT_ERROR, data: "Error" });
    }
  }
}

function* requestUploadAnyDocuments(action) {
  try {
    let { data } = yield call(uploadAnyDocumentApi, action?.data);
    if (data) {
      yield put({
        type: UPLOAD_ANY_DOCUMENT_SUCCESS,
        data: { ...data?.documents[0], document_status: "not signed" },
      });
    }
  } catch ({ response }) {
    if (response) {
      yield put({
        type: UPLOAD_ANY_DOCUMENT_ERROR,
        data: response?.data?.Error,
      });
    } else {
      yield put({ type: UPLOAD_ANY_DOCUMENT_ERROR, data: "Error" });
    }
  }
}

function* requestUpdateAnyDocuments(action) {
  try {
    const resp = yield call(updateAnyDocumentApi, action?.data?.formData);

    if (resp.status === 200 || resp.status === 201) {
      yield put({
        type: GET_DATA_ROOM_REQUEST,
        data: {
          deal_id: action?.data?.dealId,
          message: resp?.data?.msg,
        },
      });
    }
  } catch ({ response }) {
    if (response?.data) {
      yield put({
        type: UPDATE_ANY_DOCUMENT_ERROR,
        data: response?.data?.Error || response?.data?.error,
      });
    } else {
      yield put({ type: UPDATE_ANY_DOCUMENT_ERROR, data: "Error" });
    }
  }
}

function* requestUploadDataRoomDocuments(action) {
  try {
    const resp = yield call(uploadAnyDocumentApi, action?.data?.formData);

    if (resp.status === 200 || resp.status === 201) {
      yield put({
        type: GET_DATA_ROOM_REQUEST,
        data: {
          deal_id: action?.data?.dealId,
        },
      });
    }
  } catch ({ response }) {
    if (response) {
      yield put({
        type: UPLOAD_DATA_ROOM_ERROR,
        data: response?.data?.error,
      });
    } else {
      yield put({ type: UPLOAD_DATA_ROOM_ERROR, data: "Error" });
    }
  }
}

function* requestDeleteAnyDocuments(action) {
  try {
    let resp = yield call(deleteAnyDocumentApi, action?.data?.formData);

    if (resp.status === 200 || resp.status === 201) {
      yield put({
        type: GET_DATA_ROOM_REQUEST,
        data: {
          deal_id: action?.data?.dealId,
        },
      });
    }
  } catch ({ response }) {
    if (response) {
      yield put({
        type: DELETE_ANY_DOCUMENT_ERROR,
        data: response?.data?.Error,
      });
    } else {
      yield put({ type: DELETE_ANY_DOCUMENT_ERROR, data: "Error" });
    }
  }
}

function* askPermissionToDocumentRequest(action) {
  try {
    const formData = action?.data?.formData;
    formData.append("deal_id", action?.data?.dealId);

    let resp = yield call(askPermissionApi, formData);

    if (resp.status === 200 || resp.status === 201) {
      yield put({
        type: GET_DATA_ROOM_REQUEST,
        data: {
          deal_id: action?.data?.dealId,
          message: resp?.data?.msg,
        },
      });
    }
  } catch ({ response }) {
    if (response) {
      yield put({
        type: ASK_PERMISSION_TO_DOCUMENT_ERROR,
        data: response?.data?.Error || response?.data?.error,
      });
    } else {
      yield put({ type: ASK_PERMISSION_TO_DOCUMENT_ERROR, data: "Error" });
    }
  }
}

function* getAskedPermissionToDocumentRequest({ data: { dealId = null } }) {
  try {
    let resp = yield call(getPermissionRequestApi, dealId);

    if (resp.status === 200 || resp.status === 201) {
      yield put({
        type: ASKED_PERMISSION_TO_DOCUMENTS_SUCCESS,
        data: resp?.data?.documents || [],
      });
    }
  } catch ({ response }) {
    if (response) {
      yield put({
        type: ASKED_PERMISSION_TO_DOCUMENTS_ERROR,
        data: response?.data?.Error || response?.data?.error,
      });
    } else {
      yield put({ type: ASKED_PERMISSION_TO_DOCUMENTS_ERROR, data: "Error" });
    }
  }
}

function* givePermissionToDocumentRequest(action) {
  try {
    let resp = yield call(givePermissionRequestApi, action.data);

    const add_document_id = action?.data?.get("add_document_id");
    const user_id = action?.data?.get("user_id");
    const document_id = action?.data?.get("document_id");

    const permissionsAsked = yield select(
      (state) => state?.dealDetails?.permissionsAsked
    );

    const newPermissions = permissionsAsked.filter((el) => {
      if (document_id) {
        if (Number(el?.user_id) === Number(user_id))
          return Number(el?.document_id) !== Number(document_id);
      }

      if (add_document_id) {
        if (Number(el?.user_id) === Number(user_id))
          return Number(el?.add_document_id) !== Number(add_document_id);
      }
    });

    if (resp.status === 200 || resp.status === 201) {
      yield put({
        type: GIVE_PERMISSION_TO_DOCUMENTS_SUCCESS,
        data: newPermissions,
      });
      message.success(resp?.data?.msg);
    }
  } catch ({ response }) {
    if (response) {
      yield put({
        type: GIVE_PERMISSION_TO_DOCUMENTS_ERROR,
        data: response?.data?.Error || response?.data?.error,
      });
    } else {
      yield put({ type: ASKED_PERMISSION_TO_DOCUMENTS_ERROR, data: "Error" });
    }
  }
}

function* requestChengeAllowDoc(action) {
  try {
    let resp = yield call(chengeAllowDocApi, action?.data);
    if (resp.status === 200 || resp.status === 201) {
      const document_id = action?.data?.get("document_id");
      const delete_flag = action?.data?.get("delete_flag") === "true";

      const userDocuments = yield select(
        (state) => state?.dealDetails?.userDocuments
      );

      const index = userDocuments.findIndex(
        (el) => el.document_id === Number(document_id)
      );

      userDocuments[index].delete_flag = delete_flag;

      yield put({
        type: GET_USER_DOCUMENTS_SUCCESS,
        data: [...userDocuments],
      });
    }
  } catch ({ response }) {
    if (response) {
      yield put({
        type: CHENGE_ALLOW_DOC_ERROR,
        data: response?.data?.Error,
      });
    } else {
      yield put({ type: CHENGE_ALLOW_DOC_ERROR, data: "Error" });
    }
  }
}

function* requestSignAnyDocuments(action) {
  try {
    let { data } = yield call(signAnyDocumentApi, action?.data);
    const document_id = action?.data?.get("document_id");

    if (data) {
      yield put({
        type: SIGN_ANY_DOCUMENT_SUCCESS,
        data: { ...data, id: Number(document_id) },
      });
    }
  } catch ({ response }) {
    if (response) {
      yield put({
        type: SIGN_ANY_DOCUMENT_ERROR,
        data: response?.data?.Error,
      });
    } else {
      yield put({ type: SIGN_ANY_DOCUMENT_ERROR, data: "Error" });
    }
  }
}

function* uploadSignedAnyDocuments() {
  try {
    const dealDetails = yield select((state) => state?.dealDetails);
    const formData = new FormData();
    formData.append("envelope_id", dealDetails[dealDetails.pending]);
    formData.append("document_id", dealDetails.pending);

    let resp = yield call(uploadSignedAnyDocumentsApi, formData);

    if (resp.status === 200 || resp.status === 201) {
      yield put({
        type: UPLOAD_SIGN_ANY_DOCS_SUCCESS,
      });
    }
  } catch ({ response }) {
    if (response) {
      yield put({
        type: UPLOAD_SIGN_ANY_DOCS_ERROR,
        data: response?.data?.Error,
      });
    } else {
      yield put({ type: UPLOAD_SIGN_ANY_DOCS_ERROR, data: "Error" });
    }
  }
}

function* requestUserDocuments(action) {
  try {
    const resp = yield call(getUserDocumentsApi, action?.data);

    if (resp.status === 200 || resp.status === 201) {
      yield put({ type: GET_USER_DOCUMENTS_SUCCESS, data: resp?.data });
    }
  } catch ({ response }) {
    if (response) {
      yield put({
        type: GET_USER_DOCUMENTS_ERROR,
        data: response?.data?.Error,
      });
    } else {
      yield put({ type: GET_USER_DOCUMENTS_ERROR, data: "Error" });
    }
  }
}

function* requestDataRoomDocuments(action) {
  try {
    const resp = yield call(getDataRoomApi, action?.data);

    if (resp.status === 200 || resp.status === 201) {
      yield put({ type: GET_DATA_ROOM_SUCCESS, data: resp?.data });
      if (action?.data?.message) {
        message.success(action?.data?.message);
      }
    }
  } catch ({ response }) {
    if (response) {
      yield put({
        type: GET_DATA_ROOM_ERROR,
        data: response?.data?.error,
      });
    } else {
      yield put({ type: GET_USER_DOCUMENTS_ERROR, data: "Error" });
    }
  }
}

function* DealDetailsWatcher() {
  yield takeLatest(ORGANIZER_DEAL_DETAILS_REQUEST, requestOrganizerDealDetails);
  yield takeLatest(INVESTOR_DEAL_DETAILS_REQUEST, requestInvestorDealDetails);
  yield takeLatest(PUBLISH_DEAL_REQUEST, requestPublishDealApi);
  yield takeLatest(UNPUBLISH_DEAL_REQUEST, requestUnPublishDealApi);
  yield takeLatest(ADD_COMMENT_REQUEST, requestAddComment);
  yield takeLatest(UPLOAD_SIGN_ANY_DOCS_REQUEST, uploadSignedAnyDocuments);
  yield takeLatest(SIGN_ANY_DOCUMENT_REQUEST, requestSignAnyDocuments);
  yield takeLatest(DELETE_ANY_DOCUMENT_REQUEST, requestDeleteAnyDocuments);
  yield takeLatest(CHENGE_ALLOW_DOC_REQUEST, requestChengeAllowDoc);
  yield takeLatest(UPLOAD_DOCUMENT_REQUEST, requestPploadDocument);
  yield takeLatest(INVESTOR_DETAILS_REQUEST, requestInvestorDetails);
  yield takeLatest(UPLOAD_ANY_DOCUMENT_REQUEST, requestUploadAnyDocuments);
  yield takeLatest(GET_USER_DOCUMENTS_REQUEST, requestUserDocuments);
  yield takeLatest(UPLOAD_DATA_ROOM_REQUEST, requestUploadDataRoomDocuments);
  yield takeLatest(GET_DATA_ROOM_REQUEST, requestDataRoomDocuments);
  yield takeLatest(UPDATE_ANY_DOCUMENT_REQUEST, requestUpdateAnyDocuments);
  yield takeLatest(
    ASK_PERMISSION_TO_DOCUMENT_REQUEST,
    askPermissionToDocumentRequest
  );
  yield takeLatest(
    ASKED_PERMISSION_TO_DOCUMENTS_REQUEST,
    getAskedPermissionToDocumentRequest
  );

  yield takeLatest(
    GIVE_PERMISSION_TO_DOCUMENTS_REQUEST,
    givePermissionToDocumentRequest
  );
}
export default DealDetailsWatcher;
