import { call, put, select, takeLatest } from "redux-saga/effects";
import { getNotifications, deleteNotification } from "../../../api";
import {
    GET_NOTIFICATIONS_REQUEST,
    GET_NOTIFICATIONS_SUCCESS,
    GET_NOTIFICATIONS_ERROR,
    DELETE_NOTIFICATIONS_REQUEST,
    DELETE_NOTIFICATIONS_SUCCESS,
    DELETE_NOTIFICATIONS_ERROR,
} from "./constants";

function* getNotificationsRequest(action) {
  try {
    let { data } = yield call(getNotifications, action?.data);
    if (data) {
      yield put({ type: GET_NOTIFICATIONS_SUCCESS, data });
    }
  } catch (err) {
    yield put({ type: GET_NOTIFICATIONS_ERROR });
  }
}

function* deleteNotificationsRequest(action) {
  try {
    let { data } = yield call(deleteNotification, action?.data);
    const notificationsData = yield select((state) => state.notificationsData.notificationsList);
    const index = notificationsData.findIndex(
      (el) => el.id === action?.data
    );
    if(index > -1) {
      notificationsData.splice(index, 1);
    }  
    if (data.Msg) {
      yield put({ type: DELETE_NOTIFICATIONS_SUCCESS, data: notificationsData});
    }
  } catch (err) {
    yield put({ type: DELETE_NOTIFICATIONS_ERROR });
  }
}

function* NotificationsWatcher() {
  yield takeLatest(GET_NOTIFICATIONS_REQUEST, getNotificationsRequest);
  yield takeLatest(DELETE_NOTIFICATIONS_REQUEST, deleteNotificationsRequest);
}

export default NotificationsWatcher;