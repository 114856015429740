/* eslint-disable react/jsx-no-target-blank */
import { Table } from "antd";
import React from "react";

const transactionColumns = [
  {
    title: "Date",
    dataIndex: "transaction_date",
  },
  {
    title: "Owner",
    dataIndex: "owner",
  },
  {
    title: "Amount",
    dataIndex: "amount",
  },
];

const TransactionTable = ({ data }) => {
  return (
    <Table
      rowClassName={() => "editable-row"}
      pagination={false}
      dataSource={data}
      size="small"
      columns={transactionColumns}
    />
  );
};

export default TransactionTable;
