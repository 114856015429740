import React from "react";
import {
  Avatar,
  Button,
  Col,
  Row,
  Space,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { createChatAction } from "../../chatPage/redux/action";
import { getFirstLater, getImageURL } from "../../../Utils";

const { Text, Title } = Typography;

const colors = [
  "magenta",
  "volcano",
  "orange",
  "gold",
  "lime",
  "green",
  "cyan",
  "blue",
  "geekblue",
  "purple",
];

function IPDetailHeader({ ipDetail = {}, userRole }) {
  const dispatch = useDispatch();
  const { ip_logo, title, sector, description, service_organizer } = ipDetail;

  const history = useNavigate();

  const SectorTags = () => (
    <div className="top-title_sector">
      {sector?.map((el, index) => {
        return <Tag color={colors[index]}>{el.sector_name}</Tag>;
      })}
    </div>
  );

  const handleChatClick = () => {
    const formData = new FormData();
    formData.append("user_ids", JSON.stringify([service_organizer?.user_id]));

    dispatch(createChatAction(formData));

    history("/chat");
  };

  return (
    <>
      <Row justify="space-between" align="middle" gutter={[0, 0]} className="deal-details-header-row">
        <Col md={18} sm={18} xs={24}>
          <Avatar.Group>
            <Avatar
              size={60}
              shape="square"
              src={getImageURL(ip_logo || "")}
              className={`${!ip_logo ? "shape-avatar-letter" : ""} deal-logo`}
            >
              {getFirstLater(title || "")}
            </Avatar>
            <div style={{ width: "100%" }}>
              <Row align="middle" gutter={[0, 0]}>
                <Col md={24} sm={24} display={"flex"} className="top-title">
                  <Tooltip title={title}>
                    <Title
                      level={4}
                      className="top-title_title"
                      ellipsis={true}
                      style={{ maxWidth: "250px" }}
                    >
                      {title}
                    </Title>
                  </Tooltip>

                  <SectorTags />
                </Col>
              </Row>
              <Tooltip title={description}>
                <Text
                  className="description"
                  ellipsis={true}
                  style={{ width: "200px" }}
                >
                  {description}
                </Text>
              </Tooltip>
            </div>
          </Avatar.Group>
        </Col>
        <Col
          md={6}
          sm={6}
          xs={24}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: `flex-end`,
          }}
        >
          <Space className="header-btns">
            <Button onClick={handleChatClick} type="primary">
              Message
            </Button>
          </Space>
        </Col>
      </Row>
    </>
  );
}

export default IPDetailHeader;
