import {
    Card,
    Tag,
    Image,
    Modal,
    Button,
    notification,

} from "antd";
import Title from "antd/lib/typography/Title";
import React, { useState } from "react";


import Paragraph from "antd/lib/typography/Paragraph";

import {
    LoadingOutlined,
    LockOutlined,
    UnlockOutlined,
    StarFilled
} from "@ant-design/icons";
import "./style.scss";
import { useLocation, useNavigate } from "react-router-dom";
import FallbackImg from "../../assets/images/default-fallback-image.png";
import { BASE_URL, requestAccessToProjectFiles as requestAccessProject } from "../../api";
import { getUser } from "../../Utils/isAuth";


const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;

const colors = {
    Partnerships: "gold",
    "Whitelabel Solutions": "lime",
    "Investable Projects": "green",
    "Business Cooperation": "cyan",
    "Analytical Dashboards": "magenta",
    "Ecosystems As A Service": "volcano",
    "DeepTech Projects": "orange",
    "Philanthropy Projects": "blue",
    "Thematic Projects": "geekblue",
    "SaaS Projects": "purple",
    //"BioMed Projects": "purple",
    "AI Industry Projects": "magenta",
    "GovTech Projects": "green",
    "Longevity Projects": "lime",
    "Life Sciences Projects": "volcano",
};


const ProjectCard = ({ record, index }) => {
    const navigate = useNavigate();
    const { _id: userId, roles: userRole, email } = getUser()
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const pathname = useLocation();

    const SectorTags = (sector) => {
        let sectors;
        try {
            sectors = JSON.parse(sector);
        } catch (error) {
            sectors = [sector];
        }
        return sectors.map((sector, index) => (
            <span className="top-title_sector" key={sector}>
                <Tag color={colors[sector]}>
                    {sector === "Investable Projects"
                        ? sector
                        : sector?.split("Projects")[0]}
                </Tag>
            </span>
        ));
    };

    const handleCardClick = () => {
        if (userHasAccessProject(record)) {
            if (pathname.pathname === "/"){
                navigate(`/project/${index}?projectId=${record?._id}`, { state: { projectId: record?._id } });
            }else{
                window.location.href = `/project/${index}?projectId=${record?._id}`
            }
        } else {
            setIsModalVisible(true);
        }
    };

    const handleSubmitModal = async () => {
        const data = { projectId: record?._id, userId: userId, accessType: "project" }
        try {
            setLoading(true)
            // eslint-disable-next-line no-unused-vars
            const response = await requestAccessProject(data) // changed name for more context
            setLoading(false)
            successSubmission() // Success modal
            setIsModalVisible(false);
        } catch (err) {
            notification.error({
                message: `Something went wrong - ${err.message}`
            })
        }

    };

    const userHasAccessProject = (record) => {
        const emailPattern = /^[a-zA-Z0-9._%+-]+@dkv\.global$/; // user email must match @dkv.global
        return (record?.hasAccess || (userRole === "Admin" || userRole === "Partner" || emailPattern.test(email)))
    }

    const handleCloseModal = (e) => {
        e.stopPropagation();
        setIsModalVisible(false);
    };
    const successSubmission = () => {
        Modal.success({
            title: 'Request Submitted Successfully',
            content: (
                <div>
                    <p>Your access request has been sent. We will notify you once it has been reviewed.</p>
                </div>
            ),
            onOk() { },
        });
    };

    return (
        <Card
            className="marketplace flex-1"

            onClick={handleCardClick}
            cover={
                <div className="card-cover-img">
                    <div
                        style={{
                            overflow: "hidden",
                            height: "220px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <Image
                            className="cover-img"
                            alt="example"
                            src={
                                BASE_URL +
                                record?.imagesPath +
                                record?.links?.find((item) => item.type === "background")
                                    ?.link || FallbackImg
                            }
                            fallback={FallbackImg}
                            preview={false}
                            loading="lazy"
                        />
                    </div>
                </div>
            }
        >
            <div className="marketplace-body">
                <Title
                    level={4}
                    className="marketplace-body__title"
                    // ellipsis={{ tooltip: record?.projectName }}
                    style={{ width: "90%" }}
                >
                    {record?.projectName}
                </Title>
                <Paragraph
                    className="marketplace-body__description"
                    ellipsis={{ rows: 3, tooltip: record?.description }}
                >
                    <p dangerouslySetInnerHTML={{ __html: record?.description }}></p>
                </Paragraph>
                <div className="sector" style={{ marginBottom: "15px" }}>
                    {SectorTags(record?.sector)}
                    <div style={{ position: "absolute", bottom: 10, right: 10 }}>
                        {
                                userHasAccessProject(record) ?
                                <UnlockOutlined style={{ fontSize: 24, color: "green" }} />
                                :
                                <LockOutlined style={{ fontSize: 24, color: "red" }} />
                        }
                        {record?.pinned && (
                        <StarFilled style={{ fontSize: 24, color: '#fadb14', marginLeft:"0.5rem" }} fill="'#fadb14'" />
                    )}
                    </div>
                </div>

                {/* Modal content */}
                <Modal
                    title={"Request for Access to this Project"}
                    open={isModalVisible}
                    onCancel={(e) => handleCloseModal(e)}
                    footer={
                        <>
                            <Button key="back" onClick={(e) => handleCloseModal(e)}>
                                Cancel
                            </Button>
                            <Button key="submit" type="primary" loading={loading} onClick={handleSubmitModal}>
                                Submit
                            </Button>
                        </>

                    }
                    cancelText="Cancel"
                >
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <div
                            style={{
                                overflow: "hidden",
                                height: "220px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <Image
                                className="cover-img"
                                alt="example"
                                src={
                                    BASE_URL +
                                    record?.imagesPath +
                                    record?.links?.find((item) => item.type === "background")
                                        ?.link || FallbackImg
                                }
                                fallback={FallbackImg}
                                preview={false}
                            />
                        </div>

                        <div style={{ marginLeft: 10, marginTop: 5 }}>
                            <p>{record?.projectName}</p>
                            <p dangerouslySetInnerHTML={{ __html: record?.description }}></p>
                        </div>
                    </div>
                </Modal>

            </div>

        </Card>
        )

}

export default ProjectCard