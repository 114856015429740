import { message } from "antd";
import { call, put, takeLatest } from "redux-saga/effects";
import { dealTypeDetailsApi } from "../../../../api";

import {
  CREATE_DEALTYPE_INFO_REQUEST,
  CREATE_DEALTYPE_INFO_SUCCESS,
  CREATE_DEALTYPE_INFO_ERROR,
} from "./constants";

function* dealTypeInfo(action) {
  try {
    let { data } = yield call(dealTypeDetailsApi, action?.data);

    if (data) {
      yield put({ type: CREATE_DEALTYPE_INFO_SUCCESS, data: data?.Msg });
    }

    if(data){
      message.success(data?.Msg)
    }
  } catch (err) {
    yield put({ type: CREATE_DEALTYPE_INFO_ERROR });
  }
}

function* dealTypeInfoWatcher() {
  yield takeLatest(CREATE_DEALTYPE_INFO_REQUEST, dealTypeInfo);
}

export default dealTypeInfoWatcher;
