import {
  UPDATE_MODAL_STATE,
  GET_USERS_LIST_REQUEST,
  GET_USER_ROLES_REQUEST,
  UPDATE_USER_ROLES_REQUEST,
  UPDATE_USER_ACCOUNT_STATUS_REQUEST,
  GET_USER_DETAIL_BY_ID_REQUEST,
  SET_EDIT_USER_ID,
  SET_IS_SKIPPED
} from "./constants";

export const updateModalState = (data) => {
  return {
    type: UPDATE_MODAL_STATE,
    data,
  };
};

export const getUsersList = () => {
  return {
    type: GET_USERS_LIST_REQUEST,
  };
};

export const getUsersRolesList = () => {
  return {
    type: GET_USER_ROLES_REQUEST,
  };
};

export const updateUserRole = (role) => {
  return {
    type: UPDATE_USER_ROLES_REQUEST,
    data: role,
  };
};

export const updateUserStatus = (status) => {
  return {
    type: UPDATE_USER_ACCOUNT_STATUS_REQUEST,
    data: status,
  };
};

export const userDetailByIdAction = (data) => ({
  type: GET_USER_DETAIL_BY_ID_REQUEST,
  data,
});

export const setEditUserId = (id) => ({
  type: SET_EDIT_USER_ID,
  data: id,
});
export const setIsSkiped = (data) => ({
  type: SET_IS_SKIPPED,
  data
});
