import { Col, Row } from "antd";
import React from "react";

import TransactionTable from "./TransactionTable";

const TransactionTab = ({ data }) => {
  return (
    <>
      <Row gutter={[24, 0]}>
        <Col span={24} style={{ marginBottom: "20px" }}>
          <h2 className="financial_title">Transaction list</h2>
        </Col>

        <Col span={24} style={{ marginBottom: "20px" }}>
          <TransactionTable data={data} />
        </Col>
      </Row>
    </>
  );
};

export default TransactionTab;
