import { message } from "antd";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  dealInvest,
  getDealInvesting,
  signInvestDoc,
  getIPInvest,
  createInvest,
  getEscrowDataAPI,
  creatEscrowDataAPI,
} from "../../../api";

import {
  SIGN_INVEST_DOCS_OPEN_REQUEST,
  SIGN_INVEST_DOCS_OPEN_SUCCESS,
  SIGN_INVEST_DOCS_OPEN_ERROR,
  GET_DEAL_INVESTING_ERROR,
  GET_DEAL_INVESTING_REQUEST,
  GET_DEAL_INVESTING_SUCCESS,
  INVEST_DEAL_REQUEST,
  INVEST_DEAL_SUCCESS,
  INVEST_DEAL_ERROR,
  IP_INVEST_ERROR,
  IP_INVEST_REQUEST,
  IP_INVEST_SUCCESS,
  CREATE_IP_INVEST_REQUEST,
  CREATE_IP_INVEST_ERROR,
  CREATE_IP_INVEST_SUCCESS,
  GET_ESCROW_REQUEST,
  CREATE_ESCROW_REQUEST,
  CREATE_ESCROW_SUCCESS,
  CREATE_ESCROW_ERROR,
} from "./constants";

function* requestDealInvesting(action) {
  try {
    const { data } = yield call(getDealInvesting, action.data);

    if (data) {
      yield put({
        type: GET_DEAL_INVESTING_SUCCESS,
        data: {
          dealInvesting: data,
          deal_id: action.data.deal_id,
        },
      });
    }
  } catch (err) {
    yield put({ type: GET_DEAL_INVESTING_ERROR });
  }
}

function* requestInvestDeal(action) {
  try {
    let { data } = yield call(dealInvest, action.data);

    if (data) {
      yield put({
        type: INVEST_DEAL_SUCCESS,
      });
    }
  } catch ({ response }) {
    if (response) {
      yield put({ type: INVEST_DEAL_ERROR, data: response?.data?.Msg });
    } else {
      yield put({ type: INVEST_DEAL_ERROR, data: "Error" });
    }
  }
}

function* requestEscrowData(action) {
  try {
    console.log(action, 89879878);
    let { data } = yield call(getEscrowDataAPI, action.data);

    console.log(data);

    // if (data) {
    //   yield put({
    //     type: INVEST_DEAL_SUCCESS,
    //   });
    // }
  } catch ({ response }) {
    if (response) {
      yield put({ type: INVEST_DEAL_ERROR, data: response?.data?.Msg });
    } else {
      yield put({ type: INVEST_DEAL_ERROR, data: "Error" });
    }
  }
}

function* createEscrowData(action) {
  try {
    let { data } = yield call(creatEscrowDataAPI, action.data);

    if (data) {
      message.success(data?.Msg);
      yield put({
        type: CREATE_ESCROW_SUCCESS,
        data: data?.Msg,
      });
    }
  } catch ({ response }) {
    if (response?.data?.error) {
      yield put({ type: CREATE_ESCROW_ERROR, data: response?.data?.error });
    } else {
      yield put({ type: CREATE_ESCROW_ERROR, data: "Error" });
    }
  }
}

function* requestSignDocDocs(action) {
  try {
    let { data } = yield call(signInvestDoc, action?.data?.formData);
    if (data) {
      yield put({
        type: SIGN_INVEST_DOCS_OPEN_SUCCESS,
        data: {
          ...data,
          key: action?.data?.key,
        },
      });
    }
  } catch (err) {
    yield put({ type: SIGN_INVEST_DOCS_OPEN_ERROR });
  }
}

function* requestIPDetails(action) {
  try {
    let { data } = yield call(getIPInvest, action.id);

    if (data) {
      yield put({
        type: IP_INVEST_SUCCESS,
        data,
      });
    }
  } catch ({ response }) {
    if (response) {
      yield put({
        type: IP_INVEST_ERROR,
        data: response?.data?.Msg,
      });
    } else {
      yield put({ type: IP_INVEST_ERROR, data: "Error" });
    }
  }
}

function* requestInvestIP(action) {
  try {
    let { data } = yield call(createInvest, action.data);
    if (data.Msg) {
      yield put({
        type: CREATE_IP_INVEST_SUCCESS,
        data: data.Msg,
      });
    }
  } catch ({ response }) {
    if (response) {
      yield put({ type: CREATE_IP_INVEST_ERROR, data: response?.data?.Msg });
    } else {
      yield put({ type: CREATE_IP_INVEST_ERROR, data: "Error" });
    }
  }
}

function* InvestWatcher() {
  yield takeLatest(SIGN_INVEST_DOCS_OPEN_REQUEST, requestSignDocDocs);
  yield takeLatest(GET_DEAL_INVESTING_REQUEST, requestDealInvesting);
  yield takeLatest(INVEST_DEAL_REQUEST, requestInvestDeal);
  yield takeLatest(IP_INVEST_REQUEST, requestIPDetails);
  yield takeLatest(CREATE_IP_INVEST_REQUEST, requestInvestIP);
  yield takeLatest(GET_ESCROW_REQUEST, requestEscrowData);
  yield takeLatest(CREATE_ESCROW_REQUEST, createEscrowData);
}
export default InvestWatcher;
