import React from "react";
import { Col, Row, Tabs } from "antd";

import OverviewTab from "./tabs/overviewTab/OverviewTab";
import DetailsTab from "./tabs/detailsTab/DetailsTab";
import AnalyticsTab from "./tabs/analyticsTab/AnalyticsTab";

import TransactionTab from "./tabs/transactionTab/TransactionTab";
import InvestorsTab from "./tabs/investorsTab/InvestorsTab";
import { getUser } from "../../Utils/isAuth";

import DataRoomTab from "./tabs/dataRoom/DataRoomTab";
import TeamTab from "./tabs/teamTab/TeamTab";
import CommentsTab from "./tabs/commentsTab/CommentsTab";
import { isStartup } from "../../Utils";

const { TabPane } = Tabs;

function OrganizerDealDetail({ dealDetail, userRole, id }) {
  const user = getUser();

  return (
    <>
      <Row gutter={[24, 0]}>
        <Col sx={24} md={24} lg={24} xl={24}>
          <Tabs
            defaultActiveKey="2"
            className="profile-tab-custom"
            destroyInactiveTabPane={true}
          >
            <TabPane tab="Overview" key="1">
              <OverviewTab data={dealDetail} />
            </TabPane>
            <TabPane tab="Details" key="2">
              <DetailsTab data={dealDetail?.deal_info} />
            </TabPane>

            <TabPane tab="Investors" key="3">
              <InvestorsTab data={dealDetail} />
            </TabPane>

            <TabPane tab="Team" key="4">
              <TeamTab data={dealDetail?.deal_info?.team} />
            </TabPane>
{/* 
            <TabPane tab="Discussions" key="6">
              <CommentsTab data={dealDetail?.deal_info} />
            </TabPane> */}

            <TabPane tab="Transactions" key="5">
              <TransactionTab data={dealDetail?.transaction_info} />
            </TabPane>

            <TabPane tab="Data Room" key="11">
              <DataRoomTab
                data={dealDetail?.deal_info}
                userRole={userRole}
                destroyInactiveTabPane={true}
              />
            </TabPane>
            {isStartup() &&
              user?.user_id === dealDetail?.deal_info?.organizer?.id && (
                <TabPane tab="Analytics" key="7">
                  <AnalyticsTab data={dealDetail} />
                </TabPane>
              )}
          </Tabs>
        </Col>
      </Row>
    </>
  );
}

export default OrganizerDealDetail;
