import {
  GET_FACILITATORS_LIST_REQUEST,
  GET_FACILITATORS_SERVICES_REQUEST,
  SET_FACILITATORS_SERVICES_REQUEST,
  UPDATE_FACILITATOR_STATUS_REQUEST,
} from "./constants";

export const getFacilitatorList = () => ({
  type: GET_FACILITATORS_LIST_REQUEST,
});

export const getFacilitatorServices = () => ({
  type: GET_FACILITATORS_SERVICES_REQUEST,
});

export const setFacilitatorServices = (services) => ({
  type: SET_FACILITATORS_SERVICES_REQUEST,
  data: services,
});

export const updateFacilitatorsStatusRequest = (data) => {
  return {
    type: UPDATE_FACILITATOR_STATUS_REQUEST,
    data,
  };
};