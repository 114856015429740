import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CommonTable from "../common/commonTable";
import { getAdminIpsListRequest } from "./redux/action";

import "../adminDeals/style.scss";

const AdminIps = ({ columns, deviceColumns }) => {
  const adminIpsListState = useSelector((state) => state.adminIpsList);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAdminIpsListRequest());
  }, [dispatch]);

  const adminIpsKeys = [
    "All",
    "In Review",
    "Compliance Check",
    "Active",
    "In Progress",
    "Completed",
  ];

  return (
    <div className="admin-deals">
      <CommonTable
        columns={columns}
        deviceColumns={deviceColumns}
        allData={adminIpsListState}
        isIp={true}
        keys={adminIpsKeys}
      />
    </div>
  );
};

export default AdminIps;
