import {
  GET_FACILITATORS_LIST_REQUEST,
  GET_FACILITATORS_LIST_SUCCESS,
  GET_FACILITATORS_LIST_ERROR,
  GET_FACILITATORS_SERVICES_REQUEST,
  GET_FACILITATORS_SERVICES_SUCCESS,
  GET_FACILITATORS_SERVICES_ERROR,
  SET_FACILITATORS_SERVICES_REQUEST,
  SET_FACILITATORS_SERVICES_SUCCESS,
  SET_FACILITATORS_SERVICES_ERROR,
  UPDATE_FACILITATOR_STATUS_ERROR,
  UPDATE_FACILITATOR_STATUS_REQUEST,
} from "./constants";
const initialState = {
  facilitatorList: [],
  facilitatorServices: [],
  goToMarketPlace: false,
  loading: false,
  errorMessage: "",
};

export default function facilitatorsPage(state = initialState, action) {
  switch (action.type) {
    // GETTING FACILITATORS DATA
    case GET_FACILITATORS_LIST_REQUEST:
      return { ...state, loading: true };
    case GET_FACILITATORS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        facilitatorList: action?.data,
      };
    case GET_FACILITATORS_LIST_ERROR:
      return { ...state, loading: false, errorMessage: action.data };
      // GETTING FACILITATOR SERVICES
    case GET_FACILITATORS_SERVICES_REQUEST:
      return { ...state, loading: true };
    case GET_FACILITATORS_SERVICES_SUCCESS:
      return {
        ...state,
        loading: false,
        facilitatorServices: action?.data,
      };
    case GET_FACILITATORS_SERVICES_ERROR:
      return { ...state, loading: false, errorMessage: action.data };
      // SETTING FACILITATOR SERVICES
    case SET_FACILITATORS_SERVICES_REQUEST:
      return { ...state, loading: true };
    case SET_FACILITATORS_SERVICES_SUCCESS:
      return {
        ...state,
        loading: false,
        goToMarketPlace: true,
      };
    case SET_FACILITATORS_SERVICES_ERROR:
      return { ...state, loading: false, errorMessage: action.data };
      case UPDATE_FACILITATOR_STATUS_REQUEST:
      return { ...state, loading: true };
    case UPDATE_FACILITATOR_STATUS_ERROR:
      return { ...state, data: action.data, loading: false };

    default:
      return state;
  }
}
