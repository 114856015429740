export const swotChartOption = {
  title: {
    text: "Basic Radar Chart",
  },
  xaxis: {
    categories: ["January", "February", "March", "April", "May", "June"],
  },
};

export const getTitleForDetails = (key) => {
  switch (key) {
    case "c_em":
      return "EMAIL";
    case "link":
      return "WEBSITE";
    case "city":
      return "CITY";
    case "country":
      return "COUNTRY";
    case "ipo_status":
      return "IPO STATUS";
    case "year":
      return "YEAR FOUNDED";
    case "region":
      return "REGION";
    case "title":
      return "COMPANY NAME";
    case "pips":
      return "NUMBER OF PIPELINES";
    case "p_peer":
      return "PEER-REVIEWED";
    case "staff":
      return "ESTIMATED NUMBER OF EMPLOYEES";
    case "comment":
      return "COMMENT";
    case "c_tw":
      return "TWITTER PAGE";
    case "c_li":
      return "LINKEDIN PAGE";
    case "fund_total":
      return "TOTAL FUND";
    case "fund_last":
      return "LAST FUND";
    case "fund_rev":
      return "REV FUND";
    case "financial_short_id":
      return "FINANCIAL SHORT ID";
    case "financial_full_id":
      return "FINANCIAL FULL ID";
    default:
      return false;
  }
};

export const getValueForDetailsFromKey = (key, value) => {
  switch (key) {
    case "c_tw":
    case "c_li":
    case "link":
      return (
        <a href={value} target="_blank" rel="noreferrer">
          {value}
        </a>
      );
    case "staff":
      let temp = value.slice(1).split("_");
      return `${parseInt(temp[1], 10)} - ${parseInt(temp[2], 10)}`;
    default:
      return false;
  }
};
