import React from "react";
import { Avatar, Col, Row, Steps } from "antd";
import LinePlot from "./LinePlot";
import { getFirstLater, getImageURL } from "../../../../Utils";
import { mockDeal } from "../../../dealDetail/components/mock";

const { Step } = Steps;

const OverviewTab = ({ data }) => {
  const { activity = [] } = data;

  const checkIpType =
    data?.ip_info?.is_register_ip || data?.ips?.is_register_ip;
  return (
    <div className="deal-details-tab">
      {!checkIpType && (
        <Row gutter={[24, 0]}>
          <Col span={24} style={{ marginBottom: "20px" }}>
            <h2 className="financial_title">Investment Dynamics</h2>
          </Col>
          <Col span={24} style={{ marginBottom: "20px" }}>
            <LinePlot data={data?.investment_dynamics} />
          </Col>
        </Row>
      )}
      <Row gutter={[24, 0]}>
        <Col span={24} style={{ marginBottom: "20px" }}>
          <h2 className="financial_title">Activity</h2>
        </Col>

        <Col span={24} style={{ marginBottom: "20px" }}>
          <Steps current={activity?.length} direction="vertical">
            {activity.map((el) => {
              return (
                <Step
                  title={el.investor}
                  description={el.activity}
                  subTitle={el.date}
                  icon={
                    <Avatar
                      size={40}
                      shape="circle"
                      src={getImageURL(mockDeal.photo || "")}
                      className={`${
                        !mockDeal.photo ? "shape-avatar-letter" : ""
                      } team-card__avatar`}
                    >
                      {getFirstLater(el.investor || "")}
                    </Avatar>
                  }
                />
              );
            })}
          </Steps>
        </Col>
      </Row>
    </div>
  );
};

export default OverviewTab;
