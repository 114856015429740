export const SERVICE_ORGANIZER_DEAL_LIST_REQUEST =
  "SERVICE_ORGANIZER_DEAL_LIST_REQUEST";
export const SERVICE_ORGANIZER_DEAL_LIST_SUCCESS =
  "SERVICE_ORGANIZER_DEAL_LIST_SUCCESS";
export const SERVICE_ORGANIZER_DEAL_LIST_ERROR =
  "SERVICE_ORGANIZER_DEAL_LIST_ERROR";

export const UPDATE_SERVICE_ORGANIZER_DEAL_STATUS_REQUEST =
  "UPDATE_SERVICE_ORGANIZER_DEAL_STATUS_REQUEST";
export const UPDATE_SERVICE_ORGANIZER_DEAL_STATUS_SUCCESS =
  "UPDATE_SERVICE_ORGANIZER_DEAL_STATUS_SUCCESS";
export const UPDATE_SERVICE_ORGANIZER_DEAL_STATUS_ERROR =
  "UPDATE_SERVICE_ORGANIZER_DEAL_STATUS_ERROR";
