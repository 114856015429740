import { call, put, takeLatest } from "redux-saga/effects";

import { getServiceMarketplaceListApi } from "../../../api";

import {
  SERVICE_MARKETPLACE_LIST_ERROR,
  SERVICE_MARKETPLACE_LIST_REQUEST,
  SERVICE_MARKETPLACE_LIST_SUCCESS,
} from "./constants";

function* requestServiceMarketplaceList(action) {
  try {
    let { data } = yield call(getServiceMarketplaceListApi, action?.data);

    if (data) {
      data.sort((a, b) =>  String(a.name).toLocaleLowerCase().localeCompare(String(b.name).toLocaleLowerCase()))
      yield put({ type: SERVICE_MARKETPLACE_LIST_SUCCESS, data });
    }
  } catch (err) {
    yield put({ type: SERVICE_MARKETPLACE_LIST_ERROR });
  }
}

function* ServiceMarketplaceListWatcher() {
  yield takeLatest(
    SERVICE_MARKETPLACE_LIST_REQUEST,
    requestServiceMarketplaceList
  );
}
export default ServiceMarketplaceListWatcher;
