import React, { useEffect, useState } from "react";
import { Row, Col, Modal } from "antd";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ExactPage } from "../../redux/auth/action";
import SignIn from "./SignIn";
import SignUp from "./SignUp";
import Logo from "../../../src/assets/images/main-logo-svg.svg";
import EmailVerify from "./EmailVerify";
import ForgotPassword from "./ForgotPassword";
import CreatePassword from "./CreatePassword";
import AccountType from "./AccountType";
import RegistrationLeftBar from "../../components/RegistrationLeftBar";
import WebsiteLogo from "../../components/RegistrationLeftBar/WebsiteLogo";
import ExclamationCircleOutlined from "@ant-design/icons/ExclamationCircleOutlined";
import ResetPassword from "./ResetPassword";
import { getToken } from "../../Utils/isAuth";

const { error } = Modal;

export const AuthContext = React.createContext();

const showError = (errorMessage) => {
  error({
    title: "Error",
    content: errorMessage,
    icon: <ExclamationCircleOutlined />,
    centered: true,

    onOk() {
      console.log("OK");
    },
  });
};

const Registration = ({ isAuthDone }) => {
  const [isPanelRightActive, setIsPanelRightActive] = useState(false);
  const authDetails = useSelector((state) => state.auth);

  const { current, error } = authDetails;
  const [progress, setProgress] = React.useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (error) {
      showError(JSON.stringify(error));
    }
  }, [error]);

  useEffect(() => {
    const token = getToken();
    if (!isAuthDone) {
      //navigate("/home");
      navigate("/login");
    }
    if (token) {
      navigate("/");
    }
  }, []);

  const next = () => {
    dispatch(ExactPage(parseInt(current) + 1));
    setProgress(progress + 30);
  };

  const steps = [
    // {
    //   content: <SignUp />,
    // },
    // {
    //   content: <EmailVerify />,
    // },
    // {
    //   content: <AccountType />,
    // },
    {
      content: <SignIn next={() => next()} />,
    },
    {
      content: <ForgotPassword />,
    },
    {
      content: <ResetPassword />,
    },
    {
      content: <CreatePassword />,
    },
  ];

  const store = {
    setIsPanelRightActive,
    isPanelRightActive,
  };

  return (
    <>
      <Row className="mobile-media mobile-media-ext">
        <Col
          md={12}
          xs={24}
          xl={12}
          ml={12}
          sm={24}
          className="mobile_responsive align-left leftbar-background"
        >
          <WebsiteLogo />
          <RegistrationLeftBar current={current} />
        </Col>
        <Col
          md={12}
          xs={24}
          xl={12}
          ml={12}
          sm={24}
          justify="space-around"
          className="main-section"
        >
          <Link to="/registration">
            <div className="logo-image-mobile">
              <img src={Logo} alt="Logo" />
            </div>
          </Link>
          <AuthContext.Provider value={store}>
            <div className="auth-page auth-page-ext">
              <Helmet>
                <title>Authentication</title>
                <meta name="description" content="React App authentication" />
              </Helmet>
              <div>
                <div className="align-content align-content-ext">
                  {steps[current].content}
                </div>
              </div>
            </div>
          </AuthContext.Provider>
        </Col>
      </Row>
    </>
  );
};
export default Registration;
