import React, { useState, useEffect } from 'react';
import { Form, Input, Button, message, Result } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { resend2FACode, verify2FA } from '../../api';

const TwoFactorVerification = () => {
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState('');
    const param = useLocation()
    const navigate = useNavigate();

    const queryParam = () => {
        return new URLSearchParams(param.search);
    };

    useEffect(() => {
        const query = queryParam();
        const emailFromQuery = query.get('email');
        if (emailFromQuery) {
            setEmail(emailFromQuery);
        }
    }, []);

    const resendTwoFactorCode = async () => {
        console.log(email);
        setLoading(true);
        try{
            const response = await resend2FACode({ email })
            message.success(response?.data?.message);
        }catch(error){
            message.error(error?.response?.data?.message);
        }finally{
            setLoading(false);
        }
    }

    const onFinish = async (values) => {
        setLoading(true);
        const { twoFactorCode } = values;

        try {
            const { data } = await verify2FA({ email, twoFactorCode });
            if (data?.token) {
                localStorage.setItem('token', data?.token);
                localStorage.setItem("user", JSON.stringify(data?.userData));
                message.success('2FA verified successfully');
                navigate('/');
            }
        } catch (error) {
            message.error(error?.response?.data?.message);
        }
        setLoading(false);
    };

    if (!email) {
        return <Result status="error" title="Email not found" />;
    }

    return (
        <div style={{ maxWidth: 400, margin: '50px auto', padding: 20, border: '1px solid #f0f0f0', borderRadius: 4 }}>
            <h2 style={{ textAlign: 'center' }}>Verify Your Code</h2>
            <Form
                name="verify_code"
                onFinish={onFinish}
                layout="vertical"
                initialValues={{ email }}
            >
                <Form.Item
                    label="Email"
                    name="email"
                    rules={[{ required: true, message: 'Please input your email!' }]}
                >
                    <Input disabled />
                </Form.Item>
                <Form.Item
                    label="2FA Code"
                    name="twoFactorCode"
                    rules={[{ required: true, message: 'Please input your 2FA code!' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit" loading={loading} block>
                        Verify
                    </Button>
                    {/* add resend button */}
                    <Button
                        type="link"
                        onClick={resendTwoFactorCode}
                        style={{ marginTop: 10 }}
                    >
                        Resend Code
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};

export default TwoFactorVerification;