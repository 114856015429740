import { Avatar, Card, Col, Row, Spin, Tag, Empty, Image, Modal } from "antd";
import Title from "antd/lib/typography/Title";
import Text from "antd/lib/typography/Title";
import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import Paragraph from "antd/lib/typography/Paragraph";

import { LoadingOutlined } from "@ant-design/icons";
import "../marketplacePage/style.scss";
import {
  deleteGrantRequest,
  grantMarketplaceListRequest,
} from "./redux/action";
import MarketPlaceTabHeader from "../../components/Common/MarketPlaceTabHeader/MarketPlaceTabHeader";
import Icon from "@ant-design/icons";
import FallbackImg from "../../assets/images/default-fallback-image.png";
import { getFirstLater, getImageURL, isSuperAdmin } from "../../Utils";
import { EditSvg } from "../../assets/images/editSvg";
import { DeleteSvg } from "../../assets/images/deleteSvg";
import AddGrantModal from "./AddGrantModal";
import { BASE_URL } from "../../api";
import ViewInfoModal from "./ViewInfoModal";

const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;

const OrganizeCard = ({ record, setOpenPopUp }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [openViewInfoModal, setOpenViewInfoModal] = useState(false);
  const [editGrantDetails, setEditGrantDetails] = useState("");
  const dispatch = useDispatch();

  const handleEditPopUp = (record) => {
    setEditGrantDetails(record);
    setModalOpen(true);
    setOpenPopUp(false);
  };
  const actions = [
    <div className="marketplace-action">
      <Text
        level={5}
        style={{
          color: "rgb(11, 83, 148)",
          cursor: "pointer",
          margin: "5px 0 0",
        }}
      >
        <a
          href={record.website}
          target="_blank"
          onClick={(e) => e.stopPropagation()}
          className="website"
        >
          Go to Website
        </a>
      </Text>
    </div>,
  ];

  const showConfirm = (id) => {
    Modal.confirm({
      content: (
        <div style={{ marginTop: "10px" }}>
          Are you sure you want to remove from grant marketplace?
        </div>
      ),
      okText: "Yes",
      cancelText: "No",
      centered: true,
      onOk: () => {
        const formData = new FormData();
        formData.append("grant_id", id);
        dispatch(deleteGrantRequest(formData));
        setOpenPopUp(false);
      },
    });
  };

  return (
    <Card
      className="marketplace"
      cover={
        <div className="card-cover-img">
          <div
            style={{
              overflow: "hidden",
              height: "220px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "10px"
            }}
            onClick={() => {
              setOpenViewInfoModal(true);
              setEditGrantDetails(record);
            }}
          >
            <Image
              className="cover-img"
              alt="example"
              src={BASE_URL + record?.photo || FallbackImg}
              fallback={FallbackImg}
              preview={false}
            />
          </div>
          {/* <div className="cover-avatar-group">
            <Avatar
              shape="round"
              size={60}
              src={getImageURL(record?.photo || "")}
              className={`${
                !record?.photo ? "shape-avatar-letter" : ""
              } shape-avatar cover-avatar-group__avatar`}
            >
              {getFirstLater(record?.name || "")}
            </Avatar>
          </div> */}
        </div>
      }
      actions={actions}
    >
      <div className="marketplace-body">
        <Title
          level={4}
          className="marketplace-body__title"
          ellipsis={{ tooltip: record?.title }}
        >
          <div
            className="grant-name"
            onClick={() => {
              setOpenViewInfoModal(true);
              setEditGrantDetails(record);
            }}
          >
            {record.name}
          </div>
          {isSuperAdmin() && (
            <>
              <div className="icons">
                <Icon
                  component={EditSvg}
                  className="edit-menu__icon"
                  onClick={() => handleEditPopUp(record)}
                  height={8}
                />
                <Icon
                  component={DeleteSvg}
                  className="edit-menu__icon"
                  onClick={() => showConfirm(record.id)}
                />
              </div>
            </>
          )}
        </Title>

        <Paragraph
          className="marketplace-body__description"
          ellipsis={{ rows: 3, tooltip: record?.description }}
          onClick={() => {
            setOpenViewInfoModal(true);
            setEditGrantDetails(record);
          }}
        >
          {record?.description}
        </Paragraph>
      </div>
      <AddGrantModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        editGrantDetails={editGrantDetails}
        setEditGrantDetails={setEditGrantDetails}
      />
      {openViewInfoModal && (
        <ViewInfoModal
          openViewInfoModal={openViewInfoModal}
          setOpenViewInfoModal={setOpenViewInfoModal}
          editGrantDetails={editGrantDetails}
          setEditGrantDetails={setEditGrantDetails}
        />
      )}
    </Card>
  );
};

function NewGrantMarketPlace() {
  const dispatch = useDispatch();

  const { data, loading } = useSelector((state) => state.grantMarketPlace);
  const [search, setSearch] = useState("");
  const [service, setService] = useState("");
  const [openPopUp, setOpenPopUp] = useState(false);

  useEffect(() => {
    dispatch(grantMarketplaceListRequest());
  }, [dispatch]);

  const marketplace = useMemo(() => {
    return data
      .filter((item) => {
        return (
          !search ||
          service === "other" ||
          item?.name.toString().toLowerCase().includes(search?.toLowerCase())
        );
      })
      .sort(function (a, b) {
        return a.name === b.name ? 0 : a.name < b.name ? -1 : 1;
      });
  }, [data, search, service]);

  return (
    <>
      {loading && (
        <div className="spinner_background">
          <Spin className="spinner" indicator={antIcon} />
        </div>
      )}
      <MarketPlaceTabHeader
        setVisible={false}
        searchFilter={(e) => setSearch(e.target.value)}
        isServiceMarketplace={false}
        isGrantMarketPlace={true}
        onServiceChange={(value) => setService(value)}
        setOpenPopUp={setOpenPopUp}
      />
      {marketplace.length ? (
        <Row gutter={[16, 16]}>
          {marketplace.length ? (
            marketplace.map((el) => {
              return (
                <Col md={8} sm={24} key={el.id}>
                  <OrganizeCard
                    record={el}
                    openPopUp={openPopUp}
                    setOpenPopUp={setOpenPopUp}
                  />
                </Col>
              );
            })
          ) : (
            <Empty />
          )}
        </Row>
      ) : (
        <Row gutter={[16, 16]} className="service-marketplace-wrapper">
          <Empty />
        </Row>
      )}
    </>
  );
}

export default NewGrantMarketPlace;
