import { Drawer, Row, Select, Typography } from "antd";
import React from "react";

const FilterDrawer = ({
  handleFilterModal,
  openFilterModal,
  filters,
  handleFilterSelect,
  handleFilterUnselect,
}) => {
  const { Text } = Typography;
  const { Option } = Select;
  return (
    <Drawer
      title="Filters"
      placement="right"
      onClose={handleFilterModal}
      open={openFilterModal}
      className="drawer"
      width={450}
    >
      {filters?.map((filter, index) => {
        return (
          <Row key={filter.title + index} className="pb-10">
            <Text className="pb-5 capitalize">{filter?.label}</Text>
            <Select
              mode="multiple"
              allowClear
              name={filter?.name}
              style={{ width: "100%", fontSize: "12px" }}
              placeholder="Please select filter"
              onSelect={(e) => handleFilterSelect(e, filter.name)}
              onDeselect={(e) => handleFilterUnselect(e, filter.name)}
            >
              {typeof filter?.values[0] === "string"
                ? filter?.values
                    .sort((a, b) => {
                      return a.toLowerCase().localeCompare(b.toLowerCase());
                    })
                    .map((item, index) => {
                      return (
                        <Option
                          key={item + index + Math.random()}
                          value={item}
                          label={item}
                        >
                          {item}
                        </Option>
                      );
                    })
                : filter?.values
                    .sort((a, b) => {
                      return b - a;
                    })
                    .map((item, index) => {
                      return (
                        <Option
                          key={item + index + Math.random()}
                          value={item}
                          label={item}
                        >
                          {item}
                        </Option>
                      );
                    })}
            </Select>
          </Row>
        );
      })}
    </Drawer>
  );
};

export default FilterDrawer;
