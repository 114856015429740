import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import ReactApexChart from "react-apexcharts";

const SwotAnalysisTab = ({ swotMetrics }) => {
  const [options, setOptions] = useState({
    title: {
      text: "SWOT Analysis",
    },
    xaxis: {
      categories: [],
    },
  });
  const [series, setSeries] = useState([
    {
      name: "",
      data: [],
    },
  ]);

  useEffect(() => {
    setSeries((prev) => [
      {
        name: swotMetrics?.title || "",
        data: swotMetrics?.metrics?.map((x) => Number(x?.value).toFixed(2)),
      },
    ]);
    setOptions((prev) => ({
      ...prev,
      title: {
        text: swotMetrics?.title || "",
      },
      xaxis: {
        categories: swotMetrics?.metrics?.map((x) => x?.metric) || [],
        labels: {
          show: true,
          style: {
            colors: new Array(
              swotMetrics?.metrics?.map((x) => x?.metric).length || [].length
            ).fill("black"),
          },
        },
      },
      yaxis: {
        show: true,
        logBase: 10,
        tickAmount: 5,
        labels: {
            show: true,
            align: 'right',
            formatter: (value) => +value.toFixed(2),
        },
    }
    }));
  }, [swotMetrics]);

  return (
    <div style={{ marginTop: "20px" }}>
      <ReactApexChart
        options={options}
        series={series}
        type="radar"
        height={500}
      />
    </div>
  );
};

export default SwotAnalysisTab;
