import React, { useCallback, useMemo, useState } from "react";
import "antd/dist/antd.css";
import "./style.scss";
import {
  Button,
  Layout,
  Typography,
  Spin,
  Input,
  Col,
  Row,
  Radio,
  Card,
  Divider,
  List,
  Modal,
  Form,
  InputNumber,
  message,
  Tooltip,
  Menu,
} from "antd";
import _ from "lodash";
import ArrowLeftOutlined from "@ant-design/icons/ArrowLeftOutlined";
import FileTextOutlined from "@ant-design/icons/FileTextOutlined";
import CheckCircleOutlined from "@ant-design/icons/CheckCircleOutlined";

import Logo from "../../assets/images/main-logo-svg.svg";
import Icon from "@ant-design/icons";

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LoadingOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { SuccessSvg } from "../../assets/images/successSvg";
import {
  clearInvestError,
  clearInvestStore,
  closeSignModal,
  createEscrowData,
  investDeal,
  setIsInvestedFalse,
  signInvestDocsRequest,
} from "./redux/action";
import DocIframe from "../createDeal/forms/DocIframe";
import { SIGN_INVEST_DOCS_SUCCESS } from "./redux/constants";
import ExclamationCircleOutlined from "@ant-design/icons/ExclamationCircleOutlined";
import CheckOutlined from "@ant-design/icons/CheckOutlined";
import { getUser } from "../../Utils/isAuth";
import { numberFormatter } from "../../Utils";
import { useMediaQuery } from "react-responsive";
import EscrowModal from "./EscrowModal";

const { error, success } = Modal;

const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;

const nf = new Intl.NumberFormat();

const { Header, Sider, Content } = Layout;
const { Text, Title } = Typography;

const BANK_ACC = [
  {
    value: "bank_name",
    title: "Bank Name",
    isRequired: true,
  },
  {
    value: "holder_name",
    title: "Holder Name",
    isRequired: true,
  },
  {
    value: "swift_code",
    title: "Swift Code",
    isRequired: true,
  },
  {
    value: "iban_number",
    title: "Iban Number",
    isRequired: true,
  },
  {
    value: "company_name",
    title: "Company Name",
  },
  {
    value: "bank_address",
    title: "Bank Address",
  },
  {
    value: "routing_number",
    title: "Routing Number",
  },
];

const AddingModal = ({ isModalVisible, handleOk, handleCancel, colums }) => {
  const [form] = Form.useForm();

  const modalFields = colums.map((el) => {
    return (
      <Form.Item
        key={el.dataIndex}
        label={el.title}
        rules={[
          {
            required: el.isRequired,
            message: `${el.title} is required`,
          },
        ]}
        name={el.value}
        required={el.isRequired}
      >
        <Input
          type={
            el.value === "round_name" || el.value === "comments"
              ? "number"
              : "text"
          }
        />
      </Form.Item>
    );
  });

  const handleOkClick = (data) => {
    handleOk(data);
    form.resetFields();
  };

  return (
    <>
      <Modal
        title="Bank Account"
        visible={isModalVisible}
        onOk={() => form.submit()}
        onCancel={() => {
          form.resetFields();
          handleCancel();
        }}
        okText="Save"
        className="modal-form"
        centered
        width={430}
      >
        <Row
          gutter={[0, 16]}
          className="modal-form__body modal-form__invest-body"
        >
          <Form layout="vertical" form={form} onFinish={handleOkClick}>
            {modalFields}
          </Form>
        </Row>
      </Modal>
    </>
  );
};

const showError = (errorMessage) => {
  error({
    title: "Error",
    content: errorMessage,
    icon: <ExclamationCircleOutlined />,
    centered: true,

    onOk() {
      console.log("OK");
    },
  });
};

const InvestPage = () => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const dealDetails = useSelector((state) => state.dealDetails?.data);
  const [collapsed, setCollapsed] = useState(false);
  const [bankAccount, setBankAccount] = useState(1);
  const [isIframeOpen, setIsIframeOpen] = useState(false);
  const [amount, setAmount] = useState(0);
  const [price] = useState(() => dealDetails.price);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEscrowVisible, setIsEscrowVisible] = useState(false);
  const [bankInfo, setBankInfo] = useState(null);
  const user = getUser();
  const { spv_documents = null, is_m_and_a } = dealDetails;

  const isLowerScreen = useMediaQuery({
    query: "(max-width:1200px)",
  });

  const {
    envelopeUrl,
    signed,
    pending,
    dealInvesting,
    loading,
    deal_id,
    memorandum_of_association,
    spv_services_agreement,
    dealInvested,
    errorMessage,
    escrowSuccess,
    escrowPending,
  } = useSelector((state) => state.invest);
  const goToHome = useCallback(() => {
    history("/deals");
    dispatch(setIsInvestedFalse());
  }, [history, dispatch]);

  const showConfirmToHome = () => {
    success({
      title: "Deal confirmed!",
      icon: <CheckOutlined />,
      centered: true,
      content: `The next step after approve by our legal team will be: \n
        Transfering money to our bank account. \n
        Review from our manager (1-2 days).`,
      className: "invest-modal",
      onOk() {
        history(`/project/${deal_id}`);
        dispatch(setIsInvestedFalse());
      },
      okText: "Back to deals",
    });
  };

  useEffect(() => {
    if (
      _.isEmpty(dealDetails) ||
      dealDetails === null ||
      dealDetails === undefined
    ) {
      history(-1);
    }
  }, [dealDetails, history]);

  const handleSign = (key, name) => {
    if (!bankInfo) {
      message.error("Please add payment method to proceed further");
      return;
    }
    const formData = new FormData();

    formData.append("document_name", name);
    formData.append("deal_id", deal_id);

    dispatch(signInvestDocsRequest({ key, formData }));
  };

  const confirmInvest = () => {
    if (!bankInfo) {
      message.error("Please add payment method to proceed further");
      return;
    }
    const formData = new FormData();

    formData.append("memorandum_of_association", memorandum_of_association);
    formData.append("spv_services_agreement", spv_services_agreement);
    if (is_m_and_a) {
      formData.append("buy_price", price);
    } else {
      formData.append("amount_of_inv", amount);
    }
    formData.append("deal_id", deal_id);

    if (bankInfo?.iban_number !== dealInvesting?.bank_info?.iban_number) {
      const keys = Object.keys(bankInfo);

      keys.forEach((key) => {
        formData.append(key, bankInfo[key]);
      });
    }

    dispatch(investDeal(formData));
  };

  const confEscrow = (data) => {
    dispatch(
      createEscrowData({
        ...data,
        deal_id,
      })
    );
  };

  useEffect(() => {
    if (envelopeUrl) {
      setIsIframeOpen(true);
    } else {
      setIsIframeOpen(false);
    }
  }, [envelopeUrl]);

  useEffect(() => {
    if (dealInvested) {
      showConfirmToHome(goToHome);
    }
  }, [dealInvested, dispatch, goToHome]);

  useEffect(() => {
    if (errorMessage) {
      showError(errorMessage);

      dispatch(clearInvestError());
    }
  }, [errorMessage, dispatch]);

  useEffect(() => {
    isLowerScreen && setCollapsed(true);
    return () => {
      dispatch(clearInvestStore());
    };
  }, [dispatch, isLowerScreen]);

  const hasValidSign = useMemo(() => {
    if (
      dealDetails?.deal_type === "spv" &&
      dealDetails?.service_organizer?.name === "invest_tech" &&
      spv_documents
    ) {
      return (
        signed.includes("memorandum_of_association") &&
        signed.includes("spv_services_agreement")
      );
    }

    return true;
  }, [dealDetails, signed, spv_documents]);

  const FileList = () => {
    return (
      <List
        itemLayout="horizontal"
        dataSource={[
          {
            name: "Memorandum of Association",
            key: "memorandum_of_association",
          },
          {
            name: "SPV Services Agreement",
            key: "spv_services_agreement",
          },
        ]}
        renderItem={(item) => (
          <List.Item
            actions={[
              signed.includes(item.key) ? (
                <Icon component={SuccessSvg} />
              ) : (
                <Button
                  type="primary"
                  htmlType="submit"
                  onClick={() => handleSign(item?.key, item?.name)}
                  // loading={pending === item.key}
                >
                  Sign
                </Button>
              ),
            ]}
          >
            <List.Item.Meta
              avatar={<FileTextOutlined style={{ fontSize: "2.5rem" }} />}
              title={<Text className="lable">{item?.name}</Text>}
            />
          </List.Item>
        )}
      />
    );
  };

  return (
    <>
      <Layout className="invest">
        {loading && (
          <div className="spinner_background">
            <Spin className="spinner" indicator={antIcon} />
          </div>
        )}
        {envelopeUrl ? (
          <DocIframe
            isModalVisible={isIframeOpen}
            handleCancel={() => {
              setIsIframeOpen(false);
              dispatch(closeSignModal());
            }}
            url={envelopeUrl}
            actionType={SIGN_INVEST_DOCS_SUCCESS}
          />
        ) : null}
        <AddingModal
          isModalVisible={isModalVisible}
          handleOk={(data) => {
            setBankInfo({ ...data, user: user.user_id });
            setIsModalVisible(false);
          }}
          handleCancel={() => {
            setIsModalVisible(false);
          }}
          colums={BANK_ACC}
        />
        <EscrowModal
          isModalVisible={isEscrowVisible}
          amount={amount}
          handleOk={confEscrow}
          handleCancel={() => setIsEscrowVisible(false)}
        />
        <Header className="invest__header">
          <div className="header-layout">
            <div className="left">
              <div
                className="header-brand"
                onClick={() => history("/")}
                style={{ cursor: "pointer" }}
              >
                <img src={Logo} alt="Logo" />
              </div>

              <Button
                onClick={() => {
                  history(`/project/${deal_id}`);
                  dispatch(setIsInvestedFalse());
                }}
              >
                <ArrowLeftOutlined /> Back
              </Button>
            </div>
          </div>
        </Header>

        <Layout>
          <Content className="invest__content">
            <Row gutter={[0, 16]} className="content-row">
              <Col span={24}>
                <h2 className="financial_title">{dealDetails?.title}</h2>
              </Col>
            </Row>
            {is_m_and_a ? (
              <Row gutter={[0, 16]} className="content-row">
                <Col span={24}>
                  <Text className="lable">Price</Text>
                  <InputNumber
                    onChange={(e) => {}}
                    className="currency-input input-number-style"
                    addonAfter={dealDetails?.currency}
                    value={price}
                    disabled={true}
                    formatter={numberFormatter.formatter}
                    parser={numberFormatter.parser}
                  />
                </Col>
              </Row>
            ) : (
              <Row gutter={[0, 16]} className="content-row">
                <Col span={24}>
                  <Text className="lable">Commitment Amount</Text>
                  <InputNumber
                    onChange={(e) => setAmount(e)}
                    className="currency-input input-number-style"
                    addonAfter={dealInvesting?.spv_terms?.currency}
                    value={amount}
                    formatter={numberFormatter.formatter}
                    parser={numberFormatter.parser}
                  />
                </Col>
              </Row>
            )}
            <Row gutter={[0, 16]} className="content-row">
              {!_.isEmpty(bankInfo) &&
              bankInfo !== null &&
              bankInfo !== undefined ? (
                <Col span={24} className={"bank-cards"}>
                  <Text className="lable">Bank Account</Text>
                  <Radio.Group
                    name="radiogroup"
                    defaultValue={bankAccount}
                    onChange={(e) => setBankAccount(e.target.value)}
                  >
                    <Card
                      className={
                        bankAccount === 1
                          ? `onboarding-card-clicked`
                          : `onboarding-card`
                      }
                      title={
                        <div className="card-content-identity">
                          <Radio value={1}>
                            <div className="radio-label">
                              <Text className="lable">{`${bankInfo?.bank_name} ${bankInfo?.holder_name}`}</Text>
                              <small className="lable-second">{`${bankInfo?.iban_number}`}</small>
                            </div>
                          </Radio>
                        </div>
                      }
                      extra={
                        <Button
                          type="link"
                          onClick={() => {
                            setBankInfo(null);
                          }}
                        >
                          Remove
                        </Button>
                      }
                      bodyStyle={{ padding: "0" }}
                    />
                  </Radio.Group>
                </Col>
              ) : (
                <Col
                  span={24}
                  style={{
                    justifyContent: "center",
                    display: "flex",
                  }}
                >
                  <Button
                    onClick={() => setIsModalVisible(true)}
                    style={{
                      color: "#0B5394",
                    }}
                  >
                    + Add new payment method{" "}
                    <span className="required-star required-star-ext">*</span>
                  </Button>
                </Col>
              )}
            </Row>
            {/* <Row gutter={[0, 16]} className="content-row">
              <Col
                span={24}
                style={{
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                <Button
                  type={"default"}
                  onClick={() => setIsEscrowVisible(true)}
                  style={{
                    color: "#0B5394",
                  }}
                  icon={escrowSuccess ? <CheckCircleOutlined /> : null}
                  disabled={escrowSuccess ? true : false}
                  loading={escrowPending}
                >
                  Create escrow account
                </Button>
              </Col>
            </Row> */}
            <Row gutter={[0, 16]} className="content-row">
              <Col span={24}>
                <Divider />
              </Col>
            </Row>
            {dealDetails?.deal_type === "spv" &&
            dealDetails?.service_organizer?.name === "invest_tech"
              ? spv_documents && (
                  <Row gutter={[0, 16]} className="content-row">
                    <Col span={24}>
                      <Text className="lable">Documents</Text>
                      <FileList />
                    </Col>
                  </Row>
                )
              : ""}

            <Row gutter={[0, 16]} className="content-row">
              <Col span={24}>
                <Button
                  type={!bankInfo || !hasValidSign ? "default" : "primary"}
                  style={{
                    width: "100%",
                  }}
                  onClick={() => confirmInvest()}
                  disabled={!bankInfo || !hasValidSign}
                  title={!bankInfo ? "Add payment method to continue" : ""}
                >
                  Confirm
                </Button>
              </Col>
            </Row>
          </Content>
          {is_m_and_a ? null : (
            <Sider
              className="invest__sider height-100"
              collapsible
              reverseArrow
              collapsed={collapsed}
              onCollapse={(value) => setCollapsed(value)}
              width={500}
            >
              {collapsed ? null : (
                <Card title="Terms">
                  <Card.Grid className="card-grid" hoverable={false}>
                    <div className="card-grid__item">
                      <p>Deal Creator</p>
                      <Title level={5}>
                        {dealInvesting?.spv_terms?.fund_manager}
                      </Title>
                    </div>
                  </Card.Grid>
                  <Card.Grid className="card-grid" hoverable={false}>
                    <div className="card-grid__item">
                      <p>Minimal Investment</p>
                      <Title level={5}>
                        <Tooltip
                          title={nf.format(
                            Number(dealInvesting?.spv_terms?.minimal_investment)
                          )}
                        >
                          <Text ellipsis={true} style={{ width: "50px" }}>
                            {`${nf.format(
                              Number(
                                dealInvesting?.spv_terms?.minimal_investment
                              )
                            )}`}
                          </Text>
                        </Tooltip>{" "}
                        {dealInvesting?.spv_terms?.currency}
                      </Title>
                    </div>
                  </Card.Grid>
                  <Card.Grid className="card-grid" hoverable={false}>
                    <div className="card-grid__item">
                      <p>Raised to date</p>
                      <Title level={5}>
                        <Tooltip
                          title={nf.format(
                            Number(dealInvesting?.spv_terms?.raised_to_data)
                          )}
                        >
                          <Text ellipsis={true} style={{ width: "50px" }}>
                            {`${nf.format(
                              Number(dealInvesting?.spv_terms?.raised_to_data)
                            )}`}
                          </Text>
                        </Tooltip>{" "}
                        {dealInvesting?.spv_terms?.currency}
                      </Title>
                    </div>
                  </Card.Grid>
                  <Card.Grid className="card-grid" hoverable={false}>
                    <div className="card-grid__item">
                      <p>Target size</p>
                      <Title level={5}>
                        <Tooltip
                          title={nf.format(
                            Number(dealInvesting?.spv_terms?.target_size)
                          )}
                        >
                          <Text ellipsis={true} style={{ width: "50px" }}>
                            {`${nf.format(
                              Number(dealInvesting?.spv_terms?.target_size)
                            )}`}
                          </Text>
                        </Tooltip>{" "}
                        {dealInvesting?.spv_terms?.currency}
                      </Title>
                    </div>
                  </Card.Grid>
                  {/* <Card.Grid className="card-grid" hoverable={false}>
                    <div className="card-grid__item">
                      <p>Management Fee %</p>
                      <Title level={5}>
                        {dealInvesting?.spv_terms?.management_fee}%
                      </Title>
                    </div>
                  </Card.Grid> */}
                  {/* <Card.Grid className="card-grid" hoverable={false}>
                    <div className="card-grid__item">
                      <p>Carried Interest %</p>
                      <Title level={5}>
                        {dealInvesting?.spv_terms?.carried_interest}%
                      </Title>
                    </div>
                  </Card.Grid> */}
                </Card>
              )}
              {collapsed && (
                <Menu
                  mode="inline"
                  items={[{ icon: "Terms" }]}
                  onClick={() => setCollapsed(!collapsed)}
                />
              )}
            </Sider>
          )}
        </Layout>
      </Layout>
    </>
  );
};

export default InvestPage;
