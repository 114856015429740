export const IP_LIST_REQUEST = "IP_LIST_REQUEST";
export const IP_LIST_SUCCESS = "IP_LIST_SUCCESS";
export const IP_LIST_ERROR = "IP_LIST_ERROR";

export const ORGANIZER_IP_LIST_REQUEST = "ORGANIZER_IP_LIST_REQUEST";
export const ORGANIZER_IP_LIST_SUCCESS = "ORGANIZER_IP_LIST_SUCCESS";
export const ORGANIZER_IP_LIST_ERROR = "ORGANIZER_IP_LIST_ERROR";

export const DELETE_IP_REQUEST = "DELETE_IP_REQUEST";
export const DELETE_IP_SUCCESS = "DELETE_IP_SUCCESS";
export const DELETE_IP_ERROR = "DELETE_IP_ERROR";
