import React, { useEffect, useState } from "react";
import "antd/dist/antd.css";

import { UserOutlined, UsergroupAddOutlined } from "@ant-design/icons";
import { Avatar, Select, Tag } from "antd";
import { useSelector } from "react-redux";
import { useMemo } from "react";
const { Option, OptGroup } = Select;

const colors = [
  "magenta",
  "volcano",
  "orange",
  "gold",
  "lime",
  "green",
  "cyan",
  "blue",
  "geekblue",
  "purple",
];

const getTypeOfUser = (users, typeOfAccount) =>
  users.filter((el) => el.type_of_account === typeOfAccount);

const TagRender = (props) => {
  const { label, closable, onClose } = props;
  const [colorNumber, setColorNumber] = useState(0);

  useEffect(() => {
    setColorNumber(Math.floor(Math.random() * colors.length - 1));
  }, []);

  const onPreventMouseDown = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <Tag
      color={colors[colorNumber]}
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
      style={{
        marginRight: 3,
      }}
    >
      {label}
    </Tag>
  );
};

const UserSelect = ({
  setSelectedUsers,
  selectedUsers,
  disabled = false,
  docPermission = false,
}) => {
  const users = useSelector((state) => state.chat.users);
  const permissionsGroup = [
    {
      value: "investors",
      label: "Investors",
    },
    {
      value: "facilitator",
      label: "Facilitators",
    },
  ];

  const addItem = (item) => {
    setSelectedUsers([...selectedUsers, item]);
  };

  const removeItem = (item) => {
    const index = selectedUsers.indexOf(item);

    const newArray = [...selectedUsers];
    newArray.splice(index, 1);

    setSelectedUsers(newArray);
  };

  const buildOptions = (userDetails, type) => {
    return userDetails.map((el) => (
      <Option
        value={el.id}
        key={`${el.first_name} ${el.last_name} ${el.id} ${type}`}
        label={`${el.first_name} ${el.last_name}`}
      >
        <Avatar
          size="small"
          icon={<UserOutlined />}
          style={{ marginRight: "5px" }}
        />
        {el.first_name} {el.last_name}
      </Option>
    ));
  };

  const serviceOrgs = useMemo(() => {
    const filterdUser = getTypeOfUser(users, "SVC_ORG");
    return buildOptions(filterdUser, "src_org");
  }, [users]);

  const facilitators = useMemo(() => {
    const filterdUser = getTypeOfUser(users, "FAC");
    return buildOptions(filterdUser, "fac");
  }, [users]);

  const org = useMemo(() => {
    return users
      .filter((el) => {
        return el.type_of_account === "ORG";
      })
      .map((el) => {
        return (
          <Option
            value={el.id}
            key={`${el.first_name} ${el.last_name} ${el.id} org`}
          >
            <Avatar
              size="small"
              icon={<UserOutlined />}
              style={{ marginRight: "5px" }}
            />
            {el.first_name} {el.last_name}
          </Option>
        );
      });
  }, [users]);

  const inv = useMemo(() => {
    return users
      .filter((el) => {
        return el.type_of_account === "INV";
      })
      .map((el) => {
        return (
          <Option
            value={el.id}
            key={`${el.first_name} ${el.last_name} ${el.id} inv`}
            label={`${el.first_name} ${el.last_name}`}
          >
            <Avatar
              size="small"
              icon={<UserOutlined />}
              style={{ marginRight: "5px" }}
            />
            {el.first_name} {el.last_name}
          </Option>
        );
      });
  }, [users]);

  const admin = useMemo(() => {
    return [
      {
        email: null,
        first_name: "Admin",
        id: "admin",
        is_superuser: false,
        last_name: null,
        logo: "https://joeschmoe.io/api/v1/random",
        sub_type_of_account: null,
        type_of_account: null,
      },
    ].map((el) => {
      return (
        <Option
          value={el.id}
          key={`${el.first_name} ${el.last_name} ${el.id} admin`}
          label={`${el.first_name} ${el.last_name}`}
        >
          <Avatar
            size="small"
            icon={<UserOutlined />}
            style={{ marginRight: "5px" }}
          />
          {el.first_name} {el.last_name}
        </Option>
      );
    });
  }, [users]);

  return (
    <Select
      mode="multiple"
      showArrow
      tagRender={TagRender}
      placeholder="Search"
      autoClearSearchValue={true}
      optionFilterProp="label"
      value={selectedUsers}
      onSelect={(value) => {
        addItem(value);
      }}
      onDeselect={(value) => {
        removeItem(value);
      }}
      style={{
        width: "100%",
      }}
      name="users-select"
      disabled={disabled}
    >
      {docPermission ? (
        <OptGroup label="Groups">
          {permissionsGroup.map((el) => {
            return (
              <Option value={el.value} key={el.value} label={`${el.label}`}>
                <Avatar
                  size="small"
                  icon={<UsergroupAddOutlined />}
                  style={{ marginRight: "5px" }}
                />

                {el.label}
              </Option>
            );
          })}
        </OptGroup>
      ) : (
        <OptGroup label="Admin">{admin}</OptGroup>
      )}

      <OptGroup label="Investors">{inv}</OptGroup>
      <OptGroup label="Start ups">{org}</OptGroup>
      <OptGroup label="Organizers">{serviceOrgs}</OptGroup>
      <OptGroup label="Faclitators">{facilitators}</OptGroup>
    </Select>
  );
};

export default UserSelect;
