import { call, put, takeLatest } from "redux-saga/effects";
import { longevityLogin } from "../../../api";
import {
  LONGEVITY_LOGIN_FAILURE,
  LONGEVITY_LOGIN_REQUEST,
  LONGEVITY_LOGIN_SUCCESS,
} from "./constant";

function* loginRequest(action) {
  try {
    const { data } = yield call(longevityLogin, action.data);

    if (data.lc_user) {
      localStorage.setItem("lc_email", data.lc_user);
      yield put({ type: LONGEVITY_LOGIN_SUCCESS, data: data });
    }
  } catch (err) {
    yield put({ type: LONGEVITY_LOGIN_FAILURE, data: err });
  }
}

function* LongevityWatcher() {
  yield takeLatest(LONGEVITY_LOGIN_REQUEST, loginRequest);
}
export default LongevityWatcher;
