import { call, put, takeLatest } from "redux-saga/effects";
import {
  getAccessTokenSumsubReq,
  getApplicantActionSumsubReq,
  getSumsubStatusReq,
} from "../../../api";

import {
  GET_SUMSUB_TOKEN_ERROR,
  GET_SUMSUB_TOKEN_REQUEST,
  GET_SUMSUB_TOKEN_SUCCESS,
} from "./constants";

function* requestSumSubToken(action) {
  try {
    let res = null;
    const { data } = yield call(getSumsubStatusReq);

    if (data?.status === "RED" || action?.data?.needApplicantToken === false) {
      res = yield call(getAccessTokenSumsubReq);
    } else {
      res = yield call(getApplicantActionSumsubReq);
    }

    if (res) {
      yield put({
        type: GET_SUMSUB_TOKEN_SUCCESS,
        data: res?.data?.access_token,
      });
    }
  } catch ({ response }) {
    if (response) {
      yield put({
        type: GET_SUMSUB_TOKEN_ERROR,
        data:
          response?.data?.Error ||
          response?.data?.errors ||
          response?.data?.error,
      });
    } else {
      yield put({
        type: GET_SUMSUB_TOKEN_ERROR,
        data: "Something went wrong, Please try again !!",
      });
    }
  }
}

function* SumSubWatcher() {
  yield takeLatest(GET_SUMSUB_TOKEN_REQUEST, requestSumSubToken);
}
export default SumSubWatcher;
