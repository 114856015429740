export const OrganizerIcon = () => (
  <svg
    width="29"
    height="39"
    viewBox="0 0 29 39"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.21939 38.4386H26.7806C28.0044 38.4386 29 37.443 29 36.2192V7.2192C29 5.99543 28.0044 4.99981 26.7806 4.99981H23.0816V4.26001C23.0816 3.8515 22.7504 3.52022 22.3418 3.52022H18.686C18.0682 1.77415 16.3952 0.561035 14.5 0.561035C12.6048 0.561035 10.9318 1.77415 10.314 3.52022H6.65816C6.24965 3.52022 5.91837 3.8515 5.91837 4.26001V4.99981H2.21939C0.995617 4.99981 0 5.99543 0 7.2192V36.2192C0 37.443 0.995617 38.4386 2.21939 38.4386ZM7.39796 4.99981H10.875C11.2269 4.99981 11.53 4.75191 11.6001 4.40709C11.8782 3.03587 13.0979 2.04063 14.5 2.04063C15.9021 2.04063 17.1218 3.03587 17.3999 4.40709C17.47 4.75191 17.7731 4.99981 18.125 4.99981H21.602V6.4794H7.39796V4.99981ZM1.47959 7.2192C1.47959 6.81128 1.81146 6.4794 2.21939 6.4794H5.91837V7.2192C5.91837 7.62771 6.24965 7.95899 6.65816 7.95899H22.3418C22.7504 7.95899 23.0816 7.62771 23.0816 7.2192V6.4794H26.7806C27.1885 6.4794 27.5204 6.81128 27.5204 7.2192V36.2192C27.5204 36.6271 27.1885 36.959 26.7806 36.959H2.21939C1.81146 36.959 1.47959 36.6271 1.47959 36.2192V7.2192Z"
      fill="#6B7280"
    />
    <path
      d="M9.8 27.5C9.8 28.7703 8.77026 29.8 7.5 29.8C6.22975 29.8 5.2 28.7703 5.2 27.5C5.2 26.2297 6.22975 25.2 7.5 25.2C8.77026 25.2 9.8 26.2297 9.8 27.5Z"
      stroke="#6B7280"
      stroke-width="1.4"
    />
    <path
      d="M6.9946 21.2833C7.13908 21.4277 7.32832 21.5 7.51771 21.5C7.70709 21.5 7.89633 21.4277 8.04082 21.2833L12 16.3241C12.2889 16.0352 12.2889 15.5668 12 15.2778C11.711 14.989 11.2427 14.989 10.9537 15.2778L7.51771 19.7139L5.56122 17.7574C5.27226 17.4686 4.80397 17.4686 4.51493 17.7574C4.22604 18.0463 4.22604 18.5148 4.51493 18.8037L6.9946 21.2833Z"
      fill="#6B7280"
    />
    <path
      d="M14.5 17.7193H23.8215C24.23 17.7193 24.5613 17.3881 24.5613 16.9795C24.5613 16.571 24.23 16.2397 23.8215 16.2397H14.5C14.0915 16.2397 13.7603 16.571 13.7603 16.9795C13.7603 17.3881 14.0915 17.7193 14.5 17.7193Z"
      fill="#6B7280"
    />
    <path
      d="M14.5 20.6783H20.8623C21.2708 20.6783 21.6021 20.347 21.6021 19.9385C21.6021 19.53 21.2708 19.1987 20.8623 19.1987H14.5C14.0915 19.1987 13.7603 19.53 13.7603 19.9385C13.7603 20.347 14.0915 20.6783 14.5 20.6783Z"
      fill="#6B7280"
    />
    <path
      d="M14.5 27.1173H23.8215C24.23 27.1173 24.5613 26.786 24.5613 26.3775C24.5613 25.969 24.23 25.6377 23.8215 25.6377H14.5C14.0915 25.6377 13.7603 25.969 13.7603 26.3775C13.7603 26.786 14.0915 27.1173 14.5 27.1173Z"
      fill="#6B7280"
    />
    <path
      d="M14.5 30.0763H20.8623C21.2708 30.0763 21.6021 29.745 21.6021 29.3365C21.6021 28.928 21.2708 28.5967 20.8623 28.5967H14.5C14.0915 28.5967 13.7603 28.928 13.7603 29.3365C13.7603 29.745 14.0915 30.0763 14.5 30.0763Z"
      fill="#6B7280"
    />
  </svg>
);
