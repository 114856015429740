/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import {
  Avatar,
  Button,
  Card,
  Dropdown,
  Menu,
  Tag,
  Modal,
  Divider,
} from "antd";

import Icon from "@ant-design/icons";
import Title from "antd/lib/typography/Title";
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  QuestionCircleOutlined,
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons";

import "./style.scss";

import ApproveDealModalIcon from "../../../assets/images/modal-approve-deal.png";
import { DropdownSvg } from "../../../assets/images/dropdownSvg";
import { updateInvestmentStatusRequest } from "../../../pages/adminInvestment/redux/action";

import ComplianceCheck from "../../../assets/images/compliance-check.png";
import { getFirstLater, getImageURL } from "../../../Utils";
import { useLocation } from "react-router-dom";
import { updateIPInvestmentStatusRequest } from "../../../pages/adminIPInvestment/redux/action";

const AdminDealComplianceCheckMenu = ({ record, dispatch, type = "" }) => {
  const [visibleApprove, setVisibleApprove] = useState(false);
  const [visibleReject, setVisibleReject] = useState(false);
  const { pathname } = useLocation();

  const showApproveModal = (e) => {
    e.domEvent.stopPropagation();
    setVisibleApprove(true);
  };

  const showRejectModal = (e) => {
    e.domEvent.stopPropagation();
    setVisibleReject(true);
  };
  const inv_id = pathname === "/ip-investments" ? "ip_buy_id" : "deal_inv_id";
  const inv_status =
    pathname === "/ip-investments" ? "ip_buy_status" : "deal_inv_status";
  const ApproveStatus =
    pathname === "/ip-investments" ? "Approve" : `Approve ${type}`.trim();

  const RejectStatus =
    pathname === "/ip-investments" ? "Reject" : `Reject ${type}`.trim();

  const handleOkApprove = (e) => {
    e.stopPropagation();
    const formData = new FormData();
    formData.append(inv_id, record?.id);
    formData.append(inv_status, ApproveStatus);
    dispatch(
      pathname === "/ip-investments"
        ? updateIPInvestmentStatusRequest(formData)
        : updateInvestmentStatusRequest(formData)
    );

    setVisibleApprove(false);
  };

  const handleCancelApprove = (e) => {
    e.stopPropagation();
    setVisibleApprove(false);
  };

  const handleOkReject = (e) => {
    e.stopPropagation();
    const formData = new FormData();

    formData.append(inv_id, record?.id);
    formData.append(inv_status, RejectStatus);
    dispatch(
      pathname === "/ip-investments"
        ? updateIPInvestmentStatusRequest(formData)
        : updateInvestmentStatusRequest(formData)
    );
    setVisibleReject(false);
  };

  const handleOkComplianceCheck = (e) => {
    e.domEvent.stopPropagation();
    const formData = new FormData();

    formData.append(inv_id, record?.id);
    formData.append(inv_status, `Compliance Check`);
    dispatch(
      pathname === "/ip-investments"
        ? updateIPInvestmentStatusRequest(formData)
        : updateInvestmentStatusRequest(formData)
    );
  };

  const handleCancelReject = (e) => {
    e.stopPropagation();
    setVisibleReject(false);
  };

  return (
    <>
      <Menu className="edit-menu" triggerSubMenuAction="click">
        {record.status === "In Review" ? (
          <>
            <Menu.Item
              onClick={handleOkComplianceCheck}
              className="edit-menu__item"
              key="edit-menu-item-0"
            >
              {/* <Icon
                component={ComplianceCheck}
                className="dropdown-menu-button"
              /> */}
              <img
                src={ComplianceCheck}
                className="edit-menu__icon edit-menu__item menu-icon-size"
                alt=""
              />
              <span className="list-item compliance-container">
                Compliance Check
              </span>
            </Menu.Item>
            <Divider className="divider-margin" />
          </>
        ) : null}
        <Menu.Item
          className="edit-menu__item"
          key="edit-menu-item-1"
          onClick={showApproveModal}
          style={{ color: "#34D399" }}
        >
          <CheckOutlined className="menu-item-icon approve-txt" />
          <span className="list-item approve-txt">Approve</span>
        </Menu.Item>
        <Menu.Item
          className="edit-menu__item"
          key="edit-menu-item-2"
          onClick={showRejectModal}
          style={{ color: "#EF4444" }}
        >
          <CloseOutlined className="menu-item-icon reject-txt" />
          <span className="list-item reject-txt">Reject</span>
        </Menu.Item>
      </Menu>
      <Modal
        visible={visibleApprove}
        onOk={handleOkApprove}
        onCancel={handleCancelApprove}
        className="approve-modal"
        centered={true}
        footer={[
          <Button
            key="no"
            onClick={handleCancelApprove}
            className="modal-cancel-btn"
          >
            No
          </Button>,
          <Button
            key="yes"
            type="primary"
            onClick={handleOkApprove}
            className="modal-yes-btn"
          >
            Yes
          </Button>,
        ]}
      >
        <div>
          <img
            src={ApproveDealModalIcon}
            className="approve-modal-icon"
            alt=""
          />
          <p className="approve-modal-title">
            Approve an {type ? type : "Investment"}?
          </p>
          <p className="approve-modal-desc">
            Are you sure you want to approve an {type}? This action cannot be
            undone.
          </p>
        </div>
      </Modal>
      <Modal
        visible={visibleReject}
        onOk={handleOkReject}
        onCancel={handleCancelReject}
        className="approve-modal"
        centered={true}
        footer={[
          <Button
            key="no"
            onClick={handleCancelReject}
            className="modal-cancel-btn"
          >
            No
          </Button>,
          <Button
            key="yes"
            type="primary"
            onClick={handleOkReject}
            className="reject-modal-yes-btn "
          >
            Yes
          </Button>,
        ]}
      >
        <div>
          <CloseOutlined className="reject-modal-icon" />
          <p className="approve-modal-title">Reject an {type}?</p>
          <p className="approve-modal-desc">
            Are you sure you want to reject an {type}? This action cannot be
            undone.
          </p>
        </div>
      </Modal>
    </>
  );
};

const adminInvestColumns = (dispatch, location) => [
  {
    title: ({ sortColumns }) => {
      const sortedColumn = sortColumns?.find(
        ({ column }) => column.key === "name"
      );

      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          Name
          {sortedColumn?.order ? (
            sortedColumn.order === "ascend" ? (
              <ArrowUpOutlined style={{ marginLeft: "8px" }} />
            ) : (
              <ArrowDownOutlined style={{ marginLeft: "8px" }} />
            )
          ) : null}
        </div>
      );
    },

    sorter: (a, b) =>
      (a.deal_title || a.ip_title).localeCompare(b?.deal_title || b?.ip_title),
    dataIndex: "name",
    key: "name",
    render: (text, record) => {
      return (
        <Avatar.Group>
          <Avatar
            className={`${
              !record?.deal_logo || !record?.ip_logo
                ? "shape-avatar-letter"
                : ""
            } shape-avatar`}
            shape="round"
            size={40}
            src={getImageURL(record?.deal_logo || !record?.ip_logo || "")}
          >
            {getFirstLater(record?.deal_title || record?.ip_title || "")}
          </Avatar>
          <div className="avatar-info">
            <Title level={5}>{record?.deal_title || record?.ip_title}</Title>
          </div>
        </Avatar.Group>
      );
    },
  },
  {
    title: ({ sortColumns }) => {
      const sortedColumn = sortColumns?.find(({ column }) => {
        return column.key === "committed_amount";
      });

      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          Invested amount
          {sortedColumn?.order ? (
            sortedColumn.order === "ascend" ? (
              <ArrowUpOutlined style={{ marginLeft: "8px" }} />
            ) : (
              <ArrowDownOutlined style={{ marginLeft: "8px" }} />
            )
          ) : null}
        </div>
      );
    },
    sorter: (a, b) => {
      if (a.committed_amount) {
        return a?.committed_amount - b?.committed_amount;
      } else {
        return a?.price - b?.price;
      }
    },
    dataIndex:
      location?.pathname === "/ip-investments" ? "price" : "committed_amount",
    key:
      location?.pathname === "/ip-investments" ? "price" : "committed_amount",
  },
  {
    title: "Investor",
    key: "Investor",
    render: (text, record) => {
      return (
        <Avatar.Group>
          <Avatar
            className={`${
              !record?.investor_photo || record?.investor?.logo
                ? "shape-avatar-letter"
                : ""
            } shape-avatar`}
            shape="round"
            size={30}
            src={getImageURL(
              record?.investor_photo || record?.investor?.logo || ""
            )}
          >
            {getFirstLater(record?.investor?.name || record?.investor || "")}
          </Avatar>
          <div className="avatar-info">
            <Title level={5}>
              {record?.investor?.name || record?.investor || ""}
            </Title>
          </div>
        </Avatar.Group>
      );
    },
  },
  {
    title: (
      <div style={{ display: "flex", alignItems: "center" }}>
        Status <QuestionCircleOutlined style={{ marginLeft: "8px" }} />
      </div>
    ),
    dataIndex: "status",
    key: "status",
    render: (status) => {
      let backgroundColor = "";
      let textColor = "";
      if (status === "Completed") {
        textColor = "#1F2937";
        backgroundColor = "#F3F4F6";
      } else if (status === "In Progress") {
        textColor = "#92400E";
        backgroundColor = "#FEF3C7";
      } else if (status === "Active") {
        textColor = "#027A48";
        backgroundColor = "#ECFDF3";
      } else if (status === "In Review") {
        textColor = "#0B5394";
        backgroundColor = "#F0F9FF";
      }
      return (
        <Tag className="tag_text" color={backgroundColor}>
          <span
            style={{
              color: textColor,
              fontFamily: "Manrope",
              fontStyle: "normal",
              fontWeight: 500,
              fontSize: "14px",
              lineHeight: "20px",
              letterSpacing: "0.01em",
            }}
          >
            &#9679;&nbsp;{status}
          </span>
        </Tag>
      );
    },
  },
  {
    render: (text, record) => {
      const getType = (status) => {
        let type = "";

        if (status === "In Review") {
          type = "Investment";
        }

        if (status === "Active") {
          type = "Transfer";
        }

        if (status === "Compliance Check") {
          type = "Investment";
        }

        if (status === "In Progress") {
          type = "";
        }

        return type;
      };

      return record.status === "In Review" ||
        record.status === "Active" ||
        record.status === "Compliance Check" ||
        record.status === "In Progress" ? (
        <Dropdown
          overlay={
            <AdminDealComplianceCheckMenu
              record={record}
              dispatch={dispatch}
              type={getType(record.status)}
            />
          }
          trigger="click"
          placement="bottomLeft"
        >
          <Icon component={DropdownSvg} className="dropdown-menu-button" />
        </Dropdown>
      ) : null;
    },
  },
];

const adminInvestDeviceColumns = (dispatch, navigate) => [
  {
    render: (text, record) => {
      let backgroundColor = "";
      let textColor = "";
      if (record.status === "Completed") {
        textColor = "#1F2937";
        backgroundColor = "#F3F4F6";
      } else if (record.status === "In Progress") {
        textColor = "#92400E";
        backgroundColor = "#FEF3C7";
      } else if (record.status === "Active") {
        textColor = "#027A48";
        backgroundColor = "#ECFDF3";
      } else if (record.status === "In Review") {
        textColor = "#0B5394";
        backgroundColor = "#F0F9FF";
      }

      const getType = (status) => {
        let type = "";

        if (status === "In Review") {
          type = "Investment";
        }

        if (status === "Active") {
          type = "Transfer";
        }

        if (status === "Compliance Check") {
          type = "Investment";
        }

        if (status === "In Progress") {
          type = "";
        }

        return type;
      };

      return (
        <Card
          className="deal-table-card"
          title={
            <Avatar.Group>
              <Avatar
                className={`${
                  !record?.deal_logo || !record?.ip_logo
                    ? "shape-avatar-letter"
                    : ""
                } shape-avatar`}
                shape="round"
                size={40}
                src={getImageURL(record?.deal_logo || record?.ip_logo || "")}
              >
                {getFirstLater(record?.deal_title || record?.ip_title || "")}
              </Avatar>
              <div className="avatar-info">
                <Title level={5}>
                  {record?.deal_title || record?.ip_title || ""}
                </Title>
              </div>
            </Avatar.Group>
          }
          extra={
            <div>
              <Tag className="tag_text" color={backgroundColor}>
                <span
                  style={{
                    color: textColor,
                    fontFamily: "Manrope",
                    fontStyle: "normal",
                    fontWeight: 500,
                    fontSize: "14px",
                    lineHeight: "20px",
                    letterSpacing: "0.01em",
                  }}
                >
                  &#9679;&nbsp;{record.status}
                </span>
              </Tag>
              {record.status === "In Review" ||
              record.status === "Active" ||
              record.status === "Compliance Check" ||
              record.status === "In Progress" ? (
                <Dropdown
                  overlay={
                    <AdminDealComplianceCheckMenu
                      record={record}
                      dispatch={dispatch}
                      type={getType(record.status)}
                    />
                  }
                  trigger="click"
                  placement="bottomLeft"
                >
                  <Icon
                    component={DropdownSvg}
                    className="dropdown-menu-button"
                  />
                </Dropdown>
              ) : null}
            </div>
          }
        >
          <Card.Grid className="card-grid">
            <div className="card-grid__item">
              <p>Invested amount</p>
              <Title level={5}>
                {record?.committed_amount || record?.price}
              </Title>
            </div>
          </Card.Grid>
          <Card.Grid className="card-grid">
            <div className="card-grid__item">
              <p>Investor</p>
              <Avatar.Group>
                <Avatar
                  className={`${
                    !record?.investor_photo ? "shape-avatar-letter" : ""
                  } shape-avatar`}
                  shape="round"
                  size={30}
                  src={getImageURL(
                    record.investor_photo || record.investor.logo || ""
                  )}
                >
                  {getFirstLater(
                    record?.investor.name || record.investor || ""
                  )}
                </Avatar>
                <div className="avatar-info">
                  <Title level={5}>
                    {record.investor.name || record.investor}
                  </Title>
                </div>
              </Avatar.Group>
            </div>
          </Card.Grid>
        </Card>
      );
    },
  },
];

export { adminInvestColumns, adminInvestDeviceColumns };
