import React from "react";
import { Col, Row, Tabs } from "antd";

import OverviewTab from "./tabs/overviewTab/OverviewTab";
import DetailsTab from "./tabs/detailsTab/DetailsTab";

import TransactionTab from "./tabs/transactionTab/TransactionTab";
import InvestorsTab from "./tabs/investorsTab/InvestorsTab";

import DataRoomTab from "./tabs/dataRoom/DataRoomTab";
import AnalyticsTab from "./tabs/analyticsTab/AnalyticsTab";

const { TabPane } = Tabs;

function AdminDealDetail({ dealDetail, userRole }) {
  return (
    <Row gutter={[24, 0]}>
      <Col sx={24} md={24} lg={24} xl={24}>
        <Tabs
          defaultActiveKey="2"
          className="profile-tab-custom"
          destroyInactiveTabPane={true}
        >
          <TabPane tab="Overview" key="1">
            <OverviewTab data={dealDetail} />
          </TabPane>
          <TabPane tab="Details" key="2">
            <DetailsTab data={dealDetail?.deal_info} />
          </TabPane>

          <TabPane tab="Investors" key="3">
            <InvestorsTab data={dealDetail} />
          </TabPane>

          <TabPane tab="Transactions" key="5">
            <TransactionTab data={dealDetail?.transaction_info} />
          </TabPane>

          <TabPane tab="Data Room" key="9">
            <DataRoomTab
              data={dealDetail?.deal_info}
              userRole={userRole}
              destroyInactiveTabPane={true}
            />
          </TabPane>
           <TabPane tab="Analytics" key="7">
            <AnalyticsTab
           data={dealDetail}
            />
          </TabPane>
        </Tabs>
      </Col>
    </Row>
  );
}

export default AdminDealDetail;
