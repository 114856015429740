import React from "react";

import { Typography, List, Button } from "antd";

import { useDispatch } from "react-redux";

import { giveIpPermissionToDocuments } from "../../redux/action";

const { Text } = Typography;

const UserPermissionList = ({ permissionsAsked, ipId }) => {
  const dispatch = useDispatch();

  return (
    <List
      itemLayout="horizontal"
      className="permission-list"
      dataSource={permissionsAsked}
      renderItem={(item) => (
        <List.Item
          actions={[
            <Button
              type="text"
              className="doccument-accept"
              onClick={() => {
                const formData = new FormData();
                if (Object.keys(item).includes("document_id")) {
                  formData.append("document_id", item?.document_id);
                }

                if (Object.keys(item).includes("add_document_id")) {
                  formData.append("add_document_id", item?.add_document_id);
                }
                formData.append("user_id", item?.user_id);
                formData.append("permission", "Accept");

                dispatch(giveIpPermissionToDocuments(formData));
              }}
            >
              Accept
            </Button>,
            <Button
              type="text"
              className="document-reject"
              onClick={() => {
                const formData = new FormData();
                if (Object.keys(item).includes("document_id")) {
                  formData.append("document_id", item?.document_id);
                }

                if (Object.keys(item).includes("add_document_id")) {
                  formData.append("add_document_id", item?.add_document_id);
                }
                formData.append("user_id", item?.user_id);
                formData.append("permission", "Reject");

                dispatch(giveIpPermissionToDocuments(formData));
              }}
            >
              Reject
            </Button>,
          ]}
        >
          <List.Item.Meta
            title={
              <Text>
                {item.user_name}
                <span style={{ color: "red", marginLeft: "5px" }}>
                  {item.time_ago}
                </span>
              </Text>
            }
            description={item.document_name}
          />
        </List.Item>
      )}
    />
  );
};

export default UserPermissionList;
