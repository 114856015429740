export const IPInvestment = () => {
  return (
    <svg
      width="39"
      height="37"
      viewBox="0 0 39 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.0221 7.12513L23.7271 5.59295C23.5502 4.67359 22.7422 4.00636 21.806 4.00636H17.1928C16.2565 4.00636 15.4485 4.67359 15.2716 5.59295L14.9766 7.12549"
        stroke="#6B7280"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M26.8897 7.02402L26.5 5C26.0877 2.85812 23.9888 1.40468 21.8075 1.40468C21.8075 1.40468 19.8888 1.40468 17.1944 1.40468C14.5 1.40468 13 3.39868 12.5 4.99991L12 7.02393"
        stroke="#6B7280"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18.5 30.7351H2.81934C2.22069 30.7351 1.73535 30.2498 1.73535 29.6511V8.20941C1.73535 7.61076 2.22069 7.12542 2.81934 7.12542H36.1816C36.7803 7.12542 37.2656 7.61076 37.2656 8.20941V24.9818V29.6511C37.2656 30.2498 36.7803 30.7351 36.1816 30.7351H34"
        stroke="#6B7280"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.8638 20.0232H5.80405C3.55695 20.0232 1.73535 18.2016 1.73535 15.9545V8.20928C1.73535 7.61056 2.22069 7.1253 2.81934 7.1253H36.1816C36.7803 7.1253 37.2656 7.61056 37.2656 8.20928V15.9545C37.2656 18.2016 35.444 20.0232 33.1969 20.0232H22.5495"
        stroke="#6B7280"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M21.8822 20.4526H17.1181C16.7498 20.4526 16.4512 20.154 16.4512 19.7857V18.6143C16.4512 17.4751 17.3746 16.5517 18.5137 16.5517H20.4866C21.6257 16.5517 22.5491 17.4751 22.5491 18.6143V19.7857C22.5491 20.154 22.2506 20.4526 21.8822 20.4526Z"
        stroke="#6B7280"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16.4512 20.439H22.5492"
        stroke="#6B7280"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M21.25 37C20.8358 37 20.5 36.6642 20.5 36.25V26.75C20.5 26.3358 20.8358 26 21.25 26C21.6642 26 22 26.3358 22 26.75V36.25C22 36.6642 21.6642 37 21.25 37Z"
        fill="#6B7280"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M26.25 37C25.8358 37 25.5 36.6642 25.5 36.25V22.75C25.5 22.3358 25.8358 22 26.25 22C26.6642 22 27 22.3358 27 22.75V36.25C27 36.6642 26.6642 37 26.25 37Z"
        fill="#6B7280"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M31.25 37C30.8358 37 30.5 36.6642 30.5 36.25V30.75C30.5 30.3358 30.8358 30 31.25 30C31.6642 30 32 30.3358 32 30.75V36.25C32 36.6642 31.6642 37 31.25 37Z"
        fill="#6B7280"
      />
    </svg>
  );
};
