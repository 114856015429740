/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useMemo } from "react";
import "antd/dist/antd.css";

import { Button, Col, List, Row, Typography, Upload } from "antd";

import Icon, { DeleteOutlined } from "@ant-design/icons";

import ArrowLeftOutlined from "@ant-design/icons/ArrowLeftOutlined";
import FileTextOutlined from "@ant-design/icons/FileTextOutlined";
import PlusOutlined from "@ant-design/icons/PlusOutlined";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { signDocsRequest, uploadSpvDocsRequest } from "../../redux/action";
import DocIframe from "../DocIframe";
import { SuccessSvg } from "../../../../assets/images/successSvg";

const keys = {
  "SPV Services Agreement - SPV Platform.docx": "spv_services_agreement",
  "Memorandum of Association - Private Companies Limited by Shares.docx":
    "memorandum_of_association",
  "Articles of Association - Private Companies Limited by Shares.docx":
    "articles_of_association",
};
const { Text } = Typography;

const dummyRequest = ({ file, onSuccess }) => {
  setTimeout(() => {
    onSuccess("ok");
  }, 0);
};

const SPVConditionForm = ({
  handleConfirm,
  visible,
  confirmPublish,
  prevStep,
  isRequiredButton,
}) => {
  const [documents, setDocuments] = useState([]);
  const [isIframeOpen, setIsIframeOpen] = useState(false);
  const { downloadLinks, envelopeUrl, signed, pending } = useSelector(
    (state) => state.createDeal
  );
  const [errorFiles, setErrorFiles] = useState(null);
  const dispatch = useDispatch();

  const handleSign = (key, value) => {
    const name = value.includes("SPV Services")
      ? "SPV Services Agreement"
      : "Memorandum of Association";

    const formData = new FormData();
    formData.append("document_name", name);
    dispatch(signDocsRequest({ key, formData }));
  };

  useEffect(() => {
    if (envelopeUrl) {
      setIsIframeOpen(true);
    } else {
      setIsIframeOpen(false);
    }
  }, [envelopeUrl]);

  useEffect(() => {
    if (documents.length < 3 && documents.length > 0) {
      setErrorFiles(`You need to upload ${3 - documents.length} documents`);
    } else {
      setErrorFiles(null);
    }

    if (documents.length === 3) {
      var formData = new FormData();
      const spv_services_agreement = documents.find((el) =>
        el.name.includes("SPV Services")
      );
      const memorandum_of_association = documents.find((el) =>
        el.name.includes("Memorandum of Association")
      );
      const articles_of_association = documents.find((el) =>
        el.name.includes("Articles of Association")
      );

      formData.append("spv_services_agreement", spv_services_agreement);
      formData.append("memorandum_of_association", memorandum_of_association);
      formData.append("articles_of_association", articles_of_association);

      dispatch(uploadSpvDocsRequest(formData));
    }
  }, [documents, setErrorFiles, dispatch]);

  const handleChange = (info) => {
    if (info.file.status === "uploading") {
      return;
    }

    if (info.file.status === "done") {
      setDocuments([...documents, info.file.originFileObj]);
    }
  };

  const FileList = () => {
    const editedSignedDocLinks = useSelector(
      (state) =>
        state.createDeal?.editedDeal?.deal_info ||
        state.dealDetails?.data?.deal_info
    );

    const allDocUploadedNotSigned = useSelector(
      (state) =>
        state.createDeal?.editedDeal?.deal_info?.allDocUploadedNotSigned
    );

    const {
      spv_documents,
      spv_services_agreement_status = null,
      memorandum_of_association_status = null,
    } = editedSignedDocLinks || {};

    const editingDocs = useMemo(() => {
      let buildSPVDocs = {};

      if (!spv_documents) {
        if (allDocUploadedNotSigned) {
          buildSPVDocs = {
            download_spv_service_agreement: "NO-URL",
            download_memorandum_of_association: "NO-URL",
            download_articles_of_association: "NO-URL",
          };
        } else {
          return null;
        }
      } else {
        buildSPVDocs = spv_documents;
      }

      return Object.entries(buildSPVDocs).map(([key, value]) => {
        let fileName = "";
        let fullName = "";
        let documentSignStatus = false;

        switch (key) {
          case "download_spv_service_agreement":
            fileName = "SPV Services Agreement - SPV Platform.docx";
            fullName = "spv_services_agreement";
            documentSignStatus = spv_services_agreement_status === "completed";
            break;
          case "download_memorandum_of_association":
            fileName =
              "Memorandum of Association - Private Companies Limited by Shares.docx";
            fullName = "memorandum_of_association";
            documentSignStatus =
              memorandum_of_association_status === "completed";
            break;
          default:
            fileName =
              "Articles of Association - Private Companies Limited by Shares.docx";
            fullName = "articles_of_association";
            documentSignStatus = "N / A";
        }
        return { name: fileName, url: value, fullName, documentSignStatus };
      });
    }, [
      spv_documents,
      spv_services_agreement_status,
      memorandum_of_association_status,
      allDocUploadedNotSigned,
    ]);

    const handleDelete = (index) => {
      editingDocs
        ? setDocuments(
            editingDocs.filter((_, innerIndex) => innerIndex !== index)
          )
        : setDocuments((prevDocs) => {
            return prevDocs.filter((_, innerIndex) => innerIndex !== index);
          });
    };

    return (
      <List
        itemLayout="horizontal"
        dataSource={documents?.length ? documents : editingDocs || []}
        renderItem={(item, index) => {
          const actions = [];

          const checkItemName =
            item.name.startsWith("Articles") && item.name.includes(" (")
              ? " ("
              : ".";

          const itemName = Object.keys(keys)
            .map((fileName) => fileName.split("."))
            .flat(2)
            .includes(
              item.name.split(
                item.name.startsWith("Articles") && checkItemName
              )[0]
            );

          // const getItemName = Object.en;

          if (
            signed.includes(keys[item.name]) ||
            itemName ||
            item.documentSignStatus
          ) {
            actions.push(<Icon component={SuccessSvg} />);
          } else {
            actions.push(
              <Button
                type="primary"
                htmlType="submit"
                onClick={() => handleSign(keys[item.name], item.name)}
                disabled={errorFiles}
                loading={pending === keys[item.name]}
              >
                Sign
              </Button>
            );
            actions.push(
              <DeleteOutlined
                style={{ fontSize: "22px" }}
                onClick={() => handleDelete(index)}
              />
            );
          }

          return (
            <List.Item actions={actions}>
              <List.Item.Meta
                avatar={<FileTextOutlined style={{ fontSize: "2.5rem" }} />}
                title={<Text className="lable">{item.name}</Text>}
                description={item.size ? item.size / 1000 : ""}
              />
            </List.Item>
          );
        }}
      />
    );
  };

  return (
    <div className={`create-deal-form ${visible ? "visible" : "hide"}`}>
      {envelopeUrl ? (
        <DocIframe
          isModalVisible={isIframeOpen}
          handleCancel={() => setIsIframeOpen(false)}
          url={envelopeUrl}
        />
      ) : null}

      {/* {downloadLinks ? (
        <Row gutter={[16, 16]} style={{ marginBottom: "15px" }}>
          {downloadButtons()}
        </Row>
      ) : null} */}

      <Row>
        <Col span={24} lg={24} xl={15}>
          <Upload
            customRequest={dummyRequest}
            onChange={handleChange}
            showUploadList={false}
            className="spv-documents"
            style={{
              width: "100%",
            }}
          >
            <Button
              type="dashed"
              block={true}
              style={{
                height: "60px",
                color: "rgba(107, 114, 128, 1)",
                backgroundColor: "rgba(249, 250, 251, 1)",
              }}
              //onClick={showModal}
            >
              <PlusOutlined />
              Add SPV document
            </Button>
          </Upload>
          {errorFiles ? <Text type="danger">{errorFiles}</Text> : null}
        </Col>
      </Row>

      <Row>
        <Col span={24} lg={24} xl={15} className="line">
          <FileList />
        </Col>
      </Row>

      {isRequiredButton && (
        <>
          <Row gutter={[0, 16]}>
            <Col span={24} lg={24} xl={15} className="space-between-row">
              <Button onClick={prevStep}>
                <ArrowLeftOutlined /> Back
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                onClick={() => confirmPublish(handleConfirm)}
                // disabled
              >
                Continue
              </Button>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};

export default SPVConditionForm;
