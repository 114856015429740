import React, { useState } from "react";
import "../../assets/styles/home.scss";
import { Form, Input } from "antd";
import { useNavigate } from "react-router";

const HomePage = ({ setIsAuthDone }) => {
  const navigate = useNavigate();
  const [password, setPassword] = useState("");

  const onSubmitHanlder = () => {
    if (password === "DKG2024T3") {
      setIsAuthDone(true);
      //navigate("/registration");
      navigate("/login");
    } else {
      alert("The password you entered is incorrect. Please try again.");
    }
  };

  return (
    <>
      <div className="home_wrapper">
        <h1 className="home-title-wrapper">Guest Area</h1>
        <Form className="form-input-wrapper">
          <div>
            <p className="home-page-text">Please enter the password below.</p>
          </div>
          <div>
            <Input
              className="form-input"
              placeholder="Password"
              autocomplete="off"
              type="password"
              onChange={(e) => setPassword(e.target.value)}
              required=""
              value={password}
            />
          </div>
          <div className="button">
            <button
              className="form-button"
              data-testid="submitButton"
              onClick={onSubmitHanlder}
            >
              Go
            </button>
          </div>
        </Form>
      </div>
    </>
  );
};

export default HomePage;
