import { call, put, takeLatest } from "redux-saga/effects";

import { getUserFlowApi } from "../../../api";

import {
  USER_FLOW_ERROR,
  USER_FLOW_REQUEST,
  USER_FLOW_SUCCESS,
} from "./constants";

function* requestUserFlow() {
  try {
    let { data } = yield call(getUserFlowApi);

    if (data) {
      yield put({ type: USER_FLOW_SUCCESS, data });
    }
  } catch (err) {
    yield put({ type: USER_FLOW_ERROR });
  }
}

function* UserFlowWatcher() {
  yield takeLatest(USER_FLOW_REQUEST, requestUserFlow);
}
export default UserFlowWatcher;
