import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../adminInvestment/style.scss";
import {
  adminInvestColumns,
  adminInvestDeviceColumns,
} from "../../components/Common/DealsTable/AdminInvestTableColumns";
import CommonTable from "../common/commonTable";
import { getAdminIPInvestmentRequest } from "./redux/action";
import { useLocation } from "react-router-dom";

const AdminIPInvestment = () => {
  const adminIPInvestmentList = useSelector(
    (state) => state.adminIPInvestmentList
  );
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    dispatch(getAdminIPInvestmentRequest());
  }, [dispatch]);

  const adminInvestmentKeys = [
    "All",
    "In Review",
    "Compliance Check",
    "Active",
    "In Progress",
    "Completed",
    "Rejected",
  ];

  return (
    <div className="admin-deals">
      <CommonTable
        columns={adminInvestColumns(dispatch, location)}
        deviceColumns={adminInvestDeviceColumns(dispatch)}
        allData={adminIPInvestmentList}
        keys={adminInvestmentKeys}
        isIp={true}
      />
    </div>
  );
};

export default AdminIPInvestment;
