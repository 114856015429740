import React from "react";
import { Button, Col, List, Row, Tag, Typography } from "antd";
import Title from "antd/lib/typography/Title";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useState } from "react";
import { getTitleForDetails, getValueForDetailsFromKey } from "../../constant";
import _ from "lodash";

const { Text } = Typography;

const MatchingToolTab = ({ matchingTools }) => {
  const [selectedTagId, setSelectedTagId] = useState("");
  const [selectedTagDetails, setSelectedTagDetails] = useState({});

  return (
    <>
      <Col className="title" sm={24}>
        <div className="dot"></div>
        <Title level={4}>Matching Tool</Title>
      </Col>
      {selectedTagId !== "" && (
        <Col sm={24}>
          <Button onClick={() => setSelectedTagId("")}>
            <ArrowLeftOutlined /> Back
          </Button>
        </Col>
      )}
      {selectedTagId === "" && (
        <Col sm={24} className="tag-list">
          {matchingTools.map((item) => {
            return (
              <Tag
                color="geekblue"
                key={item.id}
                className="matching-tag"
                onClick={() => {
                  setSelectedTagId(item.id);
                  let nonEmptyData = _.omitBy(
                    item,
                    (v) =>
                      v === null ||
                      v === "" ||
                      v === undefined ||
                      Array.isArray(v)
                  );
                  setSelectedTagDetails(nonEmptyData);
                }}
              >
                {item.title}
              </Tag>
            );
          })}
        </Col>
      )}
      {selectedTagId !== "" && (
        <List
          size="small"
          bordered={false}
          header={
            <Row className="header-left">
              <Col sm={24} className="flex-v-h-c flex-center">
                {selectedTagDetails?.logo && (
                  <img
                    src={
                      "https://platform.dkv.global/files/" +
                      selectedTagDetails.logo
                    }
                    alt="img"
                    className="img"
                  />
                )}
              </Col>
            </Row>
          }
          footer={<Text>{selectedTagDetails?.description}</Text>}
          dataSource={Object.entries(selectedTagDetails).filter(
            (item) =>
              item[0] !== "id" && item[0] !== "logo" && item[0] !== "slug"
          )}
          renderItem={(item) => {
            const [key, value] = item;
            if (key === "description") {
              return;
            }
            return (
              <List.Item className="flex-v-h-c justify-flex-start">
                <Col sm={6} md={8} xl={8}></Col>
                <Col sm={6}>
                  <Title level={5}>
                    {getTitleForDetails(key) || key}
                  </Title>
                </Col>
                <Col sm={8}>
                  :&nbsp;&nbsp;
                  {getValueForDetailsFromKey(key, value) || (
                    <Text>{value}</Text>
                  )}
                </Col>
                <Col sm={2}></Col>
              </List.Item>
            );
          }}
        />
      )}
    </>
  );
};

export default MatchingToolTab;
