import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import { Avatar, Card, Col, Row, Spin, Tag, Image, Tooltip } from "antd";
import Title from "antd/lib/typography/Title";
import Paragraph from "antd/lib/typography/Paragraph";
import { LoadingOutlined } from "@ant-design/icons";

import MarketPlaceTabHeader from "../../components/Common/MarketPlaceTabHeader/MarketPlaceTabHeader";
import { ipMarketplaceListRequest } from "./redux/action";
import { getFirstLater, getImageURL, numberFormatter } from "../../Utils";
import FallbackImg from "../../assets/images/default-fallback-image.png";
import "../marketplacePage/style.scss";
import { BASE_URL } from "../../api";

const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;

const colors = [
  "magenta",
  "volcano",
  "orange",
  "gold",
  "lime",
  "green",
  "cyan",
  "blue",
  "geekblue",
  "purple",
  "volcano",
  "green",
];

const OrganizeCard = ({ record }) => {
  const navigate = useNavigate();
  const actions = [
    <div className="marketplace-action">
      <p className="marketplace-action__label">Price</p>
      <Title level={5} className="marketplace-action__title">
        {(numberFormatter.formatter(record?.price) || 0) + " USD"}
      </Title>
    </div>,
  ];

  return (
    <Card
      className="marketplace flex-1"
      onClick={() => {
        navigate(`/ipDetails/${record?.id}`);
      }}
      cover={
        <div className="card-cover-img">
          <div
            style={{
              overflow: "hidden",
              height: "220px",
              background: "rgb(3, 129, 169, 0.3)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Image
              className="cover-img"
              alt="example"
              src={BASE_URL + record?.ip_logo || FallbackImg}
              fallback={FallbackImg}
              preview={false}
            />
            {!record.is_published && (
              <div className="cover-avatar-group__status">Unpublished</div>
            )}
          </div>
          <div className="cover-avatar-group">
            <Tooltip title={record?.creator_details?.name}>
              <Avatar
                shape="round"
                size={60}
                src={getImageURL(record?.creator_details?.photo || "")}
                className={`${
                  !record?.creator_details?.photo ? "shape-avatar-letter" : ""
                } shape-avatar cover-avatar-group__avatar`}
              >
                {getFirstLater(record?.title || "")}
              </Avatar>
            </Tooltip>
          </div>
        </div>
      }
      actions={actions}
    >
      <div className="marketplace-body">
        <Title
          level={4}
          className="marketplace-body__title"
          ellipsis={{ tooltip: record?.title }}
          style={{ width: "90%" }}
        >
          {record.title}
        </Title>
        <Paragraph
          className="marketplace-body__description"
          ellipsis={{ rows: 3, tooltip: record?.description }}
        >
          {record?.description}
        </Paragraph>

        <div className="sector" style={{ marginBottom: "15px" }}>
          {record.sector?.map((el) => {
            return (
              <Tag color={colors[el?.id]} key={el.id}>
                {el.sector_name}
              </Tag>
            );
          })}
        </div>
      </div>
    </Card>
  );
};

function IPMarketplacePage() {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const searchValue = searchParams.get("value");

  const { data = [], loading } = useSelector(
    (state) => state.ipMarketplaceList
  );
  const [search, setSearch] = useState("");

  useEffect(() => {
    if (searchValue) {
      setSearch(searchValue);
    }
  }, [searchValue]);

  useEffect(() => {
    dispatch(ipMarketplaceListRequest());
  }, [dispatch]);

  const marketplace = useMemo(() => {
    return data.filter((item) => {
      return (
        !search ||
        (search &&
          item?.title.toString().toLowerCase().includes(search?.toLowerCase()))
      );
    });
  }, [data, search]);

  const searchFilter = (e) => {
    setSearch(e.target.value);
    setSearchParams("");
  };

  return (
    <>
      {loading && (
        <div className="spinner_background">
          <Spin className="spinner" indicator={antIcon} />
        </div>
      )}
      <MarketPlaceTabHeader
        setVisible={false}
        searchFilter={searchFilter}
        search={search}
      />
      <Row gutter={[30, 30]}>
        {marketplace.map((el) => {
          return (
            <Col md={8} sm={24} key={el.id} className="d-flex-card">
              <OrganizeCard record={el} />
            </Col>
          );
        })}
      </Row>
    </>
  );
}

export default IPMarketplacePage;
