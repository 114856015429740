import CommentForm from "./CommentForm";
import React, { useState } from "react";
import { Comment, Tooltip, Avatar, Tag, Row, Col } from "antd";
import moment from "moment";

const CommentCard = ({
  comment,
  replies,
  setActiveComment,
  activeComment,
  updateComment,
  deleteComment,
  addComment,
  parentId = null,
  currentUserId,
}) => {
  const isEditing =
    activeComment &&
    activeComment.id === comment.id &&
    activeComment.type === "editing";
  const isReplying =
    activeComment &&
    activeComment.id === comment.id &&
    activeComment.type === "replying";
  const fiveMinutes = 300000;
  const timePassed = new Date() - new Date(comment.createdAt) > fiveMinutes;
  const canDelete =
    currentUserId === comment.userId && replies.length === 0 && !timePassed;
  const canReply = Boolean(currentUserId);
  const canEdit = currentUserId === comment.userId && !timePassed;
  const replyId = parentId ? parentId : comment.id;
  const createdAt = new Date(comment.createdAt).toLocaleDateString();
  const [likes, setLikes] = useState(0);
  const [dislikes, setDislikes] = useState(0);
  const [action, setAction] = useState(null);

  const like = () => {
    setLikes(1);
    setDislikes(0);
    setAction("liked");
  };

  const dislike = () => {
    setLikes(0);
    setDislikes(1);
    setAction("disliked");
  };

  const actions = [
    // <Tooltip key="comment-basic-like" title="Like">
    //     <span onClick={like}>
    //         {createElement(action === "liked" ? LikeFilled : LikeOutlined)}
    //         <span className="comment-action">{likes}</span>
    //     </span>
    // </Tooltip>,
    // <Tooltip key="comment-basic-dislike" title="Dislike">
    //     <span onClick={dislike}>
    //         {React.createElement(
    //             action === "disliked" ? DislikeFilled : DislikeOutlined
    //         )}
    //         <span className="comment-action">{dislikes}</span>
    //     </span>
    // </Tooltip>,
    // <div className="comment_actions">
    //     <Tooltip title={moment().format("YYYY-MM-DD HH:mm:ss")}>
    //         <small>{moment().fromNow()}</small>
    //     </Tooltip>
    //     {"."}
    //
    // </div>,
  ];
  return (
    <Row gutter={[24, 24]}>
      <Col>
        <div key={comment.id} className="comment_list">
          <Comment
            actions={actions}
            author={
              <>
                <span className="author_name_deals_details">
                  {comment.username}
                </span>
                <Tag>{comment.tag}</Tag>
              </>
            }
            avatar={
              <Avatar src="https://joeschmoe.io/api/v1/random" alt="Han Solo" />
            }
            content={
              <div className="comment-right-part">
                {!isEditing && (
                  <div className="comment-text">{comment.body}</div>
                )}
                {isEditing && (
                  <CommentForm
                    submitLabel="Update"
                    hasCancelButton
                    initialText={comment.body}
                    handleSubmit={(text) => updateComment(text, comment.id)}
                    handleCancel={() => {
                      setActiveComment(null);
                    }}
                  />
                )}
                <div className="comment_actions">
                  <Tooltip title={moment().format("YYYY-MM-DD HH:mm:ss")}>
                    <small>{moment().fromNow()}</small>
                  </Tooltip>
                  {canReply && (
                    <div
                      className="comment-action"
                      onClick={() =>
                        setActiveComment({
                          id: comment.id,
                          type: "replying",
                        })
                      }
                    >
                      <div className="reply_container">
                        <span className="reply_dot">.</span>
                        <span className="reply_text">Reply</span>
                      </div>
                    </div>
                  )}{" "}
                </div>

                {isReplying && (
                  <CommentForm
                    submitLabel="Reply"
                    handleSubmit={(text) => addComment(text, replyId)}
                  />
                )}
                {replies.length > 0 && (
                  <div className="comment_list">
                    {replies.map((reply) => (
                      <Comment
                        // actions={reply}
                        // author={<span>{currentUserId}</span>}
                        // avatar={
                        //     <Avatar
                        //         src="https://joeschmoe.io/api/v1/random"
                        //         alt="Han Solo"
                        //     />
                        // }
                        content={
                          <CommentCard
                            comment={reply}
                            key={reply.id}
                            setActiveComment={setActiveComment}
                            activeComment={activeComment}
                            updateComment={updateComment}
                            deleteComment={deleteComment}
                            addComment={addComment}
                            parentId={comment.id}
                            replies={[]}
                            currentUserId={currentUserId}
                          />
                        }
                      />
                    ))}
                  </div>
                )}
              </div>
            }
          />
        </div>
      </Col>
    </Row>
  );
};

export default CommentCard;
