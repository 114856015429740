export const SuccessSvg = () => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="28" height="28" rx="14" fill="#D1FAE5" />
    <path
      d="M12.6665 16.115L18.7945 9.98633L19.7378 10.929L12.6665 18.0003L8.42383 13.7577L9.36649 12.815L12.6665 16.115Z"
      fill="#027A48"
    />
  </svg>
);
