import { Button, Col, Row, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Spin } from "antd";
import { isUserBelongsToDeal } from "../../../../Utils";
import { getUser } from "../../../../Utils/isAuth";
import { LoadingOutlined } from "@ant-design/icons";
import DocIframe from "../../../createDeal/forms/DocIframe";
import { getAllUsersAction } from "../../../chatPage/redux/action";
import {
  askedPermissionToDocumentsIp,
  clearUserIpDoc,
  getIpDataRoomDocument,
} from "../../redux/action";
import { UPLOAD_SIGN_ANY_IP_DOCS_REQUEST } from "../../redux/constants";

import DocumentsList from "./DocumentsList";
import UploadModalForm from "./UploadModalForm";
import UserPermissionList from "./UserPermissionList";

const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;

const DataRoomTab = ({ data = {} }) => {
  const dispatch = useDispatch();
  const user = getUser();
  const [open, setOpen] = useState(false);
  const [uploadSection, setUploadSection] = useState(null);
  const [isIframeOpen, setIsIframeOpen] = useState(false);
  const [editDoc, setEditDoc] = useState(null);
  const [selectedInvestor, setSelectedInvestor] = useState(user?.user_id);

  const [corporateDocuments, setCorporateDocuments] = useState([]);
  const [investmentDocuments, setInvestmentDocuments] = useState([]);
  const [ipDocs, setIpDocs] = useState([]);

  let {
    documents: { userDocuments },
    permissions,
  } = useSelector((state) => state?.ipDetails);
  let { documents, envelopeUrl } = useSelector((state) => state?.ipDetails);

  const hasUploadPermission = (key) => {
    switch (key) {
      case "corporate_documents":
        return (
          user?.user_id === data?.ip_info?.creator_details?.id ||
          user?.user_id === data?.creator_details?.id ||
          user?.user_id === data?.ips?.id
        );
      case "investment_documents":
      case "ip_documents":
        return isUserBelongsToDeal(data?.ip_info || data?.ips || data);
      default:
        return false;
    }
  };

  useEffect(() => {
    if (envelopeUrl) {
      setIsIframeOpen(true);
    } else {
      setIsIframeOpen(false);
    }
  }, [envelopeUrl]);

  useEffect(() => {
    if (editDoc) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [editDoc]);

  useEffect(() => {
    dispatch(getAllUsersAction());
    dispatch(
      askedPermissionToDocumentsIp({
        ipId: data?.ip_info?.id || data?.id || data?.ips?.id,
      })
    );
    dispatch(
      getIpDataRoomDocument({
        ip_id: data?.ip_info?.id || data?.id || data?.ips?.id,
      })
    );
    return () => {
      dispatch(clearUserIpDoc());
    };
  }, [data]);

  const handleCloseModal = useCallback(() => {
    setEditDoc(null);
    setUploadSection(null);
    setOpen(false);
  }, []);

  useEffect(() => {
    if (userDocuments?.corporate_documents) {
      let documents = [];
      userDocuments?.corporate_documents?.forEach((element) => {
        documents = [...documents, ...element?.documents];
      });

      setCorporateDocuments(documents);
    }
    if (userDocuments?.investment_documents) {
      setInvestmentDocuments(userDocuments?.investment_documents);
    }
    if (userDocuments?.ip_documents) {
      let documents = [];
      userDocuments?.ip_documents?.forEach((element) => {
        documents = [...documents, ...element?.documents];
      });
      setIpDocs(documents);
    }
  }, [userDocuments]);

  if (documents?.loading || permissions?.loading) {
    return (
      <div className="spinner_background">
        <Spin className="spinner" indicator={antIcon} />
      </div>
    );
  }

  return (
    <>
      <Row gutter={[24, 0]}>
        <UploadModalForm
          open={open}
          setOpen={setOpen}
          uploadSection={uploadSection}
          ipId={data?.ip_info?.id || data?.ips?.id || data?.id}
          editDoc={editDoc}
          handleClose={handleCloseModal}
          selectedInvestor={selectedInvestor}
        />
        {envelopeUrl && (
          <DocIframe
            isModalVisible={isIframeOpen}
            handleCancel={() => setIsIframeOpen(false)}
            url={envelopeUrl}
            actionType={UPLOAD_SIGN_ANY_IP_DOCS_REQUEST}
          />
        )}
        {permissions?.permissionsAsked?.length > 0 ? (
          <>
            <Col
              xs={24}
              lg={20}
              style={{
                marginBottom: "20px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <h2 className="financial_title">Requested Permissions</h2>
            </Col>
            <Col
              xs={24}
              lg={20}
              style={{
                marginBottom: "20px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <UserPermissionList
                permissionsAsked={permissions?.permissionsAsked}
                ipId={data?.ip_info?.id || data?.ips?.id || data?.id}
              />
            </Col>
          </>
        ) : null}

        <Col
          xs={24}
          lg={20}
          style={{
            marginBottom: "20px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <h2 className="financial_title">Corporate Documents</h2>
          {hasUploadPermission("corporate_documents") ? (
            <Button
              type="primary"
              onClick={() => {
                setUploadSection("corporate_documents");
                setOpen(true);
              }}
            >
              Upload Document
            </Button>
          ) : null}
        </Col>

        <Col xs={24} lg={20} style={{ marginBottom: "20px" }}>
          <DocumentsList
            documents={corporateDocuments}
            setEditDoc={setEditDoc}
            ipId={data?.ip_info?.id || data?.ips?.id || data?.id}
          />
        </Col>
        <Col
          xs={24}
          lg={20}
          style={{
            marginBottom: "20px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <h2 className="financial_title">Investment Documents</h2>

          {hasUploadPermission("investment_documents") ? (
            <Button
              type="primary"
              onClick={() => {
                setUploadSection("investment_documents");
                setOpen(true);
              }}
            >
              Upload Document
            </Button>
          ) : null}
        </Col>

        <Col xs={24} lg={20} style={{ marginBottom: "20px" }}>
          {investmentDocuments.length > 0 ? (
            <Tabs
              defaultActiveKey="1"
              tabPosition={"top"}
              className="investment-documents-list"
              onChange={function (activeKey) {
                setSelectedInvestor(activeKey);
              }}
            >
              {investmentDocuments.map((el, index) => {
                const isStartupTab = Object.keys(el).includes("startup_id");

                return (
                  <Tabs.TabPane
                    tab={isStartupTab ? "Company Documents" : el?.user_name}
                    key={`${isStartupTab ? el?.startup_id : el?.investor_id}`}
                  >
                    <DocumentsList
                      documents={el?.documents || []}
                      setEditDoc={setEditDoc}
                      ipId={data?.ip_info?.id || data?.ips?.id || data?.id}
                    />
                  </Tabs.TabPane>
                );
              })}
            </Tabs>
          ) : (
            <DocumentsList
              documents={[]}
              ipId={data?.ip_info?.id || data?.ips?.id || data?.id}
            />
          )}
        </Col>
        <Col
          xs={24}
          lg={20}
          style={{
            marginBottom: "20px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <h2 className="financial_title">Ip Documents</h2>
          {hasUploadPermission("ip_documents") && (
            <Button
              type="primary"
              onClick={() => {
                setUploadSection("ip_documents");
                setOpen(true);
              }}
            >
              Upload Document
            </Button>
          )}
        </Col>
        <Col xs={24} lg={20} style={{ marginBottom: "20px" }}>
          <DocumentsList
            documents={ipDocs}
            setEditDoc={setEditDoc}
            ipId={data?.ip_info?.id || data?.ips?.id || data?.id}
          />
        </Col>
      </Row>
    </>
  );
};

export default DataRoomTab;
