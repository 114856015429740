import { Button, Col, Row, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  isInvestor,
  isStartup,
  isSuperAdmin,
  isUserBelongsToDeal,
} from "../../../../Utils";
import { getUser } from "../../../../Utils/isAuth";

import { getAllUsersAction } from "../../../chatPage/redux/action";
import DocIframe from "../../../createDeal/forms/DocIframe";
import {
  askedPermissionToDocuments,
  clearUserDoc,
  getDataRoomDocument,
} from "../../redux/action";
import { UPLOAD_SIGN_ANY_DOCS_REQUEST } from "../../redux/constants";

import DocumentsList from "./DocumentsList";
import UploadModalForm from "./UploadModalForm";
import UserPermissionList from "./UserPermissionList";

const DataRoomTab = ({ data = {} }) => {
  const dispatch = useDispatch();
  const user = getUser();
  const [open, setOpen] = useState(false);
  const [uploadSection, setUploadSection] = useState(null);
  const [isIframeOpen, setIsIframeOpen] = useState(false);
  const [editDoc, setEditDoc] = useState(null);
  const [selectedInvestor, setSelectedInvestor] = useState(user?.user_id);

  const [corporateDocuments, setCorporateDocuments] = useState([]);
  const [investmentDocuments, setInvestmentDocuments] = useState([]);
  const [
    investmentMarketingDocuments,
    setInvestmentMarketingDocuments,
  ] = useState([]);

  const { userDocuments, envelopeUrl, permissionsAsked } = useSelector(
    (state) => state?.dealDetails
  );

  const hasUploadPermission = (section) => {
    if (isInvestor() && section === "investment_documents") {
      return isUserBelongsToDeal(data);
    }

    if (isSuperAdmin()) {
      return true;
    }

    return isUserBelongsToDeal(data) && isStartup();
  };

  useEffect(() => {
    if (envelopeUrl) {
      setIsIframeOpen(true);
    } else {
      setIsIframeOpen(false);
    }
  }, [envelopeUrl]);

  useEffect(() => {
    if (editDoc) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [editDoc]);

  useEffect(() => {
    // dispatch(getAllUsersAction());
    // dispatch(askedPermissionToDocuments({ dealId: data.id }));
    // dispatch(
    //   getDataRoomDocument({
    //     deal_id: data.id,
    //   })
    // );

    return () => {
      dispatch(clearUserDoc());
    };
  }, [data]);

  const handleCloseModal = useCallback(() => {
    setEditDoc(null);
    setUploadSection(null);
    setOpen(false);
  }, []);

  useEffect(() => {
    if (userDocuments?.corporate_documents) {
      let documents = [];
      userDocuments?.corporate_documents?.forEach((element) => {
        documents = [...documents, ...element?.documents];
      });

      setCorporateDocuments(documents);
    }

    if (userDocuments?.investment_documents) {
      setInvestmentDocuments(userDocuments?.investment_documents);
    }

    if (userDocuments?.investment_marketing_documents) {
      let documents = [];
      userDocuments?.investment_marketing_documents?.forEach((element) => {
        documents = [...documents, ...element?.documents];
      });

      setInvestmentMarketingDocuments(documents);
    }
  }, [userDocuments]);

  return (
    <>
      <Row gutter={[24, 0]}>
        <UploadModalForm
          open={open}
          setOpen={setOpen}
          uploadSection={uploadSection}
          dealId={data?.id}
          editDoc={editDoc}
          handleClose={handleCloseModal}
          selectedInvestor={selectedInvestor}
        />
        {envelopeUrl && (
          <DocIframe
            isModalVisible={isIframeOpen}
            handleCancel={() => setIsIframeOpen(false)}
            url={envelopeUrl}
            actionType={UPLOAD_SIGN_ANY_DOCS_REQUEST}
          />
        )}
        {permissionsAsked.length ? (
          <>
            <Col
              xs={24}
              lg={20}
              style={{
                marginBottom: "20px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <h2 className="financial_title">Requested Permissions</h2>
            </Col>
            <Col
              xs={24}
              lg={20}
              style={{
                marginBottom: "20px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <UserPermissionList permissionsAsked={permissionsAsked} />
            </Col>
          </>
        ) : null}

        <Col
          xs={24}
          lg={20}
          style={{
            marginBottom: "20px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <h2 className="financial_title">Corporate Documents</h2>
          {hasUploadPermission("corporate_documents") ? (
            <Button
              type="primary"
              onClick={() => {
                setUploadSection("corporate_documents");
                setOpen(true);
              }}
            >
              Upload Document
            </Button>
          ) : null}
        </Col>

        <Col xs={24} lg={20} style={{ marginBottom: "20px" }}>
          <DocumentsList
            documents={corporateDocuments}
            setEditDoc={setEditDoc}
            dealId={data?.id}
          />
        </Col>

        <Col
          xs={24}
          lg={20}
          style={{
            marginBottom: "20px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <h2 className="financial_title">Investment Documents</h2>

          {hasUploadPermission("investment_documents") ? (
            <Button
              type="primary"
              onClick={() => {
                setUploadSection("investment_documents");
                setOpen(true);
              }}
            >
              Upload Document
            </Button>
          ) : null}
        </Col>

        <Col xs={24} lg={20} style={{ marginBottom: "20px" }}>
          {investmentDocuments.length > 0 ? (
            <Tabs
              defaultActiveKey="1"
              tabPosition={"top"}
              className="investment-documents-list"
              onChange={function(activeKey) {
                setSelectedInvestor(activeKey);
              }}
            >
              {investmentDocuments.map((el, index) => {
                const isStartupTab = Object.keys(el).includes("startup_id");

                return (
                  <Tabs.TabPane
                    tab={isStartupTab ? "Company Documents" : el?.user_name}
                    key={`${isStartupTab ? el?.startup_id : el?.investor_id}`}
                  >
                    <DocumentsList
                      documents={el?.documents || []}
                      setEditDoc={setEditDoc}
                      dealId={data?.id}
                    />
                  </Tabs.TabPane>
                );
              })}
            </Tabs>
          ) : (
            <DocumentsList documents={[]} />
          )}
        </Col>
        <Col
          xs={24}
          lg={20}
          style={{
            marginBottom: "20px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <h2 className="financial_title">Investment Marketing Documents</h2>

          {hasUploadPermission("investment_marketing_documents") ? (
            <Button
              type="primary"
              onClick={() => {
                setUploadSection("investment_marketing_documents");
                setOpen(true);
              }}
            >
              Upload Document
            </Button>
          ) : null}
        </Col>

        <Col xs={24} lg={20} style={{ marginBottom: "20px" }}>
          <DocumentsList
            documents={investmentMarketingDocuments}
            setEditDoc={setEditDoc}
            dealId={data?.id}
          />
        </Col>
      </Row>
    </>
  );
};

export default DataRoomTab;
