import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CommonTable from "../common/commonTable";

import "../adminDeals/style.scss";
import { getAdminIpsListRequest } from "../adminIps/redux/action";

const ServiceOrganizerIps = ({ columns, deviceColumns }) => {
  const adminIpsListState = useSelector((state) => state.adminIpsList);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAdminIpsListRequest());
  }, [dispatch]);

  const adminIpsKeys = [
    "All",
    "In Review",
    "Compliance Check",
    "Active",
    "In Progress",
    "Completed",
  ];

  return (
    <div className="admin-deals">
      <CommonTable
        columns={columns}
        deviceColumns={deviceColumns}
        allData={{
          ...adminIpsListState,
          data: (adminIpsListState?.data || []).filter(
            (data) => !data.is_register_ip
          ),
        }}
        keys={adminIpsKeys}
        isIp={true}
      />
    </div>
  );
};

export default ServiceOrganizerIps;
