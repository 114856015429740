import React from "react";
import { Image, Row } from "antd";

import mainImg from "../../assets/images/main_img.png";
import "./slider.less";

function Slider({ width }) {
  return (
    <>
      <Row></Row>
      <Row justify="space-around align-content">
        <div className="slider-container">
          <div className="slider-descriptions slider-div">
            <p className="slider-descriptions-header">
              Deep Knowledge Group Projects Platform
            </p>
            <p className="slider-descriptions-text">
              End-to-End platform to discover, become involved with,
              <br />
              or invest in Deep Knowledge Group's current scope of market-ready products and projects
            </p>
          </div>
          <div className="slider-image">
            <Image preview={false} src={mainImg} width="50%" style={{ mixBlendMode: "multiply" }} />
          </div>

          {/* <div className="slider-descriptions">
            <p className="slider-descriptions-header">Important Information</p>
            <p className="slider-descriptions-info">
              This website is directed solely at investors who meet the UK
              Financial Conduct Authority (“FCA”) definitions of Eligible
              Counterparty or Professional Client, and should not be relied upon
              by other persons, such as Retail Clients, as defined under the
              FCA’s Rules.
            </p>
          </div> */}
        </div>
      </Row>
    </>
  );
}

export default Slider;
