import { call, put, takeLatest } from "redux-saga/effects";

import { getMAMarketplaceListApi } from "../../../api";

import {
  MA_MARKETPLACE_LIST_ERROR,
  MA_MARKETPLACE_LIST_SUCCESS,
  MA_MARKETPLACE_LIST_REQUEST,
} from "./constants";

function* requestMAMarketplaceList(action) {
  try {
    let { data } = yield call(getMAMarketplaceListApi, action?.data);

    if (data) {
      yield put({ type: MA_MARKETPLACE_LIST_SUCCESS, data });
    }
  } catch (err) {
    yield put({ type: MA_MARKETPLACE_LIST_ERROR });
  }
}

function* MAMarketplaceListWatcher() {
  yield takeLatest(MA_MARKETPLACE_LIST_REQUEST, requestMAMarketplaceList);
}
export default MAMarketplaceListWatcher;
