import { message, Modal } from "antd";
import { call, put, takeLatest } from "redux-saga/effects";

import {
  getIndividualProfile,
  updateProfile,
  getCompanyProfile,
  updateCompanyProfile,
  getCurrentPassword,
  postKybRequest,
  uploadIDProofIAPI,
  questionnaireStatic,
  sourceOfFund,
  setFacilitatorServicesApi,
  getFacilitatorServicesApi,
  getKybRequest,
  updateKybRequest,
  updateIDProofIAPI,
  updateQuestionnaireStatic,
  getQuestionnaireStatic,
  getKycRequest,
  getSourceOfFund,
  updateSourceOfFund,
  registerCompanyProfile,
  getSelectedServices,
  updateFacilitatorServicesApi,
  deleteSourceOfFund,
} from "../../../api";
import { refreshTokenAction } from "../../../redux/auth/action";
import { getUser } from "../../../Utils/isAuth";
import {
  GET_INDIVIDUAL_PROFILE_REQUEST,
  GET_INDIVIDUAL_PROFILE_REQUEST_SUCCESS,
  GET_INDIVIDUAL_PROFILE_REQUEST_ERROR,
  UPDATE_PROFILE_REQUEST,
  UPDATE_PROFILE_REQUEST_SUCCESS,
  UPDATE_PROFILE_REQUEST_ERROR,
  GET_COMPANY_PROFILE_REQUEST,
  GET_COMPANY_PROFILE_REQUEST_SUCCESS,
  GET_COMPANY_PROFILE_REQUEST_ERROR,
  UPDATE_COMPANY_PROFILE_REQUEST,
  UPDATE_COMPANY_PROFILE_REQUEST_SUCCESS,
  UPDATE_COMPANY_PROFILE_REQUEST_ERROR,
  GET_CURRENT_PASSWORD_REQUEST,
  GET_CURRENT_PASSWORD_REQUEST_SUCCESS,
  GET_CURRENT_PASSWORD_REQUEST_ERROR,
  SETTING_KYB_SUCCESS,
  SETTING_KYB_ERROR,
  SETTING_KYB_REQUEST,
  SETTING_KYC_REQUEST,
  SETTING_KYC_SUCCESS,
  SETTING_KYC_ERROR,
  SETTING_QUESTIONARE_REQUEST,
  SETTING_QUESTIONARE_SUCCESS,
  SETTING_QUESTIONARE_ERROR,
  SETTING_SOURCE_OF_FUNDS_SUCCESS,
  SETTING_SOURCE_OF_FUNDS_ERROR,
  SETTING_SOURCE_OF_FUNDS_REQUEST,
  GET_FAC_SETTING_SERVICES_SUCCESS,
  GET_FAC_SETTING_SERVICES_ERROR,
  GET_FAC_SETTING_SERVICES_REQUEST,
  SET_FAC_SETTING_SERVICES_REQUEST,
  SET_FAC_SETTING_SERVICES_SUCCESS,
  SET_FAC_SETTING_SERVICES_ERROR,
  GET_SETTING_KYB_SUCCESS,
  GET_SETTING_KYB_ERROR,
  GET_SETTING_KYB_REQUEST,
  GET_SETTING_QUESTIONARE_REQUEST,
  GET_SETTING_QUESTIONARE_SUCCESS,
  GET_SETTING_QUESTIONARE_ERROR,
  GET_SETTING_KYC_ERROR,
  GET_SETTING_KYC_SUCCESS,
  GET_SETTING_KYC_REQUEST,
  GET_SOURCE_OF_FUNDS_REQUEST,
  GET_SOURCE_OF_FUNDS_SUCCESS,
  GET_SOURCE_OF_FUNDS_ERROR,
  GET_FAC_SERVICES_REQUEST,
  GET_FAC_SERVICES_SUCCESS,
  GET_FAC_SERVICES_ERROR,
  DELETE_SOURCE_OF_FUNDS_REQUEST,
  DELETE_SOURCE_OF_FUNDS_SUCCESS,
  DELETE_SOURCE_OF_FUNDS_ERROR,
} from "./constants";

function* getIndividualProfileRequest(action) {
  // try {
  //   let { data } = yield call(getIndividualProfile, action?.data);

  //   if (data) {
  //     yield put({ type: GET_INDIVIDUAL_PROFILE_REQUEST_SUCCESS, data });
  //   }
  // } catch (err) {
  //   yield put({ type: GET_INDIVIDUAL_PROFILE_REQUEST_ERROR });
  // }
}

function* updateProfileRequest(action) {
  try {
    let { data } = yield call(updateProfile, action?.data);

    if (data.Msg) {
      Modal.success({
        title: data.Msg,
      });
      yield put({ type: UPDATE_PROFILE_REQUEST_SUCCESS, data });
      yield put({
        type: GET_INDIVIDUAL_PROFILE_REQUEST,
      });

      if (action.refreshData) {
        yield put(refreshTokenAction());
      }
    }
  } catch (err) {
    yield put({ type: UPDATE_PROFILE_REQUEST_ERROR });
  }
}

function* getCompanyProfileRequest(action) {
  try {
    let { data } = yield call(getCompanyProfile, action?.data);

    if (data) {
      yield put({ type: GET_COMPANY_PROFILE_REQUEST_SUCCESS, data });
    }
  } catch (err) {
    yield put({ type: GET_COMPANY_PROFILE_REQUEST_ERROR });
  }
}

function* updateCompanyProfileRequest(action) {
  const user = getUser();
  const object = {};
  action.data.forEach(function (value, key) {
    object[key] = value;
  });

  object.first_name = user.first_name;
  object.last_name = user.last_name;
  object.email = user.email;
  object.user = user.user_id;

  try {
    let resp;
    if (user?.onboardingStepsDone?.company_profile === "True") {
      resp = yield call(updateCompanyProfile, action.data);
      if (resp?.data?.Msg) {
        Modal.success({
          title: resp?.data?.Msg,
        });
      }
    } else {
      resp = yield call(registerCompanyProfile, object);
    }

    if (resp.data) {
      yield put({
        type: UPDATE_COMPANY_PROFILE_REQUEST_SUCCESS,
        data: resp.data,
      });
      yield put({ type: GET_COMPANY_PROFILE_REQUEST });

      if (action.refreshData) {
        yield put(refreshTokenAction());
      }
    }
  } catch ({ response }) {
    if (response) {
      let x = Object.entries(response.data.errors);
      x?.map((item) => {
        // Modal.error({
        //   title: "Error",
        //   content: item?.toString(),
        // })
        message.error(item?.toString(), [3]);
        return item;
      });
      // message?.error(item?.toString())
      yield put({
        type: UPDATE_COMPANY_PROFILE_REQUEST_ERROR,
        data: JSON.stringify(response?.data),
      });
    } else {
      yield put({ type: UPDATE_COMPANY_PROFILE_REQUEST_ERROR, data: "Error" });
    }
  }
}

function* getCurrentPasswordRequest(action) {
  try {
    let { data } = yield call(getCurrentPassword, action?.data);

    if (data) {
      yield put({ type: GET_CURRENT_PASSWORD_REQUEST_SUCCESS, data });
    }
  } catch (err) {
    yield put({ type: GET_CURRENT_PASSWORD_REQUEST_ERROR });
  }
}

function* setKYBRequest(action) {
  const user = getUser();
  try {
    let resp;
    if (user?.onboardingStepsDone?.kyb === "True") {
      resp = yield call(updateKybRequest, action.data);
      if (resp?.data?.Msg) {
        Modal.success({
          title: resp?.data?.Msg,
        });
      }
    } else {
      resp = yield call(postKybRequest, action.data);
    }

    if (resp.status === 201 || resp.status === 200) {
      yield put({
        type: SETTING_KYB_SUCCESS,
      });
      yield put({
        type: GET_SETTING_KYB_REQUEST,
      });

      if (action.refreshData) {
        yield put(refreshTokenAction());
      }
    }
  } catch ({ response }) {
    if (response) {
      yield put({
        type: SETTING_KYB_ERROR,
        data: JSON.stringify(response?.data),
      });
    } else {
      yield put({ type: SETTING_KYB_ERROR, data: "Error" });
    }
  }
}

function* getKYBRequest() {
  try {
    const resp = yield call(getKybRequest);

    if (resp.status === 201 || resp.status === 200) {
      yield put({
        type: GET_SETTING_KYB_SUCCESS,
        data: resp.data,
      });
    }
  } catch ({ response }) {
    if (response) {
      yield put({
        type: GET_SETTING_KYB_ERROR,
        data: JSON.stringify(response?.data),
      });
    } else {
      yield put({ type: GET_SETTING_KYB_ERROR, data: "Error" });
    }
  }
}

function* getKYCRequest() {
  try {
    const resp = yield call(getKycRequest);

    if (resp.status === 201 || resp.status === 200) {
      yield put({
        type: GET_SETTING_KYC_SUCCESS,
        data: resp.data,
      });
    }
  } catch ({ response }) {
    if (response) {
      yield put({
        type: GET_SETTING_KYC_ERROR,
        data: JSON.stringify(response?.data),
      });
    } else {
      yield put({ type: GET_SETTING_KYC_ERROR, data: "Error" });
    }
  }
}

function* setIDProofRequest(action) {
  const user = getUser();
  try {
    let resp;
    if (user?.onboardingStepsDone?.id_proof === "True") {
      resp = yield call(updateIDProofIAPI, action.data);
      if (resp?.data) {
        Modal.success({
          title: resp?.data?.msg || resp?.data?.Msg,
        });
      }
    } else {
      resp = yield call(uploadIDProofIAPI, action.data);
    }

    if (resp.status === 201 || resp.status === 200) {
      yield put({
        type: SETTING_KYC_SUCCESS,
      });

      yield put({
        type: GET_SETTING_KYC_REQUEST,
      });

      if (action.refreshData) {
        yield put(refreshTokenAction());
      }
    }
  } catch ({ response }) {
    if (response) {
      yield put({
        type: SETTING_KYC_ERROR,
        data: JSON.stringify(response?.data),
      });
    } else {
      yield put({ type: SETTING_KYC_ERROR, data: "Error" });
    }
  }
}
function* getQuestionaireRequest() {
  try {
    const resp = yield call(getQuestionnaireStatic);

    if (resp.status === 201 || resp.status === 200) {
      const data = {};
      resp.data.forEach((element) => {
        data[element?.name] = element?.answer;
      });
      yield put({
        type: GET_SETTING_QUESTIONARE_SUCCESS,
        data,
      });
    }
  } catch ({ response }) {
    if (response) {
      yield put({
        type: GET_SETTING_QUESTIONARE_ERROR,
        data: JSON.stringify(response?.data),
      });
    } else {
      yield put({ type: GET_SETTING_QUESTIONARE_ERROR, data: "Error" });
    }
  }
}

function* setQuestionaireRequest(action) {
  const user = getUser();
  try {
    let resp;
    if (user?.onboardingStepsDone?.questionnaire === "True") {
      resp = yield call(updateQuestionnaireStatic, action.data);
      if (resp.data.result === "OK") {
        Modal.success({
          title: "Questionnaire Updated",
        });
      }
    } else {
      resp = yield call(questionnaireStatic, action.data);
    }

    if (resp.status === 201 || resp.status === 200) {
      yield put({
        type: SETTING_QUESTIONARE_SUCCESS,
      });

      if (action.refreshData) {
        yield put(refreshTokenAction());
      }
    }
  } catch ({ response }) {
    if (response) {
      yield put({
        type: SETTING_QUESTIONARE_ERROR,
        data: JSON.stringify(response?.data),
      });
    } else {
      yield put({ type: SETTING_QUESTIONARE_ERROR, data: "Error" });
    }
  }
}

function* setSourceOfFundRequest(action) {
  const user = getUser();
  try {
    let resp;
    if (user?.onboardingStepsDone?.source_of_funds === "True") {
      resp = yield call(updateSourceOfFund, action.data);
    } else {
      resp = yield call(sourceOfFund, action.data);
    }

    if (resp.status === 201 || resp.status === 200) {
      yield put({
        type: SETTING_SOURCE_OF_FUNDS_SUCCESS,
      });
      yield put({
        type: GET_SOURCE_OF_FUNDS_REQUEST,
      });

      if (action.refreshData) {
        yield put(refreshTokenAction());
      }
    }
  } catch ({ response }) {
    if (response) {
      yield put({
        type: SETTING_SOURCE_OF_FUNDS_ERROR,
        data: JSON.stringify(response?.data),
      });
    } else {
      yield put({ type: SETTING_SOURCE_OF_FUNDS_ERROR, data: "Error" });
    }
  }
}

function* deleteSourceOfFundRequest(action) {
  try {
    const resp = yield call(deleteSourceOfFund, action.data);

    if (resp.status === 201 || resp.status === 200) {
      yield put({
        type: DELETE_SOURCE_OF_FUNDS_SUCCESS,
        data: resp.data,
      });
    }
  } catch ({ response }) {
    if (response) {
      yield put({
        type: DELETE_SOURCE_OF_FUNDS_ERROR,
        data: JSON.stringify(response?.data),
      });
    } else {
      yield put({ type: DELETE_SOURCE_OF_FUNDS_ERROR, data: "Error" });
    }
  }
}

function* getSourceOfFundRequest() {
  try {
    const resp = yield call(getSourceOfFund);

    if (resp.status === 201 || resp.status === 200) {
      yield put({
        type: GET_SOURCE_OF_FUNDS_SUCCESS,
        data: resp.data,
      });
    }
  } catch ({ response }) {
    if (response) {
      yield put({
        type: GET_SOURCE_OF_FUNDS_ERROR,
        data: JSON.stringify(response?.data),
      });
    } else {
      yield put({ type: GET_SOURCE_OF_FUNDS_ERROR, data: "Error" });
    }
  }
}

function* getPrevServcesRequest() {
  try {
    const resp = yield call(getSelectedServices);

    if (resp.status === 201 || resp.status === 200) {
      yield put({
        type: GET_FAC_SERVICES_SUCCESS,
        data: resp.data,
      });
    }
  } catch ({ response }) {
    if (response) {
      yield put({
        type: GET_FAC_SERVICES_ERROR,
        data: JSON.stringify(response?.data),
      });
    } else {
      yield put({ type: GET_FAC_SERVICES_ERROR, data: "Error" });
    }
  }
}

function* requestFacilitatorServices() {
  try {
    let { data } = yield call(getFacilitatorServicesApi);
    if (data) {
      yield put({ type: GET_FAC_SETTING_SERVICES_SUCCESS, data });
    }
  } catch ({ response }) {
    if (response) {
      yield put({
        type: GET_FAC_SETTING_SERVICES_ERROR,
        data: JSON.stringify(response?.data),
      });
    } else {
      yield put({ type: GET_FAC_SETTING_SERVICES_ERROR, data: "Error" });
    }
  }
}

function* requestSetFacilitatorServices(action) {
  const user = getUser();
  try {
    let resp;
    if (user?.onboardingStepsDone?.services === "True") {
      resp = yield call(updateFacilitatorServicesApi, action.data);
    } else {
      resp = yield call(setFacilitatorServicesApi, action.data);
    }

    if (resp.status === 201 || resp.status === 200) {
      yield put({
        type: SET_FAC_SETTING_SERVICES_SUCCESS,
      });
      message.success("Success");
      if (action.refreshData) {
        yield put(refreshTokenAction());
      }
    }
  } catch ({ response }) {
    if (response) {
      yield put({
        type: SET_FAC_SETTING_SERVICES_ERROR,
        data: JSON.stringify(response?.data),
      });
    } else {
      yield put({ type: SET_FAC_SETTING_SERVICES_ERROR, data: "Error" });
    }
  }
}

function* SettingsWatcher() {
  yield takeLatest(GET_INDIVIDUAL_PROFILE_REQUEST, getIndividualProfileRequest);
  yield takeLatest(UPDATE_PROFILE_REQUEST, updateProfileRequest);
  yield takeLatest(GET_COMPANY_PROFILE_REQUEST, getCompanyProfileRequest);
  yield takeLatest(UPDATE_COMPANY_PROFILE_REQUEST, updateCompanyProfileRequest);
  yield takeLatest(GET_CURRENT_PASSWORD_REQUEST, getCurrentPasswordRequest);
  yield takeLatest(SETTING_KYB_REQUEST, setKYBRequest);
  yield takeLatest(SETTING_KYC_REQUEST, setIDProofRequest);
  yield takeLatest(SETTING_QUESTIONARE_REQUEST, setQuestionaireRequest);
  yield takeLatest(SETTING_SOURCE_OF_FUNDS_REQUEST, setSourceOfFundRequest);
  yield takeLatest(DELETE_SOURCE_OF_FUNDS_REQUEST, deleteSourceOfFundRequest);
  yield takeLatest(
    GET_FAC_SETTING_SERVICES_REQUEST,
    requestFacilitatorServices
  );
  yield takeLatest(
    SET_FAC_SETTING_SERVICES_REQUEST,
    requestSetFacilitatorServices
  );
  yield takeLatest(GET_SETTING_KYB_REQUEST, getKYBRequest);
  yield takeLatest(GET_SETTING_QUESTIONARE_REQUEST, getQuestionaireRequest);
  yield takeLatest(GET_SETTING_KYC_REQUEST, getKYCRequest);
  yield takeLatest(GET_SOURCE_OF_FUNDS_REQUEST, getSourceOfFundRequest);
  yield takeLatest(GET_FAC_SERVICES_REQUEST, getPrevServcesRequest);
}
export default SettingsWatcher;
