import {
  LOGIN_REQUEST,
  SIGNUP_REQUEST,
  PAGINATION_REQUEST_NEXT,
  PAGINATION_REQUEST_PREVIOUS,
  PAGINATION_REQUEST_EXACT,
  ACCOUNT_SELECTION_REQUEST,
  INVESTOR_TYPE_REQUEST,
  REGISTER_PROFILE_REQUEST,
  REGISTER_IDENTITY_REQUEST,
  UPLOAD_IMAGE_REQUEST,
  ADD_BANK_REQUEST,
  FORGOT_PASSWORD_REQUEST,
  VERIFY_FORGOT_PASSWORD_REQUEST,
  CHANGE_PASSWORD_REQUEST,
  RESEND_SIGNUP_VERIFY_LINK,
  VERIFY_TOKEN_REQUEST,
  USER_ROLE_SUCCESS,
  USER_ROLE_REQUEST,
  POST_KYB_REQUEST,
  REGISTER_COMPANY_PROFILE_REQUEST,
  QUESTIONNAIRE_STATIC_REQUEST,
  SOURCE_OF_FUNDS_REQUEST,
  SOURCE_OF_FUNDS_TEMPLATE_REQUEST,
  ADD_INDIVIDUAL_BANK_REQUEST,
  REFRESH_TOKEN_REQUEST,
  FORGOT_PASSWORD_STATE_INITIAL,
  SOCIAL_LOGIN_REQUEST,
} from "./constants";

export const loginRequest = (data) => {
  return {
    type: LOGIN_REQUEST,
    data,
  };
};
export const signupRequest = (data) => {
  return {
    type: SIGNUP_REQUEST,
    data,
  };
};
export const accountSelectionRequest = (data) => {
  return {
    type: ACCOUNT_SELECTION_REQUEST,
    data,
  };
};
export const investorSelectionRequest = (data) => {
  return {
    type: INVESTOR_TYPE_REQUEST,
    data,
  };
};

export const registerProfileRequest = (data) => {
  return {
    type: REGISTER_PROFILE_REQUEST,
    data,
  };
};
export const registerIdentityRequest = (data) => {
  return {
    type: REGISTER_IDENTITY_REQUEST,
    data,
  };
};
export const uploadImageRequest = (data) => {
  return {
    type: UPLOAD_IMAGE_REQUEST,
    data,
  };
};

export const addBankRequest = (data) => {
  return {
    type: ADD_BANK_REQUEST,
    data,
  };
};
export const nextPage = (data) => {
  return {
    type: PAGINATION_REQUEST_NEXT,
    data,
  };
};

// export const movePrevious = () => {
// 	return {
// 		type: PAGINATION_REQUEST_NEXT,
// 		data,
// 	};
// };
export const PreviousPage = (data) => {
  return {
    type: PAGINATION_REQUEST_PREVIOUS,
    data,
  };
};
export const ExactPage = (data) => {
  return {
    type: PAGINATION_REQUEST_EXACT,
    data,
  };
};

export const forgotPasswordRequest = (data) => {
  return {
    type: FORGOT_PASSWORD_REQUEST,
    data,
  };
};

export const forgotPasswordStateInitial = (data) => {
  return {
    type: FORGOT_PASSWORD_STATE_INITIAL,
    data,
  };
};
export const verifyForgotPasswordRequest = (data) => {
  return {
    type: VERIFY_FORGOT_PASSWORD_REQUEST,
    data,
  };
};

export const changePasswordRequest = (data, refreshData = false) => {
  return {
    type: CHANGE_PASSWORD_REQUEST,
    data,
    refreshData,
  };
};

export const resendSignupVerifyLink = (data) => {
  return {
    type: RESEND_SIGNUP_VERIFY_LINK,
    data,
  };
};

export const userRoleSuccess = (data) => {
  return {
    type: USER_ROLE_SUCCESS,
    data,
  };
};

export const userRoleRequest = (data) => {
  return {
    type: USER_ROLE_REQUEST,
    data,
  };
};

export const verifyTokenRequest = (data) => {
  return {
    type: VERIFY_TOKEN_REQUEST,
    data,
  };
};

export const postKyb = (data) => {
  return {
    type: POST_KYB_REQUEST,
    data,
  };
};

export const registerCompanyProfileRequest = (data) => {
  return {
    type: REGISTER_COMPANY_PROFILE_REQUEST,
    data,
  };
};

export const questionnaireStaticRequest = (data) => {
  return {
    type: QUESTIONNAIRE_STATIC_REQUEST,
    data,
  };
};

export const sourceOfFundRequest = (data) => {
  return {
    type: SOURCE_OF_FUNDS_REQUEST,
    data,
  };
};

export const refreshTokenAction = () => {
  return {
    type: REFRESH_TOKEN_REQUEST,
  };
};

export const getSourceOfFundsTemplate = (data) => {
  return {
    type: SOURCE_OF_FUNDS_TEMPLATE_REQUEST,
    data,
  };
};

export const addIndividualBankRequest = (data) => {
  return {
    type: ADD_INDIVIDUAL_BANK_REQUEST,
    data,
  };
};

export const socialLoginRequest = (data) => ({ type: SOCIAL_LOGIN_REQUEST, data })
