import {
  SIGN_INVEST_DOCS_OPEN_REQUEST,
  SIGN_INVEST_DOCS_OPEN_SUCCESS,
  SIGN_INVEST_DOCS_OPEN_ERROR,
  INVEST_DEAL_INVEST_FALSE,
  SIGN_INVEST_DOCS_SUCCESS,
  GET_DEAL_INVESTING_ERROR,
  GET_DEAL_INVESTING_SUCCESS,
  GET_DEAL_INVESTING_REQUEST,
  INVEST_DEAL_REQUEST,
  INVEST_DEAL_SUCCESS,
  INVEST_DEAL_ERROR,
  CLEAR_INVEST_ERROR,
  CLEAR_INVEST_STORE,
  IP_INVEST_REQUEST,
  IP_INVEST_SUCCESS,
  IP_INVEST_ERROR,
  CREATE_IP_INVEST_REQUEST,
  CREATE_IP_INVEST_SUCCESS,
  CREATE_IP_INVEST_ERROR,
  CREATE_ESCROW_SUCCESS,
  CREATE_ESCROW_ERROR,
  CREATE_ESCROW_REQUEST,
  SIGN_INVEST_DOCS_CLOSE,
} from "./constants";

const initialState = {
  loading: false,
  signed: [],
  pending: null,
  dealInvesting: null,
  successMessage: null,
  errorMessage: null,
  memorandum_of_association: null,
  spv_services_agreement: null,
  dealID: null,
  deal_id: null,
  dealInvested: false,
  ipDetails: {},
  createIPDetails: {},
  escrowPending: false,
  escrowSuccess: false,
};

export default function investReducer(state = initialState, action) {
  switch (action.type) {
    case SIGN_INVEST_DOCS_OPEN_REQUEST:
      return { ...state, loading: true };
    case SIGN_INVEST_DOCS_OPEN_SUCCESS:
      return {
        ...state,
        [action.data.key]: action.data.envelope_id,
        envelopeUrl: action.data.envelope_url,
        pending: action.data.key,
        loading: false,
      };
    case SIGN_INVEST_DOCS_OPEN_ERROR:
      return { ...state, loading: false, envelopeUrl: null };
    case SIGN_INVEST_DOCS_CLOSE:
      return {
        ...state,
        envelopeUrl: null,
        pending: null,
        loading: false,
      };
    case SIGN_INVEST_DOCS_SUCCESS:
      return {
        ...state,
        envelopeUrl: null,
        signed: [...state.signed, state.pending],
        pending: null,
        loading: false,
      };
    case GET_DEAL_INVESTING_REQUEST:
    case IP_INVEST_REQUEST:
      return { ...state, loading: true };
    case GET_DEAL_INVESTING_SUCCESS:
      return {
        ...state,
        dealInvesting: action.data.dealInvesting,
        deal_id: action.data.deal_id,
        loading: false,
      };
    case IP_INVEST_SUCCESS:
      return {
        ...state,
        ipDetails: action.data,
        loading: false,
      };
    case GET_DEAL_INVESTING_ERROR:
    case IP_INVEST_ERROR:
      return { ...state, loading: false };
    case INVEST_DEAL_REQUEST:
      return { ...state, loading: true };
    case INVEST_DEAL_SUCCESS:
      return {
        ...state,
        dealInvested: true,
        loading: false,
      };
    case INVEST_DEAL_INVEST_FALSE:
      return {
        ...state,
        dealInvested: false,
      };
    case INVEST_DEAL_ERROR:
      return { ...state, errorMessage: action.data, loading: false };
    case CLEAR_INVEST_ERROR:
      return { ...state, errorMessage: "" };

    case CLEAR_INVEST_STORE:
      return initialState;

    case CREATE_IP_INVEST_REQUEST:
      return {
        ...state,
        createIPDetails: action.data,
      };
    case CREATE_IP_INVEST_SUCCESS:
      return {
        ...state,
        successMessage: action.data,
      };
    case CREATE_IP_INVEST_ERROR:
      return {
        ...state,
        errorMessage: action.data,
      };
    case CREATE_ESCROW_REQUEST:
      return {
        ...state,
        escrowPending: true,
      };
    case CREATE_ESCROW_SUCCESS:
      return {
        ...state,
        escrowSuccess: true,
        escrowPending: false,
      };
    case CREATE_ESCROW_ERROR:
      return {
        ...state,
        errorMessage: action.data,
        escrowPending: false,
      };

    default:
      return state;
  }
}
