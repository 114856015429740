import React from "react";

import {
  Row,
  Col,
  Card,
  Button,
  List,
  Avatar,
  Space,
  Tabs,
  Progress,
} from "antd";
import ReactPlayer from "react-player";

import profilavatar from "../assets/images/dealprofile.png";
import overview from "../assets/images/overview2.png";
import Financials from "../components/dashboardComponent/deal/Financials";
import Team from "../components/dashboardComponent/deal/Team";
import Conditions from "../components/dashboardComponent/deal/Conditions";
import Documents from "../components/dashboardComponent/deal/Documents";
import Discussions from "../components/dashboardComponent/deal/Discussions";
import { useNavigate } from "react-router-dom";
import sharebtn from "../assets/images/sharebtn.png";

const { TabPane } = Tabs;

const { Meta } = Card;
const overviewlist = [
  "Lorem ipsum dolor sit amet, consectetur adipiscing elit.Nulla maximus nisl vel dui porttitor, at accumsan enim lacinia.",
  "Praesent ac mi sodales, posuere nisi id, euismod ipsum.",
  "Fusce non odio id justo placerat venenatis.",
  "Cras sit amet orci sit amet ligula posuere eleifend sit amet ut magna.",
  "Suspendisse vitae nulla commodo, consectetur est nec, dictum leo.",
  "Vivamus ultricies tortor ac tortor fringilla bibendum.",
];
const proceedlist = [
  "- Marketing (inc Advertising) - 44%",
  "- Salaries (no Founders salaries being paid) - 29%",
  "- Fundraising Fees & Expenses - 6%",
  "- Product Development - 3%",
  "- Working Capital - 18%",
];

const DealDetail = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className="layout-content">
        <Card
          className="card-profile-head"
          bodyStyle={{ display: "none" }}
          title={
            <Row justify="space-between" align="middle" gutter={[24, 0]}>
              <Col span={24} md={12} className="col-info">
                <Avatar.Group>
                  <Avatar
                    size={74}
                    shape="square"
                    className="deals_profile_avatar"
                    src={profilavatar}
                  />

                  <div className="deal_title avatar-info">
                    <h4 className="m-0 ">Global Flowers</h4>
                    <p>
                      Company with a big perspective in future investigation!
                    </p>
                  </div>
                </Avatar.Group>
              </Col>
              <Col
                span={24}
                md={12}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <Col span={12}>
                  <Space className="setting_btn">
                    <Button className="share_btn_deals">
                      <img
                        src={sharebtn}
                        className="share_btn"
                        alt="Share button"
                      />
                    </Button>
                    <Button
                      className="invest_btn"
                      onClick={() => navigate("/invest")}
                    >
                      Invest
                    </Button>
                  </Space>
                </Col>
              </Col>
            </Row>
          }
        ></Card>
        <Row gutter={[24, 0]}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <div className="card_video_head">
              <ReactPlayer
                className="card_video_head"
                url="https://www.youtube.com/watch?v=ysz5S6PUM-U"
              />
            </div>
          </Col>

          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <div className="card_detail_head ">Raise complete -60%</div>
            <div className="card_detail_head card_details_deals_amount">
              $16000
            </div>
            <div>
              <Progress
                strokeColor={"#0B5394"}
                size="small"
                percent={60}
                showInfo={false}
              />
            </div>
            <List
              grid={{ gutter: 24, column: 2 }}
              dataSource={[]}
              bordered={false}
              renderItem={(item) => (
                <List.Item>
                  <Card className="deal_list " bordered={false}>
                    <div>{item.title}</div>
                    <Space>
                      <span className="days_left_count">{item.desc}</span>
                      {item.linkedinicon}
                      {item.fbicon}
                      {item.twittericon}
                    </Space>
                  </Card>
                </List.Item>
              )}
            />
          </Col>
        </Row>

        <Row gutter={[24, 0]}>
          <Col sx={24} md={24} lg={24} xl={24}>
            <Tabs defaultActiveKey="1" className="profile-tab-custom">
              <TabPane tab="Overview" key="1">
                <Row>
                  <Col sx={24} md={16} lg={18} xl={18}>
                    <List
                      size="small"
                      bordered={false}
                      className="overviewlist "
                      dataSource={overviewlist}
                      renderItem={(item) => (
                        <List.Item className="proceed_list">
                          <p className="overview_list">{item}</p>
                        </List.Item>
                      )}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sx={24} md={16} lg={18} xl={18}>
                    <Card
                      className="card_custom_head card_overview"
                      hoverable
                      bordered={false}
                      cover={<img alt="example" src={overview} />}
                      title={
                        <div>
                          <h3 className="intro_overview">Intro</h3>
                          <p className="intro_overview_description">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Vitae pellentesque morbi nunc vulputate nisl
                            <br /> gravida elit tortor. Et ullamcorper semper
                            platea euismod ut duis egestas ac. Amet,
                            pellentesque id at eros
                            <br /> leo sit tellus porttitor.
                          </p>
                        </div>
                      }
                      // title='hiiii'
                      bodyStyle={{ paddingLeft: "0" }}
                    >
                      <Meta
                        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vitae pellentesque morbi nunc vulputate nisl
                                            gravida elit tortor. Et ullamcorper semper platea euismod ut duis egestas ac. Amet, pellentesque id at eros
                                            leo sit tellus porttitor. Quisque nulla augue volutpat in mattis morbi lobortis. Ipsum vitae turpis purus
                                            feugiat. Odio lacus netus mauris turpis. Et eget cum faucibus volutpat. Lectus id leo, cursus blandit tellus.
                                            Sit ut molestie id urna ac enim porttitor ut pulvinar.
                                            "
                      />
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col sx={24} md={16} lg={18} xl={18}>
                    <Card
                      hoverable
                      bordered={false}
                      className="card_custom_head card_overview"
                      bodyStyle={{ paddingLeft: "0" }}
                      cover={<img alt="example" src={overview} />}
                      title={
                        <div>
                          <h3>Product Vision</h3>
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Vitae pellentesque morbi nunc vulputate nisl
                            <br /> gravida elit tortor. Et ullamcorper semper
                            platea euismod ut duis egestas ac. Amet,
                            pellentesque id at eros
                            <br /> leo sit tellus porttitor.
                          </p>
                        </div>
                      }
                    >
                      <Meta
                        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vitae pellentesque morbi nunc vulputate nisl
                                            gravida elit tortor. Et ullamcorper semper platea euismod ut duis egestas ac. Amet, pellentesque id at eros
                                            leo sit tellus porttitor. Quisque nulla augue volutpat in mattis morbi lobortis. Ipsum vitae turpis purus
                                            feugiat. Odio lacus netus mauris turpis. Et eget cum faucibus volutpat. Lectus id leo, cursus blandit tellus.
                                            Sit ut molestie id urna ac enim porttitor ut pulvinar.
                                            "
                      />
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col sx={24} md={16} lg={18} xl={18}>
                    <div className="pro_list">
                      <div>
                        <h2>Proceeds</h2>
                      </div>
                      <List
                        size="small"
                        bordered={false}
                        dataSource={proceedlist}
                        renderItem={(item) => (
                          <>
                            <List.Item className="proceed_list">
                              {item}
                            </List.Item>
                          </>
                        )}
                      />
                    </div>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tab="Financials" key="2">
                <Row>
                  <Col xs={24} sm={24} xxl={24} md={24} xl={24}>
                    <Financials />
                  </Col>
                </Row>
              </TabPane>
              <TabPane tab="Team" key="3">
                <Row>
                  <Col xs={24} sm={24} xxl={24} md={24} xl={24}>
                    <Team />
                  </Col>
                </Row>
              </TabPane>
              <TabPane tab="Conditions" key="4">
                <Row>
                  <Col xs={24} sm={24} xxl={24} md={24} xl={24}>
                    <Conditions />
                  </Col>
                </Row>
              </TabPane>
              <TabPane tab="Documents" key="5">
                <Row>
                  <Col xs={24} sm={24} xxl={24} md={24} xl={24}>
                    <Documents />
                  </Col>
                </Row>
              </TabPane>
              <TabPane tab="Discussions" key="6">
                <Row>
                  <Col xs={24} sm={24} xxl={24} md={24} xl={24}>
                    <Discussions />
                  </Col>
                </Row>
              </TabPane>
            </Tabs>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default DealDetail;
