export const InvestmentsIcon = () => (
  <svg
    width="25"
    height="29"
    viewBox="0 0 25 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.1999 29C0.758075 29 0.399902 28.6418 0.399902 28.2L0.399902 9.8C0.399901 9.35817 0.758074 9 1.1999 9C1.64173 9 1.9999 9.35817 1.9999 9.8L1.9999 28.2C1.9999 28.6418 1.64173 29 1.1999 29Z"
      fill="#6B7280"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.1999 29C12.7581 29 12.3999 28.6418 12.3999 28.2L12.3999 0.799999C12.3999 0.358171 12.7581 -1.56561e-08 13.1999 -3.4969e-08C13.6417 -5.42818e-08 13.9999 0.358171 13.9999 0.799999L13.9999 28.2C13.9999 28.6418 13.6417 29 13.1999 29Z"
      fill="#6B7280"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M24.1999 29C23.7581 29 23.3999 28.6418 23.3999 28.2L23.3999 15.8C23.3999 15.3582 23.7581 15 24.1999 15C24.6417 15 24.9999 15.3582 24.9999 15.8L24.9999 28.2C24.9999 28.6418 24.6417 29 24.1999 29Z"
      fill="#6B7280"
    />
  </svg>
);
