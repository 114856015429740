import {
  GET_ALL_USERS_SUCCESS,
  GET_ALL_USERS_ERROR,
  GET_ALL_CHAT_SUCCESS,
  GET_ALL_MESSAGES_ERROR,
  GET_ALL_MESSAGES_SUCCESS,
  CREATE_CHAT_SUCCESS,
  CREATE_CHAT_ERROR,
  DELETE_USER_FROM_CHAT_SUCCESS,
  DELETE_USER_FROM_CHAT_ERROR,
  ADD_USER_TO_CHAT_SUCCESS,
  ADD_USER_TO_CHAT_ERROR,
  NEW_MESSAGE_ERROR,
  NEW_MESSAGE_SUCCESS,
  NEW_MESSAGE_REQUEST,
  SET_PRE_SELECTED_CHAT,
  RESET_MESSAGES,
  RESET_ALL_DETAILS,
} from "./constants";

const initialState = {
  loading: false,
  users: [],
  allMessages: [],
  chatList: [],
  preSelectedChat: null,
};

export default function chatReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_USERS_SUCCESS:
      return {
        ...state,
        users: action.data,
      };
    case GET_ALL_USERS_ERROR:
      return { ...state, errorMessage: action.data };
    case GET_ALL_CHAT_SUCCESS:
      return {
        ...state,
        chatList: action.data,
      };

    case CREATE_CHAT_SUCCESS:
      return {
        ...state,
        chatList: [action.data, ...state.chatList],
      };
    case CREATE_CHAT_ERROR:
      return { ...state, errorMessage: action.data };
    case DELETE_USER_FROM_CHAT_SUCCESS:
      return {
        ...state,
        chatList: [...action.data],
      };
    case DELETE_USER_FROM_CHAT_ERROR:
      return { ...state, errorMessage: action.data };
    case ADD_USER_TO_CHAT_SUCCESS:
      return {
        ...state,
        chatList: [...action.data],
      };
    case ADD_USER_TO_CHAT_ERROR:
      return { ...state, errorMessage: action.data };
    case GET_ALL_MESSAGES_SUCCESS:
      return {
        ...state,
        allMessages: action.data,
      };
    case GET_ALL_MESSAGES_ERROR:
      return { ...state, errorMessage: action.data };
    case NEW_MESSAGE_REQUEST:
      return { ...state, loading: true };
    case NEW_MESSAGE_SUCCESS:
      return {
        ...state,
        allMessages: action.data,
        loading: false,
      };
    case NEW_MESSAGE_ERROR:
      return { ...state, errorMessage: action.data, loading: false };

    case SET_PRE_SELECTED_CHAT:
      return { ...state, preSelectedChat: action.data };
    case RESET_MESSAGES:
      return { ...state, allMessages: [] };
    case RESET_ALL_DETAILS:
      return initialState;
    default:
      return state;
  }
}
