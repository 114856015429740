import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isInvestor } from "../../Utils";

import CommonTable from "../common/commonTable";
import { dealsListRequest } from "./redux/action";

function InvestorDeals({ columns, deviceColumns, userRole }) {
  const dispatch = useDispatch();
  const dealListState = useSelector((state) => state.dealList || []);

  useEffect(() => {
    if (isInvestor()) {
      dispatch(dealsListRequest());
    }
  }, [dispatch, userRole]);

  const invKeys = ["All", "In Review", "Active", "In Progress", "Completed"];

  return (
    <div className="admin-deals">
      <CommonTable
        columns={columns}
        deviceColumns={deviceColumns}
        allData={dealListState}
        keys={invKeys}
      />
    </div>
  );
}

export default InvestorDeals;

