import { call, put, takeLatest } from "redux-saga/effects";

import { getInvestorViewData } from "../../../api";

import {
  GET_INVESTOR_VIEW_DATA_REQUEST,
  GET_INVESTOR_VIEW_DATA_SUCCESS,
  GET_INVESTOR_VIEW_DATA_ERROR,
} from "./constants";

function* getInvestorViewDataRequest() {
  try {
    let { data } = yield call(getInvestorViewData);
    if (data) {
      yield put({ type: GET_INVESTOR_VIEW_DATA_SUCCESS, data });
    }
  } catch (err) {
    yield put({ type: GET_INVESTOR_VIEW_DATA_ERROR });
  }
}

function* InvestorViewDataWatcher() {
  yield takeLatest(GET_INVESTOR_VIEW_DATA_REQUEST, getInvestorViewDataRequest);
}
export default InvestorViewDataWatcher;
