import React from "react";
import { Button, Divider, Image, message, Modal, Tag } from "antd";
import { CopyFilled } from "@ant-design/icons";
import FallbackImg from "../../../assets/images/default-fallback-image.png";
import "./modalStyle.scss";

const LongevityDetails = ({
  openViewInfoModal,
  setOpenViewInfoModal,
  cardDetails,
}) => {
  const {
    Name = "",
    AvatarUri = "",
    Description = "",
    Url = "",
    ShortDescription = "",
    Discount = "",
  } = cardDetails;

  const handlePromo = () => {
    navigator.clipboard.writeText(cardDetails.DiscountCode);
    message.success("Code copied to clipboard !");
  };

  const handleCancel = () => {
    setOpenViewInfoModal(false);
  };

  return (
    <Modal
      // title="Longevity Information"
      visible={openViewInfoModal}
      footer={false}
      onCancel={handleCancel}
      width={800}
      className="longevity-detail-modal"
    >
      <div className="card-cover-img">
        <div
          style={{
            overflow: "hidden",
            height: "220px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderTopLeftRadius: "8px",
            borderTopRightRadius: "8px",
          }}
        >
          <Image
            className="cover-img"
            alt="example"
            src={AvatarUri || FallbackImg}
            fallback={FallbackImg}
            preview={false}
          />
        </div>
      </div>
      <div className="longevity__info">
        <div>
          {Discount && (
            <Tag className="marketplace-body__tag" color="blue">
              {Discount}
            </Tag>
          )}
          {/* <Tag className="marketplace-body__tag" color="red">
            Ends 01/02/2023
          </Tag> */}
        </div>
        <div className="longevity__title">{Name}</div>
        <div className="longevity__desc">{ShortDescription}</div>
        <div className="longevity__desc">{Description}</div>
        <div className="longevity__subtitle">Promo code</div>
        <div className="longevity__desc">
          It's a deeplink,which needs to be copy-pasted as it is.
        </div>
        {cardDetails?.DiscountCode && (
          <Button className="longevity__promo" type="dashed" size="large">
            {cardDetails.DiscountCode}
            <CopyFilled onClick={handlePromo} />
          </Button>
        )}
        <Divider style={{ margin: 0 }} />
        <Button
          className="save-draft-btn"
          type="primary"
          htmlType="submit"
          onClick={() => {
            window.open(Url, "_blank", "noopener noreferrer");
          }}
        >
          Go to {Name}
        </Button>
      </div>
    </Modal>
  );
};

export default LongevityDetails;
