import React, { useEffect, useState } from "react";
import { Col, Row, Spin, Empty, Pagination, message } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import MarketPlaceTabHeader from "../../components/Common/MarketPlaceTabHeader/MarketPlaceTabHeader";
import LongevityCard from "./components/LongevityCard";
import LongevityDetails from "./components/LongevityDetails";
import "./style.scss";
import { getPartners } from "../../api/longevityExternal";
import { useParams } from "react-router-dom";
import { useMemo } from "react";

const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;

function LongevityView() {
  const [cardData, setCardData] = useState([]);

  const [openDrawer, setOpenDrawer] = useState(false);
  const [pagination, setPagination] = useState({
    size: 9,
    pageNo: 1,
    count: 0,
  });
  const [loading, setLoading] = useState(false);
  const [cardDetails, setCardDetails] = useState();
  const [search, setSearch] = useState("");
  const params = useParams();

  const fetchLongevityData = async (
    showLoader = false,
    withoutSearch = false
  ) => {
    try {
      setLoading(showLoader);
      const filterValue = !withoutSearch && search;
      let res = await getPartners(filterValue);
      const filterByCategory = res.data.filter(
        (items) => items.CategoryId === Number(params.id)
      );
      setCardData(filterByCategory || []);
      setPagination((prev) => ({ ...prev, count: res?.data?.count || 0 }));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      message.error("Something went wrong, While fetching Data");
    }
  };

  useEffect(() => {
    fetchLongevityData(true);
  }, [pagination.size, pagination.pageNo]);

  const newCardData = useMemo(() => {
    return cardData.filter((item) => {
      return (
        !search ||
        item?.Name.toString().toLowerCase().includes(search?.toLowerCase())
      );
    });
  }, [search, cardData]);

  const searchFilter = (e) => {
    setSearch(e.target.value);
    if (e.target.value.trim() === "") {
      if (pagination.pageNo === 1) {
        fetchLongevityData(true, true);
      } else {
        setPagination((prev) => ({ ...prev, pageNo: 1 }));
      }
    }
  };

  const handleSearch = () => {
    if (pagination.pageNo === 1) {
      fetchLongevityData(true);
    } else {
      setPagination((prev) => ({ ...prev, pageNo: 1 }));
    }
  };

  const onPageNumberChange = (page) =>
    setPagination((prev) => ({ ...prev, pageNo: page }));

  const onShowSizeChange = (_, totalItems) =>
    setPagination((prev) => ({ ...prev, size: totalItems, pageNo: 1 }));

  return (
    <>
      {loading && (
        <div className="spinner_background">
          <Spin className="spinner" indicator={antIcon} />
        </div>
      )}
      <MarketPlaceTabHeader
        setVisible={false}
        searchFilter={searchFilter}
        handleSearch={handleSearch}
      />
      {newCardData?.length <= 0 ? (
        <div className="no-data-mamp">
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        </div>
      ) : (
        <>
          <Row gutter={[16, 16]} className="longevity__card">
            {newCardData?.map((el) => {
              return (
                <Col md={8} sm={24} key={el.title} className="d-flex-card">
                  <LongevityCard
                    record={el}
                    setOpenDrawer={setOpenDrawer}
                    setCardDetails={setCardDetails}
                  />
                </Col>
              );
            })}

            <Col
              md={24}
              sm={24}
              className="overflow-hidden pagination-component"
            >
              <Pagination
                size="small"
                total={pagination.count}
                current={pagination.pageNo}
                defaultPageSize={pagination.size}
                onChange={onPageNumberChange}
                onShowSizeChange={onShowSizeChange}
              />
            </Col>
          </Row>
        </>
      )}

      {openDrawer && (
        <LongevityDetails
          openViewInfoModal={openDrawer}
          setOpenViewInfoModal={setOpenDrawer}
          cardDetails={cardDetails}
        />
      )}
    </>
  );
}

export default LongevityView;
