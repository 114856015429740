import {
  PUBLISH_IP_REQUEST,
  GET_IP_DETAILS_REQUEST,
  GET_IP_DATA_ROOM_REQUEST,
  ADD_COMMENT_IP_REQUEST,
  ASKED_PERMISSION_TO_DOCUMENTS_REQUEST_IP,
  GIVE_PERMISSION_TO_DOCUMENTS_IP_REQUEST,
  UPLOAD_DATA_ROOM_IP_REQUEST,
  ASK_PERMISSION_TO_DOCUMENT_IP_REQUEST,
  UPDATE_ANY_IP_DOCUMENT_REQUEST,
  CLEAR_USER_IP_DOC,
  SIGN_ANY_IP_DOCUMENT_REQUEST,
  DELETE_ANY_IP_DOCUMENT_REQUEST,
  GET_INVESTOR_IP_DETAILS_REQUEST,
  CLEAR_IPDETAILS,
  UNPUBLISH_IP_REQUEST,
} from "./constants";

export const publishIP = (data) => {
  return {
    type: PUBLISH_IP_REQUEST,
    data,
  };
};

export const unPublishIP = (data) => {
  return {
    type: UNPUBLISH_IP_REQUEST,
    data,
  };
};

export const ipDetailsAction = (id) => {
  return {
    type: GET_IP_DETAILS_REQUEST,
    id,
  };
};

export const ipInvestorDetails = (id) => {
  return {
    type: GET_INVESTOR_IP_DETAILS_REQUEST,
    id,
  };
};

export const getIpDataRoomDocument = (id) => {
  return {
    type: GET_IP_DATA_ROOM_REQUEST,
    id,
  };
};

export const addIpComment = (data) => {
  return {
    type: ADD_COMMENT_IP_REQUEST,
    data,
  };
};

export const askedPermissionToDocumentsIp = (data) => {
  return {
    type: ASKED_PERMISSION_TO_DOCUMENTS_REQUEST_IP,
    data,
  };
};

export const giveIpPermissionToDocuments = (data) => {
  return {
    type: GIVE_PERMISSION_TO_DOCUMENTS_IP_REQUEST,
    data,
  };
};

export const uploadIpDataRoomDocument = (data) => {
  return {
    type: UPLOAD_DATA_ROOM_IP_REQUEST,
    data,
  };
};

export const updateAnyIpDocument = (data) => {
  return {
    type: UPDATE_ANY_IP_DOCUMENT_REQUEST,
    data,
  };
};

export const clearUserIpDoc = () => {
  return {
    type: CLEAR_USER_IP_DOC,
  };
};

export const signAnyIpDocuments = (data) => {
  return {
    type: SIGN_ANY_IP_DOCUMENT_REQUEST,
    data,
  };
};

export const deleteAnyIpDocument = (data) => {
  return {
    type: DELETE_ANY_IP_DOCUMENT_REQUEST,
    data,
  };
};

export const askIpPermissionToDocument = (data) => {
  return {
    type: ASK_PERMISSION_TO_DOCUMENT_IP_REQUEST,
    data,
  };
};

export const resetIpDetails = () => {
  return {
    type: CLEAR_IPDETAILS,
  };
};
