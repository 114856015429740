import {
  GRANT_MARKETPLACE_LIST_ERROR,
  GRANT_MARKETPLACE_LIST_SUCCESS,
  GRANT_MARKETPLACE_LIST_REQUEST,
  ADD_GRANT_SUCCESS,
  EDIT_GRANT_SUCCESS,
  DELETE_GRANT_SUCCESS,
  ADD_GRANT_ERROR,
  EDIT_GRANT_ERROR,
  DELETE_GRANT_ERROR,
  ADD_GRANT_REQUEST,
  EDIT_GRANT_REQUEST,
  DELETE_GRANT_REQUEST,
} from "./constants";

const initialState = {
  loading: false,
  data: [],
  grant: "",
  editGrant: {},
  grant_id: "",
};

export default function grantMarketplaceListReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case GRANT_MARKETPLACE_LIST_REQUEST:
      return { ...state, loading: true };
    case ADD_GRANT_REQUEST:
      return {
        ...state,
        grant: action.data,
        loading: false,
      };
    case EDIT_GRANT_REQUEST:
      return {
        ...state,
        editGrant: action.data,
        loading: false,
      };
    case DELETE_GRANT_REQUEST:
      return {
        ...state,
        grant_id: action.data,
        loading: false,
      };
    case GRANT_MARKETPLACE_LIST_SUCCESS:
      return {
        ...state,
        data: action.data,
        loading: false,
      };
    case ADD_GRANT_SUCCESS:
    case EDIT_GRANT_SUCCESS:
    case DELETE_GRANT_SUCCESS:
      return {
        ...state,
        grant: action.msg,
        loading: false,
      };
    case GRANT_MARKETPLACE_LIST_ERROR:
    case ADD_GRANT_ERROR:
    case EDIT_GRANT_ERROR:
    case DELETE_GRANT_ERROR:
      return { ...state, loading: false };
    default:
      return state;
  }
}
