import React from "react";
import { Divider, List, Avatar, Row, Col, Card, Space } from "antd";
import { MoreOutlined } from "@ant-design/icons";
import {
  conditiondetail,
  conditionmail,
  conditiontitle,
} from "../../../data/data";
import { getFirstLater, getImageURL } from "../../../Utils";

const Conditions = () => {
  return (
    <div className="conditions_tab">
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={12} xl={12} xxl={12}>
          {conditiontitle.map((c, index) => (
            <>
              <h3>
                {c.title} <MoreOutlined className="threedot" />
              </h3>
              <small>{c.desc}</small>
            </>
          ))}
        </Col>
        <Col xs={24} sm={24} md={12} xl={12} xxl={12}></Col>
      </Row>
      <Row gutter={[24, 16]}>
        <Col xs={24} sm={24} md={12} xl={12} xxl={12}>
          <List
            grid={{ gutter: 24, column: 2 }}
            dataSource={conditiondetail}
            bordered={false}
            renderItem={(item) => (
              <List.Item key={item.id}>
                <Card className="condition_list" bordered={false}>
                  <div>
                    <h3>{item.title}</h3>
                  </div>
                  <Space>
                    <h2>{item.desc}</h2>
                    {item.linkedinicon}
                    {item.fbicon}
                    {item.twittericon}
                  </Space>
                </Card>
              </List.Item>
            )}
          />
        </Col>

        <Col xs={24} sm={24} md={12} xl={12} xxl={12}>
          {conditionmail.map((c, index) => (
            <Card className="condition_card">
              <Row gutter={[16, 8]}>
                <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
                  <Avatar src={getImageURL(c.avatar || "")}
                  //  icon={<UserOutlined />}
                  className={`${!c.avatar ? "shape-avatar-letter" : ""}`}
                  >
                    {getFirstLater(c?.title || "")}
                  </Avatar>
                </Col>

                <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
                  <h3>{c.title}</h3>
                </Col>
              </Row>
              <Row gutter={[16, 0]}>
                <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
                  <Space>
                    <small>{c.designation}</small>
                  </Space>
                </Col>
              </Row>
              <Row gutter={[16, 0]}>
                <Divider />
              </Row>
              <Row gutter={[8, 8]}>
                <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
                  <Space>
                    <img src={c.emailicon} alt="email" />
                    <label>{c.email}</label>
                  </Space>
                </Col>
              </Row>
            </Card>
          ))}
        </Col>
      </Row>
    </div>
  );
};

export default Conditions;
