import {
  GET_ADMIN_IP_INVESTMENT_REQUEST,
  UPDATE_IP_INVESTMENT_STATUS_REQUEST,
} from "./constants";

export const getAdminIPInvestmentRequest = () => {
  return {
    type: GET_ADMIN_IP_INVESTMENT_REQUEST,
  };
};

export const updateIPInvestmentStatusRequest = (data) => {
  return {
    type: UPDATE_IP_INVESTMENT_STATUS_REQUEST,
    data,
  };
};
