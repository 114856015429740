export const dealStepsHeaderDetails = {
  1: {
    title: "General Information",
    description:
      "This information will be displayed publicly so be careful what you share.",
  },
  2: {
    title: "Introduction",
    description:
      "This information will be displayed publicly so be careful what you share.",
  },
  3: {
    title: "Financial Statement",
    description:
      "This information will be displayed publicly so be careful what you share.",
  },
  4: {
    title: "Team Presentation",
    description:
      "This information will be displayed publicly so be careful what you share.",
  },
  5: {
    title: "Fund Conditions",
    description:
      "This information will be displayed publicly so be careful what you share.",
  },
  6: {
    title: "Project background",
    description:
      "You should upload file including: Certificate of Incorporation (.pdf), Bylaws/ Operating agreement(.pdf) or TIN Certificate (.pdf)",
  },
  7: {
    title: "Add Bank Details",
    description:
      "This information will be displayed publicly so be careful what you share.",
  },
  8: {
    title: "Deal Type",
    description:
      "This information will be displayed publicly so be careful what you share.",
  },
  9: {
    title: "Spv Documents",
    description:
      "This information will be displayed publicly so be careful what you share.",
  },
};

export const dealTypesDetails = [
  {
    title: "SPV",
    value: "spv",
  },
  {
    title: "SPV Syndication Deals",
    value: "spv_syndication_deals",
  },
  {
    title: "Crowdfunding SPVs",
    value: "crowdfunding_spv",
  },
  {
    title: "Shared Earnings Agreement (SEA)",
    value: "shared_earning_agreement",
  },
  {
    title: "Simple Agreement for Future Equity",
    value: "simple_agreement_for_future_equity",
  },
  {
    title: "Enterprise Investment Scheme (EIS)",
    value: "entreprise_investment_scheme",
  },
  {
    title: "SEED Enterprise Investment Scheme",
    value: "seed_enterprise_investment_scheme",
  },
  {
    title: "Performance Tracking Securities",
    value: "performance_tracking_securities",
  },
  {
    title: "Structured Financial Products",
    value: "structured_financial_products",
  },
  {
    title: "Convertible Bonds",
    value: "convertible_bonds",
  },

  {
    title: "OTHER",
    value: "others",
  },
];

export const getDealType = (value) =>
  dealTypesDetails.find((item) => item.value === value)?.title;

export const ipStepsHeaderDetails = {
  1: {
    title: "General Information",
    description:
      "This information will be displayed publicly so be careful what you share.",
  },
  2: {
    title: "Introduction",
    description:
      "This information will be displayed publicly so be careful what you share.",
  },
  3: {
    title: "IP Type",
    description:
      "This information will be displayed publicly so be careful what you share.",
  },
  4: {
    title: "Intellectual Property Documents",
    description: "Add documents regarding your Intellectual Property.",
  },
  5: {
    title: "Fund Conditions",
    description:
      "This information will be displayed publicly so be careful what you share.",
  },
  6: {
    title: "Company Documents",
    description:
      "You should upload file including: Certificate of Incorporation (jpg.png.doc,pdf), Bylaws/ Operating agreement (jpg.png.doc,pdf) or TIN Certificate (.pdf)",
  },
  7: {
    title: "Bank Account Details",
    description:
      "This information will be displayed publicly so be careful what you share.",
  },
  8: {
    title: "Service Organizer",
    description:
      "This information will be displayed publicly so be careful what you share.",
  },
};

export const ipCreateStepsHeaderDetails = {
  1: {
    title: "General Information",
    description:
      "This information will be displayed publicly so be careful what you share.",
  },
  2: {
    title: "Introduction",
    description:
      "This information will be displayed publicly so be careful what you share.",
  },
  3: {
    title: "IP Type",
    description:
      "This information will be displayed publicly so be careful what you share.",
  },
  4: {
    title: "Intellectual Property Documents",
    description: "Add documents regarding your Intellectual Property.",
  },
  5: {
    title: "Company Documents",
    description:
      "You should upload file including: Certificate of Incorporation (.pdf), Bylaws/ Operating agreement(.pdf) or TIN Certificate (.pdf)",
  },
  6: {
    title: "Service Organizer",
    description:
      "This information will be displayed publicly so be careful what you share.",
  },
};
