import { Timeline } from "antd";
import { isUserIndevidual } from "../../../Utils";
import StepCard from "../stepCard/StepCard";

import { getPercent, getStatus, StepDot } from "./utils";

const getOnboedingSteps = (navigate, userFlow) => {
  const steps = [
    {
      title: "Profile",
      description:
        "Fill out your profile for full interaction with the platform",
      button: {
        title: "Go to Settings",
        callback: () => {
          navigate("/settings");
        },
      },
      dot: (
        <StepDot
          stepDone={
            isUserIndevidual()
              ? userFlow?.onboarding?.completed_profile
              : userFlow?.onboarding?.completed_company_profile
          }
          firstItem={true}
        />
      ),
    },
    {
      title: "Identity",
      description: "Upload necessary KYB documents",
      button: {
        title: "Go to Settings",
        callback: () => {
          navigate("/settings");
        },
      },
      dot: <StepDot stepDone={userFlow?.onboarding?.verified_identity} />,
    },
    {
      title: "Questionnaire",
      description:
        "Provide us with some additional info for better interaction with you",
      button: {
        title: "Go to Settings",
        callback: () => {
          navigate("/settings");
        },
      },
      dot: <StepDot stepDone={userFlow?.onboarding?.visited_questionnaire} />,
    },
    {
      title: "SumSub",
      description:
        "Get verified with SumSub for more secure and faster KYB/KYC process",
      button: {
        title: "Go to Settings",
        callback: () => {
          navigate("/settings");
        },
      },
      dot: <StepDot stepDone={userFlow?.onboarding?.verified_sumsub} />,
    },
  ];
  if (isUserIndevidual()) {
    steps.splice(1, 1);
  }

  return steps;
};

export const startupSteps = (userFlow, navigate, current, percentCalculate) => [
  {
    title: "Explore InvestTech",
    status: getStatus(userFlow?.explore_investtech, current, 0),
    percent: getPercent(percentCalculate),
    content: (
      <Timeline mode="alternate">
        {[
          {
            title: "Service Marketplace",
            description:
              "Find and connect with platform users offering organizer and facilitator services",
            button: {
              title: "Go to Service Marketplace",
              callback: () => {
                navigate("/service-marketplace");
              },
            },
            dot: (
              <StepDot
                stepDone={
                  userFlow?.explore_investtech?.visited_service_marketplace
                }
                firstItem={true}
              />
            ),
          },
          {
            title: "Grant Marketplace",
            description:
              "Discover other grant opportunities for secure funding and support",
            button: {
              title: "Go to Grant Marketplace",
              callback: () => {
                navigate("/grant-marketplace");
              },
            },
            dot: (
              <StepDot
                stepDone={
                  userFlow?.explore_investtech?.visited_grant_marketplace
                }
              />
            ),
          },
          {
            title: "InvestTech Marketplace",
            description: "Access all the fundraising deals",
            button: {
              title: "Go to InvestTech Marketplace",
              callback: () => {
                navigate("/");
              },
            },
            dot: (
              <StepDot
                stepDone={
                  userFlow?.explore_investtech?.visited_investtech_marketplace
                }
              />
            ),
          },
          {
            title: "Longevity Marketplace",
            description: "Discover Longevity products and services",
            button: {
              title: "Go to Longevity Marketplace",
              callback: () => {
                navigate("/longevity-marketplace");
              },
            },
            dot: (
              <StepDot
                stepDone={
                  userFlow?.explore_investtech?.visited_longevity_marketplace
                }
              />
            ),
          },
          {
            title: "IP Marketplace",
            description: "Explore and view Intellectual Property deals",
            button: {
              title: "Go to IP Marketplace",
              callback: () => {
                navigate("/ip-marketplace");
              },
            },
            dot: (
              <StepDot
                stepDone={userFlow?.explore_investtech?.visited_ip_marketplace}
              />
            ),
          },
          {
            title: "M&A Marketplace",
            description: "Explore and view Mergers & Acquisitions deals",
            button: {
              title: "Go to M&A Marketplace",
              callback: () => {
                navigate("/MAmarketplace");
              },
            },
            dot: (
              <StepDot
                stepDone={userFlow?.explore_investtech?.visited_m_and_a}
              />
            ),
          },
          {
            title: "Chat",
            description: "Get in touch with platform users",
            button: {
              title: "Go to Chat",
              callback: () => {
                navigate("/chat");
              },
            },
            dot: (
              <StepDot stepDone={userFlow?.explore_investtech?.visited_chat} />
            ),
          },
          {
            title: "Investors",
            description:
              "Explore investors, view insights and connect with potential investors for your deals",
            button: {
              title: "Go to Investors",
              callback: () => {
                navigate("/investor-relations");
              },
            },
            dot: (
              <StepDot
                stepDone={userFlow?.engage_with_investors?.visited_irm}
              />
            ),
          },
        ].map((el, index) => {
          return (
            <Timeline.Item dot={el?.dot}>
              <StepCard
                title={el?.title}
                description={el?.description}
                button={el?.button}
                index={index}
              />
            </Timeline.Item>
          );
        })}
      </Timeline>
    ),
  },

  {
    title: "Onboarding",
    status: getStatus(userFlow?.onboarding, current, 1),
    percent: getPercent(userFlow?.onboarding),
    content: (
      <Timeline mode="alternate">
        {getOnboedingSteps(navigate, userFlow).map((el, index) => {
          return (
            <Timeline.Item dot={el?.dot}>
              <StepCard
                title={el?.title}
                description={el?.description}
                button={el?.button}
                index={index}
              />
            </Timeline.Item>
          );
        })}
      </Timeline>
    ),
  },

  {
    title: "Create Deals",
    status: getStatus(userFlow?.create_deal, current, 2),
    percent: getPercent(userFlow?.create_deal),
    content: (
      <Timeline mode="alternate">
        {[
          {
            title: "Create Deals",
            description:
              "Take the first step and create a deal to take place in the marketplace",
            button: {
              title: "Create deal",
              callback: () => {
                navigate("/create-deal");
              },
            },
            dot: (
              <StepDot
                firstItem={true}
                stepDone={userFlow?.create_deal?.visited_deal_creation}
              />
            ),
          },
          {
            title: "Select Deal Type",
            description:
              "Define the appropriate deal type for your deal (e.g. SPV)",
            button: {
              title: "My deals",
              callback: () => {
                navigate("/deals");
              },
            },
            dot: (
              <StepDot stepDone={userFlow?.create_deal?.visited_deal_type} />
            ),
          },
          {
            title: "Select Service Organizer",
            description:
              "Select a service provider to organize all the steps and ease the procedures",
            button: {
              title: "My deals",
              callback: () => {
                navigate("/deals");
              },
            },
            dot: (
              <StepDot
                stepDone={userFlow?.create_deal?.visited_service_organizer}
              />
            ),
          },
          {
            title: "Publish",
            description:
              "When you are ready, publish your deal and start discussions with investors",
            button: {
              title: "My deals",
              callback: () => {
                navigate("/deals");
              },
            },
            dot: <StepDot stepDone={userFlow?.create_deal?.visited_publish} />,
          },
        ].map((el, index) => {
          return (
            <Timeline.Item dot={el?.dot}>
              <StepCard
                title={el?.title}
                description={el?.description}
                button={el?.button}
                index={index}
              />
            </Timeline.Item>
          );
        })}
      </Timeline>
    ),
  },

  {
    title: "Engage with Investors",
    status: getStatus(userFlow?.engage_with_investors, current, 3),
    percent: getPercent(userFlow?.engage_with_investors),
    content: (
      <Timeline mode="alternate">
        {[
          {
            title: "Search for Investors",
            description:
              "Access investors database and invite them to view your deal",
            button: {
              title: "Investors",
              callback: () => {
                navigate("/investor-relations");
              },
            },
            dot: (
              <StepDot
                firstItem={true}
                stepDone={userFlow?.engage_with_investors?.visited_irm}
              />
            ),
          },
          {
            title: "Data Room",
            description: "Manage and share necessary documents with visitors",
            button: {
              title: "My deals",
              callback: () => {
                navigate("/deals");
              },
            },
            dot: (
              <StepDot
                stepDone={userFlow?.engage_with_investors?.visited_dataroom}
              />
            ),
          },
          {
            title: "Discussions",
            description: "Discuss with interested investors about your deals",
            button: {
              title: "My deals",
              callback: () => {
                navigate("/deals");
              },
            },
            dot: (
              <StepDot
                stepDone={userFlow?.engage_with_investors?.visited_discussion}
              />
            ),
          },
          {
            title: "Accept Deal Offers",
            description:
              "Get investments from investors and reach your fundraising target",
            button: {
              title: "My deals",
              callback: () => {
                navigate("/deals");
              },
            },
            dot: (
              <StepDot
                stepDone={
                  userFlow?.engage_with_investors?.visited_accept_deal_offer
                }
              />
            ),
          },
          // {
          //   title: "Escrow Account",
          //   description:
          //     "Create escrow account to have a more secure funding experience for your deals",
          //   button: {
          //     title: "My deals",
          //     callback: () => {
          //       navigate("/deals");
          //     },
          //   },
          //   dot: (
          //     <StepDot
          //       stepDone={
          //         userFlow?.engage_with_investors?.visited_escrow_account
          //       }
          //     />
          //   ),
          // },
        ].map((el, index) => {
          return (
            <Timeline.Item dot={el?.dot}>
              <StepCard
                title={el?.title}
                description={el?.description}
                button={el?.button}
                index={index}
              />
            </Timeline.Item>
          );
        })}
      </Timeline>
    ),
  },

  {
    title: "Reports",
    status: getStatus(userFlow?.reports, current, 4),
    percent: getPercent(userFlow?.reports),
    content: (
      <Timeline mode="alternate">
        {[
          {
            title: "Deal Analytics",
            description:
              "Get insights of your deal viewers and downloaded documents",
            button: {
              title: "My deals",
              callback: () => {
                navigate("/deals");
              },
            },
            dot: (
              <StepDot
                stepDone={userFlow?.reports?.visited_deal_analytics}
                firstItem={true}
              />
            ),
          },
          {
            title: "Investment Dynamics",
            description: "View the deal report of your investment dynamics",
            button: {
              title: "My deals",
              callback: () => {
                navigate("/deals");
              },
            },
            dot: (
              <StepDot
                stepDone={userFlow?.reports?.visited_investment_dynamics}
              />
            ),
          },
        ].map((el, index) => {
          return (
            <Timeline.Item dot={el?.dot}>
              <StepCard
                title={el?.title}
                description={el?.description}
                button={el?.button}
                index={index}
              />
            </Timeline.Item>
          );
        })}
      </Timeline>
    ),
  },
];
