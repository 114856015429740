import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Spin, Typography, Alert, Result, Button } from 'antd';
import { validateProjectInviteRequest } from '../../api';
const { Text } = Typography;

const UserInviteCallBack = () => {
    const { token, projectId } = useParams();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const handleCallback = async () => {
        setLoading(true);
        try {
            const response = await validateProjectInviteRequest({ token, projectId });
            const { data } = response;
            localStorage.setItem("token", data?.token);
            localStorage.setItem("user", JSON.stringify(data?.userData));
            // Navigate to the project page with the project ID
            navigate(`/project/${data?.projectId}?projectId=${projectId}`);
        } catch (error) {
            setError(error?.response?.data?.message || 'Something went wrong while verifying your invitation. Please try again later or contact the administrator.');
            console.log("error: ", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (!token || !projectId) {
            setError('Invalid invitation parameters. Please check your invitation link.');
            return;
        }
        handleCallback();
    }, [token, projectId]);

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', flexDirection: 'column' }}>
            {loading && (
                <>
                    <Spin size="large" />
                    <Text style={{ marginTop: 20, fontSize: 16 }}>Please wait while we verify your invitation and redirect you to the project page...</Text>
                </>
            )}
            {error && (
                <Result
                    status="403"
                    title="403"
                    subTitle={`Sorry, you are not authorized to access this page ${error}, please contact the administrator.`}
                    extra={<Button type="primary" onClick={() => navigate('/')}>Back Home</Button>}
                />
            )}
        </div>
    );
};

export default UserInviteCallBack;
