import {
  IP_LIST_REQUEST,
  DELETE_IP_REQUEST,
  ORGANIZER_IP_LIST_REQUEST,
} from "./constants";

export const IpsListRequest = (data) => {
  return {
    type: IP_LIST_REQUEST,
    data,
  };
};

export const organizerIpsListRequest = () => {
  return {
    type: ORGANIZER_IP_LIST_REQUEST,
  };
};

export const deleteIpRequest = (data) => {
  return {
    type: DELETE_IP_REQUEST,
    data,
  };
};
