import { numberFormatter } from ".";
import "./common.scss";

export const countries = [
  "Global",
  "Asia",
  "Africa",
  "Europe",
  "Australia",
  "Americas",
  "Albania",
  "Andorra",
  "Armenia",
  "Austria",
  "Azerbaijan",
  "Belarus",
  "Belgium",
  "Bosnia and Herzegovina",
  "Bulgaria",
  "Croatia",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Estonia",
  "Finland",
  "France",
  "Georgia",
  "Germany",
  "Greece",
  "Hungary",
  "Iceland",
  "Ireland",
  "Italy",
  "Kosovo",
  "Latvia",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macedonia",
  "Malta",
  "Moldova",
  "Monaco",
  "Montenegro",
  "The Netherlands",
  "Norway",
  "Poland",
  "Portugal",
  "Romania",
  "San Marino",
  "Serbia",
  "Slovakia",
  "Slovenia",
  "Spain",
  "Sweden",
  "Switzerland",
  "Turkey",
  "Ukraine",
  "United States",
  "United Kingdom",
  "Vatican City",
  "United Arab Emirates",
  "Saudi Arabia",
  "MENA",
  "Gulf Region"
];

export const pastFundraisesColumns = [
  {
    title: "Round name",
    dataIndex: "round_name",
    rules: [{ required: true, message: "Please input your round name" }],
    type: "text",
  },
  {
    title: "Date",
    dataIndex: "date",
    rules: [{ required: true, message: "Please enter Date" }],
    type: "date",
  },
  {
    title: "Amount",
    dataIndex: "amount",
    rules: [{ required: true, message: "Please enter amount" }],
    type: "number",
    currencySelector: true,
    render: (_, record) => (
      <div className="add-currency">
        <div>{numberFormatter.formatter(record.amount)} </div>
        <div>{record.currency}</div>
      </div>
    ),
  },
  {
    title: "Comments",
    dataIndex: "comments",
    type: "text",
  },
];

export const KPIColumns = [
  {
    title: "Date",
    dataIndex: "date",
    type: "date",
    rules: [{ required: true, message: "Please enter Date" }],
  },
  {
    title: "Currency",
    dataIndex: "currency",
    type: "currency",
    hidden: true,
    rules: [{ required: true, message: "Please enter Currency" }],
  },
  {
    title: "Revenue",
    dataIndex: "revenue",
    type: "number",
    render: (_, record) => (
      <div className="add-currency">
        <div>{numberFormatter.formatter(record.revenue)}</div>
        <div>{record.currency}</div>
      </div>
    ),
    rules: [{ required: true, message: "Please enter Revenue" }],
  },
  {
    title: "Short Term Debt",
    dataIndex: "short_term_debt",
    type: "number",
    render: (_, record) => (
      <div className="add-currency">
        <div>{numberFormatter.formatter(record.short_term_debt)} </div>
        <div>{record.currency}</div>
      </div>
    ),
    rules: [{ required: true, message: "Please enter Short Term Debt" }],
  },
  {
    title: "In Cash",
    dataIndex: "cash_on_hand",
    type: "number",
    render: (_, record) => (
      <div className="add-currency">
        <div>{numberFormatter.formatter(record.cash_on_hand)}</div>
        <div>{record.currency}</div>
      </div>
    ),
    rules: [{ required: true, message: "Please enter In Cash" }],
  },
  {
    title: "Net Profit",
    dataIndex: "net_profit",
    type: "number",
    render: (_, record) => (
      <div className="add-currency">
        <div>{numberFormatter.formatter(record.net_profit)} </div>
        <div>{record.currency}</div>
      </div>
    ),
    rules: [{ required: true, message: "Please enter Net Profit" }],
  },
];

export const spv_documents_payload_Create = [
  {
    title: "SPV Services Agreement - SPV Platform",
    key: "spv_services_agreement",
    signed: false,
    uploaded: false,
    eid: null,
    downloadLink: null,
    canSign: true,
    file: null,
  },
  {
    title: "Memorandum of Association - Private Companies Limited by Shares",
    key: "memorandum_of_association",
    signed: false,
    uploaded: false,
    eid: null,
    downloadLink: null,
    canSign: true,
    file: null,
  },
  {
    title: "Articles of Association - Private Companies Limited by Shares",
    key: "articles_of_association",
    signed: false,
    uploaded: false,
    eid: null,
    downloadLink: null,
    canSign: false,
    file: null,
  },
];

export const createDealSlider = [
  { title: "General Information", disable: 0 },
  { title: "Introduction", disable: 1 },
  { title: "Financial Statement", disable: 2 },
  { title: "Team Presentation", disable: 3 },
  { title: "Fund Conditions", disable: 4 },
  { title: "Company Documents", disable: 5 },
  { title: "Bank Account Details", disable: 6 },
  { title: "Deal Types", disable: 7 },
  { title: "Spv Documents", disable: 8 },
];

export const IPSliderStepList = [
  { title: "General Information", disable: 0 },
  { title: "Introduction", disable: 1 },
  { title: "IP Type", disable: 2 },
  { title: "Intellectual Property Documents", disable: 3 },
  { title: "Fund Conditions", disable: 4 },
  { title: "Company Documents", disable: 5 },
  { title: "Bank Account Details", disable: 6 },
  { title: "Service Organizer", disable: 7 },
];

export const IPCreateSliderStepList = [
  { title: "General Information", disable: 0 },
  { title: "Introduction", disable: 1 },
  { title: "IP Type", disable: 2 },
  { title: "Intellectual Property Documents", disable: 3 },
  { title: "Company Documents", disable: 4 },
  { title: "Service Organizer", disable: 5 },
];

export const formItemLayout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 24,
    lg: 24,
    xl: 14,
  },
};

export const TooltipContents = {
  "service-marketplace":
    "Find and connect with platform users offering organizer and facilitator services to enhance your investment experience",
  "grant-marketplace":
    "Discover and explore other grant opportunities for secure funding and support",
  "my-deals": "View and manage all your current deals you are engaged with",
  "my-ips":
    "Register and manage your Intellectual Properties, create IPs to sell on the platform",
  investments: "View and manage all the deal investments you are involved in",
  "ip-investments":
    "View and manage all the Intellectual Property investments you are involved in",
  organizers:
    "Manage organizer accounts, view details, approve or reject users",
  facilitators:
    "Manage facilitator accounts, view details, approve or reject users",
  users: "View and manage all the user accounts",
  "investTech-marketplace": "Access all the fundraising deals",
  "longevity-marketplace":
    "Discover Longevity products and services, explore special discounts",
  "ip-marketplace":
    "Explore and view Intellectual Property deals, search for IPs relevant to your business",
  "investor-relation":
    "Explore investors, view their investments, access analysis and insights, and connect with potential investors for your deals",
  "m&a-marketplace":
    " Explore and view Mergers and Acquisitions deals, search for opportunities to acquire",
  chats: "Get in touch with platform users",
  settings: "Manage your profile and account",
  dashboard:
    "Track your investment portfolio, view real-time updates and insights, and stay informed on your deal performance",
  "register-ip":
    "Submit your Intellectual Property for registration, receive expert guidance to ensure your IP is protected and secure",
  "sell-ip":
    "Sell your registered Intellectual Property in the IP Marketplace, connect with interested buyers, and monetize your IP assets",
};

export const getRoleByType = (type) => {
  switch (type) {
    case "ORG":
      return "Startup";
    case "FAC":
      return "Facilitator";
    case "INV":
      return "Investor";
    case "SVC_ORG":
      return "Organizer";
    default:
      return "Startup";
  }
};
