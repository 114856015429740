import React, { useState, useEffect } from "react";
import {
  Avatar,
  Button,
  Col,
  Divider,
  Drawer,
  Row,
  Space,
  Tabs,
  Typography,
} from "antd";

import "./index.scss";

import { useDispatch, useSelector } from "react-redux";
import { clearUser } from "../../redux/userDetail/action";
import DocumentsList from "../../pages/dealDetail/tabs/documentsTab/DocumentsList";
import { useNavigate, useSearchParams } from "react-router-dom";

import { createChatAction } from "../../pages/chatPage/redux/action";
import { getFirstLater, getImageURL } from "../../Utils";

const { Text, Title } = Typography;
const { TabPane } = Tabs;

function UserSider({ checkPath }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const searchId = searchParams.get("id");
  const searchValue = searchParams.get("value");
  const [visible, setVisible] = useState(false);
  const { userDetail, user_id } = useSelector((state) => state.userDetail);

  useEffect(() => {
    if (Object.keys(userDetail).length > 0) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  }, [userDetail]);

  useEffect(() => {
    return () => {
      window.addEventListener("unload", handleRefresh);
      return () => {
        window.removeEventListener("unload", handleRefresh);
      };
    };
  }, []);

  const handleRefresh = () => {
    setVisible(false);
    dispatch(clearUser());
  };

  const {
    profile,
    company_details,
    payment_details,
    id_proof_details,
    kyb_details,
    questionnaire_details,
    deals_detail = {},
    services = [],
  } = userDetail;
  const { source_of_funds = null } = deals_detail;

  const getSourceOfFundsDocuments = (documents, payments) => {
    const docs = [];
    if (documents?.source_of_funds) {
      docs.push({
        name: `Source Of Fund`,
        url: documents?.source_of_funds,
      });
    }
    if (documents?.supporting_evidences.length) {
      documents?.supporting_evidences.forEach((evidences, index) => {
        docs.push({
          name: `Supporting Doc ${index + 1}`,
          url: evidences,
        });
      });
    }

    return docs;
  };
  const getVerifyDocuments = (documents, payments) => {
    const docs = [];

    if (documents?.id_card?.length) {
      documents?.id_card?.forEach((evidences, i) => {
        docs.push({
          name: `Id Verification ${i + 1}`,
          url: evidences,
        });
      });
    }

    if (documents?.proof_of_address) {
      docs.push({
        name: `Proof of address`,
        url: documents?.proof_of_address,
      });
    }

    return docs;
  };

  const getKybDocuments = (documents, payments) => {
    const docs = [];

    if (documents?.certificate_of_incorporation) {
      docs.push({
        name: `Certificate of incorporation`,
        url: documents?.certificate_of_incorporation,
      });
    }
    if (documents?.articles_of_association) {
      docs.push({
        name: `Articles of assciation`,
        url: documents?.articles_of_association,
      });
    }
    if (documents?.memorandum_of_association) {
      docs.push({
        name: `Memorandum of assciation`,
        url: documents?.memorandum_of_association,
      });
    }
    // if (documents?.operating_agreement) {
    //   docs.push({
    //     name: `Operating agreement`,
    //     url: documents?.operating_agreement,
    //   });
    // }
    if (documents?.tin_certificate) {
      docs.push({
        name: `Tin certificate`,
        url: documents?.ein_certificate,
      });
    }
    if (documents?.shareholder_register) {
      docs.push({
        name: `Shareholder register`,
        url: documents?.shareholder_register,
      });
    }
    if (documents?.source_of_funds) {
      docs.push({
        name: `Source of funds`,
        url: documents?.source_of_funds,
      });
    }

    return docs;
  };

  const checkUserType = profile && profile?.sub_type_of_account === "CMP";

  const getUserRole = (role) => {
    switch (role) {
      case "FAC":
        return "Facilitator";
      case "ORG":
        return "Organiser";
      case "INVESTOR":
        return "Investore";
      case "SVC_ORG":
        return "Service Organiser";
      default:
        return "-";
    }
  };

  const handleChatClick = () => {
    if (user_id) {
      const formData = new FormData();
      formData.append("user_ids", JSON.stringify([user_id]));

      dispatch(createChatAction(formData));

      navigate("/chat");
    }
  };

  return (
    <Drawer
      title="User Profile"
      placement={"right"}
      width={530}
      onClose={() => {
        if (searchValue && searchId) {
          navigate("/service-marketplace");
        }
        dispatch(clearUser());
      }}
      visible={visible}
      className="user-sider"
    >
      <Row justify="space-between" align="middle" gutter={[0, 0]}>
        <Col sx={24} className={"user-sider__header"}>
          <Avatar
            size={45}
            shape="round"
            src={getImageURL(profile?.photo || "")}
            className={`${
              !profile?.photo ? "shape-avatar-letter" : ""
            } user-photo`}
          >
            {getFirstLater(profile?.full_name || "")}
          </Avatar>
          <div>
            <Row align="middle" gutter={[0, 0]}>
              <Col md={24} sm={24} display={"flex"} className="top-title">
                <Title level={4} className="top-title_title">
                  {checkUserType
                    ? company_details?.legal_name
                    : profile?.full_name}
                </Title>
              </Col>
            </Row>
            <Text className="description">
              {getUserRole(userDetail?.profile?.role)}{" "}
              <Button
                type="primary"
                onClick={handleChatClick}
                className="message-button"
              >
                Message
              </Button>
            </Text>
          </div>
        </Col>
      </Row>
      <Row gutter={[24, 0]}>
        <Col sx={24} md={24} lg={24} xl={24}>
          <Tabs defaultActiveKey="1" className="profile-tab-custom">
            <TabPane tab="Profile" key="1">
              {(company_details?.about || profile?.email) && (
                <div className="list-item">
                  <div className="list-item__label">
                    {checkUserType ? "About:" : "Email:"}
                  </div>
                  <Space>
                    <span className="list-item__value">
                      {checkUserType ? company_details?.about : profile?.email}
                    </span>
                  </Space>
                </div>
              )}
              <div className="list-item">
                <div className="list-item__label">Sub type:</div>
                <Space>
                  <span className="list-item__value">
                    {profile?.sub_type_of_account === "IND"
                      ? "Individual"
                      : "Company"}
                  </span>
                </Space>
              </div>
              {(company_details?.location || profile?.location) && (
                <div className="list-item">
                  <div className="list-item__label">Location:</div>
                  <Space>
                    <span className="list-item__value">
                      {checkUserType
                        ? company_details?.location
                        : profile?.location}
                    </span>
                  </Space>
                </div>
              )}
              {(profile?.phone_number || company_details?.phone_number) && (
                <div className="list-item">
                  <div className="list-item__label">Phone number:</div>
                  <Space>
                    <span className="list-item__value">
                      {profile?.phone_number || company_details?.phone_number}
                    </span>
                  </Space>
                </div>
              )}
              {(profile?.date_of_birth ||
                company_details?.date_incorporation) && (
                <div className="list-item">
                  <div className="list-item__label">
                    {checkUserType ? "Date Incorporation" : "Date of birth:"}
                  </div>
                  <Space>
                    <span className="list-item__value">
                      {profile?.date_of_birth ||
                        company_details?.date_incorporation}
                    </span>
                  </Space>
                </div>
              )}
              {(company_details?.company_website ||
                profile?.passport_national_id) && (
                <div className="list-item">
                  <div className="list-item__label">
                    {checkUserType ? "Company Website" : "Passport ID:"}
                  </div>
                  {checkUserType ? (
                    <Space>
                      <a
                        href={company_details?.company_website}
                        target="_blank"
                        onClick={(e) => e.stopPropagation()}
                        className="list-item__value website"
                      >
                        {company_details?.company_website}
                      </a>
                    </Space>
                  ) : (
                    <Space>
                      <span className="list-item__value">
                        {profile?.passport_national_id}
                      </span>
                    </Space>
                  )}
                </div>
              )}
            </TabPane>

            {payment_details && (
              <TabPane tab="Payment details" key="2">
                <div className="list-item">
                  <div className="list-item__label">Bank name:</div>
                  <Space>
                    <span className="list-item__value">
                      {payment_details?.bank_name}
                    </span>
                  </Space>
                </div>
                <div className="list-item">
                  <div className="list-item__label">Bank address:</div>
                  <Space>
                    <span className="list-item__value">
                      {payment_details?.bank_address}
                    </span>
                  </Space>
                </div>
                <div className="list-item">
                  <div className="list-item__label">Holder name:</div>
                  <Space>
                    <span className="list-item__value">
                      {payment_details?.holder_name}
                    </span>
                  </Space>
                </div>
                <div className="list-item">
                  <div className="list-item__label">Swift code:</div>
                  <Space>
                    <span className="list-item__value">
                      {payment_details?.swift_code}
                    </span>
                  </Space>
                </div>
                <div className="list-item">
                  <div className="list-item__label">Routing number:</div>
                  <Space>
                    <span className="list-item__value">
                      {payment_details?.routing_number}
                    </span>
                  </Space>
                </div>
                <div className="list-item">
                  <div className="list-item__label">Account number:</div>
                  <Space>
                    <span className="list-item__value">
                      {payment_details?.account_number}
                    </span>
                  </Space>
                </div>
              </TabPane>
            )}

            {id_proof_details ? (
              <TabPane tab="Verify identity" key="3">
                {getVerifyDocuments(id_proof_details)?.length > 0 ? (
                  <Col xs={24} lg={24} style={{ marginBottom: "20px" }}>
                    <DocumentsList
                      documents={getVerifyDocuments(id_proof_details)}
                      showDeleteIcon={false}
                      showEditIcon={false}
                    />
                  </Col>
                ) : null}
              </TabPane>
            ) : null}

            {kyb_details ? (
              <TabPane tab="KYB details" key="4">
                {getKybDocuments(kyb_details)?.length > 0 ? (
                  <Col xs={24} lg={24} style={{ marginBottom: "20px" }}>
                    <DocumentsList
                      documents={getKybDocuments(kyb_details)}
                      showDeleteIcon={false}
                      showEditIcon={false}
                      checkPath={checkPath}
                    />
                  </Col>
                ) : null}
              </TabPane>
            ) : null}

            {questionnaire_details?.length && (
              <TabPane tab="Questionnaire Details" key="5">
                {questionnaire_details
                  ? questionnaire_details.map((questions) => {
                      return (
                        <div className="list-item">
                          <div className="list-item__label">
                            {questions?.question}
                          </div>
                          <Space>
                            <span className="list-item__value">
                              {questions?.answer?.length
                                ? questions.answer
                                : "-"}
                            </span>
                          </Space>
                        </div>
                      );
                    })
                  : null}
              </TabPane>
            )}

            {source_of_funds ? (
              <TabPane tab="Source Of Funds" key="6">
                {getSourceOfFundsDocuments(source_of_funds)?.length > 0 ? (
                  <Col xs={24} lg={24} style={{ marginBottom: "20px" }}>
                    <DocumentsList
                      documents={getSourceOfFundsDocuments(source_of_funds)}
                      showDeleteIcon={false}
                      showEditIcon={false}
                    />
                  </Col>
                ) : null}
              </TabPane>
            ) : null}
            {!!services?.length ? (
              <TabPane tab="Services" key="7">
                <Row>
                  {services.map((item, index) => (
                    <>
                      <Col key={item + index}>{item.service}</Col>
                      <Divider key={item + index} type="horizontal" />
                    </>
                  ))}
                </Row>
              </TabPane>
            ) : null}
          </Tabs>
        </Col>
      </Row>
    </Drawer>
  );
}

export default UserSider;
