import { call, put, takeLatest } from "redux-saga/effects";

import { deleteIPRequest, getOrganizerIPList } from "../../../api";

import {
  DELETE_IP_ERROR,
  DELETE_IP_REQUEST,
  DELETE_IP_SUCCESS,
  ORGANIZER_IP_LIST_ERROR,
  ORGANIZER_IP_LIST_REQUEST,
  ORGANIZER_IP_LIST_SUCCESS,
} from "./constants";

// function* requestIPsList(action) {
//   try {
//     let { data } = yield call(getIPList, action?.data);

//     if (data) {
//       yield put({ type: IP_LIST_SUCCESS, data });
//     }
//   } catch (err) {
//     yield put({ type: IP_LIST_ERROR });
//   }
// }

function* requestDeleteIP(action) {
  try {
    let { data } = yield call(deleteIPRequest, action?.data);
    if (data.Msg) {
      yield put({ type: DELETE_IP_SUCCESS });
      yield put({ type: ORGANIZER_IP_LIST_REQUEST });
    }
  } catch (err) {
    yield put({ type: DELETE_IP_ERROR });
  }
}

function* requestOrganizerIPsList() {
  try {
    let { data } = yield call(getOrganizerIPList);

    if (data) {
      yield put({ type: ORGANIZER_IP_LIST_SUCCESS, data });
    }
  } catch (err) {
    yield put({ type: ORGANIZER_IP_LIST_ERROR });
  }
}

function* IPsListWatcher() {
  // yield takeLatest(IP_LIST_REQUEST, requestIPsList);
  yield takeLatest(ORGANIZER_IP_LIST_REQUEST, requestOrganizerIPsList);
  yield takeLatest(DELETE_IP_REQUEST, requestDeleteIP);
}
export default IPsListWatcher;
