import {
  CLEAR_ERROR,
  CLEAR_SUCCESS,
  FACILITATORS_SERVICES_ERROR,
  FACILITATORS_SERVICES_SET_ERROR,
  FACILITATORS_SERVICES_SET_REQUEST,
  FACILITATORS_SERVICES_SET_SUCCESS,
  FACILITATORS_SERVICES_SUCCESS,
  PREVIOUS_PAGE,
  PUT_PROFILE_COMP_ERROR,
  PUT_PROFILE_COMP_REQUEST,
  PUT_PROFILE_COMP_SUCCESS,
  SET_KYB_ERROR,
  SET_KYB_PASS,
  SET_KYB_REQUEST,
  SET_KYB_SUCCESS,
  SET_KYC_ERROR,
  SET_KYC_REQUEST,
  SET_KYC_SUCCESS,
  SET_PROFILE_COMP_ERROR,
  SET_PROFILE_COMP_REQUEST,
  SET_PROFILE_COMP_SUCCESS,
  SET_PROFILE_IND_ERROR,
  SET_PROFILE_IND_REQUEST,
  SET_PROFILE_IND_SUCCESS,
  SET_QUESTIONARE_ERROR,
  SET_QUESTIONARE_REQUEST,
  SET_QUESTIONARE_SUCCESS,
  SET_SOURCE_OF_FUNDS_ERROR,
  SET_SOURCE_OF_FUNDS_REQUEST,
  SET_SOURCE_OF_FUNDS_SUCCESS,
  SET_TYPE_USER_ERROR,
  SET_TYPE_USER_REQUEST,
  SET_TYPE_USER_SUCCESS,
} from "./constants";

const initialState = {
  loading: false,
  successMessage: null,
  errorMessage: null,
  onboardingCurrent: 0,
  onboardingDone: false,
  facilitatorServices: [],
};

export default function OnboardinReducer(state = initialState, action) {
  switch (action.type) {
    case SET_TYPE_USER_REQUEST:
    case SET_PROFILE_IND_REQUEST:
    case SET_PROFILE_COMP_REQUEST:
    case PUT_PROFILE_COMP_REQUEST:
    case SET_KYC_REQUEST:
    case SET_KYB_REQUEST:
    case SET_QUESTIONARE_REQUEST:
    case FACILITATORS_SERVICES_SET_REQUEST:
    case SET_SOURCE_OF_FUNDS_REQUEST:
      return { ...state, loading: true };
    case SET_TYPE_USER_SUCCESS:
    case SET_PROFILE_IND_SUCCESS:
    case SET_PROFILE_COMP_SUCCESS:
    case PUT_PROFILE_COMP_SUCCESS:
    case SET_KYC_SUCCESS:
    case SET_KYB_SUCCESS:
    case SET_QUESTIONARE_SUCCESS:
    case SET_SOURCE_OF_FUNDS_SUCCESS:
    case FACILITATORS_SERVICES_SET_SUCCESS:
      return {
        ...state,
        onboardingCurrent: action.data.step,
        onboardingDone: action.data.onboardingDone,
        loading: false,
        successMessage: "Success",
      };
    case SET_KYB_PASS:
      return {
        ...state,
        onboardingCurrent: action.data.step,
        onboardingDone: false,
        loading: false,
        successMessage: "Success",
      };
    case SET_TYPE_USER_ERROR:
    case SET_PROFILE_IND_ERROR:
    case SET_PROFILE_COMP_ERROR:
    case PUT_PROFILE_COMP_ERROR:
    case SET_KYC_ERROR:
    case SET_KYB_ERROR:
    case SET_QUESTIONARE_ERROR:
    case SET_SOURCE_OF_FUNDS_ERROR:
    case FACILITATORS_SERVICES_SET_ERROR:
    case FACILITATORS_SERVICES_ERROR:
      return {
        ...state,
        loading: false,
        errorMessage: action.data,
      };
    case FACILITATORS_SERVICES_SUCCESS:
      return {
        ...state,
        loading: false,
        facilitatorServices: action?.data,
      };
    case CLEAR_SUCCESS:
      return { ...state, successMessage: null };

    case CLEAR_ERROR:
      return { ...state, errorMessage: "" };
    case PREVIOUS_PAGE:
      return { ...state, onboardingCurrent: state.onboardingCurrent - 1 };
    default:
      return state;
  }
}
