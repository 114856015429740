import { Col, Row, Spin, Empty, Pagination, message } from "antd";
import React, { useEffect, useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import "./style.scss";
import MarketPlaceTabHeader from "../../components/Common/MarketPlaceTabHeader/MarketPlaceTabHeader";
import FilterDrawer from "./components/FilterDrawer";
import { getEmailsForIRM, getUserInsideInvestorRelation } from "../../api";
import { getFiletrsForInvestors, getInvestors } from "../../api/external";
import InvestorDetails from "./components/InvestorDetails";
import InvestorCard from "./components/InvestorCard";
import { useNavigate, useSearchParams } from "react-router-dom";
import _ from "lodash";

const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;

function InvestorRelation() {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const searchId = searchParams.get("id");
  const searchValue = searchParams.get("value");

  const [investors, setInvestors] = useState([]);

  const [filters, setFilters] = useState([]);
  const [filterValues, setFilterValues] = useState({});
  const [investorDetail, setInvestorDetail] = useState({
    data: {},
    industryGroups: [],
  });

  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [pagination, setPagination] = useState({
    size: 10,
    pageNo: 1,
    count: 0,
  });
  const [loading, setLoading] = useState(false);

  const [deals, setDeals] = useState([]);
  const [search, setSearch] = useState(searchValue || "");

  const fetchFiletrsForInvestors = async () => {
    let data = await getFiletrsForInvestors();
    let shortedData = data.sort((a, b) =>
      a.label.toLocaleLowerCase().localeCompare(b.label.toLocaleLowerCase())
    );
    setFilters(shortedData);
  };

  const getDeals = async () => {
    const res = await getEmailsForIRM();
    setDeals(res?.data || []);
  };

  const fetchInvestors = async (
    showLoader = false,
    withoutSearch = false,
    searchValue = ""
  ) => {
    try {
      setLoading(showLoader);
      const queryParams =
        String(searchValue || search).trim() && !withoutSearch
          ? { ...filterValues, q: searchValue || search }
          : { ...filterValues };
      let res = await getInvestors(pagination, queryParams);

      setInvestors(res?.data?.data || []);
      let response = _.find(res?.data?.data, { id: Number(searchId) });
      let filteredData = _.omitBy(
        response,
        (v) => v === null || v === "" || v === undefined || Array.isArray(v)
      );
      setInvestorDetail({
        data: { ...filteredData },
        industryGroups: response?.["Industry Groups"] || [],
      });
      setPagination((prev) => ({ ...prev, count: res?.data?.count || 0 }));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      message.error("Something went wrong, While fetching investors");
    }
  };

  const checkUserInsideInvestorRelation = async () => {
    try {
      await getUserInsideInvestorRelation();
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    if (investors.length > 0 && searchValue && searchId) {
      setOpenDrawer(true);
    }
    return () => {
      if (window.location.pathname.includes("investor-relations")) {
        navigate("/investor-relations");
      }
    };
  }, [investors, searchId, searchValue]);

  useEffect(() => {
    if (searchValue) {
      setSearch(searchValue);
      fetchInvestors(true, false, searchValue);
    }
  }, [searchValue]);

  useEffect(() => {
    fetchInvestors(true);
  }, [pagination.size, pagination.pageNo]);

  const [firstTime, setFirstTime] = useState(true);

  useEffect(() => {
    if (firstTime) {
      setFirstTime(false);
    } else {
      fetchInvestors();
    }
  }, [filterValues]);

  useEffect(() => {
    fetchFiletrsForInvestors();
    getDeals();
    checkUserInsideInvestorRelation();
  }, []);

  const searchFilter = (e) => {
    setSearch(e.target.value);
    setSearchParams("");

    if (e.target.value.trim() === "") {
      if (pagination.pageNo === 1) {
        fetchInvestors(true, true);
      } else {
        setPagination((prev) => ({ ...prev, pageNo: 1 }));
      }
    }
  };

  const toggleFilters = () => setOpenFilterModal(!openFilterModal);

  const handleSearch = () => {
    if (pagination.pageNo === 1) {
      fetchInvestors(true);
    } else {
      setPagination((prev) => ({ ...prev, pageNo: 1 }));
    }
  };

  const handleFilterSelect = (value, name) =>
    setFilterValues((prev) => {
      const newObj = {
        ...prev,
      };

      let p = filterValues[name] || [];
      newObj[name] = [...p, value];

      return newObj;
    });

  const handleFilterUnselect = (value, name) =>
    setFilterValues((prev) => {
      let a = {
        ...prev,
      };
      let q = prev[name] || [];
      a[name] = [...q.filter((x) => x !== value)];

      if (!a[name].length) {
        delete a[name];
      }

      return { ...a };
    });

  const onPageNumberChange = (page) =>
    setPagination((prev) => ({ ...prev, pageNo: page }));

  const onShowSizeChange = (_, totalItems) =>
    setPagination((prev) => ({ ...prev, size: totalItems, pageNo: 1 }));

  return (
    <>
      {loading && (
        <div className="spinner_background">
          <Spin className="spinner" indicator={antIcon} />
        </div>
      )}
      <MarketPlaceTabHeader
        setVisible={false}
        searchFilter={searchFilter}
        search={search}
        showSettings={true}
        handleFilterModal={toggleFilters}
        filtercount={Object.keys(filterValues).length}
        handleSearch={handleSearch}
      />
      {investors.length <= 0 && (
        <div className="no-data-mamp">
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        </div>
      )}
      <Row gutter={[16, 16]} className="investors-card-container">
        {investors?.map((el) => {
          return (
            <Col md={8} sm={24} key={el.title} className="d-flex-card">
              <InvestorCard
                record={el}
                setInvestorDetail={setInvestorDetail}
                setOpenDrawer={setOpenDrawer}
              />
            </Col>
          );
        })}
        <Col md={24} sm={24} className="overflow-hidden pagination-component">
          <Pagination
            size="small"
            total={pagination.count}
            showSizeChanger
            showQuickJumper
            showTotal={(total, range) =>
              `${range[0]}-${range[1]} of ${total} items`
            }
            current={pagination.pageNo}
            defaultPageSize={pagination.size}
            onChange={onPageNumberChange}
            onShowSizeChange={onShowSizeChange}
          />
        </Col>
      </Row>

      <FilterDrawer
        handleFilterModal={toggleFilters}
        openFilterModal={openFilterModal}
        filters={filters}
        handleFilterSelect={handleFilterSelect}
        handleFilterUnselect={handleFilterUnselect}
      />

      {openDrawer && (
        <InvestorDetails
          openDrawer={openDrawer}
          toggleDrawer={() => setOpenDrawer((prev) => !prev)}
          investorDetail={investorDetail}
          deals={deals}
        />
      )}
    </>
  );
}

export default InvestorRelation;
