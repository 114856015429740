import { call, put, select, takeLatest } from "redux-saga/effects";

import {
  deleteDealRequest,
  getDealList,
  getOrganizerDealList,
} from "../../../api";

import {
  DEAL_LIST_ERROR,
  DEAL_LIST_REQUEST,
  DEAL_LIST_SUCCESS,
  DELETE_DEAL_ERROR,
  DELETE_DEAL_REQUEST,
  DELETE_DEAL_SUCCESS,
  ORGANIZER_DEAL_LIST_ERROR,
  ORGANIZER_DEAL_LIST_REQUEST,
  ORGANIZER_DEAL_LIST_SUCCESS,
} from "./constants";

function* requestDealsList(action) {
  try {
    let { data } = yield call(getDealList, action?.data);

    if (data) {
      yield put({ type: DEAL_LIST_SUCCESS, data });
    }
  } catch (err) {
    yield put({ type: DEAL_LIST_ERROR });
  }
}

function* requestDeleteDeal(action) {
  try {
    let resp = yield call(deleteDealRequest, action?.data);
    const organizerData = yield select((state) => state.dealList.organizerData);

    const index = organizerData.findIndex(
      (el) => el.id === action?.data.deal_id
    );

    if (index > -1) {
      organizerData.splice(index, 1);
    }
    if (resp) {
      yield put({ type: DELETE_DEAL_SUCCESS, data: organizerData });
    }
  } catch (err) {
    yield put({ type: DELETE_DEAL_ERROR });
  }
}

function* requestOrganizerDealsList(action) {
  try {
    let { data } = yield call(getOrganizerDealList, action?.data);

    if (data) {
      yield put({ type: ORGANIZER_DEAL_LIST_SUCCESS, data });
    }
  } catch (err) {
    yield put({ type: ORGANIZER_DEAL_LIST_ERROR });
  }
}

function* DealsListWatcher() {
  yield takeLatest(DEAL_LIST_REQUEST, requestDealsList);
  yield takeLatest(ORGANIZER_DEAL_LIST_REQUEST, requestOrganizerDealsList);
  yield takeLatest(DELETE_DEAL_REQUEST, requestDeleteDeal);
}
export default DealsListWatcher;
