import React from 'react';
import { Button, Space } from 'antd';
import { ClockCircleOutlined } from '@ant-design/icons';

const AccessButton = ({ accessType, userHasFilesAccess, userHasAccessProject, showProjectDataFiles, setShowProjectDataFiles, handleRequestAccess, loading }) => {
    const hasAccess = userHasFilesAccess?.[accessType] === true || userHasAccessProject();
    const isPending = userHasFilesAccess?.[accessType] === "pending";
    const showButton = showProjectDataFiles === accessType;

    return (
        <Space className="header-btns">
            {hasAccess ? (
                <Button
                    className="btn-flex"
                    onClick={() => setShowProjectDataFiles((prev) => prev === accessType ? null : accessType)}
                    type="primary"
                >
                    {showButton ? `Hide ${ accessType === "tier_5_data" ? "Extended Data Room" : accessType === "data_room" && "Data Room Access" }` : 
                    `Show ${ accessType === "tier_5_data" ? "Extended Data Room" : accessType === "data_room" && "Data Room" }`}
                </Button>
            ) : isPending ? (
                <span>
                    A request to gain access to { accessType === "tier_5_data" ? "Extended Data Room" : accessType === "data_room" && "Data Room" }
                    {" "}is already made. Please wait for admin approval. <ClockCircleOutlined />
                </span>
            ) : (
                <Button
                    className="btn-flex"
                    onClick={() => handleRequestAccess(accessType)}
                    type="primary"
                    loading={loading}
                >
                    Request { accessType === "tier_5_data" ? "Extended Access" : accessType === "data_room" && "Data Room Access" }
                </Button>
            )}
        </Space>
    );
};

export default AccessButton;
