import React, { useEffect, useState } from "react";
import "antd/dist/antd.css";
import "./style.scss";
import EditIcon from "../../../assets/images/edit-icon.svg";
import { Avatar, Button, Image, message, Upload } from "antd";
import { isValidUrl } from "../../../Utils";
import FallbackImg from "../../../assets/images/default-fallback-image.png";
import AntdImgCrop from "antd-img-crop";

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

const beforeUpload = (file) => {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";

  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!");
  }

  const isLt2M = file.size / 1024 / 1024 < 2;

  if (!isLt2M) {
    message.error("Image must smaller than 2MB!");
  }

  return isJpgOrPng && isLt2M;
};

const dummyRequest = ({ file, onSuccess }) => {
  setTimeout(() => {
    onSuccess("ok");
  }, 0);
};

const UploadAvatar = ({
  setImgFile,
  setBackGroundImage,
  saveURL,
  coverUrl,
  imgUrl = null,
  isEditGrant = "",
  shape = null,
  aspectRatio,
}) => {
  const [imageUrl, setImageUrl] = useState(imgUrl);
  const [background, setBackground] = useState(coverUrl);

  useEffect(() => {
    setImageUrl(imgUrl);
    setBackground(imgUrl);
  }, [imgUrl, coverUrl]);

  const handleChange = (info, type) => {
    if (info.file.status === "uploading") {
      return;
    }

    if (info.file.status === "done") {
      // Get this url from response in real world.
      if (type === "cover-image") {
        if (setBackGroundImage) {
          setBackGroundImage(info.file.originFileObj);

          getBase64(info.file.originFileObj, (url) => {
            setBackground(url);
          });
        }
        return;
      }
      if (setImgFile) {
        setImgFile(info.file.originFileObj);
      }

      getBase64(info.file.originFileObj, (url) => {
        if (saveURL) {
          saveURL(url);
        }
        setImageUrl(url);
      });
    }
  };

  return (
    <div>
      {isEditGrant ? (
        <div className="card-cover-img">
          <div
            style={{
              overflow: "hidden",
              height: "220px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Image
              className="cover-img"
              alt="example"
              src={background || FallbackImg}
              fallback={background || FallbackImg}
              preview={false}
            />
            <AntdImgCrop grid rotate>
              <Upload
                name="avatar"
                className="grant-image"
                showUploadList={false}
                customRequest={dummyRequest}
                beforeUpload={beforeUpload}
                onChange={(info) => handleChange(info, "cover-image")}
                maxCount={1}
              >
                <img src={EditIcon} alt="EditIcon" className="edit-icon-back" />
              </Upload>
            </AntdImgCrop>
          </div>
        </div>
      ) : (
        <>
          <Avatar
            size={64}
            shape={shape ? "square" : "circle"}
            icon={
              isValidUrl(imageUrl) ? (
                <img src={imageUrl} alt="avatar" />
              ) : (
                <img src={FallbackImg} alt="background" />
              )
            }
          />
          <AntdImgCrop grid rotate aspect={aspectRatio}>
            <Upload
              name="avatar"
              className="avatar-uploader"
              showUploadList={false}
              customRequest={dummyRequest}
              beforeUpload={beforeUpload}
              onChange={handleChange}
              maxCount={1}
            >
              <Button className="avatar-uploader_button">Change</Button>
            </Upload>
          </AntdImgCrop>
        </>
      )}
    </div>
  );
};

export default UploadAvatar;
