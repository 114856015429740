import { GET_ADMIN_INVESTMENT_REQUEST, UPDATE_INVESTMENT_STATUS_REQUEST } from "./constants";

export const getAdminInvestmentRequest = () => {
  return {
    type: GET_ADMIN_INVESTMENT_REQUEST,
  };
};

export const updateInvestmentStatusRequest = (data) => {
  return {
    type: UPDATE_INVESTMENT_STATUS_REQUEST,
    data
  };
};
