import {
  GET_USER_DETAILS_SUCCESS,
  GET_USER_DETAILS_REQUEST,
  GET_USER_DETAILS_ERROR,
  GET_SUBSCRIBE_SUCCESS,
  CLEAR_USER,
  POST_SUBSCRIBE_SUCCESS,
} from "./constants";

const initialState = {
  loading: false,
  userDetail: {},
  errorMessage: null,
  subscribestatus: "",
  user_id: null,
};

export default function userDetail(state = initialState, action) {
  switch (action.type) {
    case GET_USER_DETAILS_REQUEST:
      return { ...state, user_id: action?.data?.id, loading: true };
    case POST_SUBSCRIBE_SUCCESS:
      return { ...state, subscribestatus: action };
    case GET_SUBSCRIBE_SUCCESS:
      return { ...state, subscribestatus: action.data };
    case GET_USER_DETAILS_SUCCESS:
      return {
        ...state,
        userDetail: action.data,
        loading: false,
      };
    case GET_USER_DETAILS_ERROR:
      return { ...state, loading: false, errorMessage: null };
    case CLEAR_USER:
      return { ...state, userDetail: {} };

    default:
      return state;
  }
}
