export const InvestmentTechMarketplaceIcon = () => (
  <svg
    width="30"
    height="27"
    viewBox="0 0 30 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M27.4194 1H2.58065C2.16157 1.00047 1.7598 1.17113 1.46347 1.47453C1.16714 1.77794 1.00046 2.1893 1 2.61837V19.7269C1.00046 20.156 1.16714 20.5673 1.46347 20.8707C1.7598 21.1741 2.16157 21.3448 2.58065 21.3453H11.7394L11.4684 22.7324H9.58065C9.46087 22.7324 9.346 22.7811 9.26131 22.8679C9.17661 22.9546 9.12903 23.0722 9.12903 23.1948V25.5376C9.12903 25.6602 9.17661 25.7779 9.26131 25.8646C9.346 25.9513 9.46087 26 9.58065 26H20.4194C20.5391 26 20.654 25.9513 20.7387 25.8646C20.8234 25.7779 20.871 25.6602 20.871 25.5376V23.1948C20.871 23.0722 20.8234 22.9546 20.7387 22.8679C20.654 22.7811 20.5391 22.7324 20.4194 22.7324H18.5316L18.2606 21.3453H27.4194C27.8384 21.3448 28.2402 21.1741 28.5365 20.8707C28.8329 20.5673 28.9995 20.156 29 19.7269V2.61837C28.9995 2.1893 28.8329 1.77794 28.5365 1.47453C28.2402 1.17113 27.8384 1.00047 27.4194 1ZM19.9677 23.6572V25.0752H10.0323V23.6572H19.9677ZM12.3897 22.7324L12.6606 21.3453H17.3394L17.6103 22.7324H12.3897ZM28.0968 19.7269C28.0966 19.9108 28.0252 20.0871 27.8982 20.2172C27.7712 20.3472 27.599 20.4203 27.4194 20.4205H2.58065C2.40103 20.4203 2.22881 20.3472 2.1018 20.2172C1.97479 20.0871 1.90338 19.9108 1.90323 19.7269V18.5709H28.0968V19.7269ZM28.0968 17.6461H1.90323V2.61837C1.90338 2.43447 1.97479 2.25814 2.1018 2.1281C2.22881 1.99806 2.40103 1.92494 2.58065 1.92478H27.4194C27.599 1.92494 27.7712 1.99806 27.8982 2.1281C28.0252 2.25814 28.0966 2.43447 28.0968 2.61837V17.6461Z"
      fill="#111827"
      stroke="#111827"
      stroke-width="0.3"
    />
  </svg>
);
