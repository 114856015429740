/* eslint-disable jsx-a11y/iframe-has-title */
import Modal from "antd/lib/modal/Modal";
import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { SumSub } from "../../../components/SumSub";
import { SIGN_DOCS_SUCCESS } from "../redux/constants";

const DocIframe = ({
  isModalVisible,
  handleCancel,
  url,
  actionType = SIGN_DOCS_SUCCESS,
}) => {
  const ref = useRef(null);
  const dispatch = useDispatch();
  const [canSign, setCanSign] = useState();

  useEffect(() => {
    const interval = setInterval(() => {
      const url = ref.current?.contentWindow.location.href;
      if (
        url === `${window.location.origin}/complited?event=signing_complete`
      ) {
        dispatch({
          type: actionType,
        });
      }
    }, 1000);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal
      title="DocuSign"
      visible={isModalVisible}
      onCancel={handleCancel}
      className="ifame-modal-form"
      centered
      footer={null}
      destroyOnClose={true}
    >
      {canSign ? (
        <iframe
          className="doc-iframe"
          src={url}
          title="desription"
          ref={ref}
        ></iframe>
      ) : (
        <SumSub
          successCallback={(type, payload) => {
            console.log(type, "SUCCESS type"); //idCheck.applicantStatus
            console.log(payload, "SUCCESS payload"); //payload.reviewResult.reviewAnswer ==="GREEN"
            if (payload.allowContinuing || payload.answer === "GREEN") {
              setCanSign(true);
            }

            if (
              type === "idCheck.onActionSubmitted" &&
              payload.applicantActionId
            ) {
              setCanSign(true);
            }
          }}
          errorCallback={(data) => {
            console.log(data, "ERROR");
          }}
        />
      )}
    </Modal>
  );
};

export default DocIframe;
