import React from "react";
import { Card, Image, Tag } from "antd";
import Paragraph from "antd/lib/typography/Paragraph";
import Title from "antd/lib/typography/Title";
import FallbackImg from "../../../assets/images/default-fallback-image.png";

const LongevityCard = ({ record, setOpenDrawer, setCardDetails }) => {
  const handleDetail = () => {
    setCardDetails(record);
    setOpenDrawer(true);
  };

  return (
    <Card
      className="marketplace flex-1"
      onClick={() => handleDetail()}
      cover={
        <div className="card-cover-img">
          <div
            style={{
              overflow: "hidden",
              height: "150px",
              background: "rgb(3, 129, 169, 0.3)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderTopLeftRadius: "8px",
              borderTopRightRadius: "8px",
              borderBottom: "1px solid #eaecf0",
            }}
          >
            <Image
              className="cover-img"
              alt="example"
              src={record?.AvatarUri ?? FallbackImg}
              fallback={FallbackImg}
              preview={false}
            />
          </div>
        </div>
      }
    >
      <div className="marketplace-body">
        <div style={{ display: "flex" }}>
          <Title
            level={3}
            className="marketplace-body__title"
            ellipsis={{ tooltip: record?.Name }}
            style={{ width: "90%" }}
          >
            {record?.Name}
          </Title>
          {record?.Discount && (
            <Tag className="marketplace-body__tag" color="blue">
              {record.Discount}
            </Tag>
          )}
        </div>
        <Paragraph
          className="marketplace-body__description"
          ellipsis={{ rows: 3 }}
        >
          {record?.ShortDescription}
        </Paragraph>
      </div>
    </Card>
  );
};

export default LongevityCard;
