import React from "react";
import "antd/dist/antd.css";

import { Button } from "antd";
import { SIGN_DOCS_ERROR } from "../redux/constants";
import { useDispatch } from "react-redux";

const Complited = () => {
  const dispatch = useDispatch();

  return (
    <Button
      onClick={() =>
        dispatch({
          type: SIGN_DOCS_ERROR,
        })
      }
    >
      close
    </Button>
  );
};

export default Complited;
