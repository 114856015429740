export const GrandMarketplaceIcon = () => (
  <svg
    width="39"
    height="37"
    viewBox="0 0 39 37"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.98684 22.6108L2 19.112L10.8651 13.9311L11.5 13.5601M7.98684 22.6108L19.7303 29.4738L31.7039 22.2896M7.98684 22.6108V29.4738L19.7303 35.9212L31.7039 29.4738V22.2896M31.7039 22.2896L37 19.112L29.3136 14.5002"
      stroke="#6B7280"
      stroke-width="1.4"
    />
    <circle
      cx="20.4209"
      cy="10.3618"
      r="9.66184"
      stroke="#6B7280"
      stroke-width="1.4"
    />
    <path
      d="M17 15V14.0408H17.7083C17.9769 14.0408 18.2083 13.9456 18.4028 13.7551C18.5972 13.5646 18.6944 13.3379 18.6944 13.0748V7.55782C18.6944 7.05896 18.8079 6.61678 19.0347 6.23129C19.2662 5.8458 19.5787 5.54422 19.9722 5.32653C20.3704 5.10884 20.8194 5 21.3194 5C21.7546 5 22.1389 5.09524 22.4722 5.28571C22.8102 5.47165 23.1042 5.74603 23.3542 6.10884C23.6088 6.46712 23.8241 6.90249 24 7.41497L23.0694 7.71428C22.8796 7.12925 22.6435 6.69161 22.3611 6.40136C22.0833 6.10658 21.7315 5.95918 21.3056 5.95918C20.8287 5.95918 20.4444 6.10884 20.1528 6.40816C19.8657 6.70295 19.7222 7.0975 19.7222 7.59184V14.0408H23.6319V15H17ZM17 10.5102V9.55102H22.25V10.5102H17Z"
      fill="#6B7280"
      stroke="#6B7280"
      stroke-width="0.4"
    />
  </svg>
);
