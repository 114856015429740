import React, { useState } from 'react';
import { Viewer, Worker } from '@react-pdf-viewer/core';
// Import the styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import Logo from "../../assets/images/main-logo-svg.svg";
import { Result } from 'antd';


const PdfViewer = ({ file }) => {
    const renderPage = (props) => (
        <>
            {props.canvasLayer.children}
            <div
                style={{
                    alignItems: 'center',
                    display: 'flex',
                    height: '100%',
                    justifyContent: 'center',
                    left: 0,
                    position: 'absolute',
                    top: 0,
                    width: '100%',
                }}
            >
                <div
                    style={{
                        color: 'rgba(0, 0, 0, 0.2)',
                        fontSize: `${8 * props.scale}rem`, // Adjust the watermark size based on zoom scale
                        fontWeight: 'bold',
                        textTransform: 'uppercase',
                        transform: 'rotate(-45deg)',
                        userSelect: 'none',
                        opacity: 0.2,
                    }}
                >
                    <img src={Logo} alt="logo" style={{ width: '40rem', height: '40rem' }} />
                </div>
            </div>
            {props.annotationLayer.children}
            <div style={{
                userSelect: 'none',
            }}>
                {props.textLayer.children}
            </div>
        </>
    );

    const CustomError = ({ message, name }) => {
        return (
            <Result
                status="404"
                title="404"
                subTitle="Sorry, the page you visited does not exist."
            />
        );
    };



    return (
        <div>
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                <Viewer fileUrl={file} renderPage={renderPage} renderError={(error) => <CustomError message={error.message} name={error.name} />} />
            </Worker>
        </div>
    );
};

export default PdfViewer;
