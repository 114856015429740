import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import "../adminDeals/style.scss";
import {
  adminTopOrganizersColumns,
  adminTopOrganizersDeviceColumns,
} from "../../components/Common/DealsTable/AdminTopOrganizersColumns";
import CommonTable from "../common/commonTable";
import { getAdminOrganizersRequest } from "./redux/action";

const AdminTopOrgarnizers = () => {
  const adminTopOrgarnizersList = useSelector(
    (state) => state.adminTopOrganizersList
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAdminOrganizersRequest());
  }, [dispatch]);

  const topOrgKeys = [
    "All",
    "In Review",
    "Compliance Check",
    "Active",
    "Rejected",
  ];

  return (
    <div className="admin-deals">
      <CommonTable
        columns={adminTopOrganizersColumns(dispatch)}
        deviceColumns={adminTopOrganizersDeviceColumns(dispatch)}
        keys={topOrgKeys}
        allData={adminTopOrgarnizersList}
        topOrgPage="top_org"
      />
    </div>
  );
};

export default AdminTopOrgarnizers;
