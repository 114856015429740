import React from "react";
import { Avatar, Card, Tag, Row, Col, Dropdown, Menu, Space } from "antd";
import Title from "antd/lib/typography/Title";
import Icon from "@ant-design/icons";
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  QuestionCircleOutlined,
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import "antd/dist/antd.css";
import { updateFacilitatorsStatusRequest } from "./redux/action.js";
import ComplianceCheck from "../../assets/images/compliance-check.png";
import { DropdownSvg } from "../../assets/images/dropdownSvg";
import "./index.scss";
import { useDispatch } from "react-redux";
import { getFirstLater, getImageURL } from "../../Utils/index.js";

const statusStyle = (status) => {
  let backgroundColor = "";
  let textColor = "";
  if (status === "Completed") {
    textColor = "#027A48";
    backgroundColor = "#ECFDF3";
  } else if (status === "In Review") {
    textColor = "#0B5394";
    backgroundColor = "#F0F9FF";
  } else if (status === "Active") {
    textColor = "#027A48";
    backgroundColor = "#ECFDF3";
  } else if (status === "Compliance Check") {
    textColor = "#92400E";
    backgroundColor = "#FEF3C7";
  } else if (status === "Rejected") {
    textColor = "#991B1B";
    backgroundColor = "#FEF2F2";
  }

  return {
    color: textColor,
    backgroundColor: backgroundColor,
  };
};

const ml8 = { marginLeft: "8px" };

const DropdownExt = ({
  record,
  setVisibleApprove,
  setVisibleReject,
  setDropDownUserId,
}) => {
  const dispatch = useDispatch();

  const showApproveModal = (e) => {
    // e.domEvent.stopPropagation();
    setDropDownUserId(record.user_id);
    setVisibleApprove(true);
  };
  const showRejectModal = (e) => {
    // e.domEvent.stopPropagation();
    setDropDownUserId(record.user_id);
    setVisibleReject(true);
  };

  const handleOkComplianceCheck = (e) => {
    // e.domEvent.stopPropagation();
    setDropDownUserId(record.user_id);
    const formData = new FormData();

    formData.append("user_id", record?.user_id);
    formData.append("status", `Compliance Check`);
    dispatch(updateFacilitatorsStatusRequest(formData));
  };

  const menuItems = [];

  if (record.status === "In Review") {
    menuItems.push({
      label: (
        <div
          onClick={handleOkComplianceCheck}
          className="pointer compliance-container compliance-container-ext"
        >
          <>
            <img
              src={ComplianceCheck}
              alt="icon"
              className="edit-menu__icon edit-menu__item menu-icon-size"
            />
            <span className="list-item">Compliance Check</span>
          </>
        </div>
      ),
      key: 0,
    });
  }
  if (record.status !== "Completed") {
    menuItems.push({
      label: (
        <div onClick={showApproveModal} className="pointer">
          <Space className="approve-txt">
            <CheckOutlined />
            <span>Approve</span>
          </Space>
        </div>
      ),
      key: 1,
    });
  }

  menuItems.push({
    label: (
      <div onClick={showRejectModal} className="pointer ">
        <Space className="reject-txt">
          <CloseOutlined />
          <span>Reject</span>
        </Space>
      </div>
    ),
    key: 2,
  });

  return !(record.status === "Completed" || record.status === "Rejected") ? (
    <Dropdown
      overlay={<Menu className="dropdown-row" items={menuItems} />}
      trigger="click"
      placement="bottomLeft"
    >
      <Icon component={DropdownSvg} className="dropdown-menu-button" />
    </Dropdown>
  ) : null;
};

export const facilitatorsDeviceColumns = (
  onViewProfile,
  setVisibleApprove,
  setVisibleReject,
  setDropDownUserId
) => [
  {
    render: (text, record) => {
      return (
        <Card
          className="deal-table-card"
          title={
            <Avatar.Group>
              <Row className="pointer" onClick={() => onViewProfile(record)}>
                <Col sm={24} className="center-vertical">
                  <Avatar
                    shape="round"
                    size={50}
                    src={getImageURL(record.img_url || "")}
                    className={`${
                      !record.img_url ? "shape-avatar-letter" : ""
                    } shape-avatar`}
                  >
                    {getFirstLater(record?.name || "")}
                  </Avatar>
                  <div className="avatar-info">
                    <Title level={5}>{record.name}</Title>
                    <p>{record.email}</p>
                  </div>
                </Col>
              </Row>
            </Avatar.Group>
          }
          extra={
            record?.status !== undefined && record.status !== null ? (
              <div className="center-vertical">
                <Tag
                  className="tag_text"
                  color={statusStyle(record.status).backgroundColor}
                >
                  <span
                    className="status-txt"
                    style={{
                      color: statusStyle(record.status).color,
                    }}
                  >
                    &#9679;&nbsp;{record.status}
                  </span>
                </Tag>
                <DropdownExt
                  record={record}
                  setVisibleApprove={setVisibleApprove}
                  setVisibleReject={setVisibleReject}
                  setDropDownUserId={setDropDownUserId}
                />
              </div>
            ) : (
              <h3>-</h3>
            )
          }
        ></Card>
      );
    },
  },
];

export const facilitatorColumns = (
  onViewProfile,
  setVisibleApprove,
  setVisibleReject,
  setDropDownUserId
) => [
  {
    title: ({ sortColumns }) => {
      const sortedColumn = sortColumns?.find(
        ({ column }) => column.key === "name"
      );

      return (
        <div className="center-vertical">
          Name
          {sortedColumn?.order ? (
            sortedColumn.order === "ascend" ? (
              <ArrowUpOutlined style={ml8} />
            ) : (
              <ArrowDownOutlined style={ml8} />
            )
          ) : null}
        </div>
      );
    },

    sorter: (a, b) => a.name.localeCompare(b.name),
    dataIndex: "name",
    key: "name",
    render: (text, record) => {
      return (
        <div className="pointer" onClick={() => onViewProfile(record)}>
          <Avatar.Group>
            <Avatar
              shape="round"
              size={40}
              src={getImageURL(record.img_url || "")}
              className={`${
                !record.img_url ? "shape-avatar-letter" : ""
              } shape-avatar`}
            >
              {getFirstLater(record?.name || "")}
            </Avatar>
            <div className="avatar-info">
              <Title level={5}>{record.name}</Title>
              <p>{record.email}</p>
            </div>
          </Avatar.Group>
        </div>
      );
    },
  },
  {
    title: (
      <div className="ver">
        Status <QuestionCircleOutlined style={ml8} />
      </div>
    ),
    dataIndex: "status",
    key: "status",
    render: (status) => {
      return status !== undefined && status !== null ? (
        <Tag className="tag_text" color={statusStyle(status).backgroundColor}>
          <span
            className="status-txt"
            style={{
              color: statusStyle(status).color,
            }}
          >
            &#9679;&nbsp;{status}
          </span>
        </Tag>
      ) : (
        <h3>-</h3>
      );
    },
  },
  {
    render: (text, record) => {
      return (
        <DropdownExt
          record={record}
          setVisibleApprove={setVisibleApprove}
          setVisibleReject={setVisibleReject}
          setDropDownUserId={setDropDownUserId}
        />
      );
    },
  },
];
