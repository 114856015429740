import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CommonTable from "../common/commonTable";
import { getServiceOrganizerDealsListRequest } from "./redux/action";

import "../adminDeals/style.scss";

const ServiceOrganizerDeals = ({ columns, deviceColumns }) => {
  const serviceOrganizerDealsListState = useSelector(
    (state) => state.serviceOrganizerDeals
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getServiceOrganizerDealsListRequest());
  }, [dispatch]);

  const svcOrgKeys = [
    "All",
    "In Review",
    "Compliance Check",
    "Active",
    "In Progress",
    "Completed",
  ];

  return (
    <div className="admin-deals">
      <CommonTable
        columns={columns}
        deviceColumns={deviceColumns}
        allData={serviceOrganizerDealsListState}
        keys={svcOrgKeys}
      />
    </div>
  );
};

export default ServiceOrganizerDeals;
