export const IPMarketplaceIcon = () => (
  <svg
    width="36"
    height="39"
    viewBox="0 0 36 39"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.6252 0.350361C13.8572 0.216546 14.1428 0.216546 14.3748 0.350361L27.3748 7.85036C27.607 7.9843 27.75 8.23197 27.75 8.5V16.9375C27.75 17.3517 27.4142 17.6875 27 17.6875C26.5858 17.6875 26.25 17.3517 26.25 16.9375V8.93317L14 1.86587L1.75 8.93317V23.0668L14 30.1341L20.7964 26.2131C21.1552 26.0062 21.6138 26.1292 21.8208 26.488C22.0278 26.8468 21.9048 27.3054 21.546 27.5124L14.3748 31.6496C14.1428 31.7835 13.8572 31.7835 13.6252 31.6496L0.625208 24.1496C0.39304 24.0157 0.25 23.768 0.25 23.5V8.5C0.25 8.23197 0.39304 7.9843 0.625208 7.85036L13.6252 0.350361ZM3.97485 9.98934C4.18785 9.63408 4.6485 9.51876 5.00376 9.73176L14 15.1255L22.9963 9.73175C23.3516 9.51876 23.8122 9.63408 24.0252 9.98934C24.2382 10.3446 24.1229 10.8053 23.7676 11.0182L14.75 16.4248V26.3125C14.75 26.7267 14.4142 27.0625 14 27.0625C13.5858 27.0625 13.25 26.7267 13.25 26.3125V16.4248L4.23243 11.0182C3.87718 10.8052 3.76185 10.3446 3.97485 9.98934Z"
      fill="#6B7280"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M28 17.75C24.5482 17.75 21.75 20.5482 21.75 24C21.75 26.2255 22.9126 28.18 24.6672 29.2883C24.8848 29.4257 25.0167 29.6651 25.0167 29.9224V31.25H30.9833V29.9224C30.9833 29.6651 31.1152 29.4257 31.3328 29.2883C33.0874 28.18 34.25 26.2255 34.25 24C34.25 20.5482 31.4518 17.75 28 17.75ZM30.963 32.75H25.037C25.2102 34.2327 26.4708 35.3833 28 35.3833C29.5292 35.3833 30.7897 34.2327 30.963 32.75ZM20.25 24C20.25 19.7198 23.7198 16.25 28 16.25C32.2802 16.25 35.75 19.7198 35.75 24C35.75 26.6105 34.4589 28.9189 32.4833 30.3222V32.4C32.4833 34.6205 30.869 36.464 28.75 36.8209V38C28.75 38.4142 28.4142 38.75 28 38.75C27.5858 38.75 27.25 38.4142 27.25 38V36.8209C25.131 36.464 23.5167 34.6205 23.5167 32.4V30.3222C21.5411 28.9189 20.25 26.6105 20.25 24ZM24.6238 23.5199C24.889 23.2017 25.3619 23.1587 25.6801 23.4238L28 25.3571L30.3199 23.4238C30.6381 23.1587 31.111 23.2017 31.3762 23.5199C31.6413 23.8381 31.5983 24.311 31.2801 24.5762L28.75 26.6846V29.1333C28.75 29.5475 28.4142 29.8833 28 29.8833C27.5858 29.8833 27.25 29.5475 27.25 29.1333V26.6846L24.7199 24.5762C24.4017 24.311 24.3587 23.8381 24.6238 23.5199Z"
      fill="#6B7280"
    />
  </svg>
);
