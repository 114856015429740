import {
  GET_IPS_LIST_REQUEST,
  GET_IPS_LIST_REQUEST_SUCCESS,
  GET_IPS_LIST_REQUEST_ERROR,
  ADMIN_IP_UPDATE_REQUEST,
  ADMIN_IP_UPDATE_SUCCESS,
  ADMIN_IP_UPDATE_ERROR,
} from "./constants";

const initialState = {
  loading: false,
  data: [],
};

export default function adminIpsList(state = initialState, action) {
  switch (action.type) {
    case GET_IPS_LIST_REQUEST:
      return { ...state, loading: true };
    case GET_IPS_LIST_REQUEST_SUCCESS:
      return {
        ...state,
        data: action.data,
        loading: false,
      };
    case GET_IPS_LIST_REQUEST_ERROR:
      return { ...state, data: action.data, loading: false };

    case ADMIN_IP_UPDATE_REQUEST:
      return { ...state, loading: true };
    case ADMIN_IP_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case ADMIN_IP_UPDATE_ERROR:
      return { ...state, loading: false };

    default:
      return state;
  }
}
