import { call, put, takeLatest } from "redux-saga/effects";
import {
  GET_FACILITATORS_LIST_REQUEST,
  GET_FACILITATORS_LIST_SUCCESS,
  GET_FACILITATORS_LIST_ERROR,
  GET_FACILITATORS_SERVICES_REQUEST,
  GET_FACILITATORS_SERVICES_SUCCESS,
  GET_FACILITATORS_SERVICES_ERROR,
  SET_FACILITATORS_SERVICES_REQUEST,
  SET_FACILITATORS_SERVICES_SUCCESS,
  SET_FACILITATORS_SERVICES_ERROR,
  UPDATE_FACILITATOR_STATUS_REQUEST,
  UPDATE_FACILITATOR_STATUS_ERROR,
  UPDATE_FACILITATOR_STATUS_SUCCESS,
} from "./constants";
import {
  getFacilitatorListApi,
  getFacilitatorServicesApi,
  getUserDetailsApi,
  setFacilitatorServicesApi,
  updateFacilitatorApi,
} from "../../../api";
import { getToken, getUser, setUserSession } from "../../../Utils/isAuth";
import { getNextSteps } from "../../../Utils/onboardingUtils";
import { QUESTIONNAIRE_STATIC_SUCCESS } from "../../../redux/auth/constants";

function* requestFacilitatorsList(action) {
  try {
    let { data } = yield call(getFacilitatorListApi, action?.data);
    if (data) {
      yield put({ type: GET_FACILITATORS_LIST_SUCCESS, data });
    }
  } catch ({ response }) {
    if (response) {
      yield put({
        type: GET_FACILITATORS_LIST_ERROR,
        data: response?.data?.Error,
      });
    } else {
      yield put({ type: GET_FACILITATORS_LIST_ERROR, data: "Error" });
    }
  }
}
function* requestFacilitatorServices(action) {
  try {
    let { data } = yield call(getFacilitatorServicesApi, action?.data);
    if (data) {
      yield put({ type: GET_FACILITATORS_SERVICES_SUCCESS, data });
    }
  } catch ({ response }) {
    if (response) {
      yield put({
        type: GET_FACILITATORS_SERVICES_ERROR,
        data: response?.data?.Error,
      });
    } else {
      yield put({ type: GET_FACILITATORS_SERVICES_ERROR, data: "Error" });
    }
  }
}
function* requestSetFacilitatorServices(action) {
  const user = getUser();
  const step = getNextSteps(
    3,
    user?.onboardingStepsDone,
    user?.sub_type_of_account
  );
  try {
    const token = getToken();
    let { data } = yield call(setFacilitatorServicesApi, action?.data);
    if (data) {
      yield put({ type: SET_FACILITATORS_SERVICES_SUCCESS, data });
    }
    if (token) {
      let userDetail = yield call(getUserDetailsApi, token);
      setUserSession(token, user?.data?.user_logo, userDetail?.data);
    }
    if (data) {
      yield put({ type: QUESTIONNAIRE_STATIC_SUCCESS, data: step });
    }
  } catch ({ response }) {
    if (response) {
      yield put({
        type: SET_FACILITATORS_SERVICES_ERROR,
        data: response?.data?.Error,
      });
    } else {
      yield put({ type: SET_FACILITATORS_SERVICES_ERROR, data: "Error" });
    }
  }
}

function* updateTopFacilitatorStatusService(action) {
  try {
    let { data } = yield call(updateFacilitatorApi, action?.data);

    if (data?.Msg) {
      yield put({ type: UPDATE_FACILITATOR_STATUS_SUCCESS, data });
      // Add api call
      yield put({ type: GET_FACILITATORS_LIST_REQUEST });
    }
  } catch (err) {
    yield put({ type: UPDATE_FACILITATOR_STATUS_ERROR });
  }
}

function* FacilitatorWatcher() {
  yield takeLatest(GET_FACILITATORS_LIST_REQUEST, requestFacilitatorsList);
  yield takeLatest(
    GET_FACILITATORS_SERVICES_REQUEST,
    requestFacilitatorServices
  );
  yield takeLatest(
    SET_FACILITATORS_SERVICES_REQUEST,
    requestSetFacilitatorServices
  );
  yield takeLatest(
    UPDATE_FACILITATOR_STATUS_REQUEST,
    updateTopFacilitatorStatusService
  );
}

export default FacilitatorWatcher;
