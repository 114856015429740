import { call, put, takeLatest } from "redux-saga/effects";

import { getMarketplaceListApi } from "../../../api";

import {
  MARKETPLACE_LIST_ERROR,
  MARKETPLACE_LIST_REQUEST,
  MARKETPLACE_LIST_SUCCESS,
} from "./constants";

function* requestMarketplaceList(action) {
  try {
    let { data } = yield call(getMarketplaceListApi, action?.data);

    if (data) {
      yield put({ type: MARKETPLACE_LIST_SUCCESS, data });
    }
  } catch (err) {
    yield put({ type: MARKETPLACE_LIST_ERROR });
  }
}

function* MarketplaceListWatcher() {
  yield takeLatest(MARKETPLACE_LIST_REQUEST, requestMarketplaceList);
}
export default MarketplaceListWatcher;
