import {
  ADD_COMMENT_ERROR,
  ADD_COMMENT_REQUEST,
  ADD_COMMENT_SUCCESS,
  CHENGE_ALLOW_DOC_REQUEST,
  CLEAR_DEAL_ERROR,
  CLEAR_USER_DOC,
  DEAL_DETAILS,
  DELETE_ANY_DOCUMENT_ERROR,
  DELETE_ANY_DOCUMENT_REQUEST,
  GET_USER_DOCUMENTS_ERROR,
  GET_USER_DOCUMENTS_REQUEST,
  GET_USER_DOCUMENTS_SUCCESS,
  INVESTOR_DEAL_DETAILS_ERROR,
  INVESTOR_DEAL_DETAILS_REQUEST,
  INVESTOR_DEAL_DETAILS_SUCCESS,
  INVESTOR_DETAILS_ERROR,
  INVESTOR_DETAILS_REQUEST,
  INVESTOR_DETAILS_SUCCESS,
  ORGANIZER_DEAL_DETAILS_ERROR,
  ORGANIZER_DEAL_DETAILS_REQUEST,
  ORGANIZER_DEAL_DETAILS_SUCCESS,
  PUBLISH_DEAL_ERROR,
  PUBLISH_DEAL_REQUEST,
  SELECT_DEALTYPE_REQUEST,
  PUBLISH_DEAL_SUCCESS,
  SIGN_ANY_DOCUMENT_ERROR,
  SIGN_ANY_DOCUMENT_REQUEST,
  SIGN_ANY_DOCUMENT_SUCCESS,
  UPLOAD_ANY_DOCUMENT_ERROR,
  UPLOAD_ANY_DOCUMENT_REQUEST,
  UPLOAD_ANY_DOCUMENT_SUCCESS,
  UPLOAD_DOCUMENT_ERROR,
  UPLOAD_DOCUMENT_REQUEST,
  UPLOAD_DOCUMENT_SUCCESS,
  UPLOAD_SIGN_ANY_DOCS_ERROR,
  UPLOAD_SIGN_ANY_DOCS_REQUEST,
  UPLOAD_SIGN_ANY_DOCS_SUCCESS,
  PUBLISH_DEAL_FALSE,
  GET_DATA_ROOM_SUCCESS,
  GET_DATA_ROOM_ERROR,
  GET_DATA_ROOM_REQUEST,
  UPDATE_ANY_DOCUMENT_REQUEST,
  UPLOAD_DATA_ROOM_REQUEST,
  UPLOAD_DATA_ROOM_ERROR,
  ASK_PERMISSION_TO_DOCUMENT_ERROR,
  ASKED_PERMISSION_TO_DOCUMENTS_SUCCESS,
  GIVE_PERMISSION_TO_DOCUMENTS_SUCCESS,
  GIVE_PERMISSION_TO_DOCUMENTS_REQUEST,
  UNPUBLISH_DEAL_REQUEST,
  UNPUBLISH_DEAL_SUCCESS,
  UNPUBLISH_DEAL_ERROR,
} from "./constants";

const initialState = {
  loading: false,
  published: false,
  selectDealType: false,
  usersDetails: {},
  errorMessage: null,
  uploadSuccess: false,
  investMessage: null,
  permissionsAsked: [],
  paymentDocument: [],
  userDocuments: null,
  data: {},
  envelopeUrl: null,
  pending: null,
  signed: [],
  msg: "",
};

export default function dealDetail(state = initialState, action) {
  switch (action.type) {
    case DEAL_DETAILS:
      return {
        ...state,
        data: action.data,
      };

    case ORGANIZER_DEAL_DETAILS_REQUEST:
    case UPDATE_ANY_DOCUMENT_REQUEST:
    case GET_DATA_ROOM_REQUEST:
    case UPLOAD_DATA_ROOM_REQUEST:
      return { ...state, loading: true };
    case ORGANIZER_DEAL_DETAILS_SUCCESS:
      return {
        ...state,
        data: action.data,
        loading: false,
      };
    case ORGANIZER_DEAL_DETAILS_ERROR:
      return { ...state, loading: false };
    case INVESTOR_DEAL_DETAILS_REQUEST:
      return { ...state, loading: true };
    case INVESTOR_DEAL_DETAILS_SUCCESS:
      return {
        ...state,
        data: action.data.deals,
        investMessage: action.data.message,
        investStatus: action.data.investment_status,
        paymentDocument: action.data.payment_document,
        loading: false,
      };
    case INVESTOR_DEAL_DETAILS_ERROR:
      return { ...state, loading: false };

    case PUBLISH_DEAL_REQUEST:
    case UNPUBLISH_DEAL_REQUEST:
      return { ...state, loading: true };
    case UNPUBLISH_DEAL_SUCCESS:
      return {
        ...state,
        loading: false,
        published: false,
        msg: action.data,
      };
    case PUBLISH_DEAL_SUCCESS:
      return {
        ...state,
        published: true,
        loading: false,
        msg: action.data,
      };
    case PUBLISH_DEAL_FALSE:
      return {
        ...state,
        published: false,
      };
    case UNPUBLISH_DEAL_ERROR:
    case PUBLISH_DEAL_ERROR:
      return { ...state, loading: false };

    case ADD_COMMENT_REQUEST:
      return { ...state, loading: true };
    case ADD_COMMENT_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case INVESTOR_DETAILS_REQUEST:
      return { ...state, loading: true, userDocuments: [] };
    case INVESTOR_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        usersDetails: {
          ...state?.usersDetails,
          ...action?.data,
        },
      };
    case ADD_COMMENT_ERROR:
      return { ...state, loading: false };
    case INVESTOR_DETAILS_ERROR:
      return { ...state, loading: false };
    case UPLOAD_DOCUMENT_REQUEST:
      return { ...state, loading: true };
    case UPLOAD_DOCUMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        uploadSuccess: true,
        paymentDocument: [...state.paymentDocument, action?.data],
      };

    case UPLOAD_DOCUMENT_ERROR:
      return { ...state, loading: false, errorMessage: action.data };
    case UPLOAD_ANY_DOCUMENT_REQUEST:
      return { ...state, loading: true };
    case UPLOAD_ANY_DOCUMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        uploadSuccess: true,
        userDocuments: [...state.userDocuments, action?.data],
      };

    case UPLOAD_ANY_DOCUMENT_ERROR:
      return { ...state, loading: false, errorMessage: action.data };

    case GET_USER_DOCUMENTS_REQUEST:
    case CHENGE_ALLOW_DOC_REQUEST:
    case DELETE_ANY_DOCUMENT_REQUEST:
      return { ...state, loading: true };
    case GET_USER_DOCUMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        uploadSuccess: true,
        userDocuments: [...action?.data],
      };
    case GET_DATA_ROOM_SUCCESS:
      return {
        ...state,
        loading: false,
        uploadSuccess: true,
        userDocuments: action?.data,
      };
    case GET_USER_DOCUMENTS_ERROR:
    case GET_DATA_ROOM_ERROR:
    case UPLOAD_DATA_ROOM_ERROR:
      return { ...state, loading: false, errorMessage: action.data };
    case SIGN_ANY_DOCUMENT_REQUEST:
    case GIVE_PERMISSION_TO_DOCUMENTS_REQUEST:
      return { ...state, loading: true };
    case ASKED_PERMISSION_TO_DOCUMENTS_SUCCESS:
      return { ...state, permissionsAsked: action.data };
    case GIVE_PERMISSION_TO_DOCUMENTS_SUCCESS:
      return { ...state, loading: false, permissionsAsked: action.data };
    case SIGN_ANY_DOCUMENT_SUCCESS:
      return {
        ...state,
        [action.data.id]: action.data.envelope_id,
        envelopeUrl: action.data.envelope_url,
        pending: action.data.id,
        loading: false,
      };
    case DELETE_ANY_DOCUMENT_ERROR:
    case SIGN_ANY_DOCUMENT_ERROR:
    case ASK_PERMISSION_TO_DOCUMENT_ERROR:
      return { ...state, loading: false, errorMessage: action.data };
    case CLEAR_USER_DOC:
      return { ...state, loading: false, userDocuments: [] };
    case UPLOAD_SIGN_ANY_DOCS_REQUEST:
      return {
        ...state,
        envelopeUrl: null,
        loading: true,
      };
    case UPLOAD_SIGN_ANY_DOCS_SUCCESS:
      return {
        ...state,
        envelopeUrl: null,
        signed: [...state.signed, state.pending],
        pending: null,
        loading: false,
      };
    case UPLOAD_SIGN_ANY_DOCS_ERROR:
      return {
        ...state,
        envelopeUrl: null,
        pending: null,
        loading: false,
        errorMessage: action.data,
      };
    case CLEAR_DEAL_ERROR:
      return { ...state, loading: false, errorMessage: null };

    case SELECT_DEALTYPE_REQUEST:
      return {
        ...state,
        loading: false,
        selectDealType: true,
      };
    default:
      return state;
  }
}
