import { Checkbox, Form, Input, Modal, Row } from "antd";
import { useEffect, useState } from "react";

const EscrowModal = ({ isModalVisible, amount, handleOk, handleCancel }) => {
  const [form] = Form.useForm();
  const [transpactNominated, setTranspactNominated] = useState(false);

  const colums = [
    {
      value: "amount",
      title: "Amount",
      isRequired: true,
      isNumber: true,
      isDisable: true,
      rules: [
        () => ({
          validator(_, value) {
            if (isNaN(value)) {
              return Promise.reject("Max days has to be a number.");
            }
            if (value < 1) {
              return Promise.reject("Amount can't less than 1");
            }

            return Promise.resolve();
          },
        }),
      ],
    },
    {
      value: "max_days_dispute_pay_wait",
      title: "Max days dispute pay wait",
      rules: [
        { require: true },
        () => ({
          validator(_, value) {
            if (isNaN(value)) {
              return Promise.reject("Max days has to be a number.");
            }
            if (value < 5) {
              return Promise.reject("Max days can't less than 4");
            }
            if (value > 60) {
              return Promise.reject("Max days can't be more than 60 digits");
            }
            return Promise.resolve();
          },
        }),
      ],
      isRequired: true,
      isNumber: true,
    },
    {
      value: "conditions",
      title: "Conditions",
      rules: [{ require: true }],
      isRequired: true,
      isTextArea: true,
    },
    {
      value: "transpact_nominated_referee",
      title:
        "Allow Transpact to nominate the neutral referee if there is a dispute",
      checkbox: true,
    },
    {
      value: "name_referee",
      title: "Name referee",
      needHide: true,
    },
    {
      value: "email_referee",
      title: "Email referee",
      needHide: true,
    },
  ];

  useEffect(() => {
    form.setFieldsValue({
      amount: Number(amount),
      transpact_nominated_referee: false,
    });
  }, [amount]);

  const modalFields = (form) => {
    console.log(form.getFieldValue("transpact_nominated_referee"), 77777);
    return colums.map((el) => {
      return (
        <Form.Item
          key={el.dataIndex}
          label={el.checkbox ? null : el.title}
          rules={el.rules}
          name={el.value}
          required={el.isRequired}
          hidden={el.needHide && transpactNominated}
        >
          {el.checkbox ? (
            <Checkbox
              name={el.value}
              checked={transpactNominated}
              onChange={(e) => setTranspactNominated(e.target.checked)}
            >
              <span className="remember_me">{el?.title}</span>
            </Checkbox>
          ) : el.isTextArea ? (
            <Input.TextArea rows={2} />
          ) : (
            <Input
              type={el?.isNumber ? "number" : "text"}
              disabled={el?.isDisable}
            />
          )}
        </Form.Item>
      );
    });
  };

  const handleOkClick = (data) => {
    if (transpactNominated) {
      handleOk({
        ...data,
        name_referee: null,
        email_referee: null,
        transpact_nominated_referee: transpactNominated,
      });
    } else {
      handleOk({ ...data, transpact_nominated_referee: transpactNominated });
    }

    handleCancel();
    form.resetFields();
  };

  return (
    <>
      <Modal
        title="Escrow account"
        open={isModalVisible}
        onOk={() => form.submit()}
        onCancel={() => {
          form.resetFields();
          handleCancel();
        }}
        okText="Save"
        className="modal-form"
        centered
        width={430}
      >
        <Row
          gutter={[0, 16]}
          className="modal-form__body modal-form__invest-body"
        >
          <Form
            layout="vertical"
            form={form}
            onFinish={handleOkClick}
            initialValues={{
              amount: Number(amount),
              transpact_nominated_referee: false,
            }}
          >
            {modalFields(form)}
          </Form>
        </Row>
      </Modal>
    </>
  );
};

export default EscrowModal;
