import React from "react";
import { Row, Col, Input, Select } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { CheckOutlined } from "@ant-design/icons";
import SelectIcon from "../../../assets/images/select-icon.svg";
import "../MarketPlaceTabHeader/style.scss";

const { Option } = Select;

function UserHeaderSearchBar({ searchFilter, setRole, showRoles = true }) {
  const { usersRole } = useSelector((state) => state.userPage);

  return (
    <div className="marketplace_tab_header">
      <Row gutter={[8, 0]}>
        <Col md={6}>
          <div className="mkt_header_search">
            <Input
              className="search_btn"
              onChange={searchFilter}
              placeholder="Search "
              prefix={<SearchOutlined />}
            />
          </div>
        </Col>
        {showRoles && (
          <Col md={6}>
            <div className="mkt_header_search">
              <Select
                placeholder="Search"
                autoClearSearchValue={true}
                optionFilterProp="label"
                onSelect={(value) => setRole(value)}
                onDeselect={() => setRole(undefined)}
                name="users-select"
                className="role-filter"
                allowClear
                menuItemSelectedIcon={
                  <CheckOutlined height={20} width={20} color="#0B5394" />
                }
                suffixIcon={<img src={SelectIcon} alt="icon" />}
              >
                {usersRole?.map((role) => (
                  <Option value={role?.value} label={role?.label}>
                    {role?.label}
                  </Option>
                ))}
              </Select>
            </div>
          </Col>
        )}
        <Col span={12} md={18} className="header-control"></Col>
      </Row>
    </div>
  );
}

export default UserHeaderSearchBar;
