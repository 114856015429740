export const PUBLISH_IP_REQUEST = "PUBLISH_IP_REQUEST";
export const PUBLISH_IP_SUCCESS = "PUBLISH_IP_SUCCESS";
export const PUBLISH_IP_ERROR = "PUBLISH_IP_ERROR";

export const UNPUBLISH_IP_REQUEST = "UNPUBLISH_IP_REQUEST";
export const UNPUBLISH_IP_SUCCESS = "UNPUBLISH_IP_SUCCESS";
export const UNPUBLISH_IP_ERROR = "UNPUBLISH_IP_ERROR";

export const GET_IP_DETAILS_REQUEST = "GET_IP_DETAILS_REQUEST";
export const GET_IP_DETAILS_SUCCESS = "GET_IP_DETAILS_SUCCESS";
export const GET_IP_DETAILS_ERROR = "GET_IP_DETAILS_ERROR";

export const GET_INVESTOR_IP_DETAILS_REQUEST =
  "GET_INVESTOR_IP_DETAILS_REQUEST";
export const GET_INVESTOR_IP_DETAILS_SUCCESS =
  "GET_INVESTOR_IP_DETAILS_SUCCESS";
export const GET_INVESTOR_IP_DETAILS_ERROR = "GET_INVESTOR_IP_DETAILS_ERROR";

export const GET_IP_DATA_ROOM_REQUEST = "GET_IP_DATA_ROOM_REQUEST";
export const GET_IP_DATA_ROOM_SUCCESS = "GET_IP_DATA_ROOM_SUCCESS";
export const GET_IP_DATA_ROOM_ERROR = "GET_IP_DATA_ROOM_ERROR";

export const ADD_COMMENT_IP_REQUEST = "ADD_COMMENT_IP_REQUEST";
export const ADD_COMMENT_IP_SUCCESS = "ADD_COMMENT_IP_SUCCESS";
export const ADD_COMMENT_IP_ERROR = "ADD_COMMENT_IP_ERROR";

export const ASKED_PERMISSION_TO_DOCUMENTS_REQUEST_IP =
  "ASKED_PERMISSION_TO_DOCUMENTS_REQUEST_IP";
export const ASKED_PERMISSION_TO_DOCUMENTS_SUCCESS_IP =
  "ASKED_PERMISSION_TO_DOCUMENTS_SUCCESS_IP";
export const ASKED_PERMISSION_TO_DOCUMENTS_ERROR_IP =
  "ASKED_PERMISSION_TO_DOCUMENTS_ERROR_IP";

export const GIVE_PERMISSION_TO_DOCUMENTS_IP_REQUEST =
  "GIVE_PERMISSION_TO_DOCUMENTS_IP_REQUEST";
export const GIVE_PERMISSION_TO_DOCUMENTS_IP_SUCCESS =
  "GIVE_PERMISSION_TO_DOCUMENTS_IP_SUCCESS";
export const GIVE_PERMISSION_TO_DOCUMENTS_IP_ERROR =
  "GIVE_PERMISSION_TO_DOCUMENTS_IP_ERROR";

export const ASK_PERMISSION_TO_DOCUMENT_IP_REQUEST =
  "ASK_PERMISSION_TO_DOCUMENT_IP_REQUEST";
export const ASK_PERMISSION_TO_DOCUMENT_IP_SUCCESS =
  "ASK_PERMISSION_TO_DOCUMENT_IP_SUCCESS";
export const ASK_PERMISSION_TO_DOCUMENT_IP_ERROR =
  "ASK_PERMISSION_TO_DOCUMENT_IP_ERROR";

export const UPLOAD_DATA_ROOM_IP_REQUEST = "UPLOAD_DATA_ROOM_IP_REQUEST";
export const UPLOAD_DATA_ROOM_IP_SUCCESS = "UPLOAD_DATA_ROOM_IP_SUCCESS";
export const UPLOAD_DATA_ROOM_IP_ERROR = "UPLOAD_DATA_ROOM_IP_ERROR";

export const UPDATE_ANY_IP_DOCUMENT_REQUEST = "UPDATE_ANY_IP_DOCUMENT_REQUEST";
export const UPDATE_ANY_IP_DOCUMENT_SUCCESS = "UPDATE_ANY_IP_DOCUMENT_SUCCESS";
export const UPDATE_ANY_IP_DOCUMENT_ERROR = "UPDATE_ANY_IP_DOCUMENT_ERROR";

export const CLEAR_USER_IP_DOC = "CLEAR_USER_IP_DOC";

export const UPLOAD_SIGN_ANY_IP_DOCS_REQUEST =
  "UPLOAD_SIGN_ANY_IP_DOCS_REQUEST";
export const UPLOAD_SIGN_ANY_IP_DOCS_SUCCESS =
  "UPLOAD_SIGN_ANY_IP_DOCS_SUCCESS";
export const UPLOAD_SIGN_ANY_IP_DOCS_ERROR = "UPLOAD_SIGN_ANY_IP_DOCS_ERROR";

export const SIGN_ANY_IP_DOCUMENT_REQUEST = "SIGN_ANY_IP_DOCUMENT_REQUEST";
export const SIGN_ANY_IP_DOCUMENT_SUCCESS = "SIGN_ANY_IP_DOCUMENT_SUCCESS";
export const SIGN_ANY_IP_DOCUMENT_ERROR = "SIGN_ANY_IP_DOCUMENT_ERROR";

export const DELETE_ANY_IP_DOCUMENT_REQUEST = "DELETE_ANY_IP_DOCUMENT_REQUEST";
export const DELETE_ANY_IP_DOCUMENT_SUCCESS = "DELETE_ANY_IP_DOCUMENT_SUCCESS";
export const DELETE_ANY_IP_DOCUMENT_ERROR = "DELETE_ANY_IP_DOCUMENT_ERROR";

export const CLEAR_IPDETAILS = "CLEAR_IPDETAILS";
