import { Col, Row } from "antd";
import React from "react";

import FinancialsLinkList from "./FinancialsLinkList";
import KpiTable from "./KpiTable";
import PastFundRaisersTable from "./PastFundRaisersTable";

const FinancialsTab = ({ data }) => {
  return (
    <>
      <Row gutter={[24, 0]}>
        <Col span={24} style={{ marginBottom: "20px" }}>
          <h2 className="financial_title">Key financial indicators</h2>
        </Col>

        <Col span={24} style={{ marginBottom: "20px" }}>
          <KpiTable data={data} />
        </Col>

        <Col span={24} style={{ marginBottom: "20px" }}>
          <h2 className="financial_title">Past Fundraises</h2>
        </Col>

        <Col span={24} style={{ marginBottom: "20px" }}>
          <PastFundRaisersTable data={data} />
        </Col>

        <Col span={24} style={{ marginBottom: "20px" }}>
          <h2 className="financial_title">Shared links</h2>
        </Col>

        <Col span={24} style={{ marginBottom: "20px" }}>
          <FinancialsLinkList data={data.links} />
        </Col>
      </Row>
    </>
  );
};

export default FinancialsTab;
