import React, { useState, useEffect, useMemo } from "react";
import { Spin, message, Modal, Form, Select, Checkbox, Button } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import UserHeaderSearchBar from "../../components/Common/UsersTabHeader/UserTabHeader";
import UsersTable from "./UsersTable";
import { usersColumns, usersDeviceColumns } from "./Constants.js";
import noUsers from "../../assets/images/no-users.svg";
import { getAllProjects, getUsersData, updateUsersData } from "../../api";

const UserEmpty = (
  <div className="no-users-table-img">
    <img src={noUsers} alt="no data" />
    <span className="title-txt">Invite and interract with users</span>
    <span className="description-txt">
      Please contact your manager to fix this problem.
    </span>
    <span className="description-txt">
      Or you can directly invite new one in right corner.
    </span>
  </div>
);

const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;

const plainOptions = [
  "Partnerships",
  "Whitelabel Solutions",
  "Investable Projects",
  "Business Cooperation",
  "Analytical Dashboards",
  "Ecosystems As A Service",
  "Longevity Projects",
  "DeepTech Projects",
  "Philanthropy Projects",
  "Thematic Projects",
  "BioMed Projects",
  "AI Industry Projects",
  "GovTech Projects",
];

function Users() {
  const [search, setSearch] = useState("");
  const [user, setUser] = useState([]);
  const [loading, setLoading] = useState(true);

  const [showConfiguration, setShowConfiguration] = useState(false);
  const [checkedList, setCheckedList] = useState([]);
  const [projects, setProjects] = useState([]);

  const checkAll = plainOptions.length === checkedList.length;
  const indeterminate =
    checkedList.length > 0 && checkedList.length < plainOptions.length;

  const onChange = (list) => {
    setCheckedList(list);
  };

  const onCheckAllChange = (e) => {
    setCheckedList(e.target.checked ? plainOptions : []);
  };

  const fetchUsers = () => {
    setLoading(true);

    getUsersData()
      .then(({ data }) => {
        setUser(data);
      })
      .catch(() => {
        message.error("Something went wrong, Please try again !!");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchAllProjects = () => {
    getAllProjects()
      .then(({ data }) => {
        setProjects(
          data?.projects?.map(({ projectName, _id }) => ({
            value: _id,
            title: projectName,
          }))
        );
      })
      .catch(() => {
        message.error("Something went wrong, Please try again !!");
      });
  };

  useEffect(() => {
    fetchUsers();
    fetchAllProjects();
  }, []);

  const filteredTableData = useMemo(() => {
    return user.filter(
      (item) =>
        !search ||
        (search &&
          String(item.email)
            .toLowerCase()
            .includes(String(search).toLowerCase()))
    );
  }, [user, search]);

  const [form] = Form.useForm();

  const onConfigure = (record) => {
    setShowConfiguration(record);

    if (record.categories?.[0] === "all") {
      setCheckedList(plainOptions);
    } else {
      setCheckedList(record.categories);
    }

    form.setFieldsValue({
      roles: record.roles,
      projects: record.projects,
    });
  };

  const onCancel = () => {
    setCheckedList([]);
    form.resetFields();
    setShowConfiguration(null);
  };

  const onSaveConfiguration = () => {
    let categories = [...checkedList];
    if (checkAll) {
      categories = ["all"];
    }

    categories.push("access");

    updateUsersData({
      categories,
      roles: form.getFieldValue("roles"),
      projects: form.getFieldValue("projects"),
      _id: showConfiguration._id,
    })
      .then((res) => {
        fetchUsers();
        onCancel();
        message.success(res.data.message);
      })
      .catch((err) => {
        message.error(
          err.response?.data?.message ||
            "Something went wrong, Please try again"
        );
      });
  };

  return (
    <div className="admin-users">
      {loading && (
        <div className="spinner_background">
          <Spin className="spinner" indicator={antIcon} />
        </div>
      )}
      <UserHeaderSearchBar
        searchFilter={(e) => setSearch(e.target.value)}
        showRoles={false}
      />
      <UsersTable
        data={filteredTableData}
        loading={false}
        columns={usersColumns(onConfigure)}
        deviceColumns={usersDeviceColumns(onConfigure)}
        empty={UserEmpty}
      />
      <Modal
        open={showConfiguration}
        onOk={onSaveConfiguration}
        onCancel={onCancel}
        className="approve-modal"
        centered={true}
        okText="Save"
        cancelText="Cancel"
        title="Configure User"
      >
        <Form form={form} layout="vertical" style={{ textAlign: "left" }}>
          <Form.Item name="roles" label="Role:">
            <Select placeholder="Select roles">
              <Select.Option value="Admin">Admin</Select.Option>
              <Select.Option value="Organisation">User</Select.Option>
              <Select.Option value="Partner">Partner</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item name="access" label="Access:">
            <Checkbox
              indeterminate={indeterminate}
              onChange={onCheckAllChange}
              checked={checkAll}
            >
              Check all
            </Checkbox>

            <Checkbox.Group
              options={plainOptions}
              value={checkedList}
              onChange={onChange}
            />
          </Form.Item>
          <Form.Item
            name="projects"
            labelCol={{ className: "projects-label" }}
            label={
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                Projects:
                <Button
                  onClick={() =>
                    form.setFieldValue(
                      "projects",
                      projects.map(({ value }) => value)
                    )
                  }
                  style={{ margin: "10px 0 10px", marginLeft: "auto" }}
                >
                  Select All Projects
                </Button>
              </div>
            }
          >
            <Select placeholder="Select projects" mode="multiple">
              {projects.map(({ title, value }) => (
                <Select.Option value={value}>{title}</Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}

export default Users;
