import axios from "axios";
import _ from "lodash";
import { getRefreshToken, getToken, setUserSession } from "../Utils/isAuth";
import queryString from "qs";
import { message } from "antd";

export const BASE_URL =
  process.env.NODE_ENV === "development"
    ? "https://spv-dev.dkv.global:8000"
    : `${window.location.origin}:8000`;

const externalApi = axios.create({
  baseURL: "https://platform.dkv.global",
  paramsSerializer: (params) =>
    queryString.stringify(params, { arrayFormat: "repeat" }),
  headers: {
    Accept: "*/*",
    "Content-Type": "application/json",
    Authorization: "Bearer " + getToken(),
  },
});

externalApi.interceptors.request.use(
  (config) => {
    const token = getToken();

    if (token && !config?.headers?.Authorization) {
      config.headers["Authorization"] = "Bearer " + token;
    }

    if (config?._retry) {
      config.headers["Authorization"] = "Bearer " + token;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

externalApi.interceptors.response.use(
  (response) => {
    return response;
  },

  (error) => {
    const originalRequest = error.config;

    if (error.response.status === 401 && !originalRequest?._retry) {
      originalRequest._retry = true;
      const refreshToken = getRefreshToken();

      return axios
        .post(`${BASE_URL}/api/user/token/`, {
          refresh: refreshToken,
        })
        .then((res) => {
          if (res.status === 201 || res.status === 200) {
            axios.defaults.headers.common["Authorization"] =
              "Bearer " + res.data.access;
            setUserSession(res.data.access);

            return axios(originalRequest);
          }
        });
    }

    return Promise.reject(error);
  }
);

export const getInvestors = async (pagination, filterValues) => {
  let url = `/dashboards/api/companies-list-for-investtech/?section=investors&dashboard=longevity-investment&email=True&page=${pagination.pageNo}&per_page=${pagination.size}&show_labels=Industry%20Groups`;

  let filters = _.omitBy(filterValues, (v) => !v || v?.length === 0);

  for (const property in filters) {
    if (typeof filterValues[property] === "string") {
      url = url + `&${property}=${filterValues[property]}`;
    } else {
      // eslint-disable-next-line no-loop-func
      filterValues[property].map((item) => {
        url = url + `&${property}=${item}`;
        return item;
      });
    }
  }

  return externalApi.post(url);
};

export const getFiletrsForInvestors = async (pagination, filterValues) => {
  let url = `/dashboards/api/companies-filters-for-investtech/?section=investors&dashboard=longevity-investment&email=True&fields=staff&fields=business-model&fields=financial-service-type&fields=investor-type&fields=industry-segment&fields=industry-groups&fields=industries&fields=fund_rev&fields=country&fields=ipo_status&sorting=fund_total&fields=year&fields=longevity-sector&fields=longevity-subsector&sorting=fund_total`;

  try {
    let res = await externalApi.post(url);

    let data = [];
    for (let x in res.data.filters) {
      let filter = {};
      if (x === "longevity-sector") {
        filter.multiple = false;
      }
      filter.title = x;
      filter.label = res.data.filters[x].label;
      filter.name = res.data.filters[x].name;
      filter.values = res.data.filters[x].value.map((item) => item[1]);
      data.push(filter);
    }
    return data;
  } catch (error) {
    message.error("Something went wrong, While fetching investor filters");
    return [];
  }
};

export const getMatchingTools = async (groupName) => {
  let url = `/dashboards/api/companies-list-for-investtech/?dashboard=longevity-investment&section=investors&category_2=${groupName}`;
  return externalApi.post(url);
};

export const getSwotMetrics = async (companyId) => {
  let url = `/p/swot-metrics/get-company-metrics?dashboard=longevity-investment&company_id=${companyId}`;
  return externalApi.get(url);
};

export const getComparativeAnalysis = (mode) => {
  let url = `/dashboards/api/get-company-competitors/?company=012factory&dashboard=longevity-investment&mode=${mode}`;
  return externalApi.get(url);
};
