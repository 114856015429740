import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { getUser } from "../../Utils/isAuth";
import AdminIps from "../adminIps/AdminIps";
import ServiceOrganizerIps from "./ServiceOrganizerIps";
import {
  OrganizerColumns,
  OrganizerDeviceColumns,
} from "../../components/Common/IPTable/OrganizerColumns";
import InvestorIps from "./InvestorIps";
import OrganizerIps from "./OrganizerIps";
import {
  adminColumns,
  adminDeviceColumns,
} from "../../components/Common/IPTable/AdminColumns";
import {
  investorColumns,
  investorDeviceColumns,
} from "../../components/Common/IPTable/InvestorColumns";

function Ips() {
  const userRole = getUser()?.type_of_account;
  const user = getUser();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return user && user.is_superuser ? (
    <AdminIps
      columns={adminColumns(dispatch, navigate)}
      deviceColumns={adminDeviceColumns(dispatch, navigate)}
    />
  ) : userRole === "ORG" ? (
    userRole && (
      <OrganizerIps
        columns={OrganizerColumns(dispatch, navigate)}
        deviceColumns={OrganizerDeviceColumns(dispatch, navigate)}
        userRole={userRole}
      />
    )
  ) : userRole === "SVC_ORG" ? (
    userRole && (
      <ServiceOrganizerIps
        columns={adminColumns(dispatch, navigate)}
        deviceColumns={adminDeviceColumns(dispatch, navigate)}
        userRole={userRole}
      />
    )
  ) : userRole ? (
    <InvestorIps
      columns={investorColumns(dispatch, navigate)}
      deviceColumns={investorDeviceColumns(dispatch, navigate)}
      userRole={userRole}
    />
  ) : null;
}

export default Ips;
