export const GET_DEAL_INVESTING_REQUEST = "GET_DEAL_INVESTING_REQUEST";
export const GET_DEAL_INVESTING_SUCCESS = "GET_DEAL_INVESTING_SUCCESS";
export const GET_DEAL_INVESTING_ERROR = "GET_DEAL_INVESTING_ERROR";

export const SIGN_INVEST_DOCS_OPEN_REQUEST = "SIGN_INVEST_DOCS_OPEN_REQUEST";
export const SIGN_INVEST_DOCS_OPEN_SUCCESS = "SIGN_INVEST_DOCS_OPEN_SUCCESS";
export const SIGN_INVEST_DOCS_OPEN_ERROR = "SIGN_INVEST_DOCS_OPEN_ERROR";

export const INVEST_DEAL_REQUEST = "INVEST_DEAL_REQUEST";
export const INVEST_DEAL_SUCCESS = "INVEST_DEAL_SUCCESS";
export const INVEST_DEAL_ERROR = "INVEST_DEAL_ERROR";
export const INVEST_DEAL_INVEST_FALSE = "INVEST_DEAL_INVEST_FALSE";

export const SIGN_INVEST_DOCS_SUCCESS = "SIGN_INVEST_DOCS_SUCCESS";
export const CLEAR_INVEST_ERROR = "CLEAR_INVEST_ERROR";
export const SIGN_INVEST_DOCS_CLOSE = "SIGN_INVEST_DOCS_CLOSE";

export const CLEAR_INVEST_STORE = "CLEAR_INVEST_STORE";

export const IP_INVEST_REQUEST = "IP_INVEST_REQUEST";
export const IP_INVEST_SUCCESS = "IP_INVEST_SUCCESS";
export const IP_INVEST_ERROR = "IP_INVEST_ERROR";

export const CREATE_IP_INVEST_REQUEST = "CREATE_IP_INVEST_REQUEST";
export const CREATE_IP_INVEST_SUCCESS = "CREATE_IP_INVEST_SUCCESS";
export const CREATE_IP_INVEST_ERROR = "CREATE_IP_INVEST_ERROR";

export const GET_ESCROW_REQUEST = "GET_ESCROW_REQUEST";
export const GET_ESCROW_SUCCESS = "GET_ESCROW_SUCCESS";
export const GET_ESCROW_ERROR = "GET_ESCROW_ERROR";

export const CREATE_ESCROW_REQUEST = "CREATE_ESCROW_REQUEST";
export const CREATE_ESCROW_SUCCESS = "CREATE_ESCROW_SUCCESS";
export const CREATE_ESCROW_ERROR = "CREATE_ESCROW_ERROR";
