import { call, put, takeLatest } from "redux-saga/effects";
import { createIp, editIp, getIPAllTitles, getOrganizerIPDetails } from "../../../api";

import { getToken } from "../../../Utils/isAuth";
import {
  STATE_CREATE_IP_ERROR,
  STATE_CREATE_IP_SUCCESS,
  STATE_CREATE_IP_REQUEST,
  EDIT_IP_DETAILS_SUCCESS,
  EDIT_IP_DETAILS_ERROR,
  EDIT_IP_DETAILS_REQUEST,
  UPDATE_IP_ERROR,
  UPDATE_IP_REQUEST,
  UPDATE_IP_SUCCESS,
  UPDATE_IP_STEP_REQUEST,
  UPDATE_IP_STEP_SUCCESS,
  UPDATE_IP_STEP_ERROR,
  IP_TITLES_SUCCESS,
  IP_TITLES_ERROR,
  IP_TITLES_REQUEST,
} from "./constants";

function* requestCreateIp(action) {
  try {
    const token = getToken();
    let { data } = yield call(createIp, action?.data, token);
    if (data) {
      yield put({ type: STATE_CREATE_IP_SUCCESS, data });
    }
  } catch ({ response }) {
    console.log(response, "response");
    if (response) {
      yield put({
        type: STATE_CREATE_IP_ERROR,
        data:
          response?.data?.Error ||
          response?.data?.errors ||
          response?.data?.error ||
          response?.data
            ? "Something went wrong, Please try again !!"
            : "",
      });
    } else {
      yield put({
        type: STATE_CREATE_IP_ERROR,
        data: "Something went wrong, Please try again !!",
      });
    }
  }
}

function* requestUpdateDeal(action) {
  try {
    const token = getToken();
    let { data } = yield call(editIp, action?.data, token);

    if (data) {
      yield put({ type: UPDATE_IP_SUCCESS, data });
    }
  } catch ({ response }) {
    if (response) {
      yield put({
        type: UPDATE_IP_ERROR,
        data:
          response?.data?.Error ||
          response?.data?.errors ||
          response?.data?.error,
      });
    } else {
      yield put({
        type: UPDATE_IP_ERROR,
        data: "Something went wrong, Please try again !!",
      });
    }
  }
}

function* requestUpdateDealOnStep(action) {
  try {
    const token = getToken();
    let { data } = yield call(editIp, action?.data, token);

    if (data) {
      yield put({ type: UPDATE_IP_STEP_SUCCESS });
    }
  } catch ({ response }) {
    if (response) {
      yield put({
        type: UPDATE_IP_STEP_ERROR,
        data:
          response?.data?.Error ||
          response?.data?.errors ||
          response?.data?.error,
      });
    } else {
      yield put({
        type: UPDATE_IP_STEP_ERROR,
        data: "Something went wrong, Please try again !!",
      });
    }
  }
}

// function* requestGetSectors(action) {
//   try {
//     const token = getToken();
//     const { data } = yield call(sectors, token);

//     if (data) {
//       yield put({
//         type: GET_SECTORS_SUCCESS,
//         data,
//       });
//     }
//   } catch (err) {
//     yield put({ type: GET_SECTORS_ERROR });
//   }
// }

function* requestEditIPDetails(action) {
  try {
    let { data } = yield call(getOrganizerIPDetails, action?.data);

    if (data) {
      yield put({ type: EDIT_IP_DETAILS_SUCCESS, data });
    }
  } catch (err) {
    yield put({ type: EDIT_IP_DETAILS_ERROR });
  }
}

function* requestIPTitles(action) {
  try {
    let { data } = yield call(getIPAllTitles, action?.data);

    if (data) {
      yield put({ type: IP_TITLES_SUCCESS, data });
    }
  } catch (err) {
    yield put({ type: IP_TITLES_ERROR });
  }
}

function* CreateIpWatcher() {
  yield takeLatest(STATE_CREATE_IP_REQUEST, requestCreateIp);
  // yield takeLatest(GET_SECTORS_REQUEST, requestGetSectors);
  yield takeLatest(UPDATE_IP_REQUEST, requestUpdateDeal);
  yield takeLatest(UPDATE_IP_STEP_REQUEST, requestUpdateDealOnStep);
  yield takeLatest(EDIT_IP_DETAILS_REQUEST, requestEditIPDetails);
  yield takeLatest(IP_TITLES_REQUEST, requestIPTitles);
}
export default CreateIpWatcher;
