import { call, put, takeLatest } from "redux-saga/effects";

import { getAdminIPs, adminIPUpdateStatus } from "../../../api";
import {
  GET_IPS_LIST_REQUEST,
  GET_IPS_LIST_REQUEST_SUCCESS,
  GET_IPS_LIST_REQUEST_ERROR,
  ADMIN_IP_UPDATE_REQUEST,
  ADMIN_IP_UPDATE_SUCCESS,
  ADMIN_IP_UPDATE_ERROR,
} from "./constants";

function* getAdminIPsListRequest(action) {
  try {
    let { data } = yield call(getAdminIPs, action?.data);

    if (data) {
      yield put({ type: GET_IPS_LIST_REQUEST_SUCCESS, data });
    }
  } catch (err) {
    yield put({ type: GET_IPS_LIST_REQUEST_ERROR });
  }
}

function* adminIPUpdateRequest(action) {
  try {
    let { data } = yield call(adminIPUpdateStatus, action?.data);

    if (data.Msg) {
      yield put({ type: ADMIN_IP_UPDATE_SUCCESS });
      yield put({ type: GET_IPS_LIST_REQUEST });
    }
  } catch (err) {
    yield put({ type: ADMIN_IP_UPDATE_ERROR });
  }
}

function* AdminIpsListWatcher() {
  yield takeLatest(GET_IPS_LIST_REQUEST, getAdminIPsListRequest);
  yield takeLatest(ADMIN_IP_UPDATE_REQUEST, adminIPUpdateRequest);
}
export default AdminIpsListWatcher;
