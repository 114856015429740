export const UPDATE_MODAL_STATE = "UPDATE_MODAL_STATE";
export const SET_EDIT_USER_ID = "SET_EDIT_USER_ID";

export const GET_USERS_LIST_REQUEST = "GET_USERS_LIST_REQUEST";
export const GET_USERS_LIST_SUCCESS = "GET_USERS_LIST_SUCCESS";
export const GET_USERS_LIST_ERROR = "GET_USER_DOCUMENTS_ERROR";

export const GET_USER_ROLES_REQUEST = "GET_USER_ROLES_REQUEST ";
export const GET_USER_ROLES_SUCCESS = "GET_USER_ROLES_SUCCESS ";
export const GET_USER_ROLES_ERROR = "GET_USER_ROLES_ERROR";

export const UPDATE_USER_ROLES_REQUEST = "UPDATE_USER_ROLES_REQUEST ";
export const UPDATE_USER_ROLES_SUCCESS = "UPDATE_USER_ROLES_SUCCESS ";
export const UPDATE_USER_ROLES_ERROR = "UPDATE_USER_ROLES_ERROR";

export const UPDATE_USER_ACCOUNT_STATUS_REQUEST =
  "UPDATE_USER_ACCOUNT_STATUS_REQUEST ";
export const UPDATE_USER_ACCOUNT_STATUS_SUCCESS =
  "UPDATE_USER_ACCOUNT_STATUS_SUCCESS ";
export const UPDATE_USER_ACCOUNT_STATUS_ERROR =
  "UPDATE_USER_ACCOUNT_STATUS_ERROR";

export const GET_USER_DETAIL_BY_ID_REQUEST = "GET_USER_DETAIL_BY_ID_REQUEST";
export const GET_USER_DETAIL_BY_ID_SUCCESS = "GET_USER_DETAIL_BY_ID_SUCCESS";
export const GET_USER_DETAIL_BY_ID_ERROR = "GET_USER_DETAIL_BY_ID_ERROR";
export const GET_USERS_LIST_SUCCESS_EXT = "GET_USERS_LIST_SUCCESS_EXT";
export const USER_DETAIL_BY_ID_SUCCESS_PROFILE = "USER_DETAIL_BY_ID_SUCCESS_PROFILE";

export const SET_IS_SKIPPED = "SET_IS_SKIPPED";