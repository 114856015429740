import React, { useState, useEffect } from "react";
import { Form, Input, Button, Row, Col, message } from "antd";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import WebsiteLogo from "../../components/RegistrationLeftBar/WebsiteLogo";
import RegistrationLeftBar from "../../components/RegistrationLeftBar";
import { changePasswordRequest, ExactPage } from "../../redux/auth/action";
import StrengthMeter from "./StrengthMeter";
import { getPasswordStrength } from "../../Utils";
import { changePassword } from "../../api";

const CreatePassword = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const authDetails = useSelector((state) => state.auth);
  const { current } = authDetails;
  const { changePasswordSuccess } = authDetails;
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [passwordStrength, setPasswordStrength] = useState();

  useEffect(() => {
    if (changePasswordSuccess) {
      dispatch(ExactPage(3));
      navigate("/");
    }
  }, [changePasswordSuccess, navigate, dispatch]);

  const onFinish = (fieldsValue) => {
    const values = {
      ...fieldsValue,
      token: params.email_token
    };
    changePassword(values)
      .then((res) => {
        message.success(res.data.message);
        dispatch(ExactPage(0));
        navigate("/registration");
      })
      .catch((err) => {
        message.error(
          err.response?.data?.message ||
            "Something went wrong, Please try again !!"
        );
      });
  };

  const handleChange = (e) => {
    setPasswordStrength(getPasswordStrength(e.target.value));
  };

  return (
    <>
      <Row className="align-content mobile-media">
        <Col
          md={12}
          xs={24}
          xl={12}
          ml={12}
          sm={24}
          className="mobile_responsive align-left leftbar-background"
        >
          <WebsiteLogo />
          <RegistrationLeftBar current={current} />
        </Col>
        <Col md={12} xs={24} xl={12} ml={12} sm={24} justify="space-around">
          <div className="auth-page">
            <div>
              <Col span={16} offset={4} className="rm-offset">
                <h1 className="create-account">Create new password</h1>
                <div className="option-text">
                  <p>
                    "Type and confirm a secure new password for your account."
                  </p>
                </div>
                <Form form={form} onFinish={onFinish}>
                  <Form.Item
                    name="password"
                    hasFeedback
                    label={<span className="label-input">New Password</span>}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    onChange={handleChange}
                    rules={[
                      {
                        required: true,
                        message: "Please enter your new password.",
                      },
                    ]}
                  >
                    <Input.Password
                      className="input-style input-font-style"
                      placeholder="•••••••••"
                      size="large"
                      id="password"
                    />
                  </Form.Item>
                  <StrengthMeter strength={passwordStrength} />

                  <Form.Item
                    name="confirmPassword"
                    hasFeedback
                    className="mt-2"
                    label={
                      <span className="label-input">Repeat New Password</span>
                    }
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    dependencies={["password"]}
                    rules={[
                      {
                        required: true,
                        message: "Please confirm your password!",
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue("password") === value) {
                            return Promise.resolve();
                          }

                          return Promise.reject(
                            new Error(
                              "The two passwords that you entered do not match!"
                            )
                          );
                        },
                      }),
                    ]}
                  >
                    <Input.Password
                      className="input-style input-font-style"
                      placeholder="•••••••••"
                      size="large"
                      id="password2"
                    />
                  </Form.Item>
                  <Form.Item>
                    <Button
                      className="form-submit-btn"
                      type="primary"
                      htmlType="submit"
                      shape="round"
                      size="large"
                    >
                      Reset password
                    </Button>
                  </Form.Item>
                </Form>
              </Col>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default CreatePassword;
