import React, { useEffect, useState } from "react";
import { Row, Col, message, Spin } from "antd";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";

import RegistrationLeftBar from "../components/RegistrationLeftBar";
import { ExactPage } from "../redux/auth/action";
import WebsiteLogo from "../components/RegistrationLeftBar/WebsiteLogo";
import Logo from "../assets/images/main-logo-svg.svg";
import { confirmEmail } from "../api";
import { LoadingOutlined } from "@ant-design/icons";

export const AuthContext = React.createContext();

function SelectionPage() {
  const [current] = React.useState(3);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    confirmEmail(params.email_token)
      .then((res) => {
        if (res?.data?.isVerified) {
          message.success(res?.data?.message);
        }

        dispatch(ExactPage(0));
        navigate("/registration");
      })
      .catch((err) => {
        if (err.response.status === 403) {
          message.error("User not found, Please register to proceed further");
        } else {
          message.error("Something went wrong, Please try again");
        }

        dispatch(ExactPage(0));
        navigate("/registration");
      });
  }, [dispatch, params]);

  return (
    <>
      <Row
        className="align-content mobile-media onboarding-wrapper"
        style={{ height: "100vh", overflow: "hidden" }}
      >
        <Col
          md={12}
          xs={24}
          xl={12}
          ml={12}
          sm={24}
          className="mobile_responsive align-left leftbar-background"
          style={{ height: "100vh" }}
        >
          <WebsiteLogo />
          <RegistrationLeftBar current={current} width="80%" />
        </Col>
        <Col
          md={12}
          xs={24}
          xl={12}
          ml={12}
          sm={24}
          justify="space-around"
          className="main-section main-onboarding-wrapper verify-social-login-wrapper"
        >
          <Link to="/registration">
            <div className="logo-image-mobile">
              <img src={Logo} alt="Logo" />
            </div>
          </Link>
          <Spin
            spinning
            className="spining-verify"
            indicator={<LoadingOutlined className="indicator" />}
          />
          <div className="verify-txt">Verifying Email....</div>
        </Col>
      </Row>
    </>
  );
}

export default SelectionPage;
