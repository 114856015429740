export const IPsIcon = () => (
  <svg
    width="36"
    height="38"
    viewBox="0 0 36 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.54435 9.45713L5.52887 5.46777L4.47168 6.53189L8.48215 10.5163C6.4726 12.7695 5.25028 15.7425 5.25028 18.9998C5.25028 22.2572 6.4726 25.2302 8.48215 27.4834L4.47168 31.4678L5.52887 32.5319L9.54435 28.5425C11.7942 30.5375 14.7563 31.7498 18.0003 31.7498C21.2443 31.7498 24.2064 30.5375 26.4562 28.5425L30.4717 32.5319L31.5289 31.4678L27.5184 27.4834C29.528 25.2302 30.7503 22.2572 30.7503 18.9998C30.7503 15.7425 29.528 12.7695 27.5184 10.5163L31.5289 6.53189L30.4717 5.46777L26.4562 9.45713C24.2064 7.46219 21.2443 6.24983 18.0003 6.24983C14.7563 6.24983 11.7942 7.46219 9.54435 9.45713ZM18.0003 7.74983C14.9005 7.74983 12.0947 9.00253 10.0592 11.0311C8.01435 13.0688 6.75028 15.886 6.75028 18.9998C6.75028 22.1137 8.01435 24.9308 10.0592 26.9686C12.0947 28.9971 14.9005 30.2498 18.0003 30.2498C21.1001 30.2498 23.9058 28.9971 25.9414 26.9686C27.9862 24.9308 29.2503 22.1137 29.2503 18.9998C29.2503 15.886 27.9862 13.0688 25.9414 11.0311C23.9058 9.00253 21.1001 7.74983 18.0003 7.74983Z"
      fill="#6B7280"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18 12.75C15.6528 12.75 13.75 14.6528 13.75 17C13.75 18.5131 14.5401 19.8421 15.7339 20.5962C15.9514 20.7336 16.0833 20.973 16.0833 21.2303V21.9643H19.9167V21.2303C19.9167 20.973 20.0486 20.7336 20.2661 20.5962C21.4599 19.8421 22.25 18.5131 22.25 17C22.25 14.6528 20.3472 12.75 18 12.75ZM19.86 23.4643H16.14C16.3475 24.2985 17.1016 24.9167 18 24.9167C18.8984 24.9167 19.6525 24.2985 19.86 23.4643ZM12.25 17C12.25 13.8244 14.8244 11.25 18 11.25C21.1756 11.25 23.75 13.8244 23.75 17C23.75 18.8969 22.831 20.5788 21.4167 21.6252V23C21.4167 24.6293 20.2762 25.9923 18.75 26.3341V27C18.75 27.4142 18.4142 27.75 18 27.75C17.5858 27.75 17.25 27.4142 17.25 27V26.3341C15.7238 25.9923 14.5833 24.6293 14.5833 23V21.6252C13.169 20.5788 12.25 18.8969 12.25 17ZM15.4238 16.5199C15.689 16.2017 16.1619 16.1587 16.4801 16.4238L18 17.6904L19.5199 16.4238C19.8381 16.1587 20.311 16.2017 20.5762 16.5199C20.8413 16.8381 20.7983 17.311 20.4801 17.5762L18.75 19.0179V20.6667C18.75 21.0809 18.4142 21.4167 18 21.4167C17.5858 21.4167 17.25 21.0809 17.25 20.6667V19.0179L15.5199 17.5762C15.2017 17.311 15.1587 16.8381 15.4238 16.5199Z"
      fill="#6B7280"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.5 1.75C2.5335 1.75 1.75 2.5335 1.75 3.5C1.75 4.4665 2.5335 5.25 3.5 5.25C4.4665 5.25 5.25 4.4665 5.25 3.5C5.25 2.5335 4.4665 1.75 3.5 1.75ZM0.25 3.5C0.25 1.70507 1.70507 0.25 3.5 0.25C5.29493 0.25 6.75 1.70507 6.75 3.5C6.75 5.29493 5.29493 6.75 3.5 6.75C1.70507 6.75 0.25 5.29493 0.25 3.5Z"
      fill="#6B7280"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M32.5 1.75C31.5335 1.75 30.75 2.5335 30.75 3.5C30.75 4.4665 31.5335 5.25 32.5 5.25C33.4665 5.25 34.25 4.4665 34.25 3.5C34.25 2.5335 33.4665 1.75 32.5 1.75ZM29.25 3.5C29.25 1.70507 30.7051 0.25 32.5 0.25C34.2949 0.25 35.75 1.70507 35.75 3.5C35.75 5.29493 34.2949 6.75 32.5 6.75C30.7051 6.75 29.25 5.29493 29.25 3.5Z"
      fill="#6B7280"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.5 32.75C2.5335 32.75 1.75 33.5335 1.75 34.5C1.75 35.4665 2.5335 36.25 3.5 36.25C4.4665 36.25 5.25 35.4665 5.25 34.5C5.25 33.5335 4.4665 32.75 3.5 32.75ZM0.25 34.5C0.25 32.7051 1.70507 31.25 3.5 31.25C5.29493 31.25 6.75 32.7051 6.75 34.5C6.75 36.2949 5.29493 37.75 3.5 37.75C1.70507 37.75 0.25 36.2949 0.25 34.5Z"
      fill="#6B7280"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M32.5 32.75C31.5335 32.75 30.75 33.5335 30.75 34.5C30.75 35.4665 31.5335 36.25 32.5 36.25C33.4665 36.25 34.25 35.4665 34.25 34.5C34.25 33.5335 33.4665 32.75 32.5 32.75ZM29.25 34.5C29.25 32.7051 30.7051 31.25 32.5 31.25C34.2949 31.25 35.75 32.7051 35.75 34.5C35.75 36.2949 34.2949 37.75 32.5 37.75C30.7051 37.75 29.25 36.2949 29.25 34.5Z"
      fill="#6B7280"
    />
  </svg>
);
