import React from "react";
import { Avatar, Card, Col, List, Row, Typography } from "antd";
import { LinkedinFilled, FacebookFilled } from "@ant-design/icons";
import { getFirstLater, getImageURL, getURL } from "../../../../Utils";

const { Text, Title } = Typography;

function TeamTab({ data }) {
  return (
    <Row className="team-tab">
      <Col span={24} style={{ marginBottom: "20px" }}>
        <List
          grid={{ gutter: 24, xs: 1, sm: 1, md: 2, lg: 3, xl: 4, xxl: 4 }}
          dataSource={data}
          bordered={false}
          renderItem={(item) => (
            <List.Item>
              <Card>
                <Row className="team-card">
                  <Col md={20} sm={20}>
                    <Avatar.Group>
                      <Avatar
                        size={40}
                        shape="circle"
                        src={getImageURL(item.team_member_photo || "")}
                        className={`${
                          !item.team_member_photo ? "shape-avatar-letter" : ""
                        } team-card__avatar`}
                      >
                        {getFirstLater(item?.first_name || "")}
                      </Avatar>
                      <div>
                        <Title
                          level={5}
                          className="team-card__name"
                        >{`${item.first_name} ${item.last_name}`}</Title>

                        <Text className="team-card__position">
                          {item.position}
                        </Text>
                      </div>
                    </Avatar.Group>
                  </Col>
                  <Col md={4} sm={4} className="team-card__links">
                    {item?.facebook_link ? (
                      <a
                        href={getURL(item?.facebook_link)}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FacebookFilled
                          style={{ color: "#0B5394", fontSize: "20px" }}
                          size={40}
                        />
                      </a>
                    ) : null}

                    {item?.linkedin_link ? (
                      <a
                        href={getURL(item?.linkedin_link)}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <LinkedinFilled
                          style={{ color: "#0B5394", fontSize: "20px" }}
                        />
                      </a>
                    ) : null}
                  </Col>
                </Row>
              </Card>
            </List.Item>
          )}
        />
      </Col>
    </Row>
  );
}

export default TeamTab;
