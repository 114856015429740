export const CREATE_DEAL_REQUEST = "DEAL_LISTCREATE_DEAL_REQUEST";
export const CREATE_DEAL_SUCCESS = "CREATE_DEAL_SUCCESS";
export const CREATE_DEAL_ERROR = "CREATE_DEAL_ERROR";

export const UPDATE_DEAL_REQUEST = "UPDATE_DEAL_REQUEST";
export const UPDATE_DEAL_SUCCESS = "UPDATE_DEAL_SUCCESS";
export const UPDATE_DEAL_ERROR = "UPDATE_DEAL_ERROR";

export const UPDATE_DEAL_ON_REQUEST = "UPDATE_DEAL_ON_REQUEST";
export const UPDATE_DEAL_ON_SUCCESS = "UPDATE_DEAL_ON_SUCCESS";
export const UPDATE_DEAL_ON_ERROR = "UPDATE_DEAL_ON_ERROR";

export const DOWLOAD_SPV_DOCS_REQUEST = "DOWLOAD_SPV_DOCS_REQUEST";
export const DOWLOAD_SPV_DOCS_SUCCESS = "DOWLOAD_SPV_DOCS_SUCCESS";
export const DOWLOAD_SPV_DOCS_ERROR = "DOWLOAD_SPV_DOCS_ERROR";

export const UPLOAD_SPV_DOCS_REQUEST = "UPLOAD_SPV_DOCS_REQUEST";
export const UPLOAD_SPV_DOCS_SUCCESS = "UPLOAD_SPV_DOCS_SUCCESS";
export const UPLOAD_SPV_DOCS_ERROR = "UPLOAD_SPV_DOCS_ERROR";

export const DELETE_SPV_DOCS_REQUEST = "DELETE_SPV_DOC_REQUEST";
export const DELETE_SPV_DOCS_SUCCESS = "DELETE_SPV_DOCS_SUCCESS";
export const DELETE_SPV_DOCS_ERROR = "DELETE_SPV_DOCS_ERROR";

export const SIGN_DOCS_SUCCESS = "SIGN_DOCS_SUCCESS";
export const SIGN_DOCS_ERROR = "SIGN_DOCS_ERROR";

export const CLEAR_SIGN_DETAILS = "CLEAR_SIGN_DETAILS";

export const SIGN_DOCS_OPEN_REQUEST = "SIGN_DOCS_OPEN_REQUEST";
export const SIGN_DOCS_OPEN_SUCCESS = "SIGN_DOCS_OPEN_SUCCESS";
export const SIGN_DOCS_OPEN_ERROR = "SIGN_DOCS_OPEN_ERROR";

export const GET_SECTORS_REQUEST = "GET_SECTORS_REQUEST";
export const GET_SECTORS_SUCCESS = "GET_SECTORS_SUCCESS";
export const GET_SECTORS_ERROR = "GET_SECTORS_ERROR";

export const EDIT_DEAL_DETAILS_REQUEST = "EDIT_DEAL_DETAILS_REQUEST";
export const EDIT_DEAL_DETAILS_SUCCESS = "EDIT_DEAL_DETAILS_SUCCESS";
export const EDIT_DEAL_DETAILS_ERROR = "EDIT_DEAL_DETAILS_ERROR";

export const CLEAR_SUCCESS = "CLEAR_SUCCESS";
export const CLEAR_ERROR = "CLEAR_ERROR";

export const CLEAR_CREATE_DEAL_DETAILS = "CLEAR_CREATE_DEAL_DETAILS";

export const SHOW_POPUP = "SHOW_POPUP";

export const DEAL_TITLES_REQUEST = "DEAL_TITLES_REQUEST";
export const DEAL_TITLES_SUCCESS = "DEAL_TITLES_SUCCESS";
export const DEAL_TITLES_ERROR = "DEAL_TITLES_ERROR";

export const CLEAR_DEAL_TITLES = "CLEAR_DEAL_TITLES";
