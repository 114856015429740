import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./style.scss";
import {
  adminInvestColumns,
  adminInvestDeviceColumns,
} from "../../components/Common/DealsTable/AdminInvestTableColumns";
import CommonTable from "../common/commonTable";
import { getAdminInvestmentRequest } from "./redux/action";

const AdminInvestment = () => {
  const adminInvestmentList = useSelector((state) => state.adminInvestmentList);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAdminInvestmentRequest());
  }, [dispatch]);

  const adminInvestmentKeys = [
    "All",
    "In Review",
    "Compliance Check",
    "Active",
    "In Progress",
    "Completed",
    "Rejected",
  ];

  return (
    <div className="admin-deals">
      <CommonTable
        columns={adminInvestColumns(dispatch)}
        deviceColumns={adminInvestDeviceColumns(dispatch)}
        allData={adminInvestmentList}
        keys={adminInvestmentKeys}
      />
    </div>
  );
};

export default AdminInvestment;
