import React from "react";
import { Row, Col, Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";

function FacilitatorHeaderSearch({ searchFilter }) {

  return (
    <div className="marketplace_tab_header">
      <Row gutter={[8, 0]}>
        <Col md={6}>
          <div className="mkt_header_search">
            <Input
              className="search_btn"
              onChange={searchFilter}
              placeholder="Search "
              prefix={<SearchOutlined />}
            />
          </div>
        </Col>
        <Col span={12} md={18} className="header-control"></Col>
      </Row>
    </div>
  );
}

export default FacilitatorHeaderSearch;
