export const GET_INDIVIDUAL_PROFILE_REQUEST = "GET_INDIVIDUAL_PROFILE_REQUEST";
export const GET_INDIVIDUAL_PROFILE_REQUEST_SUCCESS =
  "GET_INDIVIDUAL_PROFILE_REQUEST_SUCCESS";
export const GET_INDIVIDUAL_PROFILE_REQUEST_ERROR =
  "GET_INDIVIDUAL_PROFILE_REQUEST_ERROR";

export const UPDATE_PROFILE_REQUEST = "UPDATE_PROFILE_REQUEST";
export const UPDATE_PROFILE_REQUEST_SUCCESS = "UPDATE_PROFILE_REQUEST_SUCCESS";
export const UPDATE_PROFILE_REQUEST_ERROR = "UPDATE_PROFILE_REQUEST_ERROR";

export const GET_COMPANY_PROFILE_REQUEST = "GET_COMPANY_PROFILE_REQUEST";
export const GET_COMPANY_PROFILE_REQUEST_SUCCESS =
  "GET_COMPANY_PROFILE_REQUEST_SUCCESS";
export const GET_COMPANY_PROFILE_REQUEST_ERROR =
  "GET_COMPANY_PROFILE_REQUEST_ERROR";

export const UPDATE_COMPANY_PROFILE_REQUEST = "UPDATE_COMPANY_PROFILE_REQUEST";
export const UPDATE_COMPANY_PROFILE_REQUEST_SUCCESS =
  "UPDATE_COMPANY_PROFILE_REQUEST_SUCCESS";
export const UPDATE_COMPANY_PROFILE_REQUEST_ERROR =
  "UPDATE_COMPANY_PROFILE_REQUEST_ERROR";

export const GET_CURRENT_PASSWORD_REQUEST = "GET_CURRENT_PASSWORD_REQUEST";
export const GET_CURRENT_PASSWORD_REQUEST_SUCCESS =
  "GET_CURRENT_PASSWORD_REQUEST_SUCCESS";
export const GET_CURRENT_PASSWORD_REQUEST_ERROR =
  "GET_CURRENT_PASSWORD_REQUEST_ERROR";

export const SETTING_KYC_REQUEST = "SETTING_KYC_REQUEST";
export const SETTING_KYC_SUCCESS = "SETTING_KYC_SUCCESS";
export const SETTING_KYC_ERROR = "SET_KYC_ERROR";

export const SETTING_KYB_REQUEST = "SETTING_KYB_REQUEST";
export const SETTING_KYB_SUCCESS = "SETTING_KYB_SUCCESS";
export const SETTING_KYB_ERROR = "SETTING_KYB_ERROR";

export const GET_SETTING_KYB_REQUEST = "GET_SETTING_KYB_REQUEST";
export const GET_SETTING_KYB_SUCCESS = "GET_SETTING_KYB_SUCCESS";
export const GET_SETTING_KYB_ERROR = "GET_SETTING_KYB_ERROR";

export const GET_SETTING_KYC_REQUEST = "GET_SETTING_KYC_REQUEST";
export const GET_SETTING_KYC_SUCCESS = "GET_SETTING_KYC_SUCCESS";
export const GET_SETTING_KYC_ERROR = "GET_SETTING_KYC_ERROR";

export const SETTING_QUESTIONARE_REQUEST = "SETTING_QUESTIONARE_REQUEST";
export const SETTING_QUESTIONARE_SUCCESS = "SETTING_QUESTIONARE_SUCCESS";
export const SETTING_QUESTIONARE_ERROR = "SETTING_QUESTIONARE_ERROR";

export const GET_SETTING_QUESTIONARE_REQUEST =
  "GET_SETTING_QUESTIONARE_REQUEST";
export const GET_SETTING_QUESTIONARE_SUCCESS =
  "GET_SETTING_QUESTIONARE_SUCCESS";
export const GET_SETTING_QUESTIONARE_ERROR = "GET_SETTING_QUESTIONARE_ERROR";

export const SETTING_SOURCE_OF_FUNDS_REQUEST =
  "SETTING_SOURCE_OF_FUNDS_REQUEST";
export const SETTING_SOURCE_OF_FUNDS_SUCCESS =
  "SETTING_SOURCE_OF_FUNDS_SUCCESS";
export const SETTING_SOURCE_OF_FUNDS_ERROR = "SETTING_SOURCE_OF_FUNDS_ERROR";

export const DELETE_SOURCE_OF_FUNDS_REQUEST = "DELETE_SOURCE_OF_FUNDS_REQUEST";
export const DELETE_SOURCE_OF_FUNDS_SUCCESS = "DELETE_SOURCE_OF_FUNDS_SUCCESS";
export const DELETE_SOURCE_OF_FUNDS_ERROR = "DELETE_SOURCE_OF_FUNDS_ERROR";

export const GET_SOURCE_OF_FUNDS_REQUEST = "GET_SOURCE_OF_FUNDS_REQUEST";
export const GET_SOURCE_OF_FUNDS_SUCCESS = "GET_SOURCE_OF_FUNDS_SUCCESS";
export const GET_SOURCE_OF_FUNDS_ERROR = "GET_SOURCE_OF_FUNDS_ERROR";

export const GET_FAC_SETTING_SERVICES_REQUEST =
  "GET_FAC_SETTING_SERVICES_REQUEST";
export const GET_FAC_SETTING_SERVICES_SUCCESS =
  "GET_FAC_SETTING_SERVICES_SUCCESS";
export const GET_FAC_SETTING_SERVICES_ERROR = "GET_FAC_SETTING_SERVICES_ERROR";

export const SET_FAC_SETTING_SERVICES_REQUEST =
  "SET_FAC_SETTING_SERVICES_REQUEST";
export const SET_FAC_SETTING_SERVICES_SUCCESS =
  "SET_FAC_SETTING_SERVICES_SUCCESS";
export const SET_FAC_SETTING_SERVICES_ERROR = "SET_FAC_SETTING_SERVICES__ERROR";

export const GET_FAC_SERVICES_REQUEST = "GET_FAC_SERVICES_REQUEST";
export const GET_FAC_SERVICES_SUCCESS = "GET_FAC_SERVICES_SUCCESS";
export const GET_FAC_SERVICES_ERROR = "GET_FAC_SERVICES_ERROR";

export const CLEAR_SETTING_MESSAGE = "CLEAR_SETTING_MESSAGE";
export const CLEAR_ERROR_MESSAGE = "CLEAR_ERROR_MESSAGE";

export const CLEAR_UPDATE_SUCCESS_MESSAGE = "CLEAR_UPDATE_SUCCESS_MESSAGE";
export const PROJECT_TITLE = "PROJECT_TITLE";