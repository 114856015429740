export const GET_FACILITATORS_LIST_REQUEST = "GET_FACILITATORS_LIST_REQUEST";
export const GET_FACILITATORS_LIST_SUCCESS = "GET_FACILITATORS_LIST_SUCCESS";
export const GET_FACILITATORS_LIST_ERROR = "GET_FACILITATORS_LIST_ERROR";

export const GET_FACILITATORS_SERVICES_REQUEST =
  "GET_FACILITATORS_SERVICES_REQUEST";
export const GET_FACILITATORS_SERVICES_SUCCESS =
  "GET_FACILITATORS_SERVICES_SUCCESS";
export const GET_FACILITATORS_SERVICES_ERROR =
  "GET_FACILITATORS_SERVICES_ERROR";

export const SET_FACILITATORS_SERVICES_REQUEST =
  "SET_FACILITATORS_SERVICES_REQUEST";
export const SET_FACILITATORS_SERVICES_SUCCESS =
  "SET_FACILITATORS_SERVICES_SUCCESS";
export const SET_FACILITATORS_SERVICES_ERROR =
  "SET_FACILITATORS_SERVICES_ERROR";

export const UPDATE_FACILITATOR_STATUS_REQUEST =
  "UPDATE_FACILITATOR_STATUS_REQUEST";
export const UPDATE_FACILITATOR_STATUS_SUCCESS =
  "UPDATE_FACILITATOR_STATUS_SUCCESS";
export const UPDATE_FACILITATOR_STATUS_ERROR =
  "UPDATE_FACILITATOR_STATUS_ERROR";
