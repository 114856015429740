import {
  DEAL_LIST_REQUEST,
  DEAL_LIST_SUCCESS,
  DEAL_LIST_ERROR,
  ORGANIZER_DEAL_LIST_REQUEST,
  ORGANIZER_DEAL_LIST_SUCCESS,
  ORGANIZER_DEAL_LIST_ERROR,
  DELETE_DEAL_REQUEST,
  DELETE_DEAL_SUCCESS,
  DELETE_DEAL_ERROR,
} from "./constants";

const initialState = {
  loading: false,
  data: [],
  organizerData: [],
};

export default function dealList(state = initialState, action) {
  switch (action.type) {
    case DEAL_LIST_REQUEST:
      return { ...state, loading: true };
    case DEAL_LIST_SUCCESS:
      return {
        ...state,
        data: action.data,
        loading: false,
      };
    case DEAL_LIST_ERROR:
      return { ...state, loading: false };

    case ORGANIZER_DEAL_LIST_REQUEST:
      return { ...state, loading: true };
    case ORGANIZER_DEAL_LIST_SUCCESS:
      return {
        ...state,
        organizerData: action.data,
        loading: false,
      };
    case ORGANIZER_DEAL_LIST_ERROR:
      return { ...state, loading: false };

    case DELETE_DEAL_REQUEST:
      return { ...state, loading: true };
    case DELETE_DEAL_SUCCESS:
      return {
        ...state,
        organizerData: action.data,
        loading: false,
      };
    case DELETE_DEAL_ERROR:
      return { ...state, loading: false };

    default:
      return state;
  }
}
