import {
  Avatar,
  Button,
  Card,
  Col,
  Empty,
  Popover,
  Row,
  Spin,
  Tag,
  Image,
} from "antd";
import Title from "antd/lib/typography/Title";
import Text from "antd/lib/typography/Title";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LoadingOutlined, MailOutlined } from "@ant-design/icons";
import FallbackImg from "../../assets/images/default-fallback-image.png";
import { useNavigate, useSearchParams } from "react-router-dom";
import "./style.scss";
import { serviceMarketplaceListRequest } from "./redux/action";
import MarketPlaceTabHeader from "../../components/Common/MarketPlaceTabHeader/MarketPlaceTabHeader";
import { BASE_URL } from "../../api";
import { useMemo } from "react";
import _ from "lodash";
import { userDetailsAction } from "../../redux/userDetail/action";
import { createChatAction } from "../chatPage/redux/action";
import { getFirstLater, getImageURL } from "../../Utils";

const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;

const OrganizeCard = ({ record }) => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const imageUrl = BASE_URL + record?.logo;
  const bannerUrl = BASE_URL + record?.banner;
  const handleOnCardClick = () => {
    const { user_id } = record;
    dispatch(userDetailsAction({ id: user_id, deal_id: "" }));
  };

  const handleChatClick = (e) => {
    e.stopPropagation();
    const formData = new FormData();
    formData.append("user_ids", JSON.stringify([record?.user_id]));

    dispatch(createChatAction(formData));

    history("/chat");
  };

  return (
    <Card
      className="service_marketplace"
      onClick={handleOnCardClick}
      style={{ cursor: "pointer" }}
    >
      <Card.Grid className="card-grid" hoverable={false}>
        <div className="card-grid__item img-card-grid__item">
          {/* <Image

            src={getImageURL(bannerUrl || "")}
            cover={
              record?.bannerUrl ? (
                <img src={bannerUrl} alt="avatar" />
              ) : (
                    ""
              )
            }
            className={`${!bannerUrl ? "shape-avatar-letter" : ""} shape-avatar`}
          > */}
          <Image
            className="cover-img"
            alt="example"
            src={getImageURL(bannerUrl || FallbackImg)}
            fallback={FallbackImg}
            preview={false}
          />

          {/* {getFirstLater(record.name || "")} */}

          {/* <Text level={8}>{record.name}</Text> */}
        </div>
        <div className="card-grid__item img-card-grid__item">
          <Avatar
            shape="round"
            size={50}
            src={getImageURL(imageUrl || "")}
            // icon={
            //   record?.logo ? (
            //     <img src={imageUrl} alt="avatar" />
            //   ) : (
            //     <UserOutlined />
            //   )
            // }
            className={`${!imageUrl ? "shape-avatar-letter" : ""} shape-avatar`}
          >
            {getFirstLater(record.name || "")}
          </Avatar>
        </div>
      </Card.Grid>
      <Card.Grid className="card-grid" hoverable={false}>
        <div className="card-grid__item">
          <Text level={4}>{record.name}</Text>
          {/* <Text level={5}>{record?.description}</Text> */}
        </div>
      </Card.Grid>
      <Card.Grid className="card-grid" hoverable={false}>
        <div className="card-grid__item service-marketplace-description">
          <Text level={5}>{record?.description}</Text>
        </div>
      </Card.Grid>
      <Card.Grid className="card-grid" hoverable={false}>
        <div className="card-grid__item">
          <div className="email-facilitator">
            <MailOutlined />
            <Text
              level={5}
              style={{ cursor: "pointer" }}
              onClick={() => {
                window.open(
                  `mailto:${record.email}?subject=SendMail&body=Description`
                );
              }}
            >
              {record.email}
            </Text>
          </div>
          <div className="view-service-wrapper">
            {!!record?.services?.length && (
              <Popover
                placement="bottom"
                className="popover-service"
                content={record.services.map((x) => (
                  <Tag color="blue" className="service-tag">
                    {x}
                  </Tag>
                ))}
                overlayStyle={{ width: "200px" }}
              >
                View Services
              </Popover>
            )}
          </div>
        </div>
      </Card.Grid>
      <Card.Grid className="card-grid message" hoverable={false}>
        <Button type="primary" onClick={(e) => handleChatClick(e)}>
          Message
        </Button>
      </Card.Grid>
    </Card>
  );
};

function ServiceMarketPlace() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const searchId = searchParams.get("id");
  const searchValue = searchParams.get("value");
  const { data, loading } = useSelector(
    (state) => state.serviceMarketplaceList
  );
  const [search, setSearch] = useState("");
  const [role, setRole] = useState("facilitator");
  const [service, setService] = useState("");

  useEffect(() => {
    if (searchValue) {
      setSearch(searchValue);
    }
  }, [searchValue]);

  useEffect(() => {
    if (service || role) {
      const queryArr = [];
      if (role === "svc_org") {
        queryArr.push(`user_type=${role}`);
      } else {
        if (service && service !== "other") {
          queryArr.push(`service_id=${service}`);
        } else {
          queryArr.push(`user_type=${role}`);
        }
      }

      dispatch(serviceMarketplaceListRequest(queryArr.join("&")));
    }
  }, [dispatch, service, role]);

  useEffect(() => {
    if (role !== "svc_org" && service === "other") {
      const queryArr = [];

      if (role !== "svc_org" && search) {
        if (service === "other") {
          queryArr.push(`other=${search}`);
        } else if (service) {
          queryArr.push(`service_id=${service}`);
        }
      }

      dispatch(serviceMarketplaceListRequest(queryArr.join("&")));
    }
  }, [dispatch, search]);

  const marketplace = useMemo(() => {
    return data.filter((item) => {
      return (
        !search ||
        service === "other" ||
        item?.name.toString().toLowerCase().includes(search?.toLowerCase())
      );
    });
  }, [data, search, service]);

  useEffect(() => {
    if (searchValue && searchId && marketplace) {
      dispatch(userDetailsAction({ id: searchId, deal_id: "" }));
    }
    return () => {
      if (window.location.pathname.includes("service-marketplace")) {
        navigate("/service-marketplace");
      }
    };
  }, [searchValue, searchId, marketplace, dispatch]);

  useEffect(() => {
    // Remove search params from the URL on refresh
    window.history.replaceState({}, document.title, window.location.pathname);
  }, []);

  const searchFilter = (e) => {
    setSearch(e.target.value);
  };

  const selectFilter = (value) => {
    setRole(value);
    setService(null);
  };

  const onServiceChange = (value) => setService(value);

  return (
    <>
      {loading && (
        <div className="spinner_background">
          <Spin className="spinner" indicator={antIcon} />
        </div>
      )}

      <MarketPlaceTabHeader
        setVisible={false}
        searchFilter={searchFilter}
        selectFilter={selectFilter}
        search={search}
        isServiceMarketplace={true}
        isGrantMarketPlace={false}
        onServiceChange={onServiceChange}
      />
      {marketplace.length ? (
        <Row gutter={[16, 16]}>
          {marketplace.length ? (
            marketplace.map((el) => {
              return (
                <Col md={8} sm={24} key={el.id}>
                  <OrganizeCard record={el} />
                </Col>
              );
            })
          ) : (
            <Empty />
          )}
        </Row>
      ) : (
        <Row gutter={[16, 16]} className="service-marketplace-wrapper">
          <Empty />
        </Row>
      )}
    </>
  );
}

export default ServiceMarketPlace;
