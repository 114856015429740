export const GET_ADMIN_IP_INVESTMENT_REQUEST =
  "GET_ADMIN_IP_INVESTMENT_REQUEST";
export const GET_ADMIN_IP_INVESTMENT_SUCCESS =
  "GET_ADMIN_IP_INVESTMENT_SUCCESS";
export const GET_ADMIN_IP_INVESTMENT_ERROR = "GET_ADMIN_IP_INVESTMENT_ERROR";

export const UPDATE_IP_INVESTMENT_STATUS_REQUEST =
  "UPDATE_IP_INVESTMENT_STATUS_REQUEST";
export const UPDATE_IP_INVESTMENT_STATUS_SUCCESS =
  "UPDATE_IP_INVESTMENT_STATUS_SUCCESS";
export const UPDATE_IP_INVESTMENT_STATUS_ERROR =
  "UPDATE_IP_INVESTMENT_STATUS_ERROR";
