import { call, put, takeLatest } from "redux-saga/effects";

import {
  adminAddGrantApi,
  adminDeleteGrantApi,
  adminEditGrantApi,
  getAllGrantApi,
} from "../../../api";

import {
  ADD_GRANT_ERROR,
  ADD_GRANT_REQUEST,
  ADD_GRANT_SUCCESS,
  DELETE_GRANT_ERROR,
  DELETE_GRANT_REQUEST,
  DELETE_GRANT_SUCCESS,
  EDIT_GRANT_ERROR,
  EDIT_GRANT_REQUEST,
  EDIT_GRANT_SUCCESS,
  GRANT_MARKETPLACE_LIST_ERROR,
  GRANT_MARKETPLACE_LIST_REQUEST,
  GRANT_MARKETPLACE_LIST_SUCCESS,
} from "./constants";

function* requestGrantMarketplaceList() {
  try {
    let { data } = yield call(getAllGrantApi);
    if (data) {
      yield put({ type: GRANT_MARKETPLACE_LIST_SUCCESS, data });
    }
  } catch (err) {
    yield put({ type: GRANT_MARKETPLACE_LIST_ERROR });
  }
}

function* addGrantMarketplaceList(action) {
  try {
    let { data } = yield call(adminAddGrantApi, action?.data);

    if (data) {
      yield put({ type: ADD_GRANT_SUCCESS, msg: data.msg });
      yield put({ type: GRANT_MARKETPLACE_LIST_REQUEST });
    }
  } catch (err) {
    yield put({ type: ADD_GRANT_ERROR });
  }
}

function* editGrantMarketplaceList(action) {
  try {
    let { data } = yield call(adminEditGrantApi, action?.data);

    if (data.msg) {
      yield put({ type: EDIT_GRANT_SUCCESS, msg: data.msg });
      yield put({ type: GRANT_MARKETPLACE_LIST_REQUEST });
    }
  } catch (err) {
    yield put({ type: EDIT_GRANT_ERROR });
  }
}

function* deleteGrantMarketplaceList(action) {
  try {
    let { data } = yield call(adminDeleteGrantApi, action?.data);

    if (data) {
      yield put({ type: DELETE_GRANT_SUCCESS, msg: data.msg });
      yield put({ type: GRANT_MARKETPLACE_LIST_REQUEST });
    }
  } catch (err) {
    yield put({ type: DELETE_GRANT_ERROR });
  }
}

function* GrantMarketplaceListWatcher() {
  yield takeLatest(GRANT_MARKETPLACE_LIST_REQUEST, requestGrantMarketplaceList);
  yield takeLatest(ADD_GRANT_REQUEST, addGrantMarketplaceList);
  yield takeLatest(EDIT_GRANT_REQUEST, editGrantMarketplaceList);
  yield takeLatest(DELETE_GRANT_REQUEST, deleteGrantMarketplaceList);
}

export default GrantMarketplaceListWatcher;
