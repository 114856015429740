import AuthSaga from "../redux/auth/sagas";
import { all } from "redux-saga/effects";
import DealsListWatcher from "../pages/deals/redux/sagas";
import CreateDealWatcher from "../pages/createDeal/redux/sagas";
import AdminDealsListWatcher from "../pages/adminDeals/redux/sagas";
import InvestWatcher from "../pages/investPage/redux/sagas";
import DealDetailsWatcher from "../pages/dealDetail/redux/sagas";
import MarketplaceListWatcher from "../pages/marketplacePage/redux/sagas";
import ServiceMarketplaceListWatcher from "../pages/serviceMarketPlace/redux/sagas";
import NotificationsWatcher from "../layout/dashboard/redux/sagas";
import AdminInvestmentWatcher from "../pages/adminInvestment/redux/sagas";
import SettingsWatcher from "../pages/settings/redux/sagas";
import {
  UserDetailsWatcher,
  SubscribeWatcher,
  SubscribestatusWatcher,
} from "./userDetail/sagas";
import ChatWatcher from "../pages/chatPage/redux/sagas";
import InvestorViewDataWatcher from "../pages/dashboard/redux/sagas";
import UsersListWatcher from "../pages/users/redux/sagas";
import AdminTopOrganizersWatcher from "../pages/adminTopOrganizers/redux/sagas";
import dealTypeInfoWatcher from "../pages/common/dealTypeSelectionModal/redux/sagas";
import MAMarketplaceListWatcher from "../pages/MAmarketplace/redux/sagas";
import ServiceOrganizersDealsWatcher from "../pages/serviceOrganizerDeals/redux/sagas";
import OnboardingWatcher from "../pages/onBoardingPage/redux/sagas";
import FacilitatorWatcher from "../pages/facilitator/redux/sagas";
import GrantMarketplaceListWatcher from "../pages/grantMarketPlace/redux/sagas";
import CreateIpWatcher from "../pages/createIp/redux/sagas";
import IPsListWatcher from "../pages/ips/redux/sagas";
import AdminIpsListWatcher from "../pages/adminIps/redux/sagas";
import IPMarketplaceListWatcher from "../pages/IntellectualPropertyMarketPlace/redux/sagas";
import IPDetailsWatcher from "../pages/ipDetail/redux/sagas";
import AdminIPInvestmentWatcher from "../pages/adminIPInvestment/redux/sagas";
import SumSubWatcher from "../components/SumSub/redux/sagas";
import LongevityWatcher from "../pages/LongevityMarketplace/redux/saga";
import UserFlowWatcher from "../pages/userJourney/redux/sagas";

export default function* RootSaga() {
  yield all([
    AuthSaga(),
    InvestWatcher(),
    DealsListWatcher(),
    CreateDealWatcher(),
    CreateIpWatcher(),
    DealDetailsWatcher(),
    AdminDealsListWatcher(),
    IPsListWatcher(),
    MarketplaceListWatcher(),
    ServiceMarketplaceListWatcher(),
    NotificationsWatcher(),
    AdminInvestmentWatcher(),
    AdminTopOrganizersWatcher(),
    SettingsWatcher(),
    UserDetailsWatcher(),
    ChatWatcher(),
    InvestorViewDataWatcher(),
    UsersListWatcher(),
    dealTypeInfoWatcher(),
    ServiceOrganizersDealsWatcher(),
    OnboardingWatcher(),
    FacilitatorWatcher(),
    MAMarketplaceListWatcher(),
    SubscribeWatcher(),
    SubscribestatusWatcher(),
    GrantMarketplaceListWatcher(),
    AdminIpsListWatcher(),
    IPMarketplaceListWatcher(),
    IPDetailsWatcher(),
    AdminIPInvestmentWatcher(),
    SumSubWatcher(),
    LongevityWatcher(),
    UserFlowWatcher(),
  ]);
}
