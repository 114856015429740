import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  adminColumns,
  adminDeviceColumns,
  investorColumns,
  investorDeviceColumns,
  organizeColumns,
  organizeDeviceColumns,
} from "../../components/Common/DealsTable/DealsTableColumns";
import { getUser } from "../../Utils/isAuth";
import AdminDeals from "../adminDeals/AdminDeals";
import ServiceOrganizerDeals from "../serviceOrganizerDeals/ServiceOrganizerDeals";

import InvestorDeals from "./InvestorDeals";
import OrganizerDeals from "./OrganizerDeals";

function Deals() {
  const userRole = getUser()?.roles;
  const user = getUser();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return user && user.is_superuser ? (
    <AdminDeals
      columns={adminColumns(dispatch, navigate)}
      deviceColumns={adminDeviceColumns(dispatch, navigate)}
    />
  ) : userRole === "Organisation" ? (
    userRole && (
      <OrganizerDeals
        columns={organizeColumns(dispatch, navigate)}
        deviceColumns={organizeDeviceColumns(dispatch, navigate)}
        userRole={userRole}
      />
    )
  ) : userRole === "Organisation" ? (
    userRole && (
      <ServiceOrganizerDeals
        columns={adminColumns(dispatch, navigate)}
        deviceColumns={adminDeviceColumns(dispatch, navigate)}
        userRole={userRole}
      />
    )
  ) : userRole ? (
    <InvestorDeals
      columns={investorColumns()}
      deviceColumns={investorDeviceColumns()}
      userRole={userRole}
    />
  ) : null;
}

export default Deals;
