import React from "react";
import { Typography, List, Button, Tooltip, Modal } from "antd";
import Icon, {
  FileTextOutlined,
  DownloadOutlined,
  DeleteOutlined,
  EditOutlined,
  EyeInvisibleOutlined,
} from "@ant-design/icons";
import { useDispatch } from "react-redux";

import {
  askIpPermissionToDocument,
  signAnyIpDocuments,
  deleteAnyIpDocument,
} from "../../redux/action";
import { useSelector } from "react-redux";

import { SuccessSvg } from "../../../../assets/images/successSvg";
import { ipDataRoomNotificationApi } from "../../../../api";

const { Text } = Typography;

const DocumentsList = ({ documents, setEditDoc, ipId }) => {
  const dispatch = useDispatch();

  const { signed, pending } = useSelector((state) => state?.ipDetails);

  const handleSign = (id) => {
    const formData = new FormData();
    formData.append("document_id", id);

    dispatch(signAnyIpDocuments(formData));
  };

  const dataRoomNotification = (item) => {
    let data = new FormData();
    if (item?.document_id) {
      data.document_id = item?.document_id;
      data.append("document_id", item?.document_id);
      data.append("ip_id", ipId);
    } else {
      data.add_document_id = item?.add_document_id;
      data.append("add_document_id", item?.add_document_id);
      data.append("ip_id", ipId);
    }
    ipDataRoomNotificationApi(data);
  };
  return (
    <List
      itemLayout="horizontal"
      dataSource={documents || []}
      renderItem={(item) => {
        const actions = [];

        if (item?.url) {
          actions.push(
            <a
              href={item.url}
              target="_blank"
              style={{
                fontSize: "30px",
                color: "#111827",
              }}
              rel="noreferrer"
            >
              <DownloadOutlined onClick={() => dataRoomNotification(item)} />
            </a>
          );
        }

        if (!item?.url && !item.is_permission_request) {
          actions.push(
            <Tooltip title="Ask permission">
              <EyeInvisibleOutlined
                style={{ fontSize: "22px" }}
                onClick={() => {
                  const formData = new FormData();
                  if (Object.keys(item).includes("document_id")) {
                    formData.append("document_id", item?.document_id);
                  }

                  if (Object.keys(item).includes("add_document_id")) {
                    formData.append("add_document_id", item?.add_document_id);
                  }
                  formData.append("ip_id", ipId);
                  dispatch(askIpPermissionToDocument({ formData, ipId }));
                }}
              />
            </Tooltip>
          );
        }

        if (item.is_permission_request) {
          actions.push(<Text style={{ color: "#16b364" }}>Requested</Text>);
        }

        if (item.is_delete) {
          actions.push(
            <EditOutlined
              style={{ fontSize: "22px" }}
              onClick={() => {
                setEditDoc(item);
              }}
            />
          );
          actions.push(
            <DeleteOutlined
              style={{ fontSize: "22px" }}
              onClick={() => {
                Modal.confirm({
                  title: "Are you sure you want to delete this document ?",
                  onOk() {
                    const formData = new FormData();
                    if (Object.keys(item).includes("document_id")) {
                      formData.append("document_id", item?.document_id);
                    }

                    if (Object.keys(item).includes("add_document_id")) {
                      formData.append("document_id", item?.add_document_id);
                    }

                    dispatch(deleteAnyIpDocument({ formData, ipId }));
                  },
                  onCancel() {},
                });
              }}
            />
          );
        }

        if (item.is_edit_permission) {
          actions.push(
            <EditOutlined
              style={{ fontSize: "22px" }}
              onClick={() => {
                setEditDoc(item);
              }}
            />
          );
        }

        if (!signed.includes(item?.add_document_id) && item?.sign_flag) {
          if (item?.sign_status === "not signed") {
            actions.push(
              <Button
                type="primary"
                htmlType="submit"
                onClick={() => handleSign(item?.add_document_id)}
                loading={pending === item.document_id}
              >
                Sign
              </Button>
            );
          } else {
            actions.push(<Icon component={SuccessSvg} />);
          }
        }

        return (
          <List.Item actions={actions}>
            <List.Item.Meta
              avatar={<FileTextOutlined style={{ fontSize: "2.5rem" }} />}
              title={<Text className="lable ovr-wrp">{item.name}</Text>}
            />
          </List.Item>
        );
      }}
    />
  );
};

export default DocumentsList;
