import { Modal, Spin } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "../../Utils/isAuth";
import DealDetailHeader from "./components/DealDetailHeader";
import DealDetailIntro from "./components/DealDetailIntro";
import InvestorDealDetail from "./InvestorDealDetail";
import OrganizerDealDetail from "./OrganizerDealDetail";
import ExclamationCircleOutlined from "@ant-design/icons/ExclamationCircleOutlined";
import { LoadingOutlined } from "@ant-design/icons";
import { Button } from "antd";

import "./style.scss";
import {
    clearDealError,
    investorDealDetailsAction,
    organizerDealDetailsAction,
} from "./redux/action";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import AdminDealDetail from "./AdminDealDetail";
import { clearMessage } from "../common/dealTypeSelectionModal/redux/action";
import { fetchProjectLogs, getProject, getProjects, getRelatedProjects } from "../../api";
import DocumentsList from "./tabs/dataRoom/DocumentsList";
import { PROJECT_TITLE } from "../settings/redux/constants";
const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;

const { error } = Modal;



const showError = (errorMessage) => {
    error({
        title: "Error",
        content: errorMessage,
        icon: <ExclamationCircleOutlined />,
        centered: true,

        onOk() {
            console.log("OK");
        },
    });
};

const useDealDetails = () => {
    const userRole = getUser()?.roles;
    const dealDetails = useSelector((state) => state.dealDetails.data);
    const loading = useSelector((state) => state.dealDetails.loading);
    const errorMessage = useSelector((state) => state?.dealDetails?.errorMessage);
    const message = useSelector((state) => state?.dealTypeSelection?.Message);
    const msg = useSelector((state) => state?.dealDetails?.msg);
    const [projectLogs, setProjectLogs] = useState([]);
    const user = getUser();
    const dispatch = useDispatch();
    const [projectInfo, setProjectInfo] = useState({});
    const [displayDataRoom, setDisplayDataRoom] = useState(false);
    const [showProjectDataFiles, setShowProjectDataFiles] = useState(null);
    const [ relatedProjects, setRelatedProjects ] = useState([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const [ projectNotFound, setProjectNotFound ] = useState(false);

    const projectId = searchParams.get("projectId");

    const projectDetail = useMemo(()=>{
        return projectInfo
    }, [projectInfo])


    const dataRoomLink = projectDetail?.project?.dataRoomLink;

    const { id } = useParams();
    const { state } = useLocation();

    useEffect(() => {
        if (id) {
            if (userRole === 'ORG' || userRole === 'SVC_ORG' || user.is_superuser) {
                dispatch(organizerDealDetailsAction({ deal_id: id }));
            } else {
                dispatch(investorDealDetailsAction({ id: id }));
            }
        }
    }, [id, dispatch]);
    
        useEffect(() => {
            if (id && message !== '') {
                if (userRole === 'ORG' || userRole === 'SVC_ORG' || user.is_superuser) {
                    dispatch(organizerDealDetailsAction({ deal_id: id }));
                } else {
                    dispatch(investorDealDetailsAction({ id: id }));
                }
            }
            dispatch(clearMessage());
        }, [message, id]);


    useEffect(() => {
        getProjectData();
        fetProjectLogs();
        getRelatedProjectsBySector();
    }, []);

    const fetProjectLogs = async () => {
        try{
            const response = await fetchProjectLogs({
                projectId: projectId
            })
            setProjectLogs(response?.data?.logs?.docs || []);
            console.log("response?.data?.logs?.docs: ", response?.data?.logs?.docs)
        }catch(error){
            console.log("error: ", error)
        }finally{
            console.log("done: ")
        }
    }

    const getProjectData = () => {
        const headers = {
            authorization: `Bearer ${localStorage.getItem('token')}`,
        };
        if (projectId){
            getProject({
                headers,
                id: projectId,
            })
                .then((response) => {
                    setProjectInfo(response?.data || {});
                    dispatch({
                        type: PROJECT_TITLE,
                        data: response?.data?.project?.projectName,
                    });
                })
                .catch((error) => {
                    setProjectNotFound(true);
                    console.log(error);
                });
        }else{
            getProjects()
                .then(({ data }) => {
                    getProject({
                        headers,
                        id: data?.projects?.find((item) => item.projectId === id)?._id,
                    })
                        .then((response) => {
                            setProjectInfo(response?.data || {});
                            dispatch({
                                type: PROJECT_TITLE,
                                data: response?.data?.project?.projectName,
                            });
                        })
                        .catch((error) => {
                            setProjectNotFound(true);
                            console.log(error);
                        });
                })
                .catch(() => {
                    setProjectNotFound(true);
                    message.error('Something went wrong, Please try again !!');
                });
        }
    };


    const getRelatedProjectsBySector = async () => {
        try{
            const response = await getRelatedProjects(projectId)
            setRelatedProjects(response?.data?.relatedProjects || [] );
        }catch(error){
            console.log("error: ", error)
        }finally{
            console.log("done: ")
        }
    }

    useEffect(() => {

        if (errorMessage) {
            showError(errorMessage);
            dispatch(clearDealError());
        }
        if (msg) {
            if (userRole === 'ORG' || userRole === 'SVC_ORG' || user?.is_superuser) {
                dispatch(organizerDealDetailsAction({ deal_id: id }));
            } else {
                dispatch(investorDealDetailsAction({ id: id }));
            }
        }
    }, [errorMessage, dispatch, msg]);

    return {
        dealDetails,
        loading,
        errorMessage,
        message,
        msg,
        projectData:projectDetail,
        displayDataRoom,
        setDisplayDataRoom,
        showProjectDataFiles,
        setShowProjectDataFiles,
        dataRoomLink,
        userRole,
        user,
        projectNotFound,
        projectLogs,
        relatedProjects
    };
};

export default useDealDetails;
