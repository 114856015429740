import React from "react";
import { Table } from "antd";
import { useMediaQuery } from "react-responsive";

function UsersTable({ data, loading, columns, deviceColumns, empty }) {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1210px)",
  });

  return (
    <>
      <div className="table-responsive-ext">
        {!isDesktopOrLaptop ? (
          <Table
            columns={deviceColumns}
            dataSource={data?.map((el, index) => {
              return { ...el, key: index };
            })}
            pagination={false}
            showHeader={false}
            expandable
            loading={loading}
            bordered={false}
            tableLayout="auto"
            size="small"
            className="ant-border-space deals_table"
          />
        ) : (
          <Table
            columns={columns}
            dataSource={data.map((el, index) => {
              return { ...el, key: index };
            })}
            pagination={{ pageSize: 25 }}
            loading={loading}
            className="ant-border-space deals_table"
            locale={{
              emptyText: empty,
            }}
          />
        )}
      </div>
    </>
  );
}

export default UsersTable;
