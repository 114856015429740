import React, { useState } from "react";
import { Row, Col, Form, Input, Button, Divider } from "antd";
import { useDispatch } from "react-redux";
import { signupRequest, ExactPage } from "../../redux/auth/action";
import SignUpSuccessModal from "./SignUpSuccessModal";
import StrengthMeter from "./StrengthMeter";
import { getPasswordStrength } from "../../Utils";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router";

const TermsServiceLink =
  "https://www.dropbox.com/s/rzdrmo079bvhfze/Terms%20for%20InvestTech%20Platform.docx?dl=0";

const PrivacyPolicyLink =
  "https://www.dropbox.com/s/tb4c4x0jpnf9x3o/Privacy%20Policy%20InvestTech%20Platform.docx?dl=0";

const SignUp = () => {
  const [passwordStrength, setPasswordStrength] = useState();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loginWithRedirect } = useAuth0();

  const handleChange = (e) => {
    setPasswordStrength(getPasswordStrength(e.target.value));
  };

  const onFinish = (fieldsValue) => {
    dispatch(signupRequest(fieldsValue));
  };

  const redirectToLogin = () => {
    <SignUpSuccessModal />;
    //dispatch(ExactPage(3));
    navigate("/login");
  };

  const onLoginSocial = () => {
    loginWithRedirect({ prompt: "login" });
  };

  return (
    <Col sm={{ span: 16 }} xs={{ span: 20 }} offset={4} className="rm-offset">
      <Form name="signup" autoComplete="off" onFinish={onFinish}>
        <h1 className="create-account">Create an account</h1>
        <div className="option-text">
          Get access to exclusive startup investing opportunities.
        </div>
        {/* <div className='social-text'>Sign In with</div> */}
        {/* <SocialNetworks /*SocialLogin={() => loginWithPopup()}/> */}
        <Divider className="divider">
          {/* <span className='divider-text'>OR</span> */}
        </Divider>
        <Row gutter={{ xs: 8, sm: 16 }} className="input-responsive">
          <Col className="gutter-row" xs={{ span: 12 }} md={{ span: 12 }}>
            <Row className="mobile-media">
              <Form.Item
                hasFeedback
                name="firstName"
                className="pd-firstname mobile-media"
                label={<span className="label-input">First name</span>}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: "This field is required!",
                  },
                  {
                    pattern: new RegExp(/^[a-zA-Z]+$/),
                    message: "Only letters!",
                  },
                ]}
              >
                <Input
                  placeholder="First name"
                  size="large"
                  name="firstName"
                  className="input-style input-font-style"
                  autoComplete="new-firstName"
                />
              </Form.Item>
            </Row>
          </Col>
          <Col
            className="gutter-row lastname-left-padding"
            xs={{ span: 12 }}
            md={{ span: 12 }}
          >
            <Row className="mobile-media">
              <Form.Item
                hasFeedback
                className="pd-lastname mobile-media"
                name="lastName"
                label={<span className="label-input">Last name</span>}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: "This field is required!",
                  },
                  {
                    pattern: new RegExp(/^[a-zA-Z]+$/),
                    message: "Only letters!",
                  },
                ]}
              >
                <Input
                  placeholder="Last name"
                  size="large"
                  name="lastName"
                  className="input-style input-font-style"
                  autoComplete="new-lastName"
                />
              </Form.Item>
            </Row>
          </Col>
        </Row>
        <Row className="mobile-media">
          <Col className="gutter-row" xs={{ span: 24 }} md={{ span: 24 }}>
            <Form.Item
              name="email"
              className="mobile-media"
              label={<span className="label-input">Email address</span>}
              labelCol={{ span: 24 }}
              hasFeedback
              wrapperCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  type: "email",
                  message: "Please enter a valid email!",
                },
              ]}
            >
              <Input
                className="input-style input-font-style"
                placeholder="Email"
                name="email"
                size="large"
                autoComplete="new-email"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row className="mobile-media">
          <Col className="gutter-row" xs={{ span: 24 }} md={{ span: 24 }}>
            <Form.Item
              name="password"
              className="mobile-media"
              label={<span className="label-input">Password</span>}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Please enter password!",
                },
              ]}
            >
              <Input.Password
                className="input-style input-font-style"
                placeholder="Password"
                size="large"
                name="password"
                onChange={handleChange}
                autoComplete="new-password"
              />
            </Form.Item>
            <StrengthMeter strength={passwordStrength} />
          </Col>
        </Row>
        <Row className="mobile-media">
          <Col className="gutter-row" xs={{ span: 24 }} md={{ span: 24 }}>
            <div>
              <span className="i_agree">
                By clicking <b>Create an Account</b>, you agree to Deep
                Knowledge Group Projects Platform{" "}
                {/* <a
                  href={TermsServiceLink}
                  target="_blank"
                  className="terms-service"
                  rel="noreferrer"
                >
                  Terms of Service{" "}
                </a>
                &{" "}
                <a
                  href={PrivacyPolicyLink}
                  target="_blank"
                  className="terms-service"
                  rel="noreferrer"
                >
                  Privacy Policy.
                </a> */}
              </span>{" "}
            </div>
          </Col>
        </Row>
        <Row className="mobile-media">
          <Col className="gutter-row" xs={{ span: 24 }} md={{ span: 24 }}>
            <Button
              type="submit"
              className="form-submit-btn mobile-media"
              htmlType="submit"
              size="large"
            >
              Create an Account
            </Button>
            <Button
              className="form-submit-btn"
              type="primary"
              shape="round"
              size="large"
              onClick={onLoginSocial}
            >
              Log in with your social account
            </Button>
          </Col>
        </Row>
        <Row className="mobile-media">
          <p className="existing-account mobile-media">
            Already have an account?
            <span className="login-text" onClick={redirectToLogin}>
              Log in
            </span>
          </p>
        </Row>
      </Form>
    </Col>
  );
};

export default SignUp;
