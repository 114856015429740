import { useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Layout, Affix, Spin, Tooltip, Button, Modal, Form, Input, message } from "antd";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { LoadingOutlined, ArrowLeftOutlined } from "@ant-design/icons";

import UserSider from "../../components/UserSider";
import MobileHeader from "../../components/Common/MobileHeader";
import Sidenav from "./Sidenav";
import Header from "./Header";
import { inviteUserToProject } from "../../api";
import { getUser } from "../../Utils/isAuth";

const { Header: AntHeader, Content, Sider } = Layout;

const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;





const EmailModal = ({ showEmailModal, setShowEmailModal }) => {
  const location = useLocation();
  const [form] = Form.useForm();
  const params = new URLSearchParams(location.search);
  const projectId = params.get('projectId');
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);


  const handleOk = async () => {
    console.log("handleOk called");
    console.log({ projectId, email });

    if (!projectId) {
      message.error("Project Id is missing");
      return;
    }
    if (!email) {
      message.error("Email is missing");
      return;
    }

    setLoading(true);
    try {
      const response = await inviteUserToProject({
        invitedUserEmail: email,
        projectId,
      })
      // clear input
      setShowEmailModal(false);
      form.resetFields();
      setEmail("");
      message.success(response?.data?.message);
    } catch (error) {
      message.error(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    form.resetFields();
    setEmail("");
    setShowEmailModal(false);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  return (
    <>
      <Modal
        title="Enter Your Email"
        open={showEmailModal}
        onOk={handleOk}
        onCancel={handleCancel}
        okText={"Send Invite Link"} 
        okButtonProps={{ loading: loading }}
        cancelText="Cancel"
      >
        <Form form={form}>
          <Form.Item
            label="Email"
            name="email"
            rules={[
              { required: true, message: 'Please input your email!' },
              { type: 'email', message: 'Please enter a valid email!' },
            ]}
          >
            <Input value={email} onChange={handleEmailChange} placeholder="Enter your email" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};


function Main({ children, hideBlock = false }) {
  const [visible, setVisible] = useState(false);
  const loading = useSelector((state) => state.userDetail.loading);
  const user = getUser();

  const [showEmailModal, setShowEmailModal] = useState(false);
  const navigate = useNavigate();
  

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1210px)",
  });

  const [sidenavColor, setSidenavColor] = useState("#1890ff");
  const [, setSidenavType] = useState("transparent");
  const [fixed, setFixed] = useState(false);

  const openDrawer = () => setVisible(!visible);
  const handleSidenavType = (type) => setSidenavType(type);
  const handleSidenavColor = (color) => setSidenavColor(color);
  const handleFixedNavbar = (type) => setFixed(type);

  let { pathname } = useLocation();
  pathname = pathname.replace("/", "");



  const checkPath = pathname.includes("dealdetails")
    ? "dealpage"
    : "organizer page";
  const projectDetailPage = pathname.includes("project") && pathname !== "my-projects";

  return (
    <>
      <Layout className="layout-dashboard">
        {loading && (
          <div className="spinner_background">
            <Spin className="spinner" indicator={antIcon} />
          </div>
        )}
        {( !projectDetailPage && isDesktopOrLaptop) && (
          <Sider
            breakpoint="lg"
            collapsedWidth="0"
            onCollapse={(collapsed, type) => {
              console.log(collapsed, type);
            }}
            width={300}
            trigger={null}
            theme="light"
            className="sider-primary ant-layout-sider-primary"
          >
            <Sidenav color={sidenavColor} />
          </Sider>
        )}
        <Layout hasSider={false} style={{"--margin-left-value": !projectDetailPage && isDesktopOrLaptop ? "316px" : "0" }}>
          {
            !projectDetailPage ?
            <>
              {!isDesktopOrLaptop && (
                <Affix className="header-mob">
                  <AntHeader className={`${fixed ? "ant-header-fixed" : ""}`}>
                    <MobileHeader />
                  </AntHeader>
                </Affix>
              )}
              <AntHeader className={`${hideBlock ? "hide-block" : ""}`}>
                <Header
                  onPress={openDrawer}
                  name={pathname}
                  subName={pathname}
                  handleSidenavColor={handleSidenavColor}
                  handleSidenavType={handleSidenavType}
                  handleFixedNavbar={handleFixedNavbar}
                />
              </AntHeader>
            </>
            :
              <div style={{ padding: "1rem .5rem", borderBottom: "1px solid #eaecf0", marginBottom: "1rem", display: "flex", justifyContent: "space-between" }}>
                <Button type="primary" onClick={() => navigate("/")}><ArrowLeftOutlined /> Go Back Home</Button>
                {
                  (user?.roles?.includes("Ad3123min") || user?.email?.endsWith("@dkv.global")) &&
                  <Button onClick={() => setShowEmailModal(true)}>Invite User to Project</Button>
                }
              </div>
          }
          <Content
            className={`${
              pathname === "chat" ? "chat-content" : "content-ant"
            }`}
          >
            {children}
          </Content>
          <UserSider checkPath={checkPath} />
        </Layout>
      </Layout>

      <div style={showEmailModal? { display:"fixed", width:"100%", height:"100%", zIndex:"9999", top:"0", left:"0" } : { display:"none" }}>
        <EmailModal
          showEmailModal={showEmailModal}
          setShowEmailModal={setShowEmailModal}
        />
      </div>
      {/* {!pathname.includes("chat") && (
        <Tooltip placement="top" title="Support">
          <div
            className="support-chat"
            onClick={() => navigate("/chat?openSupport=true")}
          >
            <MessageOutlined style={{ fontSize: "30px" }} />
          </div>
        </Tooltip>
      )} */}
    </>
  );
}

export default Main;
