import React, { useState, useMemo, useEffect } from "react";
import { Tabs, Spin, Badge, message, Modal, Form, Select, Col, Input, List, Avatar, Button, Typography } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import UserHeaderSearchBar from "../../components/Common/UsersTabHeader/UserTabHeader";
import UsersTable from ".././users/UsersTable";
import { myProjectsDeviceColumns, projectsColumns } from ".././users/Constants";
import { useCallback } from "react";
import noUsers from "../../assets/images/no-users.svg";
import { archiveProjectTableData, getAllUsers, getMyProjects, updateDeal } from "../../api";
import { useNavigate } from "react-router-dom";
import { UserOutlined } from "@ant-design/icons";
const { TabPane } = Tabs;

const { Text } = Typography;
const UserEmpty = (
  <div className="no-users-table-img">
    <img src={noUsers} alt="no data" />
    <span className="title-txt">Start supporting charity projects</span>
    <span className="description-txt" style={{ whiteSpace: "break-spaces" }}>
      New projects will be displayed here.Click below to search and support a
      charity project that matters to you.
    </span>
  </div>
);

const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;

const TabHeader = ({ tabName, count }) => (
  <span className="tab-header">
    {tabName}
    <Badge
      count={count || 0}
      size="small"
      className="count-badge-style"
      showZero
    />
  </span>
);

function MyprojectsPage() {
  const [tabValue, setTabValue] = useState("All");
  const [search, setSearch] = useState("");

  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);

  const filteredTableData = useMemo(() => {
    return tableData.filter(
      (item) =>
        (tabValue === "All" ||
          (tabValue !== "All") & (item.status === tabValue)) &&
        (!search ||
          (search &&
            String(item.projectName)
              .toLowerCase()
              .includes(String(search).toLowerCase())))
    );
  }, [tableData, tabValue, search]);

  const getCount = useCallback(
    (tabName) => {
      if (tabName === "All") {
        return tableData?.length;
      } else if (tabName === "Active") {
        return tableData?.filter((user) => user.status === "Active")?.length;
      } else if (tabName === "Disabled") {
        return tableData?.filter((user) => user.status === "Disabled")?.length;
      } else if (tabName === "Archived") {
        return tableData?.filter((user) => user.status === "Archived")?.length;
      } else if (tabName === "Completed") {
        return tableData?.filter((user) => user.status === "Completed")?.length;
      } else if (tabName === "Draft") {
        return tableData?.filter(
          (user) => user.status === "Draft" || user.isDraft
        )?.length;
      }

      return 0;
    },
    [tableData]
  );

  const fetchData = () => {
    setLoading(true);
    getMyProjects().then(({ data }) => {
      setTableData(data.userProjects);
      setLoading(false);
    });
  };


  useEffect(() => {
    fetchData();
  }, []);

  // archiveProjectTableData(record._id).then(fetchData);

  const navigate = useNavigate();
  const permissions = [
    {
      "title": "Read Only",
      "value": "READONLY",
    },
    {
      "title": "Read & Update",
      "value": "READUPDATE",
    },
    {
      "title": "Full Access",
      "value": "FULLACCESS",
    },
  ]

  const humanReadablePermissions = {
    "READONLY": "Read Only",
    "READUPDATE": "Read & Update",
    "FULLACCESS": "Full Access",
  }
  // enum: ["READONLY", "READUPDATE", "FULLACCESS"],

  const navigateEdit = (id) => navigate(`/edit-projects/${id}`);

  const [shareAccess, setShareAccess] = useState(null);
  const [collaborators, setCollaborators] = useState(null);
  const [shareAccessLoading, setShareAccessLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [form] = Form.useForm();

  const onShareAccess = (record) => {
    form.setFieldsValue({ sharedOwner: record?.sharedOwner });
    setShareAccess(record);
  };

  const onViewCollaborators = (collaboratorsArray) => {
    console.log("collaboratorsArray: ", collaboratorsArray)
    setCollaborators(collaboratorsArray);
  };

  const onCancelShareAccess = () => {
    form.resetFields();
    setShareAccess(null);
  };

  const onSaveShareAccess = () => {
    setShareAccessLoading(true);
    const formdata = new FormData();
    const { permissions, userEmail } = form.getFieldsValue();
    if (!userEmail || !permissions) {
      message.error("Please select userEmail and permissions");
      setShareAccessLoading(false);
      return;
    }
    formdata.set("id", shareAccess?._id);
    formdata.set("isCollaboratorUpdated", true);
    formdata.set("links", JSON.stringify(shareAccess?.links));
    const collaborators = {
      userEmail,
      permissions: permissions // Ensure permissions is an array
    }
    formdata.set("collaborators", JSON.stringify(collaborators));

    console.log(collaborators)

    updateDeal({}, formdata)
      .then(({ data }) => {
        onCancelShareAccess();
        message.success("Project access has been updated");
      })
      .catch((err) => {
        message.error(
          err.response?.data?.message ||
          "Something went wrong, Please try again !!"
        );
      })
      .finally(() => {
        setShareAccessLoading(false);
      });
  };

  const fetchAllUsers = () => {
    getAllUsers()
      .then(({ data }) => {
        setUsers(data?.map(({ email, _id }) => ({ title: email, value: _id })));
      })
      .catch(() => {
        message.error("Something went wrong, Please try again !!");
      });
  };

  useEffect(() => {
    fetchAllUsers();
  }, []);

  return (
    <div className="admin-users">
      {loading && (
        <div className="spinner_background">
          <Spin className="spinner" indicator={antIcon} />
        </div>
      )}
      <Tabs defaultActiveKey={tabValue} onChange={(tab) => setTabValue(tab)}>
        <TabPane
          tab={<TabHeader tabName="All" count={getCount("All")} />}
          key="All"
        />
        <TabPane
          tab={<TabHeader tabName="Active" count={getCount("Active")} />}
          key="Active"
        />
        <TabPane
          tab={<TabHeader tabName="Archived" count={getCount("Archived")} />}
          key="Archived"
        />
        <TabPane
          tab={<TabHeader tabName="Completed" count={getCount("Completed")} />}
          key="Completed"
        />
        <TabPane
          tab={<TabHeader tabName="Drafts" count={getCount("Drafts")} />}
          key="Drafts"
        />
      </Tabs>
      <UserHeaderSearchBar
        searchFilter={(e) => setSearch(e.target.value)}
        showRoles={false}
      />
      <UsersTable
        data={filteredTableData}
        loading={false}
        columns={projectsColumns(fetchData, navigateEdit, onShareAccess, onViewCollaborators)}
        deviceColumns={myProjectsDeviceColumns(
          fetchData,
          navigateEdit,
          onShareAccess,
          onViewCollaborators
        )}
        empty={UserEmpty}
      />
      <Modal
        open={collaborators}
        className="approve-modal"
        title="View Collaborators"
        footer={null}
        onCancel={() => setCollaborators(null)}
      >
        <List
        itemLayout="horizontal"
        dataSource={collaborators}
        renderItem={(collator) => (
          <List.Item
          >
            <List.Item.Meta
              title={<Text strong>{collator?.userId?.email}</Text>}
              description={`Permission: ${humanReadablePermissions[collator?.permissions]}`}
            />
          </List.Item>
        )}
      />
      </Modal>
      <Modal
        open={shareAccess}
        onOk={onSaveShareAccess}
        onCancel={onCancelShareAccess}
        className="approve-modal"
        centered={true}
        okText="Save"
        cancelText="Cancel"
        title="Share Access"
        okButtonProps={{
          loading: shareAccessLoading,
        }}
      >
        <Form form={form} layout="vertical" style={{ textAlign: "left" }}>
          <Form.Item name="userEmail" label="Collaborators :" >
            <Input
              style={{ width: "100%", borderRadius: 6 }}
              name="userEmail"
              placeholder="Enter user email"

              prefix={<UserOutlined />}
            />
          </Form.Item>
          <Form.Item name="permissions" label="Role :">
            <Select placeholder="Select shared owner">
              {permissions.map(({ title, value }) => (
                <Select.Option value={value}>{title}</Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}

export default MyprojectsPage;
