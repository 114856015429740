import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Tabs, Badge } from "antd";

import DealsTable from "../../../components/Common/DealsTable";
import MarketPlaceTabHeader from "../../../components/Common/MarketPlaceTabHeader/MarketPlaceTabHeader";
import { getUser } from "../../../Utils/isAuth";
import { userDetailsAction } from "../../../redux/userDetail/action";

const { TabPane } = Tabs;

const CommonTable = ({
  deviceColumns,
  columns,
  keys,
  topOrgPage,
  allData = [],
  isIp,
  changeIp,
  typeIP,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = getUser();
  const location = useLocation();

  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState("");
  const [selectOrg, setSelectOrg] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const { data: tableData = [], loading = false } = allData;
  useEffect(() => {
    let searchData = [...tableData];

    if (search) {
      searchData = searchData.filter(
        ({ name = null, deal_title = null, title = null, ip_title = null }) =>
          (name || deal_title || title || ip_title || "")
            .toString()
            .toLowerCase()
            .includes(search.toLowerCase())
      );
    }

    if (filter && filter !== "All") {
      searchData = searchData.filter((currData) => {
        if (filter === "Drafts") {
          return currData.status === "Draft" || currData.status === "Drafts";
        } else {
          return currData.status === filter;
        }
      });
    }

    setData(searchData);
    // if (selectOrg && selectOrg) setData(searchData);
  }, [filter, allData, search]);

  const getSelectedTab = (dataItem) => setFilter(dataItem);

  const searchFilter = ({ target: { value } }) => setSearch(value);

  const selectFilter = (value) => setSelectOrg(value);

  const dealDetailPage = ({ id = "", deal = "", ip = "" }) => {
    if (deal && !isIp) {
      navigate(`/project/${deal}`);
      return;
    }
    if (isIp && id && location.pathname !== "/ip-investments") {
      navigate(`/ipdetails/${id}`);
      return;
    }
    if (isIp && ip && location.pathname === "/ip-investments") {
      navigate(`/ipdetails/${ip}`);
    }
    if (user.is_superuser && topOrgPage === "top_org" && !isIp) {
      dispatch(userDetailsAction({ id: id, deal_id: "" }));
    } else {
      !isIp && navigate(`/project/${id}`);
    }
  };

  return (
    <Tabs
      defaultActiveKey="All"
      onChange={getSelectedTab}
      style={{ color: "black" }}
    >
      {keys.map((ele) => {
        const dealTypeCounts =
          ele === "All"
            ? tableData.length
            : tableData.filter((data) => {
                if (ele === "Drafts") {
                  return data.status === "Draft" || data.status === "Drafts";
                } else {
                  return data.status === ele;
                }
              }).length;

        return (
          <TabPane
            key={ele}
            tab={
              <span className="tab-header">
                {ele}
                <Badge
                  count={dealTypeCounts}
                  size="small"
                  className="count-badge-style"
                />
              </span>
            }
          >
            <MarketPlaceTabHeader
              selectFilter={selectFilter}
              searchFilter={searchFilter}
              changeIp={changeIp}
              typeIP={typeIP}
              setCurrentPage={setCurrentPage}
            />
            <DealsTable
              data={data}
              dealDetailPage={dealDetailPage}
              loading={loading}
              columns={columns}
              deviceColumns={deviceColumns}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </TabPane>
        );
      })}
    </Tabs>
  );
};

export default CommonTable;
