import React, { useCallback } from "react";
import { Table } from "antd";

import { useMediaQuery } from "react-responsive";

function DealsTable({
  dealDetailPage,
  data = [],
  loading = false,
  columns = [],
  deviceColumns = [],
  currentPage,
  setCurrentPage,
}) {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1210px)",
  });

  const handleRowClick = useCallback(
    (event, record) => {
      const targetClassName = event?.target?.className;
      if (typeof targetClassName !== "string") {
        return;
      }

      if (targetClassName.includes("list-item")) {
        return;
      }

      dealDetailPage(record);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dealDetailPage]
  );

  return (
    <>
      <div className="table-responsive">
        {!isDesktopOrLaptop ? (
          <Table
            onRow={(record, rowIndex) => {
              if (!dealDetailPage) {
                return;
              }

              return {
                onClick: (e) => {
                  e.preventDefault();
                  handleRowClick(e, record);
                },
              };
            }}
            columns={deviceColumns}
            dataSource={data.map((el, index) => {
              return { ...el, key: index };
            })}
            pagination={false}
            showHeader={false}
            expandable
            loading={loading}
            bordered={false}
            tableLayout="auto"
            size="small"
            className="ant-border-space deals_table"
          />
        ) : (
          <Table
            onRow={(record, rowIndex) => {
              if (!dealDetailPage) {
                return;
              }

              return {
                onClick: (e) => {
                  e.preventDefault();
                  handleRowClick(e, record);
                },
              };
            }}
            columns={columns}
            dataSource={data.map((el, index) => {
              return { ...el, key: index };
            })}
            pagination={{
              pageSize: 7,
              current: currentPage,
              onChange: (page) => {
                setCurrentPage(page);
              },
            }}
            loading={loading}
            className="ant-border-space deals_table"
          />
        )}
      </div>
    </>
  );
}

export default DealsTable;
