import React from "react";
import { List, Typography } from "antd";

import LinkOutlined from "@ant-design/icons/LinkOutlined";
const { Text } = Typography;

const linkItems = {
  balance: "Balance",
  incomeStatement: "Income Statement",
  cashFlowStatement: "Cash Flow Statement",
};

const FinancialsLinkList = ({ data }) => (
  <List
    itemLayout="horizontal"
    dataSource={data}
    renderItem={(item) => (
      <List.Item>
        <List.Item.Meta
          style={{
            display: "flex",
            alignItems: "center",
          }}
          avatar={<LinkOutlined style={{ fontSize: "30px" }} />}
          title={<Text className="lable">{linkItems[item.title]}</Text>}
          description={
            <a href={item.link} target="_blank" rel="noopener noreferrer">
              {item.link}
            </a>
          }
        />
      </List.Item>
    )}
  />
);

export default FinancialsLinkList;
