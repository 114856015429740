import { Table } from "antd";
import React from "react";
import { numberFormatter } from "../../../../Utils";

const KPIColumns = [
  {
    title: "Date",
    dataIndex: "date",
  },
  {
    title: "Revenue",
    dataIndex: "revenue",
    render(text) {
      return numberFormatter.formatter(text);
    },
  },
  {
    title: "Short Term Debt",
    dataIndex: "short_term_debt",
    render(text) {
      return numberFormatter.formatter(text);
    },
  },
  {
    title: "In Cash",
    dataIndex: "cash_on_hand",
    render(text) {
      return numberFormatter.formatter(text);
    },
  },
  {
    title: "Net Profit",
    dataIndex: "net_profit",
    render(text) {
      return numberFormatter.formatter(text);
    },
  },
];

const KpiTable = ({ data }) => {
  return (
    <Table
      rowClassName={() => "editable-row"}
      pagination={false}
      dataSource={data.kpi}
      size="small"
      columns={KPIColumns}
    />
  );
};

export default KpiTable;
