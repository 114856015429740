import React from "react";
import {
  LinkedinFilled,
  FacebookFilled,
  TwitterCircleFilled,
  EyeOutlined,
  EditOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { Col, Row, Typography } from "antd";
import { getDealType } from "../constants";

const { Link, Text } = Typography;

const nf = new Intl.NumberFormat();

export const financials = [
  {
    id: "1",
    title: "Key financials indicators",
  },
];
export const pastfundraises = [
  {
    id: "1",
    title: "Past Fundraises",
  },
];
export const spvdoctitle = [
  {
    id: "1",
    title: "SPV Documents",
  },
];
export const team = [
  {
    id: "1",
    title: "John Doe",
    image: "/images/avataruser.png",
    designation: "Ceo",
    designation2: "Co-Founder",
    fbicon: (
      <div>
        <FacebookFilled style={{ color: "#0B5394" }} />
      </div>
    ),

    linkedinicon: (
      <div>
        <LinkedinFilled style={{ color: "#0A66C2" }} />
      </div>
    ),
  },
  {
    id: "2",
    title: "John Doe",
    image: "/images/avataruser.png",
    designation: "Ceo",
    designation2: "Co-Founder",
    fbicon: (
      <div>
        <FacebookFilled style={{ color: "#0B5394" }} />
      </div>
    ),

    linkedinicon: (
      <div>
        <LinkedinFilled style={{ color: "#0A66C2" }} />
      </div>
    ),
  },
  {
    id: "3",
    title: "John Doe",
    image: "/images/avataruser.png",
    designation: "Ceo",
    designation2: "Co-Founder",
    fbicon: (
      <div>
        <FacebookFilled style={{ color: "#0B5394" }} />
      </div>
    ),

    linkedinicon: (
      <div>
        <LinkedinFilled style={{ color: "#0A66C2" }} />
      </div>
    ),
  },
  {
    id: "4",
    title: "John Doe",
    image: "/images/avataruser.png",
    designation: "Ceo",
    designation2: "Co-Founder",
    fbicon: (
      <div>
        <FacebookFilled style={{ color: "#0B5394" }} />
      </div>
    ),

    linkedinicon: (
      <div>
        <LinkedinFilled style={{ color: "#0A66C2" }} />
      </div>
    ),
  },
  {
    id: "5",
    title: "John Doe",
    image: "/images/avataruser.png",
    designation: "Ceo",
    designation2: "Co-Founder",
    fbicon: (
      <div>
        <FacebookFilled style={{ color: "#0B5394" }} />
      </div>
    ),

    linkedinicon: (
      <div>
        <LinkedinFilled style={{ color: "#0A66C2" }} />
      </div>
    ),
  },
];
export const dealDetailListData = (data) => [
  {
    title: "Days left",
    value: data?.days_left,
  },
  {
    title: "Min. Investment",
    value: '00',
  },
  {
    title: "Number of Investors",
    value: data?.no_of_investors,
  },
  {
    title: "Avg. Ticket",
    value: data?.average_ticket,
  },
  {
    title: "Social Media",
    fbicon: (
      <a href={data?.facebook_link}>
        <FacebookFilled style={{ color: "#0B5394" }} />
      </a>
    ),
    linkedinicon: (
      <a href={data?.linkedin_link}>
        <LinkedinFilled style={{ color: "#0A66C2" }} />
      </a>
    ),
  },
  {
    title: "Website",
    value: (
      <Link
        href={data?.company_website}
        style={{ color: "#0B5394" }}
        target="_blank"
      >
        Link
      </Link>
    ),
  },
  {
    title: "Deal Type",
    value: getDealType(data?.deal_type),
  },
  {
    title: "Service Organizer",
    value:
      data?.service_organizer?.name === "invest_tech"
        ? "InvestTech"
        : data?.service_organizer?.name,
  },
];
export const dealDetailListDataMA = (data) => [
  {
    title: "Social Media",
    fbicon: (
      <a href={data?.facebook_link} target="_blank">
        <FacebookFilled style={{ color: "#0B5394" }} />
      </a>
    ),

    linkedinicon: (
      <a href={data?.linkedin_link} target="_blank">
        <LinkedinFilled style={{ color: "#0A66C2" }} />
      </a>
    ),
  },
  {
    title: "Website",
    value: (
      <Link
        href={data?.company_website}
        style={{ color: "#0B5394" }}
        target="_blank"
      >
        Link
      </Link>
    ),
  },
  {
    title: "Deal Type",
    value: getDealType(data?.deal_type),
  },
  {
    title: "Service Organizer",
    value:
      data?.service_organizer?.name === "invest_tech"
        ? "InvestTech"
        : data?.service_organizer?.name,
  },
];

export const conditionsListData = (data) => [
  {
    title: "Formation Date",
    value: data?.date_available_from,
  },
  {
    title: "Expected Close Date",
    value: data?.date_available_to,
  },
  {
    title: "Funding Target",
    value: '00',
  },
  {
    title: "Minimal investment",
    value: '00',
  },

  // {
  //   title: "Management Fee",
  //   value: data?.management_fee,
  // },
  // {
  //   title: "Carried Interest",
  //   value: data?.carried_interest,
  // },
  {
    title: "Minimal closing amount",
    value: '00',
  },
  // {
  //   title: "Address",
  //   value: data?.address,
  // },
];

export const companydocuments = [
  {
    title: "Company Documents",
  },
];

export const invested = [
  {
    title1: "SPV Updates",
    title2: "Terms",
  },
];

export const spvupdates = [
  {
    id: "1",
    title: "Money Transfer pending",
    desc: "Angela Cruz need to wire 5 000 USD",
  },
  {
    id: "2",
    title: "Money Transfer pending",
    desc: "Angela Cruz need to wire 5 000 USD",
  },
  {
    id: "3",
    title: "Money Transfer pending",
    desc: "Angela Cruz need to wire 5 000 USD",
  },
  {
    id: "4",
    title: "Money Transfer pending",
    desc: "Angela Cruz need to wire 5 000 USD",
  },
];
export const spvdocuments = [
  {
    title: "SPV Documents",
  },
];
export const companydetail = [
  {
    id: "1",
    title: "Offering Documents.pdf",
    desc: "13 March",
    size: "4mb",
  },
  { id: "2", title: "Offering Documents.pdf", desc: "13 March", size: "4mb" },
];

export const spvdetail = [
  {
    id: "1",
    title: "Offering Documents.pdf",
    desc: "13 March",
    size: "4mb",
  },
  { id: "2", title: "Offering Documents.pdf", desc: "13 March", size: "4mb" },
];
export const conditiontitle = [
  {
    title: "SPV Name",
    desc: "Fund#1",
  },
];
export const conditiondetail = [
  {
    id: "1",
    title: "Formation Date",
    desc: "17 January, 2022",
  },
  { id: "2", title: "Expected Close Date", desc: "25 February, 2022" },
  { id: "3", title: "Funding Target", desc: "€700,000" },
  { id: "4", title: "Minimal investment", desc: "€700,000" },
  { id: "5", title: "Management Fee", desc: "2%" },
  { id: "6", title: " Carried Interest", desc: "2%" },

  { id: "7", title: "Minimal closing amount", desc: "€100,000" },

  { id: "8", title: "Address", desc: "London, Kingston street, UK, 00701" },
];
export const spvterms = [
  {
    id: "1",
    title: "Fund Manager",
    desc: "Steven Smith",
  },
  { id: "2", title: "Fund Manager", desc: "€1,000" },
  { id: "3", title: "Raised to date", desc: "€45,000" },
  { id: "4", title: "Target size", desc: "€100,000" },
  { id: "5", title: "Sign by", desc: "25 March, 2022" },
  { id: "6", title: "Management Fee", desc: "2%" },

  { id: "7", title: "Carried Interest", desc: "1%" },
  { id: "8", title: "Formation date", desc: "17 January, 2022" },

  { id: "9", title: "Address", desc: "London, Kingston street, UK, 00701" },
];
export const sharedlink = [
  {
    id: "1",
    title: "Shared links",
  },
];
export const revenue = [
  {
    id: "1",
    title: "Revenue",
    amt: "$1280",
    percent: "10",
  },
];
export const shorttermdebt = [
  {
    id: "1",
    title: "Short Term Debt",
    amt: "14",
    percent: "12",
  },
];
export const incash = [
  {
    id: "1",
    title: "In Cash",
    amt: "$91.42",
    percent: "2",
  },
];
export const netprofit = [
  {
    id: "1",
    title: "Net Profit",
    amt: "$91.42",
    percent: "2",
  },
];
export const conditionmail = [
  {
    id: "1",
    avatar: "/images/avataruser.png",
    title: "Steven Smith",
    designation: "Lead Organizer",
    emailicon: "/images/email.png",
    email: "stevemsmith@gmail.com",
  },
];
export const pastfundtable = [
  {
    key: "1",
    roundname: "Convertable Note",
    date: "01-04-2021",
    amount: "56,000 EUR",
    investors: "Fund1,Fund2,Fund3,Fund4",
  },
  {
    key: "2",
    roundname: "Seed",
    date: "01-04-2021",
    amount: "56,000 EUR",
    investors: "Fund1,Fund2,Fund3,Fund4",
  },
  {
    key: "3",
    roundname: "Loan",
    date: "01-04-2021",
    amount: "56,000 EUR",
    investors: "Fund1,Fund2,Fund3,Fund4",
  },
  {
    key: "4",
    roundname: "Convertable Note",
    date: "01-04-2021",
    amount: "56,000 EUR",
    investors: "Fund1,Fund2,Fund3,Fund4",
  },
  {
    key: "5",
    roundname: "Seed",
    date: "01-04-2021",
    amount: "56,000 EUR",
    investors: "Fund1,Fund2,Fund3,Fund4",
  },
  {
    key: "6",
    roundname: "Loan",
    date: "01-04-2021",
    amount: "56,000 EUR",
    investors: "Fund1,Fund2,Fund3",
  },
];

export const spvdoctable = [
  {
    key: "1",
    roundname: "Offering Documents.pdf",
    date: "1.04.2021",
    actions: (
      <div>
        <Row gutter={16}>
          <Col>
            <EyeOutlined />
          </Col>
          <Col>
            <EditOutlined />
          </Col>
          <Col>
            <UploadOutlined />
          </Col>
        </Row>
      </div>
    ),
  },
  {
    key: "2",
    roundname: "Offering Documents.pdf",
    date: "1.04.2021",
    actions: (
      <div>
        <Row gutter={16}>
          <Col>
            <EyeOutlined />
          </Col>
          <Col>
            <EditOutlined />
          </Col>
          <Col>
            <UploadOutlined />
          </Col>
        </Row>
      </div>
    ),
  },
  {
    key: "3",
    roundname: "Offering Documents.pdf",
    date: "1.04.2021",
    actions: (
      <div>
        <Row gutter={16}>
          <Col>
            <EyeOutlined />
          </Col>
          <Col>
            <EditOutlined />
          </Col>
          <Col>
            <UploadOutlined />
          </Col>
        </Row>
      </div>
    ),
  },
  {
    key: "4",
    roundname: "Offering Documents.pdf",
    date: "1.04.2021",
    actions: (
      <div>
        <Row gutter={16}>
          <Col>
            <EyeOutlined />
          </Col>
          <Col>
            <EditOutlined />
          </Col>
          <Col>
            <UploadOutlined />
          </Col>
        </Row>
      </div>
    ),
  },
  {
    key: "5",
    roundname: "Offering Documents.pdf",
    date: "1.04.2021",
    actions: (
      <div>
        <Row gutter={16}>
          <Col>
            <EyeOutlined />
          </Col>
          <Col>
            <EditOutlined />
          </Col>
          <Col>
            <UploadOutlined />
          </Col>
        </Row>
      </div>
    ),
  },
  {
    key: "6",
    roundname: "Offering Documents.pdf",
    date: "1.04.2021",
    actions: (
      <div>
        <Row gutter={16}>
          <Col>
            <EyeOutlined />
          </Col>
          <Col>
            <EditOutlined />
          </Col>
          <Col>
            <UploadOutlined />
          </Col>
        </Row>
      </div>
    ),
  },
  {
    key: "7",
    roundname: "Offering Documents.pdf",
    date: "1.04.2021",
    actions: (
      <div>
        <Row gutter={16}>
          <Col>
            <EyeOutlined />
          </Col>
          <Col>
            <EditOutlined />
          </Col>
          <Col>
            <UploadOutlined />
          </Col>
        </Row>
      </div>
    ),
  },
];

export const ipDetailListData = (data) => {
  const checkIPType = data?.is_register_ip
    ? []
    : [
        {
          title: "IP Transfer Option",
          value: data?.ip_transfer_option || "-",
        },
      ];
  return [
    {
      title: "Social Media",
      fbicon: (
        <a href={data?.facebook_link} target="_blank">
          <FacebookFilled style={{ color: "#0B5394" }} />
        </a>
      ),
      linkedinicon: (
        <a href={data?.linkedin_link} target="_blank">
          <LinkedinFilled style={{ color: "#0A66C2" }} />
        </a>
      ),
    },
    {
      title: "Website",
      value: (
        <a href={data?.company_website} target="_blank">
          Link
        </a>
      ),
    },
    {
      title: "IP Type",
      value: data?.ip_type || "-",
    },
    ...checkIPType,
    {
      title: "Service Organizer",
      value:
        data?.service_organizer?.name === "invest_tech"
          ? "InvestTech"
          : data?.service_organizer?.name || "-",
    },
  ];
};

export const ipDetailsTabListData = (data) => {
  const checkIPType = data?.is_register_ip
    ? []
    : [
        {
          title: "IP Transfer Option",
          value:
            data?.ip_transfer_option || data?.ips?.ip_transfer_option || "-",
        },
      ];
  return [
    {
      title: "IP Name",
      value: data?.title || data?.ips?.title || "-",
    },
    ...checkIPType,
    {
      title: "IP Type",
      value: data?.ip_type || data?.ips?.ip_type || "-",
    },
  ];
};
