import { Modal } from "antd";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import DealTypeForm from "../../createDeal/forms/DealType";
import SPVConditionForm from "../../createDeal/forms/SPVConditionForm/SPVConditionForm";
import {
  downloadSpvDocsRequest,
  showErrorMsg,
} from "../../createDeal/redux/action";
import { createDealDetailInfo } from "./redux/action";
import "./style.scss";

const DealTypeSelectionModal = ({ isModalOpen, setIsModalOpen }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { memorandum_of_association, spv_services_agreement, editedDeal } =
    useSelector((state) => state.createDeal);

  const [showSPVForms, setShowSPVForms] = useState(false);

  const initialDealInfo = {
    deal_type: "",
    service_organizer_id: "",
  };
  const [dealInfo, setDealInfo] = useState(initialDealInfo);

  useEffect(() => {
    dispatch(downloadSpvDocsRequest());
  }, []);

  const formData = new FormData();
  formData.append("deal_id", id);
  formData.append("deal_type", dealInfo.deal_type);
  formData.append("service_organizer_id", dealInfo.service_organizer_id);

  const handleOk = () => {
    if (dealInfo.service_organizer_id !== "invest_tech") {
      dispatch(createDealDetailInfo(formData));
      setIsModalOpen(false);
      setDealInfo(initialDealInfo);
    }

    if (
      dealInfo.service_organizer_id === "invest_tech" &&
      dealInfo.deal_type !== "spv"
    ) {
      dispatch(createDealDetailInfo(formData));
      setIsModalOpen(false);
      setDealInfo(initialDealInfo);
    }

    if (
      !showSPVForms &&
      dealInfo.deal_type === "spv" &&
      dealInfo.service_organizer_id === "invest_tech"
    ) {
      setShowSPVForms(true);
      return;
    }

    const {
      memorandum_of_association_eid = null,
      spv_services_agreement_eid = null,
    } = editedDeal?.deal_info || {};

    const spvServicesDoc =
      spv_services_agreement_eid || spv_services_agreement || null;

    const memorandumDoc =
      memorandum_of_association_eid || memorandum_of_association || null;

    if (
      dealInfo.deal_type === "spv" &&
      dealInfo.service_organizer_id === "invest_tech" &&
      spvServicesDoc &&
      memorandumDoc
    ) {
      formData.append("memorandum_of_association", memorandum_of_association);
      formData.append("spv_services_agreement", spv_services_agreement);
      dispatch(createDealDetailInfo(formData));
      setIsModalOpen(false);
      setDealInfo(initialDealInfo);
    } else {
      dispatch(showErrorMsg("Please sign all documents"));
      return;
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Modal
        title={showSPVForms ? "Spv Documents" : "Deal Type"}
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        centered={true}
        className="dealtype_modal"
      >
        {showSPVForms ? (
          <SPVConditionForm
            visible={true}
            isRequiredButton={false}
            dealId={id}
            dealInfo={dealInfo}
          />
        ) : (
          <DealTypeForm
            visible={true}
            isRequiredButton={false}
            setDealInfo={setDealInfo}
          />
        )}
      </Modal>
    </>
  );
};

export default DealTypeSelectionModal;
