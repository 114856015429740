import React from "react";
import { Avatar, Card, Col, Divider, List, Row, Space } from "antd";
import { useDispatch } from "react-redux";
import { ipDetailsTabListData } from "../../../../data/data";
import { userDetailsAction } from "../../../../redux/userDetail/action";
import { MailOutlined } from "@ant-design/icons";

import { getFirstLater, getImageURL } from "../../../../Utils";

const DetailsTab = ({ data }) => {
  const dispatch = useDispatch();
  // const user = getUser();
  const handleUserClick = () => {
    dispatch(
      userDetailsAction({
        id: data?.creator_details?.id,
        deal_id: "",
        ip_id: data?.id || "",
      })
    );
  };

  return (
    <>
      <Row gutter={[24, 0]}>
        <Col xs={24} lg={12} style={{ marginBottom: "20px" }}>
          <List
            grid={{ gutter: 24, xs: 1, sm: 2, md: 2, lg: 2, xl: 2, xxl: 2 }}
            dataSource={ipDetailsTabListData(data)}
            bordered={false}
            className="deal-intro-list deal-intro-list-ext"
            renderItem={(item) => (
              <List.Item>
                <Card className="list-item" bordered={false}>
                  <div>{item.title}</div>
                  <Space>
                    <span className="list-item__value">{item.value}</span>
                  </Space>
                </Card>
              </List.Item>
            )}
          />
        </Col>
        {data?.creator_details ? (
          <Col
            xs={24}
            lg={12}
            style={{ marginBottom: "20px", cursor: "pointer" }}
          >
            <Card className="condition_card" onClick={handleUserClick}>
              <Row gutter={[16, 8]}>
                <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
                  <Avatar
                    src={getImageURL(data?.creator_details.avatar || "")}
                    className="shape-avatar-letter"
                  >
                    {getFirstLater(data?.creator_details.name || "")}
                  </Avatar>
                </Col>

                <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
                  <h3>{data?.creator_details.name}</h3>
                </Col>
              </Row>
              <Row gutter={[16, 0]}>
                <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
                  <Space>
                    <small>{data?.creator_details?.designation}</small>
                  </Space>
                </Col>
              </Row>
              <Row gutter={[16, 0]}>
                <Divider />
              </Row>
              <Row gutter={[8, 8]}>
                <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
                  <Space
                    onClick={() => {
                      window.open(
                        `mailto:${data?.creator_details?.email}?subject=SendMail&body=Description`
                      );
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <MailOutlined />
                    <label>{data?.creator_details?.email}</label>
                  </Space>
                </Col>
              </Row>
            </Card>
          </Col>
        ) : null}
      </Row>
    </>
  );
};

export default DetailsTab;
