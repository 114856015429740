import { call, put, takeLatest } from "redux-saga/effects";
import {
  UPDATE_MODAL_STATE,
  GET_USERS_LIST_REQUEST,
  GET_USERS_LIST_SUCCESS,
  GET_USERS_LIST_ERROR,
  GET_USER_ROLES_SUCCESS,
  GET_USER_ROLES_ERROR,
  GET_USER_ROLES_REQUEST,
  UPDATE_USER_ROLES_REQUEST,
  UPDATE_USER_ROLES_ERROR,
  UPDATE_USER_ROLES_SUCCESS,
  UPDATE_USER_ACCOUNT_STATUS_REQUEST,
  UPDATE_USER_ACCOUNT_STATUS_SUCCESS,
  UPDATE_USER_ACCOUNT_STATUS_ERROR,
  GET_USER_DETAIL_BY_ID_SUCCESS,
  GET_USER_DETAIL_BY_ID_ERROR,
  GET_USER_DETAIL_BY_ID_REQUEST,
  GET_USERS_LIST_SUCCESS_EXT,
  USER_DETAIL_BY_ID_SUCCESS_PROFILE,
} from "./constants";
import {
  getUserDetailByIdApi,
  getUsersListApi,
  getUsersRolesApi,
  updateUserRoleApi,
  updateUserStatusApi,
} from "../../../api";
import { message } from "antd";

function* requestUsersList(action) {
  try {
    let { data } = yield call(getUsersListApi, action?.data);
    if (data) {
      yield put({ type: GET_USERS_LIST_SUCCESS, data });
    }
  } catch ({ response }) {
    if (response) {
      yield put({
        type: GET_USERS_LIST_ERROR,
        data: response?.data?.Error,
      });
    } else {
      yield put({ type: GET_USERS_LIST_ERROR, data: "Error" });
    }
  }
}
function* requestUsersRoles(action) {
  try {
    let { data } = yield call(getUsersRolesApi, action?.data);
    if (data) {
      yield put({ type: GET_USER_ROLES_SUCCESS, data });
    }
  } catch ({ response }) {
    if (response) {
      yield put({
        type: GET_USER_ROLES_ERROR,
        data: response?.data?.Error,
      });
    } else {
      yield put({ type: GET_USER_ROLES_ERROR, data: "Error" });
    }
  }
}

function* updateUserRole(action) {
  try {
    let { data } = yield call(updateUserRoleApi, action?.data);
    if (data) {
      yield put({ type: UPDATE_USER_ROLES_SUCCESS, data });
      yield put({ type: GET_USERS_LIST_SUCCESS_EXT, data: data?.user_data });
      yield put({ type: UPDATE_MODAL_STATE, data: "" });
      message.success(data?.Msg);
    }
  } catch ({ response }) {
    if (response) {
      yield put({
        type: UPDATE_USER_ROLES_ERROR,
        data: response?.data?.Error,
      });
      yield put({ type: UPDATE_MODAL_STATE, data: "" });
      message.error(response?.data?.Error);
    } else {
      yield put({ type: UPDATE_USER_ROLES_ERROR, data: "Error" });
      yield put({ type: UPDATE_MODAL_STATE, data: "" });
      message.error("Error: Something went wrong !!");
    }
  }
}

function* updateUserStatus(action) {
  try {
    let { data } = yield call(updateUserStatusApi, action?.data);
    if (data) {
      yield put({ type: UPDATE_USER_ACCOUNT_STATUS_SUCCESS, data });
      yield put({ type: GET_USERS_LIST_SUCCESS_EXT, data: data.user_data });
      yield put({ type: UPDATE_MODAL_STATE, data: "" });
      yield put({
        type: USER_DETAIL_BY_ID_SUCCESS_PROFILE,
        data: { active: data.user_data.status === "Active" },
      });

      message.success(data?.Msg);
    }
  } catch ({ response }) {
    if (response) {
      yield put({
        type: UPDATE_USER_ACCOUNT_STATUS_ERROR,
        data: response?.data?.Error,
      });
      yield put({ type: UPDATE_MODAL_STATE, data: "" });
      message.error(response?.data?.Error);
    } else {
      yield put({ type: UPDATE_USER_ACCOUNT_STATUS_ERROR });
      yield put({ type: UPDATE_MODAL_STATE, data: "" });
      message.error("Error: Something went wrong !!");
    }
  }
}

function* requestUserDetailById(action) {
  try {
    let { data } = yield call(getUserDetailByIdApi, action?.data);
    if (data) {
      yield put({
        type: GET_USER_DETAIL_BY_ID_SUCCESS,
        data: { ...data, id: action?.data.id },
      });
    }
  } catch ({ response }) {
    if (response) {
      yield put({
        type: GET_USER_DETAIL_BY_ID_ERROR,
        data: response?.data?.Error,
      });
    } else {
      yield put({ type: GET_USER_DETAIL_BY_ID_ERROR, data: "Error" });
    }
  }
}

function* UsersListWatcher() {
  yield takeLatest(GET_USERS_LIST_REQUEST, requestUsersList);
  yield takeLatest(GET_USER_ROLES_REQUEST, requestUsersRoles);
  yield takeLatest(UPDATE_USER_ROLES_REQUEST, updateUserRole);
  yield takeLatest(UPDATE_USER_ACCOUNT_STATUS_REQUEST, updateUserStatus);
  yield takeLatest(GET_USER_DETAIL_BY_ID_REQUEST, requestUserDetailById);
}

export default UsersListWatcher;
