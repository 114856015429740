import {
  IP_LIST_REQUEST,
  IP_LIST_SUCCESS,
  IP_LIST_ERROR,
  ORGANIZER_IP_LIST_REQUEST,
  ORGANIZER_IP_LIST_SUCCESS,
  ORGANIZER_IP_LIST_ERROR,
  DELETE_IP_REQUEST,
  DELETE_IP_SUCCESS,
  DELETE_IP_ERROR,
} from "./constants";

const initialState = {
  loading: false,
  data: [],
  organizerIpData: null,
};

export default function IPList(state = initialState, action) {
  switch (action.type) {
    case IP_LIST_REQUEST:
      return { ...state, loading: true };
    case IP_LIST_SUCCESS:
      return {
        ...state,
        data: action.data,
        loading: false,
      };
    case IP_LIST_ERROR:
      return { ...state, loading: false };

    case ORGANIZER_IP_LIST_REQUEST:
      return { ...state, loading: true };
    case ORGANIZER_IP_LIST_SUCCESS:
      return {
        ...state,
        data: action.data,
        loading: false,
      };
    case ORGANIZER_IP_LIST_ERROR:
      return { ...state, loading: false };

    case DELETE_IP_REQUEST:
      return { ...state, loading: true };
    case DELETE_IP_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case DELETE_IP_ERROR:
      return { ...state, loading: false };

    default:
      return state;
  }
}
