import {
  GET_INVESTOR_VIEW_DATA_REQUEST,
  GET_INVESTOR_VIEW_DATA_SUCCESS,
  GET_INVESTOR_VIEW_DATA_ERROR,
} from "./constants";

const initialState = {
  loading: false,
  data: {},
};

export default function investerViewData(state = initialState, action) {
  switch (action.type) {
    case GET_INVESTOR_VIEW_DATA_REQUEST:
      return { ...state, loading: true };
    case GET_INVESTOR_VIEW_DATA_SUCCESS:
      return {
        ...state,
        data: action.data,
        loading: false,
      };
    case GET_INVESTOR_VIEW_DATA_ERROR:
      return { ...state, loading: false };
    default:
      return state;
  }
}
