/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import {
  Avatar,
  Button,
  Card,
  Dropdown,
  Menu,
  Tag,
  Modal,
  Divider,
} from "antd";

import Icon from "@ant-design/icons";
import Title from "antd/lib/typography/Title";
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  QuestionCircleOutlined,
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons";

import "./style.scss";

import ApproveDealModalIcon from "../../../assets/images/modal-approve-deal.png";
import { DropdownSvg } from "../../../assets/images/dropdownSvg";
import { updateOrganizersStatusRequest } from "../../../pages/adminTopOrganizers/redux/action";

import ComplianceCheck from "../../../assets/images/compliance-check.png";
import { BASE_URL } from "../../../api";
import { getFirstLater, getImageURL } from "../../../Utils"

const AdminTopOrganizersComplianceCheckMenu = ({
  record,
  dispatch,
  type = "",
}) => {
  const [visibleApprove, setVisibleApprove] = useState(false);
  const [visibleReject, setVisibleReject] = useState(false);

  const showApproveModal = (e) => {
    e.domEvent.stopPropagation();
    setVisibleApprove(true);
  };

  const showRejectModal = (e) => {
    e.domEvent.stopPropagation();
    setVisibleReject(true);
  };

  const handleOkApprove = (e) => {
    e.stopPropagation();
    const formData = new FormData();

    formData.append("id", record?.id);
    formData.append("status", `Approve`.trim());
    dispatch(updateOrganizersStatusRequest(formData));

    setVisibleApprove(false);
  };

  const handleCancelApprove = (e) => {
    e.stopPropagation();
    setVisibleApprove(false);
  };

  const handleOkReject = (e) => {
    e.stopPropagation();
    const formData = new FormData();

    formData.append("id", record?.id);
    formData.append("status", `Reject `.trim());
    dispatch(updateOrganizersStatusRequest(formData));
    setVisibleReject(false);
  };

  const handleOkComplianceCheck = (e) => {
    e.domEvent.stopPropagation();
    const formData = new FormData();

    formData.append("id", record?.id);
    formData.append("status", `Compliance Check`);
    dispatch(updateOrganizersStatusRequest(formData));
  };

  const handleCancelReject = (e) => {
    e.stopPropagation();
    setVisibleReject(false);
  };

  return (
    <>
      <Menu className="edit-menu" triggerSubMenuAction="click">
        {record.status === "In Review" ? (
          <>
            <Menu.Item
              onClick={handleOkComplianceCheck}
              className="edit-menu__item"
              key="edit-menu-item-1"
            >
              {/* <Icon
                component={ComplianceCheck}
                className="dropdown-menu-button"
              /> */}
              <img
                src={ComplianceCheck}
                className="edit-menu__icon edit-menu__item menu-icon-size"
                alt=""
              />
              <span className="list-item compliance-container">Compliance Check</span>
            </Menu.Item>
            <Divider className="divider-margin" />
          </>
        ) : null}
        {record.status !== "Completed" ? (
          <Menu.Item
            className="edit-menu__item"
            key="edit-menu-item-2"
            onClick={showApproveModal}
            style={{ color: "#34D399" }}
          >
            <CheckOutlined className="menu-item-icon approve-txt" />
            <span className="list-item approve-txt">Approve</span>
          </Menu.Item>
        ) : null}
        <Menu.Item
          className="edit-menu__item"
          key="edit-menu-item-3"
          onClick={showRejectModal}
          style={{ color: "#EF4444" }}
        >
          <CloseOutlined className="menu-item-icon reject-txt" />
          <span className="list-item reject-txt">Reject</span>
        </Menu.Item>
      </Menu>
      <Modal
        visible={visibleApprove}
        onOk={handleOkApprove}
        onCancel={handleCancelApprove}
        className="approve-modal"
        centered={true}
        footer={[
          <Button
            key="no"
            onClick={handleCancelApprove}
            className="modal-cancel-btn"
          >
            No
          </Button>,
          <Button
            key="yes"
            type="primary"
            onClick={handleOkApprove}
            className="modal-yes-btn"
          >
            Yes
          </Button>,
        ]}
      >
        <div>
          <img
            src={ApproveDealModalIcon}
            className="approve-modal-icon"
            alt=""
          />
          <p className="approve-modal-title">
            Approve an {type || "Investment"}?
          </p>
          <p className="approve-modal-desc">
            Are you sure you want to approve an {type}? This action cannot be
            undone.
          </p>
        </div>
      </Modal>
      <Modal
        visible={visibleReject}
        onOk={handleOkReject}
        onCancel={handleCancelReject}
        className="approve-modal"
        centered={true}
        footer={[
          <Button
            key="no"
            onClick={handleCancelReject}
            className="modal-cancel-btn"
          >
            No
          </Button>,
          <Button
            key="yes"
            type="primary"
            onClick={handleOkReject}
            className="reject-modal-yes-btn "
          >
            Yes
          </Button>,
        ]}
      >
        <div>
          <CloseOutlined className="reject-modal-icon" />
          <p className="approve-modal-title">Reject an {type}?</p>
          <p className="approve-modal-desc">
            Are you sure you want to reject an {type}? This action cannot be
            undone.
          </p>
        </div>
      </Modal>
    </>
  );
};

const adminTopOrganizersColumns = (dispatch) => [
  {
    title: ({ sortColumns }) => {
      const sortedColumn = sortColumns?.find(
        ({ column }) => column.key === "name"
      );

      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          Name
          {sortedColumn?.order ? (
            sortedColumn.order === "ascend" ? (
              <ArrowUpOutlined style={{ marginLeft: "8px" }} />
            ) : (
              <ArrowDownOutlined style={{ marginLeft: "8px" }} />
            )
          ) : null}
        </div>
      );
    },

    sorter: (a, b) => a.name.localeCompare(b.name),
    dataIndex: "name",
    key: "name",
    render: (text, record) => {
      const imageUrl = BASE_URL + record?.logo;

      return (
        <Avatar.Group>
          <Avatar
            className={`${!imageUrl ? "shape-avatar-letter" : ""} shape-avatar`}
            shape="round"
            size={40}
            src={getImageURL(imageUrl || "")}
          >
            {getFirstLater(record?.name || "")}
          </Avatar>
        </Avatar.Group>
      );
    },
  },
  {
    title: ({ sortColumns }) => {
      const sortedColumn = sortColumns?.find(
        ({ column }) => column.key === "name"
      );

      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          Organizer Name
          {sortedColumn?.order ? (
            sortedColumn.order === "ascend" ? (
              <ArrowUpOutlined style={{ marginLeft: "8px" }} />
            ) : (
              <ArrowDownOutlined style={{ marginLeft: "8px" }} />
            )
          ) : null}
        </div>
      );
    },
    sorter: (a, b) => a?.name - b?.name,
    dataIndex: "name",
    key: "name",
  },
  {
    title: (
      <div style={{ display: "flex", alignItems: "center" }}>
        Status <QuestionCircleOutlined style={{ marginLeft: "8px" }} />
      </div>
    ),
    dataIndex: "status",
    key: "status",
    render: (status) => {
      let backgroundColor = "";
      let textColor = "";
      if (status === "Completed") {
        textColor = "#027A48";
        backgroundColor = "#ECFDF3";
      } else if (status === "In Review") {
        textColor = "#0B5394";
        backgroundColor = "#F0F9FF";
      }
      return (
        <Tag className="tag_text" color={backgroundColor}>
          <span
            style={{
              color: textColor,
              fontFamily: "Manrope",
              fontStyle: "normal",
              fontWeight: 500,
              fontSize: "14px",
              lineHeight: "20px",
              letterSpacing: "0.01em",
            }}
          >
            &#9679;&nbsp;{status === "Completed" ? "Active" : status}
          </span>
        </Tag>
      );
    },
  },
  {
    render: (text, record) => {
      const getType = (status) => {
        let type = "";

        if (status === "In Review") {
          type = "Investment";
        }

        if (status === "Completed") {
          type = "Transfer";
        }

        if (status === "Compliance Check") {
          type = "";
        }

        return type;
      };

      return !(
        record.status === "Completed" || record.status === "Rejected"
      ) ? (
        <Dropdown
          overlay={
            <AdminTopOrganizersComplianceCheckMenu
              record={record}
              dispatch={dispatch}
              type={getType(record.status)}
            />
          }
          trigger="click"
          placement="bottomLeft"
        >
          <Icon component={DropdownSvg} className="dropdown-menu-button" />
        </Dropdown>
      ) : null;
    },
  },
];

const adminTopOrganizersDeviceColumns = (dispatch, navigate) => [
  {
    render: (text, record) => {
      let backgroundColor = "";
      let textColor = "";
      if (record.status === "Completed") {
        textColor = "#027A48";
        backgroundColor = "#ECFDF3";
      } else if (record.status === "In Review") {
        textColor = "#0B5394";
        backgroundColor = "#F0F9FF";
      }

      const getType = (status) => {
        let type = "";

        if (status === "In Review") {
          type = "";
        }

        if (status === "Completed") {
          type = "Transfer";
        }

        if (status === "Compliance Check") {
          type = "";
        }

        return type;
      };

      const imageUrl = BASE_URL + record?.logo;

      return (
        <Card
          className="deal-table-card"
          title={
            <Avatar.Group>
              <Avatar
                className={`${!imageUrl ? "shape-avatar-letter" : ""} shape-avatar`}
                shape="round"
                size={40}
                src={getImageURL(imageUrl || "")}
              >
                {getFirstLater(record?.name || "")}
              </Avatar>
            </Avatar.Group>
          }
          extra={
            <div>
              <Tag className="tag_text" color={backgroundColor}>
                <span
                  style={{
                    color: textColor,
                    fontFamily: "Manrope",
                    fontStyle: "normal",
                    fontWeight: 500,
                    fontSize: "14px",
                    lineHeight: "20px",
                    letterSpacing: "0.01em",
                  }}
                >
                  &#9679;&nbsp;
                  {record.status === "Completed" ? "Active" : record.status}
                </span>
              </Tag>
              {!(
                record.status === "Completed" || record.status === "Rejected"
              ) ? (
                <Dropdown
                  overlay={
                    <AdminTopOrganizersComplianceCheckMenu
                      record={record}
                      dispatch={dispatch}
                      type={getType(record.status)}
                    />
                  }
                  trigger="click"
                  placement="bottomLeft"
                >
                  <Icon
                    component={DropdownSvg}
                    className="dropdown-menu-button"
                  />
                </Dropdown>
              ) : null}
            </div>
          }
        >
          <Card.Grid className="card-grid">
            <div className="card-grid__item">
              <p>Organizer Name</p>
              <Title level={5}>{record.name}</Title>
            </div>
          </Card.Grid>
        </Card>
      );
    },
  },
];

export { adminTopOrganizersColumns, adminTopOrganizersDeviceColumns };
