import "./App.less";
import React, { useEffect } from "react";
import { BrowserRouter, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { initializeTagManager } from "./gtm";

import "antd/dist/antd.css";
import "./assets/styles/main.less";
import "./assets/styles/responsive.less";

import AppRoutes from "./routes/AppRoutes";
import { getAllChatAction } from "./pages/chatPage/redux/action";
import { getUser } from "./Utils/isAuth";
import useIdleLogout from "./hooks/useIdleLogout";
import { message } from "antd";

function App() {
  const user = getUser();
  const dispatch = useDispatch();
  const handleOnIdle = () => {
    if (window.location.pathname === '/login') {
      return;
    }
    // Perform logout actions
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    message.error('You have been logged out due to inactivity. Please login again.');
    window.location.href = '/login';
  };

  useIdleLogout(handleOnIdle, 30 * 60 * 1000); // 30 minutes

  useEffect(() => {
    initializeTagManager();
    let intervalId = "";
    if (user) {
      dispatch(getAllChatAction());
      intervalId = setInterval(() => {
        dispatch(getAllChatAction());
      }, 300000);
    } else if (intervalId !== "") {
      clearInterval(intervalId);
    }

    return () => (intervalId !== "" ? clearInterval(intervalId) : null);
  }, [dispatch, user]);



  return (
    <BrowserRouter>
      <AppRoutes />
    </BrowserRouter>
  );
}

export default App;
