import { call, put, takeLatest } from "redux-saga/effects";

import { getIPMarketplaceListApi } from "../../../api";

import {
  IP_MARKETPLACE_LIST_ERROR,
  IP_MARKETPLACE_LIST_REQUEST,
  IP_MARKETPLACE_LIST_SUCCESS,
} from "./constants";

function* requestIPMarketplaceList(action) {
  try {
    let { data } = yield call(getIPMarketplaceListApi, action.data);
    if (data) {
      yield put({ type: IP_MARKETPLACE_LIST_SUCCESS, data });
    }
  } catch (err) {
    yield put({ type: IP_MARKETPLACE_LIST_ERROR });
  }
}

function* IPMarketplaceListWatcher() {
  yield takeLatest(IP_MARKETPLACE_LIST_REQUEST, requestIPMarketplaceList);
}
export default IPMarketplaceListWatcher;
