import {
  UPDATE_MODAL_STATE,
  SET_EDIT_USER_ID,
  GET_USERS_LIST_REQUEST,
  GET_USERS_LIST_SUCCESS,
  GET_USERS_LIST_ERROR,
  GET_USER_ROLES_REQUEST,
  GET_USER_ROLES_SUCCESS,
  GET_USER_ROLES_ERROR,
  UPDATE_USER_ROLES_REQUEST,
  UPDATE_USER_ROLES_SUCCESS,
  UPDATE_USER_ROLES_ERROR,
  UPDATE_USER_ACCOUNT_STATUS_REQUEST,
  UPDATE_USER_ACCOUNT_STATUS_SUCCESS,
  UPDATE_USER_ACCOUNT_STATUS_ERROR,
  GET_USER_DETAIL_BY_ID_REQUEST,
  GET_USER_DETAIL_BY_ID_SUCCESS,
  GET_USER_DETAIL_BY_ID_ERROR,
  GET_USERS_LIST_SUCCESS_EXT,
  USER_DETAIL_BY_ID_SUCCESS_PROFILE,
  SET_IS_SKIPPED,
} from "./constants";
const initialState = {
  active: "",
  editUser: {},
  usersList: [],
  loading: false,
  usersRole: [],
  updatingLoading: false,
  errorMessage: "",
  userDetailById: {},
  userDetailByIdLoading: false,
  isSkiped: false,
};

export default function userPage(state = initialState, action) {
  switch (action.type) {
    case UPDATE_MODAL_STATE:
      return {
        ...state,
        active: action.data,
      };
    case SET_EDIT_USER_ID:
      return {
        ...state,
        editUser: action.data,
      };

    // GETTING USERS DATA
    case GET_USERS_LIST_REQUEST:
      return { ...state, loading: true };
    case GET_USERS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        usersList: action?.data,
      };
    case GET_USERS_LIST_SUCCESS_EXT:
      return {
        ...state,
        loading: false,
        usersList: [
          action?.data,
          ...state.usersList.filter((user) => user.id !== action?.data.id),
        ],
      };
    case GET_USERS_LIST_ERROR:
      return { ...state, loading: false, errorMessage: action.data };

    // GETTING USERS ROLES
    case GET_USER_ROLES_REQUEST:
      return { ...state, loading: true };
    case GET_USER_ROLES_SUCCESS:
      return {
        ...state,
        loading: false,
        usersRole: action?.data,
      };
    case GET_USER_ROLES_ERROR:
      return { ...state, loading: false, errorMessage: action.data };

    // UPDATING USER ROLE
    case UPDATE_USER_ROLES_REQUEST:
      return { ...state, updatingLoading: true };
    case UPDATE_USER_ROLES_SUCCESS:
      return {
        ...state,
        updatingLoading: false,
        message: action?.data,
      };
    case UPDATE_USER_ROLES_ERROR:
      return { ...state, updatingLoading: false, errorMessage: action.data };

    //UPDATING USER ACOUNT STATUS
    case UPDATE_USER_ACCOUNT_STATUS_REQUEST:
      return { ...state, updatingLoading: true };
    case UPDATE_USER_ACCOUNT_STATUS_SUCCESS:
      return {
        ...state,
        updatingLoading: false,
        message: action?.data,
      };
    case UPDATE_USER_ACCOUNT_STATUS_ERROR:
      return { ...state, updatingLoading: false, errorMessage: action.data };

    // FETCHING USER DETAILS
    case GET_USER_DETAIL_BY_ID_REQUEST:
      return { ...state, userDetailByIdLoading: true };
    case GET_USER_DETAIL_BY_ID_SUCCESS:
      return {
        ...state,
        userDetailById: action.data,
        userDetailByIdLoading: false,
      };
    case USER_DETAIL_BY_ID_SUCCESS_PROFILE:
      return {
        ...state,
        userDetailById: {
          ...state.userDetailById,
          profile: state.userDetailById?.profile
            ? { ...state.userDetailById.profile, ...action.data }
            : undefined,
        },
      };
    case GET_USER_DETAIL_BY_ID_ERROR:
      return { ...state, userDetailByIdLoading: false, errorMessage: null };
    
    case SET_IS_SKIPPED:
      return { ...state, isSkiped: action.data}
    default:
      return state;
  }
}
