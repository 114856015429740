import { call, put, takeLatest, select } from "redux-saga/effects";
import {
  PUBLISH_IP_ERROR,
  PUBLISH_IP_REQUEST,
  PUBLISH_IP_SUCCESS,
  GET_IP_DETAILS_REQUEST,
  GET_IP_DETAILS_SUCCESS,
  GET_IP_DETAILS_ERROR,
  GET_IP_DATA_ROOM_SUCCESS,
  GET_IP_DATA_ROOM_ERROR,
  GET_IP_DATA_ROOM_REQUEST,
  ADD_COMMENT_IP_REQUEST,
  ADD_COMMENT_IP_ERROR,
  ADD_COMMENT_IP_SUCCESS,
  ASKED_PERMISSION_TO_DOCUMENTS_SUCCESS_IP,
  ASKED_PERMISSION_TO_DOCUMENTS_ERROR_IP,
  ASKED_PERMISSION_TO_DOCUMENTS_REQUEST_IP,
  GIVE_PERMISSION_TO_DOCUMENTS_IP_REQUEST,
  GIVE_PERMISSION_TO_DOCUMENTS_IP_SUCCESS,
  GIVE_PERMISSION_TO_DOCUMENTS_IP_ERROR,
  ASK_PERMISSION_TO_DOCUMENT_IP_REQUEST,
  ASK_PERMISSION_TO_DOCUMENT_IP_ERROR,
  UPLOAD_DATA_ROOM_IP_REQUEST,
  UPLOAD_DATA_ROOM_IP_ERROR,
  UPDATE_ANY_IP_DOCUMENT_REQUEST,
  UPDATE_ANY_IP_DOCUMENT_ERROR,
  UPLOAD_SIGN_ANY_IP_DOCS_REQUEST,
  UPLOAD_SIGN_ANY_IP_DOCS_SUCCESS,
  UPLOAD_SIGN_ANY_IP_DOCS_ERROR,
  SIGN_ANY_IP_DOCUMENT_REQUEST,
  SIGN_ANY_IP_DOCUMENT_SUCCESS,
  SIGN_ANY_IP_DOCUMENT_ERROR,
  DELETE_ANY_IP_DOCUMENT_REQUEST,
  DELETE_ANY_IP_DOCUMENT_ERROR,
  GET_INVESTOR_IP_DETAILS_REQUEST,
  GET_INVESTOR_IP_DETAILS_SUCCESS,
  GET_INVESTOR_IP_DETAILS_ERROR,
  UNPUBLISH_IP_SUCCESS,
  UNPUBLISH_IP_ERROR,
  UNPUBLISH_IP_REQUEST,
} from "./constants";

import {
  publishIPApi,
  getIPDetailPageDetails,
  getIpDataRoomApi,
  addIpCommentApi,
  getIpPermissionRequestApi,
  askIpPermissionApi,
  giveIpPermissionRequestApi,
  uploadAnyDocumentApi,
  updateAnyDocumentApi,
  uploadSignedAnyIpDocumentsApi,
  signAnyDocumentApi,
  uploadSignedAnyDocumentsApi,
  deleteAnyDocumentApi,
  getIPDetailInvestorPageDetails,
  unPublishIPApi
} from "../../../api";
import { message } from "antd";
import { EDIT_IP_DETAILS_REQUEST } from "../../createIp/redux/constants";

function* requestPublishIPApi(action) {
  try {
    let { data } = yield call(publishIPApi, action?.data);

    if (data) {
      yield put({ type: PUBLISH_IP_SUCCESS });
      yield put({ type: EDIT_IP_DETAILS_REQUEST });
      message.success("Published successfully");
    }
  } catch (err) {
    yield put({ type: PUBLISH_IP_ERROR });
  }
}

function* requestUnPublishIPApi(action) {
  try {
    let { data } = yield call(unPublishIPApi, action?.data);
    if (data.Msg) {
      yield put({ type: UNPUBLISH_IP_SUCCESS, data: data.Msg });
      message.success("Unpublish successfully");
    }
  } catch (err) {
    yield put({ type: UNPUBLISH_IP_ERROR });
  }
}

function* getIpDetails(action) {
  try {
    let { data } = yield call(getIPDetailPageDetails, action?.id);

    if (data) {
      yield put({ type: GET_IP_DETAILS_SUCCESS, data: data });
    }
  } catch (err) {
    yield put({ type: GET_IP_DETAILS_ERROR });
  }
}

function* requestInvestorIPDetails(action) {
  try {
    let { data } = yield call(getIPDetailInvestorPageDetails, action?.id);

    if (data) {
      yield put({ type: GET_INVESTOR_IP_DETAILS_SUCCESS, data });
    }
  } catch (err) {
    yield put({ type: GET_INVESTOR_IP_DETAILS_ERROR });
  }
}

function* requestDataRoomDocuments(action) {
  try {
    const resp = yield call(getIpDataRoomApi, action?.id);
    if (resp.status === 200 || resp.status === 201) {
      yield put({ type: GET_IP_DATA_ROOM_SUCCESS, data: resp?.data });
    }
  } catch ({ response }) {
    if (response) {
      yield put({
        type: GET_IP_DATA_ROOM_ERROR,
        data: response?.data?.error,
      });
    } else {
      yield put({ type: GET_IP_DATA_ROOM_ERROR, data: "Error" });
    }
  }
}

function* requestAddComment(action) {
  try {
    let { data } = yield call(addIpCommentApi, action?.data);

    if (data) {
      yield put({ type: ADD_COMMENT_IP_SUCCESS });
    }
  } catch (err) {
    yield put({ type: ADD_COMMENT_IP_ERROR });
  }
}

function* getAskedPermissionToDocumentRequestIp({ data: { ipId = null } }) {
  try {
    let resp = yield call(getIpPermissionRequestApi, ipId);

    if (resp.status === 200 || resp.status === 201) {
      yield put({
        type: ASKED_PERMISSION_TO_DOCUMENTS_SUCCESS_IP,
        data: resp?.data?.documents || [],
      });
    }
  } catch ({ response }) {
    if (response) {
      yield put({
        type: ASKED_PERMISSION_TO_DOCUMENTS_ERROR_IP,
        data: response?.data?.Error || response?.data?.error,
      });
    } else {
      yield put({
        type: ASKED_PERMISSION_TO_DOCUMENTS_ERROR_IP,
        data: "Error",
      });
    }
  }
}

function* givePermissionToIpDocumentRequest(action) {
  try {
    let resp = yield call(giveIpPermissionRequestApi, action.data);

    const add_document_id = action?.data?.get("add_document_id");
    const user_id = action?.data?.get("user_id");
    const document_id = action?.data?.get("document_id");

    const permissionsAsked = yield select(
      (state) => state?.ipDetails?.permissions?.permissionsAsked
    );

    const newPermissions = permissionsAsked.filter((el) => {
      if (document_id) {
        if (Number(el?.user_id) === Number(user_id))
          return Number(el?.document_id) !== Number(document_id);
      }

      if (add_document_id) {
        if (Number(el?.user_id) === Number(user_id))
          return Number(el?.add_document_id) !== Number(add_document_id);
      }
    });

    if (resp.status === 200 || resp.status === 201) {
      yield put({
        type: GIVE_PERMISSION_TO_DOCUMENTS_IP_SUCCESS,
        data: newPermissions,
      });
      message.success(resp?.data?.msg || resp?.msg);
    }
  } catch ({ response }) {
    if (response) {
      yield put({
        type: GIVE_PERMISSION_TO_DOCUMENTS_IP_ERROR,
        data: response?.data?.Error || response?.data?.error,
      });
    } else {
      yield put({
        type: ASKED_PERMISSION_TO_DOCUMENTS_ERROR_IP,
        data: "Error",
      });
    }
  }
}

function* askIpPermissionToDocumentRequest(action) {
  try {
    const formData = action?.data?.formData;
    let resp = yield call(askIpPermissionApi, formData);
    if (resp.status === 200 || resp.status === 201) {
      yield put({
        type: GET_IP_DATA_ROOM_REQUEST,
        id: {
          ip_id: action?.data?.ipId,
          message: resp?.data?.msg,
        },
      });
      resp?.data?.msg && message.success(resp?.data?.msg);
    }
  } catch ({ response }) {
    if (response) {
      yield put({
        type: ASK_PERMISSION_TO_DOCUMENT_IP_ERROR,
        data: response?.data?.Error || response?.data?.error,
      });
    } else {
      yield put({ type: ASK_PERMISSION_TO_DOCUMENT_IP_ERROR, data: "Error" });
    }
  }
}

function* requestUploadIpDataRoomDocuments(action) {
  try {
    const resp = yield call(uploadAnyDocumentApi, action?.data?.formData);

    if (resp.status === 200 || resp.status === 201) {
      yield put({
        type: GET_IP_DATA_ROOM_REQUEST,
        id: {
          ip_id: action?.data?.ipId,
          message: resp?.data?.msg,
        },
      });
    }
  } catch ({ response }) {
    if (response) {
      yield put({
        type: UPLOAD_DATA_ROOM_IP_ERROR,
        data: response?.data?.error,
      });
    } else {
      yield put({ type: UPLOAD_DATA_ROOM_IP_ERROR, data: "Error" });
    }
  }
}

function* requestUpdateAnyIpDocuments(action) {
  try {
    const resp = yield call(updateAnyDocumentApi, action?.data?.formData);

    if (resp.status === 200 || resp.status === 201) {
      yield put({
        type: GET_IP_DATA_ROOM_REQUEST,
        id: {
          ip_id: action?.data?.ipId,
          message: resp?.data?.msg,
        },
      });
    }
  } catch ({ response }) {
    if (response?.data) {
      yield put({
        type: UPDATE_ANY_IP_DOCUMENT_ERROR,
        data: response?.data?.Error || response?.data?.error,
      });
    } else {
      yield put({ type: UPDATE_ANY_IP_DOCUMENT_ERROR, data: "Error" });
    }
  }
}

function* uploadSignedIpAnyDocuments() {
  try {
    const ipDetails = yield select((state) => state?.ipDetails);
    const formData = new FormData();
    formData.append("envelope_id", ipDetails[ipDetails.pending]);
    formData.append("document_id", ipDetails.pending);

    let resp = yield call(uploadSignedAnyDocumentsApi, formData);

    if (resp.status === 200 || resp.status === 201) {
      yield put({
        type: UPLOAD_SIGN_ANY_IP_DOCS_SUCCESS,
      });
    }
  } catch ({ response }) {
    if (response) {
      yield put({
        type: UPLOAD_SIGN_ANY_IP_DOCS_ERROR,
        data: response?.data?.Error,
      });
    } else {
      yield put({ type: UPLOAD_SIGN_ANY_IP_DOCS_ERROR, data: "Error" });
    }
  }
}

function* requestSignAnyIpDocuments(action) {
  try {
    let { data } = yield call(signAnyDocumentApi, action?.data);
    const document_id = action?.data?.get("document_id");

    if (data) {
      yield put({
        type: SIGN_ANY_IP_DOCUMENT_SUCCESS,
        data: { ...data, id: Number(document_id) },
      });
    }
  } catch ({ response }) {
    if (response) {
      yield put({
        type: SIGN_ANY_IP_DOCUMENT_ERROR,
        data: response?.data?.Error,
      });
    } else {
      yield put({ type: SIGN_ANY_IP_DOCUMENT_ERROR, data: "Error" });
    }
  }
}

function* requestDeleteAnyIpDocuments(action) {
  try {
    let resp = yield call(deleteAnyDocumentApi, action?.data?.formData);

    if (resp.status === 200 || resp.status === 201) {
      yield put({
        type: GET_IP_DATA_ROOM_REQUEST,
        id: {
          ip_id: action?.data?.ipId,
          message: resp?.data?.msg,
        },
      });
    }
  } catch ({ response }) {
    if (response) {
      yield put({
        type: DELETE_ANY_IP_DOCUMENT_ERROR,
        data: response?.data?.Error,
      });
    } else {
      yield put({ type: DELETE_ANY_IP_DOCUMENT_ERROR, data: "Error" });
    }
  }
}

function* IPDetailsWatcher() {
  yield takeLatest(PUBLISH_IP_REQUEST, requestPublishIPApi);
  yield takeLatest(UNPUBLISH_IP_REQUEST, requestUnPublishIPApi);

  yield takeLatest(GET_IP_DETAILS_REQUEST, getIpDetails);
  yield takeLatest(GET_INVESTOR_IP_DETAILS_REQUEST, requestInvestorIPDetails);

  yield takeLatest(GET_IP_DATA_ROOM_REQUEST, requestDataRoomDocuments);
  yield takeLatest(ADD_COMMENT_IP_REQUEST, requestAddComment);
  yield takeLatest(DELETE_ANY_IP_DOCUMENT_REQUEST, requestDeleteAnyIpDocuments);
  yield takeLatest(SIGN_ANY_IP_DOCUMENT_REQUEST, requestSignAnyIpDocuments);
  yield takeLatest(UPLOAD_SIGN_ANY_IP_DOCS_REQUEST, uploadSignedIpAnyDocuments);
  yield takeLatest(
    UPLOAD_DATA_ROOM_IP_REQUEST,
    requestUploadIpDataRoomDocuments
  );
  yield takeLatest(UPDATE_ANY_IP_DOCUMENT_REQUEST, requestUpdateAnyIpDocuments);
  yield takeLatest(
    ASKED_PERMISSION_TO_DOCUMENTS_REQUEST_IP,
    getAskedPermissionToDocumentRequestIp
  );
  yield takeLatest(
    GIVE_PERMISSION_TO_DOCUMENTS_IP_REQUEST,
    givePermissionToIpDocumentRequest
  );
  yield takeLatest(
    ASK_PERMISSION_TO_DOCUMENT_IP_REQUEST,
    askIpPermissionToDocumentRequest
  );
}
export default IPDetailsWatcher;
