import React from "react";
import { passwordStrength } from "../../Utils";

const StrengthMeter = ({ strength }) => (
  <div className="strength-meter">
    <div className="flex-container">
      {[
        passwordStrength.POOR,
        passwordStrength.WEAK,
        passwordStrength.STRONG,
      ].includes(strength) && <div className="red h-4"></div>}
      {[passwordStrength.WEAK, passwordStrength.STRONG].includes(strength) && (
        <div className="orange h-4"></div>
      )}
      {[passwordStrength.STRONG].includes(strength) && (
        <div className="green h-4"></div>
      )}
    </div>
    <p> {strength}</p>
  </div>
);

export default StrengthMeter;
