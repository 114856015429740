import { Card, Col, Row, Statistic } from "antd";
import React, { useState } from "react";
import AnalyticsDetailData from "./AnalyticsDetailData";
import "./styles.scss";

const AnalyticsTab = ({ data }) => {
  const {
    ip_view_count,
    document_view_count,
    document_viewers,
    ip_viewers,
    ip_chart,
    document_chart,
  } = data;
  const [selected, setSelected] = useState("");

  return (
    <>
      <Row gutter={[24, 0]}>
        {selected === "dealPage" ? (
          <AnalyticsDetailData
            chartData={ip_chart || []}
            count={ip_view_count || 0}
            analyticData={ip_viewers || []}
            selected={selected}
            setSelected={setSelected}
          />
        ) : selected === "documentPage" ? (
          <AnalyticsDetailData
            analyticData={document_viewers || []}
            count={document_view_count || 0}
            chartData={document_chart || []}
            selected={selected}
            setSelected={setSelected}
          />
        ) : (
          <Col xs={24} className="deal__card" style={{ marginBottom: "50px" }}>
            <Row gutter={[24, 24]}>
              <Col xs={24} md={5}>
                <Card
                  hoverable
                  onClick={() => {
                    setSelected("dealPage");
                  }}
                >
                  <Row>
                    <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
                      {
                        <h3 className="financial_statics_title">
                          User views by IP
                        </h3>
                      }
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col xs={24} sm={24} md={12} xl={12} xxl={12}>
                      <Statistic
                        className="stats_percentage"
                        value={ip_view_count}
                      />
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col xs={24} md={5}>
                <Card
                  hoverable
                  onClick={() => {
                    setSelected("documentPage");
                  }}
                >
                  <Row>
                    <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
                      {
                        <h3 className="financial_statics_title">
                          Downloaded Documents
                        </h3>
                      }
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col xs={24} sm={24} md={12} xl={12} xxl={12}>
                      <Statistic
                        className="stats_percentage"
                        value={document_view_count}
                      />
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Col>
        )}
      </Row>
    </>
  );
};

export default AnalyticsTab;
