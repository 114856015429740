import { Timeline } from "antd";
import StepCard from "../stepCard/StepCard";

import { getPercent, getStatus, StepDot } from "./utils";
import { isUserIndevidual } from "../../../Utils";

const getOnboedingSteps = (navigate, userFlow) => {
  const steps = [
    {
      title: "Profile",
      description:
        "Fill out your profile for full interaction with the platform",
      button: {
        title: "Go to Settings",
        callback: () => {
          navigate("/settings");
        },
      },
      dot: (
        <StepDot
          stepDone={
            isUserIndevidual()
              ? userFlow?.onboarding?.completed_profile
              : userFlow?.onboarding?.completed_company_profile
          }
          firstItem={true}
        />
      ),
    },
    {
      title: "Identity",
      description: "Upload necessary KYB documents",
      button: {
        title: "Go to Settings",
        callback: () => {
          navigate("/settings");
        },
      },
      dot: <StepDot stepDone={userFlow?.onboarding?.verified_identity} />,
    },
    {
      title: "Questionnaire",
      description:
        "Provide us with some additional info for better interaction with you",
      button: {
        title: "Go to Settings",
        callback: () => {
          navigate("/settings");
        },
      },
      dot: <StepDot stepDone={userFlow?.onboarding?.visited_questionnaire} />,
    },
    {
      title: "Source of Funds",
      description: "Upload necessary documents or download our template",
      button: {
        title: "Go to Settings",
        callback: () => {
          navigate("/settings");
        },
      },
      dot: <StepDot stepDone={userFlow?.onboarding?.visited_source_of_funds} />,
    },
    {
      title: "SumSub",
      description:
        "Get verified with SumSub for more secure and faster KYB/KYC process",
      button: {
        title: "Go to Settings",
        callback: () => {
          navigate("/settings");
        },
      },
      dot: <StepDot stepDone={userFlow?.onboarding?.verified_sumsub} />,
    },
  ];
  if (isUserIndevidual()) {
    steps.splice(1, 1);
  }

  return steps;
};

export const investorSteps = (userFlow, navigate, current) => [
  {
    title: "Explore InvestTech",
    status: getStatus(userFlow?.explore_investtech, current, 0),
    percent: getPercent(userFlow?.explore_investtech),
    content: (
      <Timeline mode="alternate">
        {[
          {
            title: "Service Marketplace",
            description:
              "Find and connect with platform users offering organizer and facilitator services",
            button: {
              title: "Go to Service Marketplace",
              callback: () => {
                navigate("/service-marketplace");
              },
            },
            dot: (
              <StepDot
                stepDone={
                  userFlow?.explore_investtech?.visited_service_marketplace
                }
                firstItem={true}
              />
            ),
          },
          {
            title: "Grant Marketplace",
            description:
              "Discover other grant opportunities for secure funding and support",
            button: {
              title: "Go to Grant Marketplace",
              callback: () => {
                navigate("/grant-marketplace");
              },
            },
            dot: (
              <StepDot
                stepDone={
                  userFlow?.explore_investtech?.visited_grant_marketplace
                }
              />
            ),
          },
          {
            title: "InvestTech Marketplace",
            description: "Access all the fundraising deals",
            button: {
              title: "Go to InvestTech Marketplace",
              callback: () => {
                navigate("/");
              },
            },
            dot: (
              <StepDot
                stepDone={
                  userFlow?.explore_investtech?.visited_investtech_marketplace
                }
              />
            ),
          },
          {
            title: "Longevity Marketplace",
            description: "Discover Longevity products and services",
            button: {
              title: "Go to Longevity Marketplace",
              callback: () => {
                navigate("/longevity-marketplace");
              },
            },
            dot: (
              <StepDot
                stepDone={
                  userFlow?.explore_investtech?.visited_longevity_marketplace
                }
              />
            ),
          },
          {
            title: "IP Marketplace",
            description: "Explore and view Intellectual Property deals",
            button: {
              title: "Go to IP Marketplace",
              callback: () => {
                navigate("/ip-marketplace");
              },
            },
            dot: (
              <StepDot
                stepDone={userFlow?.explore_investtech?.visited_ip_marketplace}
              />
            ),
          },
          {
            title: "M&A Marketplace",
            description: "Explore and view Mergers & Acquisitions deals",
            button: {
              title: "Go to M&A Marketplace",
              callback: () => {
                navigate("/MAmarketplace");
              },
            },
            dot: (
              <StepDot
                stepDone={userFlow?.explore_investtech?.visited_m_and_a}
              />
            ),
          },
          {
            title: "Chat",
            description: "Get in touch with platform users",
            button: {
              title: "Go to Chat",
              callback: () => {
                navigate("/chat");
              },
            },
            dot: (
              <StepDot stepDone={userFlow?.explore_investtech?.visited_chat} />
            ),
          },
          {
            title: "Dashboard",
            description:
              "Track your investment portfolio and stay informed on your deal performance",
            button: {
              title: "Go to Dashboard",
              callback: () => {
                navigate("/dashboard");
              },
            },
            dot: (
              <StepDot
                stepDone={userFlow?.explore_investtech?.visited_dashboard}
              />
            ),
          },
        ].map((el, index) => {
          return (
            <Timeline.Item dot={el?.dot}>
              <StepCard
                title={el?.title}
                description={el?.description}
                button={el?.button}
                index={index}
              />
            </Timeline.Item>
          );
        })}
      </Timeline>
    ),
  },

  {
    title: "Onboarding",
    status: getStatus(userFlow?.onboarding, current, 1),
    percent: getPercent(userFlow?.onboarding),
    content: (
      <Timeline mode="alternate">
        {getOnboedingSteps(navigate, userFlow).map((el, index) => {
          return (
            <Timeline.Item dot={el?.dot}>
              <StepCard
                title={el?.title}
                description={el?.description}
                button={el?.button}
                index={index}
              />
            </Timeline.Item>
          );
        })}
      </Timeline>
    ),
  },

  {
    title: "Invest on Deals",
    status: getStatus(userFlow?.invest_on_deals, current, 2),
    percent: getPercent(userFlow?.invest_on_deals),
    content: (
      <Timeline mode="alternate">
        {[
          {
            title: "Search for Deals",
            description:
              "Access all the view fundraising deals and view the deal page you are interested in",
            button: {
              title: "InvestTech Marketplace ",
              callback: () => {
                navigate("/");
              },
            },
            dot: (
              <StepDot
                stepDone={userFlow?.invest_on_deals?.visited_deal_search}
                firstItem={true}
              />
            ),
          },
          {
            title: "View Data Room",
            description: "Check deal documents in the deal data room ",
            button: {
              title: "My deals",
              callback: () => {
                navigate("/deals");
              },
            },
            dot: (
              <StepDot stepDone={userFlow?.invest_on_deals?.visited_dataroom} />
            ),
          },
          {
            title: "Discussions",
            description: "Discuss about the deal with startup in the deal page",
            button: {
              title: "My deals",
              callback: () => {
                navigate("/deals");
              },
            },
            dot: (
              <StepDot
                stepDone={userFlow?.invest_on_deals?.visited_discussion}
              />
            ),
          },
          {
            title: "Submit Deal Offer",
            description: "Make an investment on a deal you are interested in",
            button: {
              title: "My deals",
              callback: () => {
                navigate("/deals");
              },
            },
            dot: (
              <StepDot
                stepDone={userFlow?.invest_on_deals?.visited_submit_deal_offer}
              />
            ),
          },
          // {
          //   title: "Escrow Account",
          //   description:
          //     "Create escrow account to have a more secure funding experience for your investment",
          //   button: {
          //     title: "My deals",
          //     callback: () => {
          //       navigate("/deals");
          //     },
          //   },
          //   dot: (
          //     <StepDot
          //       stepDone={userFlow?.invest_on_deals?.visited_escrow_account}
          //     />
          //   ),
          // },
        ].map((el, index) => {
          return (
            <Timeline.Item dot={el?.dot}>
              <StepCard
                title={el?.title}
                description={el?.description}
                button={el?.button}
                index={index}
              />
            </Timeline.Item>
          );
        })}
      </Timeline>
    ),
  },
];
