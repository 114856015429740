import React, { useState } from "react";
import { Button, Card, Col, List, Row, Space, Tooltip, Typography } from "antd";

import ReactPlayer from "react-player";
import { ipDetailListData } from "../../../data/data";
import { BASE_URL } from "../../../api";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "../../../Utils/isAuth";
import { formDataWrapper, isFacilitator, isInvestor } from "../../../Utils";
import {
  ipDetailsAction,
  ipInvestorDetails,
  publishIP,
  unPublishIP,
} from "../redux/action";
import { useParams } from "react-router-dom";

const { Text } = Typography;
const nf = new Intl.NumberFormat();

function IpDetailsIntro({ IpDetails = {}, userRole, handleInvest }) {
  const dispatch = useDispatch();
  const { id } = useParams();

  const published = useSelector(
    (state) => state.ipDetails?.data?.is_published || state.ipDetails.published
  );
  const { message, investment_status } = useSelector(
    (state) => state.ipDetails.ips
  );
  const ipInvestmentStatus = useSelector(
    (state) => state.ipDetails.data.investment_status
  );
  const user = getUser();

  const [, setIsModalOpen] = useState(false);

  const { background_video, is_published, isDraft } = IpDetails;

  const handlePublish = () => {
    const formData = formDataWrapper({
      ip_id: Number(id),
    });

    if (is_published || published) {
      dispatch(unPublishIP(formData));
    } else {
      dispatch(publishIP(formData));
    }
    setTimeout(() => {
      isInvestor() || isFacilitator()
        ? dispatch(ipInvestorDetails(id))
        : dispatch(ipDetailsAction(id));
    }, 500);
  };

  // const handleInvestClick = () => {
  //   if (user?.is_onboarded || user?.onboardingStepsDone?.onboarding_status) {
  //     dispatch(
  //       getDealInvesting({
  //         deal_id: id,
  //       })
  //     );
  //     history("/invest");
  //   } else {
  //     history("/settings");
  //   }
  // };

  const InvestButtons = () => {
    const getButtonLabel = () => {
      switch (investment_status) {
        case "Active":
          return `IP In Progress`;
        case "In Progress":
          return `IP In Progress`;
        case "Completed":
          return `IP complete`;
        case "In Review":
          return `IP under review`;
        default:
          return "Buy";
      }
    };

    return (
      <>
        {!IpDetails.is_draft && !IpDetails.is_register_ip && (
          <Button
            className="header-btns"
            disabled={message === "Already Invested" || message === "Completed"}
            type={
              message === "Already Invested" || message === "Completed"
                ? "text"
                : ""
            }
            onClick={() => handleInvest()}
          >
            {getButtonLabel()}
          </Button>
        )}
      </>
    );
  };

  const OrganizerButtons = () => {
    return (
      <div className="org-buttons">
        {!IpDetails.is_draft &&
          IpDetails.status !== "Completed" &&
          ipInvestmentStatus !== "Completed" && 
          user?.email === IpDetails?.creator_details?.email && (
            <Button
              onClick={() => {
                handlePublish();
              }}
              disabled={isDraft}
              // style={{ color: is_published || published ? "#16B364" : "" }}
              type={"primary"}
            >
              {is_published || published ? "Unpublish" : "Publish"}
            </Button>
          )}
        {/* <Button
          onClick={() => setIsModalOpen(true)}
          style={{ color: "#0b5394", marginLeft: "5px" }}
        >
          Manage Ip Type
        </Button> */}
      </div>
    );
  };

  return (
    <div className="deal-intro">
      <Row gutter={[24, 0]}>
        <Col xs={24} sm={24} md={12}>
          <div className="intro-video">
            <ReactPlayer
              url={
                background_video?.includes("http")
                  ? background_video
                  : `${BASE_URL}${background_video}`
              }
              controls={true}
              className="intro-video"
            />
          </div>
        </Col>
        <Col xs={24} sm={24} md={12}>
          {!IpDetails.is_register_ip && (
            <div className="card_detail_head card_details_deals_amount">
              <div className="card_detail_head ">Price</div>
              <div className="card_detail_price">
                <Tooltip title={nf.format(Number(IpDetails?.price))}>
                  <Text ellipsis={true} style={{ maxWidth: "150px" }}>
                    {`${nf.format(Number(IpDetails?.price))}`}
                  </Text>{" "}
                  <Text ellipsis={true} style={{ width: "50px" }}>
                    {IpDetails?.currency}
                  </Text>
                </Tooltip>
                {userRole === "ORG" && (
                  <OrganizerButtons
                    status={IpDetails?.status}
                    dispatch={dispatch}
                    id={IpDetails.id}
                    setIsModalOpen={setIsModalOpen}
                  />
                )}
                {userRole === "INV" &&
                  user?.email !== IpDetails?.creator_details?.email && (
                    <InvestButtons />
                  )}
              </div>
            </div>
          )}

          <List
            grid={{ gutter: 24, xs: 1, sm: 2, md: 2, lg: 2, xl: 2, xxl: 2 }}
            dataSource={ipDetailListData(IpDetails)}
            bordered={false}
            className="deal-intro-list deal-intro-list-ext"
            renderItem={(item) => (
              <List.Item>
                <Card className="list-item" bordered={false}>
                  <div>{item.title}</div>
                  <Space>
                    <span className="list-item__value">{item.value}</span>
                    {item?.linkedinicon}
                    {item?.fbicon}
                    {item?.twittericon}
                  </Space>
                </Card>
              </List.Item>
            )}
          />
        </Col>
      </Row>
      {/* <DealTypeSelectionModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      /> */}
    </div>
  );
}

export default IpDetailsIntro;
