import {
  ADD_USER_TO_CHAT_REQUEST,
  CREATE_CHAT_REQUEST,
  DELETE_USER_FROM_CHAT_REQUEST,
  GET_ALL_CHAT_REQUEST,
  GET_ALL_MESSAGES_REQUEST,
  GET_ALL_USERS_REQUEST,
  NEW_MESSAGE_REQUEST,
  RESET_ALL_DETAILS,
  RESET_MESSAGES,
  SET_PRE_SELECTED_CHAT,
  START_CHAT,
} from "./constants";

export const getAllUsersAction = () => {
  return {
    type: GET_ALL_USERS_REQUEST,
  };
};

export const setPreSelectedChatAction = (data) => {
  return {
    type: SET_PRE_SELECTED_CHAT,
    data,
  };
};

export const startChatAction = () => {
  return {
    type: START_CHAT,
  };
};

export const createChatAction = (data) => {
  return {
    type: CREATE_CHAT_REQUEST,
    data,
  };
};

export const getAllMessagesAction = (data) => {
  return {
    type: GET_ALL_MESSAGES_REQUEST,
    data,
  };
};

export const newMessageAction = (data) => {
  return {
    type: NEW_MESSAGE_REQUEST,
    data,
  };
};

export const getAllChatAction = () => {
  return {
    type: GET_ALL_CHAT_REQUEST,
  };
};

export const deleteUserFromChatAction = (data) => {
  return {
    type: DELETE_USER_FROM_CHAT_REQUEST,
    data,
  };
};

export const addUserToChatAction = (data) => {
  return {
    type: ADD_USER_TO_CHAT_REQUEST,
    data,
  };
};

export const resetMessages = () => ({
  type: RESET_MESSAGES,
});

export const resetAllDetails = () => ({
  type: RESET_ALL_DETAILS,
});
