import React from "react";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Button, Col } from "antd";
import email_img from "../../assets/images/email.svg";
import { ExactPage, resendSignupVerifyLink } from "../../redux/auth/action";
import { getUser } from "../../Utils/isAuth";
import { useNavigate } from "react-router";

const EmailVerify = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = getUser();
  const authDetails = useSelector((state) => state.auth);
  const { resendVerifyEmailSuccess } = authDetails;
  const [verifyRequestSuccess, setVerifyRequestSuccess] = useState(false);

  useEffect(() => {
    if (resendVerifyEmailSuccess) {
      setVerifyRequestSuccess(true);
      const timer = setTimeout(() => {
        setVerifyRequestSuccess(false);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [resendVerifyEmailSuccess]);

  const resendLink = () => {
    const payload = {
      uid: user?.user_id,
      email: user?.email,
    };
    dispatch(resendSignupVerifyLink(payload));
  };

  return (
    <>
      <Col span={16} offset={4} className="rm-offset">
        <div className="email-container">
          <p className="verify-email-text">Verify your email</p>
          <div className="reset-link-instruction">
            "A verification link has been sent to your email. Please click on
            the link to verify your email and continue the registration
            process."
          </div>
          {verifyRequestSuccess && (
            <Col
              className="gutter-row rm-padding error-message"
              xs={{ span: 24 }}
              md={{ span: 24 }}
            >
              <p>Link sent!</p>
            </Col>
          )}
          <Form>
            <div className="verify_email">
              <img src={email_img} alt="verify email" />
              <div className="password-reset-link">
                <p>
                  Didn’t receive the email?
                  <Button
                    type="link"
                    htmlType="submit"
                    onClick={resendLink}
                    className="resend_link"
                    disabled
                  >
                    Resend link
                  </Button>
                </p>{" "}
                <Button
                  type="link"
                  onClick={() => {
                    //dispatch(ExactPage(3));
                    navigate("/login");
                  }}
                >
                  Login Now
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </Col>
    </>
  );
};
export default EmailVerify;
