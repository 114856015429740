import React from "react";
import {
  Steps,
  Avatar,
  Button,
  Card,
  Col,
  Divider,
  Row,
  List,
  Space,
} from "antd";
import { MoreOutlined } from "@ant-design/icons";

import SpvDocTable from "../components/dashboardComponent/tables/SpvDocTable";
import profilavatar from "../assets/images/dealprofile.png";
import { spvterms, spvupdates, invested, spvdoctitle } from "../data/data";

const { Step } = Steps;

const InvestedView = () => {
  return (
    <div className="card_page">
      <Row gutter={[24, 4]}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Card bordered={false} className="top_card">
            <Row>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Avatar.Group>
                  <Avatar shape="square" src={profilavatar} />

                  <div>
                    <h4>Global Flowers</h4>
                    <p>
                      Company with a big perspective in future investigation!
                    </p>
                  </div>
                </Avatar.Group>
              </Col>
              <Col md={1} lg={1} xl={1}>
                <Divider type="vertical" />
              </Col>
              <Col xs={24} sm={24} md={5} lg={5} xl={5}>
                {" "}
                <div className="top_card">
                  <h4>10,000</h4>
                  <p>Commited amount, EUR</p>
                </div>
              </Col>
              <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                <Button className="btn_invest">View Deal Profile</Button>
              </Col>
            </Row>
          </Card>
        </Col>

        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Card bordered={false} className="top_card">
            <Row className="step_card">
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Steps current={2} percent={80}>
                  <Step title="Finished" description="This is a description." />
                  <Step title="Finished" description="This is a description." />
                  <Step
                    title="In Progress"
                    subTitle="Left 00:00:08"
                    description="This is a description."
                  />
                  <Step title="Waiting" description="This is a description." />
                </Steps>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <Row gutter={[24, 24]}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          {/* <Card bordered={false} className="invest_update_card"> */}
          <Row gutter={[24, 24]}>
            <Col xs={24} sm={24} md={16} lg={16} xl={16}>
              <Card bordered={false} className="invest_update_card">
                <Row gutter={[24, 0]}>
                  <Col xs={24} sm={24} md={23} xl={23} xxl={23}>
                    {invested.map((c, index) => (
                      <>
                        <h2>{c.title1} </h2>
                      </>
                    ))}{" "}
                  </Col>
                  <Col xs={24} sm={24} md={1} xl={1} xxl={1}>
                    <MoreOutlined
                      style={{
                        fontSize: "18px",
                        color: "#9CA3AF",
                      }}
                    />
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <Card bordered={false} className="invest_update_card">
                <Row gutter={[8, 0]}>
                  <Col xs={24} sm={24} md={23} xl={23} xxl={23}>
                    {invested.map((c, index) => (
                      <>
                        <h2>{c.title2} </h2>
                      </>
                    ))}{" "}
                  </Col>
                  <Col xs={24} sm={24} md={1} xl={1} xxl={1}>
                    <MoreOutlined
                      style={{
                        fontSize: "18px",
                        color: "#9CA3AF",
                      }}
                    />
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
          {/* </Card> */}
        </Col>
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Row gutter={[32, 24]}>
              <Col xs={24} sm={24} md={16} lg={16} xl={16}>
                <div className="spv_updates">
                  <Row gutter={[24, 16]}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      {" "}
                      <Row gutter={[24, 16]}>
                        {spvupdates.map((c, index) => (
                          <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
                            <Card>
                              <>
                                <h2>{c.title} </h2>
                                <p>{c.desc}</p>
                              </>
                            </Card>
                          </Col>
                        ))}{" "}
                      </Row>
                    </Col>

                    <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
                      {spvdoctitle.map((c, index) => (
                        <h2>{c.title}</h2>
                      ))}
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <SpvDocTable />
                    </Col>
                  </Row>
                </div>
              </Col>

              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Row>
                  <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
                    <div className="spv_list">
                      <List
                        grid={{ gutter: 24, column: 1 }}
                        dataSource={spvterms}
                        renderItem={(item) => (
                          <List.Item key={item.id}>
                            <Card className="spv_term_list" bordered={false}>
                              <div>
                                <h2>{item.title}</h2>
                              </div>
                              <Space>
                                <h3>{item.desc}</h3>
                              </Space>
                            </Card>
                          </List.Item>
                        )}
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Row>
    </div>
  );
};

export default InvestedView;
