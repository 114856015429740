import { useEffect } from 'react';

/**
 * useIdleLogout hook
 *
 * A hook to handle idle logout. It will call the onIdle function if the user is idle for the specified amount of time.
 * @param {function} onIdle - The function to call if the user is idle.
 * @param {number} idleTime - The amount of time in miliseconds before the user is considered idle.
 */
const useIdleLogout = (onIdle, idleTime = 30 * 60 * 1000) => {
    useEffect(() => {
        let idleTimeout;

        /**
         * Reset the idle timer
         */
        const resetTimer = () => {
            clearTimeout(idleTimeout);
            idleTimeout = setTimeout(onIdle, idleTime);
        };

        const events = [
            'mousemove', // triggered when the user moves the mouse
            'keydown', // triggered when the user presses a key
            'mousedown', // triggered when the user clicks the mouse
            'touchstart', // triggered when the user touches the screen
        ];

        events.forEach((event) => {
            window.addEventListener(event, resetTimer);
        });

        resetTimer();

        /**
         * Clean up the event listeners when the component is unmounted
         */
        return () => {
            clearTimeout(idleTimeout);
            events.forEach((event) => {
                window.removeEventListener(event, resetTimer);
            });
        };
    }, [onIdle, idleTime]);
};

export default useIdleLogout;