import React, { Fragment, useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import { Button, Form, Input } from "antd";
import { UserOutlined } from "@ant-design/icons";
import ArrowLeftOutlined from "@ant-design/icons/ArrowLeftOutlined";

import { getAdminOrganizersRequest } from "../../../adminTopOrganizers/redux/action";
import { useSelector, useDispatch } from "react-redux";
import { BASE_URL } from "../../../../api";

import "./style.scss";
import "../../../common/dealTypeSelectionModal/style.scss";
import { dealTypesDetails } from "../../../../constants";

const DealTypeForm = ({
  visible,
  handleConfirm,
  editedDealDetails,
  setFormData,
  prevStep,
  confirmPublish,
  isRequiredButton,
  setDealInfo,
  checkFormValidation,
  setCreateDealData,
  createDealData,
  createDealFormList,
  createDealConfirm,
}) => {
  const dispatch = useDispatch();
  const adminTopOrgarnizersList = useSelector(
    (state) => state.adminTopOrganizersList.data
  );
  const { deal_info } = useSelector(({ dealDetails: { data = {} } }) => data);

  const [dealTypeInfo, setDealTypeInfo] = useState({
    deal_type: "",
    service_organizer_id: "",
  });
  const [searchInput, setSearchInput] = useState("");
  const { deal_type = "", service_organizer_id = "" } = editedDealDetails || {};

  useEffect(() => {
    dispatch(getAdminOrganizersRequest());
  }, [dispatch]);

  useEffect(() => {
    const svcOrgAdmin = deal_info?.is_svc_org_admin ? "invest_tech" : "";

    setDealTypeInfo({
      deal_type: deal_type || deal_info?.deal_type || "",
      service_organizer_id:
        service_organizer_id ||
        deal_info?.service_organizer?.id ||
        svcOrgAdmin ||
        "",
    });
  }, [deal_info, editedDealDetails]);

  const handleClick = (id) => {
    setDealTypeInfo((prev) => {
      return {
        ...prev,
        service_organizer_id: id,
      };
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDealTypeInfo((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const dealInfo = useMemo(() => {
    return {
      deal_type: dealTypeInfo.deal_type,
      service_organizer_id:
        Number(dealTypeInfo.service_organizer_id) ||
        dealTypeInfo.service_organizer_id ||
        "",
    };
  }, [dealTypeInfo]);

  useEffect(() => {
    if (dealInfo && setCreateDealData) {
      setCreateDealData({
        ...createDealData,
        ...dealInfo,
      });
    }
  }, [dealInfo]);

  const onFinish = async () => {
    if (
      dealInfo.deal_type === "spv" &&
      dealInfo.service_organizer_id === "invest_tech"
    ) {
      setFormData();
      if (editedDealDetails) {
        if (dealInfo && setCreateDealData) {
          setCreateDealData({
            ...createDealData,
            ...dealInfo,
          });
        }
        createDealConfirm(editedDealDetails?.is_draft, "fromStep");
      }
    } else {
      setFormData();
      await confirmPublish(
        handleConfirm,
        checkFormValidation,
        createDealFormList
      );
    }
  };

  if (setDealInfo) {
    setDealInfo(dealTypeInfo);
  }

  const filteredUsers = useMemo(() => {
    let allData = adminTopOrgarnizersList?.filter((item) => {
      const lowerName = item.name?.toLowerCase();
      const lowerEmail = item.email?.toLowerCase();
      const searchInputLower = searchInput.toLowerCase();

      if (
        lowerName.includes(searchInputLower) ||
        lowerEmail.includes(searchInputLower)
      ) {
        return true;
      }
      return false;
    });

    allData = allData.map(({ name, ...item }) => {
      const [firstname = "", lastName = ""] = name.split(" ");
      return {
        ...item,
        name: `${firstname} ${lastName}`,
      };
    });
    return allData;
  }, [adminTopOrgarnizersList, searchInput]);

  return (
    <div
      name="validate_other"
      className={classNames("create-deal-form", {
        visible,
        hide: !visible,
      })}
    >
      <div className="head">
        <div className="header-deal-type">
          <label className="title">Deal Type </label>
          <Button
            className="dealtype-clear-state"
            type="default"
            onClick={() =>
              setDealTypeInfo({
                ...dealTypeInfo,
                deal_type: "",
              })
            }
          >
            Clear Selection
          </Button>
        </div>
        <span className="deal-type">
          {dealTypesDetails.map(({ value, title, service_organizer_id }) => {
            return (
              <Fragment key={service_organizer_id}>
                <input
                  type="radio"
                  id={value}
                  value={value}
                  checked={dealTypeInfo?.deal_type === value}
                  name="deal_type"
                  className="input"
                  onChange={handleChange}
                />
                <label for={value} className="label1">
                  {title}
                </label>
              </Fragment>
            );
          })}
        </span>
      </div>

      <div className="head">
        <div style={{ display: "flex", alignItems: "center" }}>
          <p className="heading">Select Your Service Organizer</p>
          <Button
            className="dealtype-clear-state"
            type="default"
            onClick={() =>
              setDealTypeInfo({
                ...dealTypeInfo,
                service_organizer_id: "",
              })
            }
          >
            Clear Selection
          </Button>
        </div>
        <div
          style={{
            padding: "0 0 10px 0",
          }}
        >
          <Input
            placeholder="search organizer ..."
            onChange={(e) => setSearchInput(e.target.value)}
            className="searchInput"
            style={{
              display: "inline-block",
              width: "190px",
              borderRadius: "0px",
              padding: "15px",
              marginBottom: "10px",
              fontWeight: "500",
            }}
          />
        </div>
        <div className="main-box">
          <div
            className={classNames("box", {
              "box-background":
                dealTypeInfo.service_organizer_id === "invest_tech",
            })}
            onClick={() => handleClick("invest_tech")}
          >
            <div className="detail-card">
              <div className="invest-tech-card">Invest Tech</div>
            </div>
          </div>
          {filteredUsers.map(({ service_organizer_id, logo, name, email }) => {
            const imageUrl = BASE_URL + logo;
            const dynamicStyle =
              String(service_organizer_id) ===
              String(dealTypeInfo.service_organizer_id);
            return (
              <div
                className={classNames("box", {
                  "box-background": dynamicStyle,
                })}
                onClick={() => handleClick(String(service_organizer_id))}
              >
                <div className="detail">
                  {!!logo ? (
                    <img src={imageUrl} alt="profile" />
                  ) : (
                    <UserOutlined />
                  )}
                  <p className="name">{name}</p>
                </div>
                <div className="detail">
                  <div className="name">Email:-</div>
                  <div className="email">{email}</div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {isRequiredButton && (
        <>
          <Form.Item className="space-between-row deal-type-selection">
            <Button onClick={prevStep}>
              <ArrowLeftOutlined /> Back
            </Button>
            <Button type="primary" htmlType="button" onClick={onFinish}>
              {dealTypeInfo.deal_type === "spv" &&
              dealTypeInfo?.service_organizer_id === "invest_tech"
                ? "Continue"
                : "Submit"}
            </Button>
          </Form.Item>
        </>
      )}
    </div>
  );
};
export default DealTypeForm;
