import { Modal } from "antd";
import { call, put, takeLatest } from "redux-saga/effects";
import { getUserDetailsByIDApi, getsubscribe, postsubscribe } from "../../api";

import {
  GET_USER_DETAILS_REQUEST,
  GET_USER_DETAILS_SUCCESS,
  GET_USER_DETAILS_ERROR,
  GET_SUBSCRIBE,
  GET_SUBSCRIBE_SUCCESS,
  POST_SUBSCRIBE,
  POST_SUBSCRIBE_SUCCESS,
} from "./constants";

function* requestUserDetails(action) {
  try {
    let { data } = yield call(getUserDetailsByIDApi, action?.data);
    if (data) {
      yield put({ type: GET_USER_DETAILS_SUCCESS, data });
    }
  } catch ({ response }) {
    if (response) {
      yield put({ type: GET_USER_DETAILS_ERROR, data: response?.data?.Error });
    } else {
      yield put({ type: GET_USER_DETAILS_ERROR, data: "Error" });
    }
  }
}

function* UserDetailsWatcher() {
  yield takeLatest(GET_USER_DETAILS_REQUEST, requestUserDetails);
}

function* getsubscribewatch(action) {
  try {
    let res = yield call(getsubscribe, action?.data);
    let data = res.data;
    if (data) {
      yield put({ type: GET_SUBSCRIBE_SUCCESS, data });
    }
  } catch ({ response }) {
    if (response) {
      yield put({ type: GET_USER_DETAILS_ERROR, data: response?.data?.Error });
    } else {
      yield put({ type: GET_USER_DETAILS_ERROR, data: "Error" });
    }
  }
}
function* postsubscribewatch(action) {
  try {
    let res = yield call(postsubscribe, action?.data);
    let data = res.data;
    if(data?.Msg){
      Modal.success({
        title: data?.Msg
      })
    }
    if (data) {
      yield put({ type: POST_SUBSCRIBE_SUCCESS, data });
      yield put({ type: GET_SUBSCRIBE });
    }
  } catch ({ response }) {
    if (response) {
      yield put({ type: GET_USER_DETAILS_ERROR, data: response?.data?.Error });
    } else {
      yield put({ type: GET_USER_DETAILS_ERROR, data: "Error" });
    }
  }
}

function* SubscribeWatcher() {
  yield takeLatest(GET_SUBSCRIBE, getsubscribewatch);
}
function* SubscribestatusWatcher() {
  yield takeLatest(POST_SUBSCRIBE, postsubscribewatch);
}

export { UserDetailsWatcher, SubscribeWatcher, SubscribestatusWatcher };
