import { call, put, takeLatest } from "redux-saga/effects";

import {
  getAdminIPInvestmentApi,
  updateIPInvestmentStatusApi,
} from "../../../api";
import {
  GET_ADMIN_IP_INVESTMENT_REQUEST,
  GET_ADMIN_IP_INVESTMENT_SUCCESS,
  GET_ADMIN_IP_INVESTMENT_ERROR,
  UPDATE_IP_INVESTMENT_STATUS_REQUEST,
} from "./constants";

function* getAdminIPInvestmentRequest() {
  try {
    let { data } = yield call(getAdminIPInvestmentApi);

    if (data) {
      yield put({ type: GET_ADMIN_IP_INVESTMENT_SUCCESS, data });
    }
  } catch (err) {
    yield put({ type: GET_ADMIN_IP_INVESTMENT_ERROR });
  }
}

function* updateIPInvestmentStatusRequest(action) {
  try {
    let { data } = yield call(updateIPInvestmentStatusApi, action?.data);

    if (data?.Msg) {
      yield put({ type: GET_ADMIN_IP_INVESTMENT_REQUEST });
    }
  } catch (err) {
    yield put({ type: GET_ADMIN_IP_INVESTMENT_ERROR });
  }
}

function* AdminIPInvestmentWatcher() {
  yield takeLatest(
    GET_ADMIN_IP_INVESTMENT_REQUEST,
    getAdminIPInvestmentRequest
  );
  yield takeLatest(
    UPDATE_IP_INVESTMENT_STATUS_REQUEST,
    updateIPInvestmentStatusRequest
  );
}
export default AdminIPInvestmentWatcher;
