import { Table, Tag } from "antd";
import React from "react";
import { userDetailsAction } from "../../../../redux/userDetail/action";
import { isOrganizer, isStartup, isSuperAdmin } from "../../../../Utils";
import { getUser } from "../../../../Utils/isAuth";

const investorsListColumns = [
  {
    title: "Investor",
    dataIndex: "investor",
  },
  {
    title: "Status",
    dataIndex: "status",
    render: (status) => {
      let color = status === "Completed" ? "#0b5394" : "#000";
      if (status === "Inactive") {
        color = "#F3F4F6";
      }
      return (
        <Tag className="tag_text" color={color}>
          {status}
        </Tag>
      );
    },
  },
  // {
  //   title: "Fee",
  //   dataIndex: "fee",
  // },
  // {
  //   title: "Carry",
  //   dataIndex: "carry",
  // },
  {
    title: "Update",
    dataIndex: "update",
  },
];

const InvestorsListTable = ({ data = [], dispatch, dealInfo = {} }) => {
  const user = getUser();
  
  const handleUserClick = (data) => {
    if (isStartup() || isSuperAdmin() || (dealInfo?.service_organizer?.user_id === user?.user_id && isOrganizer())) {
      dispatch(
        userDetailsAction({ id: data?.investor_id, deal_id: dealInfo?.id || "" })
      );
    }
  };

  return (
    <Table
      rowClassName={() => "editable-row"}
      pagination={false}
      dataSource={data}
      size="small"
      columns={investorsListColumns}
      onRow={record => ({
        onClick: () => handleUserClick(record)
      })}
    />
  );
};

export default InvestorsListTable;
