import { call, put, takeLatest } from "redux-saga/effects";
import {
  createDeal,
  deleteDocs,
  downloadDocs,
  getDealAllTitles,
  getOrganizerDealDetails,
  sectors,
  signDoc,
  updateDeal,
  uploadDocs,
} from "../../../api";

import { getToken } from "../../../Utils/isAuth";
import {
  CREATE_DEAL_ERROR,
  CREATE_DEAL_REQUEST,
  CREATE_DEAL_SUCCESS,
  DEAL_TITLES_ERROR,
  DEAL_TITLES_REQUEST,
  DEAL_TITLES_SUCCESS,
  DELETE_SPV_DOCS_ERROR,
  DELETE_SPV_DOCS_REQUEST,
  DELETE_SPV_DOCS_SUCCESS,
  DOWLOAD_SPV_DOCS_ERROR,
  DOWLOAD_SPV_DOCS_REQUEST,
  DOWLOAD_SPV_DOCS_SUCCESS,
  EDIT_DEAL_DETAILS_ERROR,
  EDIT_DEAL_DETAILS_REQUEST,
  EDIT_DEAL_DETAILS_SUCCESS,
  GET_SECTORS_ERROR,
  GET_SECTORS_REQUEST,
  GET_SECTORS_SUCCESS,
  SIGN_DOCS_OPEN_ERROR,
  SIGN_DOCS_OPEN_REQUEST,
  SIGN_DOCS_OPEN_SUCCESS,
  UPDATE_DEAL_ERROR,
  UPDATE_DEAL_ON_ERROR,
  UPDATE_DEAL_ON_REQUEST,
  UPDATE_DEAL_ON_SUCCESS,
  UPDATE_DEAL_REQUEST,
  UPDATE_DEAL_SUCCESS,
  UPLOAD_SPV_DOCS_ERROR,
  UPLOAD_SPV_DOCS_REQUEST,
  UPLOAD_SPV_DOCS_SUCCESS,
} from "./constants";

function* requestCreateDeal(action) {
  try {
    const token = getToken();
    let { data } = yield call(createDeal, token, action?.data);

    if (data) {
      yield put({ type: CREATE_DEAL_SUCCESS, data });
    }
  } catch ({ response }) {
    if (response) {
      yield put({
        type: CREATE_DEAL_ERROR,
        data:
          response?.data?.Error ||
          response?.data?.errors ||
          response?.data?.error,
      });
    } else {
      yield put({
        type: CREATE_DEAL_ERROR,
        data: "Something went wrong, Please try again !!",
      });
    }
  }
}

function* requestUpdateDeal(action) {
  try {
    const token = getToken();
    let { data } = yield call(updateDeal, token, action?.data);

    if (data) {
      yield put({ type: UPDATE_DEAL_SUCCESS, data });
    }
  } catch ({ response }) {
    if (response) {
      yield put({
        type: UPDATE_DEAL_ERROR,
        data:
          response?.data?.Error ||
          response?.data?.errors ||
          response?.data?.error,
      });
    } else {
      yield put({
        type: UPDATE_DEAL_ERROR,
        data: "Something went wrong, Please try again !!",
      });
    }
  }
}

function* requestUpdateDealOnStep(action) {
  try {
    const token = getToken();
    let { data } = yield call(updateDeal, token, action?.data);

    if (data) {
      yield put({ type: UPDATE_DEAL_ON_SUCCESS, data });
    }
  } catch ({ response }) {
    if (response) {
      yield put({
        type: UPDATE_DEAL_ON_ERROR,
        data:
          response?.data?.Error ||
          response?.data?.errors ||
          response?.data?.error,
      });
    } else {
      yield put({
        type: UPDATE_DEAL_ON_ERROR,
        data: "Something went wrong, Please try again !!",
      });
    }
  }
}
function* requestDownloadSpvDocs(action) {
  try {
    const token = getToken();
    let { data } = yield call(downloadDocs, token);

    if (data) {
      yield put({ type: DOWLOAD_SPV_DOCS_SUCCESS, data });
    }
  } catch (err) {
    yield put({ type: DOWLOAD_SPV_DOCS_ERROR });
  }
}

function* requestUploadSpvDocs(action) {
  try {
    const token = getToken();
    const { data } = yield call(uploadDocs, token, action?.data);

    if (data) {
      yield put({ type: UPLOAD_SPV_DOCS_SUCCESS });
    }
  } catch (err) {
    yield put({ type: UPLOAD_SPV_DOCS_ERROR });
  }
}

function* requestDeleteSpvDocs(action) {
  try {
    const token = getToken();
    const { data } = yield call(deleteDocs, token, action?.data);

    if (data) {
      if (action.data.eid) {
        localStorage.setItem(action.data.doc_type, "delete");
      }
      yield put({ type: DELETE_SPV_DOCS_SUCCESS, data: action.data });
    }
  } catch (err) {
    yield put({ type: DELETE_SPV_DOCS_ERROR });
  }
}

function* requestSignDocDocs(action) {
  try {
    const token = getToken();
    let { data } = yield call(signDoc, token, action?.data?.formData);

    if (data) {
      yield put({
        type: SIGN_DOCS_OPEN_SUCCESS,
        data: {
          ...data,
          key: action?.data?.name,
        },
      });
    }
  } catch (err) {
    yield put({ type: SIGN_DOCS_OPEN_ERROR });
  }
}

function* requestGetSectors(action) {
  try {
    const token = getToken();
    const { data } = yield call(sectors, token);

    if (data) {
      yield put({
        type: GET_SECTORS_SUCCESS,
        data,
      });
    }
  } catch (err) {
    yield put({ type: GET_SECTORS_ERROR });
  }
}

function* requestEditDealDetails(action) {
  try {
    let { data } = yield call(getOrganizerDealDetails, action?.data);

    if (data) {
      yield put({ type: EDIT_DEAL_DETAILS_SUCCESS, data });
    }
  } catch (err) {
    yield put({ type: EDIT_DEAL_DETAILS_ERROR });
  }
}

function* requestDealTitles(action) {
  try {
    let { data } = yield call(getDealAllTitles, action?.data);

    if (data) {
      yield put({ type: DEAL_TITLES_SUCCESS, data });
    }
  } catch (err) {
    yield put({ type: DEAL_TITLES_ERROR });
  }
}

function* CreateDealWatcher() {
  yield takeLatest(CREATE_DEAL_REQUEST, requestCreateDeal);
  yield takeLatest(DOWLOAD_SPV_DOCS_REQUEST, requestDownloadSpvDocs);
  yield takeLatest(UPLOAD_SPV_DOCS_REQUEST, requestUploadSpvDocs);
  yield takeLatest(DELETE_SPV_DOCS_REQUEST, requestDeleteSpvDocs);
  yield takeLatest(SIGN_DOCS_OPEN_REQUEST, requestSignDocDocs);
  yield takeLatest(GET_SECTORS_REQUEST, requestGetSectors);
  yield takeLatest(UPDATE_DEAL_REQUEST, requestUpdateDeal);
  yield takeLatest(UPDATE_DEAL_ON_REQUEST, requestUpdateDealOnStep);
  yield takeLatest(DEAL_TITLES_REQUEST, requestDealTitles);

  yield takeLatest(EDIT_DEAL_DETAILS_REQUEST, requestEditDealDetails);
}
export default CreateDealWatcher;
