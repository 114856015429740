import { call, put, takeLatest } from "redux-saga/effects";

import { getAdminDealList, adminDealUpdate } from "../../../api";
import {
  GET_DEALS_LIST_REQUEST,
  GET_DEALS_LIST_REQUEST_SUCCESS,
  GET_DEALS_LIST_REQUEST_ERROR,
  ADMIN_DEAL_UPDATE_REQUEST,
  ADMIN_DEAL_UPDATE_SUCCESS,
  ADMIN_DEAL_UPDATE_ERROR,
} from "./constants";

function* getAdminDealsListRequest(action) {
  try {
    let { data } = yield call(getAdminDealList, action?.data);

    if (data) {
      yield put({ type: GET_DEALS_LIST_REQUEST_SUCCESS, data });
    }
  } catch (err) {
    yield put({ type: GET_DEALS_LIST_REQUEST_ERROR });
  }
}

function* adminDealUpdateRequest(action) {
  try {
    let { data } = yield call(adminDealUpdate, action?.data);

    if (data.Msg) {
      yield put({ type: ADMIN_DEAL_UPDATE_SUCCESS });
      yield put({ type: GET_DEALS_LIST_REQUEST });
    }
  } catch (err) {
    yield put({ type: ADMIN_DEAL_UPDATE_ERROR });
  }
}

function* AdminDealsListWatcher() {
  yield takeLatest(GET_DEALS_LIST_REQUEST, getAdminDealsListRequest);
  yield takeLatest(ADMIN_DEAL_UPDATE_REQUEST, adminDealUpdateRequest);
}
export default AdminDealsListWatcher;
