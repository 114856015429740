import React, { useCallback, useState } from "react";
import "antd/dist/antd.css";
import "./style.scss";
import {
  Button,
  Layout,
  Typography,
  Spin,
  Input,
  Col,
  Row,
  Radio,
  Card,
  Divider,
  Modal,
  Form,
  InputNumber,
  message,
} from "antd";
import _ from "lodash";
import ArrowLeftOutlined from "@ant-design/icons/ArrowLeftOutlined";
import ExclamationCircleOutlined from "@ant-design/icons/ExclamationCircleOutlined";
import Logo from "../../assets/images/main-logo-svg.svg";

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LoadingOutlined } from "@ant-design/icons";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  clearInvestError,
  clearInvestStore,
  createIPInvest,
  getIPInvest,
  setIsInvestedFalse,
} from "./redux/action";
import CheckOutlined from "@ant-design/icons/CheckOutlined";
import { numberFormatter } from "../../Utils";
import { useMediaQuery } from "react-responsive";

const { error, success } = Modal;

const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;

const { Header, Content } = Layout;
const { Text } = Typography;

const BANK_ACC = [
  {
    value: "bank_name",
    title: "Bank Name",
    isRequired: true,
  },
  {
    value: "holder_name",
    title: "Holder Name",
    isRequired: true,
  },
  {
    value: "swift_code",
    title: "Swift Code",
    isRequired: true,
  },
  {
    value: "iban_number",
    title: "Iban Number",
    isRequired: true,
  },
  {
    value: "company_name",
    title: "Company Name",
  },
  {
    value: "bank_address",
    title: "Bank Address",
  },
  {
    value: "routing_number",
    title: "Routing Number",
  },
];

const AddingModal = ({ isModalVisible, handleOk, handleCancel, colums }) => {
  const [form] = Form.useForm();

  const modalFields = colums.map((el) => {
    return (
      <Form.Item
        key={el.dataIndex}
        label={el.title}
        rules={[
          {
            required: el.isRequired,
            message: `${el.title} is required`,
          },
        ]}
        name={el.value}
        required={el.isRequired}
      >
        <Input
          type={
            el.value === "round_name" || el.value === "comments"
              ? "number"
              : "text"
          }
        />
      </Form.Item>
    );
  });

  const handleOkClick = (data) => {
    handleOk(data);
    form.resetFields();
  };

  return (
    <>
      <Modal
        title="Bank Account"
        visible={isModalVisible}
        onOk={() => form.submit()}
        onCancel={() => {
          form.resetFields();
          handleCancel();
        }}
        okText="Save"
        className="modal-form"
        centered
        width={430}
      >
        <Row
          gutter={[0, 16]}
          className="modal-form__body modal-form__invest-body"
        >
          <Form layout="vertical" form={form} onFinish={handleOkClick}>
            {modalFields}
          </Form>
        </Row>
      </Modal>
    </>
  );
};

const showError = (errorMessage) => {
  error({
    title: "Error",
    content: errorMessage,
    icon: <ExclamationCircleOutlined />,
    centered: true,

    onOk() {
      console.log("OK");
    },
  });
};

const IPInvestPage = () => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const { loading, errorMessage, successMessage, ipDetails } = useSelector(
    (state) => state.invest
  );

  const [, setCollapsed] = useState(false);
  const [bankAccount, setBankAccount] = useState(1);
  const [price, setPrice] = useState(() => ipDetails.price);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [bankInfo, setBankInfo] = useState(null);
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");

  const isLowerScreen = useMediaQuery({
    query: "(max-width:1200px)",
  });

  useEffect(() => {
    if (ipDetails.price) {
      setPrice(ipDetails.price);
    }
    if (ipDetails.bank_account) {
      setBankInfo(ipDetails.bank_account);
    }
  }, [ipDetails.price, ipDetails.bank_account]);

  useEffect(() => {
    dispatch(getIPInvest(id));
  }, [id, dispatch]);

  const goToHome = useCallback(() => {
    history("/ips");
    dispatch(setIsInvestedFalse());
  }, [history, dispatch]);

  const showConfirmToHome = () => {
    success({
      title: "IP confirmed!",
      icon: <CheckOutlined />,
      centered: true,
      content: `The next step after approve by our legal team will be: \n
        Transfering money to our bank account. \n
        Review from our manager (1-2 days)11.`,
      className: "invest-modal",
      onOk() {
        history(`/ipdetails/${id}`);
        dispatch(setIsInvestedFalse());
      },
      okText: "Back to ips",
    });
  };

  //   useEffect(() => {
  //     if (
  //       _.isEmpty(dealDetails) ||
  //       dealDetails === null ||
  //       dealDetails === undefined
  //     ) {
  //       history(-1);
  //     }
  //   }, [dealDetails, history]);

  const confirmInvest = () => {
    if (Object.keys(bankInfo).length === 0) {
      message.error("Please add payment method to proceed further");
      return;
    }
    const formData = new FormData();

    formData.append("buy_price", price);
    formData.append("id", id);
    formData.append("country_region", ipDetails.country_region);

    if (bankInfo?.iban_number !== ipDetails?.bank_info?.iban_number) {
      const keys = Object.keys(bankInfo);

      keys.forEach((key) => {
        formData.append(key, bankInfo[key]);
      });
    }

    dispatch(createIPInvest(formData));
  };

  useEffect(() => {
    if (successMessage) {
      showConfirmToHome(goToHome);
    }
  }, [successMessage, dispatch, goToHome]);

  useEffect(() => {
    if (errorMessage) {
      showError(errorMessage);

      dispatch(clearInvestError());
    }
  }, [errorMessage, dispatch]);

  useEffect(() => {
    isLowerScreen && setCollapsed(true);
    return () => {
      dispatch(clearInvestStore());
    };
  }, [dispatch, isLowerScreen]);

  return (
    <>
      <Layout className="invest">
        {loading && (
          <div className="spinner_background">
            <Spin className="spinner" indicator={antIcon} />
          </div>
        )}
        <AddingModal
          isModalVisible={isModalVisible}
          handleOk={(data) => {
            setBankInfo({ ...data });
            setIsModalVisible(false);
          }}
          handleCancel={() => {
            setIsModalVisible(false);
          }}
          colums={BANK_ACC}
        />
        <Header className="invest__header">
          <div className="header-layout">
            <div className="left">
              <div
                className="header-brand"
                onClick={() => history("/")}
                style={{ cursor: "pointer" }}
              >
                <img src={Logo} alt="Logo" />
              </div>

              <Button
                onClick={() => {
                  history(`/ipdetails/${id}`);
                  dispatch(setIsInvestedFalse());
                }}
              >
                <ArrowLeftOutlined /> Back
              </Button>
            </div>
          </div>
        </Header>

        <Layout>
          <Content className="invest__content">
            <Row gutter={[0, 16]} className="content-row">
              <Col span={24}>
                <h2 className="financial_title">{ipDetails?.ip_name}</h2>
              </Col>
            </Row>
            <Row gutter={[0, 16]} className="content-row">
              <Col span={24}>
                <Text className="lable">Price</Text>
                <InputNumber
                  onChange={(e) => {}}
                  className="currency-input input-number-style"
                  addonAfter={ipDetails?.currency}
                  value={price}
                  disabled={true}
                  formatter={numberFormatter.formatter}
                  parser={numberFormatter.parser}
                />
              </Col>
            </Row>
            <Row gutter={[0, 16]} className="content-row">
              {!_.isEmpty(bankInfo) &&
              bankInfo !== null &&
              bankInfo !== undefined ? (
                <Col span={24} className={"bank-cards"}>
                  <Text className="lable">Bank Account</Text>
                  <Radio.Group
                    name="radiogroup"
                    defaultValue={bankAccount}
                    onChange={(e) => setBankAccount(e.target.value)}
                  >
                    <Card
                      className={
                        bankAccount === 1
                          ? `onboarding-card-clicked`
                          : `onboarding-card`
                      }
                      title={
                        <div className="card-content-identity">
                          <Radio value={1}>
                            <div className="radio-label">
                              <Text className="lable">{`${bankInfo?.bank_name} ${bankInfo?.holder_name}`}</Text>
                              <small className="lable-second">{`${bankInfo?.iban_number}`}</small>
                            </div>
                          </Radio>
                        </div>
                      }
                      extra={
                        <Button
                          type="link"
                          onClick={() => {
                            setBankInfo(null);
                          }}
                        >
                          Remove
                        </Button>
                      }
                      bodyStyle={{ padding: "0" }}
                    />
                  </Radio.Group>
                </Col>
              ) : (
                <Col
                  span={24}
                  style={{
                    justifyContent: "center",
                    display: "flex",
                  }}
                >
                  <Button
                    onClick={() => setIsModalVisible(true)}
                    style={{
                      color: "#0B5394",
                    }}
                  >
                    + Add new payment method{" "}
                    <span className="required-star required-star-ext">*</span>
                  </Button>
                </Col>
              )}
            </Row>
            <Row gutter={[0, 16]} className="content-row">
              <Col span={24}>
                <Divider />
              </Col>
            </Row>

            <Row gutter={[0, 16]} className="content-row">
              <Col span={24}>
                <Button
                  type={!bankInfo ? "default" : "primary"}
                  style={{
                    width: "100%",
                  }}
                  onClick={() => confirmInvest()}
                  disabled={!bankInfo}
                  title={!bankInfo ? "Add payment method to continue" : ""}
                >
                  Confirm
                </Button>
              </Col>
            </Row>
          </Content>
        </Layout>
      </Layout>
    </>
  );
};

export default IPInvestPage;
