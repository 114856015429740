import {
  Avatar,
  Button,
  Col,
  Grid,
  Image,
  Row,
  Tooltip,
  Typography,
} from "antd";

import {
  EditFilled,
  EyeOutlined,
  InfoCircleOutlined
} from '@ant-design/icons';

import { Link } from "react-router-dom";

import FallbackImg from "../../../assets/images/default-fallback-image.png";
import useDealHeader from "./useDealHeader";
import AccessButton from "./AccessButtons";

const { Title, Paragraph } = Typography;

function DealDetailHeader({
  projectData = {},
  userRole,
  displayDataRoom,
  setDisplayDataRoom,
  setShowProjectDataFiles,
  showProjectDataFiles,
  setShowLogs,
  showProjectLogs,
  countViews

}) {

  const {
    handleRequestAccess,
    userHasAccessProject,
    SectorTags,
    getImageLink,
    userHasFilesAccess,
    loading,
    logo,
    projectName,
    websiteLink,
    description,
    userEmail,
  } = useDealHeader(projectData, userRole)
  const screens = Grid.useBreakpoint();

  return (
    <>
      <Row
        justify="space-between"
        align="middle"
        gutter={[0, 0]}
        className="deal-details-header-row"
      >
        <Col md={18} sm={24} xs={24}>
          <Avatar.Group>
            <div className={`deal-logo`}>
              <Image
                width={60}
                src={
                  logo
                    ? getImageLink(projectData?.imagesPath, logo)
                    : FallbackImg
                }
                style={{
                  marginRight: "15px",
                }}
              />
            </div>

            <div style={{ width: "100%" }}>
              <Row align="middle" gutter={[0, 0]}>
                <Col
                  style={{
                    flexWrap: "wrap",
                  }}
                  md={24}
                  sm={24}
                  display={"flex"}
                  className="top-title"
                >
                  <Title
                    level={4}
                  >
                    {projectName}
                    {
                      (userRole === "Admin" || userEmail?.includes("dkv.global") || (projectData?.currentUserPermission && projectData?.currentUserPermission !== "READONLY") ) &&
                      <Link to={`/edit-projects/${projectData?._id}`}><EditFilled /></Link>
                    }
                    <br />
                    <div style={{ display: "flex", alignItems: "center", fontSize: "14px", gap: "5px", color: "#6b7280", fontWeight: "400" }}>
                      <EyeOutlined fill="blue" />
                      {countViews} viewed this project
                      <span style={{ color: "#6b7280", fontSize: "10px", fontWeight: "400" }}>(Since we started recording views)</span>
                    </div>
                  </Title>

                  <div>
                    <SectorTags />
                  </div>
                </Col>
              </Row>
            </div>
          </Avatar.Group>
          <Paragraph
            ellipsis={{ rows: 3, expandable: true, symbol: "more" }}
            style={{ color: "#6b7280", marginTop: "5px", maxWidth:"100%" }}
          >
            {description}
          </Paragraph>
        </Col>
        <Col
          md={6}
          sm={24}
          xs={24}
          style={{
            display: "flex",
            justifyContent: `${screens.md ? "flex-end" : "center"}`,
            marginTop: `${screens.xs ? "5px" : ""}`,
          }}
        >
          <div style={{
            display: "flex",
            alignItems: "",
            gap: "1rem",
            flexDirection: "column",
            marginBottom:"1rem",
            width: `${ screens.md ? "fit-content" : "100%" }`
          }}>
            <AccessButton
              accessType="data_room"
              userHasFilesAccess={userHasFilesAccess}
              userHasAccessProject={userHasAccessProject}
              showProjectDataFiles={showProjectDataFiles}
              setShowProjectDataFiles={setShowProjectDataFiles}
              handleRequestAccess={handleRequestAccess}
              loading={loading}
            />
            <AccessButton
              accessType="tier_5_data"
              userHasFilesAccess={userHasFilesAccess}
              userHasAccessProject={userHasAccessProject}
              showProjectDataFiles={showProjectDataFiles}
              setShowProjectDataFiles={setShowProjectDataFiles}
              handleRequestAccess={handleRequestAccess}
              loading={loading}
            />
            {
            userHasAccessProject() && <Button type="primary"
            onClick={() => setShowProjectDataFiles((prev) => prev === "pricing_documents" ? null : "pricing_documents")}>
              { showProjectDataFiles === "pricing_documents" ? "Hide" : "Show" } Pricing
            </Button>
            }
            {
              showProjectDataFiles &&
              <Button onClick={() => setShowProjectDataFiles(null)}>
                Clear 
              </Button>
            }
            <Button type="primary" onClick={() => setShowLogs(!showProjectLogs)}>
              {showProjectLogs ? "Hide" : "Show"} Logs
            </Button>
          </div>
        </Col>
        {websiteLink && (
          <Button
            type="primary"
            onClick={() => window.open(websiteLink, "_blank")}
          >
            Go To Website
          </Button>
        )}
      </Row>
    </>
  );
}

export default DealDetailHeader;
