import {
  CLEAR_INVEST_ERROR,
  GET_DEAL_INVESTING_REQUEST,
  INVEST_DEAL_REQUEST,
  SIGN_INVEST_DOCS_OPEN_REQUEST,
  INVEST_DEAL_INVEST_FALSE,
  CLEAR_INVEST_STORE,
  IP_INVEST_REQUEST,
  CREATE_IP_INVEST_REQUEST,
  GET_ESCROW_REQUEST,
  CREATE_ESCROW_REQUEST,
  SIGN_INVEST_DOCS_CLOSE,
} from "./constants";

export const getDealInvesting = (data) => {
  return {
    type: GET_DEAL_INVESTING_REQUEST,
    data,
  };
};

export const signInvestDocsRequest = (data) => {
  return {
    type: SIGN_INVEST_DOCS_OPEN_REQUEST,
    data,
  };
};

export const investDeal = (data) => {
  return {
    type: INVEST_DEAL_REQUEST,
    data,
  };
};

export const getEscrowData = (data) => {
  return {
    type: GET_ESCROW_REQUEST,
    data,
  };
};

export const createEscrowData = (data) => {
  return {
    type: CREATE_ESCROW_REQUEST,
    data,
  };
};

export const clearInvestError = () => {
  return {
    type: CLEAR_INVEST_ERROR,
  };
};

export const clearInvestStore = () => {
  return {
    type: CLEAR_INVEST_STORE,
  };
};

export const setIsInvestedFalse = () => {
  return {
    type: INVEST_DEAL_INVEST_FALSE,
  };
};

export const getIPInvest = (id) => {
  return {
    type: IP_INVEST_REQUEST,
    id,
  };
};

export const createIPInvest = (data) => {
  return {
    type: CREATE_IP_INVEST_REQUEST,
    data,
  };
};

export const closeSignModal = () => ({ action: SIGN_INVEST_DOCS_CLOSE });
