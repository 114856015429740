import React from "react";
import { Col, List, Row, Typography } from "antd";
import Title from "antd/lib/typography/Title";
import { getTitleForDetails, getValueForDetailsFromKey } from "../../constant";

const { Text } = Typography;

const DetailsTab = ({ data }) => {
  return (
    <List
      size="small"
      bordered={false}
      header={
        <Row className="header-left">
          <Col sm={24} className="flex-v-h-c flex-center">
            {data?.logo && (
              <img
                src={"https://platform.dkv.global/files/" + data.logo}
                alt="img"
                className="img"
              />
            )}
          </Col>
        </Row>
      }
      footer={<Text>{data?.description}</Text>}
      dataSource={Object.entries(data).filter(
        (item) => item[0] !== "id" && item[0] !== "logo" && item[0] !== "slug"
      )}
      renderItem={(item) => {
        const [key, value] = item;
        if (key === "description") {
          return;
        }
        return (
          <List.Item className="flex-v-h-c justify-flex-start">
            <Col sm={6} md={8} xl={8}></Col>
            <Col sm={6}>
              <Title level={5}>{getTitleForDetails(key) || key}</Title>
            </Col>
            <Col sm={8}>
              :&nbsp;&nbsp;
              {getValueForDetailsFromKey(key, value) || <Text>{value}</Text>}
            </Col>
            <Col sm={2}></Col>
          </List.Item>
        );
      }}
    />
  );
};

export default DetailsTab;
