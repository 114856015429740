import React, { useEffect, useMemo, useState } from "react";

import { Steps } from "antd";

import "./index.scss";

import { startupSteps } from "./usersSteps/startupSteps";
import { investorSteps } from "./usersSteps/investorSteps";
import { facilitatorSteps } from "./usersSteps/facilitatorSteps";
import { organizerSteps } from "./usersSteps/organizerSteps";

import { isInvestor, isOrganizer, isStartup, isFacilitator } from "../../Utils";
import { getUserFlow } from "./redux/action";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

function UserJourney() {
  const [current, setCurrent] = useState(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userFlow } = useSelector((state) => state.userFlow);

  const onChange = (value) => {
    setCurrent(value);
  };

  useEffect(() => {
    dispatch(getUserFlow());
  }, []);

  useEffect(() => {
    if (userFlow) {
      const steps = Object.keys(userFlow);

      for (let i = 0; i < steps.length; i++) {
        const values = Object.values(userFlow?.[steps[i]]);
        if (values.some((value) => value === false)) {
          setCurrent(i);
          return;
        }
      }
    }
  }, [userFlow]);

  const steps = useMemo(() => {
    if (isInvestor()) {
      return investorSteps(userFlow, navigate, current);
    }
    if (isOrganizer()) {
      return organizerSteps(userFlow, navigate, current);
    }
    if (isStartup()) {
      let calculatePercentageData = userFlow?.explore_investtech;
      if (calculatePercentageData?.hasOwnProperty("visited_investor_search")) {
        delete calculatePercentageData?.visited_investor_search;
        calculatePercentageData.visited_irm =
          !!userFlow?.engage_with_investors?.visited_irm;
      }
      return startupSteps(userFlow, navigate, current, calculatePercentageData);
    }
    if (isFacilitator()) {
      return facilitatorSteps(userFlow, navigate, current);
    }
  }, [userFlow, current]);

  return (
    <div>
      <Steps
        size="small"
        current={current}
        percent={steps[current]?.percent}
        onChange={onChange}
        className="site-navigation-steps"
        items={steps}
      />
      <div className="steps-content">{steps[current].content}</div>
    </div>
  );
}

export default UserJourney;
