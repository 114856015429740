import React from "react";
import { Line } from "@ant-design/plots";

const LinePlot = ({
  data = [
    {
      month: "June",
      year: 2022,
      amount: 0,
    },
  ],
}) => {
  const config = {
    data,
    padding: "auto",
    xField: "month",
    yField: "amount",
    xAxis: {
      tickCount: 5,
    },
  };
  return <Line {...config} />;
};

export default LinePlot;
