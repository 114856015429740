import { Button, Col, Divider, message } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ExactPage,
  forgotPasswordRequest,
  forgotPasswordStateInitial,
} from "../../redux/auth/action";
import email_img from "../../assets/images/email.svg";
import { useState } from "react";
import { forgotPassword } from "../../api";

function ResetPassword() {
  const [resendDone, setResendDone] = useState(false);
  const dispatch = useDispatch();
  const authDetails = useSelector((state) => state.auth);
  const email = localStorage.getItem("recoveryEmail");

  useEffect(() => {
    if (resendDone) {
      if (authDetails.forgotPasswordSuccess) {
        dispatch(ExactPage(4));
        setResendDone(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authDetails.forgotPasswordSuccess, resendDone]);

  const handleLogin = (e) => {
    e.preventDefault();

    setResendDone(true);
    dispatch(forgotPasswordStateInitial());
    dispatch(ExactPage(0));
  };

  const handleResend = () => {
    forgotPassword({ email: email })
      .then((res) => {
        message.success(res.data.message);
      })
      .catch((err) => {
        message.error(
          err.response?.data?.message ||
            "Something went wrong, Please try again !!"
        );
      });
  };
  return (
    <Col span={16} offset={4} className="rm-offset">
      <div className="email-container">
        <div className="verify-email-text">Forgot Your Password?</div>

        <div className="verify_email">
          <img
            src={email_img}
            alt="verify email"
            style={{ marginBottom: "35px" }}
          />
          <div className="reset-link-instruction">
            We sent a recovery link to your email <br />
            <b>{email || "-"}</b>
          </div>
          <Divider />

          <div className="password-reset-link">
            <p>
              <Button
                type="link"
                htmlType="submit"
                onClick={handleLogin}
                className="resend_link"
              >
                Return to log in
              </Button>{" "}
              <Button
                type="link"
                htmlType="submit"
                onClick={handleResend}
                className="resend_link"
              >
                Resend recovery link
              </Button>
            </p>{" "}
          </div>
        </div>
      </div>
    </Col>
  );
}

export default ResetPassword;
