export const getNextSteps = (current, onboardingDone, sub_type_of_account) => {
  let nextNumb = current + 1;
  const blokingSteps = [];

  if (onboardingDone?.bank_account === "True") {
    blokingSteps.push(3);
  }

  if (onboardingDone?.id_proof === "True") {
    blokingSteps.push(2);
  }
  if (onboardingDone?.profile === "True") {
    blokingSteps.push(1);
  }
  if (onboardingDone?.questionnaire === "True") {
    blokingSteps.push(4);
  }
  if (onboardingDone?.source_of_funds === "True") {
    blokingSteps.push(5);
  }
  if (sub_type_of_account) {
    blokingSteps.push(0);
  }

  if (onboardingDone?.company_profile === "True") {
    blokingSteps.push(1);
  }
  if (onboardingDone?.kyb === "True") {
    blokingSteps.push(2);
  }

  while (blokingSteps.indexOf(nextNumb) !== -1) {
    nextNumb++;

    if (nextNumb > 5) {
      break;
    }
  }

  return nextNumb;
};
