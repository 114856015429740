import {
  GET_SUMSUB_TOKEN_SUCCESS,
  GET_SUMSUB_TOKEN_REQUEST,
  GET_SUMSUB_TOKEN_ERROR,
} from "./constants";

const initialState = {
  sumSubToken: null,
};

export default function sumSubReducer(state = initialState, action) {
  switch (action.type) {
    case GET_SUMSUB_TOKEN_REQUEST:
      return { ...state, sumSubToken: null, loading: true };
    case GET_SUMSUB_TOKEN_SUCCESS:
      return {
        ...initialState,
        sumSubToken: action.data,
        loading: false,
      };
    case GET_SUMSUB_TOKEN_ERROR:
      return {
        ...state,
        loading: false,
        errorMessage: action.data,
      };

    default:
      return state;
  }
}
