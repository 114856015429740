import {
  PUBLISH_IP_ERROR,
  PUBLISH_IP_REQUEST,
  PUBLISH_IP_SUCCESS,
  GET_IP_DETAILS_REQUEST,
  GET_IP_DETAILS_SUCCESS,
  GET_IP_DETAILS_ERROR,
  GET_IP_DATA_ROOM_SUCCESS,
  GET_IP_DATA_ROOM_ERROR,
  GET_IP_DATA_ROOM_REQUEST,
  ASKED_PERMISSION_TO_DOCUMENTS_SUCCESS_IP,
  GIVE_PERMISSION_TO_DOCUMENTS_IP_SUCCESS,
  GIVE_PERMISSION_TO_DOCUMENTS_IP_REQUEST,
  CLEAR_USER_IP_DOC,
  UPLOAD_SIGN_ANY_IP_DOCS_REQUEST,
  UPLOAD_SIGN_ANY_IP_DOCS_SUCCESS,
  UPLOAD_SIGN_ANY_IP_DOCS_ERROR,
  SIGN_ANY_IP_DOCUMENT_SUCCESS,
  SIGN_ANY_IP_DOCUMENT_REQUEST,
  SIGN_ANY_IP_DOCUMENT_ERROR,
  DELETE_ANY_IP_DOCUMENT_REQUEST,
  DELETE_ANY_IP_DOCUMENT_ERROR,
  GET_INVESTOR_IP_DETAILS_REQUEST,
  GET_INVESTOR_IP_DETAILS_SUCCESS,
  GET_INVESTOR_IP_DETAILS_ERROR,
  CLEAR_IPDETAILS,
  UNPUBLISH_IP_REQUEST,
  UNPUBLISH_IP_SUCCESS,
  UNPUBLISH_IP_ERROR,
} from "./constants";

const initialState = {
  loading: false,
  published: false,
  data: {
    loading: false,
  },
  documents: { userDocuments: {}, loading: false },
  allUsers: {
    loading: true,
    users: [],
    errorMessage: "",
  },
  permissions: {
    permissionsAsked: [],
    loading: false,
  },
  envelopeUrl: null,
  pending: null,
  signed: [],
  ips: {},
  msg: "",
};

export default function ipDetail(state = initialState, action) {
  switch (action.type) {
    case GET_IP_DATA_ROOM_REQUEST:
      return {
        ...state,
        documents: { ...state?.documents, loading: true },
      };
    case GET_IP_DETAILS_REQUEST:
      return {
        ...state,
        data: { ...state.data, loading: true },
      };
    case DELETE_ANY_IP_DOCUMENT_REQUEST:
    case SIGN_ANY_IP_DOCUMENT_REQUEST:
    case GET_INVESTOR_IP_DETAILS_REQUEST:
      return {
        ...state,
        ips: { ...state.data, loading: true },
      };
    case PUBLISH_IP_REQUEST:
    case UNPUBLISH_IP_REQUEST:
      return { ...state, loading: true };
    case PUBLISH_IP_SUCCESS:
      return {
        ...state,
        published: true,
        loading: false,
        msg: action.data,
      };
    case UNPUBLISH_IP_SUCCESS:
      return {
        ...state,
        published: false,
        loading: false,
        msg: action.data,
      };
    case UNPUBLISH_IP_ERROR:
      return {
        ...state,
        loading: false,
      };
    case GET_IP_DATA_ROOM_SUCCESS:
      return {
        ...state,
        documents: {
          ...state?.documents,
          userDocuments: action.data,
          loading: false,
        },
      };
    case GET_IP_DETAILS_SUCCESS:
      return {
        ...state,
        data: { ...action.data, loading: false },
        loading: false,
      };
    case GET_INVESTOR_IP_DETAILS_SUCCESS:
      return {
        ...state,
        ips: { ...action.data, loading: false },
        loading: false,
      };
    case GET_IP_DATA_ROOM_ERROR:
      return {
        ...state,
        documents: {
          ...state?.documents,
          loading: false,
        },
      };
    case GET_IP_DETAILS_ERROR:
      return {
        ...state,
        data: { ...state.data, loading: false },
      };
    case DELETE_ANY_IP_DOCUMENT_ERROR:
    case SIGN_ANY_IP_DOCUMENT_ERROR:
    case GET_INVESTOR_IP_DETAILS_ERROR:
      return {
        ...state,
        ips: { ...state.data, loading: false },
      };
    case PUBLISH_IP_ERROR:
      return { ...state, loading: false };
    case ASKED_PERMISSION_TO_DOCUMENTS_SUCCESS_IP:
      return {
        ...state,
        permissions: {
          ...state.permissions,
          permissionsAsked: action?.data || [],
          loading: false,
        },
      };
    case GIVE_PERMISSION_TO_DOCUMENTS_IP_REQUEST:
      return { ...state, permissions: { ...state.permissions, loading: true } };
    case GIVE_PERMISSION_TO_DOCUMENTS_IP_SUCCESS:
      return {
        ...state,
        permissions: {
          ...state.permissions,
          permissionsAsked: action?.data || [],
          loading: false,
        },
      };
    case CLEAR_USER_IP_DOC:
      return {
        ...state,
        documents: {
          ...state?.documents,
          userDocuments: [],
        },
      };
    case UPLOAD_SIGN_ANY_IP_DOCS_REQUEST:
      return {
        ...state,
        envelopeUrl: null,
        loading: true,
      };
    case UPLOAD_SIGN_ANY_IP_DOCS_SUCCESS:
      return {
        ...state,
        envelopeUrl: null,
        signed: [...state.signed, state.pending],
        pending: null,
        loading: false,
      };
    case UPLOAD_SIGN_ANY_IP_DOCS_ERROR:
      return {
        ...state,
        envelopeUrl: null,
        pending: null,
        loading: false,
        errorMessage: action.data,
      };
    case SIGN_ANY_IP_DOCUMENT_SUCCESS:
      return {
        ...state,
        [action.data.id]: action.data.envelope_id,
        envelopeUrl: action.data.envelope_url,
        pending: action.data.id,
        loading: false,
      };
    case CLEAR_IPDETAILS:
      return {
        ...state,
        ips: {},
        data: {
          loading: false,
        },
      };
    default:
      return state;
  }
}
