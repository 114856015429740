import { useState } from "react";
import { Comment, Avatar, Form, Button, Input, Row, Col } from "antd";

const { TextArea } = Input;

const Editor = ({ onChange, onSubmit, submitting, value }) => (
  <>
    <Form.Item>
      <Row gutter={8}>
        <Col xs={24} sm={24} md={1} xl={1} xxl={1}>
          <Avatar src="https://joeschmoe.io/api/v1/random" alt="Han Solo" />
        </Col>
        <Col xs={24} sm={23} md={23} xl={23} xxl={23}>
          <TextArea
            rows={4}
            onChange={onChange}
            value={value}
            className="comment_input"
            placeholder="Input placeholder"
          />
        </Col>
      </Row>
    </Form.Item>
    <Form.Item>
      <Button
        htmlType="submit"
        loading={submitting}
        onClick={onSubmit}
        className="btn_comment"
      >
        Post
      </Button>
    </Form.Item>
  </>
);

const CommentForm = ({
  handleSubmit,
  submitLabel,
  hasCancelButton = false,
  handleCancel,
  initialText = "",
}) => {
  const [text, setText] = useState(initialText);
  const onSubmit = (event) => {
    event.preventDefault();
    handleSubmit(text);
    setText("");
  };
  return (
    <Comment
      content={
        <Editor
          onChange={(e) => setText(e.target.value)}
          onSubmit={onSubmit}
          value={text}
        />
      }
    />
  );
};

export default CommentForm;
