import {
  CLEAR_CREATE_DEAL_DETAILS,
  CLEAR_DEAL_TITLES,
  CLEAR_ERROR,
  CLEAR_SUCCESS,
  CREATE_DEAL_REQUEST,
  DEAL_TITLES_REQUEST,
  DELETE_SPV_DOCS_REQUEST,
  DOWLOAD_SPV_DOCS_REQUEST,
  EDIT_DEAL_DETAILS_REQUEST,
  GET_SECTORS_REQUEST,
  SHOW_POPUP,
  SIGN_DOCS_OPEN_REQUEST,
  UPDATE_DEAL_ON_REQUEST,
  UPDATE_DEAL_REQUEST,
  UPLOAD_SPV_DOCS_REQUEST,
} from "./constants";

export const createDealRequest = (data) => {
  return {
    type: CREATE_DEAL_REQUEST,
    data,
  };
};

export const getSectorsRequest = () => {
  return {
    type: GET_SECTORS_REQUEST,
  };
};

export const updateDealRequest = (data) => {
  return {
    type: UPDATE_DEAL_REQUEST,
    data,
  };
};

export const updateDealOnStep = (data) => {
  return {
    type: UPDATE_DEAL_ON_REQUEST,
    data,
  };
};

export const downloadSpvDocsRequest = (data) => {
  return {
    type: DOWLOAD_SPV_DOCS_REQUEST,
    data,
  };
};

export const clearSuccess = () => {
  return {
    type: CLEAR_SUCCESS,
  };
};

export const clearError = () => {
  return {
    type: CLEAR_ERROR,
  };
};

export const uploadSpvDocsRequest = (data) => {
  return {
    type: UPLOAD_SPV_DOCS_REQUEST,
    data,
  };
};

export const DeleteSpvDocs = (data) => {
  return {
    type: DELETE_SPV_DOCS_REQUEST,
    data,
  };
};

export const signDocsRequest = (data) => {
  return {
    type: SIGN_DOCS_OPEN_REQUEST,
    data,
  };
};

export const editDealDetailsAction = (data) => {
  return {
    type: EDIT_DEAL_DETAILS_REQUEST,
    data,
  };
};

export const setIntialDealDetails = () => {
  return {
    type: CLEAR_CREATE_DEAL_DETAILS,
  };
};

export const showErrorMsg = (data) => {
  return {
    type: SHOW_POPUP,
    data,
  };
};

export const getAllDealTitlesAction = () => {
  return {
    type: DEAL_TITLES_REQUEST,
  };
};

export const getClearDealAllTitles = () => {
  return {
    type: CLEAR_DEAL_TITLES,
  };
};
