import { Table } from "antd";
import React from "react";
import { numberFormatter } from "../../../../Utils";

const pastFundraisesColumns = [
  {
    title: "Round name",
    dataIndex: "round_name",
  },
  {
    title: "Date",
    dataIndex: "date",
  },
  {
    title: "Amount",
    dataIndex: "amount",
    render: (_, record) => (
      <div className="add-currency">
        <div>{numberFormatter.formatter(record.amount)} </div>
        <div>{record.currency}</div>
      </div>
    ),
  },
  {
    title: "Comments",
    dataIndex: "comments",
  },
];

const DetailsFundRaisersTable = ({ data }) => {
  return (
    <Table
      rowClassName={() => "editable-row"}
      pagination={false}
      dataSource={data?.past_fund_raisers}
      size="small"
      columns={pastFundraisesColumns}
    />
  );
};

export default DetailsFundRaisersTable;
