import {
  GET_INDIVIDUAL_PROFILE_REQUEST,
  GET_INDIVIDUAL_PROFILE_REQUEST_SUCCESS,
  GET_INDIVIDUAL_PROFILE_REQUEST_ERROR,
  UPDATE_PROFILE_REQUEST,
  UPDATE_PROFILE_REQUEST_SUCCESS,
  UPDATE_PROFILE_REQUEST_ERROR,
  GET_COMPANY_PROFILE_REQUEST,
  GET_COMPANY_PROFILE_REQUEST_SUCCESS,
  GET_COMPANY_PROFILE_REQUEST_ERROR,
  UPDATE_COMPANY_PROFILE_REQUEST,
  UPDATE_COMPANY_PROFILE_REQUEST_SUCCESS,
  UPDATE_COMPANY_PROFILE_REQUEST_ERROR,
  GET_CURRENT_PASSWORD_REQUEST,
  GET_CURRENT_PASSWORD_REQUEST_SUCCESS,
  GET_CURRENT_PASSWORD_REQUEST_ERROR,
  CLEAR_UPDATE_SUCCESS_MESSAGE,
  SETTING_KYB_REQUEST,
  SETTING_KYC_REQUEST,
  SETTING_KYB_SUCCESS,
  SETTING_KYC_SUCCESS,
  SETTING_KYB_ERROR,
  SETTING_KYC_ERROR,
  CLEAR_SETTING_MESSAGE,
  CLEAR_ERROR_MESSAGE,
  SETTING_QUESTIONARE_SUCCESS,
  SETTING_QUESTIONARE_ERROR,
  SETTING_QUESTIONARE_REQUEST,
  SETTING_SOURCE_OF_FUNDS_ERROR,
  SETTING_SOURCE_OF_FUNDS_REQUEST,
  SETTING_SOURCE_OF_FUNDS_SUCCESS,
  GET_FAC_SETTING_SERVICES_SUCCESS,
  GET_FAC_SETTING_SERVICES_ERROR,
  GET_FAC_SETTING_SERVICES_REQUEST,
  SET_FAC_SETTING_SERVICES_REQUEST,
  SET_FAC_SETTING_SERVICES_SUCCESS,
  SET_FAC_SETTING_SERVICES_ERROR,
  GET_SETTING_KYB_REQUEST,
  GET_SETTING_KYB_SUCCESS,
  GET_SETTING_KYB_ERROR,
  GET_SETTING_QUESTIONARE_REQUEST,
  GET_SETTING_QUESTIONARE_SUCCESS,
  GET_SETTING_QUESTIONARE_ERROR,
  GET_SETTING_KYC_SUCCESS,
  GET_SETTING_KYC_ERROR,
  GET_SETTING_KYC_REQUEST,
  GET_SOURCE_OF_FUNDS_REQUEST,
  GET_SOURCE_OF_FUNDS_SUCCESS,
  GET_SOURCE_OF_FUNDS_ERROR,
  GET_FAC_SERVICES_REQUEST,
  GET_FAC_SERVICES_SUCCESS,
  GET_FAC_SERVICES_ERROR,
  DELETE_SOURCE_OF_FUNDS_REQUEST,
  DELETE_SOURCE_OF_FUNDS_SUCCESS,
  DELETE_SOURCE_OF_FUNDS_ERROR,
  PROJECT_TITLE,
} from "./constants";

const initialState = {
  loading: null,
  profileData: null,
  companyData: null,
  currentPassword: null,
  updateProfileSucces: null,
  updateProfileFailed: null,
  updateCompanyProfileSucces: true,
  updateCompanyProfileFailed: null,
  facilitatorServices: [],
  selectedServices: [],
  documentsKYB: [],
  sourceOfFundsPrev: [],
  documentsKYC: [],
  questionarePrev: {},
  success: null,
  error: null,
  projectTitle: ""
};

export default function settings(state = initialState, action) {
  switch (action.type) {
    case GET_INDIVIDUAL_PROFILE_REQUEST:
    case SETTING_KYB_REQUEST:
    case SETTING_KYC_REQUEST:
    case SETTING_QUESTIONARE_REQUEST:
    case SETTING_SOURCE_OF_FUNDS_REQUEST:
    case DELETE_SOURCE_OF_FUNDS_REQUEST:
    case GET_FAC_SETTING_SERVICES_REQUEST:
    case SET_FAC_SETTING_SERVICES_REQUEST:
    case GET_SETTING_KYB_REQUEST:
    case GET_SETTING_KYC_REQUEST:
    case GET_SOURCE_OF_FUNDS_REQUEST:
    case GET_SETTING_QUESTIONARE_REQUEST:
    case GET_FAC_SERVICES_REQUEST:
      return { ...state, loading: true };
    case GET_INDIVIDUAL_PROFILE_REQUEST_SUCCESS:
      return {
        ...state,
        profileData: action.data,
        loading: false,
      };
    case GET_INDIVIDUAL_PROFILE_REQUEST_ERROR:
      return { ...state, profileData: action.data, loading: false };

    case UPDATE_PROFILE_REQUEST:
      return { ...state, loading: true };
    case UPDATE_PROFILE_REQUEST_SUCCESS:
      return {
        ...state,
        updateProfileSucces: true,
        loading: false,
      };
    case UPDATE_PROFILE_REQUEST_ERROR:
      return { ...state, updateProfileSucces: false, loading: false };

    case GET_COMPANY_PROFILE_REQUEST:
      return { ...state, loading: true };
    case GET_COMPANY_PROFILE_REQUEST_SUCCESS:
      return {
        ...state,
        companyData: action.data,
        loading: false,
      };
    case GET_COMPANY_PROFILE_REQUEST_ERROR:
      return { ...state, companyData: action.data, loading: false };

    case UPDATE_COMPANY_PROFILE_REQUEST:
      return {
        ...state,
        loading: true,
        updateCompanyProfileSucces: null,
        updateCompanyProfileFailed: null,
      };
    case UPDATE_COMPANY_PROFILE_REQUEST_SUCCESS:
      return {
        ...state,
        companyData: action.data,
        updateCompanyProfileSucces: true,
        loading: false,
      };
    case UPDATE_COMPANY_PROFILE_REQUEST_ERROR:
      return {
        ...state,
        updateCompanyProfileSucces: false,
        loading: false,
        updateCompanyProfileFailed: true,
      };

    case GET_CURRENT_PASSWORD_REQUEST:
      return { ...state, loading: true };
    case GET_CURRENT_PASSWORD_REQUEST_SUCCESS:
      return {
        ...state,
        currentPassword: action.data,
        loading: false,
      };
    case GET_CURRENT_PASSWORD_REQUEST_ERROR:
      return { ...state, currentPassword: action.data, loading: false };

    case CLEAR_UPDATE_SUCCESS_MESSAGE:
      return {
        ...state,
        updateProfileSucces: null,
        updateCompanyProfileSucces: null,
      };
    case SETTING_KYB_SUCCESS:
    case SETTING_KYC_SUCCESS:
    case SETTING_QUESTIONARE_SUCCESS:
    case SETTING_SOURCE_OF_FUNDS_SUCCESS:
    case DELETE_SOURCE_OF_FUNDS_SUCCESS:
    case SET_FAC_SETTING_SERVICES_SUCCESS:
      return { ...state, loading: false };
    case SETTING_KYB_ERROR:
    case SETTING_KYC_ERROR:
    case SETTING_QUESTIONARE_ERROR:
    case SETTING_SOURCE_OF_FUNDS_ERROR:
    case DELETE_SOURCE_OF_FUNDS_ERROR:
    case GET_FAC_SETTING_SERVICES_ERROR:
    case SET_FAC_SETTING_SERVICES_ERROR:
    case GET_SETTING_QUESTIONARE_ERROR:
    case GET_SOURCE_OF_FUNDS_ERROR:
    case GET_FAC_SERVICES_ERROR:
    case GET_SETTING_KYB_ERROR:
    case GET_SETTING_KYC_ERROR:
      return { ...state, loading: false, error: action.data };
    case GET_FAC_SETTING_SERVICES_SUCCESS:
      return {
        ...state,
        loading: false,
        facilitatorServices: action?.data,
      };
    case GET_SETTING_QUESTIONARE_SUCCESS:
      return {
        ...state,
        loading: false,
        questionarePrev: action?.data,
      };
    case GET_SETTING_KYB_SUCCESS:
      return { ...state, loading: false, documentsKYB: action?.data };
    case GET_SETTING_KYC_SUCCESS:
      return { ...state, loading: false, documentsKYC: action?.data };
    case GET_FAC_SERVICES_SUCCESS:
      return { ...state, loading: false, selectedServices: action?.data };
    case GET_SOURCE_OF_FUNDS_SUCCESS:
      return { ...state, loading: false, sourceOfFundsPrev: action?.data };
    case CLEAR_SETTING_MESSAGE:
      return { ...state, loading: false, success: null };
    case CLEAR_ERROR_MESSAGE:
      return { ...state, loading: false, error: null };
    case PROJECT_TITLE:
      return { ...state, projectTitle: action?.data };
    default:
      return state;
  }
}
