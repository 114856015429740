import SumsubWebSdk from "@sumsub/websdk-react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getUser } from "../../Utils/isAuth";
import { getSumSubTokenRequest } from "./redux/action";

const SumSub = ({
  successCallback,
  errorCallback,
  needApplicantToken = true,
}) => {
  const { email } = getUser();
  const { sumSubToken } = useSelector((state) => state.sumSub);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSumSubTokenRequest({ needApplicantToken }));
  }, []);

  return (
    <div>
      {sumSubToken ? (
        <SumsubWebSdk
          accessToken={sumSubToken}
          updateAccessToken={() =>
            dispatch(getSumSubTokenRequest({ needApplicantToken }))
          }
          expirationHandler={() => {
            dispatch(getSumSubTokenRequest({ needApplicantToken }));
          }}
          config={{
            email: email,
            onMessage: successCallback,
            onError: errorCallback,
            expirationHandler: () => {
              dispatch(getSumSubTokenRequest({ needApplicantToken }));
            },
          }}
          options={{ addViewportTag: false, adaptIframeHeight: true }}
          onMessage={successCallback}
          onError={errorCallback}
        />
      ) : null}
    </div>
  );
};

export default SumSub;
