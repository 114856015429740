export const DEAL_DETAILS = "DEAL_DETAILS";

export const ORGANIZER_DEAL_DETAILS_REQUEST = "ORGANIZER_DEAL_DETAILS_REQUEST";
export const ORGANIZER_DEAL_DETAILS_SUCCESS = "ORGANIZER_DEAL_DETAILS_SUCCESS";
export const ORGANIZER_DEAL_DETAILS_ERROR = "ORGANIZER_DEAL_DETAILS_ERROR";

export const PUBLISH_DEAL_REQUEST = "PUBLISH_DEAL_REQUEST";
export const PUBLISH_DEAL_SUCCESS = "PUBLISH_DEAL_SUCCESS";
export const PUBLISH_DEAL_ERROR = "PUBLISH_DEAL_ERROR";

export const UNPUBLISH_DEAL_REQUEST = "UNPUBLISH_DEAL_REQUEST";
export const UNPUBLISH_DEAL_SUCCESS = "UNPUBLISH_DEAL_SUCCESS";
export const UNPUBLISH_DEAL_ERROR = "UNPUBLISH_DEAL_ERROR";

export const INVESTOR_DEAL_DETAILS_REQUEST = "INVESTOR_DEAL_DETAILS_REQUEST";
export const INVESTOR_DEAL_DETAILS_SUCCESS = "INVESTOR_DEAL_DETAILS_SUCCESS";
export const INVESTOR_DEAL_DETAILS_ERROR = "INVESTOR_DEAL_DETAILS_ERROR";

export const INVESTOR_DETAILS_REQUEST = "INVESTOR_DETAILS_REQUEST";
export const INVESTOR_DETAILS_SUCCESS = "INVESTOR_DETAILS_SUCCESS";
export const INVESTOR_DETAILS_ERROR = "INVESTOR_DETAILS_ERROR";

export const ADD_COMMENT_REQUEST = "ADD_COMMENT_REQUEST";
export const ADD_COMMENT_SUCCESS = "ADD_COMMENT_SUCCESS";
export const ADD_COMMENT_ERROR = "ADD_COMMENT_ERROR";

export const UPLOAD_DOCUMENT_REQUEST = "UPLOAD_DOCUMENT_REQUEST";
export const UPLOAD_DOCUMENT_SUCCESS = "UPLOAD_DOCUMENT_SUCCESS";
export const UPLOAD_DOCUMENT_ERROR = "UPLOAD_DOCUMENT_ERROR";

export const UPLOAD_DATA_ROOM_REQUEST = "UPLOAD_DATA_ROOM_REQUEST";
export const UPLOAD_DATA_ROOM_SUCCESS = "UPLOAD_DATA_ROOM_SUCCESS";
export const UPLOAD_DATA_ROOM_ERROR = "UPLOAD_DATA_ROOM_ERROR";

export const GET_DATA_ROOM_REQUEST = "GET_DATA_ROOM_REQUEST";
export const GET_DATA_ROOM_SUCCESS = "GET_DATA_ROOM_SUCCESS";
export const GET_DATA_ROOM_ERROR = "GET_DATA_ROOM_ERROR";

export const UPLOAD_ANY_DOCUMENT_REQUEST = "UPLOAD_ANY_DOCUMENT_REQUEST";
export const UPLOAD_ANY_DOCUMENT_SUCCESS = "UPLOAD_ANY_DOCUMENT_SUCCESS";
export const UPLOAD_ANY_DOCUMENT_ERROR = "UPLOAD_ANY_DOCUMENT_ERROR";

export const UPDATE_ANY_DOCUMENT_REQUEST = "UPDATE_ANY_DOCUMENT_REQUEST";
export const UPDATE_ANY_DOCUMENT_SUCCESS = "UPDATE_ANY_DOCUMENT_SUCCESS";
export const UPDATE_ANY_DOCUMENT_ERROR = "UPDATE_ANY_DOCUMENT_ERROR";

export const ASK_PERMISSION_TO_DOCUMENT_REQUEST =
  "ASK_PERMISSION_TO_DOCUMENT_REQUEST";
export const ASK_PERMISSION_TO_DOCUMENT_SUCCESS =
  "ASK_PERMISSION_TO_DOCUMENT_SUCCESS";
export const ASK_PERMISSION_TO_DOCUMENT_ERROR =
  "ASK_PERMISSION_TO_DOCUMENT_ERROR";

export const ASKED_PERMISSION_TO_DOCUMENTS_REQUEST =
  "ASKED_PERMISSION_TO_DOCUMENTS_REQUEST";
export const ASKED_PERMISSION_TO_DOCUMENTS_SUCCESS =
  "ASKED_PERMISSION_TO_DOCUMENTS_SUCCESS";
export const ASKED_PERMISSION_TO_DOCUMENTS_ERROR =
  "ASKED_PERMISSION_TO_DOCUMENTS_ERROR";

export const GIVE_PERMISSION_TO_DOCUMENTS_REQUEST =
  "GIVE_PERMISSION_TO_DOCUMENTS_REQUEST";
export const GIVE_PERMISSION_TO_DOCUMENTS_SUCCESS =
  "GIVE_PERMISSION_TO_DOCUMENTS_SUCCESS";
export const GIVE_PERMISSION_TO_DOCUMENTS_ERROR =
  "GIVE_PERMISSION_TO_DOCUMENTS_ERROR";

export const SIGN_ANY_DOCUMENT_REQUEST = "SIGN_ANY_DOCUMENT_REQUEST";
export const SIGN_ANY_DOCUMENT_SUCCESS = "SIGN_ANY_DOCUMENT_SUCCESS";
export const SIGN_ANY_DOCUMENT_ERROR = "SIGN_ANY_DOCUMENT_ERROR";

export const DELETE_ANY_DOCUMENT_REQUEST = "DELETE_ANY_DOCUMENT_REQUEST";
export const DELETE_ANY_DOCUMENT_SUCCESS = "DELETE_ANY_DOCUMENT_SUCCESS";
export const DELETE_ANY_DOCUMENT_ERROR = "DELETE_ANY_DOCUMENT_ERROR";

export const CHENGE_ALLOW_DOC_REQUEST = "CHENGE_ALLOW_DOC_REQUEST";
export const CHENGE_ALLOW_DOC_SUCCESS = "CHENGE_ALLOW_DOC_SUCCESS";
export const CHENGE_ALLOW_DOC_ERROR = "CHENGE_ALLOW_DOC_ERROR";

export const UPLOAD_SIGN_ANY_DOCS_REQUEST = "UPLOAD_SIGN_ANY_DOCS_REQUEST";
export const UPLOAD_SIGN_ANY_DOCS_SUCCESS = "UPLOAD_SIGN_ANY_DOCS_SUCCESS";
export const UPLOAD_SIGN_ANY_DOCS_ERROR = "UPLOAD_SIGN_ANY_DOCS_ERROR";

export const GET_USER_DOCUMENTS_REQUEST = "GET_USER_DOCUMENTS_REQUEST";
export const GET_USER_DOCUMENTS_SUCCESS = "GET_USER_DOCUMENTS_SUCCESS";
export const GET_USER_DOCUMENTS_ERROR = "GET_USER_DOCUMENTS_ERROR";

export const CLEAR_USER_DOC = "CLEAR_USER_DOC";
export const CLEAR_DEAL_ERROR = "CLEAR_DEAL_ERROR";

export const SELECT_DEALTYPE_REQUEST = "SELECT_DEALTYPE_REQUEST";

export const PUBLISH_DEAL_FALSE = "PUBLISH_DEAL_FALSE";
