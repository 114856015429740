import { CREATE_DEALTYPE_INFO_REQUEST, ClEAR_MESSAGE } from "./constants";

export const createDealDetailInfo = (data) => {
  return {
    type: CREATE_DEALTYPE_INFO_REQUEST,
    data,
  };
};

export const clearMessage = () => {
  return {
    type: ClEAR_MESSAGE,
  };
};
