import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import {
  Row,
  Col,
  Divider,
  Form,
  Input,
  Button,
  Checkbox,
  message,
} from "antd";

import {GoogleOutlined} from '@ant-design/icons';

import { ExactPage } from "../../redux/auth/action";
import "../../assets/styles/auth.scss";
import { useAuth0 } from "@auth0/auth0-react";
import { signIn } from "../../api";

export default function SignIn(props) {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const authDetails = useSelector((state) => state.auth);
  const { next } = props;
  const { loginWithRedirect } = useAuth0();

  const onLoginSocial = () => {
    loginWithRedirect({ connection: 'google-oauth2' });
    // loginWithRedirect({ prompt: "login" });
  };
  const onFinish = async (values) => {
    setVisible(false);
  
    // Check if 2FA is required from localStorage
    const require2FA = localStorage.getItem("require2FA") || "required";
    if (require2FA !== "notRequired") {
      values.require2FA = require2FA; // Add require2FA to values
    }
  
    try {
      const { data } = await signIn(values);
      // If "Remember Me" is checked, set 2FA as not required in localStorage
      if (values.rememberMe) {
        localStorage.setItem("require2FA", "notRequired");
      }
  
      // Check if 2FA is required and navigate to 2FA page if necessary
      if (require2FA === "required" && data?.twoFactorRequired) {
        navigate(`/2fa?email=${values.email}`);
        return;
      }
  
      // If a token is received, store it and navigate to the home page
      if (data?.token) {
        localStorage.setItem("token", data?.token);
        localStorage.setItem("user", JSON.stringify(data?.userData));
        navigate("/");
      }
    } catch (err) {
      console.error(err?.response?.data?.message);
      message.error(err?.response?.data?.message);
    }
  };

  const onFinishFailed = () => {
    console.log(authDetails);
  };

  const redirectToSignup = () => {
    dispatch(ExactPage(0));
  };

  const rememberedUsername = localStorage.getItem("rememberUser");

  return (
    <>
      <Col sm={{ span: 16 }} xs={{ span: 20 }} offset={4} className="rm-offset">
        <Form
          name="signin"
          form={form}
          initialValues={{
            email: rememberedUsername || "",
            password: "",
            rememberMe: !!rememberedUsername || false,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <h1 className="create-account">Log in</h1>
          <div className="option-text">
            Welcome back! Please enter your details.
          </div>{" "}
          {/* <div className='social-text'>Login in with</div> */}
          {/* <SocialNetworks /> */}
          <Divider className="divider">
            {/* <span className='divider-text'>OR</span> */}
          </Divider>
          <Row gutter={{ xs: 8, sm: 16 }} className="mobile-media">
            {visible && (
              <Col
                className="gutter-row rm-padding error-message"
                xs={{ span: 24 }}
                md={{ span: 24 }}
              >
                <p>Login Failed</p>
              </Col>
            )}
            <Col
              className="gutter-row rm-padding"
              xs={{ span: 24 }}
              md={{ span: 24 }}
            >
              <Form.Item
                name="email"
                className="mobile-media"
                label={<span className="label-input">Email address</span>}
                labelCol={{ span: 24 }}
                hasFeedback
                wrapperCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    type: "email",
                    message: "Please enter valid email!",
                  },
                ]}
              >
                <Input
                  className="input-style input-font-style"
                  placeholder="Email"
                  name="email"
                  size="large"
                  autoFocus={!rememberedUsername}
                  autoComplete="new-email"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={{ xs: 8, sm: 16 }} className="mobile-media">
            <Col
              className="gutter-row rm-padding"
              xs={{ span: 24 }}
              md={{ span: 24 }}
            >
              <div className="password-label">
                <span className="label-input ant-form-item-required password-required">
                  Password
                </span>
                <span className="label-input-forgot-password" onClick={next}>
                  Forgot your password?
                </span>
              </div>
              <Form.Item
                name="password"
                className="fix-margin"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Please enter password",
                  },
                ]}
              >
                <Input.Password
                  placeholder="Password"
                  type="password"
                  size="large"
                  className="input-style input-font-style"
                  autoFocus={!!rememberedUsername}
                  autoComplete="new-password"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row className="mobile-media">
            <Col className="gutter-row" xs={{ span: 24 }} md={{ span: 24 }}>
              <Form.Item
                name="rememberMe"
                valuePropName="checked"
                className="rm-margin-bottom"
              >
                <Checkbox>
                  <span className="remember_me">Remember me</span>
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>
          <Row className="mobile-media">
            <Col className="gutter-row" xs={{ span: 24 }} md={{ span: 24 }}>
              <Button
                className="form-submit-btn"
                type="primary"
                htmlType="submit"
                shape="round"
                size="large"
              >
                Log in
              </Button>
              <Button
                className="form-submit-btn"
                type="primary"
                shape="round"
                size="large"
                onClick={onLoginSocial}
              >
                <GoogleOutlined style={{fontSize:"1.5rem"}} />
                <span style={{margin:0}}>Log in with Google</span>
              </Button>
            </Col>
          </Row>
          <Row className="mobile-media">
          <p className="existing-account mobile-media">
            Don't have an account?
            <Link className="login-text" to="/register">
              Register
            </Link>
          </p>
        </Row>
        </Form>
        {/* <Row className="mobile-media">
          <p className="existing-account mobile-media">
            Don’t have an account?
            <span className="login-text" onClick={redirectToSignup}>
              Sign up
            </span>
          </p>
        </Row> */}
      </Col>
    </>
  );
}
