import { CREATE_DEALTYPE_INFO_SUCCESS, ClEAR_MESSAGE } from "./constants";

const initialState = {
  Message: "",
};

export default function dealTypeSelection(state = initialState, action) {
  switch (action.type) {
    case CREATE_DEALTYPE_INFO_SUCCESS:
      return { ...state, Message: action.data };
    case ClEAR_MESSAGE:
      return { ...state, Message: "" };
    default:
      return state;
  }
}
