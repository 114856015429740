import React from "react";
import { Row, Col } from "antd";
import { Link } from "react-router-dom";
import Logo from "../../../src/assets/images/main-logo-svg.svg";

const WebsiteLogo = () => {
  return (
    <Row>
      <Col
        xs={{ span: 24 }}
        md={{ span: 24 }}
        className="site-layout-header-left gutter-row"
      >
        {" "}
        <Link to="/">
          <div className="logo-image">
            <img src={Logo} alt="Logo" />
          </div>
        </Link>
      </Col>
    </Row>
  );
};
export default WebsiteLogo;
