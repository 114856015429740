import React from "react";
import { List } from "antd";
import { Row, Col } from "antd";
import { MoreOutlined } from "@ant-design/icons";

import Document from "../../../assets/images/documentdeals.png";
import {
  companydetail,
  spvdetail,
  companydocuments,
  spvdocuments,
} from "../../../data/data";

const Documents = () => {
  return (
    <div className="documents_tab">
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
          <Row>
            <Col xs={24} sm={24} md={12} xl={12} xxl={12}>
              {companydocuments.map((c, index) => (
                <>
                  <h2>{c.title} </h2>
                  {/* <small>{c.desc}</small> */}
                </>
              ))}{" "}
            </Col>
            <Col xs={24} sm={24} md={12} xl={12} xxl={12}>
              <MoreOutlined className="threedot" />
            </Col>
          </Row>
        </Col>
        <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
          <Row>
            <Col xs={24} sm={24} md={12} xl={12} xxl={12}>
              <List
                itemLayout="horizontal"
                dataSource={companydetail}
                renderItem={(item) => (
                  <List.Item
                    actions={[<h3 className="list-loadmore-edit">Download</h3>]}
                  >
                    <List.Item.Meta
                      avatar={<img src={Document} alt="doc" />}
                      title={
                        <a href="https://ant.design">
                          <h3>{item.title}</h3>
                        </a>
                      }
                      description={<small>{[item.desc, ".", item.size]}</small>}
                    />
                  </List.Item>
                )}
              />
            </Col>
          </Row>
        </Col>

        <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
          <Row>
            <Col xs={24} sm={24} md={12} xl={12} xxl={12}>
              {spvdocuments.map((c) => (
                <h2>{c.title}</h2>
              ))}
            </Col>
            <Col xs={24} sm={24} md={12} xl={12} xxl={12}>
              <MoreOutlined className="threedot" />
            </Col>
          </Row>
        </Col>

        <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
          <Row>
            <Col xs={24} sm={24} md={12} xl={12} xxl={12}>
              <List
                itemLayout="horizontal"
                dataSource={spvdetail}
                renderItem={(item) => (
                  <List.Item
                    actions={[<h3 className="list-loadmore-edit">Download</h3>]}
                  >
                    <List.Item.Meta
                      avatar={<img src={Document} alt="doc" />}
                      title={
                        <a href="https://ant.design">
                          <h3>{item.title}</h3>
                        </a>
                      }
                      description={<small>{[item.desc, ".", item.size]}</small>}
                    />
                  </List.Item>
                )}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default Documents;
