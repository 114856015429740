import React from "react";

import { Button } from "antd";

import "./index.scss";

function StepCard({ index, title, description, button }) {
  const checkPosition = (index) => {
    if (index % 2 === 0) {
      return "right";
    } else {
      return "left";
    }
  };
  return (
    <div className={`card-wrapper ${checkPosition(index)}`}>
      <div className="step-card">
        <div className="step-card__title">{title}</div>
        <div className="step-card__description">{description}</div>
        {button ? (
          <Button
            className="step-card__link"
            type="link"
            onClick={button.callback}
          >
            {button.title}
          </Button>
        ) : null}
      </div>
    </div>
  );
}

export default StepCard;
