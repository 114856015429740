import {
  GET_ADMIN_TOPORGANIZERS_REQUEST,
  UPDATE_TOPORGANIZERS_STATUS_REQUEST,
} from "./constants";

export const getAdminOrganizersRequest = () => {
  return {
    type: GET_ADMIN_TOPORGANIZERS_REQUEST,
  };
};

export const updateOrganizersStatusRequest = (data) => {
  return {
    type: UPDATE_TOPORGANIZERS_STATUS_REQUEST,
    data,
  };
};
