import { combineReducers } from "redux";
import createDeal from "../pages/createDeal/redux/reducer";
import dealDetail from "../pages/dealDetail/redux/reducer";
import dealList from "../pages/deals/redux/reducer";
import investReducer from "../pages/investPage/redux/reducer";
import auth from "../redux/auth/reducer";
import adminDealsList from "../pages/adminDeals/redux/reducer";
import marketplaceListReducer from "../pages/marketplacePage/redux/reducer";
import maMarketplaceListReducer from "../pages/MAmarketplace/redux/reducer";
import notificationsData from "../layout/dashboard/redux/reducer";
import adminInvestmentList from "../pages/adminInvestment/redux/reducer";
import settings from "../pages/settings/redux/reducer";
import userDetail from "./userDetail/reducer";
import chatReducer from "../pages/chatPage/redux/reducer";
import investerViewData from "../pages/dashboard/redux/reducer";
import adminTopOrganizersList from "../pages/adminTopOrganizers/redux/reducer";
import serviceMarketplaceListReducer from "../pages/serviceMarketPlace/redux/reducer";
import userPage from "../pages/users/redux/reducer";
import serviceOrganizerDeals from "../pages/serviceOrganizerDeals/redux/reducer";
import OnboardinReducer from "../pages/onBoardingPage/redux/reducer";
import facilitatorsPage from "../pages/facilitator/redux/reducer";
import grantMarketplaceListReducer from "../pages/grantMarketPlace/redux/reducer";
import dealTypeSelection from "../pages/common/dealTypeSelectionModal/redux/reducer";
import createIp from "../pages/createIp/redux/reducer";
import IPList from "../pages/ips/redux/reducer";
import adminIpsList from "../pages/adminIps/redux/reducer";
import IPMarketplaceListReducer from "../pages/IntellectualPropertyMarketPlace/redux/reducer";
import ipDetail from "../pages/ipDetail/redux/reducer";
import adminIPInvestmentList from "../pages/adminIPInvestment/redux/reducer";
import sumSubReducer from "../components/SumSub/redux/reducer";
import LongevityInfo from "../pages/LongevityMarketplace/redux/reducer";
import userFlowReducer from "../pages/userJourney/redux/reducer";

const rootReducer = combineReducers({
  auth: auth,
  dealList: dealList,
  createDeal: createDeal,
  createIp: createIp,
  adminDealsList: adminDealsList,
  ipList: IPList,
  dealDetails: dealDetail,
  ipDetails: ipDetail,
  invest: investReducer,
  marketplaceList: marketplaceListReducer,
  serviceMarketplaceList: serviceMarketplaceListReducer,
  notificationsData: notificationsData,
  adminInvestmentList: adminInvestmentList,
  adminIPInvestmentList: adminIPInvestmentList,
  adminTopOrganizersList: adminTopOrganizersList,
  adminIpsList: adminIpsList,
  ipMarketplaceList: IPMarketplaceListReducer,
  settings: settings,
  userDetail: userDetail,
  chat: chatReducer,
  investerViewData,
  userPage: userPage,
  serviceOrganizerDeals,
  onboarding: OnboardinReducer,
  facilitatorsPage: facilitatorsPage,
  maMarketplaceListReducer,
  grantMarketPlace: grantMarketplaceListReducer,
  dealTypeSelection: dealTypeSelection,
  sumSub: sumSubReducer,
  userFlow: userFlowReducer,
  LongevityInfo,
});
export default rootReducer;
