import { useAuth0 } from "@auth0/auth0-react";
import { Spin, message } from "antd";
import React, { useEffect } from "react";
import WebsiteLogo from "../../components/RegistrationLeftBar/WebsiteLogo";
import { LoadingOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getUser } from "../../Utils/isAuth";
import { signIn } from "../../api";

const VerifyingSocialLogin = () => {
  const { user } = useAuth0();
  const navigate = useNavigate();

  const authDetails = useSelector((state) => state.auth);
  const sysUser = getUser();

  useEffect(() => {
    if (user) {
      const body = {
        email: user.email,
        type: "Auth0",
        emailVerified: user.email_verified,
        firstName: user.given_name,
        lastName: user.family_name,
      };
      signIn(body).then(({ data }) => {
        if (data?.token) {
          localStorage.setItem("token", data?.token)
          localStorage.setItem("user", JSON.stringify(data?.userData))
          navigate("/");
        }
      }).catch((err) => {
        message.error(err?.response?.data?.message)
      })
    }
  }, [user]);

  useEffect(() => {
    console.log("sysUser", sysUser, authDetails);
    if (authDetails.loginSuccess) {
      if (
        sysUser?.onboardingStepsDone?.email_token &&
        sysUser?.onboardingStepsDone?.uid
      ) {
        navigate(
          `/verify-email/${sysUser?.onboardingStepsDone?.uid}/${sysUser?.onboardingStepsDone?.email_token}`
        );
      } else if (sysUser) {
        if (sysUser.sub_type_of_account || sysUser.is_superuser) {
          if (sysUser.type_of_account === "FAC") {
            navigate("/");
            return;
          }
          navigate("/deals");
        } else {
          navigate("/onboarding/0");
        }
      } else {
        navigate("/registration");
      }
    } else {
      if (authDetails.loginFailed) {
        navigate("/registration");
      }
    }
  }, [authDetails, user, navigate]);

  return (
    <div className="verify-social-login-wrapper">
      <WebsiteLogo />
      <Spin
        spinning
        className="spining-verify"
        indicator={<LoadingOutlined className="indicator" />}
      />
      <div className="verify-txt">Verifying social account credentials....</div>
    </div>
  );
};

export default VerifyingSocialLogin;
