import { message } from "antd";
import { BASE_URL } from "../api";
import { getUser } from "./isAuth";

export const getBase64FromUrl = async (url) => {
  const data = await fetch(url);
  const blob = await data.blob();
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const base64data = reader.result;
      resolve(base64data);
    };
  });
};

export const isUserBelongsToDeal = (dealDetail) => {
  let isBelongs = false;
  const user = getUser();
  dealDetail?.investors?.forEach((el) => {
    if (el?.id === user?.user_id) {
      isBelongs = true;
    }
  });

  if (dealDetail?.organizer?.id === user?.user_id) {
    isBelongs = true;
  }

  if (user.is_superuser) {
    isBelongs = true;
  }

  return isBelongs;
};

export const isInvestor = () => {
  const user = getUser();
  return ["INV"].includes(user?.type_of_account);
};

export const isOrganizer = () => {
  const user = getUser();
  return ["SVC_ORG"].includes(user?.type_of_account);
};

export const isStartup = () => {
  const user = getUser();
  return ["ORG"].includes(user?.type_of_account);
};

export const isFacilitator = () => {
  const user = getUser();
  return ["FAC"].includes(user?.type_of_account);
};

export const isOrganizerOrFacilitator = () => {
  const user = getUser();
  return ["SVC_ORG", "FAC"].includes(user?.type_of_account);
};

export const isUserCompany = () => {
  const user = getUser();
  return ["CMP"].includes(user?.sub_type_of_account);
};

export const isUserIndevidual = () => {
  const user = getUser();
  return ["IND"].includes(user?.sub_type_of_account);
};

export const hasUsersAccess = () => {
  const user = getUser();
  return user?.usertab_visible;
};

export const isSuperAdmin = () => {
  const user = getUser();
  return user?.is_superuser;
};

export const isValidPhoneNumber = (mobileNumber) => {
  const passwordRex = new RegExp(/^(?=.*[0-9]).{8,15}$/);
  return passwordRex.test(mobileNumber);
};

export const formDataWrapper = (payload) => {
  const formData = new FormData();
  Object.entries(payload).forEach(([key, value]) => {
    formData.append(key, value);
  });
  return formData;
};

export const getFirstLater = (name) => {
  return String(name?.split("")?.[0]).toLocaleUpperCase();
};

export const getImageURL = (value) => {
  let url;
  try {
    url = new URL(value);
  } catch {
    return `${BASE_URL}${value}`;
  }

  if (url && ["http:", "https:"].includes(url.protocol)) {
    return url.href;
  }

  return `${BASE_URL}${value}`;
};

export const getURL = (value) => {
  if (value && value.includes("http")) {
    return value;
  }

  return `https://${value}`;
};

export const isValidUrl = (urlString) => {
  try {
    return Boolean(new URL(urlString));
  } catch (e) {
    return false;
  }
};

const poorRegExp = /[A-Za-z]/;
const weakRegExp = /^(?=.*?[0-9#?!@$%^&*-])/;
const strongRegExp =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;

export const passwordStrength = {
  WEAK: "Medium",
  POOR: "Weak",
  STRONG: "Strong",
};

export const getPasswordStrength = (value) => {
  const passwordValue = value;
  const poorPassword = poorRegExp.test(passwordValue);
  const weakPassword = weakRegExp.test(passwordValue);
  const strongPassword = strongRegExp.test(passwordValue);

  // to check strong Password
  if (poorPassword && weakPassword && strongPassword) {
    return passwordStrength.STRONG;
  }

  // to check weak password
  if (poorPassword && (weakPassword || strongPassword)) {
    return passwordStrength.WEAK;
  }

  // to check poor password
  if (poorPassword || weakPassword || strongPassword) {
    return passwordStrength.POOR;
  }

  return "";
};

export const numberFormatter = {
  formatter: (value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ","),
  parser: (value) => value.replace(/\$\s?|(,*)/g, ""),
};

export const beforeUpload = (file) => {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";

  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!");
  }

  const isLt2M = file.size / 1024 / 1024 < 2;

  if (!isLt2M) {
    message.error("Image must smaller than 2MB!");
  }

  return isJpgOrPng && isLt2M;
};

export const dummyRequest = ({ file, onSuccess }) => {
  setTimeout(() => {
    onSuccess("ok");
  }, 0);
};

export const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};
export const analyticLineChart = (data) => {
  return {
    data: data || [],
    padding: "auto",
    width: 800,
    height: 400,
    // autoFit: false,
    xField: "date",
    yField: "value",
    // point: {
    //   size: 5,
    //   shape: "circle",
    // },
    // label: {
    //   style: {
    //     fill: "#aaa",
    //   },
    // },
  };
};
export const AUTH0_CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID;
export const AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN;
