import React, { useState, useEffect, useMemo } from "react";
import { Tabs, Spin, Badge, Modal, Button } from "antd";
import { LoadingOutlined, CloseOutlined } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import { useCallback } from "react";
import FacilitatorHeaderSearch from "./components/FacilitatorHeaderSearch";
import UsersTable from "../users/UsersTable";
import { facilitatorColumns, facilitatorsDeviceColumns } from "./Constants";
import { getFacilitatorList } from "./redux/action";
import noUsers from "../../assets/images/no-users.svg";
import { userDetailByIdAction } from "../users/redux/action";
import ApproveDealModalIcon from "../../assets/images/modal-approve-deal.png";
import { updateFacilitatorsStatusRequest } from "./redux/action.js";
import { userDetailsAction } from "../../redux/userDetail/action";

const FacilitatorEmpty = (
  <div className="no-users-table-img">
    <img src={noUsers} alt="no data" />
    <span className="title-txt">No data found</span>
    <span className="description-txt">
      Please contact your manager to fix this problem.
    </span>
  </div>
);

const { TabPane } = Tabs;

const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;

const TabHeader = ({ tabName, count }) => (
  <span className="tab-header">
    {tabName}
    <Badge
      count={count || 0}
      size="small"
      className="count-badge-style"
      showZero
    />
  </span>
);

function Facilitator() {
  const state = useSelector((state) => state.facilitatorsPage);
  const dispatch = useDispatch();

  const [tabValue, setTabValue] = useState("All");
  const [search, setSearch] = useState("");
  const [visibleApprove, setVisibleApprove] = useState(false);
  const [visibleReject, setVisibleReject] = useState(false);
  const [dropDownUserId, setDropDownUserId] = useState("");

  useEffect(() => {
    dispatch(getFacilitatorList());
  }, [dispatch]);

  const filteredTableData = useMemo(() => {
    return state?.facilitatorList?.filter(
      (item) =>
        (tabValue === "All" ||
          (tabValue !== "All") & (item.status === tabValue)) &&
        (!search ||
          (search &&
            String(item.name)
              .toLowerCase()
              .includes(String(search).toLowerCase())))
    );
  }, [tabValue, search, state.facilitatorList]);

  const getCount = useCallback(
    (tabName) => {
      if (tabName === "All") {
        return state?.facilitatorList?.length;
      } else if (tabName === "Active") {
        return state?.facilitatorList?.filter(
          (user) => user.status === "Active"
        )?.length;
      } else if (tabName === "Rejected") {
        return state?.facilitatorList?.filter(
          (user) => user.status === "Rejected"
        )?.length;
      } else if (tabName === "In Review") {
        return state?.facilitatorList?.filter(
          (user) => user.status === "In Review"
        )?.length;
      } else if (tabName === "Compliance Check") {
        return state?.facilitatorList?.filter(
          (user) => user.status === "Compliance Check"
        )?.length;
      }

      return 0;
    },
    [state.facilitatorList]
  );

  const onViewProfile = (data) => {
    dispatch(userDetailByIdAction({ id:data.user_id }));
    dispatch(userDetailsAction({ id: data.user_id, deal_id: "" }));
  };

  const handleOkApprove = (e, record) => {
    const formData = new FormData();

    formData.append("user_id", dropDownUserId);
    formData.append("status", `Approve`.trim());
    dispatch(updateFacilitatorsStatusRequest(formData));

    setVisibleApprove(false);
  };

  const handleCancelApprove = (e) => {
    setVisibleApprove(false);
  };

  const handleCancelReject = (e) => {
    setVisibleReject(false);
  };

  const handleOkReject = (e, record) => {
    const formData = new FormData();

    formData.append("user_id", dropDownUserId);
    formData.append("status", `Reject `.trim());
    dispatch(updateFacilitatorsStatusRequest(formData));
    setVisibleReject(false);
  };

  return (
    <div className="admin-users">
      {state?.loading && (
        <div className="spinner_background">
          <Spin className="spinner" indicator={antIcon} />
        </div>
      )}
      <Tabs defaultActiveKey={tabValue} onChange={(tab) => setTabValue(tab)}>
        <TabPane
          tab={<TabHeader tabName="All" count={getCount("All")} />}
          key="All"
        />
        <TabPane
          tab={<TabHeader tabName="In Review" count={getCount("In Review")} />}
          key="In Review"
        />
        <TabPane
          tab={
            <TabHeader
              tabName="Compliance Check"
              count={getCount("Compliance Check")}
            />
          }
          key="Compliance Check"
        />
        <TabPane
          tab={<TabHeader tabName="Active" count={getCount("Active")} />}
          key="Active"
        />
        <TabPane
          tab={<TabHeader tabName="Rejected" count={getCount("Rejected")} />}
          key="Rejected"
        />
      </Tabs>
      <FacilitatorHeaderSearch
        searchFilter={(e) => setSearch(e.target.value)}
      />
      <UsersTable
        data={filteredTableData}
        loading={false}
        columns={facilitatorColumns(onViewProfile, setVisibleApprove,  setVisibleReject, setDropDownUserId)}
        deviceColumns={facilitatorsDeviceColumns(onViewProfile, setVisibleApprove,  setVisibleReject, setDropDownUserId)}
        empty={FacilitatorEmpty}
      />
      <Modal
        visible={visibleApprove}
        onOk={handleOkApprove}
        onCancel={handleCancelApprove}
        className="approve-modal"
        centered={true}
        footer={[
          <Button
            key="no"
            onClick={handleCancelApprove}
            className="modal-cancel-btn"
          >
            No
          </Button>,
          <Button
            key="yes"
            type="primary"
            onClick={handleOkApprove}
            className="modal-yes-btn"
          >
            Yes
          </Button>,
        ]}
      >
        <div>
          <img
            src={ApproveDealModalIcon}
            className="approve-modal-icon"
            alt=""
          />
          <p className="approve-modal-title">Approve an Facilitator?</p>
          <p className="approve-modal-desc">
            Are you sure you want to approve an Facilitator? This action cannot
            be undone.
          </p>
        </div>
      </Modal>
      <Modal
        visible={visibleReject}
        onOk={handleOkReject}
        onCancel={handleCancelReject}
        className="approve-modal"
        centered={true}
        footer={[
          <Button
            key="no"
            onClick={handleCancelReject}
            className="modal-cancel-btn"
          >
            No
          </Button>,
          <Button
            key="yes"
            type="primary"
            onClick={handleOkReject}
            className="reject-modal-yes-btn "
          >
            Yes
          </Button>,
        ]}
      >
        <div>
          <CloseOutlined className="reject-modal-icon" />
          <p className="approve-modal-title">Reject an Facilitator?</p>
          <p className="approve-modal-desc">
            Are you sure you want to reject an Facilitator? This action cannot
            be undone.
          </p>
        </div>
      </Modal>
    </div>
  );
}

export default Facilitator;
