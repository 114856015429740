import React, { useEffect, useState } from "react";
import { Button, Col, Divider, Form, Input, Row, Typography } from "antd";
import Icon, { AppleOutlined } from "@ant-design/icons";
import LongevityCategory from "./LongevityCategory";
import { useDispatch, useSelector } from "react-redux";
import { longevitySignIn } from "./redux/action";
import { GooglePlay } from "../../assets/images/GooglePlay";

function LongevityMarketplace() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const longevityEmail = localStorage.getItem("lc_email");
  const loginData = useSelector((state) => state.LongevityInfo);

  const onFinish = (values) => {
    dispatch(longevitySignIn(values));
  };

  useEffect(() => {
    if (loginData.loginSuccess) {
      setLoggedIn(true);
    } else if (loginData.loginError) {
      setLoggedIn(false);
      setErrorMsg(true);

      const timer = setTimeout(() => {
        setErrorMsg(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [loginData.loginError, loginData.loginSuccess]);

  if (loggedIn || longevityEmail !== null) {
    return <LongevityCategory />;
  }

  return (
    <>
      <Divider style={{ marginTop: "0px" }} />
      <Col
        sm={{ span: 16 }}
        xs={{ span: 20 }}
        offset={4}
        className="rm-offset"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <Form
          name="signin"
          form={form}
          onFinish={onFinish}
          onFinishFailed={() => console.error("Error while login")}
        >
          <h1 className="create-account">Let's Get Started</h1>
          <div className="option-text">
            Welcome to Longevity Marketplace!
            <br /> If you have a Longevity Card account, please enter your email
            to continue.
          </div>{" "}
          <Divider />
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Row gutter={{ xs: 8, sm: 16 }} className="mobile-media">
              <Col
                className="gutter-row rm-padding"
                xs={{ span: 24 }}
                md={{ span: 22 }}
              >
                <Form.Item
                  name="email"
                  className="custom-required"
                  label="Longevity Card Email "
                  labelCol={{ span: 24 }}
                  hasFeedback
                  wrapperCol={{ span: 24 }}
                  rules={[
                    {
                      required: true,
                      type: "email",
                      message: "Please enter valid email!",
                    },
                  ]}
                >
                  <Input
                    className="input-style input-font-style"
                    placeholder="Email"
                    name="email"
                    size="large"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row
              className="mobile-media"
              style={{ marginBottom: "10px", marginTop: "20px" }}
            >
              <Col className="gutter-row" xs={{ span: 24 }} md={{ span: 24 }}>
                <Button
                  className="form-submit-btn"
                  type="primary"
                  htmlType="submit"
                  size="large"
                  style={{ borderRadius: "6px" }}
                >
                  Continue
                </Button>
              </Col>
            </Row>
          </div>
          {errorMsg && (
            <Row className="mobile-media">
              {" "}
              <Col
                className="gutter-row error-message"
                xs={{ span: 24 }}
                md={{ span: 24 }}
              >
                <p>No Registered user found with this email</p>
              </Col>
            </Row>
          )}
          <Row style={{ marginTop: "18px" }}>
            <Col className="gutter-row" xs={{ span: 24 }} md={{ span: 24 }}>
              <Typography.Title level={5}>
                {" "}
                Don't have a Longevity Card account? Join now
              </Typography.Title>
            </Col>
          </Row>
          <div className="social__title">Longevity Card</div>
          <div className="social__button">
            <Button
              onClick={() => {
                window.open(
                  `https://apps.apple.com/tr/app/longevity-card/id1635152843`,
                  "_blank"
                );
              }}
            >
              <AppleOutlined />
              <span>
                {" "}
                Download on the <br /> <span class="big-txt">App Store</span>
              </span>
            </Button>
            <Button
              onClick={() => {
                window.open(
                  `https://play.google.com/store/apps/details?id=cards.longevity.retail`,
                  "_blank"
                );
              }}
            >
              <Icon component={GooglePlay} />

              <span>
                {" "}
                GET IT ON <br /> <span class="big-txt">Google Play</span>
              </span>
            </Button>
          </div>
          <div className="social__title">Longevity Card Corporate</div>
          <div className="social__button">
            <Button
              onClick={() => {
                window.open(
                  `https://apps.apple.com/tr/app/longevity-card-corporate/id1571935136`,
                  "_blank"
                );
              }}
            >
              <AppleOutlined />
              <span>
                {" "}
                Download on the <br /> <span class="big-txt">App Store</span>
              </span>
            </Button>
            <Button
              onClick={() => {
                window.open(
                  `https://play.google.com/store/apps/details?id=cards.longevity.business`,
                  "_blank"
                );
              }}
            >
              <Icon component={GooglePlay} />

              <span>
                {" "}
                GET IT ON <br /> <span class="big-txt">Google Play</span>
              </span>
            </Button>
          </div>
        </Form>
      </Col>
    </>
  );
}

export default LongevityMarketplace;
