export const DEAL_LIST_REQUEST = "DEAL_LIST_REQUEST";
export const DEAL_LIST_SUCCESS = "DEAL_LIST_SUCCESS";
export const DEAL_LIST_ERROR = "DEAL_LIST_ERROR";

export const ORGANIZER_DEAL_LIST_REQUEST = "ORGANIZER_DEAL_LIST_REQUEST";
export const ORGANIZER_DEAL_LIST_SUCCESS = "ORGANIZER_DEAL_LIST_SUCCESS";
export const ORGANIZER_DEAL_LIST_ERROR = "ORGANIZER_DEAL_LIST_ERROR";

export const DELETE_DEAL_REQUEST = "DELETE_DEAL_REQUEST";
export const DELETE_DEAL_SUCCESS = "DELETE_DEAL_SUCCESS";
export const DELETE_DEAL_ERROR = "DELETE_DEAL_ERROR";
