import {
  GET_ADMIN_IP_INVESTMENT_REQUEST,
  GET_ADMIN_IP_INVESTMENT_SUCCESS,
  GET_ADMIN_IP_INVESTMENT_ERROR,
  UPDATE_IP_INVESTMENT_STATUS_ERROR,
  UPDATE_IP_INVESTMENT_STATUS_REQUEST,
} from "./constants";

const initialState = {
  loading: false,
  data: [],
};

export default function adminIPInvestmentList(state = initialState, action) {
  switch (action.type) {
    case GET_ADMIN_IP_INVESTMENT_REQUEST:
      return { ...state, loading: true };
    case GET_ADMIN_IP_INVESTMENT_SUCCESS:
      return {
        ...state,
        data: action.data,
        loading: false,
      };
    case GET_ADMIN_IP_INVESTMENT_ERROR:
      return { ...state, loading: false };
    case UPDATE_IP_INVESTMENT_STATUS_REQUEST:
      return { ...state, loading: true };
    case UPDATE_IP_INVESTMENT_STATUS_ERROR:
      return { ...state, data: action.data, loading: false };
    default:
      return state;
  }
}
