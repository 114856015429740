export const FacilitatorsIcon = () => (
  <svg
    width="33"
    height="38"
    viewBox="0 0 33 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.25781 19.2266H1.58594C1.1761 19.2266 0.84375 19.5589 0.84375 19.9688C0.84375 20.3786 1.1761 20.7109 1.58594 20.7109H4.25781V35.8047C4.25781 36.2145 4.59016 36.5469 5 36.5469H9.89844C10.3083 36.5469 10.6406 36.2145 10.6406 35.8047C10.6406 35.3949 10.3083 35.0625 9.89844 35.0625H5.74219V20.7109H9.89844C10.3083 20.7109 10.6406 20.3786 10.6406 19.9688C10.6406 19.5589 10.3083 19.2266 9.89844 19.2266H5.74219V4.875H9.89844C10.3083 4.875 10.6406 4.54265 10.6406 4.13281C10.6406 3.72298 10.3083 3.39062 9.89844 3.39062H5C4.59016 3.39062 4.25781 3.72298 4.25781 4.13281V19.2266Z"
      fill="#6B7280"
    />
    <path
      d="M29.1829 37H18.8171C17.2038 37 16.2544 35.188 17.1728 33.8616L18.5574 31.8616C18.9309 31.322 19.5455 31 20.2018 31H27.7982C28.4545 31 29.0691 31.322 29.4426 31.8616L30.8272 33.8616C31.7456 35.188 30.7962 37 29.1829 37Z"
      stroke="#6B727F"
      stroke-width="1.5"
    />
    <circle cx="24" cy="26" r="3" stroke="#6B727F" stroke-width="1.5" />
    <path
      d="M29.1829 15H18.8171C17.2038 15 16.2544 13.188 17.1728 11.8616L18.5574 9.86158C18.9309 9.32198 19.5455 9 20.2018 9H27.7982C28.4545 9 29.0691 9.32198 29.4426 9.86158L30.8272 11.8616C31.7456 13.188 30.7962 15 29.1829 15Z"
      stroke="#6B727F"
      stroke-width="1.5"
    />
    <circle cx="24" cy="4" r="3" stroke="#6B727F" stroke-width="1.5" />
  </svg>
);
