import React from "react";
import { Row, Col, List } from "antd";
import { PaperClipOutlined } from "@ant-design/icons";

const PastFundraises = () => {
  const sharedlinklist = ["Balance", "Income Statement", "Cash Flow"];
  return (
    <div className="tabled">
      <Row gutter={[24, 0]}>
        <Col xs={24} sm={24} md={12} xl={12} xxl={12}>
          <div className="pastfundraises_list">
            <List
              dataSource={sharedlinklist}
              pagination={false}
              bordered={false}
              className="ant-border-space"
              renderItem={(item) => (
                <List.Item>
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a key="list-loadmore-edit">
                    <PaperClipOutlined /> {item}
                  </a>
                </List.Item>
              )}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default PastFundraises;
