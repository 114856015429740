import axios from "axios";
import queryString from "qs";

const longevityApi = axios.create({
  baseURL: "https://proxy.longevity.cards/longevityapi/investtech/",
  paramsSerializer: (params) =>
    queryString.stringify(params, { arrayFormat: "repeat" }),
  headers: {
    Accept: "*/*",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "*",
    "Access-Control-Allow-Headers": "*",
    // "Content-Type": "application/json",
  },
});

export const getLongevityCategory = (params = "") => {
  let url = `/category`;
  if (params) {
    url = `/category/${params?.categoryId}`;
  }
  return longevityApi.get(url);
};

export const getPartners = () => {
  let url = `/partners`;
  return longevityApi.get(url);
};
