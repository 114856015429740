import React from "react";
import _ from "lodash";
import { Card, Image } from "antd";
import Paragraph from "antd/lib/typography/Paragraph";
import Title from "antd/lib/typography/Title";
import FallbackImg from "../../../assets/images/default-fallback-image.png";

const InvestorCard = ({ record, setInvestorDetail, setOpenDrawer }) => {
  const handleDetail = () => {
    let data = _.omitBy(
      record,
      (v) => v === null || v === "" || v === undefined || Array.isArray(v)
    );
    setInvestorDetail({
      data,
      industryGroups: record["Industry Groups"] || [],
    });
    // getMatchingTools(record["Industry Groups"] || []);
    setOpenDrawer(true);
  };

  return (
    <Card
      className="marketplace flex-1"
      onClick={() => handleDetail()}
      cover={
        <div className="card-cover-img">
          <div
            style={{
              overflow: "hidden",
              height: "220px",
              background: "rgb(3, 129, 169, 0.3)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "8px",
            }}
          >
            <Image
              className="cover-img"
              alt="example"
              src={
                record?.logo
                  ? "https://platform.dkv.global/files/" + record.logo
                  : FallbackImg
              }
              fallback={FallbackImg}
              preview={false}
            />
          </div>
        </div>
      }
    >
      <div className="marketplace-body">
        <Title
          level={4}
          className="marketplace-body__title"
          ellipsis={{ tooltip: record?.title }}
          style={{ width: "90%" }}
        >
          {record.title}
        </Title>
        <Paragraph
          className="marketplace-body__description"
          ellipsis={{ rows: 3, tooltip: record?.description }}
        >
          {record?.description}
        </Paragraph>
      </div>
    </Card>
  );
};

export default InvestorCard;
