import { GET_IPS_LIST_REQUEST, ADMIN_IP_UPDATE_REQUEST } from "./constants";

export const getAdminIpsListRequest = () => {
  return {
    type: GET_IPS_LIST_REQUEST,
  };
};

export const adminIpsUpdateRequest = (data) => {
  return {
    type: ADMIN_IP_UPDATE_REQUEST,
    data,
  };
};
