import React, { useState } from "react";
import {
  Button,
  Card,
  Col,
  Divider,
  List,
  message,
  Progress,
  Row,
  Space,
  Tooltip,
  Typography,
} from "antd";

import ReactPlayer from "react-player";
import { dealDetailListData, dealDetailListDataMA } from "../../../data/data";
import { BASE_URL } from "../../../api";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getUser } from "../../../Utils/isAuth";
import { publishDeal, unPublishDeal } from "../redux/action";
import { getDealInvesting } from "../../investPage/redux/action";
import DealTypeSelectionModal from "../../common/dealTypeSelectionModal";

const { Text } = Typography;

const nf = new Intl.NumberFormat();

function DealDetailIntro({ projectData={}, dealDetail = {}, userRole }) {
  const history = useNavigate();
  const dispatch = useDispatch();
  const user = getUser();

  const published = useSelector((state) => state.dealDetails.published);
  const { investMessage, investStatus } = useSelector(
    (state) => state.dealDetails
  );

  const [isModalOpen, setIsModalOpen] = useState(false);

  const {
    progress_bar,
    funding_target,
    background_video,
    is_m_and_a,
    price,
    currency,
    id,
    is_draft,
    is_published,
    status,
  } = dealDetail;

  const bg_video = projectData?.links?.find(item => item.type === "bg_video")?.link;

  const dealInvestmentStatus = useSelector(
    (state) => state.dealDetails.data.investment_status
  );

  const handleInvestClick = () => {
    if (user?.is_onboarded || user?.onboardingStepsDone?.onboarding_status) {
      dispatch(
        getDealInvesting({
          deal_id: id,
        })
      );
      history("/invest");
    } else {
      message.error("Please fill your details to process further !");
      history("/settings");
    }
  };

  const InvestButtons = () => {
    const getButtonLabel = () => {
      switch (investStatus) {
        case "Active":
          return `${is_m_and_a ? "Deal" : "Investment"} In Progress`;
        case "In Progress":
          return `${is_m_and_a ? "Deal" : "Investment"} In Progress`;
        case "Completed":
          return `${is_m_and_a ? "Deal" : "Investment"} complete`;
        case "In Review":
          return `${is_m_and_a ? "Deal" : "Investment"} under review`;
        default:
          return is_m_and_a ? "Buy" : "Invest";
      }
    };

    return (
      <Button
        className="header-btns"
        disabled={
          investMessage === "Already Invested" || investMessage === "Completed"
        }
        type={
          investMessage === "Already Invested" || investMessage === "Completed"
            ? "text"
            : ""
        }
        onClick={handleInvestClick}
      >
        {getButtonLabel()}
      </Button>
    );
  };

  const OrganizerButtons = ({ status, dispatch, id, setIsModalOpen }) => {
    const formData = new FormData();
    formData.append("deal_id", id);

    return user?.user_id === dealDetail?.organizer?.id ? (
      <div className="org-buttons">
        {status !== "Completed" && dealInvestmentStatus !== "Completed" && (
          <Tooltip title={is_draft ? "Can't publish deal in draft" : ""}>
            <Button
              onClick={() => {
                if (is_published || published) {
                  dispatch(unPublishDeal(formData));
                } else {
                  dispatch(publishDeal(formData));
                }
              }}
              disabled={is_draft}
              // style={{ color: is_published || published ? "#16B364" : "" }}
              type={"primary"}
              tytle={"awdawdf"}
            >
              {is_published || published ? "Unpublish" : "Publish"}
            </Button>
          </Tooltip>
        )}

        <Button
          onClick={() => setIsModalOpen(true)}
          style={{ color: "#0b5394", marginLeft: "5px" }}
        >
          Manage Deal Type
        </Button>
      </div>
    ) : null;
  };

  return (
    <div className="deal-intro">
      <Row gutter={[24, 0]}>
        <Col xs={24} sm={24} md={12}>
          <div className="intro-video">
            <ReactPlayer
              url={
                bg_video?.includes("http")
                  ? bg_video
                  : `${BASE_URL}${projectData?.imagesPath}${bg_video}`
              }
              controls={true}
              className="intro-video"
            />
          </div>
        </Col>
        {is_m_and_a ? (
          <Col xs={24} sm={24} md={12}>
            <div className="card_detail_head card_details_deals_amount">
              <div className="card_detail_head ">Price</div>
              <div className="card_detail_price">
                <Tooltip title={nf.format(Number(price))}>
                  <Text ellipsis={true} style={{ maxWidth: "150px" }}>
                    {`${nf.format(Number(price))}`}
                  </Text>{" "}
                  <Text ellipsis={true} style={{ width: "50px" }}>
                    {currency}
                  </Text>
                </Tooltip>
                {userRole === "ORG" && (
                  <OrganizerButtons
                    status={dealDetail?.status}
                    dispatch={dispatch}
                    id={dealDetail.id}
                    setIsModalOpen={setIsModalOpen}
                  />
                )}
                {userRole === "INV" && <InvestButtons />}
              </div>
            </div>
            <Divider type="horizontal" className="mb-0" />
            <List
              grid={{ gutter: 24, xs: 1, sm: 2, md: 2, lg: 2, xl: 2, xxl: 2 }}
              dataSource={dealDetailListDataMA(dealDetail)}
              bordered={false}
              className="deal-intro-list deal-intro-list-ext"
              renderItem={(item) => (
                <List.Item>
                  <Card className="list-item" bordered={false}>
                    <div>{item.title}</div>
                    <Space>
                      <span className="list-item__value">{item.value}</span>
                      {item?.linkedinicon}
                      {item?.fbicon}
                      {/* {item?.twittericon} */}
                    </Space>
                  </Card>
                </List.Item>
              )}
            />
          </Col>
        ) : (
          <Col xs={24} sm={24} md={12}>
            <div className="card_detail_head ">
              Raise complete -50%
            </div>
            <div className="card_detail_head card_details_deals_amount">
              <div>
                <Tooltip title={`00`}>
                  <Text ellipsis={true} style={{ maxWidth: "150px" }}>
                    {`00`}
                  </Text>{" "}
                  <Text ellipsis={true} style={{ width: "50px" }}>
                    {currency}
                  </Text>
                </Tooltip>
              </div>
              {userRole === "ORG" && (
                <OrganizerButtons
                  status={dealDetail?.status}
                  dispatch={dispatch}
                  id={dealDetail.id}
                  setIsModalOpen={setIsModalOpen}
                />
              )}
              {userRole === "INV" && <InvestButtons />}
            </div>
            <div>
              <Progress
                strokeColor={"#0B5394"}
                size="small"
                percent={progress_bar}
                showInfo={false}
              />
            </div>
            <List
              grid={{ gutter: 24, xs: 1, sm: 2, md: 2, lg: 2, xl: 2, xxl: 2 }}
              dataSource={dealDetailListData(dealDetail)}
              bordered={false}
              className="deal-intro-list deal-intro-list-ext"
              renderItem={(item) => (
                <List.Item>
                  <Card className="list-item" bordered={false}>
                    <div>{item.title}</div>
                    <Space>
                      <span className="list-item__value">{item.value}</span>
                      {item?.linkedinicon}
                      {item?.fbicon}
                      {item?.twittericon}
                    </Space>
                  </Card>
                </List.Item>
              )}
            />
          </Col>
        )}
      </Row>

      <DealTypeSelectionModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
    </div>
  );
}

export default DealDetailIntro;
