import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CommonTable from "../common/commonTable";
import { getAdminDealsListRequest } from "./redux/action";

import "./style.scss";

const AdminDeals = ({ columns, deviceColumns }) => {
  const adminDealsListState = useSelector((state) => state.adminDealsList);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAdminDealsListRequest());
  }, [dispatch]);

  const adminDealsKeys = [
    "All",
    "In Review",
    "Compliance Check",
    "Active",
    "In Progress",
    "Completed",
  ];

  return (
    <div className="admin-deals">
      <CommonTable
        columns={columns}
        deviceColumns={deviceColumns}
        allData={adminDealsListState}
        keys={adminDealsKeys}
      />
    </div>
  );
};

export default AdminDeals;
