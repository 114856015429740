import {
  SERVICE_MARKETPLACE_LIST_ERROR,
  SERVICE_MARKETPLACE_LIST_SUCCESS,
  SERVICE_MARKETPLACE_LIST_REQUEST,
} from "./constants";

const initialState = {
  loading: false,
  data: [],
};

export default function serviceMarketplaceListReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case SERVICE_MARKETPLACE_LIST_REQUEST:
      return { ...state, loading: true };
    case SERVICE_MARKETPLACE_LIST_SUCCESS:
      return {
        ...state,
        data: action.data,
        loading: false,
      };
    case SERVICE_MARKETPLACE_LIST_ERROR:
      return { ...state, loading: false };

    default:
      return state;
  }
}
