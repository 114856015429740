import { call, put, takeLatest } from "redux-saga/effects";
import jwt from "jwt-decode";
import {
  getToken,
  getUser,
  setRefreshToken,
  setUserSession,
  setRememberMe,
  getRefreshToken,
  removeRememberMe,
} from "../../../src/Utils/isAuth";

import {
  signUp,
  signIn,
  selectAccount,
  addinvestorType,
  forgotPassword,
  verifyForgotPassword,
  changePassword,
  resendSignupVerify,
  verifyToken,
  getUserRole,
  uploadImage,
  registerCompanyProfile,
  postKybRequest,
  questionnaireStatic,
  sourceOfFund,
  addBankAccount,
  getSourceOfFundsTemplateReq,
  addIndividualBank,
  getUserProfileApi,
  updateProfile,
  getUserDetailsApi,
  resetTokenRequest,
  socialVerifyingApi,
} from "../../api/index";
import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  SIGNUP_SUCCESS,
  SIGNUP_REQUEST,
  SIGNUP_ERROR,
  ACCOUNT_SELECTION_REQUEST,
  ACCOUNT_SELECTION_SUCCESS,
  ACCOUNT_SELECTION_ERROR,
  INVESTOR_TYPE_REQUEST,
  INVESTOR_TYPE_SUCCESS,
  INVESTOR_TYPE_ERROR,
  REGISTER_PROFILE_REQUEST,
  REGISTER_PROFILE_SUCCESS,
  REGISTER_PROFILE_ERROR,
  REGISTER_IDENTITY_REQUEST,
  REGISTER_IDENTITY_SUCCESS,
  REGISTER_IDENTITY_ERROR,
  UPLOAD_IMAGE_REQUEST,
  UPLOAD_IMAGE_SUCCESS,
  UPLOAD_IMAGE_ERROR,
  ADD_BANK_REQUEST,
  ADD_BANK_SUCCESS,
  ADD_BANK_ERROR,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR,
  VERIFY_FORGOT_PASSWORD_REQUEST,
  VERIFY_FORGOT_PASSWORD_REQUEST_SUCCESS,
  VERIFY_FORGOT_PASSWORD_REQUEST_ERROR,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_REQUEST_SUCCESS,
  CHANGE_PASSWORD_REQUEST_ERROR,
  RESEND_SIGNUP_VERIFY_LINK,
  RESEND_SIGNUP_VERIFY_LINK_SUCCESS,
  RESEND_SIGNUP_VERIFY_LINK_ERROR,
  VERIFY_TOKEN_REQUEST,
  VERIFY_TOKEN_REQUEST_SUCCESS,
  VERIFY_TOKEN_REQUEST_ERROR,
  USER_ROLE_REQUEST,
  USER_ROLE_ERROR,
  POST_KYB_REQUEST,
  POST_KYB_REQUEST_SUCCESS,
  POST_KYB_REQUEST_ERROR,
  REGISTER_COMPANY_PROFILE_REQUEST,
  REGISTER_COMPANY_PROFILE_SUCCESS,
  REGISTER_COMPANY_PROFILE_ERROR,
  QUESTIONNAIRE_STATIC_REQUEST,
  QUESTIONNAIRE_STATIC_SUCCESS,
  QUESTIONNAIRE_STATIC_ERROR,
  SOURCE_OF_FUNDS_REQUEST,
  SOURCE_OF_FUNDS_SUCCESS,
  SOURCE_OF_FUNDS_ERROR,
  SOURCE_OF_FUNDS_TEMPLATE_REQUEST,
  SOURCE_OF_FUNDS_TEMPLATE_SUCCESS,
  SOURCE_OF_FUNDS_TEMPLATE_ERROR,
  ADD_INDIVIDUAL_BANK_REQUEST,
  ADD_INDIVIDUAL_BANK_SUCCESS,
  ADD_INDIVIDUAL_BANK_ERROR,
  REFRESH_TOKEN_REQUEST,
  REFRESH_TOKEN_ERROR,
  FORGOT_PASSWORD_STATE_INITIAL,
  FORGOT_PASSWORD_STATE_INITIAL_SUCCESS,
  REFRESH_DATA,
  SOCIAL_LOGIN_REQUEST
} from "../auth/constants";
import { refreshTokenAction, userRoleSuccess } from "./action";
import { getNextSteps } from "../../Utils/onboardingUtils";
import { message, Modal } from "antd";

function* requestSignUp(action) {
  try {
    let { data } = yield call(signUp, action.data);
    if (data.email) {
      yield put({ type: SIGNUP_SUCCESS, data: "" });
    }
  } catch ({ response }) {
    if (response) {
      yield put({
        type: SIGNUP_ERROR,
        data:
          response?.data?.error ||
          response?.data?.Error ||
          response?.data?.msg ||
          response?.data?.errors || response?.data?.message,
      });
    } else {
      yield put({ type: SIGNUP_ERROR, data: "Something went wrong" });
    }
  }
}

function* refreshTokenTask() {
  try {
    const refreshToken = getRefreshToken();
    const { data } = yield call(resetTokenRequest, {
      refresh: refreshToken,
    });
    if (data) {
      const user = yield call(getUserProfileApi, data.access);
      const userDetail = yield call(getUserDetailsApi, data.access);
      const userRole = yield call(getUserRole, data.access);

      setUserSession(data.access, user?.data?.logo, userDetail?.data, {
        type_of_account: userRole?.data?.type,
        sub_type_of_account: userRole?.data?.subtype,
      });
      yield put({ type: REFRESH_DATA });
    }
  } catch ({ response }) {
    if (response) {
      yield put({ type: REFRESH_TOKEN_ERROR, data: response?.data?.errors });
    } else {
      yield put({ type: REFRESH_TOKEN_ERROR, data: "Error" });
    }
  }
}

function* loginRequest(action) {
  localStorage.clear();
  try {
    const { data } = yield call(signIn, action.data);

    localStorage.setItem("token", data.token);
    localStorage.setItem("user", JSON.stringify(data.userData));

    yield put({
      type: LOGIN_SUCCESS,
    });
  } catch (err) {
    yield put({ type: LOGIN_ERROR, data: err });
  }
}

function* socialLoginRequest(action) {
  localStorage.clear();
  try {
    const { data } = yield call(socialVerifyingApi, action.data);

    yield put({
      type: LOGIN_SUCCESS,
    });
  } catch (err) {
    yield put({ type: LOGIN_ERROR, data: err });
  }
}

function* userRoleRequest() {
  try {
    const token = getToken();
    const { data } = yield call(getUserRole, token);

    if (data.type) {
      yield put(userRoleSuccess(data.type));
    }
  } catch (err) {
    yield put({ type: USER_ROLE_ERROR, data: err });
  }
}

function* accountTypeRequest(action) {
  try {
    let { data } = yield call(selectAccount, action.data);
    if (data.token) {
      setUserSession(data.token.access);
      yield put({ type: ACCOUNT_SELECTION_SUCCESS });
    }
  } catch (err) {
    yield put({ type: ACCOUNT_SELECTION_ERROR });
  }
}

function* investorTypeRequest(action) {
  const user = getUser();
  const step = getNextSteps(
    0,
    user?.onboardingStepsDone,
    user?.sub_type_of_account
  );
  try {
    const { data } = yield call(addinvestorType, action.data);

    if (data.msg) {
      yield put({ type: INVESTOR_TYPE_SUCCESS, data: step });
    }
  } catch (err) {
    yield put({ type: INVESTOR_TYPE_ERROR, data: step });
  }
}

function* registerProfileRequest(action) {
  const user = getUser();
  const step = getNextSteps(
    1,
    user?.onboardingStepsDone,
    user?.sub_type_of_account
  );
  try {
    let { data } = yield call(updateProfile, action.data);

    if (data.Msg) {
      yield put({ type: REGISTER_PROFILE_SUCCESS, data: step });
    }
  } catch (err) {
    yield put({ type: REGISTER_PROFILE_ERROR, data: step });
  }
}

function* registerCompanyProfileRequest(action) {
  const user = getUser();
  const step = getNextSteps(
    1,
    user?.onboardingStepsDone,
    user?.sub_type_of_account
  );
  try {
    let { data } = yield call(registerCompanyProfile, action.data);
    if (data.Msg) {
      yield put({ type: REGISTER_COMPANY_PROFILE_SUCCESS, data: step });
    }
  } catch ({ response }) {
    yield put({ type: REGISTER_COMPANY_PROFILE_ERROR, data: 1 });
    Object.values(response.data.errors || {}).forEach((x) => {
      message.error(x[0]);
    });
  }
}

function* registerIdentityRequest(action) {
  try {
    let { data } = yield call(addinvestorType, action.data);
    if (data.msg) {
      yield put({ type: REGISTER_IDENTITY_SUCCESS });
    }
  } catch (err) {
    yield put({ type: REGISTER_IDENTITY_ERROR });
  }
}

function* uploadImageRequest(action) {
  const user = getUser();
  const step = getNextSteps(
    2,
    user?.onboardingStepsDone,
    user?.sub_type_of_account
  );
  try {
    let { data } = yield call(uploadImage, action.data);
    if (data.Msg) {
      yield put({ type: UPLOAD_IMAGE_SUCCESS, data: step });
    }
  } catch (err) {
    yield put({ type: UPLOAD_IMAGE_ERROR, data: step });
  }
}

function* addBankRequest(action) {
  const user = getUser();
  const step = getNextSteps(
    3,
    user?.onboardingStepsDone,
    user?.sub_type_of_account
  );
  try {
    let resp = yield call(addBankAccount, action.data);

    if (resp.status === 201 || resp.status === 200) {
      yield put({ type: ADD_BANK_SUCCESS, data: step });
    }
  } catch (err) {
    yield put({ type: ADD_BANK_ERROR, data: step });
  }
}

function* forgotPasswordRequest(action) {
  try {
    let { data } = yield call(forgotPassword, action.data);
    if (data) {
      yield put({ type: FORGOT_PASSWORD_SUCCESS });
    }
  } catch (err) {
    yield put({ type: FORGOT_PASSWORD_ERROR });
  }
}

function* forgotPasswordInitial() {
  yield put({ type: FORGOT_PASSWORD_STATE_INITIAL_SUCCESS });
}

function* verifyForgotPasswordRequest(action) {
  try {
    let { data } = yield call(verifyForgotPassword, action.data);
    if (data.msg) {
      yield put({ type: VERIFY_FORGOT_PASSWORD_REQUEST_SUCCESS });
    }
  } catch (err) {
    yield put({
      type: VERIFY_FORGOT_PASSWORD_REQUEST_ERROR,
      data: err.response.data.errors,
    });
  }
}

function* changePasswordRequest(action) {
  try {
    let { data } = yield call(changePassword, action.data);
    if (data.Msg) {
      message.success(data?.Msg);
      yield put({ type: CHANGE_PASSWORD_REQUEST_SUCCESS });
      if (action.refreshData) {
        yield put(refreshTokenAction());
      }
    }

    if (data.error) {
      yield put({
        type: CHANGE_PASSWORD_REQUEST_ERROR,
      });
    }
  } catch (err) {
    yield put({
      type: CHANGE_PASSWORD_REQUEST_ERROR,
    });
    if (err?.response) {
      Modal.error({
        title: err?.response?.data?.error,
      });
    }
  }
}

function* resendSignupVerifyLink(action) {
  try {
    let { data } = yield call(resendSignupVerify, action.data);
    if (data.Msg) {
      yield put({ type: RESEND_SIGNUP_VERIFY_LINK_SUCCESS });
    }
  } catch (err) {
    yield put({
      type: RESEND_SIGNUP_VERIFY_LINK_ERROR,
      data: err.response.data.errors,
    });
  }
}

function* verifyTokenRequest(action) {
  try {
    let { data } = yield call(verifyToken, action.data);
    if (data.Msg) {
      yield put({ type: VERIFY_TOKEN_REQUEST_SUCCESS });
      if (data.token) {
        let user = yield call(getUserProfileApi, data.token.access);
        setUserSession(data.token.access, user?.data?.logo);
        setRefreshToken(data.token.refresh);
        yield put({ type: SIGNUP_SUCCESS, data: jwt(data.token.access) });
      }
    } else if (data.error) {
      yield put({ type: VERIFY_TOKEN_REQUEST_ERROR });
    }
  } catch (err) {
    yield put({
      type: VERIFY_TOKEN_REQUEST_ERROR,
      data: err.response.data.errors,
    });
  }
}

function* postKyb(action) {
  const user = getUser();
  const step = getNextSteps(
    2,
    user?.onboardingStepsDone,
    user?.sub_type_of_account
  );
  try {
    let { data } = yield call(postKybRequest, action.data);
    if (data.Msg) {
      yield put({ type: POST_KYB_REQUEST_SUCCESS, data: step });
    }
  } catch (err) {
    yield put({
      type: POST_KYB_REQUEST_ERROR,
      data: step,
    });
  }
}

function* questionnaireStaticRequest(action) {
  const user = getUser();
  const step = getNextSteps(
    3,
    user?.onboardingStepsDone,
    user?.sub_type_of_account
  );

  try {
    const token = getToken();
    let { data } = yield call(questionnaireStatic, action.data);

    if (token) {
      let userDetail = yield call(getUserDetailsApi, token);
      setUserSession(token, user?.data?.logo, userDetail?.data);
    }

    if (data.result) {
      yield put({ type: QUESTIONNAIRE_STATIC_SUCCESS, data: step });
    }
  } catch (err) {
    yield put({
      type: QUESTIONNAIRE_STATIC_ERROR,
      data: step,
    });
  }
}

function* sourceOfFundRequest(action) {
  const user = getUser();
  const step = getNextSteps(
    4,
    user?.onboardingStepsDone,
    user?.sub_type_of_account
  );
  try {
    const token = getToken();

    let { data } = yield call(sourceOfFund, action.data);
    let user = yield call(getUserProfileApi, token);
    if (data.Msg) {
      let userDetail = yield call(getUserDetailsApi, token);
      setUserSession(token, user?.data?.logo, userDetail?.data);
      yield put({ type: SOURCE_OF_FUNDS_SUCCESS, data: step });
    }
  } catch (err) {
    yield put({
      type: SOURCE_OF_FUNDS_ERROR,
      data: err,
    });
  }
}

function* getSourceOfFundsTemplate(action) {
  try {
    let { data } = yield call(getSourceOfFundsTemplateReq, action.data);
    if (data.source_of_funds) {
      window.open(data.source_of_funds);
      yield put({
        type: SOURCE_OF_FUNDS_TEMPLATE_SUCCESS,
        data: data.source_of_funds,
      });
    }
  } catch (err) {
    yield put({
      type: SOURCE_OF_FUNDS_TEMPLATE_ERROR,
      data: err,
    });
  }
}

function* addIndividualBankRequest(action) {
  const user = getUser();
  const step = getNextSteps(
    3,
    user?.onboardingStepsDone,
    user?.sub_type_of_account
  );
  try {
    let resp = yield call(addIndividualBank, action.data);

    if (resp.status === 201 || resp.status === 200) {
      yield put({ type: ADD_INDIVIDUAL_BANK_SUCCESS, data: step });
    }
  } catch (err) {
    yield put({
      type: ADD_INDIVIDUAL_BANK_ERROR,
      data: step,
    });
  }
}

function* AuthWatcher() {
  yield takeLatest(SIGNUP_REQUEST, requestSignUp);
  yield takeLatest(LOGIN_REQUEST, loginRequest);
  yield takeLatest(USER_ROLE_REQUEST, userRoleRequest);
  yield takeLatest(ACCOUNT_SELECTION_REQUEST, accountTypeRequest);
  yield takeLatest(INVESTOR_TYPE_REQUEST, investorTypeRequest);
  yield takeLatest(REGISTER_PROFILE_REQUEST, registerProfileRequest);
  yield takeLatest(REGISTER_IDENTITY_REQUEST, registerIdentityRequest);
  yield takeLatest(UPLOAD_IMAGE_REQUEST, uploadImageRequest);
  yield takeLatest(ADD_BANK_REQUEST, addBankRequest);
  yield takeLatest(FORGOT_PASSWORD_REQUEST, forgotPasswordRequest);
  yield takeLatest(FORGOT_PASSWORD_STATE_INITIAL, forgotPasswordInitial);
  yield takeLatest(VERIFY_FORGOT_PASSWORD_REQUEST, verifyForgotPasswordRequest);
  yield takeLatest(CHANGE_PASSWORD_REQUEST, changePasswordRequest);
  yield takeLatest(RESEND_SIGNUP_VERIFY_LINK, resendSignupVerifyLink);
  yield takeLatest(VERIFY_TOKEN_REQUEST, verifyTokenRequest);
  yield takeLatest(POST_KYB_REQUEST, postKyb);
  yield takeLatest(
    REGISTER_COMPANY_PROFILE_REQUEST,
    registerCompanyProfileRequest
  );
  yield takeLatest(QUESTIONNAIRE_STATIC_REQUEST, questionnaireStaticRequest);
  yield takeLatest(SOURCE_OF_FUNDS_REQUEST, sourceOfFundRequest);
  yield takeLatest(SOURCE_OF_FUNDS_TEMPLATE_REQUEST, getSourceOfFundsTemplate);
  yield takeLatest(ADD_INDIVIDUAL_BANK_REQUEST, addIndividualBankRequest);
  yield takeLatest(REFRESH_TOKEN_REQUEST, refreshTokenTask);
  yield takeLatest(SOCIAL_LOGIN_REQUEST, socialLoginRequest);
}
export default AuthWatcher;
