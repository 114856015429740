export const GRANT_MARKETPLACE_LIST_REQUEST = "GRANT_MARKETPLACE_LIST_REQUEST";
export const GRANT_MARKETPLACE_LIST_SUCCESS = "GRANT_MARKETPLACE_LIST_SUCCESS";
export const GRANT_MARKETPLACE_LIST_ERROR = "GRANT_MARKETPLACE_LIST_ERROR";

export const ADD_GRANT_REQUEST = "ADD_GRANT_REQUEST";
export const ADD_GRANT_SUCCESS = "ADD_GRANT_SUCCESS";
export const ADD_GRANT_ERROR = "ADD_GRANT_ERROR";

export const EDIT_GRANT_REQUEST = "EDIT_GRANT_REQUEST";
export const EDIT_GRANT_SUCCESS = "EDIT_GRANT_SUCCESS";
export const EDIT_GRANT_ERROR = "EDIT_GRANT_ERROR";

export const DELETE_GRANT_REQUEST = "DELETE_GRANT_REQUEST";
export const DELETE_GRANT_SUCCESS = "DELETE_GRANT_SUCCESS";
export const DELETE_GRANT_ERROR = "DELETE_GRANT_ERROR";
