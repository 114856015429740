import React from "react";
import Comments from "../../comments/Comments";

const Discussions = () => {
  return (
    <div>
      <Comments
        commentsUrl="http://localhost:3000/comments"
        currentUserId="1"
      />
    </div>
  );
};

export default Discussions;
