import { Avatar, Card, Col, Row, Spin, Tag, Empty, Image } from "antd";
import Title from "antd/lib/typography/Title";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Paragraph from "antd/lib/typography/Paragraph";

import { LoadingOutlined } from "@ant-design/icons";
import "./style.scss";
import { maMarketplaceListRequest } from "./redux/action";
import MarketPlaceTabHeader from "../../components/Common/MarketPlaceTabHeader/MarketPlaceTabHeader";
import { useLocation, useNavigate } from "react-router-dom";
import MandABlueLogo from "../../assets/images/MandABlue.svg";
import { getFirstLater, getImageURL, numberFormatter } from "../../Utils";

import FallbackImg from "../../assets/images/default-fallback-image.png";
import { BASE_URL } from "../../api";

const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;

const colors = [
  "magenta",
  "volcano",
  "orange",
  "gold",
  "lime",
  "green",
  "cyan",
  "blue",
  "geekblue",
  "purple",
  "volcano",
  "green",
];

const OrganizeCard = ({ record }) => {
  const navigate = useNavigate();
  const actions = [
    <div className="marketplace-action">
      <p className="marketplace-action__label">Price</p>
      <Title level={5} className="marketplace-action__title">
        {(numberFormatter.formatter(record?.price) || 0) + " USD"}
      </Title>
    </div>,
  ];

  return (
    <Card
      className="marketplace flex-1"
      onClick={() => {
        navigate(`/project/${record?.id}`);
      }}
      cover={
        <div className="card-cover-img">
          <div
            style={{
              overflow: "hidden",
              height: "220px",
              background: "rgb(3, 129, 169, 0.3)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Image
              className="cover-img"
              alt="example"
              src={BASE_URL + record?.deal_logo || FallbackImg}
              fallback={FallbackImg}
              preview={false}
            />
            {/*record.is_m_and_a ? (
              <div style={{ display: "flex" }}>
                <img
                  src={MandABlueLogo}
                  height="15px"
                  alt="M&A"
                  className="cover-avatar-group__logo"
                />
                {!record.is_published && (
                  <div className="cover-avatar-group__status">Unpublished</div>
                )}
              </div>
            ) : null*/}
          </div>
          {/* <div className="cover-avatar-group">
            <Avatar
              shape="round"
              size={60}
              src={getImageURL(record?.startup?.photo || "")}
              className={`${
                !record?.startup?.photo ? "shape-avatar-letter" : ""
              } shape-avatar cover-avatar-group__avatar`}
            >
              {getFirstLater(record?.startup?.name || "")}
            </Avatar>
          </div> */}
        </div>
      }
      actions={actions}
    >
      <div className="marketplace-body">
        <Title
          level={4}
          className="marketplace-body__title"
          ellipsis={{ tooltip: record?.title }}
          style={{ width: "90%" }}
        >
          {record.title}
        </Title>
        <Paragraph
          className="marketplace-body__description"
          ellipsis={{ rows: 3, tooltip: record?.description }}
        >
          {record?.description}
        </Paragraph>

        <div className="sector" style={{ marginBottom: "15px" }}>
          {record.sector?.map((el) => {
            return (
              <Tag color={colors[el?.id]} key={el.id}>
                {el.sector_name}
              </Tag>
            );
          })}
        </div>
      </div>
    </Card>
  );
};

function MAMarketplacePage() {
  const dispatch = useDispatch();
  const { state } = useLocation();

  const { data, loading } = useSelector(
    (state) => state.maMarketplaceListReducer
  );
  const [search, setSearch] = useState(state?.value || "");
  const [marketplace, setMarketplace] = useState([]);

  useEffect(() => {
    dispatch(maMarketplaceListRequest());
  }, [dispatch]);

  useEffect(() => {
    let searchData = data ? [...data] : [];

    if (search) {
      searchData = searchData?.filter((item) => {
        if (
          item?.title.toString().toLowerCase().includes(search?.toLowerCase())
        ) {
          return item;
        }
        return false;
      });
    }

    setMarketplace(searchData);
  }, [data, search]);

  const searchFilter = (e) => {
    setSearch(e.target.value);
  };

  return (
    <>
      {loading && (
        <div className="spinner_background">
          <Spin className="spinner" indicator={antIcon} />
        </div>
      )}
      <MarketPlaceTabHeader
        setVisible={false}
        searchFilter={searchFilter}
        search={search}
      />
      {marketplace.length <= 0 && (
        <div className="no-data-mamp">
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        </div>
      )}
      <Row gutter={[16, 16]}>
        {marketplace.map((el) => {
          return (
            <Col md={8} sm={24} key={el.id} className="d-flex-card">
              <OrganizeCard record={el} />
            </Col>
          );
        })}
      </Row>
    </>
  );
}

export default MAMarketplacePage;
