import { Col, Row, Tabs } from "antd";
import React from "react";
import { isInvestor } from "../../Utils";
import AnalyticsTab from "./tabs/analyticsTab/AnalyticsTab";

import CommentsTab from "./tabs/commentsTab/CommentsTab";
import ConditionsTab from "./tabs/conditionsTab/ConditionsTab";
import DataRoomTab from "./tabs/dataRoom/DataRoomTab";
import FinancialsTab from "./tabs/financialsTab/FinancialsTab";
import TeamTab from "./tabs/teamTab/TeamTab";
import { isStartup } from "../../Utils";
import { getUser } from "../../Utils/isAuth";

const { TabPane } = Tabs;

function InvestorDealDetail({ dealDetail, userRole }) {
    const user = getUser();

  return (
    <Row gutter={[24, 0]}>
      <Col sx={24} md={24} lg={24} xl={24}>
        <Tabs
          defaultActiveKey="1"
          className="profile-tab-custom"
          destroyInactiveTabPane={true}
        >
          <TabPane tab="Overview" key="1">
            <div
              dangerouslySetInnerHTML={{ __html: dealDetail?.introduction }}
            />
          </TabPane>

          {
            isInvestor() &&
            <TabPane tab="Details" key="4">
              <ConditionsTab data={dealDetail} />
            </TabPane>
          }

          <TabPane tab="Financials" key="2">
            <FinancialsTab data={dealDetail} />
          </TabPane>

          <TabPane tab="Team" key="3">
            <TeamTab data={dealDetail.team} />
          </TabPane>

          {
            !isInvestor() &&
            <TabPane tab="Conditions" key="4">
              <ConditionsTab data={dealDetail} />
            </TabPane>
          }

          <TabPane tab="Discussions" key="6">
            <CommentsTab data={dealDetail} />
          </TabPane>

          <TabPane tab="Data Room" key="9">
            <DataRoomTab
              data={dealDetail}
              userRole={userRole}
              destroyInactiveTabPane={true}
            />
          </TabPane>
           {isStartup()&& user?.user_id === dealDetail?.deal_info?.organizer?.id &&<TabPane tab="Analytics" key="7">
            <AnalyticsTab
              data={dealDetail} 
              userRole={userRole}
              destroyInactiveTabPane={true}
            />
          </TabPane>}
        </Tabs>
        
      </Col>
    </Row>
  );
}

export default InvestorDealDetail;
