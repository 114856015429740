import profilavatar from "../../../assets/images/dealprofile.png";

export const mockDeal = {
  deal_logo: profilavatar,
  title: "Global Flowers",
  sector: [
    { id: 1, sector_name: "Robotics" },
    { id: 1, sector_name: "Nanotech" },
  ],
  description: "Company with a big perspective in future investigation!",
  introduction: "<p><strong>HI</strong><em>I am out of this league</em>",
  company_website: "https://www.globalf.com/feed",
  background_video: "http://techslides.com/demos/sample-videos/small.mp4",
  linkedin_link: "https://www.facebook.com/feed",
  facebook_link: "https://www.facebook.com/feed",
  twitter_link: "https://www.facebook.com/feed",
  past_fund_raisers: [
    {
      round_name: "Seeds",
      date: "2022-04-27",
      amount: 1000.0,
      comments: "Done Done Done",
    },
  ],
  kpi: [
    {
      date: "2022-04-27",
      revenue: 20000.0,
      short_term_debt: 100.0,
      cash_on_hand: 500.0,
      net_profit: 800.0,
    },
  ],
  links: [
    { title: "LinkedIn", link: "https://www.linkedin.com/feed" },
    { title: "Facebook", link: "https://www.facebook.com/feed" },
  ],
  funding_target: 1000,
  minimal_closing_amount: 5000,
  minimal_investment: 500,
  management_fee: "4%",
  carried_interest: "3%",
  date_available_from: "07-05-2022",
  date_available_to: "2022-04-27",
  investor_type: "CMP",
  company_documents: "dwddwd",
  team: [
    {
      team_member_photo: profilavatar,
      first_name: "wadawd",
      last_name: "awdawd",
      position: "Dev",
      linkedin_link: "https://www.linkedin.com/feed",
      facebook_link: "https://www.facebook.com/feed",
    },
    {
      team_member_photo: profilavatar,
      first_name: "wadawd",
      last_name: "awdawd",
      position: "Dev",
      linkedin_link: "https://www.linkedin.com/feed",
      facebook_link: "https://www.facebook.com/feed",
    },
    {
      team_member_photo: profilavatar,
      first_name: "wadawd",
      last_name: "awdawd",
      position: "Dev",
      linkedin_link: "https://www.linkedin.com/feed",
      facebook_link: "https://www.facebook.com/feed",
    },
    {
      team_member_photo: profilavatar,
      first_name: "wadawd",
      last_name: "awdawd",
      position: "Dev",
      linkedin_link: "https://www.linkedin.com/feed",
      facebook_link: "https://www.facebook.com/feed",
    },
  ],

  raise: "$16000",
  progress: 60,
  daysLeft: 24,
  minInvestment: "$1,000",
  numberInvestors: 54,
  avgTicket: "$5000",
};

export const mockDeal2 = {
  deal_logo: "/files/deal_logo_images/passport_front.jpg",
  title: "Aertliii",
  progress_bar: 10,
  sector: [
    {
      id: 1,
      sector_name: "AI",
    },
  ],
  no_of_investors: 0,
  investor_photo: [],
  average_ticket: 0,
  description: "Lorem ipsum sit amet, consectetur adipiscing elit.",
  background_video: "/files/deal_videos_images/Old_footage.mp4",
  company_website: "https://www.ai.com/",
  country_region: "United States",
  linkedin_link: "https://www.linkedin.com/feed",
  facebook_link: "https://www.facebook.com/feed",
  twitter_link: "https://www.twitter.com/feed",
  introduction: "<p>HI</p>",
  past_fund_raisers: [
    {
      id: 2,
      round_name: "Seeds",
      date: "2022-04-27",
      amount: 1000.0,
      comments: "Done Done Done",
    },
  ],
  kpi: [
    {
      id: 2,
      date: "2022-04-27",
      revenue: 20000.0,
      short_term_debt: 100.0,
      cash_on_hand: 500.0,
      net_profit: 800.0,
    },
  ],
  links: [
    {
      id: 2,
      title: "LinkedIn",
      link: "https://www.linkedin.com/feed",
    },
    {
      id: 3,
      title: "Facebook",
      link: "https://www.facebook.com/feed",
    },
  ],
  team: [
    {
      id: 2,
      team_member_photo: "/files/deal_team_member/team_photo.png",
      first_name: "Z",
      last_name: "M",
      position: "Dev",
      linkedin_link: "https://www.linkedin.com/feed",
      facebook_link: "https://www.facebook.com/feed",
    },
    {
      id: 3,
      team_member_photo: "/files/deal_team_member/team_photo_VsRlOyJ.png",
      first_name: "Zaid",
      last_name: "M",
      position: "Dev",
      linkedin_link: "https://www.linkedin.com/z",
      facebook_link: "https://www.facebook.com/z",
    },
  ],
  funding_target: 10000.0,
  minimal_closing_amount: 5000.0,
  minimal_investment: 500.0,
  management_fee: "4%",
  carried_interest: "3%",
  date_available_from: "2022-06-14",
  date_available_to: "2022-10-01",
  investor_type: "IND",
  company_documents: [
    {
      id: 2,
      file: "/files/deal_company_documents/front_DLpsLEX.png",
    },
  ],
  spv_documents: {
    download_spv_service_aggrement:
      "https://uc9cf1f2d35cce692580397df315.dl.dropboxusercontent.com/cd/0/get/BoaWwjsZmomhF1YDiLe0EgSgYEd8_h1BZcLSLKA5QQH8cIo03K-qaMiCYemu3FihiDekt7c2VxRLDGUa5RzDMSv38su1pFzbqDbT1QJBQHFAKrkMNjTi-4fefhEHuC4jFORoP9YV4DPDKN0kXWOUOa1TJ1Cy1d90ijE55w6pmPxEEAOewX-xcxHqT4PdmVoXO5M/file",
    download_memorandum_of_association: null,
  },
  days_left: 89,
  comments: [],
  spv_services_agreement_eid: "ea67f421-12a4-42b0-9bd0-60614b6e00ae",
  memorandum_of_association_eid: "",
  spv_services_agreement_status: "completed",
  memorandum_of_association_status: "not signed",
  status: "Active",
  is_draft: true,
};

export const mockDealOrganizer = {
  funding_target: 10000.0,
  committed_amount: 500,
  investment_dynamics: {
    month: "June",
    year: 2022,
    amount: 15000,
  },
  committed_amount_progress_bar: 1.5,
  transaction_info: {
    date: null,
    deal: null,
    user: null,
    amount: null,
    type_of_transaction: "",
  },
  deal_info: {
    id: 22,
    deal_logo: "/files/deal_logo_images/passport_front_wNwxtwu.jpg",
    title: "Aertificil",
    progress_bar: 10,
    sector: [
      {
        id: 1,
        sector_name: "Financial",
      },
    ],
    no_of_investors: 1,
    investor_photo: [],
    average_ticket: 15000,
    description: "Lorem ipsum sit amet, consectetur adipiscing elit.",
    background_video: "/files/deal_videos_images/front_agsUyEc.png",
    company_website: "http://www.ai.com",
    organizer: {
      name: "Zaid Marfatiya",
      email: "zaid.m@quadwave.com",
    },
    country_region: "United States",
    linkedin_link: "https://www.linkedin.com/feed",
    facebook_link: "https://www.facebook.com/feed",
    twitter_link: "https://www.twitter.com/feed",
    introduction: "",
    past_fund_raisers: [
      {
        id: 9,
        round_name: "Seeds",
        date: "2022-04-27",
        amount: 1000.0,
        comments: "1",
      },
    ],
    kpi: [
      {
        id: 9,
        date: "2022-04-27",
        revenue: 20000.0,
        short_term_debt: 100.0,
        cash_on_hand: 500.0,
        net_profit: 800.0,
      },
    ],
    links: [
      {
        id: 17,
        title: null,
        link: "https://www.linkedin.com/feed",
      },
      {
        id: 18,
        title: null,
        link: "https://www.facebook.com/feed",
      },
    ],
    team: [
      {
        id: 8,
        team_member_photo: "/files/deal_team_member/team_photo.png",
        first_name: "Z",
        last_name: "M",
        position: "Dev",
        linkedin_link: "https://www.linkedin.com/feed",
        facebook_link: "https://www.facebook.com/feed",
      },
    ],
    funding_target: 10000.0,
    minimal_closing_amount: 5000.0,
    minimal_investment: 500.0,
    management_fee: "4%",
    carried_interest: "3%",
    date_available_from: "2022-05-07",
    date_available_to: "2022-06-28",
    investor_type: "CMP",
    company_documents: [
      {
        id: 13,
        file:
          "/files/deal_company_documents/Source_of_wealth_declaration_form_-_SPV_Platform_ICAp9uO.docx",
      },
      {
        id: 14,
        file:
          "/files/deal_company_documents/Memorandum_of_Association_-_Private_Companies_Limited_by_Shares_cE7j5xH.docx",
      },
    ],
    spv_documents: {
      download_spv_service_aggrement:
        "https://uc031720caf93cc9efacf52d13a7.dl.dropboxusercontent.com/cd/0/get/BokybjHBkx8dIbojVRTezqaYk-O90ZrphdeGelF_FEr9Nn6Qz18RfJDqaxrAeMiyWdP6weDduaed2N5NYUA1NaDSTJrWzB4CqCSFPeKMB0-GbWvU-z4qmR_5plhztO_E2aIzLqJGFNNFjuptLYAbDG2tNOaKX1oAZmIM07utUdxTYsZEew4rjSEHhfZP1ADMovE/file",
      download_memorandum_of_association:
        "https://uce1f6844658746e50530d798e88.dl.dropboxusercontent.com/cd/0/get/BomhxYp5I8ya58eAMq_TmqhRzcc2FLFJL25foqB5437PektDVfWauCQVSj9eltfdWN35UAy1CNTQPKqSa0xcoSzDHWuevifX3VdehaoirMCtzQWAwTj5TlfDQwK3L1TSocIHkvLFGPRGteHBiSponE542wO4Z-BZjVVTt-5xup7YKAW2XeW7Awy-2bGSm5VbhEc/file",
    },
    days_left: 0,
    comments: [],
    spv_services_agreement_eid: "e456ac4d-829f-4361-87f9-6de3cb5188fb",
    memorandum_of_association_eid: "4d46b3bf-b96b-436e-9fa6-2f8d12017f64",
    spv_services_agreement_status: "completed",
    memorandum_of_association_status: "completed",
    status: "Active",
    is_draft: true,
    is_published: false,
  },
};
