import { Button, Result } from 'antd'
import React from 'react'
import { useNavigate } from 'react-router-dom'

const ProjectNotFound = ({ showPublishProject, onPublishClick }) => {
    const navigate = useNavigate()
    return (
        <>
            <Result
                status="404"
                title="404"
                subTitle="Sorry, the page you visited does not exist or unpublished."
                extra={
                    [
                        showPublishProject && (
                            <Button onClick={onPublishClick} type="primary">Publish Project</Button>
                        ),
                        <Button onClick={() => navigate("/")} >Back Home</Button>
                    ]
                }
            />

        </>

    )
}

export default ProjectNotFound