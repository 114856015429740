import {
  Button,
  Checkbox,
  Col,
  Modal,
  Radio,
  Row,
  Typography,
  Upload,
  message,
} from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";

import PlusOutlined from "@ant-design/icons/PlusOutlined";

import { getUser } from "../../../../Utils/isAuth";

import "./style.scss";
import UserSelect from "../../../chatPage/components/UserSelect";
import { updateAnyDocument, uploadDataRoomDocument } from "../../redux/action";
import { useEffect } from "react";
import { isInvestor } from "../../../../Utils";

const { Text } = Typography;

const dummyRequest = ({ onSuccess }) => setTimeout(() => onSuccess("ok"), 0);

const UploadModalForm = ({
  open = false,
  uploadSection,
  dealId,
  editDoc,
  handleClose,
  selectedInvestor,
}) => {
  const dispatch = useDispatch();
  const user = getUser();

  const [selectedUsers, setSelectedUsers] = useState([]);
  const [usersPermision, setUsersPermision] = useState(1);
  const [needSign, setNeedSign] = useState(false);

  const [documents, setDocuments] = useState([]);

  const handleChange = (info) => {
    if (info.file.status === "uploading") {
      return;
    }

    if (info.file.status === "done") {
      setDocuments([...documents, info.file.originFileObj]);
    }
  };

  const onChange = (e) => {
    setUsersPermision(e.target.value);
  };

  useEffect(() => {
    if (editDoc?.permission?.length > 0) {
      if (editDoc?.permission.includes("all_user")) {
        setUsersPermision(1);
      } else {
        setUsersPermision(2);
        setSelectedUsers(editDoc?.permission);
      }
    } else {
      setSelectedUsers([]);
      setUsersPermision(1);
    }

    if (editDoc?.sign_flag) {
      setNeedSign(true);
    }
  }, [editDoc]);

  const handleSubmitt = () => {
    const formData = new FormData();

    if (editDoc?.document_id || editDoc?.add_document_id) {
      if (Object.keys(editDoc).includes("document_id")) {
        formData.append("document_id", editDoc?.document_id);
      }

      if (Object.keys(editDoc).includes("add_document_id")) {
        formData.append("add_document_id", editDoc?.add_document_id);
      }

      if (!editDoc?.is_edit_permission) {
        formData.append("shouldSign", needSign ? "True" : "False");
      }

      formData.append(
        "permission",
        JSON.stringify(usersPermision === 1 ? ["all_user"] : selectedUsers)
      );

      dispatch(updateAnyDocument({ formData, dealId }));
    } else {
      if (documents.length === 0) {
        message.error(`Please upload document`);
        return;
      }

      if (uploadSection === "investment_documents") {
        formData.append(
          "user_id",
          isInvestor() ? user?.user_id : selectedInvestor
        );
      }

      formData.append("documents", documents[0]);
      formData.append("deal_id", dealId);
      formData.append("shouldSign", needSign ? "True" : "False");
      formData.append(
        "permission",
        JSON.stringify(usersPermision === 1 ? ["all_user"] : selectedUsers)
      );
      formData.append("section", uploadSection);

      dispatch(uploadDataRoomDocument({ formData, dealId }));
      setDocuments([]);
    }

    handleClose();
  };

  return (
    <Modal
      title={null}
      centered
      visible={open}
      onOk={handleSubmitt}
      onCancel={handleClose}
      width={400}
      destroyOnClose={true}
    >
      <Row gutter={[24, 0]}>
        <Col
          xs={24}
          lg={20}
          style={{
            marginBottom: "20px",
            display: "flex",
            justifyContent: "space-between",
          }}
        ></Col>
        {!editDoc ? (
          <Col
            xs={24}
            style={{
              marginBottom: "20px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Upload
              customRequest={dummyRequest}
              onChange={handleChange}
              showUploadList={true}
              maxCount={1}
              className="upload-documents"
              style={{
                width: "100%",
              }}
            >
              <Button
                type="dashed"
                block={true}
                style={{
                  height: "60px",
                  color: "rgba(107, 114, 128, 1)",
                  backgroundColor: "rgba(249, 250, 251, 1)",
                }}
              >
                <PlusOutlined />
                Add document
              </Button>
            </Upload>
          </Col>
        ) : null}

        <Col
          xs={24}
          style={{
            marginBottom: "20px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Radio.Group onChange={onChange} value={usersPermision}>
            <Radio value={1}>All users</Radio>
            <Radio value={2}>Select users or groups</Radio>
          </Radio.Group>
        </Col>

        <Col
          xs={24}
          style={{
            marginBottom: "20px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <UserSelect
            setSelectedUsers={setSelectedUsers}
            selectedUsers={selectedUsers}
            disabled={usersPermision === 1}
            docPermission={true}
          />
        </Col>
        {editDoc?.is_edit_permission ? null : (
          <>
            <Col
              xs={24}
              style={{
                marginBottom: "20px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Checkbox
                checked={needSign}
                onChange={() => setNeedSign(!needSign)}
              >
                Need Sign
              </Checkbox>
            </Col>
            <Col
              xs={24}
              style={{
                marginBottom: "20px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Text>
                To sign a document, the document must have a "Sign here" mark.
              </Text>
            </Col>
          </>
        )}
      </Row>
    </Modal>
  );
};

export default UploadModalForm;
