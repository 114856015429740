import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Divider, Image, message, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import Title from "antd/lib/typography/Title";
import { getLongevityCategory } from "../../api/longevityExternal";
import { getUserInsideLongevity } from "../../api";

const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;

function LongevityCategory() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const fetchCategory = async (showLoader = false) => {
    try {
      setLoading(showLoader);
      let res = await getLongevityCategory();
      setData(res.data || []);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      message.error("Something went wrong, While fetching investors");
    }
  };

  const checkUserInsideLongevity = async () => {
    try {
      await getUserInsideLongevity();
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    fetchCategory(true);
    checkUserInsideLongevity();
  }, []);

  return (
    <div>
      {loading && (
        <div className="spinner_background">
          <Spin className="spinner" indicator={antIcon} />
        </div>
      )}
      <Divider />
      <Title level={4}>SELECT A CATEGORY</Title>
      <div className="longevity__category">
        {data?.map((category) => {
          return (
            <div
              key={category.Id}
              style={{width: "140px" }}
              onClick={() => {
                navigate(`/longevity-marketplace/${category?.Id}`);
              }}
            >
              <Image
                width={140}
                height={100}
                src={category?.AvatarUri}
                preview={false}
                style={{objectFit:'cover'}}
              />
              <p>{category?.Name}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default LongevityCategory;
