export const ServiceMarketplaceIcon = () => (
  <svg
    width="32"
    height="33"
    viewBox="0 0 32 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 12.1509H2C0.897333 12.1509 0 11.2535 0 10.1509V2.15088C0 1.04821 0.897333 0.150879 2 0.150879H10C11.1027 0.150879 12 1.04821 12 2.15088V10.1509C12 11.2535 11.1027 12.1509 10 12.1509ZM2 1.48421C1.63333 1.48421 1.33333 1.78288 1.33333 2.15088V10.1509C1.33333 10.5189 1.63333 10.8175 2 10.8175H10C10.3667 10.8175 10.6667 10.5189 10.6667 10.1509V2.15088C10.6667 1.78288 10.3667 1.48421 10 1.48421H2Z"
      fill="#6B7280"
    />
    <path
      d="M30 12.1509H22C20.8973 12.1509 20 11.2535 20 10.1509V2.15088C20 1.04821 20.8973 0.150879 22 0.150879H30C31.1027 0.150879 32 1.04821 32 2.15088V10.1509C32 11.2535 31.1027 12.1509 30 12.1509ZM22 1.48421C21.6333 1.48421 21.3333 1.78288 21.3333 2.15088V10.1509C21.3333 10.5189 21.6333 10.8175 22 10.8175H30C30.3667 10.8175 30.6667 10.5189 30.6667 10.1509V2.15088C30.6667 1.78288 30.3667 1.48421 30 1.48421H22Z"
      fill="#6B7280"
    />
    <path
      d="M10 32.1509H2C0.897333 32.1509 0 31.2535 0 30.1509V22.1509C0 21.0482 0.897333 20.1509 2 20.1509H10C11.1027 20.1509 12 21.0482 12 22.1509V30.1509C12 31.2535 11.1027 32.1509 10 32.1509ZM2 21.4842C1.63333 21.4842 1.33333 21.7829 1.33333 22.1509V30.1509C1.33333 30.5189 1.63333 30.8175 2 30.8175H10C10.3667 30.8175 10.6667 30.5189 10.6667 30.1509V22.1509C10.6667 21.7829 10.3667 21.4842 10 21.4842H2Z"
      fill="#6B7280"
    />
    <path
      d="M30 32.1509H22C20.8973 32.1509 20 31.2535 20 30.1509V22.1509C20 21.0482 20.8973 20.1509 22 20.1509H30C31.1027 20.1509 32 21.0482 32 22.1509V30.1509C32 31.2535 31.1027 32.1509 30 32.1509ZM22 21.4842C21.6333 21.4842 21.3333 21.7829 21.3333 22.1509V30.1509C21.3333 30.5189 21.6333 30.8175 22 30.8175H30C30.3667 30.8175 30.6667 30.5189 30.6667 30.1509V22.1509C30.6667 21.7829 30.3667 21.4842 30 21.4842H22Z"
      fill="#6B7280"
    />
    <path
      d="M20.6668 6.81722H6.00016C5.63216 6.81722 5.3335 6.51855 5.3335 6.15055C5.3335 5.78255 5.63216 5.48389 6.00016 5.48389H20.6668C21.0348 5.48389 21.3335 5.78255 21.3335 6.15055C21.3335 6.51855 21.0348 6.81722 20.6668 6.81722Z"
      fill="#6B7280"
    />
    <path
      d="M26.0003 26.8177H11.3337C10.9657 26.8177 10.667 26.519 10.667 26.151C10.667 25.783 10.9657 25.4844 11.3337 25.4844H26.0003C26.3683 25.4844 26.667 25.783 26.667 26.151C26.667 26.519 26.3683 26.8177 26.0003 26.8177Z"
      fill="#6B7280"
    />
    <path
      d="M26.0002 21.4839C25.6322 21.4839 25.3335 21.1852 25.3335 20.8172V6.15055C25.3335 5.78255 25.6322 5.48389 26.0002 5.48389C26.3682 5.48389 26.6668 5.78255 26.6668 6.15055V20.8172C26.6668 21.1852 26.3682 21.4839 26.0002 21.4839Z"
      fill="#6B7280"
    />
    <path
      d="M6.00016 26.8174C5.63216 26.8174 5.3335 26.5187 5.3335 26.1507V11.484C5.3335 11.116 5.63216 10.8174 6.00016 10.8174C6.36816 10.8174 6.66683 11.116 6.66683 11.484V26.1507C6.66683 26.5187 6.36816 26.8174 6.00016 26.8174Z"
      fill="#6B7280"
    />
  </svg>
);
