import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import CommonTable from "../common/commonTable";
import { organizerIpsListRequest } from "./redux/action";

import "../adminDeals/style.scss";

const OrganizerIps = ({ columns, deviceColumns }) => {
  const organizerIpLists = useSelector((state) => state.ipList || []);
  const dispatch = useDispatch();
  const [typeIP, setTypeIP] = useState('all');

  useEffect(() => {
    dispatch(organizerIpsListRequest());
  }, [dispatch]);

  const organizerIPsKeys = [
    "All",
    "Active",
    "In Progress",
    "In Review",
    "Completed",
    "Drafts",
  ];

  const organizerIPTypeList = useMemo(() => {
    return {
      ...organizerIpLists,
      data: organizerIpLists?.data.filter(
        (ele) => {
          if(typeIP === "all"){
            return true
          }
          return ele.is_register_ip === typeIP}
      ),
    };
  }, [organizerIpLists, typeIP]);

  const changeIp = (value) => {
    setTypeIP(value);
  };


  return (
    <div className="admin-deals">
      <CommonTable
        columns={columns}
        deviceColumns={deviceColumns}
        allData={organizerIPTypeList}
        keys={organizerIPsKeys}
        isIp={true}
        changeIp={changeIp}
        typeIP={typeIP}
      />
    </div>
  );
};

export default OrganizerIps;
