import {
  LONGEVITY_LOGIN_FAILURE,
  LONGEVITY_LOGIN_REQUEST,
  LONGEVITY_LOGIN_SUCCESS,
} from "./constant";

const initialState = {
  loading: false,
  loginSuccess: false,
  loginError: false,
  error: null,
};

export default function LongevityInfo(state = initialState, action) {
  switch (action.type) {
    case LONGEVITY_LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
        loginSuccess: false,
        loginError: false,
      };
    case LONGEVITY_LOGIN_SUCCESS:
      return { ...state, loginSuccess: true, loginError: false };
    case LONGEVITY_LOGIN_FAILURE:
      return { ...state, loginError: true };
    default:
      return state;
  }
}
