import { call, put, takeLatest } from "redux-saga/effects";

import {
  getAdminTopOrganizersApi,
  updateAdminTopOrganizersApi,
} from "../../../api";
import {
  GET_ADMIN_TOPORGANIZERS_REQUEST,
  GET_ADMIN_TOPORGANIZERS_SUCCESS,
  GET_ADMIN_TOPORGANIZERS_ERROR,
  UPDATE_TOPORGANIZERS_STATUS_REQUEST,
  UPDATE_TOPORGANIZERS_STATUS_ERROR,
  UPDATE_TOPORGANIZERS_STATUS_SUCCESS,
} from "./constants";

// SubscribeWatcher

function* getAdminTopOrganizersRequest(action) {
  try {
    let { data } = yield call(getAdminTopOrganizersApi, action?.data);

    if (data) {
      yield put({ type: GET_ADMIN_TOPORGANIZERS_SUCCESS, data });
    }
  } catch (err) {
    yield put({ type: GET_ADMIN_TOPORGANIZERS_ERROR });
  }
}

function* updateTopOrganizersStatusRequest(action) {
  try {
    let { data } = yield call(updateAdminTopOrganizersApi, action?.data);

    if (data?.Msg) {
      yield put({ type: UPDATE_TOPORGANIZERS_STATUS_SUCCESS, data });
      yield put({ type: GET_ADMIN_TOPORGANIZERS_REQUEST });
    }
  } catch (err) {
    yield put({ type: UPDATE_TOPORGANIZERS_STATUS_ERROR });
  }
}

function* AdminTopOrganizersWatcher() {
  yield takeLatest(
    GET_ADMIN_TOPORGANIZERS_REQUEST,
    getAdminTopOrganizersRequest
  );
  yield takeLatest(
    UPDATE_TOPORGANIZERS_STATUS_REQUEST,
    updateTopOrganizersStatusRequest
  );
}
export default AdminTopOrganizersWatcher;
