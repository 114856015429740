import React from "react";
import { List, Typography, Divider } from "antd";

const { Text } = Typography;

const LogsDisplay = ({ logs, setShowLogs }) => {
    return (
        <div style={{ width: 300, height: "100vh", overflowY: "auto", padding: "1rem", background: "#f0f2f5", position: "fixed", right: 0, top: "4rem", zIndex: 999 }}>
            <div style={{ textAlign: "right", cursor: "pointer" }} onClick={() => setShowLogs(false)}>X</div>
            <Divider orientation="left">Project Logs</Divider>
            <List
                itemLayout="vertical"
                dataSource={logs}
                renderItem={(log) => (
                    <List.Item key={log._id} style={{ cursor: "pointer", margin: "0.5rem 0" }}>
                        <List.Item.Meta
                            title={<Text strong>{log.description}</Text>}
                            description={`Action: ${log.action}`}
                        />
                        <div>
                            <Text type="secondary">
                                {new Date(log.timestamp).toLocaleString()}
                            </Text>
                        </div>
                    </List.Item>
                )}
            />
        </div>
    );
};

export default LogsDisplay;
