import {
  STATE_CREATE_IP_ERROR,
  STATE_CREATE_IP_SUCCESS,
  STATE_CREATE_IP_REQUEST,
  CLEAR_SUCCESS,
  CLEAR_ERROR,
  EDIT_IP_DETAILS_REQUEST,
  EDIT_IP_DETAILS_SUCCESS,
  EDIT_IP_DETAILS_ERROR,
  UPDATE_IP_REQUEST,
  UPDATE_IP_SUCCESS,
  UPDATE_IP_ERROR,
  RESET_IP_DETAILS,
  UPDATE_IP_STEP_REQUEST,
  UPDATE_IP_STEP_SUCCESS,
  UPDATE_IP_STEP_ERROR,
  IP_TITLES_REQUEST,
  IP_TITLES_SUCCESS,
  IP_TITLES_ERROR,
  CLEAR_IP_TITLES,
} from "./constants";

const initialState = {
  loading: false,
  stepLoading: false,
  successMessage: null,
  errorMessage: null,
  editedIP: null,
  IPId: null,
};

export default function createIp(state = initialState, action) {
  switch (action.type) {
    case STATE_CREATE_IP_REQUEST:
    case UPDATE_IP_REQUEST:
    case IP_TITLES_REQUEST:
      return { ...state, loading: true };
    case UPDATE_IP_STEP_REQUEST:
      return { ...state, stepLoading: true };

    case CLEAR_IP_TITLES:
      return { ...state, IPTitles: [] };
    case STATE_CREATE_IP_SUCCESS:
    case UPDATE_IP_SUCCESS:
      return {
        ...initialState,
        successMessage: "Success",
        editedIP: null,
      };
    case IP_TITLES_SUCCESS:
      return {
        ...initialState,
        loading: false,
        IPTitles: action.data,
      };
    case IP_TITLES_ERROR:
      return {
        ...initialState,
        loading: false,
      };
    case UPDATE_IP_STEP_SUCCESS:
      return {
        ...initialState,
        stepLoading: false,
        editedIP: null,
      };
    case STATE_CREATE_IP_ERROR:
    case UPDATE_IP_ERROR:
    case UPDATE_IP_STEP_ERROR:
      return {
        ...state,
        loading: false,
        stepLoading: false,
        errorMessage: action.data,
      };

    case EDIT_IP_DETAILS_REQUEST:
      return { ...state, loading: true };
    case EDIT_IP_DETAILS_SUCCESS:
      return {
        ...state,
        editedIP: action?.data[0],
        IPId: action?.data?.IP_info?.id,
        loading: false,
      };
    case EDIT_IP_DETAILS_ERROR:
      return { ...state, loading: false };
    case CLEAR_SUCCESS:
      return { ...state, successMessage: null };

    case CLEAR_ERROR:
      return { ...state, errorMessage: "" };

    case RESET_IP_DETAILS:
      return { ...state, editedIP: null };
    default:
      return state;
  }
}
