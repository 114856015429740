import {
  USER_FLOW_REQUEST,
  USER_FLOW_SUCCESS,
  USER_FLOW_ERROR,
} from "./constants";

const initialState = {
  loading: false,
  userFlow: null,
};

export default function userFlowReducer(state = initialState, action) {
  switch (action.type) {
    case USER_FLOW_REQUEST:
      return { ...state, loading: true };
    case USER_FLOW_SUCCESS:
      return {
        ...state,
        userFlow: action.data,
        loading: false,
      };
    case USER_FLOW_ERROR:
      return { ...state, loading: false };
    default:
      return state;
  }
}
