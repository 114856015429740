import {
    GET_DEALS_LIST_REQUEST,
    ADMIN_DEAL_UPDATE_REQUEST
} from "./constants";

export const getAdminDealsListRequest = () => {
    return {
      type: GET_DEALS_LIST_REQUEST,
    };
};

export const adminDealUpdateRequest = (data) => {
  return {
    type: ADMIN_DEAL_UPDATE_REQUEST,
    data,
  }
}