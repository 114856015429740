import React, { useState } from "react";
import { Button, Form, Input, Modal } from "antd";
import UploadAvatar from "../createDeal/forms/UploadAvatar";
import "./modalStyle.scss";
import { addGrantRequest, editGrantRequest } from "./redux/action";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { BASE_URL } from "../../api";
import { formDataWrapper } from "../../Utils";
import FallbackImg from "../../assets/images/default-fallback-image.png";

const { TextArea } = Input;

const AddGrantModal = ({
  modalOpen,
  setModalOpen,
  editGrantDetails,
  setEditGrantDetails,
}) => {
  const dispatch = useDispatch();
  const [imgFile, setImgFile] = useState();
  const [backGroundImage, setBackGroundImage] = useState();

  const [imgUrl, setImgUrl] = useState(null);
  const [coverUrl, setCoverUrl] = useState(null);

  const [form] = Form.useForm();

  useEffect(() => {
    setImgUrl(
      editGrantDetails?.photo ? BASE_URL + editGrantDetails?.photo : null
    );
    setCoverUrl(
      editGrantDetails?.background_image
        ? BASE_URL + editGrantDetails?.background_image
        : null
    );
    form.setFieldsValue(editGrantDetails);
  }, [editGrantDetails]);

  const handleCancel = () => {
    setModalOpen(false);
    setEditGrantDetails(null);
    form.resetFields();
  };

  const onFinish = (values) => {
    if (imgFile) {
      values.photo = imgFile;
    }
    if (backGroundImage) {
      values.photo = backGroundImage;
    }
    const { name = "", photo, description, background_image, website } = values;
    let payload = {
      name,
      description,
      website,
    };

    if (editGrantDetails) {
      const { id = null } = editGrantDetails;
      payload = { ...payload, grant_id: id };
    }
    if (typeof photo !== "string" && !!photo) {
      payload = { ...payload, photo };
    }
    if (typeof background_image !== "string" && !!background_image) {
      payload = { ...payload, background_image };
    }

    payload = formDataWrapper(payload);

    if (editGrantDetails) {
      dispatch(editGrantRequest(payload));
    } else {
      dispatch(addGrantRequest(payload));
    }

    form.resetFields();
    setModalOpen(false);
  };

  return (
    <Modal
      title={editGrantDetails ? "Edit Grant" : "Add a New Grant"}
      visible={modalOpen}
      footer={false}
      onCancel={handleCancel}
    >
      <Form layout="vertical" onFinish={onFinish} form={form}>
        <Form.Item style={{ position: "relative" }}>
          <Form.Item label="" name="photo">
            <UploadAvatar
              setImgFile={setImgFile}
              imgUrl={imgUrl}
              coverUrl={coverUrl || FallbackImg}
              isEditGrant={true}
              setBackGroundImage={setBackGroundImage}
            />
          </Form.Item>
        </Form.Item>
        <Form.Item
          label="Name"
          rules={[
            {
              required: true,
              message: "Field Required!",
            },
          ]}
          name="name"
        >
          <Input placeholder="Name" />
        </Form.Item>
        <Form.Item
          label="Description"
          rules={[
            {
              required: true,
              message: "Field Required!",
            },
          ]}
          name="description"
        >
          <TextArea placeholder="description" rows={4} />
        </Form.Item>
        <Form.Item
          label="Website"
          rules={[
            {
              required: true,
              message: "Field Required!",
            },
            {
              type: "url",
              message: "Please enter valid url Ex:- http://example.com",
            },
          ]}
          name="website"
        >
          <Input placeholder="http://example.com" />
        </Form.Item>
        <Form.Item className="submit-button">
          <Button
            key="cancel"
            onClick={handleCancel}
            className="modal-cancel-btn"
          >
            Cancel
          </Button>
          <Button type="primary" htmlType="submit">
            {editGrantDetails ? "Update" : "Create"}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddGrantModal;
