import React, { useState } from "react";
import "antd/dist/antd.css";

import { Avatar, Button, Comment, Form, Input, List } from "antd";

import { getUser } from "../../../../Utils/isAuth";
import { addIpComment } from "../../redux/action";
import { useDispatch } from "react-redux";

const { TextArea } = Input;

const CommentList = ({ comments }) => (
  <List
    dataSource={comments}
    header={`${comments?.length} ${comments?.length > 1 ? "replies" : "reply"}`}
    itemLayout="horizontal"
    renderItem={(props) => <Comment {...props} />}
  />
);

const Editor = ({ onChange, onSubmit, submitting, value }) => (
  <>
    <Form.Item>
      <TextArea rows={4} onChange={onChange} value={value} />
    </Form.Item>
    <Form.Item>
      <Button
        htmlType="submit"
        loading={submitting}
        onClick={onSubmit}
        type="primary"
      >
        Add Comment
      </Button>
    </Form.Item>
  </>
);

const CommentsTab = ({ data }) => {
  const dispatch = useDispatch();
  const [comments, setComments] = useState(data?.comments || []);
  const [submitting, setSubmitting] = useState(false);
  const [value, setValue] = useState("");
  const user = getUser();

  const handleSubmit = () => {
    if (!value) return;
    setSubmitting(true);

    setSubmitting(false);
    setValue("");
    setComments([
      ...comments,
      {
        author: `${user?.first_name} ${user?.last_name} (${user?.type_of_account})`,
        avatar: "https://joeschmoe.io/api/v1/random",
        content: <p>{value}</p>,
      },
    ]);

    const formData = new FormData();

    formData.append("ip", data.id);
    formData.append("content", value);

    dispatch(addIpComment(formData));
  };

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  return (
    <>
      {comments?.length > 0 && <CommentList comments={comments} />}
      <Comment
        avatar={
          <Avatar src="https://joeschmoe.io/api/v1/random" alt="Han Solo" />
        }
        content={
          <Editor
            onChange={handleChange}
            onSubmit={handleSubmit}
            submitting={submitting}
            value={value}
          />
        }
      />
    </>
  );
};

export default CommentsTab;
