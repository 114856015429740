import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { getUser } from "../Utils/isAuth";

function PrivateRoute({ children }) {
  const user = getUser();
  const location = useLocation();

  if (
    user &&
    user.roles !== "Admin" &&
    location.pathname.includes("user-listing")
  ) {
    return <Navigate to="/" />;
  }

  //return user ? children : <Navigate to="/registration" />;
  return user ? children : <Navigate to="/login" />;
}
export function AdminOnly({ children }) {
  const user = getUser();

  if (
    user &&
    (user?.roles !== "Admin" || !user?.email?.includes("dkv.global") )
  ) {
    return <Navigate to="/" />;
  }
  
  return user ? children : <Navigate to="/login" />;
}



export const AnonymousOnly = ({ children }) => {
  const user = getUser();
  if (user) {
    return <Navigate to="/" />;
  }
  return children;
}


export default PrivateRoute;
