import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  SIGNUP_REQUEST,
  SIGNUP_SUCCESS,
  SIGNUP_ERROR,
  PAGINATION_REQUEST_NEXT,
  PAGINATION_REQUEST_PREVIOUS,
  PAGINATION_REQUEST_EXACT,
  ACCOUNT_SELECTION_REQUEST,
  ACCOUNT_SELECTION_SUCCESS,
  ACCOUNT_SELECTION_ERROR,
  INVESTOR_TYPE_REQUEST,
  INVESTOR_TYPE_SUCCESS,
  INVESTOR_TYPE_ERROR,
  REGISTER_PROFILE_REQUEST,
  REGISTER_PROFILE_SUCCESS,
  REGISTER_PROFILE_ERROR,
  UPLOAD_IMAGE_REQUEST,
  UPLOAD_IMAGE_SUCCESS,
  UPLOAD_IMAGE_ERROR,
  ADD_BANK_REQUEST,
  ADD_BANK_SUCCESS,
  ADD_BANK_ERROR,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR,
  VERIFY_FORGOT_PASSWORD_REQUEST,
  VERIFY_FORGOT_PASSWORD_REQUEST_SUCCESS,
  VERIFY_FORGOT_PASSWORD_REQUEST_ERROR,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_REQUEST_SUCCESS,
  CHANGE_PASSWORD_REQUEST_ERROR,
  RESEND_SIGNUP_VERIFY_LINK,
  RESEND_SIGNUP_VERIFY_LINK_SUCCESS,
  RESEND_SIGNUP_VERIFY_LINK_ERROR,
  VERIFY_TOKEN_REQUEST,
  VERIFY_TOKEN_REQUEST_SUCCESS,
  VERIFY_TOKEN_REQUEST_ERROR,
  USER_ROLE_SUCCESS,
  USER_ROLE_REQUEST,
  USER_ROLE_ERROR,
  POST_KYB_REQUEST,
  POST_KYB_REQUEST_SUCCESS,
  POST_KYB_REQUEST_ERROR,
  REGISTER_COMPANY_PROFILE_REQUEST,
  REGISTER_COMPANY_PROFILE_SUCCESS,
  REGISTER_COMPANY_PROFILE_ERROR,
  QUESTIONNAIRE_STATIC_REQUEST,
  QUESTIONNAIRE_STATIC_SUCCESS,
  QUESTIONNAIRE_STATIC_ERROR,
  SOURCE_OF_FUNDS_REQUEST,
  SOURCE_OF_FUNDS_SUCCESS,
  SOURCE_OF_FUNDS_ERROR,
  ADD_INDIVIDUAL_BANK_REQUEST,
  ADD_INDIVIDUAL_BANK_SUCCESS,
  ADD_INDIVIDUAL_BANK_ERROR,
  SOURCE_OF_FUNDS_TEMPLATE_SUCCESS,
  FORGOT_PASSWORD_STATE_INITIAL_SUCCESS,
  REFRESH_DATA
} from "../auth/constants";
const initialState = {
  loading: false,
  signupSuccess: false,
  loginSuccess: false,
  loginFailed: false,
  error: null,
  verifyEmailFailed: false,
  forgotPasswordSuccess: false,
  forgerPasswordFailded: false,
  verifyForgotPasswordSuccess: false,
  changePasswordFailed: false,
  onboardingCurrent: 0,
  accountSelectionSuccess: false,
  changePasswordSuccess: false,
  resendVerifyEmailSuccess: false,
  current: 0,
  userRole: null,
  sourceOfFundTemplate: null,
  facOnboardingComplete: false,
  refreshPage: false
};

export default function auth(state = initialState, action) {
  switch (action.type) {
    case SIGNUP_REQUEST:
      return { ...state, loading: true ,error: null};
    case SIGNUP_SUCCESS:
      return {
        ...state,
        data: action.data,
        loading: false,
        signupSuccess: true,
        current: 1,
      };
    case SIGNUP_ERROR:
      return { ...state, error: action.data, loading: false };
    case USER_ROLE_REQUEST:
      return { ...state, loading: true };
    case USER_ROLE_SUCCESS:
      return { ...state, loading: false, userRole: action.data };
    case USER_ROLE_ERROR:
      return { ...state, loading: false };
    case RESEND_SIGNUP_VERIFY_LINK:
      return { ...state, loading: true, resendVerifyEmailSuccess: false };
    case RESEND_SIGNUP_VERIFY_LINK_SUCCESS:
      return {
        ...state,
        data: action.data,
        loading: false,
        resendVerifyEmailSuccess: true,
      };
    case RESEND_SIGNUP_VERIFY_LINK_ERROR:
      return {
        ...state,
        data: action.data,
        loading: false,
      };
    case VERIFY_TOKEN_REQUEST:
      return {
        ...state,
        loading: false,
      };
    case VERIFY_TOKEN_REQUEST_SUCCESS:
      return {
        ...state,
        data: action.data,
        loading: false,
      };
    case VERIFY_TOKEN_REQUEST_ERROR:
      return {
        ...state,
        data: action.data,
        verifyEmailFailed: true,
        loading: false,
      };
    case LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
        loginFailed: false,
        loginSuccess: false,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        onboardingCurrent: action.data,
        error:null,
        loading: false,
        loginSuccess: true,
      };
    case LOGIN_ERROR:
      return { ...state, data: action.data, loading: false, loginFailed: true };
    case FORGOT_PASSWORD_STATE_INITIAL_SUCCESS:
      return {
        ...state,
        forgotPasswordSuccess: false,
        forgotPasswordFailded: false,
      };
    case FORGOT_PASSWORD_REQUEST:
      return {
        ...state,
        data: action.data,
        loading: false,
        forgotPasswordSuccess: false,
      };
    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        data: action.data,
        loading: false,
        forgotPasswordSuccess: true,
      };
    case FORGOT_PASSWORD_ERROR:
      return {
        ...state,
        data: action.data,
        loading: false,
        forgerPasswordFailded: true,
      };
    case VERIFY_FORGOT_PASSWORD_REQUEST:
      return { ...state, loading: true };
    case VERIFY_FORGOT_PASSWORD_REQUEST_SUCCESS:
      return {
        ...state,
        data: action.data,
        loading: false,
        verifyForgotPasswordSuccess: true,
      };
    case VERIFY_FORGOT_PASSWORD_REQUEST_ERROR:
      return {
        ...state,
        data: action.data,
        loading: false,
        verifyForgotPasswordSuccess: false,
      };
    case CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
        changePasswordSuccess: false,
        changePasswordFailed: false,
      };
    case CHANGE_PASSWORD_REQUEST_SUCCESS:
      return {
        ...state,
        data: action?.data,
        loading: false,
        changePasswordSuccess: true,
      };
    case CHANGE_PASSWORD_REQUEST_ERROR:
      return {
        ...state,
        data: action.data,
        loading: false,
        changePasswordFailed: true,
      };
    case ACCOUNT_SELECTION_REQUEST:
      return { ...state, loading: true };
    case ACCOUNT_SELECTION_SUCCESS:
      return { ...state, loading: false, accountSelectionSuccess: true };
    case ACCOUNT_SELECTION_ERROR:
      return {
        ...state,
        data: action.data,
        loading: false,
      };
    case INVESTOR_TYPE_REQUEST:
      return { ...state, loading: true };
    case INVESTOR_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        onboardingCurrent: action.data,
      };
    case INVESTOR_TYPE_ERROR:
      return {
        ...state,
        data: action.data,
        loading: false,
        onboardingCurrent: action.data,
      };
    case REGISTER_PROFILE_REQUEST:
      return { ...state, loading: true };
    case REGISTER_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        onboardingCurrent: action.data,
      };
    case REGISTER_PROFILE_ERROR:
      return {
        ...state,
        data: action.data,
        loading: false,
        onboardingCurrent: action.data,
      };
    case REGISTER_COMPANY_PROFILE_REQUEST:
      return { ...state, loading: true };
    case REGISTER_COMPANY_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        onboardingCurrent: action.data,
      };
    case REGISTER_COMPANY_PROFILE_ERROR:
      return {
        ...state,
        data: action.data,
        loading: false,
        onboardingCurrent: action.data,
      };
    case UPLOAD_IMAGE_REQUEST:
      return { ...state, loading: true };
    case UPLOAD_IMAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        accountSelectionSuccess: true,
        onboardingCurrent: action.data,
      };
    case UPLOAD_IMAGE_ERROR:
      return {
        ...state,
        data: action.data,
        loading: false,
        onboardingCurrent: action.data,
      };
    case POST_KYB_REQUEST:
      return { ...state, loading: true };
    case POST_KYB_REQUEST_SUCCESS:
      return { ...state, loading: false, onboardingCurrent: action.data };
    case POST_KYB_REQUEST_ERROR:
      return { ...state, loading: false, onboardingCurrent: action.data };
    case ADD_BANK_REQUEST:
      return { ...state, loading: true };
    case ADD_BANK_SUCCESS:
      return {
        ...state,
        loading: false,
        onboardingCurrent: action.data,
      };
    case ADD_BANK_ERROR:
      return {
        ...state,
        data: action.data,
        loading: false,
        onboardingCurrent: action.data,
      };
    case ADD_INDIVIDUAL_BANK_REQUEST:
      return { ...state, loading: true };
    case ADD_INDIVIDUAL_BANK_SUCCESS:
      return {
        ...state,
        loading: false,
        onboardingCurrent: action.data,
      };
    case ADD_INDIVIDUAL_BANK_ERROR:
      return {
        ...state,
        data: action.data,
        loading: false,
        onboardingCurrent: action.data,
      };

    case QUESTIONNAIRE_STATIC_REQUEST:
      return { ...state, loading: true };
    case QUESTIONNAIRE_STATIC_SUCCESS:
      return {
        ...state,
        loading: false,
        onboardingCurrent: action.data,
        facOnboardingComplete: true,
      };
    case QUESTIONNAIRE_STATIC_ERROR:
      return {
        ...state,
        loading: false,
        onboardingCurrent: action.data,
      };

    case SOURCE_OF_FUNDS_REQUEST:
      return { ...state, loading: true };
    case SOURCE_OF_FUNDS_SUCCESS:
      return {
        ...state,
        loading: false,
        onboardingCurrent: action.data,
      };
    case SOURCE_OF_FUNDS_ERROR:
      return {
        ...state,
        loading: false,
      };
    case PAGINATION_REQUEST_NEXT:
      return {
        ...state,
        data: action.data,
        current: action.data,
        onboardingCurrent: state.onboardingCurrent + 1,
      };
    case PAGINATION_REQUEST_EXACT:
      return {
        ...state,
        data: action.data,
        current: action.data,
        forgerPasswordFailded: false,
        forgotPasswordSuccess: false,
      };
    case PAGINATION_REQUEST_PREVIOUS:
      return {
        ...state,
        data: action.data,
        onboardingCurrent: state.onboardingCurrent - 1,
      };
    case SOURCE_OF_FUNDS_TEMPLATE_SUCCESS:
      return {
        ...state,
        data: action.data,
        sourceOfFundTemplate: action.data,
      };
    case REFRESH_DATA:
      return {
        ...state,
        refreshPage: !state.refreshPage
      };
    default:
      return state;
  }
}
