export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";

export const LOGIN_ERROR = "LOGIN_ERROR";

export const SIGNUP_REQUEST = "SIGNUP_REQUEST";

export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";

export const SIGNUP_ERROR = "SIGNUP_ERROR";

export const ACCOUNT_SELECTION_REQUEST = "ACCOUNT_SELECTION_REQUEST";

export const ACCOUNT_SELECTION_SUCCESS = "ACCOUNT_SELECTION_SUCCESS";

export const ACCOUNT_SELECTION_ERROR = "ACCOUNT_SELECTION_ERROR";

export const INVESTOR_TYPE_REQUEST = "INVESTOR_TYPE_REQUEST";
export const INVESTOR_TYPE_SUCCESS = "INVESTOR_TYPE_SUCCESS";

export const INVESTOR_TYPE_ERROR = "INVESTOR_TYPE_ERROR";

export const REGISTER_PROFILE_REQUEST = "REGISTER_PROFILE_REQUEST";
export const REGISTER_PROFILE_SUCCESS = "REGISTER_PROFILE_SUCCESS";

export const REGISTER_PROFILE_ERROR = "REGISTER_PROFILE_ERROR";

export const REGISTER_IDENTITY_REQUEST = "REGISTER_IDENTITY_REQUEST";
export const REGISTER_IDENTITY_SUCCESS = "REGISTER_IDENTITY_SUCCESS";
export const REGISTER_IDENTITY_ERROR = "REGISTER_IDENTITY_ERROR";

export const USER_ROLE_REQUEST = "USER_ROLE_REQUEST";
export const USER_ROLE_SUCCESS = "USER_ROLE_SUCCESS";
export const USER_ROLE_ERROR = "USER_ROLE_ERROR";

export const UPLOAD_IMAGE_REQUEST = "UPLOAD_IMAGE_REQUEST";
export const UPLOAD_IMAGE_SUCCESS = "UPLOAD_IMAGE_SUCCESS";
export const UPLOAD_IMAGE_ERROR = "UPLOAD_IMAGE_ERROR";

export const ADD_BANK_REQUEST = "ADD_BANK_REQUEST";
export const ADD_BANK_SUCCESS = "ADD_BANK_SUCCESS";
export const ADD_BANK_ERROR = "ADD_BANK_ERROR";

export const USER_PROFILE_REQUEST = "USER_PROFILE_REQUEST";
export const USER_PROFILE_SUCCESS = "USER_PROFILE_SUCCESS";
export const USER_PROFILE_ERROR = "USER_PROFILE_ERROR";

export const PAGINATION_REQUEST_NEXT = "PAGINATION_REQUEST_NEXT";
export const PAGINATION_REQUEST_PREVIOUS = "PAGINATION_REQUEST_PREVIOUS";

export const PAGINATION_REQUEST_EXACT = "PAGINATION_REQUEST_EXACT";

export const FORGOT_PASSWORD_REQUEST = "FORGOT_PASSWORD_REQUEST";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_ERROR = "FORGOT_PASSWORD_ERROR";
export const FORGOT_PASSWORD_STATE_INITIAL = "FORGOT_PASSWORD_STATE_INITIAL";
export const FORGOT_PASSWORD_STATE_INITIAL_SUCCESS =
  "FORGOT_PASSWORD_STATE_INITIAL_SUCCESS";

export const VERIFY_FORGOT_PASSWORD_REQUEST = "VERIFY_FORGOT_PASSWORD_REQUEST";
export const VERIFY_FORGOT_PASSWORD_REQUEST_SUCCESS =
  "VERIFY_FORGOT_PASSWORD_REQUEST_SUCCESS";
export const VERIFY_FORGOT_PASSWORD_REQUEST_ERROR =
  "VERIFY_FORGOT_PASSWORD_REQUEST_ERROR";

export const CHANGE_PASSWORD_REQUEST = "CHANGE_PASSWORD_REQUEST";
export const CHANGE_PASSWORD_REQUEST_SUCCESS =
  "CHANGE_PASSWORD_REQUEST_SUCCESS";
export const CHANGE_PASSWORD_REQUEST_ERROR = "CHANGE_PASSWORD_REQUEST_ERROR";

export const RESEND_SIGNUP_VERIFY_LINK = "RESEND_SIGNUP_VERIFY_LINK";
export const RESEND_SIGNUP_VERIFY_LINK_SUCCESS =
  "RESEND_SIGNUP_VERIFY_LINK_SUCCESS";
export const RESEND_SIGNUP_VERIFY_LINK_ERROR =
  "RESEND_SIGNUP_VERIFY_LINK_ERROR";

export const VERIFY_TOKEN_REQUEST = "VERIFY_TOKEN_REQUEST";
export const VERIFY_TOKEN_REQUEST_SUCCESS = "VERIFY_TOKEN_REQUEST_SUCCESS";
export const VERIFY_TOKEN_REQUEST_ERROR = "VERIFY_TOKEN_REQUEST_ERROR";

export const REFRESH_TOKEN_REQUEST = "REFRESH_TOKEN_REQUEST";
export const REFRESH_TOKEN_SUCCESS = "REFRESH_TOKEN_SUCCESS";
export const REFRESH_TOKEN_ERROR = "REFRESH_TOKEN_ERROR";

export const POST_KYB_REQUEST = "POST_KYB_REQUEST";
export const POST_KYB_REQUEST_SUCCESS = "POST_KYB_REQUEST_SUCCESS";
export const POST_KYB_REQUEST_ERROR = "POST_KYB_REQUEST_ERROR";

export const REGISTER_COMPANY_PROFILE_REQUEST =
  "REGISTER_COMPANY_PROFILE_REQUEST";
export const REGISTER_COMPANY_PROFILE_SUCCESS =
  "REGISTER_COMPANY_PROFILE_SUCCESS";
export const REGISTER_COMPANY_PROFILE_ERROR = "REGISTER_COMPANY_PROFILE_ERROR";

export const QUESTIONNAIRE_STATIC_REQUEST = "QUESTIONNAIRE_STATIC_REQUEST";
export const QUESTIONNAIRE_STATIC_SUCCESS = "QUESTIONNAIRE_STATIC_SUCCESS";
export const QUESTIONNAIRE_STATIC_ERROR = "QUESTIONNAIRE_STATIC_ERROR";

export const SOURCE_OF_FUNDS_REQUEST = "SOURCE_OF_FUNDS_REQUEST";
export const SOURCE_OF_FUNDS_SUCCESS = "SOURCE_OF_FUNDS_SUCCESS";
export const SOURCE_OF_FUNDS_ERROR = "SOURCE_OF_FUNDS_ERROR";

export const SOURCE_OF_FUNDS_TEMPLATE_REQUEST =
  "SOURCE_OF_FUNDS_Template_REQUEST";
export const SOURCE_OF_FUNDS_TEMPLATE_SUCCESS =
  "SOURCE_OF_FUNDS_TEMPLATE_SUCCESS";
export const SOURCE_OF_FUNDS_TEMPLATE_ERROR = "SOURCE_OF_FUNDS_TEMPLATE_ERROR";

export const ADD_INDIVIDUAL_BANK_REQUEST = "ADD_INDIVIDUAL_BANK_REQUEST";
export const ADD_INDIVIDUAL_BANK_SUCCESS = "ADD_INDIVIDUAL_BANK_SUCCESS";
export const ADD_INDIVIDUAL_BANK_ERROR = "ADD_INDIVIDUAL_BANK_ERROR";

export const SOCIAL_LOGIN_REQUEST = "SOCIAL_LOGIN_REQUEST";

export const REFRESH_DATA = "REFRESH_DATA";