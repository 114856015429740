import {
  ADD_GRANT_REQUEST,
  DELETE_GRANT_REQUEST,
  EDIT_GRANT_REQUEST,
  GRANT_MARKETPLACE_LIST_REQUEST,
} from "./constants";

export const grantMarketplaceListRequest = () => {
  return {
    type: GRANT_MARKETPLACE_LIST_REQUEST,
  };
};

export const addGrantRequest = (data) => {
  return {
    type: ADD_GRANT_REQUEST,
    data,
  };
};

export const editGrantRequest = (data) => {
  return {
    type: EDIT_GRANT_REQUEST,
    data,
  };
};

export const deleteGrantRequest = (data) => {
  return {
    type: DELETE_GRANT_REQUEST,
    data,
  };
};
