import "./iframeStyle.css";
import Logo from "../../../assets/images/main-logo-svg.svg";


const IframeComponent = ({ title, source }) => {

    return (
        <div className="iframe-container">
            <iframe
                title={title}
                src={source}
            />
            {/* <div className="iframe-watermark"><img src={Logo} alt="logo" style={{ width: '40rem', height: '40rem', opacity: 0.3 }} /></div> */}
        </div>
    );
};

export default IframeComponent