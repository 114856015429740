import React, { useEffect, useState } from "react";
import { Card } from "antd";
import { Row, Col, Button } from "antd";
import { useSelector } from "react-redux";
import investor from "../../assets/images/investor.svg";
import organizer from "../../assets/images/organizer.svg";
import serviceOrganizer from "../../assets/images/organizer.png";
import facilitatorIcon from "../../assets/images/facilitator.svg";
import { setVerifyEmail } from "../../Utils/isAuth";
import { useLocation } from "react-router-dom";

const { Meta } = Card;
const AccountType = (props) => {
  const { pathname } = useLocation();
  const [cardNumber, setCardNumber] = useState(1);
  const changeBackgroundHandler = (card, count) => {
    setCardNumber(card);

    if (count === 2) {
      onConfirm(card);
    }
  };

  useEffect(() => {
    setVerifyEmail(pathname);
  }, [pathname]);

  const { onConfirm } = props;
  const authDetails = useSelector((state) => state.auth);
  const { loading } = authDetails;
  return (
    <Row className="onboarding-row-wrapper">
      <Col md={24}>
        <p className="account-type-heading-title"> Let us help you to choose</p>
        <p className="account-type-heading-description mt-2">
          To begin this journey, let us know what type of account you’d <br />
          be opening.
        </p>
        <Card
          className={cardNumber === 1 ? `highlight-card` : `card-registration`}
          onClick={(e) => {
            changeBackgroundHandler(1, e?.detail);
          }}
        >
          <Meta
            avatar={<img src={investor} alt="investor" />}
            title="Investor"
            description="Access the fundraising deals and leverage the full scope of tools required for investments in private equity."
          />
        </Card>
        <Card
          onClick={(e) => {
            changeBackgroundHandler(2, e?.detail);
          }}
          className={cardNumber === 2 ? `highlight-card` : `card-registration`}
        >
          <Meta
            avatar={<img src={organizer} alt="organizer" />}
            title="Startup"
            description="Grow the network of investors, focus on your growth goals and enjoy comprehensive support along the fundraising process."
          />
        </Card>
        <Card
          onClick={(e) => {
            changeBackgroundHandler(3, e?.detail);
          }}
          className={cardNumber === 3 ? `highlight-card` : `card-registration`}
        >
          <Meta
            avatar={
              <img
                src={serviceOrganizer}
                alt="organizer"
                height={28}
                width={31}
              />
            }
            title="Organizer"
            description="Take the leading role in structuring investment deals."
          />
        </Card>
        <Card
          onClick={(e) => {
            changeBackgroundHandler(4, e?.detail);
          }}
          className={cardNumber === 4 ? `highlight-card` : `card-registration`}
        >
          <Meta
            avatar={
              <img
                src={facilitatorIcon}
                alt="facilitator"
                height={28}
                width={31}
                className="fac-image-selection"
              />
            }
            title="Facilitator"
            description="Extend the client base, engage in the investment deals and find counterparties by providing facilitator services."
          />
        </Card>
      </Col>
      <Button
        className="confirm-submit-btn"
        onClick={() => onConfirm(cardNumber)}
        loading={loading}
      >
        Confirm
      </Button>
    </Row>
  );
};
export default AccountType;
