import {
  SERVICE_ORGANIZER_DEAL_LIST_REQUEST,
  SERVICE_ORGANIZER_DEAL_LIST_SUCCESS,
  SERVICE_ORGANIZER_DEAL_LIST_ERROR,
  UPDATE_SERVICE_ORGANIZER_DEAL_STATUS_ERROR,
  UPDATE_SERVICE_ORGANIZER_DEAL_STATUS_SUCCESS,
  UPDATE_SERVICE_ORGANIZER_DEAL_STATUS_REQUEST,
} from "./constants";

const initialState = {
  loading: false,
  data: [],
};

export default function serviceOrganizerDeals(state = initialState, action) {
  switch (action.type) {
    case SERVICE_ORGANIZER_DEAL_LIST_REQUEST:
      return { ...state, loading: true };
    case SERVICE_ORGANIZER_DEAL_LIST_SUCCESS:
      return {
        ...state,
        data: action.data,
        loading: false,
      };
    case SERVICE_ORGANIZER_DEAL_LIST_ERROR:
      return { ...state, loading: false };
    case UPDATE_SERVICE_ORGANIZER_DEAL_STATUS_REQUEST:
      return { ...state, loading: true };
    case UPDATE_SERVICE_ORGANIZER_DEAL_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_SERVICE_ORGANIZER_DEAL_STATUS_ERROR:
      return { ...state, loading: false };

    default:
      return state;
  }
}
