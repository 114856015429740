import { call, put, takeLatest } from "redux-saga/effects";
import {
  addinvestorType,
  getFacilitatorServicesApi,
  postKybRequest,
  questionnaireStatic,
  registerCompanyProfile,
  setFacilitatorServicesApi,
  sourceOfFund,
  updateKybRequest,
  updateIDProofIAPI,
  updateProfile,
  uploadIDProofIAPI,
  updateCompanyProfile,
  deleteKybRequest,
} from "../../../api";
import { isInvestor } from "../../../Utils";
import { getUser } from "../../../Utils/isAuth";

import {
  DELETE_IDENTITY_DOC_ERROR,
  DELETE_IDENTITY_DOC_REQUEST,
  DELETE_IDENTITY_DOC_SUCCESS,
  FACILITATORS_SERVICES_ERROR,
  FACILITATORS_SERVICES_REQUEST,
  FACILITATORS_SERVICES_SET_ERROR,
  FACILITATORS_SERVICES_SET_REQUEST,
  FACILITATORS_SERVICES_SET_SUCCESS,
  FACILITATORS_SERVICES_SUCCESS,
  PUT_PROFILE_COMP_ERROR,
  PUT_PROFILE_COMP_REQUEST,
  PUT_PROFILE_COMP_SUCCESS,
  SET_KYB_ERROR,
  SET_KYB_PASS,
  SET_KYB_REQUEST,
  SET_KYB_SUCCESS,
  SET_KYC_ERROR,
  SET_KYC_REQUEST,
  SET_KYC_SUCCESS,
  SET_PROFILE_COMP_ERROR,
  SET_PROFILE_COMP_REQUEST,
  SET_PROFILE_COMP_SUCCESS,
  SET_PROFILE_IND_ERROR,
  SET_PROFILE_IND_REQUEST,
  SET_PROFILE_IND_SUCCESS,
  SET_QUESTIONARE_ERROR,
  SET_QUESTIONARE_REQUEST,
  SET_QUESTIONARE_SUCCESS,
  SET_SOURCE_OF_FUNDS_ERROR,
  SET_SOURCE_OF_FUNDS_REQUEST,
  SET_SOURCE_OF_FUNDS_SUCCESS,
  SET_TYPE_USER_ERROR,
  SET_TYPE_USER_REQUEST,
  SET_TYPE_USER_SUCCESS,
} from "./constants";

function* userTypeRequest(action) {
  try {
    const resp = yield call(addinvestorType, action.data);

    if (resp.status === 201 || resp.status === 200) {
      const userInfo = JSON.parse(localStorage.getItem("user"));

      localStorage.setItem("investorType", action.data.sub_type_of_account);
      localStorage.setItem(
        "user",
        JSON.stringify({
          ...userInfo,
          sub_type_of_account:
            action.data.sub_type_of_account === "Company" ? "CMP" : "IND",
        })
      );

      if (resp.data?.country) {
        localStorage.setItem("country", resp.data.country);
      }

      yield put({
        type: SET_TYPE_USER_SUCCESS,
        data: {
          step: 1,
          onboardingDone: false,
        },
      });
    }
  } catch ({ response }) {
    if (response) {
      yield put({
        type: SET_TYPE_USER_ERROR,
        data: JSON.stringify(response?.data),
      });
    } else {
      yield put({ type: SET_TYPE_USER_ERROR, data: "Error" });
    }
  }
}

function* setIndProfileRequest(action) {
  try {
    const resp = yield call(updateProfile, action.data);

    if (resp.status === 201 || resp.status === 200) {
      yield put({
        type: SET_PROFILE_IND_SUCCESS,
        data: {
          step: 2,
          onboardingDone: false,
        },
      });
    }
  } catch ({ response }) {
    if (response) {
      yield put({
        type: SET_PROFILE_IND_ERROR,
        data: JSON.stringify(response?.data),
      });
    } else {
      yield put({ type: SET_PROFILE_IND_ERROR, data: "Error" });
    }
  }
}

function* setCompProfileRequest(action) {
  try {
    const resp = yield call(registerCompanyProfile, action.data);

    if (resp.status === 201 || resp.status === 200) {
      yield put({
        type: SET_PROFILE_COMP_SUCCESS,
        data: {
          step: 2,
          onboardingDone: false,
        },
      });
    }
  } catch ({ response }) {
    if (response) {
      yield put({
        type: SET_PROFILE_COMP_ERROR,
        data: JSON.stringify(response?.data),
      });
    } else {
      yield put({ type: SET_PROFILE_COMP_ERROR, data: "Error" });
    }
  }
}
function* updateCompProfileRequest(action) {
  try {
    const resp = yield call(updateCompanyProfile, action.data);
    if (resp.status === 201 || resp.status === 200) {
      yield put({
        type: PUT_PROFILE_COMP_SUCCESS,
        data: {
          step: 2,
          onboardingDone: false,
        },
      });
    }
  } catch ({ response }) {
    if (response) {
      yield put({
        type: PUT_PROFILE_COMP_ERROR,
        data: JSON.stringify(response?.data),
      });
    } else {
      yield put({ type: PUT_PROFILE_COMP_ERROR, data: "Error" });
    }
  }
}

function* setIDProofRequest(action) {
  const user = getUser();
  try {
    let resp;
    if (user.onboardingStepsDone.id_proof === "True") {
      resp = yield call(updateIDProofIAPI, action.data);
    } else {
      resp = yield call(uploadIDProofIAPI, action.data);
    }

    if (resp.status === 201 || resp.status === 200) {
      yield put({
        type: SET_KYC_SUCCESS,
        data: {
          step: 3,
          onboardingDone: false,
        },
      });
    }
  } catch ({ response }) {
    if (response) {
      yield put({
        type: SET_KYC_ERROR,
        data: JSON.stringify(response?.data),
      });
    } else {
      yield put({ type: SET_KYC_ERROR, data: "Error" });
    }
  }
}

function* setKYBRequest(action) {
  const user = getUser();

  try {
    let resp;
    if (action?.key !== "exists") {
      if (user?.onboardingStepsDone?.kyb === "True") {
        resp = yield call(updateKybRequest, action.data);
      } else {
        resp = yield call(postKybRequest, action.data);
      }
    }
    if (action?.key === "exists") {
      yield put({
        type: SET_KYB_PASS,
        data: {
          step: 3,
          onboardingDone: false,
        },
      });
    } else {
      if (resp.status === 201 || resp.status === 200) {
        yield put({
          type: SET_KYB_SUCCESS,
          data: {
            step: 3,
            onboardingDone: false,
          },
        });
      }
    }
  } catch ({ response }) {
    if (response) {
      yield put({
        type: SET_KYB_ERROR,
        data: JSON.stringify(response?.data),
      });
    } else {
      yield put({ type: SET_KYB_ERROR, data: "Error" });
    }
  }
}

function* setDeleteIdentityDoc(action) {
  try {
    let { data } = yield call(deleteKybRequest, action.data);
    if (data) {
      yield put({
        type: DELETE_IDENTITY_DOC_SUCCESS,
        data: {
          step: 3,
          onboardingDone: false,
        },
      });
    }
  } catch ({ response }) {
    if (response) {
      yield put({
        type: DELETE_IDENTITY_DOC_ERROR,
        data: JSON.stringify(response?.data),
      });
    } else {
      yield put({ type: DELETE_IDENTITY_DOC_ERROR, data: "Error" });
    }
  }
}

function* setQuestionaireRequest(action) {
  const user = getUser();
  const investorType = localStorage.getItem("investorType");
  try {
    let resp;
    if (user.onboardingStepsDone.questionnaire === "True") {
      resp = yield call(questionnaireStatic, action.data);
    } else {
      resp = yield call(questionnaireStatic, action.data);
    }

    if (resp.status === 201 || resp.status === 200) {
      yield put({
        type: SET_QUESTIONARE_SUCCESS,
        data: {
          step: investorType !== "Individual" ? 4 : 3,
          onboardingDone: false,
        },
      });
    }
  } catch ({ response }) {
    if (response) {
      yield put({
        type: SET_QUESTIONARE_ERROR,
        data: JSON.stringify(response?.data),
      });
    } else {
      yield put({ type: SET_QUESTIONARE_ERROR, data: "Error" });
    }
  }
}

function* setSourceOfFundRequest(action) {
  const user = getUser();
  try {
    let resp;
    if (user.onboardingStepsDone.source_of_funds === "True") {
      resp = yield call(sourceOfFund, action.data);
    } else {
      resp = yield call(sourceOfFund, action.data);
    }

    if (resp.status === 201 || resp.status === 200) {
      yield put({
        type: SET_SOURCE_OF_FUNDS_SUCCESS,
        data: {
          step: 5,
          onboardingDone: false,
        },
      });
    }
  } catch ({ response }) {
    if (response) {
      yield put({
        type: SET_SOURCE_OF_FUNDS_ERROR,
        data: JSON.stringify(response?.data),
      });
    } else {
      yield put({ type: SET_SOURCE_OF_FUNDS_ERROR, data: "Error" });
    }
  }
}

function* requestFacilitatorServices() {
  try {
    let { data } = yield call(getFacilitatorServicesApi);
    if (data) {
      yield put({ type: FACILITATORS_SERVICES_SUCCESS, data });
    }
  } catch ({ response }) {
    if (response) {
      yield put({
        type: FACILITATORS_SERVICES_ERROR,
        data: JSON.stringify(response?.data),
      });
    } else {
      yield put({ type: FACILITATORS_SERVICES_ERROR, data: "Error" });
    }
  }
}

function* requestSetFacilitatorServices(action) {
  try {
    let { data } = yield call(setFacilitatorServicesApi, action?.data);
    if (data) {
      yield put({
        type: FACILITATORS_SERVICES_SET_SUCCESS,
        data: {
          step: 5,
          onboardingDone: false,
        },
      });
    }
  } catch ({ response }) {
    if (response) {
      yield put({
        type: FACILITATORS_SERVICES_SET_ERROR,
        data: JSON.stringify(response?.data),
      });
    } else {
      yield put({ type: FACILITATORS_SERVICES_SET_ERROR, data: "Error" });
    }
  }
}

function* OnboardingWatcher() {
  yield takeLatest(SET_TYPE_USER_REQUEST, userTypeRequest);
  yield takeLatest(SET_PROFILE_IND_REQUEST, setIndProfileRequest);
  yield takeLatest(SET_PROFILE_COMP_REQUEST, setCompProfileRequest);
  yield takeLatest(PUT_PROFILE_COMP_REQUEST, updateCompProfileRequest);
  yield takeLatest(SET_KYC_REQUEST, setIDProofRequest);
  yield takeLatest(SET_KYB_REQUEST, setKYBRequest);
  yield takeLatest(DELETE_IDENTITY_DOC_REQUEST, setDeleteIdentityDoc);
  yield takeLatest(SET_QUESTIONARE_REQUEST, setQuestionaireRequest);
  yield takeLatest(SET_SOURCE_OF_FUNDS_REQUEST, setSourceOfFundRequest);
  yield takeLatest(FACILITATORS_SERVICES_REQUEST, requestFacilitatorServices);
  yield takeLatest(
    FACILITATORS_SERVICES_SET_REQUEST,
    requestSetFacilitatorServices
  );
}

export default OnboardingWatcher;
