export const getComments = async () => {
  return [
    {
      id: "1",
      body:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dignissim pretium quam hac mattis nam nibh. Egestas ut volutpat ut at volutpat a at. Nisl elit consectetur duis iaculis proin sem. Consectetur magna ut quam id elementum.",
      username: "Jack",
      userId: "1",
      tag: "Investor",
      parentId: null,
      createdAt: "2021-08-16T23:00:33.010+02:00",
    },
  ];
};

export const createComment = async (text, parentId = null) => {
  return {
    id: Math.random()
      .toString(36)
      .substr(2, 9),
    body: text,
    parentId,
    userId: "1",
    tag: "Organizer",
    username: "John",
    createdAt: new Date().toISOString(),
  };
};

export const updateComment = async (text) => {
  return { text };
};

export const deleteComment = async () => {
  return {};
};
