import {
  DownOutlined,
  CheckCircleOutlined,
  ArrowDownOutlined,
} from "@ant-design/icons";

export const getStatus = (steps = {}, current, index) => {
  const values = Object.values(steps);

  if (values.every((currentValue) => currentValue === true)) {
    return "finish";
  }

  return current === index ? "process" : "waiting";
};

export const StepDot = ({ stepDone = false, firstItem = false }) => {
  if (stepDone) {
    return (
      <div className="step-icon">
        {firstItem ? undefined : <DownOutlined style={{ color: "#f0f0f0" }} />}
        <CheckCircleOutlined
          style={{ color: "green", fontSize: "20px", zIndex: 15 }}
        />
      </div>
    );
  }

  return (
    <div className="step-icon">
      {firstItem ? undefined : <DownOutlined style={{ color: "#f0f0f0" }} />}
      <div className="blue-circle"></div>
    </div>
  );
};

export const getPercent = (steps = {}) => {
  const values = Object.values(steps);
  const length = values.length;

  if (length === 0) {
    return 0;
  }

  let trueCount = 0;

  values.forEach((el) => {
    if (el === true) {
      trueCount++;
    }
  });
  const res = (100 / length) * trueCount;

  return res;
};
