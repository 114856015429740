import { Table } from "antd";
import React from "react";
import { numberFormatter } from "../../../../Utils";

const pastFundraisesColumns = [
  {
    title: "Round name",
    dataIndex: "round_name",
  },
  {
    title: "Date",
    dataIndex: "date",
  },
  {
    title: "Amount",
    dataIndex: "amount",
    render(text) {
      return numberFormatter.formatter(text);
    },
  },
  {
    title: "Comments",
    dataIndex: "comments",
  },
];

const PastFundRaisersTable = ({ data }) => {
  return (
    <Table
      rowClassName={() => "editable-row"}
      pagination={false}
      dataSource={data?.past_fund_raisers}
      size="small"
      columns={pastFundraisesColumns}
    />
  );
};

export default PastFundRaisersTable;
