import {
  DEAL_LIST_REQUEST,
  DELETE_DEAL_REQUEST,
  ORGANIZER_DEAL_LIST_REQUEST,
} from "./constants";

export const dealsListRequest = (data) => {
  return {
    type: DEAL_LIST_REQUEST,
    data,
  };
};

export const organizerDealsListRequest = (data) => {
  return {
    type: ORGANIZER_DEAL_LIST_REQUEST,
    data,
  };
};

export const deleteDealRequest = (data) => {
  return {
    type: DELETE_DEAL_REQUEST,
    data,
  };
};
