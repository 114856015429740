import React from "react";
import { Table, Row, Col } from "antd";
import { pastfundtable } from "../../../data/data";

const columns = [
  {
    title: " Round name",
    dataIndex: "roundname",
    key: "roundname",
  },
  {
    title: "Date",
    dataIndex: "date",
    key: "date",
  },
  {
    title: "Amount",
    dataIndex: "amount",
  },
  {
    title: "Investors",
    dataIndex: "investors",
  },
];

const PastFundraises = () => {
  return (
    <div className="tabled">
      <Row gutter={[24, 0]}>
        <Col xs={24} md={24} xl={24} xxl={24}>
          <div className="pastfundraises_tbl">
            <Table
              columns={columns}
              dataSource={pastfundtable}
              pagination={false}
              bordered={false}
              className="ant-border-space"
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default PastFundraises;
