export const DropdownSvg = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.99984 10.834C10.4601 10.834 10.8332 10.4609 10.8332 10.0007C10.8332 9.54041 10.4601 9.16732 9.99984 9.16732C9.5396 9.16732 9.1665 9.54041 9.1665 10.0007C9.1665 10.4609 9.5396 10.834 9.99984 10.834Z"
      stroke="#98A2B3"
    />
    <path
      d="M9.99984 5.00065C10.4601 5.00065 10.8332 4.62755 10.8332 4.16732C10.8332 3.70708 10.4601 3.33398 9.99984 3.33398C9.5396 3.33398 9.1665 3.70708 9.1665 4.16732C9.1665 4.62755 9.5396 5.00065 9.99984 5.00065Z"
      stroke="#98A2B3"
    />
    <path
      d="M9.99984 16.6673C10.4601 16.6673 10.8332 16.2942 10.8332 15.834C10.8332 15.3737 10.4601 15.0007 9.99984 15.0007C9.5396 15.0007 9.1665 15.3737 9.1665 15.834C9.1665 16.2942 9.5396 16.6673 9.99984 16.6673Z"
      stroke="#98A2B3"
    />
  </svg>
);
