import { call, put, takeLatest, select } from "redux-saga/effects";
import {
  addUserToChatApi,
  createChatApi,
  deleteUserFromChatApi,
  getAllChatListApi,
  getAllMessagesApi,
  getAllUserListApi,
  getNewMessagesApi,
  sentMessageToEmail,
} from "../../../api";

import {
  ADD_USER_TO_CHAT_ERROR,
  ADD_USER_TO_CHAT_REQUEST,
  ADD_USER_TO_CHAT_SUCCESS,
  CREATE_CHAT_ERROR,
  CREATE_CHAT_REQUEST,
  CREATE_CHAT_SUCCESS,
  DELETE_USER_FROM_CHAT_ERROR,
  DELETE_USER_FROM_CHAT_REQUEST,
  DELETE_USER_FROM_CHAT_SUCCESS,
  GET_ALL_CHAT_ERROR,
  GET_ALL_CHAT_REQUEST,
  GET_ALL_CHAT_SUCCESS,
  GET_ALL_MESSAGES_ERROR,
  GET_ALL_MESSAGES_REQUEST,
  GET_ALL_MESSAGES_SUCCESS,
  GET_ALL_USERS_ERROR,
  GET_ALL_USERS_REQUEST,
  GET_ALL_USERS_SUCCESS,
  NEW_MESSAGE_ERROR,
  NEW_MESSAGE_REQUEST,
  NEW_MESSAGE_SUCCESS,
  SET_PRE_SELECTED_CHAT,
} from "./constants";

function* requestAllUsersList() {
  try {
    const { data } = yield call(getAllUserListApi);

    if (data) {
      yield put({
        type: GET_ALL_USERS_SUCCESS,
        data,
      });
    }
  } catch (err) {
    yield put({ type: GET_ALL_USERS_ERROR });
  }
}

function* requestCreateChat(action) {
  try {
    const resp = yield call(createChatApi, action?.data);
    if (resp.status === 201 || resp.status === 200) {
      yield put({
        type: CREATE_CHAT_SUCCESS,
        data: resp.data,
      });

      yield put({
        type: SET_PRE_SELECTED_CHAT,
        data: resp?.data?.convo_id || resp?.data?.id,
      });
    }
  } catch (err) {
    yield put({ type: CREATE_CHAT_ERROR });
  }
}

function* requestAllChatList() {
  try {
    const { data } = yield call(getAllChatListApi);

    if (data) {
      yield put({
        type: GET_ALL_CHAT_SUCCESS,
        data,
      });
    }
  } catch (err) {
    yield put({ type: GET_ALL_CHAT_ERROR });
  }
}

function* requestAllMessages(action) {
  try {
    const { data } = yield call(getAllMessagesApi, action?.data);

    if (data) {
      yield put({
        type: GET_ALL_MESSAGES_SUCCESS,
        data: data?.message_set?.reverse(),
      });
    }
  } catch (err) {
    yield put({ type: GET_ALL_MESSAGES_ERROR });
  }
}

function* requestDeleteUserFromChat(action) {
  try {
    const resp = yield call(deleteUserFromChatApi, action?.data);

    if (resp.status === 201 || resp.status === 200) {
      const chatList = yield select((state) => state.chat.chatList);

      const index = chatList.findIndex((el) => {
        return el.id === resp.data.id;
      });

      chatList[index].receiver = resp.data.receiver;

      yield put({
        type: DELETE_USER_FROM_CHAT_SUCCESS,
        data: chatList,
      });
    }
  } catch (err) {
    yield put({ type: DELETE_USER_FROM_CHAT_ERROR });
  }
}

function* requestAddUserFromChat(action) {
  try {
    const resp = yield call(addUserToChatApi, action?.data);

    if (resp.status === 201 || resp.status === 200) {
      const chatList = yield select((state) => state.chat.chatList);

      const index = chatList.findIndex((el) => {
        return el.id === resp.data.id;
      });

      chatList[index].receiver = resp.data.receiver;
      yield put({
        type: ADD_USER_TO_CHAT_SUCCESS,
        data: chatList,
      });
    }
  } catch (err) {
    yield put({ type: ADD_USER_TO_CHAT_ERROR });
  }
}

function* requestNewMessage(action) {
  try {
    const { data } = yield call(getNewMessagesApi, action?.data);

    if (data) {
      yield put({
        type: NEW_MESSAGE_SUCCESS,
        data: data?.message_set?.reverse(),
      });
      const message_id = data?.message_set[data?.message_set.length - 1]?.id;

      yield call(sentMessageToEmail, { message_id });
    }
  } catch (err) {
    yield put({ type: NEW_MESSAGE_ERROR });
  }
}

function* ChatWatcher() {
  yield takeLatest(GET_ALL_USERS_REQUEST, requestAllUsersList);
  yield takeLatest(CREATE_CHAT_REQUEST, requestCreateChat);
  yield takeLatest(GET_ALL_CHAT_REQUEST, requestAllChatList);
  yield takeLatest(GET_ALL_MESSAGES_REQUEST, requestAllMessages);
  yield takeLatest(DELETE_USER_FROM_CHAT_REQUEST, requestDeleteUserFromChat);
  yield takeLatest(ADD_USER_TO_CHAT_REQUEST, requestAddUserFromChat);
  yield takeLatest(NEW_MESSAGE_REQUEST, requestNewMessage);
}
export default ChatWatcher;
