import {
  SERVICE_ORGANIZER_DEAL_LIST_REQUEST,
  UPDATE_SERVICE_ORGANIZER_DEAL_STATUS_REQUEST,
} from "./constants";

export const getServiceOrganizerDealsListRequest = () => {
  return {
    type: SERVICE_ORGANIZER_DEAL_LIST_REQUEST,
  };
};

export const updateServiceOrganizerDealStatusRequest = (data) => {
  return {
    type: UPDATE_SERVICE_ORGANIZER_DEAL_STATUS_REQUEST,
    data,
  };
};
