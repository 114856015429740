export const STATE_CREATE_IP_REQUEST = "STATE_CREATE_IP_REQUEST";
export const STATE_CREATE_IP_SUCCESS = "STATE_CREATE_IP_SUCCESS";
export const STATE_CREATE_IP_ERROR = "STATE_CREATE_IP_ERROR";

export const EDIT_IP_DETAILS_REQUEST = "EDIT_IP_DETAILS_REQUEST";
export const EDIT_IP_DETAILS_SUCCESS = "EDIT_IP_DETAILS_SUCCESS";
export const EDIT_IP_DETAILS_ERROR = "EDIT_IP_DETAILS_ERROR";

export const UPDATE_IP_REQUEST = "UPDATE_IP_REQUEST";
export const UPDATE_IP_SUCCESS = "UPDATE_IP_SUCCESS";
export const UPDATE_IP_ERROR = "UPDATE_IP_ERROR";

export const UPDATE_IP_STEP_REQUEST = "UPDATE_IP_STEP_REQUEST";
export const UPDATE_IP_STEP_SUCCESS = "UPDATE_IP_STEP_SUCCESS";
export const UPDATE_IP_STEP_ERROR = "UPDATE_IP_STEP_ERROR";

export const CLEAR_SUCCESS = "CLEAR_SUCCESS";
export const CLEAR_ERROR = "CLEAR_ERROR";
export const RESET_IP_DETAILS = "RESET_IP_DETAILS";


export const IP_TITLES_REQUEST = "IP_TITLES_REQUEST";
export const IP_TITLES_SUCCESS = "IP_TITLES_SUCCESS";
export const IP_TITLES_ERROR = "IP_TITLES_ERROR";

export const CLEAR_IP_TITLES = "CLEAR_IP_TITLES";