export const GET_ALL_USERS_REQUEST = "GET_ALL_USERS_REQUEST";
export const GET_ALL_USERS_SUCCESS = "GET_ALL_USERS_SUCCESS";
export const GET_ALL_USERS_ERROR = "GET_ALL_USERS_ERROR";

export const CREATE_CHAT_REQUEST = "CREATE_CHAT_REQUEST";
export const CREATE_CHAT_SUCCESS = "CREATE_CHAT_SUCCESS";
export const CREATE_CHAT_ERROR = "CREATE_CHAT_ERROR";

export const GET_ALL_CHAT_REQUEST = "GET_ALL_CHAT_REQUEST";
export const GET_ALL_CHAT_SUCCESS = "GET_ALL_CHAT_SUCCESS";
export const GET_ALL_CHAT_ERROR = "GET_ALL_CHAT_ERROR";

export const GET_ALL_MESSAGES_REQUEST = "GET_ALL_MESSAGES_REQUEST";
export const GET_ALL_MESSAGES_SUCCESS = "GET_ALL_MESSAGES_SUCCESS";
export const GET_ALL_MESSAGES_ERROR = "GET_ALL_MESSAGES_ERROR";

export const SET_PRE_SELECTED_CHAT = "SET_PRE_SELECTED_CHAT";

export const DELETE_USER_FROM_CHAT_REQUEST = "DELETE_USER_FROM_CHAT_REQUEST";
export const DELETE_USER_FROM_CHAT_SUCCESS = "DELETE_USER_FROM_CHAT_SUCCESS";
export const DELETE_USER_FROM_CHAT_ERROR = "DELETE_USER_FROM_CHAT_ERROR";

export const ADD_USER_TO_CHAT_REQUEST = "ADD_USER_TO_CHAT_REQUEST";
export const ADD_USER_TO_CHAT_SUCCESS = "ADD_USER_TO_CHAT_SUCCESS";
export const ADD_USER_TO_CHAT_ERROR = "ADD_USER_TO_CHAT_ERROR";

export const NEW_MESSAGE_REQUEST = "NEW_MESSAGE_REQUEST ";
export const NEW_MESSAGE_SUCCESS = "NEW_MESSAGE_SUCCESS";
export const NEW_MESSAGE_ERROR = "NEW_MESSAGE_ERROR";

export const START_CHAT = "START_CHAT";
export const RESET_MESSAGES = "RESET_MESSAGES";
export const RESET_ALL_DETAILS = "RESET_ALL_DETAILS";

