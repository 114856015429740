import React from "react";

import {
  Avatar,
  Button,
  Card,
  Divider,
  Dropdown,
  Menu,
  Modal,
  Tag,
} from "antd";
import Title from "antd/lib/typography/Title";
import Icon from "@ant-design/icons";
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  QuestionCircleOutlined,
  CloseOutlined,
  EditOutlined,
  CheckOutlined,
} from "@ant-design/icons";
import { DropdownSvg } from "../../../assets/images/dropdownSvg";
import ComplianceCheck from "../../../assets/images/compliance-check.png";
import RejectDeal from "../../../assets/images/reject-deal.png";
import ApproveDealModalIcon from "../../../assets/images/modal-approve-deal.png";

import { getFirstLater, getImageURL } from "../../../Utils";
import { BASE_URL } from "../../../api";
import { useState } from "react";
import { getUser } from "../../../Utils/isAuth";
import TextArea from "antd/lib/input/TextArea";
import { adminIpsUpdateRequest } from "../../../pages/adminIps/redux/action";

const AdminIPInReviewMenu = ({ record, dispatch, navigate }) => {
  const [visibleApprove, setVisibleApprove] = useState(false);
  const [visibleReject, setVisibleReject] = useState(false);
  const [, setComment] = useState();
  const { is_superuser, type_of_account } = getUser();

  const handleInput = (e) => {
    e.domEvent.stopPropagation();
    setComment(e.target.value);
  };

  const showApproveModal = (e) => {
    e.domEvent.stopPropagation();
    setVisibleApprove(true);
  };

  const showRejectModal = (e) => {
    e.domEvent.stopPropagation();
    setVisibleReject(true);
  };

  const handleOkApprove = (e) => {
    e.stopPropagation();
    dispatch(
      adminIpsUpdateRequest({
        ip_status: "Approve",
        ip_id: record?.id,
      })
    );
    setVisibleApprove(false);
  };

  const handleCancelApprove = (e) => {
    e.stopPropagation();
    setVisibleApprove(false);
  };

  const handleOkReject = (e) => {
    e.stopPropagation();
    dispatch(
      adminIpsUpdateRequest({
        ip_status: "Reject",
        ip_id: record?.id,
      })
    );
    setVisibleReject(false);
  };

  const handleCancelReject = (e) => {
    e.stopPropagation();
    setVisibleReject(false);
  };

  return (
    <>
      <Menu className="edit-menu">
        <Menu.Item
          onClick={(event) => {
            event.domEvent.stopPropagation();
            dispatch(
              adminIpsUpdateRequest({
                ip_status: "Compliance Check",
                ip_id: record?.id,
              })
            );
          }}
          className="edit-menu__item"
          key="edit-menu-item-1"
        >
          <div className="edit-menu__item">
            <img
              src={ComplianceCheck}
              className="edit-menu__icon edit-menu__item menu-icon-size"
              alt=""
            />{" "}
            <span className="compliance-container">Compliance Check</span>
          </div>
        </Menu.Item>
        <Divider className="divider-margin" />
        <Menu.Item
          className="edit-menu__item"
          key="edit-menu-item-2"
          onClick={showApproveModal}
          style={{ color: "#34D399" }}
        >
          <CheckOutlined className="menu-item-icon approve-txt" />
          <span className="list-item approve-txt"> Approve IP</span>
        </Menu.Item>
        <Menu.Item
          className="edit-menu__item"
          key="edit-menu-item-3"
          onClick={showRejectModal}
          style={{ color: "#EF4444" }}
        >
          <CloseOutlined className="menu-item-icon reject-txt" />
          <span className="list-item reject-txt">Reject IP</span>
        </Menu.Item>
        {(is_superuser || type_of_account === "ORG") && (
          <Menu.Item
            className="edit-menu__item"
            key="edit-menu-item-4"
            onClick={(event) => {
              event.domEvent.stopPropagation();
              navigate(`/edit-sell-ip/${record?.id}`);
            }}
            style={{ color: "#000" }}
          >
            <EditOutlined className="menu-item-icon editing-txt" />
            <span className="list-item editing-txt">Edit IP</span>
          </Menu.Item>
        )}
      </Menu>
      <Modal
        visible={visibleApprove}
        onOk={handleOkApprove}
        onCancel={handleCancelApprove}
        className="approve-modal"
        centered={true}
        footer={[
          <Button
            key="no"
            onClick={handleCancelApprove}
            className="modal-cancel-btn"
          >
            No
          </Button>,
          <Button
            key="yes"
            type="primary"
            onClick={handleOkApprove}
            className="modal-yes-btn"
          >
            Yes
          </Button>,
        ]}
      >
        <div>
          <img
            src={ApproveDealModalIcon}
            className="approve-modal-icon"
            alt=""
          />
          <p className="approve-modal-title">Approve a IP?</p>
          <p className="approve-modal-desc">
            Are you sure you want to approve a IP? This action cannot be undone.
          </p>
        </div>
      </Modal>
      <Modal
        visible={visibleReject}
        onOk={handleOkReject}
        onCancel={handleCancelReject}
        className="approve-modal"
        centered={true}
        footer={[
          <Button
            key="no"
            onClick={handleCancelReject}
            className="modal-cancel-btn"
          >
            No
          </Button>,
          <Button
            key="yes"
            type="primary"
            onClick={handleOkReject}
            className="reject-modal-yes-btn "
          >
            Yes
          </Button>,
        ]}
      >
        <div>
          <img src={RejectDeal} className="reject-modal-icon" alt="" />
          <p className="approve-modal-title">Reject a IP?</p>
          <p className="approve-modal-desc">
            Are you sure you want to reject a IP? This action cannot be undone.
          </p>
          <TextArea rows={4} onChange={handleInput} />
        </div>
      </Modal>
    </>
  );
};

const AdminIPComplianceCheckMenu = ({ record, dispatch, navigate }) => {
  const [visibleApprove, setVisibleApprove] = useState(false);
  const [visibleReject, setVisibleReject] = useState(false);
  const [, setComment] = useState();
  const { is_superuser, type_of_account } = getUser();

  const handleInput = (e) => {
    setComment(e.target.value);
  };

  const showApproveModal = (e) => {
    e.domEvent.stopPropagation();
    setVisibleApprove(true);
  };

  const showRejectModal = (e) => {
    e.domEvent.stopPropagation();
    setVisibleReject(true);
  };

  const handleOkApprove = (e) => {
    e.stopPropagation();
    dispatch(
      adminIpsUpdateRequest({
        ip_status: "Approve",
        ip_id: record?.id,
      })
    );
    setVisibleApprove(false);
  };

  const handleCancelApprove = (e) => {
    e.stopPropagation();
    setVisibleApprove(false);
  };

  const handleOkReject = (e) => {
    e.stopPropagation();
    dispatch(
      adminIpsUpdateRequest({
        ip_status: "Close",
        ip_id: record?.id,
      })
    );
    setVisibleReject(false);
  };

  const handleCancelReject = (e) => {
    e.stopPropagation();
    setVisibleReject(false);
  };

  return (
    <>
      <Menu className="edit-menu">
        <Menu.Item
          className="edit-menu__item"
          key="edit-menu-item-1"
          onClick={showApproveModal}
          style={{ color: "#34D399" }}
        >
          <CheckOutlined className="menu-item-icon approve-txt" />
          <span className="list-item approve-txt"> Approve IP</span>
        </Menu.Item>
        <Menu.Item
          className="edit-menu__item"
          key="edit-menu-item-2"
          onClick={showRejectModal}
          style={{ color: "#EF4444" }}
        >
          <CloseOutlined className="menu-item-icon reject-txt" />
          <span className="list-item reject-txt">Reject</span>
        </Menu.Item>
        {(is_superuser || type_of_account === "ORG") && (
          <Menu.Item
            className="edit-menu__item"
            key="edit-menu-item-3"
            onClick={(event) => {
              event.domEvent.stopPropagation();
              navigate(`/edit-sell-ip/${record?.id}`);
            }}
            style={{ color: "#000" }}
          >
            {/* <Icon component={EditSvg} className="edit-menu__icon editing-txt" /> */}
            <EditOutlined className="menu-item-icon editing-txt" />
            <span className="list-item editing-txt">Edit IP</span>
          </Menu.Item>
        )}
      </Menu>
      <Modal
        visible={visibleApprove}
        onOk={handleOkApprove}
        onCancel={handleCancelApprove}
        centered={true}
        className="approve-modal"
        footer={[
          <Button
            key="no"
            onClick={handleCancelApprove}
            className="modal-cancel-btn"
          >
            No
          </Button>,
          <Button
            key="yes"
            type="primary"
            onClick={handleOkApprove}
            className="modal-yes-btn"
          >
            Yes
          </Button>,
        ]}
      >
        <div>
          <img
            src={ApproveDealModalIcon}
            className="approve-modal-icon"
            alt=""
          />
          <p className="approve-modal-title">Approve a IP?</p>
          <p className="approve-modal-desc">
            Are you sure you want to approve a IP? This action cannot be undone.
          </p>
        </div>
      </Modal>
      <Modal
        visible={visibleReject}
        onOk={handleOkReject}
        onCancel={handleCancelReject}
        centered={true}
        className="approve-modal"
        footer={[
          <Button
            key="no"
            onClick={handleCancelReject}
            className="modal-cancel-btn"
          >
            No
          </Button>,
          <Button
            key="yes"
            type="primary"
            onClick={handleOkReject}
            className="reject-modal-yes-btn "
          >
            Yes
          </Button>,
        ]}
      >
        <div>
          <img src={RejectDeal} className="reject-modal-icon" alt="" />
          <p className="approve-modal-title">Reject a IP?</p>
          <p className="approve-modal-desc">
            Are you sure you want to reject a IP? This action cannot be undone.
          </p>
          <TextArea rows={4} onChange={handleInput} />
        </div>
      </Modal>
    </>
  );
};

const AdminIPMenu = ({ record, dispatch, navigate }) => {
  const { is_superuser, type_of_account } = getUser();
  return (
    <Menu className="edit-menu">
      <Menu.Item
        className="edit-menu__item"
        key="edit-menu-item-1"
        onClick={(e) => {
          e.domEvent.stopPropagation();
          dispatch(
            adminIpsUpdateRequest({
              ip_status: "Close",
              ip_id: record?.id,
            })
          );
        }}
        style={{ color: "#EF4444" }}
      >
        <CloseOutlined className="menu-item-icon reject-txt" />
        <span className="list-item reject-txt">Close The IP</span>
      </Menu.Item>
      {(is_superuser || type_of_account === "ORG") && (
        <Menu.Item
          className="edit-menu__item"
          key="edit-menu-item-2"
          onClick={(event) => {
            event.domEvent.stopPropagation();
            navigate(`/edit-sell-ip/${record?.id}`);
          }}
          style={{ color: "#000" }}
        >
          <EditOutlined className="menu-item-icon editing-txt" />
          <span className="list-item editing-txt">Edit IP</span>
        </Menu.Item>
      )}
    </Menu>
  );
};

const adminColumns = (dispatch, navigate) => [
  {
    title: ({ sortColumns }) => {
      const sortedColumn = sortColumns?.find(
        ({ column }) => column.key === "name"
      );

      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          Name
          {sortedColumn?.order ? (
            sortedColumn.order === "ascend" ? (
              <ArrowUpOutlined style={{ marginLeft: "8px" }} />
            ) : (
              <ArrowDownOutlined style={{ marginLeft: "8px" }} />
            )
          ) : null}
        </div>
      );
    },

    sorter: (a, b) => a?.ip_title?.localeCompare(b?.ip_title),
    dataIndex: "name",
    key: "name",
    render: (text, record) => {
      return (
        <Avatar.Group>
          <Avatar
            className={`${
              !record.ip_logo ? "shape-avatar-letter" : ""
            } shape-avatar`}
            shape="round"
            size={42}
            src={getImageURL(record.ip_logo || "")}
          >
            {getFirstLater(record?.ip_title || "")}
          </Avatar>
          <div className="avatar-info">
            <Title level={5}>{record?.ip_title}</Title>
            <p>{record?.organizer?.organizer_email}</p>
          </div>
        </Avatar.Group>
      );
    },
  },
  {
    title: "IP Type",
    key: "ip_type",
    render: (text, record) => {
      return <Title level={5}>{record?.ip_type}</Title>;
    },
  },
  {
    title: "Investors",
    key: "Investors",
    render: (record) => (
      <Avatar.Group
        maxCount={5}
        maxStyle={{
          color: "#475467",
          backgroundColor: "#F3F4F6",
        }}
      >
        {[record?.investor || record.investors]?.map((investor, index) => (
          <Avatar src={BASE_URL + investor?.logo} />
        ))}
      </Avatar.Group>
    ),
  },
  {
    title: "Profile",
    key: "Profile",
    render: (text, record) => {
      return (
        <Avatar.Group>
          <Avatar
            className={`${
              !record.ip_logo ? "shape-avatar-letter" : ""
            } shape-avatar`}
            shape="round"
            size={30}
            src={getImageURL(record.ip_logo || "")}
          >
            {getFirstLater(record?.organizer?.name || "")}
          </Avatar>
          <div className="avatar-info">
            <Title level={5}>{record?.organizer?.name}</Title>
            <p>{record?.organizer_photo}</p>
          </div>
        </Avatar.Group>
      );
    },
  },
  {
    title: (
      <div style={{ display: "flex", alignItems: "center" }}>
        Status <QuestionCircleOutlined style={{ marginLeft: "8px" }} />
      </div>
    ),
    dataIndex: "status",
    key: "status",
    render: (status) => {
      let backgroundColor = "";
      let textColor = "";
      if (status === "Completed") {
        textColor = "#1F2937";
        backgroundColor = "#F3F4F6";
      } else if (status === "In Progress") {
        textColor = "#92400E";
        backgroundColor = "#FEF3C7";
      } else if (status === "Active") {
        textColor = "#027A48";
        backgroundColor = "#ECFDF3";
      } else if (status === "In Review") {
        textColor = "#0B5394";
        backgroundColor = "#F0F9FF";
      }
      return (
        <Tag className="tag_text" color={backgroundColor}>
          <span
            style={{
              color: textColor,
              fontFamily: "Manrope",
              fontStyle: "normal",
              fontWeight: 500,
              fontSize: "14px",
              lineHeight: "20px",
              letterSpacing: "0.01em",
            }}
          >
            &#9679;&nbsp;{status}
          </span>
        </Tag>
      );
    },
  },
  {
    render: (text, record) => {
      return (
        <Dropdown
          overlay={
            record.status === "In Review" ? (
              <AdminIPInReviewMenu
                record={record}
                dispatch={dispatch}
                navigate={navigate}
              />
            ) : record.status === "Compliance Check" ? (
              <AdminIPComplianceCheckMenu
                record={record}
                dispatch={dispatch}
                navigate={navigate}
              />
            ) : (
              <AdminIPMenu
                record={record}
                dispatch={dispatch}
                navigate={navigate}
              />
            )
          }
          trigger={["click"]}
          placement="bottomLeft"
        >
          <Icon component={DropdownSvg} className="dropdown-menu-button" />
        </Dropdown>
      );
    },
  },
];

const adminDeviceColumns = (dispatch, navigate) => [
  {
    render: (text, record) => {
      let backgroundColor = "";
      let textColor = "";
      if (record.status === "Completed") {
        textColor = "#1F2937";
        backgroundColor = "#F3F4F6";
      } else if (record.status === "In Progress") {
        textColor = "#92400E";
        backgroundColor = "#FEF3C7";
      } else if (record.status === "Active") {
        textColor = "#027A48";
        backgroundColor = "#ECFDF3";
      } else if (record.status === "In Review") {
        textColor = "#0B5394";
        backgroundColor = "#F0F9FF";
      }
      return (
        <Card
          className="deal-table-card"
          title={
            <Avatar.Group>
              <Avatar
                className={`${
                  !record.ip_logo ? "shape-avatar-letter" : ""
                } shape-avatar`}
                shape="round"
                size={40}
                src={getImageURL(record.ip_logo || "")}
              >
                {getFirstLater(record?.ip_title || "")}
              </Avatar>
              <div className="avatar-info">
                <Title level={5}>{record?.ip_title || ""}</Title>
                <p>{record.company_website}</p>
              </div>
            </Avatar.Group>
          }
          extra={
            <div>
              <Tag className="tag_text" color={backgroundColor}>
                <span
                  style={{
                    color: textColor,
                    fontFamily: "Manrope",
                    fontStyle: "normal",
                    fontWeight: 500,
                    fontSize: "14px",
                    lineHeight: "20px",
                    letterSpacing: "0.01em",
                  }}
                >
                  &#9679;&nbsp;{record.status}
                </span>
              </Tag>
              <Dropdown
                overlay={
                  record.status === "In Review" ? (
                    <AdminIPInReviewMenu
                      record={record}
                      dispatch={dispatch}
                      navigate={navigate}
                    />
                  ) : record.status === "Compliance Check" ? (
                    <AdminIPComplianceCheckMenu
                      record={record}
                      dispatch={dispatch}
                      navigate={navigate}
                    />
                  ) : (
                    <AdminIPMenu
                      record={record}
                      dispatch={dispatch}
                      navigate={navigate}
                    />
                  )
                }
                trigger={["click"]}
                placement="bottomLeft"
              >
                <Icon
                  component={DropdownSvg}
                  className="dropdown-menu-button"
                />
              </Dropdown>
            </div>
          }
        >
          <Card.Grid className="card-grid" hoverable={false}>
            <div className="card-grid__item">
              <p>IP Type</p>
              <Title level={5}>{record?.ip_type}</Title>
            </div>
          </Card.Grid>
          <Card.Grid className="card-grid">
            <div className="card-grid__item">
              <p>Investors</p>
              <Avatar.Group
                maxCount={2}
                maxStyle={{
                  color: "#475467",
                  backgroundColor: "#F3F4F6",
                }}
              >
                {record.investor?.map((investor, index) => (
                  <Avatar src={BASE_URL + investor.logo} />
                ))}
              </Avatar.Group>
            </div>
          </Card.Grid>
        </Card>
      );
    },
  },
];

export { adminColumns, adminDeviceColumns };
