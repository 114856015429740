import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { organizerDealsListRequest } from "./redux/action";
import CommonTable from "../common/commonTable";

function OrganizerDeals({ columns, deviceColumns, userRole }) {
  const dispatch = useDispatch();
  const dealListState = useSelector((state) => state.dealList || []);

  useEffect(() => {
    if (userRole === "ORG") {
      dispatch(organizerDealsListRequest());
    }
  }, [dispatch, userRole]);

  const startUpKeys = [
    "All",
    "In Review",
    "Active",
    "In Progress",
    "Completed",
    "Drafts",
  ];

  const dealLists = useMemo(() => {
    return {
      ...dealListState,
      data: dealListState.organizerData,
    };
  }, [dealListState]);

  return (
    <div className="admin-deals">
      <CommonTable
        columns={columns}
        deviceColumns={deviceColumns}
        allData={dealLists}
        keys={startUpKeys}
      />
    </div>
  );
}

export default OrganizerDeals;
