export const GET_ADMIN_TOPORGANIZERS_REQUEST =
  "GET_ADMIN_TOPORGANIZERS_REQUEST";
export const GET_ADMIN_TOPORGANIZERS_SUCCESS =
  "GET_ADMIN_TOPORGANIZERS_SUCCESS";
export const GET_ADMIN_TOPORGANIZERS_ERROR = "GET_ADMIN_TOPORGANIZERS_ERROR";

export const UPDATE_TOPORGANIZERS_STATUS_REQUEST =
  "UPDATE_TOPORGANIZERS_STATUS_REQUEST";
export const UPDATE_TOPORGANIZERS_STATUS_SUCCESS =
  "UPDATE_TOPORGANIZERS_STATUS_SUCCESS";
export const UPDATE_TOPORGANIZERS_STATUS_ERROR =
  "UPDATE_TOPORGANIZERS_STATUS_ERROR";
