import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import CommonTable from "../common/commonTable";
import { organizerIpsListRequest } from "./redux/action";

import "../adminDeals/style.scss";

const InvestorIps = ({ columns, deviceColumns }) => {
  const investorIpLists = useSelector((state) => state.ipList || []);
  const [typeIP, setTypeIP] = useState("all");

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(organizerIpsListRequest());
  }, [dispatch]);

  const adminDealsKeys = [
    "All",
    "In Review",
    "Compliance Check",
    "Active",
    "In Progress",
    "Completed",
    "Drafts",
  ];

  const investorIpTypeLists = useMemo(() => {
    return {
      ...investorIpLists,
      data: investorIpLists?.data.filter((ele) => {
        if (typeIP === "yourRegisterIPs") {
          return !ele.invested_ip && ele.is_register_ip === true;
        } else if (typeIP === "yourSellIPs") {
          return !ele.invested_ip && ele.is_register_ip === false;
        } else if (typeIP === "investedIPs") {
          return ele.invested_ip;
        } else {
          return ele;
        }
      }),
    };
  }, [investorIpLists, typeIP]);

  const changeIp = (value) => {
    setTypeIP(value);
  };

  return (
    <div className="admin-deals">
      <CommonTable
        columns={columns}
        deviceColumns={deviceColumns}
        allData={investorIpTypeLists}
        keys={adminDealsKeys}
        isIp={true}
        changeIp={changeIp}
        typeIP={typeIP}
      />
    </div>
  );
};

export default InvestorIps;
