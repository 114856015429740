import React, { useEffect, useState } from "react";
import { Row, Button, Col, Card, Grid, Typography } from "antd";
import { NavLink, useNavigate } from "react-router-dom";
import { getUser } from "../../Utils/isAuth";
import { useDispatch, useSelector } from "react-redux";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { getProjectsOverView } from "../../api";
import { addSectorFilter } from "./redux/action";
import {
  DownOutlined,
  UpOutlined
} from '@ant-design/icons';
import "./Sidenav.scss";


const { useBreakpoint } = Grid;




function Header({ subName }) {
  const screens = useBreakpoint()
  const [limitCategories, setLimitCategories] = useState(0);
  useEffect(() => window.scrollTo(0, 0));
  const history = useNavigate();
  const dispatch = useDispatch();
  const user = getUser();
  const { Title } = Typography;

  const [key1, setKey1] = useState("0");
  const handleSidebarClick = (title, key) => {
    setKey1(key);
    dispatch(addSectorFilter(title));
  };

  const initialCountsSectors = [
    {
      name: "Analytical Dashboards",
      count: 0,
    },
    {
      name: "Longevity Projects",
      count: 0,
    },
    {
      name: "Ecosystems as a Service",
      count: 0,
    },
    {
      name: "DeepTech Projects",
      count: 0,
    },
    {
      name: "Partnership Projects",
      count: 0,
    },
    {
      name: "Philanthropy Projects",
      count: 0,
    },
    {
      name: "AI Industry Projects",
      count: 0,
    },
    {
      name: "Life Sciences Projects",
      count: 0,
    },
    {
      name: "GovTech Projects",
      count: 0,
    },
    {
      name: "SaaS Projects",
      count: 0,
    },
    {
      name: "All Projects",
      count: 0,
    },
    {
      name: "Whitelabel Solutions",
      count: 0,
    },
    {
      name: "Investable Projects",
      count: 0,
    },
    {
      name: "Business Cooperation",
      count: 0,
    },
  ];

  const [sectorCounts, setSectorsCount] = useState(initialCountsSectors);

  const handleCreateProjectsClick = () => history("/create-projects");

  useEffect(() => {
    getProjectsCount();
  }, [subName]);

  function getName(sector) {
    if (sector === "Partnerships") {
      return "Partnership Projects";
    } else if (sector === "Ecosystems As A Service") {
      return "Ecosystems as a Service";
    }
    return sector;
  }

  function updateSectorsCount(allSectorsCounts, totalProjects) {
    let allSectors = [...sectorCounts];
    Object.keys(allSectorsCounts).forEach((sector) => {
      const name = getName(sector);
      allSectors.forEach((ele) => {
        if (ele.name === name) {
          ele.count = allSectorsCounts[sector];
        }
      });
    });
    allSectors.forEach((item) => {
      if (item.name === "All Projects") {
        item.count = totalProjects;
      }
    });
    setSectorsCount(allSectors);
  }

  function getProjectsCount() {
    getProjectsOverView()
      .then(({ data }) => {
        if (data?.projectsCount) {
          updateSectorsCount(data?.sectorCounts, data?.projectsCount);
        }
      })
      .catch(() => {
        console.log("Something went wrong, Please try again !!");
      });
  }

  const projectTitle = useSelector((state) => state.settings.projectTitle);

  const name = () => {
    console.log("subName: ", subName)
    if (subName.includes("project")) {
      return projectTitle || "Project Details";
    } else if (subName.includes("MAmarketplace")) {
      return "M & A Marketplace";
    } else if (subName === "marketplace") {
      return "Discover Projects";
    } else if (subName === "ips") {
      return "IPs";
    } else if (subName === "investor-relations") {
      return "Investor Relationship Management";
    } else if (subName === "facilitator") {
      return "Facilitators";
    } else if (subName === "longevity-marketplace") {
      return "Longevity Marketplace";
    } else if (checkForParam(subName)) {
      return "Longevity Marketplace";
    } else if (subName === "user-journey") {
      return "User Journey";
    } else if (subName === "my-projects") {
      return "My Projects";
    } else if (subName === "user-listing") {
      return "User Listing";
    } else if (subName === "admin/manage-access") {
      return "Manage access levels requests";
    } else if (subName === "") {
      return "Deep Knowledge Group Projects Platform";
    }

    return subName.replace("/", "");
  };

  const checkForParam = (subName) => {
    const params = subName.split("/");

    if (params[0] === "longevity-marketplace" && params[1]?.length >= 1) {
      return true;
    }
    return false;
  };


  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
      <Row>
        <Col >
          <div className="ant-page-header-heading">
            {checkForParam(subName) && (
              <ArrowLeftOutlined
                onClick={() => {
                  history("/longevity-marketplace");
                }}
              />
            )}
            <Title level={3} style={{ textTransform: "capitalize", color: "#0B5394" }}>{name()}</Title>
          </div>
          <span
            className="notice-header"
            style={{ fontSize: "0.98rem", fontWeight: "700" }}
          >
            End-to-End platform to discover, become involved with, or invest in
            Deep Knowledge Group’s current scope of market-ready products and
            projects
          </span>
        </Col>
        {subName.replace("/", "") === "my-projects" &&
          (user?.roles === "Admin" || user?.email.includes("dkv.global")) && (
            <Col span={10} md={10} className="header-control">
              <Button
                className="btn-common"
                onClick={handleCreateProjectsClick}
              >
                + Create Project
              </Button>
            </Col>
          )}
      </Row>
      {subName === "" || subName?.includes("project/") ? (
        <>
          {
            limitCategories > 0 && (
              <Card>
                {sectorCounts.slice(0, limitCategories).map((project, index) => (
                  <Card.Grid
                    style={{ textAlign: "center", width: screens.sm ? "25%" : "50%", cursor: "pointer" }}
                    onClick={() =>
                      handleSidebarClick(
                        project.name === "Partnership Projects"
                          ? "Partnerships"
                          : project.name === "Ecosystems as a Service"
                            ? "Ecosystems As A Service"
                            : project.name,
                        index + 4
                      )
                    }
                    className={`${key1 === index + 4 ? "selected-item" : ""}`}>
                    <NavLink to={subName}>
                      <div
                        className="notice-header"
                        style={{
                          whiteSpace: "pre-wrap",
                          color: "#427EEE",
                          fontWeight: 900,
                        }}
                      >
                        {project.name.split(" ").length === 2
                          ? project.name.replace(" ", "\n")
                          : project.name.length < 13
                            ? project.name + " \n \n"
                            : project.name === "Ecosystems as a Service"
                              ? "Ecosystems\nas a Service"
                              : project.name === "AI Industry Projects" &&
                                window.innerWidth > 1900
                                ? "AI Industry\nProjects"
                                : project.name}
                      </div>
                      <div
                        style={{
                          color: "#1e6bfa",
                          textAlign: "center",
                          fontWeight: 900,
                        }}
                      >
                        {project.count}
                      </div>
                    </NavLink>
                  </Card.Grid>
                ))}
                {
                  user?.email.endsWith("dkv.global") &&
                  <Card.Grid
                    style={{ display: "flex", justifyContent: "center", alignItems: "center", width: screens.sm ? "25%" : "50%", cursor: "pointer" }}
                    onClick={()=> window.open("https://docs.google.com/spreadsheets/d/1I6dFIUhTosbiArt_CfHGmlOMJDYgngIE45d2bsfhDVE/edit?gid=0#gid=0", "_blank")}
                    >
                      <div
                        style={{
                          whiteSpace: "pre-wrap",
                          color: "#427EEE",
                          fontWeight: 900,
                        }}
                      >
                        DKG Projects Registry Data Rooms
                      </div>
                  </Card.Grid>
                }
              </Card>

            )
          }
          <Button type="primary" onClick={() => setLimitCategories(prev => prev === sectorCounts.length ? 0 : sectorCounts.length)}>
            {limitCategories === sectorCounts.length ? <div>Hide list {" "} <UpOutlined /></div> : <div>Show Categories {" "} <DownOutlined /></div>}
          </Button>
          <Button type="default" onClick={() => handleSidebarClick("All Projects")}>
            Clear Filter
          </Button>
        </>
      ) : (
        ""
      )}
    </div>
  );
}

export default Header;
