import React from "react";

import { Typography, List, Button } from "antd";

import Icon, {
  DeleteOutlined,
  FileTextOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import { SuccessSvg } from "../../../../assets/images/successSvg";
import { useDispatch, useSelector } from "react-redux";
import {
  chengeAllowDocAction,
  deleteAnyDocument,
  signAnyDocuments,
} from "../../redux/action";
import { getUser } from "../../../../Utils/isAuth";

const { Text } = Typography;

const DocumentsList = ({
  documents,
  showDeleteIcon,
  showEditIcon,
  checkPath,
}) => {
  const dispatch = useDispatch();
  const { signed, pending } = useSelector((state) => state?.dealDetails);
  const user = getUser();

  const handleSign = (id) => {
    const formData = new FormData();
    formData.append("document_id", id);

    dispatch(signAnyDocuments(formData));
  };
  return (
    <List
      itemLayout="horizontal"
      dataSource={documents}
      renderItem={(item) => {
        const actions = [
          <a
            href={item.url}
            target="_blank"
            style={{
              fontSize: "30px",
              color: "#111827",
            }}
            rel="noreferrer"
          >
            <DownloadOutlined />
          </a>,
        ];

        if ((item?.delete_flag || user?.is_superuser) && showDeleteIcon) {
          if (
            item?.document_status === "not signed" &&
            !signed.includes(item.id) &&
            item?.shouldSign
          ) {
            actions.push(
              <Button
                type="primary"
                htmlType="submit"
                onClick={() => handleSign(item.id)}
                loading={pending === item.id}
              >
                Sign
              </Button>
            );
          } else {
            actions.push(<Icon component={SuccessSvg} />);
          }
          if (checkPath === "dealpage") {
            actions.push(
              <DeleteOutlined
                style={{ fontSize: "22px" }}
                onClick={() => {
                  const formData = new FormData();
                  formData.append("document_id", item.id);
                  dispatch(deleteAnyDocument(formData));
                }}
              />
            );
          }
        }

        if (
          user.is_superuser &&
          !item?.unedited &&
          checkPath === "dealpage" &&
          showEditIcon
        ) {
          actions.push(
            <Button
              type="link"
              danger={item?.delete_flag}
              loading={false}
              className={!item?.delete_flag ? "Allow edit" : "Disable Edit"}
              onClick={() => {
                const formData = new FormData();
                formData.append("document_id", item.id);
                formData.append("delete_flag", !item?.delete_flag);

                dispatch(chengeAllowDocAction(formData));
              }}
            >
              {!item?.delete_flag ? "Allow edit" : "Disable Edit"}
            </Button>
          );
        }

        return (
          <List.Item actions={actions}>
            <List.Item.Meta
              avatar={<FileTextOutlined style={{ fontSize: "2.5rem" }} />}
              title={<Text className="lable ovr-wrp">{item.name}</Text>}
            />
          </List.Item>
        );
      }}
    />
  );
};

export default DocumentsList;
