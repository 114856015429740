import React from "react";
import { Col, Row, Tabs } from "antd";

import OverviewTab from "../tabs/overviewTab/OverviewTab";
import DetailsTab from "../tabs/detailsTab/DetailsTab";
import CommentsTab from "../tabs/commentsTab/CommentsTab";
import DataRoomTab from "../tabs/dataRoom/DataRoomTab";
import BuyersTab from "../tabs/buyersTab/BuyersTab";
import TransactionTab from "../tabs/transactionTab/TransactionTab";
import AnalyticsTab from "../tabs/analyticsTab/AnalyticsTab";
import {
  isFacilitator,
  isInvestor,
  isSuperAdmin,
} from "../../../Utils";
import { getUser } from "../../../Utils/isAuth";

const { TabPane } = Tabs;

function IpDetailTabs({ IpDetails, userRole }) {
  const user = getUser();

  const checkIpType =
    IpDetails?.ips?.is_register_ip || IpDetails?.ip_info?.is_register_ip;

  const creatorEmail =
    IpDetails?.ip_info?.creator_details?.email ||
    IpDetails?.ips?.creator_details?.email;

  return (
    <>
      <Row gutter={[24, 0]}>
        <Col sx={24} md={24} lg={24} xl={24}>
          <Tabs defaultActiveKey="1" className="profile-tab-custom">
            <TabPane tab="Overview" key="1">
              {isInvestor() || isFacilitator() ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html:
                      IpDetails?.introduction ||
                      IpDetails?.ips?.introduction ||
                      IpDetails?.ip_info?.introduction,
                  }}
                />
              ) : (
                <OverviewTab data={IpDetails} />
              )}
            </TabPane>
            <TabPane tab="Details" key="2">
              <DetailsTab data={IpDetails.ip_info || IpDetails?.ips} />
            </TabPane>
            {isInvestor() || isFacilitator() || checkIpType ? null : (
              <TabPane tab="Buyers" key="3">
                <BuyersTab data={IpDetails} />
              </TabPane>
            )}
            {isInvestor() || isFacilitator() || checkIpType ? null : (
              <TabPane tab="Transactions" key="5">
                <TransactionTab data={IpDetails?.transaction_info || []} />
              </TabPane>
            )}
            {isSuperAdmin() ? null : (
              <TabPane tab="Discussions" key="6">
                <CommentsTab data={IpDetails} />
              </TabPane>
            )}
            <TabPane tab="Data Room" key="9">
              <DataRoomTab data={IpDetails} userRole={userRole} />
            </TabPane>
            {(user?.email === creatorEmail || isSuperAdmin()) && !checkIpType ? (
              <TabPane tab="Analytics" key="7">
                <AnalyticsTab data={IpDetails} />
              </TabPane>
            ) : null}
          </Tabs>
        </Col>
      </Row>
    </>
  );
}

export default IpDetailTabs;
