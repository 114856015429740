import React from "react";
import { useState, useEffect } from "react";
import { Form, Input, Button, Col } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { forgotPasswordRequest } from "../../redux/auth/action";
import { ExactPage } from "../../redux/auth/action";
import { useLocation } from "react-router-dom";

const ForgotPassword = () => {
  const [form] = Form.useForm();
  const [enteredEmail, setEnteredEmail] = useState("");
  const authDetails = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const location = useLocation();

  const emailInputChangeHandler = (e) => {
    setEnteredEmail(e.target.value);
  };

  const redirectToLogin = () => {
    //dispatch(ExactPage(3));
    if (location.pathname === "/register") {
      dispatch(ExactPage(3));
    } else {
      dispatch(ExactPage(0));
    }
  };
  const redirectToSignup = () => {
    dispatch(ExactPage(0));
  };

  useEffect(() => {
    if (authDetails.forgotPasswordSuccess) {
      setVisible(false);
      dispatch(ExactPage(authDetails.current + 1));
    }
  }, [authDetails.forgotPasswordSuccess]);

  useEffect(() => {
    if (authDetails.forgerPasswordFailded) {
      setVisible(true);
    }
  }, [authDetails.forgerPasswordFailded]);

  const onSendLink = (e) => {
    e.preventDefault();
    const payload = { email: enteredEmail };
    localStorage.setItem("recoveryEmail", enteredEmail);
    dispatch(forgotPasswordRequest(payload));
    // }
  };

  return (
    <>
      <Col span={16} offset={4} className="rm-offset">
        {
          <Form
            name="createpassword"
            form={form}
            initialValues={{
              remember: false,
            }}
            autoComplete="off"
          >
            <h1 className="create-account">Forgot your password?</h1>
            <div className="option-text">
              <p>We'll email you instructions on how to reset it.</p>
            </div>

            {visible && (
              <Col
                className="gutter-row rm-padding error-message"
                xs={{ span: 24 }}
                md={{ span: 24 }}
              >
                <p>Invalid user!</p>
              </Col>
            )}
            <Form.Item
              name="email"
              hasFeedback
              label={<span className="label-input">Email address</span>}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  type: "email",
                  message: "Please enter a valid email!",
                },
              ]}
            >
              <Input
                className="input-style input-font-style"
                placeholder="you@example.com"
                size="large"
                id="email"
                name="email"
                onChange={emailInputChangeHandler}
              />
            </Form.Item>

            <Button
              className="form-submit-btn"
              type="primary"
              htmlType="submit"
              shape="round"
              size="large"
              onClick={onSendLink}
            >
              Send link
            </Button>
            <div className="password-reset-link">
              <p>
                Remember password?
                <Button
                  type="link"
                  htmlType="submit"
                  onClick={redirectToLogin}
                  className="resend_link"
                >
                  Log in
                </Button>
              </p>{" "}
            </div>
            {/* <div className="password-reset-link">
              <p>
                Don't have an account?
                <Button
                  type="link"
                  htmlType="submit"
                  onClick={redirectToSignup}
                  className="resend_link"
                >
                  Register
                </Button>
              </p>{" "}
            </div> */}
          </Form>
        }
      </Col>
    </>
  );
};

export default ForgotPassword;
