import { Avatar, Button, Col, Row, Space, Grid, Table, Typography } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Line } from "@ant-design/plots";
import Title from "antd/lib/typography/Title";
import { analyticLineChart } from "../../../../Utils";
import { createChatAction } from "../../../chatPage/redux/action";
import { useDispatch } from "react-redux";

const AnalyticsDetailsData = ({
  selected,
  setSelected,
  analyticData,
  chartData,
  count,
}) => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const { Text } = Typography;
  const screens = Grid.useBreakpoint();

  const handleChatClick = (item) => {
    const formData = new FormData();
    formData.append("user_ids", JSON.stringify([item.user_id]));

    dispatch(createChatAction(formData));

    history("/chat");
  };

  const DocColumns = [
    {
      title: "Visitor",
      dataIndex: "user_name",
      key: "user_name",
      render: (_, record) => {
        return (
          <div
            className="d-flex"
            style={{
              gap: "16px",
              justifyContent: "flex-start",
            }}
          >
            {record?.user_photo ? (
              <Avatar src={record?.user_photo} />
            ) : (
              <Avatar>{record?.user_name?.[0]}</Avatar>
            )}
            <div>
              <div>{record?.user_name}</div>
              <div style={{ color: "grey" }}>{record?.time_ago}</div>
            </div>
          </div>
        );
      },
    },
    {
      title: "Document",
      dataIndex: "document_name",
      key: "document_name",
      render: (item) => <div className="document__format">{item || "-"}</div>,
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (item) => <div>{item || "-"}</div>,
    },
    {
      title: "Message",
      dataIndex: "user_id",
      key: "user_id",
      render: (_, record) => (
        <Button onClick={() => handleChatClick(record)} type="primary">
          Message
        </Button>
      ),
    },
  ];
  const DealColumns = [
    {
      title: "Visitor",
      dataIndex: "user_name",
      key: "user_name",
      render: (_, record) => {
        return (
          <div
            className="d-flex"
            style={{
              gap: "16px",
              justifyContent: "flex-start",
            }}
          >
            {record?.user_photo ? (
              <Avatar src={record?.user_photo} />
            ) : (
              <Avatar>{record?.user_name?.[0]}</Avatar>
            )}
            <div>
              <div>{record?.user_name}</div>
              <div style={{ color: "grey" }}>{record?.time_ago}</div>
            </div>
          </div>
        );
      },
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (item) => <div>{item || "-"}</div>,
    },
    {
      title: "Message",
      dataIndex: "user_id",
      key: "user_id",
      render: (_, record) => (
        <Button onClick={() => handleChatClick(record)} type="primary">
          Message
        </Button>
      ),
    },
  ];
  return (
    <Row>
      <Space className="center ml-15" style={{ alignItems: "baseline" }}>
        <ArrowLeftOutlined
          className="pointer"
          style={{ stroke: "black", strokeWidth: 40 }}
          onClick={() => {
            setSelected("");
          }}
        />
        <div style={{ marginLeft: "30px", display: "grid" }}>
          <Text className="financial_title">
            {selected === "dealPage" ? "Deal Viewers" : "Documents Downloaded"}
          </Text>
          <Text type="secondary">Last 90 days</Text>
        </div>
      </Space>
      <Col
        span={24}
        style={
          screens?.xs
            ? {
                margin: "10px 20px",
                width: "auto",
                maxWidth: "400px",
              }
            : {
                margin: "20px 30px",
                width: "500px",
              }
        }
      >
        <Title level={4} style={{ marginBottom: "30px" }}>
          {count}
          <Text
            type="secondary"
            style={{ fontSize: "20px", marginLeft: "5px" }}
          >
            {selected === "dealPage" ? "Visitors" : "Downloads"}
          </Text>
        </Title>

        {chartData.length > 0 && (
          <Line
            style={{ marginTop: "10px", maxWidth: "1000px" }}
            {...analyticLineChart(chartData)}
          />
        )}
      </Col>

      <Col span={24} style={{ marginBottom: "20px", maxWidth: "1020px" }}>
        {analyticData.length <= 0 ? (
          <h4 className="center justify-content-center">No data available</h4>
        ) : (
          <Table
            className="analytics__table"
            columns={selected === "documentPage" ? DocColumns : DealColumns}
            dataSource={analyticData}
          />
        )}
      </Col>
    </Row>
  );
};

export default AnalyticsDetailsData;
