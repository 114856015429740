import {
  CLEAR_USER,
  GET_USER_DETAILS_REQUEST,
  GET_SUBSCRIBE,
  POST_SUBSCRIBE,
} from "./constants";

export const userDetailsAction = (data) => {
  return {
    type: GET_USER_DETAILS_REQUEST,
    data,
  };
};

export const clearUser = () => {
  return {
    type: CLEAR_USER,
  };
};

export const subscribe = () => {
  return {
    type: GET_SUBSCRIBE,
  };
};

export const postsubscribe = (data) => {
  return {
    type: POST_SUBSCRIBE,
    data,
  };
};
