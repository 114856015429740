export const DashboardIcon = () => (
  <svg
    width="37"
    height="28"
    viewBox="0 0 37 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M36.6254 14.8179L26.0758 8.48781L27.3924 7.65069C27.6144 7.50883 27.7485 7.26371 27.7485 7.00008C27.7485 6.73646 27.6144 6.49133 27.3924 6.34947L18.9132 0.953764C18.6604 0.793407 18.3382 0.793407 18.0853 0.953764L9.60621 6.34962C9.38413 6.4914 9.25001 6.73653 9.25001 7.00015C9.25001 7.26378 9.38413 7.5089 9.60613 7.65076L10.9227 8.4894L0.374637 14.8179C0.143387 14.9567 1.21902e-05 15.2064 1.21902e-05 15.4762C-0.00150539 15.746 0.138762 15.9973 0.370012 16.1376L18.0991 26.9292C18.2225 27.0047 18.3613 27.0417 18.5 27.0417C18.6388 27.0417 18.7775 27.0047 18.9009 26.9292L36.63 16.1376C36.8613 15.9958 37 15.746 37 15.4763C36.9985 15.2064 36.8566 14.9567 36.6254 14.8179ZM18.5 2.51853L25.5439 7.00015L18.5 11.4818L11.4561 7.00015L18.5 2.51853ZM12.3765 9.4144L18.0853 13.0465C18.2117 13.1267 18.3566 13.1668 18.5 13.1668C18.6434 13.1668 18.7883 13.1267 18.9132 13.0465L24.6219 9.41281L25.6379 10.0233L18.5 14.5667L11.3605 10.0234L12.3765 9.4144ZM2.26164 15.4809L9.88363 10.9099L17.0647 15.4794L9.8251 20.0859L2.26164 15.4809ZM18.5 25.3675L11.2927 20.9799L18.5 16.3935L25.7073 20.98L18.5 25.3675ZM27.175 20.0874L19.9353 15.4794L27.1165 10.9099L34.7384 15.4825L27.175 20.0874Z"
      fill="#6B7280"
    />
  </svg>
);
