import { call, put, takeLatest } from "redux-saga/effects";

import { getAdminInvestmentApi, updateInvestmentStatusApi } from "../../../api";
import {
  GET_ADMIN_INVESTMENT_REQUEST,
  GET_ADMIN_INVESTMENT_SUCCESS,
  GET_ADMIN_INVESTMENT_ERROR,
  UPDATE_INVESTMENT_STATUS_REQUEST,
} from "./constants";

function* getAdminInvestmentRequest(action) {
  try {
    let { data } = yield call(getAdminInvestmentApi, action?.data);

    if (data) {
      yield put({ type: GET_ADMIN_INVESTMENT_SUCCESS, data });
    }
  } catch (err) {
    yield put({ type: GET_ADMIN_INVESTMENT_ERROR });
  }
}

function* updateInvestmentStatusRequest(action) {
  try {
    let { data } = yield call(updateInvestmentStatusApi, action?.data);

    if (data?.Msg) {
      yield put({ type: GET_ADMIN_INVESTMENT_REQUEST });
    }
  } catch (err) {
    yield put({ type: GET_ADMIN_INVESTMENT_ERROR });
  }
}

function* AdminInvestmentWatcher() {
  yield takeLatest(GET_ADMIN_INVESTMENT_REQUEST, getAdminInvestmentRequest);
  yield takeLatest(
    UPDATE_INVESTMENT_STATUS_REQUEST,
    updateInvestmentStatusRequest
  );
}
export default AdminInvestmentWatcher;
