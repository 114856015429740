/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import {
  Avatar,
  Button,
  Card,
  Dropdown,
  Menu,
  Progress,
  Tag,
  Divider,
  Modal,
  Input,
} from "antd";

import Icon from "@ant-design/icons";
import Title from "antd/lib/typography/Title";
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  QuestionCircleOutlined,
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
} from "@ant-design/icons";

import { adminDealUpdateRequest } from "../../../pages/adminDeals/redux/action";

import { deleteDealRequest } from "../../../pages/deals/redux/action";

import "./style.scss";
import { DropdownSvg } from "../../../assets/images/dropdownSvg";
// import { LinkSvg } from "../../../assets/images/linkSvg";
// import { ExternalLinkSvg } from "../../../assets/images/externalLinkSvg";
import ComplianceCheck from "../../../assets/images/compliance-check.png";
import RejectDeal from "../../../assets/images/reject-deal.png";
import ApproveDealModalIcon from "../../../assets/images/modal-approve-deal.png";
import { BASE_URL } from "../../../api";

import user from "../../../assets/images/documentdeals.png";
import { getUser } from "../../../Utils/isAuth";
import { updateServiceOrganizerDealStatusRequest } from "../../../pages/serviceOrganizerDeals/redux/action";
import { getFirstLater, getImageURL } from "../../../Utils";
const { TextArea } = Input;

// const organizeMenu = (
//   <Menu className="edit-menu">
//     <Menu.Item
//       onClick={(e) => console.log("Edit")}
//       className="edit-menu__item"
//       key="edit-menu-item-1"
//     >
//       <Icon component={LinkSvg} className="edit-menu__icon" />
//       <p className="list-item">Invite investor</p>
//     </Menu.Item>
//     <Menu.Item className="edit-menu__item" key="edit-menu-item-2">
//       <Icon component={ExternalLinkSvg} className="edit-menu__icon" />
//       <p className="list-item">Publish deal</p>
//     </Menu.Item>
//   </Menu>
// );
const showConfirm = (e, record, dispatch) => {
  Modal.confirm({
    className:'delete__modal',
    title: "Are you sure you want to delete the deal permanently? This action cannot be undone.",
    okText: "Yes",
    cancelText: "No",
    centered: true,
    onOk: () => {
      dispatch(deleteDealRequest({ deal_id: record?.id }));
    },
    onCancel() {
      console.log("Cancel");
    },
  });
};

const organizeDraftsMenu = (record, dispatch, navigate) => (
  <Menu className="edit-menu">
    <Menu.Item
      onClick={(event) => {
        event.domEvent.stopPropagation();
        navigate(`/edit-deal/${record?.id}`);
      }}
      className="edit-menu__item"
      key="edit-menu-item-1"
    >
      <EditOutlined className="menu-item-icon editing-txt" />
      <span className="list-item editing-txt">Edit Deal</span>
    </Menu.Item>
    <Menu.Item
      className="edit-menu__item"
      key="edit-menu-item-2"
      onClick={(e) => {
        e.domEvent.stopPropagation();
        showConfirm(e, record, dispatch);
      }}
    >
      <CloseOutlined className="menu-item-icon reject-txt" />
      <span className="list-item reject-txt">Delete deal</span>
    </Menu.Item>
  </Menu>
);

const AdminDealInReviewMenu = ({ record, dispatch, navigate }) => {
  const [visibleApprove, setVisibleApprove] = useState(false);
  const [visibleReject, setVisibleReject] = useState(false);
  const [comment, setComment] = useState();
  const { is_superuser, type_of_account } = getUser();

  const handleInput = (e) => {
    e.domEvent.stopPropagation();
    setComment(e.target.value);
  };

  const showApproveModal = (e) => {
    e.domEvent.stopPropagation();
    setVisibleApprove(true);
  };

  const showRejectModal = (e) => {
    e.domEvent.stopPropagation();
    setVisibleReject(true);
  };

  const handleOkApprove = (e) => {
    e.stopPropagation();
    dispatch(
      is_superuser
        ? adminDealUpdateRequest({
            deal_status: "Approve",
            deal_id: record?.id,
          })
        : updateServiceOrganizerDealStatusRequest({
            deal_status: "Approve",
            deal_id: record?.id,
          })
    );
    setVisibleApprove(false);
  };

  const handleCancelApprove = (e) => {
    e.stopPropagation();
    setVisibleApprove(false);
  };

  const handleOkReject = (e) => {
    e.stopPropagation();
    dispatch(
      is_superuser
        ? adminDealUpdateRequest({
            deal_status: "Reject",
            deal_id: record?.id,
            deal_comment: comment,
          })
        : updateServiceOrganizerDealStatusRequest({
            deal_status: "Reject",
            deal_id: record?.id,
          })
    );
    setVisibleReject(false);
  };

  const handleCancelReject = (e) => {
    e.stopPropagation();
    setVisibleReject(false);
  };

  return (
    <>
      <Menu className="edit-menu">
        <Menu.Item
          onClick={(event) => {
            event.domEvent.stopPropagation();
            dispatch(
              is_superuser
                ? adminDealUpdateRequest({
                    deal_status: "Compliance Check",
                    deal_id: record?.id,
                  })
                : updateServiceOrganizerDealStatusRequest({
                    deal_status: "Compliance Check",
                    deal_id: record?.id,
                  })
            );
          }}
          className="edit-menu__item"
          key="edit-menu-item-1"
        >
          <div className="edit-menu__item">
            <img
              src={ComplianceCheck}
              className="edit-menu__icon edit-menu__item menu-icon-size"
              alt=""
            />{" "}
            <span className="compliance-container">Compliance Check</span>
          </div>
        </Menu.Item>
        <Divider className="divider-margin" />
        <Menu.Item
          className="edit-menu__item"
          key="edit-menu-item-2"
          onClick={showApproveModal}
          style={{ color: "#34D399" }}
        >
          <CheckOutlined className="menu-item-icon approve-txt" />
          <span className="list-item approve-txt"> Approve Deal</span>
        </Menu.Item>
        <Menu.Item
          className="edit-menu__item"
          key="edit-menu-item-3"
          onClick={showRejectModal}
          style={{ color: "#EF4444" }}
        >
          <CloseOutlined className="menu-item-icon reject-txt" />
          <span className="list-item reject-txt">Reject Deal</span>
        </Menu.Item>
        {(is_superuser || type_of_account === "ORG") && (
          <Menu.Item
            className="edit-menu__item"
            key="edit-menu-item-4"
            onClick={(event) => {
              event.domEvent.stopPropagation();
              navigate(`/edit-deal/${record?.id}`);
            }}
            style={{ color: "#000" }}
          >
            <EditOutlined className="menu-item-icon editing-txt" />
            <span className="list-item editing-txt">Edit Deal</span>
          </Menu.Item>
        )}
      </Menu>
      <Modal
        visible={visibleApprove}
        onOk={handleOkApprove}
        onCancel={handleCancelApprove}
        className="approve-modal"
        centered={true}
        footer={[
          <Button
            key="no"
            onClick={handleCancelApprove}
            className="modal-cancel-btn"
          >
            No
          </Button>,
          <Button
            key="yes"
            type="primary"
            onClick={handleOkApprove}
            className="modal-yes-btn"
          >
            Yes
          </Button>,
        ]}
      >
        <div>
          <img
            src={ApproveDealModalIcon}
            className="approve-modal-icon"
            alt=""
          />
          <p className="approve-modal-title">Approve a deal?</p>
          <p className="approve-modal-desc">
            Are you sure you want to approve a deal? This action cannot be
            undone.
          </p>
        </div>
      </Modal>
      <Modal
        visible={visibleReject}
        onOk={handleOkReject}
        onCancel={handleCancelReject}
        className="approve-modal"
        centered={true}
        footer={[
          <Button
            key="no"
            onClick={handleCancelReject}
            className="modal-cancel-btn"
          >
            No
          </Button>,
          <Button
            key="yes"
            type="primary"
            onClick={handleOkReject}
            className="reject-modal-yes-btn "
          >
            Yes
          </Button>,
        ]}
      >
        <div>
          <img src={RejectDeal} className="reject-modal-icon" alt="" />
          <p className="approve-modal-title">Reject a Deal?</p>
          <p className="approve-modal-desc">
            Are you sure you want to reject a deal? This action cannot be
            undone.
          </p>
          <TextArea rows={4} onChange={handleInput} />
        </div>
      </Modal>
    </>
  );
};

const AdminDealComplianceCheckMenu = ({ record, dispatch, navigate }) => {
  const [visibleApprove, setVisibleApprove] = useState(false);
  const [visibleReject, setVisibleReject] = useState(false);
  const [comment, setComment] = useState();
  const { is_superuser, type_of_account } = getUser();

  const handleInput = (e) => {
    setComment(e.target.value);
  };

  const showApproveModal = (e) => {
    e.domEvent.stopPropagation();
    setVisibleApprove(true);
  };

  const showRejectModal = (e) => {
    e.domEvent.stopPropagation();
    setVisibleReject(true);
  };

  const handleOkApprove = (e) => {
    e.stopPropagation();
    dispatch(
      is_superuser
        ? adminDealUpdateRequest({
            deal_status: "Approve",
            deal_id: record?.id,
          })
        : updateServiceOrganizerDealStatusRequest({
            deal_status: "Approve",
            deal_id: record?.id,
          })
    );
    setVisibleApprove(false);
  };

  const handleCancelApprove = (e) => {
    e.stopPropagation();
    setVisibleApprove(false);
  };

  const handleOkReject = (e) => {
    e.stopPropagation();
    dispatch(
      is_superuser
        ? adminDealUpdateRequest({
            deal_status: "Reject",
            deal_id: record?.id,
            deal_comment: comment,
          })
        : updateServiceOrganizerDealStatusRequest({
            deal_status: "Reject",
            deal_id: record?.id,
          })
    );
    setVisibleReject(false);
  };

  const handleCancelReject = (e) => {
    e.stopPropagation();
    setVisibleReject(false);
  };

  return (
    <>
      <Menu className="edit-menu">
        <Menu.Item
          className="edit-menu__item"
          key="edit-menu-item-1"
          onClick={showApproveModal}
          style={{ color: "#34D399" }}
        >
          <CheckOutlined className="menu-item-icon approve-txt" />
          <span className="list-item approve-txt"> Approve Deal</span>
        </Menu.Item>
        <Menu.Item
          className="edit-menu__item"
          key="edit-menu-item-2"
          onClick={showRejectModal}
          style={{ color: "#EF4444" }}
        >
          <CloseOutlined className="menu-item-icon reject-txt" />
          <span className="list-item reject-txt">Reject</span>
        </Menu.Item>
        {(is_superuser || type_of_account === "ORG") && (
          <Menu.Item
            className="edit-menu__item"
            key="edit-menu-item-3"
            onClick={(event) => {
              event.domEvent.stopPropagation();
              navigate(`/edit-deal/${record?.id}`);
            }}
            style={{ color: "#000" }}
          >
            {/* <Icon component={EditSvg} className="edit-menu__icon editing-txt" /> */}
            <EditOutlined className="menu-item-icon editing-txt" />
            <span className="list-item editing-txt">Edit Deal</span>
          </Menu.Item>
        )}
      </Menu>
      <Modal
        visible={visibleApprove}
        onOk={handleOkApprove}
        onCancel={handleCancelApprove}
        centered={true}
        className="approve-modal"
        footer={[
          <Button
            key="no"
            onClick={handleCancelApprove}
            className="modal-cancel-btn"
          >
            No
          </Button>,
          <Button
            key="yes"
            type="primary"
            onClick={handleOkApprove}
            className="modal-yes-btn"
          >
            Yes
          </Button>,
        ]}
      >
        <div>
          <img
            src={ApproveDealModalIcon}
            className="approve-modal-icon"
            alt=""
          />
          <p className="approve-modal-title">Approve a deal?</p>
          <p className="approve-modal-desc">
            Are you sure you want to approve a deal? This action cannot be
            undone.
          </p>
        </div>
      </Modal>
      <Modal
        visible={visibleReject}
        onOk={handleOkReject}
        onCancel={handleCancelReject}
        centered={true}
        className="approve-modal"
        footer={[
          <Button
            key="no"
            onClick={handleCancelReject}
            className="modal-cancel-btn"
          >
            No
          </Button>,
          <Button
            key="yes"
            type="primary"
            onClick={handleOkReject}
            className="reject-modal-yes-btn "
          >
            Yes
          </Button>,
        ]}
      >
        <div>
          <img src={RejectDeal} className="reject-modal-icon" alt="" />
          <p className="approve-modal-title">Reject a Deal?</p>
          <p className="approve-modal-desc">
            Are you sure you want to reject a deal? This action cannot be
            undone.
          </p>
          <TextArea rows={4} onChange={handleInput} />
        </div>
      </Modal>
    </>
  );
};

const AdminDealMenu = ({ record, dispatch, navigate }) => {
  const { is_superuser, type_of_account } = getUser();
  return (
    <Menu className="edit-menu">
      <Menu.Item
        className="edit-menu__item"
        key="edit-menu-item-1"
        onClick={(e) => {
          e.domEvent.stopPropagation();
          dispatch(
            is_superuser
              ? adminDealUpdateRequest({
                  deal_status: "Close",
                  deal_id: record?.id,
                })
              : updateServiceOrganizerDealStatusRequest({
                  deal_status: "Reject",
                  deal_id: record?.id,
                })
          );
        }}
        style={{ color: "#EF4444" }}
      >
        <CloseOutlined className="menu-item-icon reject-txt" />
        <span className="list-item reject-txt">Close The Deal</span>
      </Menu.Item>
      {(is_superuser || type_of_account === "ORG") && (
        <Menu.Item
          className="edit-menu__item"
          key="edit-menu-item-2"
          onClick={(event) => {
            event.domEvent.stopPropagation();
            navigate(`/edit-deal/${record?.id}`);
          }}
          style={{ color: "#000" }}
        >
          <EditOutlined className="menu-item-icon editing-txt" />
          <span className="list-item editing-txt">Edit Deal</span>
        </Menu.Item>
      )}
    </Menu>
  );
};

const investorDeviceColumns = () => [
  {
    render: (text, record) => {
      let backgroundColor = "";
      let textColor = "";
      if (record.status === "Completed") {
        textColor = "#1F2937";
        backgroundColor = "#F3F4F6";
      } else if (record.status === "In Progress") {
        textColor = "#92400E";
        backgroundColor = "#FEF3C7";
      } else if (record.status === "Active") {
        textColor = "#027A48";
        backgroundColor = "#ECFDF3";
      } else if (record.status === "In Review") {
        textColor = "#0B5394";
        backgroundColor = "#F0F9FF";
      }
      return (
        <Card
          className="deal-table-card"
          title={
            <Avatar.Group>
              <Avatar
                className={`${
                  !record.deal_logo ? "shape-avatar-letter" : ""
                } shape-avatar`}
                shape="round"
                size={40}
                src={getImageURL(record.deal_logo || "") || user}
              >
                {getFirstLater(record?.title || "")}
              </Avatar>
              <div className="avatar-info">
                <Title level={5}>{record.title}</Title>
                <p>{record.company_website}</p>
              </div>
            </Avatar.Group>
          }
          extra={
            <div>
              <Tag className="tag_text" color={backgroundColor}>
                <span
                  style={{
                    color: textColor,
                    fontFamily: "Manrope",
                    fontStyle: "normal",
                    fontWeight: 500,
                    fontSize: "14px",
                    lineHeight: "20px",
                    letterSpacing: "0.01em",
                  }}
                >
                  &#9679;&nbsp;{record.status}
                </span>
              </Tag>
            </div>
          }
        >
          <Card.Grid className="card-grid" hoverable={false}>
            <div className="card-grid__item">
              <p>Sector</p>
              <Title level={5}>{record?.sector[0]?.sector_name}</Title>
            </div>
          </Card.Grid>
          <Card.Grid className="card-grid" hoverable={false}>
            <div className="card-grid__item">
              <p>Amount</p>
              <Title level={5}>{record.minimal_closing_amount}</Title>
            </div>
          </Card.Grid>
          <Card.Grid className="card-grid" hoverable={false}>
            <div className="card-grid__item">
              <p>Completion</p>
              <Progress
                percent={record.progress_bar}
                status="active"
                showInfo={false}
                className="card-progress"
              />
            </div>
          </Card.Grid>
        </Card>
      );
    },
  },
];

const organizeDeviceColumns = (dispatch, navigate) => [
  {
    render: (text, record) => {
      let backgroundColor = "";
      let textColor = "";
      if (record.status === "Completed") {
        textColor = "#1F2937";
        backgroundColor = "#F3F4F6";
      } else if (record.status === "In Progress") {
        textColor = "#92400E";
        backgroundColor = "#FEF3C7";
      } else if (record.status === "Active") {
        textColor = "#027A48";
        backgroundColor = "#ECFDF3";
      } else if (record.status === "In Review") {
        textColor = "#0B5394";
        backgroundColor = "#F0F9FF";
      }

      const avatars = [].map((el) => {
        return <Avatar src={el} />;
      });

      return (
        <Card
          className="deal-table-card"
          title={
            <Avatar.Group>
              <Avatar
                className={`${
                  !record.deal_logo ? "shape-avatar-letter" : ""
                } shape-avatar`}
                shape="round"
                size={40}
                src={getImageURL(record.deal_logo || "") || user}
              >
                {getFirstLater(record?.title || "")}
              </Avatar>
              <div className="avatar-info">
                <Title level={5}>{record.title || record.deal_title}</Title>
                <p>{record.company_website}</p>
              </div>
            </Avatar.Group>
          }
          extra={
            <div>
              <Tag className="tag_text" color={backgroundColor}>
                <span
                  style={{
                    color: textColor,
                    fontFamily: "Manrope",
                    fontStyle: "normal",
                    fontWeight: 500,
                    fontSize: "14px",
                    lineHeight: "20px",
                    letterSpacing: "0.01em",
                  }}
                >
                  &#9679;&nbsp;{record.status}
                </span>
              </Tag>
              <Dropdown
                overlay={organizeDraftsMenu(record, dispatch, navigate)}
                trigger={["click"]}
                placement="bottomLeft"
              >
                <Icon
                  component={DropdownSvg}
                  className="dropdown-menu-button"
                />
              </Dropdown>
            </div>
          }
        >
          <Card.Grid className="card-grid" hoverable={false}>
            <div className="card-grid__item">
              <p>Investors</p>
              <Avatar.Group
                maxCount={2}
                maxStyle={{
                  color: "#475467",
                  backgroundColor: "#F3F4F6",
                }}
              >
                {avatars.length > 0 ? avatars : ""}
              </Avatar.Group>
            </div>
          </Card.Grid>
          <Card.Grid className="card-grid" hoverable={false}>
            <div className="card-grid__item">
              <p>Target amount</p>
              <Title level={5}>
                {record?.is_m_and_a
                  ? record?.price
                  : record?.minimal_closing_amount}
              </Title>
            </div>
          </Card.Grid>
          <Card.Grid className="card-grid" hoverable={false}>
            <div className="card-grid__item">
              <p>Completion</p>
              <Progress
                percent={record.progress_bar}
                status="active"
                showInfo={false}
                className="card-progress"
              />
            </div>
          </Card.Grid>
        </Card>
      );
    },
  },
];

const organizeColumns = (dispatch, navigate) => [
  {
    title: ({ sortColumns }) => {
      const sortedColumn = sortColumns?.find(
        ({ column }) => column.key === "name" || "deal_title"
      );
      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          Name
          {sortedColumn?.order ? (
            sortedColumn.order === "ascend" ? (
              <ArrowUpOutlined style={{ marginLeft: "8px" }} />
            ) : (
              <ArrowDownOutlined style={{ marginLeft: "8px" }} />
            )
          ) : null}
        </div>
      );
    },

    sorter: (a, b) => a.title.localeCompare(b.title),
    dataIndex: "name",
    key: "name",
    render: (text, record) => {
      return (
        <Avatar.Group>
          <Avatar
            className={`${
              !record.deal_logo ? "shape-avatar-letter" : ""
            } shape-avatar`}
            shape="round"
            size={40}
            src={getImageURL(record.deal_logo || "") || user}
          >
            {getFirstLater(record?.title || "")}
          </Avatar>
          <div className="avatar-info">
            <Title level={5}>{record.title || record.deal_title}</Title>
            <p>{record.company_website}</p>
          </div>
        </Avatar.Group>
      );
    },
  },
  {
    title: ({ sortColumns }) => {
      const sortedColumn = sortColumns?.find(
        ({ column }) => column.key === "minimal_closing_amount"
      );

      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          Target Amount
          {sortedColumn?.order ? (
            sortedColumn.order === "ascend" ? (
              <ArrowUpOutlined style={{ marginLeft: "8px" }} />
            ) : (
              <ArrowDownOutlined style={{ marginLeft: "8px" }} />
            )
          ) : null}
        </div>
      );
    },
    sorter: (a, b) =>
      (a?.is_m_and_a ? a?.price : a?.minimal_closing_amount) -
      (b?.is_m_and_a ? b?.price : b?.minimal_closing_amount),
    dataIndex: "minimal_closing_amount",
    key: "minimal_closing_amount",
    render: (_, row) => {
      if (row?.is_m_and_a) {
        return row?.price || "";
      } else {
        return row?.minimal_closing_amount || "";
      }
    },
  },
  {
    title: "Investors",
    key: "Investors",
    render: (text, record) => {
      const avatars = [].map((el) => {
        return <Avatar src={el} />;
      });

      return (
        <Avatar.Group
          maxCount={2}
          maxStyle={{
            color: "#475467",
            backgroundColor: "#F3F4F6",
          }}
        >
          {avatars.length ? avatars : ""}
        </Avatar.Group>
      );
    },
  },
  {
    title: ({ sortColumns }) => {
      const sortedColumn = sortColumns?.find(
        ({ column }) => column.key === "progress_bar"
      );

      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          Completion
          {sortedColumn?.order ? (
            sortedColumn.order === "ascend" ? (
              <ArrowUpOutlined style={{ marginLeft: "8px" }} />
            ) : (
              <ArrowDownOutlined style={{ marginLeft: "8px" }} />
            )
          ) : null}
        </div>
      );
    },
    dataIndex: "progress_bar",
    key: "progress_bar",
    sorter: (a, b) => a.minimal_closing_amount - b.minimal_closing_amount,
    render: (progress_bar) => (
      <Progress percent={progress_bar} status="active" showInfo={false} />
    ),
  },
  {
    title: (
      <div style={{ display: "flex", alignItems: "center" }}>
        Status <QuestionCircleOutlined style={{ marginLeft: "8px" }} />
      </div>
    ),
    dataIndex: "status",
    key: "status",
    render: (status) => {
      let backgroundColor = "";
      let textColor = "";
      if (status === "Completed") {
        textColor = "#1F2937";
        backgroundColor = "#F3F4F6";
      } else if (status === "In Progress") {
        textColor = "#92400E";
        backgroundColor = "#FEF3C7";
      } else if (status === "Active") {
        textColor = "#027A48";
        backgroundColor = "#ECFDF3";
      } else if (status === "In Review") {
        textColor = "#0B5394";
        backgroundColor = "#F0F9FF";
      }
      return (
        <Tag className="tag_text" color={backgroundColor}>
          <span
            style={{
              color: textColor,
              fontFamily: "Manrope",
              fontStyle: "normal",
              fontWeight: 500,
              fontSize: "14px",
              lineHeight: "20px",
              letterSpacing: "0.01em",
            }}
          >
            &#9679;&nbsp;{status}
          </span>
        </Tag>
      );
    },
  },
  {
    render: (text, record) => {
      return (
        <Dropdown
          overlay={organizeDraftsMenu(record, dispatch, navigate)}
          trigger={["click"]}
          placement="bottomLeft"
        >
          <Icon component={DropdownSvg} className="dropdown-menu-button" />
        </Dropdown>
      );
    },
  },
];

const investorColumns = () => [
  {
    title: ({ sortColumns }) => {
      const sortedColumn = sortColumns?.find(
        ({ column }) => column.key === "name" || "deal_title"
      );

      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          Name
          {sortedColumn?.order ? (
            sortedColumn.order === "ascend" ? (
              <ArrowUpOutlined style={{ marginLeft: "8px" }} />
            ) : (
              <ArrowDownOutlined style={{ marginLeft: "8px" }} />
            )
          ) : null}
        </div>
      );
    },

    sorter: (a, b) => a.title.localeCompare(b.title),
    dataIndex: "name",
    key: "name",
    render: (text, record) => {
      return (
        <Avatar.Group>
          <Avatar
            className={`${
              !record.deal_logo ? "shape-avatar-letter" : ""
            } shape-avatar`}
            shape="round"
            size={40}
            src={getImageURL(record.deal_logo || "") || user}
          >
            {getFirstLater(record?.title || "")}
          </Avatar>
          <div className="avatar-info">
            <Title level={5}>{record.title}</Title>
            <p>{record.company_website}</p>
          </div>
        </Avatar.Group>
      );
    },
  },
  {
    title: ({ sortColumns }) => {
      const sortedColumn = sortColumns?.find(
        ({ column }) => column.key === "sector"
      );

      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          Sector
          {sortedColumn?.order ? (
            sortedColumn.order === "ascend" ? (
              <ArrowUpOutlined style={{ marginLeft: "8px" }} />
            ) : (
              <ArrowDownOutlined style={{ marginLeft: "8px" }} />
            )
          ) : null}
        </div>
      );
    },
    dataIndex: "sector",
    key: "sector",
    sorter: (a, b) => a.title.localeCompare(b.title),
    render: (text, record) => {
      return (
        <div className="author-info">
          <Title level={5}>{record?.sector[0]?.sector_name}</Title>
        </div>
      );
    },
  },
  {
    title: ({ sortColumns }) => {
      const sortedColumn = sortColumns?.find(
        ({ column }) => column.key === "minimal_closing_amount"
      );

      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          Amount
          {sortedColumn?.order ? (
            sortedColumn.order === "ascend" ? (
              <ArrowUpOutlined style={{ marginLeft: "8px" }} />
            ) : (
              <ArrowDownOutlined style={{ marginLeft: "8px" }} />
            )
          ) : null}
        </div>
      );
    },
    sorter: (a, b) => a.minimal_closing_amount - b.minimal_closing_amount,
    dataIndex: "minimal_closing_amount",
    key: "minimal_closing_amount",
  },
  {
    title: ({ sortColumns }) => {
      const sortedColumn = sortColumns?.find(
        ({ column }) => column.key === "progress_bar"
      );

      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          Completion
          {sortedColumn?.order ? (
            sortedColumn.order === "ascend" ? (
              <ArrowUpOutlined style={{ marginLeft: "8px" }} />
            ) : (
              <ArrowDownOutlined style={{ marginLeft: "8px" }} />
            )
          ) : null}
        </div>
      );
    },
    dataIndex: "progress_bar",
    key: "progress_bar",
    sorter: (a, b) => a.minimal_closing_amount - b.minimal_closing_amount,
    render: (progress_bar) => (
      <Progress percent={progress_bar} status="active" showInfo={false} />
    ),
  },
  {
    title: (
      <div style={{ display: "flex", alignItems: "center" }}>
        Status <QuestionCircleOutlined style={{ marginLeft: "8px" }} />
      </div>
    ),
    dataIndex: "status",
    key: "status",
    render: (status) => {
      let backgroundColor = "";
      let textColor = "";
      if (status === "Completed") {
        textColor = "#1F2937";
        backgroundColor = "#F3F4F6";
      } else if (status === "In Progress") {
        textColor = "#92400E";
        backgroundColor = "#FEF3C7";
      } else if (status === "Active") {
        textColor = "#027A48";
        backgroundColor = "#ECFDF3";
      } else if (status === "In Review") {
        textColor = "#0B5394";
        backgroundColor = "#F0F9FF";
      }
      return (
        <Tag className="tag_text" color={backgroundColor}>
          <span
            style={{
              color: textColor,
              fontFamily: "Manrope",
              fontStyle: "normal",
              fontWeight: 500,
              fontSize: "14px",
              lineHeight: "20px",
              letterSpacing: "0.01em",
            }}
          >
            &#9679;&nbsp;{status}
          </span>
        </Tag>
      );
    },
  },
];

const adminColumns = (dispatch, navigate) => [
  {
    title: ({ sortColumns }) => {
      const sortedColumn = sortColumns?.find(
        ({ column }) => column.key === "name"
      );

      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          Name
          {sortedColumn?.order ? (
            sortedColumn.order === "ascend" ? (
              <ArrowUpOutlined style={{ marginLeft: "8px" }} />
            ) : (
              <ArrowDownOutlined style={{ marginLeft: "8px" }} />
            )
          ) : null}
        </div>
      );
    },

    sorter: (a, b) => a?.title?.localeCompare(b?.title),
    dataIndex: "name",
    key: "name",
    render: (text, record) => {
      return (
        <Avatar.Group>
          <Avatar
            className={`${
              !record.deal_logo ? "shape-avatar-letter" : ""
            } shape-avatar`}
            shape="round"
            size={42}
            src={getImageURL(record.deal_logo || "")}
          >
            {getFirstLater(record?.deal_title || record?.title || "")}
          </Avatar>
          <div className="avatar-info">
            <Title level={5}>{record?.deal_title || record?.title}</Title>
            <p>{record?.organizer?.organizer_email}</p>
          </div>
        </Avatar.Group>
      );
    },
  },
  {
    title: ({ sortColumns }) => {
      const sortedColumn = sortColumns?.find(
        ({ column }) => column.key === "minimal_closing_amount"
      );

      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          Target Amount
          {sortedColumn?.order ? (
            sortedColumn.order === "ascend" ? (
              <ArrowUpOutlined style={{ marginLeft: "8px" }} />
            ) : (
              <ArrowDownOutlined style={{ marginLeft: "8px" }} />
            )
          ) : null}
        </div>
      );
    },
    sorter: (a, b) =>
      (a?.is_m_and_a ? a?.price : a?.minimal_closing_amount) -
      (b?.is_m_and_a ? b?.price : b?.minimal_closing_amount),
    dataIndex: "minimal_closing_amount",
    key: "minimal_closing_amount",
    render: (_, row) => {
      if (row?.is_m_and_a) {
        return row?.price || "";
      } else {
        return row?.minimal_closing_amount || "";
      }
    },
  },
  {
    title: "Investors",
    key: "Investors",
    render: (record) => (
      <Avatar.Group
        maxCount={5}
        maxStyle={{
          color: "#475467",
          backgroundColor: "#F3F4F6",
        }}
      >
        {[record?.investor || record.investors]?.map((investor, index) => (
          <Avatar src={BASE_URL + investor?.logo} />
        ))}
      </Avatar.Group>
    ),
  },
  {
    title: "Profile",
    key: "Profile",
    render: (text, record) => {
      return (
        <Avatar.Group>
          <Avatar
            className={`${
              !record.deal_logo ? "shape-avatar-letter" : ""
            } shape-avatar`}
            shape="round"
            size={30}
            src={getImageURL(record.deal_logo || "") || user}
          >
            {getFirstLater(record?.organizer?.name || "")}
          </Avatar>
          <div className="avatar-info">
            <Title level={5}>{record?.organizer?.name}</Title>
            <p>{record?.organizer_photo}</p>
          </div>
        </Avatar.Group>
      );
    },
  },
  {
    title: (
      <div style={{ display: "flex", alignItems: "center" }}>
        Status <QuestionCircleOutlined style={{ marginLeft: "8px" }} />
      </div>
    ),
    dataIndex: "status",
    key: "status",
    render: (status) => {
      let backgroundColor = "";
      let textColor = "";
      if (status === "Completed") {
        textColor = "#1F2937";
        backgroundColor = "#F3F4F6";
      } else if (status === "In Progress") {
        textColor = "#92400E";
        backgroundColor = "#FEF3C7";
      } else if (status === "Active") {
        textColor = "#027A48";
        backgroundColor = "#ECFDF3";
      } else if (status === "In Review") {
        textColor = "#0B5394";
        backgroundColor = "#F0F9FF";
      }
      return (
        <Tag className="tag_text" color={backgroundColor}>
          <span
            style={{
              color: textColor,
              fontFamily: "Manrope",
              fontStyle: "normal",
              fontWeight: 500,
              fontSize: "14px",
              lineHeight: "20px",
              letterSpacing: "0.01em",
            }}
          >
            &#9679;&nbsp;{status}
          </span>
        </Tag>
      );
    },
  },
  {
    render: (text, record) => {
      return (
        <Dropdown
          overlay={
            record.status === "In Review" ? (
              <AdminDealInReviewMenu
                record={record}
                dispatch={dispatch}
                navigate={navigate}
              />
            ) : record.status === "Compliance Check" ? (
              <AdminDealComplianceCheckMenu
                record={record}
                dispatch={dispatch}
                navigate={navigate}
              />
            ) : (
              <AdminDealMenu
                record={record}
                dispatch={dispatch}
                navigate={navigate}
              />
            )
          }
          trigger={["click"]}
          placement="bottomLeft"
        >
          <Icon component={DropdownSvg} className="dropdown-menu-button" />
        </Dropdown>
      );
    },
  },
];

const adminDeviceColumns = (dispatch, navigate) => [
  {
    render: (text, record) => {
      let backgroundColor = "";
      let textColor = "";
      if (record.status === "Completed") {
        textColor = "#1F2937";
        backgroundColor = "#F3F4F6";
      } else if (record.status === "In Progress") {
        textColor = "#92400E";
        backgroundColor = "#FEF3C7";
      } else if (record.status === "Active") {
        textColor = "#027A48";
        backgroundColor = "#ECFDF3";
      } else if (record.status === "In Review") {
        textColor = "#0B5394";
        backgroundColor = "#F0F9FF";
      }
      return (
        <Card
          className="deal-table-card"
          title={
            <Avatar.Group>
              <Avatar
                className={`${
                  !record.deal_logo ? "shape-avatar-letter" : ""
                } shape-avatar`}
                shape="round"
                size={40}
                src={getImageURL(record.deal_logo || "") || user}
              >
                {getFirstLater(record?.deal_title || record?.title || "")}
              </Avatar>
              <div className="avatar-info">
                <Title level={5}>
                  {record?.deal_title || record?.title || ""}
                </Title>
                <p>{record.company_website}</p>
              </div>
            </Avatar.Group>
          }
          extra={
            <div>
              <Tag className="tag_text" color={backgroundColor}>
                <span
                  style={{
                    color: textColor,
                    fontFamily: "Manrope",
                    fontStyle: "normal",
                    fontWeight: 500,
                    fontSize: "14px",
                    lineHeight: "20px",
                    letterSpacing: "0.01em",
                  }}
                >
                  &#9679;&nbsp;{record.status}
                </span>
              </Tag>
              <Dropdown
                overlay={
                  record.status === "In Review" ? (
                    <AdminDealInReviewMenu
                      record={record}
                      dispatch={dispatch}
                      navigate={navigate}
                    />
                  ) : record.status === "Compliance Check" ? (
                    <AdminDealComplianceCheckMenu
                      record={record}
                      dispatch={dispatch}
                      navigate={navigate}
                    />
                  ) : (
                    <AdminDealMenu
                      record={record}
                      dispatch={dispatch}
                      navigate={navigate}
                    />
                  )
                }
                trigger={["click"]}
                placement="bottomLeft"
              >
                <Icon
                  component={DropdownSvg}
                  className="dropdown-menu-button"
                />
              </Dropdown>
            </div>
          }
        >
          <Card.Grid className="card-grid">
            <div className="card-grid__item">
              <p>Target amount</p>
              <Title level={5}>
                {record?.is_m_and_a
                  ? record?.price
                  : record?.minimal_closing_amount}
              </Title>
            </div>
          </Card.Grid>
          <Card.Grid className="card-grid">
            <div className="card-grid__item">
              <p>Investors</p>
              <Avatar.Group
                maxCount={2}
                maxStyle={{
                  color: "#475467",
                  backgroundColor: "#F3F4F6",
                }}
              >
                {record.investor?.map((investor, index) => (
                  <Avatar src={BASE_URL + investor.logo} />
                ))}
              </Avatar.Group>
            </div>
          </Card.Grid>
        </Card>
      );
    },
  },
];

export {
  investorDeviceColumns,
  organizeDeviceColumns,
  investorColumns,
  organizeColumns,
  adminColumns,
  adminDeviceColumns,
};
