import React from "react";
import { Button, Col, Form, Input, Row, Select } from "antd";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "ckeditor5-build-classic-base64-upload";
import { getUser } from "../../../../Utils/isAuth";

const { Option } = Select;

const GetInTouchTab = ({ data, handleSubmit, deals }) => {
  const [form] = Form.useForm();
  const user = getUser();
  return (
    <Form
      name="horizontal_login"
      layout="vertical"
      form={form}
      onFinish={async (value) => {
        await handleSubmit(value);
        form.resetFields();
      }}
      initialValues={{
        to: data?.c_em || "",
        deal: "",
        subject: "Reaching out to you via InvestTech Platform",
        message: `<p>Dear Sir/Madam,</p><p>| would like to invite you to view our deal page at InvestTech Platform, and |<br>would appreciate you would take a look at this invitation opportunity.<br>Thank you for your interest, and we hope you will join us at InvestTech.<br>Best Regards,</p><p>${user?.first_name} ${user?.last_name}</p>`,
      }}
      className="form-get-in-touch"
    >
      <Row className="input_field_margin mr-0-profile">
        <Col md={24}>
          <div className="email-input-container">
            <Form.Item
              label="To"
              name="to"
              rules={[
                {
                  required: true,
                  message: "Field is required",
                },
                {
                  required: true,
                  type: "email",
                  message: "Please enter a valid email",
                },
              ]}
            >
              <Input className="input_padding" disabled/>
            </Form.Item>
          </div>
        </Col>
      </Row>
      <Row className="input_field_margin mr-0-profile">
        <Col md={24}>
          <div className="email-input-container">
            <Form.Item
              label="Subject"
              name="subject"
              rules={[
                {
                  required: true,
                  message: "Field is required",
                },
              ]}
            >
              <Input
                className="input_padding"
                defaultValue="Reaching out to you via InvestTech Platform"
              />
            </Form.Item>
          </div>
        </Col>
      </Row>
      <Row className="input_field_margin mr-0-profile">
        <Col md={24}>
          <div className="email-input-container">
            <Form.Item
              label="Message"
              name="message"
              getValueFromEvent={(event, editor) => {
                const data = editor.getData();
                return data;
              }}
              shouldUpdate
              rules={[
                {
                  required: true,
                  message: "Field is required",
                },
              ]}
            >
              <CKEditor
                editor={ClassicEditor}
                data={`<p>Dear Sir/Madam,</p><p>| would like to invite you to view our deal page at InvestTech Platform, and |<br>would appreciate you would take a look at this invitation opportunity.<br>Thank you for your interest, and we hope you will join us at InvestTech.<br>Best Regards,</p><p>${user?.first_name} ${user?.last_name}</p>`}
              />
            </Form.Item>
          </div>
        </Col>
      </Row>
      {deals.length > 0 && (
        <Row className="input_field_margin mr-0-profile">
          <Col md={24}>
            <div className="email-input-container">
              <Form.Item
                label="Include a link to your deal page in the message"
                name="deal"
                rules={[
                  {
                    required: true,
                    message: "Field is required",
                  },
                ]}
              >
                <Select className="select-in-modal-form">
                  {deals.map((item) => (
                    <Option label="Organizer" key={item.id}>
                      {item.title}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
          </Col>
        </Row>
      )}
      <Row className="btn-container">
        <Button className="save_btn" htmlType="Submit">
          Submit
        </Button>
      </Row>
    </Form>
  );
};

export default GetInTouchTab;
