import { Avatar, Col, List, Row, Typography } from "antd";
import React from "react";

const { Text } = Typography;

const ListingSection = ({ data, title }) => (
  <Col sm={24} md={8} xs={24} xl={8} className="list-container-col">
    <Text className="title" level={4}>
      {title}
    </Text>
    <List
      dataSource={data}
      className="list"
      renderItem={(item) => (
        <List.Item key={item.id}>
          <List.Item.Meta
            className="list-item"
            avatar={
              <Avatar
                src={"https://platform.dkv.global/files/" + item.logo}
                className="avtar-image"
              />
            }
            description={item.title}
          />
        </List.Item>
      )}
    />
  </Col>
);

const ComparativeAnalysisTab = ({
  investors,
  competitors,
  competitorsInvestors,
}) => (
  <Row className="comparative-analysis-container">
    <ListingSection data={investors} title="Portfolio" />
    <ListingSection data={competitors} title="Competitors" />
    <ListingSection
      data={competitorsInvestors}
      title="Portfolio of Competitors"
    />
  </Row>
);

export default ComparativeAnalysisTab;
