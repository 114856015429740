import React, { useState, useEffect } from "react";
import { Image, Modal } from "antd";
import FallbackImg from "../../assets/images/default-fallback-image.png";
import "./modalStyle.scss";
import { BASE_URL } from "../../api";

const ViewInfoModal = ({
  openViewInfoModal,
  setOpenViewInfoModal,
  editGrantDetails,
}) => {
  const [imgUrl, setImgUrl] = useState(null);
  const [coverUrl, setCoverUrl] = useState(null);

  const {
    name = "",
    background_image = "",
    photo = "",
    description = "",
    website = "",
  } = editGrantDetails;

  useEffect(() => {
    setImgUrl(photo ? BASE_URL + photo : null);
    setCoverUrl(background_image ? BASE_URL + background_image : null);
  }, [editGrantDetails]);

  const handleCancel = () => {
    setOpenViewInfoModal(false);
  };

  return (
    <Modal
      title="Grant Information"
      visible={openViewInfoModal}
      footer={false}
      onCancel={handleCancel}
    >
      <div className="card-cover-img">
        <div
          style={{
            overflow: "hidden",
            height: "220px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Image
            className="cover-img"
            alt="example"
            src={imgUrl || FallbackImg}
            fallback={FallbackImg}
            preview={false}
          />
        </div>
        {/* <div className="cover-avatar-group">
          <Avatar
            shape="round"
            size={60}
            src={getImageURL(imgUrl || "")}
            className={`${
              !imgUrl ? "shape-avatar-letter" : ""
            } shape-avatar cover-avatar-group__avatar`}
          >
            {getFirstLater(name || "")}
          </Avatar>
        </div> */}
      </div>
      <div className="grant-info">
        <div className="grant-head">
          <div className="heading">Name:</div>
          <div className="grant-name">{name}</div>
        </div>
        <div className="grant-head">
          <div className="heading">Description:</div>
          <div className="grant-name">{description}</div>
        </div>
        <div className="grant-head">
          <div className="heading">Website:</div>
          <a className="grant-website" target="__blank" href={website}>
            {website}
          </a>
        </div>
      </div>
    </Modal>
  );
};

export default ViewInfoModal;
