import React from "react";

import { Avatar, Card, Dropdown, Menu, Modal, Progress, Tag } from "antd";
import Title from "antd/lib/typography/Title";
import Icon from "@ant-design/icons";
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  QuestionCircleOutlined,
  CloseOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { DropdownSvg } from "../../../assets/images/dropdownSvg";

import { getFirstLater, getImageURL } from "../../../Utils";
import { deleteIpRequest } from "../../../pages/ips/redux/action";

const showConfirm = (e, record, dispatch) => {
  Modal.confirm({
    className: "delete__modal",
    title:
      "Are you sure you want to delete the deal permanently? This action cannot be undone.",
    okText: "Yes",
    cancelText: "No",
    centered: true,
    onOk: () => {
      dispatch(deleteIpRequest(record?.id));
    },
    onCancel() {
      console.log("Cancel");
    },
  });
};

const organizeDraftsMenu = (record, dispatch, navigate) => (
  <Menu className="edit-menu">
    <Menu.Item
      onClick={(event) => {
        event.domEvent.stopPropagation();
        navigate(
          record?.is_register_ip
            ? `/edit-register-ip/${record?.id}`
            : `/edit-sell-ip/${record?.id}`
        );
      }}
      className="edit-menu__item"
      key="edit-menu-item-1"
    >
      <EditOutlined className="menu-item-icon editing-txt" />
      <span className="list-item editing-txt">Edit IP</span>
    </Menu.Item>
    <Menu.Item
      className="edit-menu__item"
      key="edit-menu-item-2"
      onClick={(e) => {
        e.domEvent.stopPropagation();
        showConfirm(e, record, dispatch);
      }}
    >
      <CloseOutlined className="menu-item-icon reject-txt" />
      <span className="list-item reject-txt">Delete IP</span>
    </Menu.Item>
  </Menu>
);

export const OrganizerDeviceColumns = (dispatch, navigate) => [
  {
    render: (text, record) => {
      let backgroundColor = "";
      let textColor = "";
      if (record.status === "Completed") {
        textColor = "#1F2937";
        backgroundColor = "#F3F4F6";
      } else if (record.status === "In Progress") {
        textColor = "#92400E";
        backgroundColor = "#FEF3C7";
      } else if (record.status === "Active") {
        textColor = "#027A48";
        backgroundColor = "#ECFDF3";
      } else if (record.status === "In Review") {
        textColor = "#0B5394";
        backgroundColor = "#F0F9FF";
      }

      const avatars = [].map((el) => {
        return <Avatar src={el} />;
      });

      return (
        <Card
          className="deal-table-card"
          title={
            <Avatar.Group>
              <Avatar
                className={`${
                  !record.deal_logo ? "shape-avatar-letter" : ""
                } shape-avatar`}
                shape="round"
                size={40}
                src={getImageURL(record.ip_logo || "")}
              >
                {getFirstLater(record?.title || "")}
              </Avatar>
              <div className="avatar-info">
                <Title level={5}>{record?.title}</Title>
                <p>{record.company_website}</p>
              </div>
            </Avatar.Group>
          }
          extra={
            <div>
              <Tag className="tag_text" color={backgroundColor}>
                <span
                  style={{
                    color: textColor,
                    fontFamily: "Manrope",
                    fontStyle: "normal",
                    fontWeight: 500,
                    fontSize: "14px",
                    lineHeight: "20px",
                    letterSpacing: "0.01em",
                  }}
                >
                  &#9679;&nbsp;
                  {record.status === "Draft" ? "Drafts" : record.status}
                </span>
              </Tag>
              <Dropdown
                overlay={organizeDraftsMenu(record, dispatch, navigate)}
                trigger={["click"]}
                placement="bottomLeft"
              >
                <Icon
                  component={DropdownSvg}
                  className="dropdown-menu-button"
                />
              </Dropdown>
            </div>
          }
        >
          <Card.Grid className="card-grid" hoverable={false}>
            <div className="card-grid__item">
              <p>IP Type</p>
              <Title level={5}>{record?.ip_type}</Title>
            </div>
          </Card.Grid>
          <Card.Grid className="card-grid" hoverable={false}>
            <div className="card-grid__item">
              <p>Type</p>
              <Title level={5}>
                {record?.is_register_ip ? "For Registration" : "For Sale"}
              </Title>
            </div>
          </Card.Grid>
          <Card.Grid className="card-grid" hoverable={false}>
            <div className="card-grid__item">
              <p>Investors</p>
              <Avatar.Group
                maxCount={2}
                maxStyle={{
                  color: "#475467",
                  backgroundColor: "#F3F4F6",
                }}
              >
                {avatars.length > 0 ? avatars : ""}
              </Avatar.Group>
            </div>
          </Card.Grid>
          <Card.Grid className="card-grid" hoverable={false}>
            <div className="card-grid__item">
              <p>Target amount</p>
              <Title level={5}>
                {record?.is_m_and_a
                  ? record?.price
                  : record?.minimal_closing_amount}
              </Title>
            </div>
          </Card.Grid>
          <Card.Grid className="card-grid" hoverable={false}>
            <div className="card-grid__item">
              <p>Completion</p>
              <Progress
                percent={record.progress_bar}
                status="active"
                showInfo={false}
                className="card-progress"
              />
            </div>
          </Card.Grid>
        </Card>
      );
    },
  },
];

export const OrganizerColumns = (dispatch, navigate) => [
  {
    title: ({ sortColumns }) => {
      const sortedColumn = sortColumns?.find(
        ({ column }) => column.key === "name" || "deal_title"
      );
      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          Name
          {sortedColumn?.order ? (
            sortedColumn.order === "ascend" ? (
              <ArrowUpOutlined style={{ marginLeft: "8px" }} />
            ) : (
              <ArrowDownOutlined style={{ marginLeft: "8px" }} />
            )
          ) : null}
        </div>
      );
    },

    sorter: (a, b) => a.title.localeCompare(b.title),
    dataIndex: "name",
    key: "name",
    render: (text, record) => {
      return (
        <Avatar.Group>
          <Avatar
            className={`${
              !record.ip_logo ? "shape-avatar-letter" : ""
            } shape-avatar`}
            shape="round"
            size={40}
            src={getImageURL(record.ip_logo || "")}
          >
            {getFirstLater(record?.title || "")}
          </Avatar>
          <div className="avatar-info">
            <Title level={5}>{record?.title}</Title>
            <p>{record?.company_website}</p>
          </div>
        </Avatar.Group>
      );
    },
  },
  {
    title: "Type",
    key: "type",
    render: (_, record) => {
      return (
        <Title level={5}>
          {record?.is_register_ip ? "For Registration" : "For Sale"}
        </Title>
      );
    },
  },
  {
    title: "IP Type",
    key: "ip_type",
    render: (text, record) => {
      return <Title level={5}>{record?.ip_type}</Title>;
    },
  },
  {
    title: (
      <div style={{ display: "flex", alignItems: "center" }}>
        Status <QuestionCircleOutlined style={{ marginLeft: "8px" }} />
      </div>
    ),
    dataIndex: "status",
    key: "status",
    render: (text, { status }) => {
      let backgroundColor = "";
      let textColor = "";
      if (status === "Completed") {
        textColor = "#1F2937";
        backgroundColor = "#F3F4F6";
      } else if (status === "In Progress") {
        textColor = "#92400E";
        backgroundColor = "#FEF3C7";
      } else if (status === "Active") {
        textColor = "#027A48";
        backgroundColor = "#ECFDF3";
      } else if (status === "In Review") {
        textColor = "#0B5394";
        backgroundColor = "#F0F9FF";
      }
      return (
        <Tag className="tag_text" color={backgroundColor}>
          <span
            style={{
              color: textColor,
              fontFamily: "Manrope",
              fontStyle: "normal",
              fontWeight: 500,
              fontSize: "14px",
              lineHeight: "20px",
              letterSpacing: "0.01em",
            }}
          >
            &#9679;&nbsp;{status === "Draft" ? "Drafts" : status}
          </span>
        </Tag>
      );
    },
  },
  {
    render: (text, record) => {
      return (
        <Dropdown
          overlay={organizeDraftsMenu(record, dispatch, navigate)}
          trigger={["click"]}
          placement="bottomLeft"
        >
          <Icon component={DropdownSvg} className="dropdown-menu-button" />
        </Dropdown>
      );
    },
  },
];
