import { Card, Col, Row, Statistic } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import InvestorsListTable from "./InvestorsListTable";

const InvestorsTab = ({ data }) => {
  // const { pending, completed } = data?.investor_statistics;
  const pending = data?.investor_statistics?.pending;
  const completed = data?.investor_statistics?.completed;
  const dispatch = useDispatch();

  return (
    <>
      <Row gutter={[24, 0]}>
        <Col span={24} style={{ marginBottom: "20px" }}>
          <h2 className="financial_title">Investor statistics</h2>
        </Col>
        <Col xs={24} style={{ marginBottom: "20px" }}>
          <Row gutter={[24, 24]}>
            <Col xs={24} md={5}>
              <Card>
                <Row>
                  <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
                    {<h3 className="financial_statics_title">Pending</h3>}
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col xs={24} sm={24} md={12} xl={12} xxl={12}>
                    <Statistic className="stats_percentage" value={pending} />
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col xs={24} md={5}>
              <Card>
                <Row>
                  <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
                    {<h3 className="financial_statics_title">Completed</h3>}
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col xs={24} sm={24} md={12} xl={12} xxl={12}>
                    <Statistic className="stats_percentage" value={completed} />
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Col>

        <Col span={24} style={{ marginBottom: "20px" }}>
          <h2 className="financial_title">Investors list</h2>
        </Col>

        <Col span={24} style={{ marginBottom: "20px" }}>
          <InvestorsListTable data={data?.investors} dealInfo={data.deal_info} dispatch={dispatch} />
        </Col>
      </Row>
    </>
  );
};

export default InvestorsTab;
