import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {  isFacilitator, isInvestor } from "../../Utils";
import {
  ipDetailsAction,
  ipInvestorDetails,
  resetIpDetails,
} from "./redux/action";
import { Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "../../Utils/isAuth";
import { LoadingOutlined } from "@ant-design/icons";
import IpDetailsHeader from "./components/IpDetailsHeader";
import IpDetailsIntro from "./components/IpDetailsIntro";
import IpDetailTabs from "./components/IpDetailTabs";
import "./style.scss";

const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;

const IPDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();

  const onInvest = () => {
    navigate(`/ip-invest/?id=${id}`);
  };

  const userRole = getUser()?.type_of_account;
  const ipDetails = useSelector((state) => state.ipDetails.data);
  const ipInvDetails = useSelector((state) => state.ipDetails.ips);

  useEffect(() => {
    isInvestor() || isFacilitator()
      ? dispatch(ipInvestorDetails(id))
      : dispatch(ipDetailsAction(id));

    return () => {
      dispatch(resetIpDetails());
    };
  }, [id, isInvestor]);

  if (ipDetails.loading) {
    return (
      <div className="spinner_background">
        <Spin className="spinner" indicator={antIcon} />
      </div>
    );
  }

  return (
    <div className="deal-detail">
      <IpDetailsHeader
        ipDetail={
          ipDetails?.ip_info?.id ? ipDetails?.ip_info : ipInvDetails?.ips
        }
      />
      <IpDetailsIntro
        IpDetails={
          ipDetails?.ip_info?.id ? ipDetails?.ip_info : ipInvDetails?.ips
        }
        userRole={userRole}
        handleInvest={onInvest}
      />
      <IpDetailTabs
        IpDetails={ipDetails?.ip_info?.id ? ipDetails : ipInvDetails}
        userRole={userRole}
        id={id}
      />
    </div>
  );
};

export default IPDetails;
